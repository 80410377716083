import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { GetNearbyCitiesReturnType, NearbyCity } from '@leon-hub/api-sdk';
import {
  getNearbyCities as getNearbyCitiesRequest,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { AddressOption } from '../types';
import { mapNearbyCityToAddressOption } from '../utils';

interface UseNearbyCitiesSearch {
  getNearbyCities(): Promise<void>;
  nearbyCities: Ref<AddressOption[]>;
}

export function useNearbyCitiesSearch(): UseNearbyCitiesSearch {
  const api = useGraphqlClient();

  const requestResult = ref<NearbyCity[]>([]);

  const nearbyCities = computed<AddressOption[]>(() => requestResult.value.map((item) => mapNearbyCityToAddressOption(item)));

  const requestNearbyCities = (): Promise<GetNearbyCitiesReturnType> => {
    return getNearbyCitiesRequest(
      api,
      (node) => node.queries.autocomplete.nearbyCities,
      { silent: true },
    );
  };

  const getNearbyCities = async (): Promise<void> => {
    const response = await requestNearbyCities();
    if (response) {
      requestResult.value = response.items;
    }
  };

  return {
    getNearbyCities,
    nearbyCities,
  };
}
