<script setup lang="ts">
import type { DialogAction } from '@core/dialogs';

import type {
  SButtonProps,
  VButtonEmits,
  VButtonProps,
} from '@components/buttons';
import {
  ButtonHeight,
  isSButtonProps,
  isVButtonProps,
  SButton,
  VButton,
} from '@components/buttons';

type JumbotronFooterButtonProps = Omit<VButtonProps, 'kind'> & {
  kind?: VButtonProps['kind'] | SButtonProps['kind'];
  action?: DialogAction;
};

const {
  isUppercase = true,
  hasAnimation = true,
  isAbsolutePosition = false,
  rounded = false,
  height = ButtonHeight.LARGE,
  action,
  ...props
} = defineProps<JumbotronFooterButtonProps>();
const emit = defineEmits<VButtonEmits>();
</script>

<template>
  <SButton
    v-if="null && isSButtonProps(props)"
    v-bind="props"
    size="large"
    :class="{
      [$style['jumbotron-footer-button']]: true,
      [$style['jumbotron-footer-button--full-width']]: props.fullWidth,
    }"
    :action
    @click="emit('click', $event)"
    @keydown="emit('keydown', $event)"
    @mousedown="emit('mousedown', $event)"
  >
    <slot />
  </SButton>
  <VButton
    v-else-if="isVButtonProps(props)"
    v-bind="props"
    :action
    :height="height"
    :is-uppercase="isUppercase"
    :has-animation="hasAnimation"
    :is-absolute-position="isAbsolutePosition"
    :rounded="rounded"
    :class="{
      [$style['jumbotron-footer-button']]: true,
      [$style['jumbotron-footer-button--full-width']]: props.fullWidth,
    }"
    @click="emit('click', $event)"
    @keydown="emit('keydown', $event)"
    @mousedown="emit('mousedown', $event)"
  >
    <slot />
  </VButton>
</template>

<style lang="scss" module>
.jumbotron-footer-button {
  margin-right: auto;
  margin-left: auto;
}

.jumbotron-footer-button--full-width {
  width: 100%;

  & + .jumbotron-footer-button--full-width {
    margin-top: $jumbotronFooterButtonMarginTop;
    margin-left: 0;
  }
}
</style>
