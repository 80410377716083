<script lang="ts" setup>
import { EgsAvailabilityStatus } from '@leon-hub/api-sdk';

import { VBadge } from '@components/badge';
import { VIcon } from '@components/v-icon';

import type { EgsGameInfoOverlayProps } from 'web/src/modules/egs/components/EgsGameInfoOverlay/types';
import { useEgsGameInfoOverlay } from 'web/src/modules/egs/components/EgsGameInfoOverlay/composables';

const props = defineProps<EgsGameInfoOverlayProps>();
const {
  isSeatsHidden,
  seatsText,
  betRange,
  iconProperties,
  status,
  ntBadgeProperties,
} = useEgsGameInfoOverlay(props);
</script>

<template>
  <div v-auto-id="'EgsGameInfoOverlay'"
    :class="{
      [$style['egs-game-info-overlay']]: true,
      [$style['egs-game-info-overlay--top']]: isTopPosition,
    }"
  >
    <div :class="$style['egs-game-info-overlay__badges']">
      <VBadge
        v-if="!!badge"
        v-bind="ntBadgeProperties"
        :class="$style['egs-game-info-overlay__nt-badge']"
      />
      <div
        v-if="isExclusive"
        :class="$style['egs-game-info-overlay__item']"
      >
        {{ $t('WEB2_EXCLUSIVE_BADGE') }}
      </div>
      <div
        v-if="!isSeatsHidden"
        :class="{
          [$style['egs-game-info-overlay__seats']]: true,
          [$style['egs-game-info-overlay__item']]: true,
          [$style['egs-game-info-overlay__seats--busy']]: status === EgsAvailabilityStatus.NOT_AVAILABLE,
          [$style['egs-game-info-overlay__seats--behind']]: status === EgsAvailabilityStatus.BET_BEHIND,
        }"
      >
        <div :class="$style['egs-game-info-overlay__seats-indicator']" />
        {{ seatsText }}
        <VIcon
          v-bind="iconProperties"
          :class="$style['egs-game-info-overlay__seats-icon']"
        />
      </div>
      <div
        v-if="betRange"
        :class="$style['egs-game-info-overlay__item']"
      >
        {{ betRange }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.egs-game-info-overlay {
  $self: &;

  @include regular\10\12;

  display: flex;
  justify-content: flex-end;

  &--top {
    @include regular\12\16;

    position: absolute;
    top: calc(8px + var(--StatusbarHeight));
    right: 8px;
    left: 44px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    @include z-index(above-base);

    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 4px 8px;
    color: var(--BrandText);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 17px;

    #{$self}--top & {
      position: static;
      padding: 8px;
      white-space: nowrap;
      border-radius: 22px;
    }
  }

  &__seats {
    // agreed with designers
    &:not(&--busy, &--behind) {
      letter-spacing: 1.5px;
    }

    &-indicator {
      width: 4px;
      height: 4px;
      background-color: var(--Green);
      border-radius: 50%;

      #{$self}__seats--busy & {
        background-color: var(--ErrorText);
      }

      #{$self}__seats--behind & {
        background-color: var(--AlertText);
      }
    }

    &-icon {
      fill: var(--BrandText);

      #{$self}__seats--busy &,
      #{$self}__seats--behind & {
        display: none;
      }
    }
  }

  &__badges {
    @include z-index('absolute-item');

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;
    pointer-events: none;

    #{$self}--top & {
      position: absolute;
      top: 5px;
      right: 5px;
      gap: 8px;
    }
  }
}
</style>
