import type { MaybeRef, Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';
import { getQueryUrlPart } from '@leon-hub/utils';

import { useSiteConfigStore } from '@core/site-config';

import type { IFrameWidgetName } from 'web/src/modules/framed-app/types';

import { getDefaultWidgetUrl } from '../utils/getDefaultWidgetUrl';

export function useFramedWidgetUrl(
  widget: MaybeRef<IFrameWidgetName>,
  theme?: MaybeRef<ITheme | undefined>,
  queryParameters?: Ref<Record<string, string> | undefined>,
): Ref<string | undefined> {
  const siteConfigStore = useSiteConfigStore();
  const iframeWidgetUrl = toRef(() => siteConfigStore.widgetIframeUrl);
  const widgetName = toRef(widget);
  const themeRef = toRef(theme);

  if (process.env.VUE_APP_BUNDLER_VITE) {
    const themePart = computed(() => (themeRef.value ? `/${themeRef.value.toLowerCase()}` : ''));
    const widgetAppPathPart = process.env.NODE_ENV === 'development' ? '/index.framed.html' : (iframeWidgetUrl.value ?? '/iframe-widgets');

    return computed(() => `${widgetAppPathPart}${themePart.value}/${widgetName.value}${getQueryUrlPart(queryParameters?.value)}`);
  }

  return computed(() => (iframeWidgetUrl.value
    ? getDefaultWidgetUrl(
        widgetName.value,
        iframeWidgetUrl.value,
        themeRef.value,
        queryParameters?.value,
      )
    : undefined));
}
