import type { Ref } from 'vue';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';

import { LanguageMicroSettings } from '@leon-hub/locale';
import { Timer } from '@leon-hub/utils';

import { useI18n, useI18nStore } from '@core/i18n';

import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/modules/core/utils/countdown/types';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
import { useCashbackStore } from 'web/src/modules/promotions/store';

interface CashbackTimeCountdownComposable {
  timeName: Ref<DisplayedTimeLeft | null>;
  timeNameAlternative: Ref<DisplayedTimeLeft | null>;
  isHourShown: Ref<boolean>;
  isMinutesShown: Ref<boolean>;
  isShown: Ref<boolean>;
  timeLeft: Ref<ValueOfTimeLeft>;
}

export default function useCashbackTimeCountdown(
  targetTime: Ref<number>,
  isCashback: Ref<boolean | undefined>,
  callback?: () => void,
): CashbackTimeCountdownComposable {
  const cashbackStore = useCashbackStore();

  let intervalId = 0;
  const isShown = ref(true);

  const timeNow = ref(Date.now());

  const { $translate } = useI18n();
  const i18nStore = useI18nStore();
  const { timeLeftValue: timeLeft } = useCountdown(targetTime, timeNow);

  const setIsShownValue = () => {
    isShown.value = [
      timeLeft.value.days,
      timeLeft.value.hours,
      timeLeft.value.minutes,
      timeLeft.value.seconds,
    ].some((value) => value > 0);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = 0;
    }
  };
  const startTimer = () => {
    void cashbackStore.getCashback();
    timeNow.value = Date.now();
    setIsShownValue();
    intervalId = Timer.setInterval(() => {
      setIsShownValue();
      timeNow.value = Date.now();
      if (!isShown.value && callback) {
        callback();
        stopTimer();
        if (isCashback.value) {
          startTimer();
        }
      }
    }, 1000);
  };

  watch(targetTime, () => {
    stopTimer();
    startTimer();
  });

  const countDays = computed(() => ({ count: String(timeLeft.value.days) }));
  const countHours = computed(() => ({ count: String(timeLeft.value.hours) }));
  const countMinutes = computed(() => ({ count: String(timeLeft.value.minutes) }));
  const countSeconds = computed(() => ({ count: String(timeLeft.value.seconds) }));

  const timeName = computed<DisplayedTimeLeft | null>(() => (isShown.value
    ? {
        days: timeLeft.value.days === 0
          ? ''
          : LanguageMicroSettings.plural([
              $translate('WEB2_PROMOTION_COUNTDOWN_DAYS_ONE', countDays).value,
              $translate('WEB2_PROMOTION_COUNTDOWN_DAYS_TWO', countDays).value,
              $translate('WEB2_PROMOTION_COUNTDOWN_DAYS', countDays).value,
            ], timeLeft.value.days, i18nStore.locale),
        hours: LanguageMicroSettings.plural([
          $translate('WEB2_PROMOTION_COUNTDOWN_HOURS_ONE', countHours).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_HOURS_TWO', countHours).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_HOURS', countHours).value,
        ], timeLeft.value.hours, i18nStore.locale),
        minutes: LanguageMicroSettings.plural([
          $translate('WEB2_PROMOTION_COUNTDOWN_MINUTES_ONE', countMinutes).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_MINUTES_TWO', countMinutes).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_MINUTES', countMinutes).value,
        ], timeLeft.value.minutes, i18nStore.locale),
        seconds: LanguageMicroSettings.plural([
          $translate('WEB2_PROMOTION_COUNTDOWN_SECONDS_ONE', countSeconds).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_SECONDS_TWO', countSeconds).value,
          $translate('WEB2_PROMOTION_COUNTDOWN_SECONDS', countSeconds).value,
        ], timeLeft.value.seconds, i18nStore.locale),
      }
    : null));

  const timeNameAlternative = computed(() => (isShown.value
    ? {
        days: timeLeft.value.days === 0 ? '' : $translate('WEB2_PROMOTION_COUNTDOWN_ALTERNATIVE_DAYS').value,
        hours: $translate('WEB2_PROMOTION_COUNTDOWN_ALTERNATIVE_HOURS').value,
        minutes: $translate('WEB2_PROMOTION_COUNTDOWN_ALTERNATIVE_MINUTES').value,
        seconds: $translate('WEB2_PROMOTION_COUNTDOWN_ALTERNATIVE_SECONDS').value,
      }
    : null));

  const isHoursAndMinutesZero = computed(() => timeLeft.value.hours === 0
    && timeLeft.value.minutes === 0
    && timeLeft.value.days > 0
    && timeLeft.value.seconds > 0);

  const isHourShown = computed(() => timeLeft.value.hours > 0
    || (timeLeft.value.hours === 0 && timeLeft.value.days > 0)
    || isHoursAndMinutesZero.value);

  const isMinutesShown = computed(() => timeLeft.value.minutes > 0
    || (timeLeft.value.minutes === 0 && timeLeft.value.hours > 0)
    || isHoursAndMinutesZero.value);

  onMounted(startTimer);

  onBeforeUnmount(stopTimer);

  return {
    timeName,
    timeNameAlternative,
    isHourShown,
    isMinutesShown,
    isShown,
    timeLeft,
  };
}
