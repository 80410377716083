import type { ComputedRef, Ref } from 'vue';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type { PhoneInput } from '@leon-hub/api-sdk';
import { LoginType, PhoneCodeMethod } from '@leon-hub/api-sdk';
import { FormControlType } from '@leon-hub/form-utils';
import { IconName, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';
import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type { FormSchema, FormUiSchema } from 'web/src/components/Form/types';
import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import type { VSmsNumberCheckProps } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/types';
import type {
  RestorePasswordCheckPhoneFormEmits,
  RestorePasswordCheckPhoneFormProps,
} from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/types';
import type { SmsFormOutputType } from 'web/src/modules/restore-password/store/types';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
import { phoneNumberStarModifier } from 'web/src/utils/phoneNumberUtils/phoneNumberStarModifier';

export interface RestorePasswordCheckPhoneFormComposable {
  phoneAttemptsLimit: Ref<number>;
  isMaxAttempts: ComputedRef<boolean>;
  isShowHintBlock: ComputedRef<boolean>;
  iconProperties: ComputedRef<VJumbotronProps>;
  closeButtonProperties: ComputedRef<VButtonProps>;
  cmsKey: ComputedRef<string>;
  smsNumberCheckProperties: ComputedRef<VSmsNumberCheckProps>;
  handleRestoreButton(): void;
  checkCode(formOutput: SmsFormOutputType): void;
  sendSmsOrCallToPhone(): Promise<void>;
  setRetrySmsCodeTime(time: number): void;
  changeShowHintBlock(value: boolean): void;
  onInput(formOutput: SmsFormOutputType): void;
  onBlur(formOutput: SmsFormOutputType): void;
  goToRestorePasswordMainPage(): void;
  goToPhoneCheckBySmsStage(): Promise<void>;
}

export default function useRestorePasswordCheckPhoneForm(props: RestorePasswordCheckPhoneFormProps, emits: RestorePasswordCheckPhoneFormEmits): RestorePasswordCheckPhoneFormComposable {
  const { $translate } = useI18n();
  const restorePasswordStore = useRestorePasswordStore();
  const siteConfigStore = useSiteConfigStore();
  const pinCodeStore = usePinCodeStore();
  const routerStore = useRouterStore();
  const phoneCheckConfirmationSettings = toRef(siteConfigStore, 'phoneCodeLengthSettings');
  const phoneAttemptsLimit = toRef(siteConfigStore, 'phoneWrongAttemptsLimit');
  const isEmailRegEnabled = toRef(siteConfigStore, 'isSuggestedEmailRegistrationEnabled');
  const emailRegTimeout = toRef(siteConfigStore, 'suggestedEmailRegistrationTimeout');
  const isNewTel = toRef(restorePasswordStore, 'isNewTel');
  const attemptsCounter = ref(1);
  const showHintBlock = ref(false);
  const isLoading = ref(false);
  const isError = ref(false);
  const errorMessage = ref('');

  const router = useRouter();

  const iconProperties = computed<VJumbotronProps>(() => ({
    icon: IconName.INFO_OUTLINE,
    iconSize: IconSize.SIZE_60,
  }));

  const closeButtonProperties = computed<VButtonProps>(() => ({
    kind: ButtonKind.PRIMARY,
    fullWidth: true,
  }));

  const cmsKey = computed<string>(() => {
    if (isNewTel.value) {
      return props.isPin
        ? 'WEB2_RESTORE_PIN_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT'
        : 'WEB2_RESTORE_PASSWORD_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT';
    }
    return props.isPin
      ? 'WEB2_RESTORE_PIN_PHONE_CHECK_HINT_BLOCK_TIMEOUT'
      : 'WEB2_RESTORE_PASSWORD_PHONE_CHECK_HINT_BLOCK_TIMEOUT';
  });

  const phoneCheckConfirmationCodeLength = computed<number>(() => {
    const formType = restorePasswordStore.phoneCheckType;
    const settingsForType = formType ? phoneCheckConfirmationSettings.value[formType] : null;
    return settingsForType || 6;
  });

  const uiSchema = computed<FormUiSchema>(() => ({
    submitButton: {
      label: $translate('WEB2_NEXT').value,
      disabled: isError.value,
    },
    fields: {
      sms: {
        widget: FormControlType.SmsCode,
        options: {
          size: phoneCheckConfirmationCodeLength.value,
          error: errorMessage.value,
          autofocus: true,
        },
      },
    },
  }));
  const schema = computed<FormSchema>(() => ({
    type: 'object',
    required: ['sms'],
    properties: {
      sms: {
        type: 'string',
        pattern: `^[0-9]{0,${phoneCheckConfirmationCodeLength.value}}$`,
        minLength: phoneCheckConfirmationCodeLength.value,
        maxLength: phoneCheckConfirmationCodeLength.value,
      },
    },
  }));
  const isMaxAttempts = computed<boolean>(() => attemptsCounter.value > phoneAttemptsLimit.value);

  const isShowHintBlock = computed<boolean>(() => isEmailRegEnabled.value && showHintBlock.value);

  const smsNumberCheckProperties = computed<VSmsNumberCheckProps>(() => {
    const currentPhone: PhoneInput = {
      prefix: restorePasswordStore.currentPhone?.prefix || '',
      suffix: restorePasswordStore.currentPhone?.suffix || '',
    };

    return {
      schema: schema.value,
      uiSchema: uiSchema.value,
      isPending: isLoading.value,
      phone: phoneNumberStarModifier(currentPhone),
      isNewTel: isNewTel.value,
      showButtonTime: restorePasswordStore.retrySmsCodeTime,
      hintTimeout: emailRegTimeout.value,
      isPin: props.isPin,
    };
  });

  async function sendSmsOrCallToPhone(): Promise<void> {
    if (props.isPin) {
      await pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
    } else {
      await restorePasswordStore.resendRestorePasswordSms({});
    }
  }

  function changeShowHintBlock(value: boolean): void {
    showHintBlock.value = value;
  }

  function goToRestorePasswordMainPage(): void {
    if (props.isPin) {
      restorePasswordStore.setActiveTab(LoginType.EMAIL);
      emits('close');
    }
    restorePasswordStore.clearCurrentRestoreData();
    restorePasswordStore.setActiveTab(LoginType.EMAIL);
  }

  async function goToPhoneCheckBySmsStage(): Promise<void> {
    if (props.isPin) {
      await pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
    } else {
      await restorePasswordStore.resendRestorePasswordSms({ method: PhoneCodeMethod.SMS });
    }
    attemptsCounter.value = 1;
  }

  function setRetrySmsCodeTime(time: number): void {
    if (props.isPin) {
      void pinCodeStore.handleSendRestorePinTokenPhone(PhoneCodeMethod.SMS);
    } else {
      restorePasswordStore.setRetrySmsCodeTime(time);
    }
  }

  function checkCode(formOutput: SmsFormOutputType): void {
    if (formOutput.errors) {
      return;
    }

    isLoading.value = true;
    if (!props.isPin) {
      restorePasswordStore.checkRestorePasswordSmsStore(formOutput)
        .then(() => {
          isError.value = false;
          void router.replace({ name: RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE });
        })
        .catch(() => {
          if (attemptsCounter.value <= phoneAttemptsLimit.value) {
            attemptsCounter.value += 1;
          }
          isError.value = true;
          setExternalError();
        })
        .finally(() => {
          isLoading.value = false;
        });
    } else {
      pinCodeStore.resetPinCodeByPhone(formOutput.formData.sms)
        .then(() => {
          emits('close');

          pinCodeStore.setStepWithLS('CREATE');

          if (routerStore.currentRouteName === RouteName.RESTORE_PIN_PROFILE) {
            void router.push({ name: RouteName.PIN_CODE_PROFILE });
          } else {
            void router.push({ name: RouteName.PIN_CODE });
          }
        })
        .catch(() => {
          isLoading.value = false;
          if (attemptsCounter.value <= phoneAttemptsLimit.value) {
            attemptsCounter.value += 1;
          }
          isError.value = true;
          setExternalError();
        });
    }
  }

  function setExternalError(): void {
    errorMessage.value = $translate('WEB2_INVALID_CODE').value;
  }

  function onInput(formOutput: SmsFormOutputType): void {
    if (formOutput.errors) {
      setExternalError();
    } else {
      errorMessage.value = '';
    }
    isError.value = false;
  }

  function onBlur(formOutput: SmsFormOutputType): void {
    if (formOutput.errors) {
      setExternalError();
    }
  }

  function clear(): void {
    restorePasswordStore.clearCurrentRestoreData();
  }

  function handleRestoreButton(): void {
    if (props.isPin) {
      emits('close');
    } else {
      clear();
    }
  }

  return {
    phoneAttemptsLimit,
    isMaxAttempts,
    isShowHintBlock,
    iconProperties,
    closeButtonProperties,
    cmsKey,
    handleRestoreButton,
    smsNumberCheckProperties,
    checkCode,
    sendSmsOrCallToPhone,
    setRetrySmsCodeTime,
    changeShowHintBlock,
    onInput,
    onBlur,
    goToRestorePasswordMainPage,
    goToPhoneCheckBySmsStage,
  };
}
