import type { MaybeRef, Ref } from 'vue';
import { toRef } from 'vue';

import type { MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { MarketsListTabsReference } from 'web/src/modules/sportline/views/markets-list/types';

interface MarketsListToolbarProps {
  isStatisticWidgetSelected?: MaybeRef<Optional<boolean>>;
  isStatisticSelected?: MaybeRef<Optional<boolean>>;
  tabsRef: Ref<Maybe<MarketsListTabsReference>>;
}

export interface MarketsListToolbarEmits {
  (e: 'market-tab-click', payload: MarketsGridFilterId): void;
  (e: 'change-statistic-selected', payload: boolean): void;
  (e: 'change-statistic-widget-selected', payload: boolean): void;
}

interface MarketsListToolbarComposable {
  slideToActiveTab(): void;
  onMarketTabClick(value: MarketsGridFilterId): void;
  onChangeStatisticSelected(value?: boolean): void;
  onChangeStatisticWidgetSelected(value?: boolean): void;
}

export function useMarketsListToolbar(
  props: MarketsListToolbarProps,
  emit: MarketsListToolbarEmits,
): MarketsListToolbarComposable {
  const { tabsRef } = props;

  const isStatisticWidgetSelected = toRef(props.isStatisticWidgetSelected ?? false);
  const isStatisticSelected = toRef(props.isStatisticSelected ?? false);

  function slideToActiveTab(): void {
    tabsRef.value?.slideToActive();
  }

  function onMarketTabClick(value: MarketsGridFilterId): void {
    emit('market-tab-click', value);
  }

  function onChangeStatisticSelected(value?: boolean): void {
    emit('change-statistic-selected', value ?? !isStatisticSelected.value);
  }

  function onChangeStatisticWidgetSelected(value?: boolean): void {
    emit('change-statistic-widget-selected', value ?? !isStatisticWidgetSelected.value);
  }

  return {
    slideToActiveTab,
    onMarketTabClick,
    onChangeStatisticSelected,
    onChangeStatisticWidgetSelected,
  };
}
