function getStars(number: number): string {
  return number ? '*'.repeat(number) : '';
}

export function phoneNumberStarModifier({ prefix = '', suffix = '' }: { prefix: string; suffix: string }): string {
  let phoneCode = '';
  let phoneStart = getStars(3);
  let phoneMiddle = getStars(2);
  let phoneEnd = suffix.slice(5);

  if (suffix.length >= 9) {
    phoneCode = `(${suffix.slice(0, 3)})`;
    phoneStart = getStars(3);
    phoneMiddle = getStars(suffix.length - 8);
    phoneEnd = suffix.slice(-2);
  }

  const dash = suffix.length > 5 ? '-' : '';

  return `+${prefix} ${phoneCode} ${phoneStart}-${phoneMiddle}${dash}${phoneEnd}`;
}
