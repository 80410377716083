<script lang="ts" setup>
import { BonusState } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';

import { VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type { BonusLoyaltyProgramPanelEmits, BonusLoyaltyProgramPanelProps } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramPanel/types';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';

import useBonusLoyaltyProgramPanel from './composables/useBonusLoyaltyProgramPanel';

const props = withDefaults(defineProps<BonusLoyaltyProgramPanelProps>(), {
  title: '',
  amountNumber: 0,
  remainingAmountNumber: 0,
  progress: 0,
  progressText: '',
  state: BonusState.EMPTY,
});

const emit = defineEmits<BonusLoyaltyProgramPanelEmits>();
const {
  progressProperties,
  buttonProperties,
  participateButtonProperties,
  pointsLabelProperties,
  requiredAmount,
} = useBonusLoyaltyProgramPanel(props);

const coinIcon = IconName.COIN_L;
</script>

<template>
  <div v-auto-id="'BonusLoyaltyProgramPanel'" :class="$style['loyalty-program-panel']">
    <div :class="$style['loyalty-program-panel__header']">
      <div :class="$style['loyalty-program-panel__header-content']">
        <div :class="$style['loyalty-program-panel__headline']">
          {{ $t('WEB2_LOYALTY_PROGRAM_POINTS') }}
        </div>
        <div :class="$style['loyalty-program-panel__points']">
          <span :class="$style['loyalty-program-panel__points-label']">
            {{ pointsLabelProperties }}
          </span>
          <VIcon
            :class="$style['loyalty-program-panel__points-icon']"
            :name="coinIcon"
          />
        </div>
      </div>
      <img
        :class="$style['loyalty-program-panel__header-icon']"
        :src="require('web/src/assets/images/gift-leon.svg')"
        alt=""
      >
    </div>
    <VButton
      v-if="null && !isParticipating"
      v-bind="participateButtonProperties"
      :class="$style['loyalty-program-panel__button']"
      @click="emit('participate')"
    />
    <div v-else-if="progress || remainingAmountNumber">
      <div
        v-if="progress < 1"
        :class="$style['loyalty-program-panel__progress']"
      >
        <VLinearProgress
          v-bind="progressProperties"
          :class="$style['loyalty-program-panel__progress-line']"
        />
        <span :class="$style['loyalty-program-panel__progress-label']">{{ `${progressText}%` }}</span>
        <span :class="$style['loyalty-program-panel__progress-total']">
          {{ requiredAmount }} {{ $t('WEB2_LOYALTY_PROGRAM_BEFORE_EXCHANGE') }}
        </span>
      </div>
      <VButton
        v-else
        v-bind="buttonProperties"
        :class="$style['loyalty-program-panel__button']"
        @click="emit('withdraw')"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.loyalty-program-panel {
  padding: 16px;
  margin-bottom: 24px;
  background-color: var(--Layer1);
  border-radius: 5px;

  &__header {
    display: flex;
    justify-content: space-between;

    &-icon {
      flex-shrink: 0;
      width: 44px;
    }
  }

  &__headline {
    @include regular\14\16\025;

    margin-bottom: 8px;
    color: var(--TextSecondary);
  }

  &__points {
    @include medium\18\20;

    display: flex;
    align-items: center;

    &-label {
      margin-right: 4px;
      color: var(--TextDefault);
    }

    &-icon {
      fill: var(--BrandDefault);
    }
  }

  &__progress {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 20px;

    &-line {
      width: 100%;
      margin-bottom: 8px;
    }

    &-label {
      @include medium\12\16;

      color: var(--BrandDefault);
    }

    &-total {
      @include regular\12\16;

      color: var(--TextSecondary);
    }
  }

  &__button {
    margin-top: 16px;
  }
}
</style>
