import { computed, toRef } from 'vue';

import { useCurrency, useCurrencyStore } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import type { PaymentsFastSumInputProps, PaymentsSuggestedAmountsComposable }
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

export default function usePaymentsSuggestedAmounts(
  props: PaymentsFastSumInputProps,
): PaymentsSuggestedAmountsComposable {
  const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');
  const { currency } = useCurrency();
  const suggestedAmountsFromConfig = toRef(useSiteConfigStore(), 'suggestedAmounts');

  const maxAmount = toRef(props, 'maxAmount');
  const minAmount = toRef(props, 'minAmount');
  const suggestedAmounts = toRef(props, 'suggestedAmounts');
  const allowedIncrement = toRef(props, 'allowedIncrement');

  const filteredDefaultSuggestedAmounts = computed(() => {
    if (suggestedAmountsFromConfig.value && formattedCurrency.value && currency.value) {
      const suggestedAmountArray = suggestedAmountsFromConfig.value.filter(
        (item) => item.currency === currency.value,
      );

      if (suggestedAmountArray.length === 1) {
        const amountList = suggestedAmountArray[0].depositAmounts;
        if (amountList?.length) {
          return amountList.filter((item) => (
            item >= minAmount.value && (maxAmount.value !== 0 ? item <= maxAmount.value : item)
          ));
        }
      }
    }
    return null;
  });

  const filteredCurrentPaymentSystemSuggestedAmounts = computed(() => {
    const amounts = suggestedAmounts?.value ?? [];
    const incr = allowedIncrement?.value ?? 0;
    if (amounts.length) {
      return amounts
        .filter(
          (amount: number) => (
            amount >= minAmount.value && amount <= maxAmount.value && (incr > 0
              ? amount % incr === 0
              : amount)
          ),
        );
    }
    return null;
  });

  return {
    filteredDefaultSuggestedAmounts,
    filteredCurrentPaymentSystemSuggestedAmounts,
  };
}
