import type { Ref } from 'vue';
import { ref } from 'vue';

import { isAppInstalled } from '@leon-hub/cordova';

interface IsAppInstalledComposable {
  isInstalled: Ref<boolean>;
  checkIfAppInstalled(packageName: string): Promise<void>;
}

// do not use this composable if you need to check several packages in one place, use useIsOneOfPakageInstalled instead
export function useIsAppInstalled(): IsAppInstalledComposable {
  const isInstalled = ref(false);

  if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
    return {
      isInstalled,
      checkIfAppInstalled: () => Promise.resolve(),
    };
  }

  async function checkIfAppInstalled(packageName: string): Promise<void> {
    isInstalled.value = await isAppInstalled(packageName);
  }

  return {
    isInstalled,
    checkIfAppInstalled,
  };
}

/*
Use if you need to check more then one package
*/

export function useIsOneOfPackageInstalled(): { checkIfOneOfAppInstalled(packageName: string): { isInstalled: Ref<boolean> } } {
  return {
    checkIfOneOfAppInstalled: (packageName: string): { isInstalled: Ref<boolean> } => {
      const { isInstalled, checkIfAppInstalled } = useIsAppInstalled();
      void checkIfAppInstalled(packageName);
      return {
        isInstalled,
      };
    },
  };
}
