import type { LiveChatService } from 'web/src/modules/live-chat/services/api/types';
import createLiveChatApi from 'web/src/modules/live-chat/services/api/createLiveChatApi';

let chatApi: LiveChatService | undefined;

export default function useLiveChatApi(): LiveChatService {
  if (!chatApi) {
    chatApi = createLiveChatApi();
  }

  return chatApi;
}
