import type { Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';

import type { Web2Image } from '@leon-hub/api-sdk';
import { EgsGameType } from '@leon-hub/api-sdk';
import { nextAnimationFrame } from '@leon-hub/html-utils';

import type { VImageRef } from '@components/image';

import type { LobbyEgsGamesItemImageProps } from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/types';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';

import { getBetRange, getImageProperties } from './utils';

export interface LobbyEgsGamesItemComposable {
  image: Ref<VImageRef | undefined>;
  imageProperties: Ref<Web2Image>;
  onLazyImageMounted(): void;
  onImageError(): void;
  onImageLoaded(): Promise<void>;
  showPanel: Ref<boolean>;
  brief: Ref<string>;
  betRange: Ref<string | undefined>;
  isDemoAvailable: Ref<boolean>;
  href: Ref<string | undefined>;
}

export default function useLobbyEgsGamesItemImage(
  props: LobbyEgsGamesItemImageProps,
): LobbyEgsGamesItemComposable {
  const router = useRouter();
  const { getEgsGameHref } = useEgsNavigation(router);
  const image = ref<VImageRef>();

  const isImageLoadError = ref(false);
  const isImageLoaded = ref(false);

  const imageProperties = computed<Web2Image>(() => getImageProperties(isImageLoadError.value, props));

  const brief = computed(() => props.item?.shortDescription || '');
  const isDemoAvailable = computed(() => !!props.item?.hasDemo && props.item?.typeId !== EgsGameType.FASTGAMES);
  const showPanel = computed<boolean>(() => {
    if (process.env.VUE_APP_PRERENDER) {
      return !!props.isPanelVisible;
    }

    return !!(props.isPanelVisible && (isImageLoaded.value || isImageLoadError.value));
  });

  const href = computed(() => (
    props.item ? getEgsGameHref(props.item) : undefined
  ));

  const betRange = computed(() => getBetRange(props.item?.limits));

  function onLazyImageMounted(): void {
    if (props.hasImageForceLoad) {
      image.value?.forceShowImage();
    }
  }

  async function onImageLoaded(): Promise<void> {
    await nextTick();
    await nextAnimationFrame();
    isImageLoaded.value = true;
  }

  function onImageError(): void {
    isImageLoadError.value = true;
  }

  return {
    href,
    image,
    imageProperties,
    onLazyImageMounted,
    onImageError,
    onImageLoaded,
    betRange,
    showPanel,
    brief,
    isDemoAvailable,
  };
}
