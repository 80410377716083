import type { MaskOptions } from '@leon-hub/input-types';

import { PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

interface UseCardCvvInput {
  maskOptions: MaskOptions;
  showHelp(): void;
}

export default function useCardCvvInput(): UseCardCvvInput {
  const { showDialog } = useDialogs();

  const { $translate } = useI18n();

  const maskOptions: MaskOptions = {
    pattern: '999',
    placeholder: '0',
  };

  const showHelp = (): void => {
    showDialog({
      presetName: PresetName.CMS_MODAL,
      options: {
        iconName: undefined,
        fullHeight: false,
        title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? '' : $translate('WEB2_WHAT_IS_CVV_CVC').value,
        cmsKey: 'WEB2_WHAT_IS_CVV',
        buttons: [
          {
            label: $translate('JSP_OK').value,
          },
        ],
        dataTestId: 'what-is-cvv',
      },
    });
  };

  return {
    maskOptions,
    showHelp,
  };
}
