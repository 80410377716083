import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import {
  computed,
  watch,
} from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';

import { useIsLoggedIn } from '@core/auth';

import type { EgsGroup } from 'web/src/modules/egs/types';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { getInitArrayStatesByLobbyType } from 'web/src/modules/egs/utils';
import useLobbyType from 'web/src/modules/lobby/composables/useLobbyType';

const useEgsGroupsStore = defineStore('egs-groups', () => {
  const { lobbyType: currentLobbyType } = useLobbyType();
  const { getGroups } = useEgsApi();
  const { isLoggedIn } = useIsLoggedIn();

  const groupsByType = getInitArrayStatesByLobbyType<EgsGroup>();

  function getGroupsByLobbyType(lobbyType: ILobbyType): Ref<EgsGroup[]> {
    const group = groupsByType[lobbyType];
    if (group) {
      return group;
    }
    throw new Error(`No groups by lobby type: ${lobbyType}`);
  }

  const currentGroups = computed(() => getGroupsByLobbyType(currentLobbyType.value).value);

  function setGroups(type: ILobbyType, groups: EgsGroup[]): void {
    const group = groupsByType[type];
    if (!group) {
      throw new Error(`No groups by lobby type: ${type}`);
    }
    group.value = groups;
  }

  async function loadGroups(lobbyType?: ILobbyType, silent = false): Promise<void> {
    const type = lobbyType ?? currentLobbyType.value;
    const groups = await getGroups({
      type,
    }, silent);
    setGroups(type, groups);
  }

  watch(isLoggedIn, () => {
    for (const group of Object.values(groupsByType)) {
      group.value = [];
    }
  });

  return {
    getGroupsByLobbyType,
    loadGroups,
    currentGroups,
  };
});

export default useEgsGroupsStore;
