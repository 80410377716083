<script lang="ts" setup>
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';

import type { MarketsListGroupEmits, MarketsListGroupProps } from './types';
import BetSwipeMarketGroup from './components/bet-swipe/MarketGroup.vue';
import DefaultMarketGroup from './components/default/MarketGroup.vue';

defineProps<MarketsListGroupProps>();
const emit = defineEmits<MarketsListGroupEmits>();

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
</script>

<template>
  <BetSwipeMarketGroup
    v-if="null && isBetSwipeEventDetailsLayoutEnabled"
    :group="group"
    :sportline-event="sportlineEvent"
    @market-group-toggle="emit('market-group-toggle', $event)"
  />
  <DefaultMarketGroup
    v-else
    :group="group"
    :sportline-event="sportlineEvent"
    @market-group-toggle="emit('market-group-toggle', $event)"
  />
</template>
