import type { ComputedRef, Ref } from 'vue';
import { computed, onBeforeUnmount, ref } from 'vue';

import { CaptchaType } from '@leon-hub/api-sdk';
import { assert, isNullOrUndefined } from '@leon-hub/guards';
import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { useErrorsConverter } from '@core/errors';
import { useI18nLocale } from '@core/i18n';
import { captchaChallengeWasShownKey } from '@core/storage';

import { CaptchaRequesterStrategy, ReCaptchaType } from '@modules/captcha-utilities';

import type { VIframeRef } from 'web/src/components/Iframe/VIframe/types';
import type { VCaptchaEmits, VCaptchaProps } from 'web/src/modules/captcha/components/VCaptcha/types';
import type { ReCaptchaResponse } from 'web/src/modules/captcha/store/types';
import { getContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import ReCaptchaPostMessageEvent from 'web/src/modules/captcha/components/types/ReCaptchaPostMessageEvent';
import {
  getAdditionalQueryStringParameters,
} from 'web/src/modules/captcha/components/VCaptcha/composables/utils';
import { ReCaptchaFramedWrapperState } from 'web/src/modules/captcha/components/VReCaptcha/enums';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { captchaIframeWidgetName } from 'web/src/modules/framed-app/constants';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface VCaptchaComposable {
  vCaptchaElement: Ref<HTMLElement | undefined>;
  iframeElement: Ref<VIframeRef | undefined>;
  iframeWidgetSrc: Ref<string | undefined>;
  expanded: Ref<boolean>;
  isDefaultStrategy: ComputedRef<boolean>;
  iframeName: typeof captchaIframeWidgetName;
  additionalQueryStringParameters: ComputedRef<Record<string, string>[]>;
  iframeMounted(): void;
  listenPostMessage(): void;
  emitVerify(reCaptchaResponse: ReCaptchaResponse): void;
  emitExpired(): void;
  emitError(error: CaptchaServiceError): void;
  emitChallengeClosed(): void;
  emitChallengeOpened(): void;
  mapCaptchaType(captchaType: CaptchaType): ReCaptchaType;
}

export default function useVCaptcha(
  props: VCaptchaProps,
  emit: VCaptchaEmits,
): VCaptchaComposable {
  let postMessageBus: Maybe<Readonly<PostMessageBus>> = null;
  const { lang } = useI18nLocale();
  const expanded = ref(false);
  const isDefaultStrategy = computed(() => props.captchaRequesterStrategy === CaptchaRequesterStrategy.DEFAULT);
  const vCaptchaElement = ref<HTMLElement>();
  const iframeElement = ref<VIframeRef>();
  const iframeName = captchaIframeWidgetName;
  const iframeWidgetSrc = useFramedWidgetUrl(iframeName);
  const additionalQueryStringParameters = computed(() => getAdditionalQueryStringParameters(props, lang.value));

  function iframeMounted(): void {
    createPostMessage();
  }

  function mapCaptchaType(captchaType: CaptchaType): ReCaptchaType {
    switch (captchaType) {
      case CaptchaType.INVISIBLE_RECAPTCHA:
      case CaptchaType.ANDROID_RECAPTCHA:
        return ReCaptchaType.CHALLENGE_INVISIBLE;
      case CaptchaType.RECAPTCHA:
        return ReCaptchaType.CHALLENGE_CHECKBOX;
      default:
        throw new Error(`Unexpected state captchaType:${captchaType}`);
    }
  }

  function listenPostMessage(): void {
    if (props.useIframeForCaptcha) {
      assert(postMessageBus);

      postMessageBus?.on(ReCaptchaPostMessageEvent.verify, emitVerify);
      postMessageBus?.on(ReCaptchaPostMessageEvent.expired, emitExpired);
      postMessageBus?.on(ReCaptchaPostMessageEvent.error, emitFramedError);
      postMessageBus?.on(ReCaptchaPostMessageEvent.wrapperState, emitWrapperState);
    }
  }

  function emitVerify(reCaptchaResponse: ReCaptchaResponse): void {
    emit('verify', reCaptchaResponse);
  }

  function emitExpired(): void {
    emit('expired');
  }

  function emitError(error: CaptchaServiceError): void {
    emit('error', useErrorsConverter().convertToBaseError(error));
  }

  function emitFramedError({ errorJSON }: { errorJSON: string }): void {
    const { name, ...errorData } = JSON.parse(errorJSON);
    emitError(Object.assign(new CaptchaServiceError(), errorData));
  }

  function emitChallengeClosed(): void {
    emit('challenge-is-closed');
  }

  function emitChallengeOpened(): void {
    emit('challenge-is-opened');
    window.sessionStorage.setItem(captchaChallengeWasShownKey, String(true));
  }

  function emitWrapperState(
    { state }: { state: ReCaptchaFramedWrapperState },
  ): void {
    expanded.value = state === ReCaptchaFramedWrapperState.Expand;
    emit('wrapper-state', state);
  }

  function createPostMessage(): void {
    const iframeEl = getContentWindowByIFrameName(iframeName);

    assert(!isNullOrUndefined(iframeEl), 'Iframe contentWindow doesn\'t exist on mounted');

    postMessageBus = Object.freeze(new PostMessageBus({
      target: iframeEl,
      targetOrigin: '*',
      initiator: 'VCaptcha',
    }));
  }

  onBeforeUnmount(() => {
    if (props.useIframeForCaptcha) {
      postMessageBus?.dispose();
    }
  });

  return {
    vCaptchaElement,
    iframeElement,
    iframeWidgetSrc,
    expanded,
    isDefaultStrategy,
    iframeName,
    additionalQueryStringParameters,
    iframeMounted,
    listenPostMessage,
    emitVerify,
    emitExpired,
    emitError,
    emitChallengeClosed,
    emitChallengeOpened,
    mapCaptchaType,
  };
}
