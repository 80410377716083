import type { VInputInputEvent } from '@leon-hub/input-types';
import { InputEventType } from '@leon-hub/input-types';

import type { BaseFormWidgetEmits } from 'web/src/components/Form/types';

import getFormDataEventWithFormattedCPFValue from './getFormDataEventWithFormattedCPFValue';

interface UseCPFNumberEvents {
  emitChange(event: VInputInputEvent): void;
  emitInput(event: VInputInputEvent): void;
  emitFocus(event: VInputInputEvent): void;
  emitBlur(event: VInputInputEvent): void;
}

function useCPFNumberEvents(emit: BaseFormWidgetEmits): UseCPFNumberEvents {
  const emitChange = (event: VInputInputEvent): void => {
    emit(InputEventType.CHANGE, getFormDataEventWithFormattedCPFValue(event));
  };

  const emitInput = (event: VInputInputEvent): void => {
    emit(InputEventType.INPUT, getFormDataEventWithFormattedCPFValue(event));
  };

  const emitFocus = (event: VInputInputEvent): void => {
    emit(InputEventType.FOCUS, getFormDataEventWithFormattedCPFValue(event));
  };

  const emitBlur = (event: VInputInputEvent): void => {
    emit(InputEventType.BLUR, getFormDataEventWithFormattedCPFValue(event));
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
}

export default useCPFNumberEvents;
