import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';

import { getHasImageObject, getIconName } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/composables/utils';

import type { BonusLoyaltyProgramCardEmits, BonusLoyaltyProgramCardProps } from '../types';

export interface BonusLoyaltyProgramCardComposable {
  iconName: ComputedRef<IconNameType>;
  onBonusLoyaltyProgramCardClick(): void;
  hasImageObject: ComputedRef<boolean>;
}

export default function useBonusLoyaltyProgramCard(
  props: BonusLoyaltyProgramCardProps,
  emit: BonusLoyaltyProgramCardEmits,
): BonusLoyaltyProgramCardComposable {
  const iconName = computed<IconNameType>(() => getIconName(props));

  const hasImageObject = computed<boolean>(() => getHasImageObject(props));

  const onBonusLoyaltyProgramCardClick = (): void => {
    if (!props.isLocked) {
      emit('click');
    }
  };

  return {
    iconName,
    onBonusLoyaltyProgramCardClick,
    hasImageObject,
  };
}
