import { isString, isValidInterface } from '@leon-hub/guards';
import { Storage } from '@leon-hub/storage';

import type { ShieldStorage } from '../types';

export function getStorage(): Storage<ShieldStorage> {
  return new Storage({
    id: 'shield-integration',
    guard: (value: unknown): value is ShieldStorage => isValidInterface(value, { session_id: isString, helmetId: isString }),
  });
}
