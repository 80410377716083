<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconDirection, IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type {
  Market,
  Sport,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import {
  useMarketColumns,
  useSportlineEventLink,
} from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useMainMarketSettings } from 'web/src/modules/sportline/composables/settings';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import InListRunner from 'web/src/modules/sportline/views/runner/InListRunner.vue';

import MainMarketContainer from './components/MainMarketContainer.vue';

interface SportlineOutrightEventMainMarketProps {
  sportlineEvent: SportlineEvent;
  sport: Sport;
  activeEventId?: Maybe<string>;
  basisKey: SportlineFragmentBasisKey;
}

interface SportlineOutrightEventMainMarketEmits {
  (e: 'click'): void;
}

const props = defineProps<SportlineOutrightEventMainMarketProps>();
const emit = defineEmits<SportlineOutrightEventMainMarketEmits>();

const sportlineEvent = toRef(props, 'sportlineEvent');
const sport = toRef(props, 'sport');
const basisKey = toRef(props, 'basisKey');
const activeEventId = toRef(props, 'activeEventId', null);

const sportlineEventId = computed(() => sportlineEvent.value.id);
const markets = computed<Market[]>(() => sportlineEvent.value.markets);
const counter = computed<number>(() => sportlineEvent.value.moreCount.runners ?? 0);

const { doUseFullAlternativeMarketsList } = useMainMarketSettings();
const {
  isOutright,
  sportlineType,
} = useSportlineEventType({ sportEvent: sportlineEvent });
const {
  href,
  onLinkClick,
} = useSportlineEventLink({
  sportEvent: sportlineEvent,
  activeEventId,
}, {
  onClick(): void { emit('click'); },
});
const {
  selectedColumnId,
  selectedColumnSize,
  columns,
} = useInjectSelectedColumn({ basisKey });
const {
  actualMarketType,
  actualMarket,
  actualMarketTypePriority,
} = useMarketColumns({
  sport,
  selectedColumnSize,
  selectedColumnId,
  columns,
  markets,
  doUseFullAlternativeMarketsList,
});
const {
  runnersPlaces,
} = useMarketRunners({
  sport,
  market: actualMarket,
  marketType: actualMarketType,
});
const { slipCaption } = useSlipCaption({ sportlineEvent });
const {
  onCatalogClick,
} = useSportlineCatalogClick({ eventId: sportlineEventId });
</script>

<template>
  <MainMarketContainer v-auto-id="'SportlineOutrightEventMainMarket'"
    :actual-market="actualMarket"
    :actual-market-type="actualMarketType"
    :actual-market-type-priority="actualMarketTypePriority"
    :runners-places-count="runnersPlaces.length"
  >
    <template v-if="actualMarket">
      <InListRunner
        v-for="(runner, index) in runnersPlaces"
        :key="runner?.id ?? index"
        :runner="runner"
        :market="actualMarket"
        :slip-caption="slipCaption"
        :sport-event-id="sportlineEventId"
        :sportline-type="sportlineType"
        :is-outright-event="isOutright"
        do-display-name
      />
    </template>

    <a
      v-if="'1'"
      :href="href"
      :class="$style['more-indicator']"
      @click="onCatalogClick($event);onLinkClick($event)"
    >
      <div :class="$style['sportline-event-markets-counter']">
        <div v-if="counter">
          {{ counter }}
        </div>
        <div :class="$style['sportline-event-markets-counter__chevron']">
          <VIcon
            :name="IconName.CHEVRON"
            :direction="IconDirection.EAST"
            :size="IconSize.SIZE_16"
          />
        </div>
      </div>
    </a>
  </MainMarketContainer>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-event-list-columns';

@include for-layout using ($isDesktop) {
  .more-indicator {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: get($runnerColumnWidth, more);
    height: 100%;
    padding-right: 8px;
  }

  .sportline-event-markets-counter {
    @include medium\14\16\025;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    color: var(--TextDefault);

    &__chevron {
      @include medium\14\16\025;

      color: var(--TextPrimary);
      text-decoration: none;
    }
  }
}
</style>
