<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';

import type { MarketsListGroupEmits, MarketsListGroupProps } from '../../types';
import useMarketsListMarketGroup from '../composables/useMarketsListMarketGroup';
import MarketsListMarket from './MarketsListMarket.vue';

const props = defineProps<MarketsListGroupProps>();
const emit = defineEmits<MarketsListGroupEmits>();

const group = toRef(props, 'group');
const sportEvent = toRef(props, 'sportlineEvent', null);

const {
  isSportEventSuspended,
  onMarketGroupToggle,
} = useMarketsListMarketGroup({ group, sportEvent }, emit);
</script>

<template>
  <section v-auto-id="'MarketGroup'"
    :key="group.id"
    :class="$style['sport-event-details-market-group']"
  >
    <article :class="$style['sport-event-details-market-group__wrapper']">
      <div
        :class="{
          [$style['sport-event-details-market-group__header']]: true,
          [$style['sport-event-details-market-group__header--closed']]: !group.isOpened,
        }"
        @click="onMarketGroupToggle(group)"
      >
        <div
          :class="{
            [$style['market-group-title']]: true,
            [$style['market-group-title--closed']]: !group.isOpened && group.hasZeroMargin,
          }"
        >
          <span
            :class="$style['market-group-title__label']"
          >{{ group.title }}</span>

          <div
            v-if="group.hasZeroMargin"
            :class="{
              [$style['market-group-title__zero-margin']]: true,
              [$style['market-group-title__zero-margin--closed']]: !group.isOpened,
            }"
          >
            <HighestOddsBadge
              badge-background="default"
              highest-odds-icon-color="highlight"
            />
          </div>
        </div>

        <div :class="$style['sport-event-details-market-group__indicator']">
          <VIcon
            :name="group.isOpened ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
            :size="IconSize.SIZE_16"
          />
        </div>
      </div>
      <div
        v-show="group.isOpened"
        :class="$style['sport-event-details-market-group__content']"
      >
        <template v-if="sportEvent">
          <MarketsListMarket
            :key="group.id"
            :class="{
              [$style['sport-event-details-market-group__market']]: true,
              [$style['sport-event-details-market-group__market--hidden']]: isSportEventSuspended,
            }"
            :group="group"
            :sportline-event="sportEvent"
          />
        </template>
      </div>
    </article>
  </section>
</template>

<style module lang="scss">
.sport-event-details-market-group {
  $borderRadius: 5px;

  padding: 0;
  margin: 0;
  background-color: var(--Layer1);
  border-radius: $borderRadius;

  &__wrapper {
    background-color: var(--Layer1);
    border-radius: $borderRadius;
  }

  &__header {
    @include medium\14\20\025;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px;
    padding-left: 0;
    color: var(--TextDefault);
    background-color: var(--Layer1);
    border-radius: $borderRadius $borderRadius 0 0;

    &--closed {
      color: var(--TextPrimary);
      border-radius: $borderRadius;

      @include for-hover {
        &:hover {
          color: var(--TextDefault);
          background-color: var(--Highlight);
        }
      }
    }

    @include is-app-layout-desktop {
      cursor: pointer;
    }
  }

  &__content {
    background-color: var(--Layer1);
    border-radius: 0 0 $borderRadius $borderRadius;
  }

  &__market {
    border-radius: 0 0 $borderRadius $borderRadius;

    &--hidden {
      opacity: 0.3;
    }
  }

  &__indicator {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
  }
}

.market-group-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 12px 44px;
  text-align: center;

  &--closed {
    padding-bottom: 20px;
  }

  &__label {
    vertical-align: middle;
  }

  &__zero-margin {
    position: absolute;
    bottom: -4px;

    &--closed {
      bottom: 4px;
    }
  }
}
</style>
