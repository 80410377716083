<script lang="ts" setup>
import { computed, ref } from 'vue';

import type {
  VTabsButtonEmits,
  VTabsButtonProps,
  VTabsButtonRef,
  VTabsButtonSlots,
} from './types';
import LTabsButton from '../LTabsButton/LTabsButton.vue';
import STabsButton from '../STabsButton/STabsButton.vue';
import { TabsType } from '../VTabs/enums';
import { useVTabsButton } from './useVTabsButton';

const props = withDefaults(defineProps<VTabsButtonProps>(), {
  type: TabsType.NORMAL,
});
const emit = defineEmits<VTabsButtonEmits>();
defineSlots<VTabsButtonSlots>();

const stab = ref<VTabsButtonRef | null>(null);
const ltab = ref<VTabsButtonRef | null>(null);
const tab = computed<VTabsButtonRef | null>(() => ltab.value ?? stab.value);

const { remapProps } = useVTabsButton();

function getOffsetLeft(): number {
  return tab.value?.getOffsetLeft() || 0;
}

defineExpose<VTabsButtonRef>({
  getOffsetLeft,
});
</script>

<template>
  <STabsButton
    v-if="null"
    v-bind="remapProps(props)"
    ref="stab"
    @click="emit('click', $event)"
  >
    <template
      v-if="$slots.default"
      #default
    >
      <slot />
    </template>
  </STabsButton>
  <LTabsButton
    v-else
    v-bind="remapProps(props)"
    ref="ltab"
    @click="emit('click', $event)"
  >
    <template
      v-if="$slots.default"
      #default
    >
      <slot />
    </template>
  </LTabsButton>
</template>
