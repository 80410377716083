<script lang="ts" setup>
import {
  onMounted,
  ref,
} from 'vue';

import type {
  FocusableInputRef,
  FocusOptions,
} from '@leon-hub/focus';

import { Currency } from '@core/money';

import { PaymentInput } from 'web/src/components/Input';
import AmountTextInput from 'web/src/components/Input/components/AmountTextInput/AmountTextInput.vue';
import { PAYMENTS_DEFAULT_INPUT_VALUE } from 'web/src/modules/payments/constants';
import usePaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/composables/usePaymentsInput';

import type { PaymentsInputEmits, PaymentsInputProps } from './types';

const props = withDefaults(defineProps<PaymentsInputProps>(), {
  minAmount: 0,
  maxAmount: 0,
  currency: Currency.RUB,
  inputValue: PAYMENTS_DEFAULT_INPUT_VALUE,
  allowedIncrement: 0,
  defaultInitInputValue: 0,
  name: 'PaymentsInput',
  isCurrentValueFromSuggestedAmounts: true,
});
const emit = defineEmits<PaymentsInputEmits>();

const {
  onFocus,
  onChange,
  onInput,
  onBlur,
  inputLabel,
  hintRightMessage,
  errorLeftMessage,
  errorRightMessage,
  placeholderText,
  formatMoneyOptions,
  currentValue,
  formattedBalance,
  paymentsInputMounted,
} = usePaymentsInput(props, emit);

onMounted(paymentsInputMounted);

const input = ref<FocusableInputRef>();
async function focus(options?: FocusOptions): Promise<void> {
  await input.value?.focus(options);
}

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <AmountTextInput
    v-if="null && !isCryptoWithdrawal"
    ref="input"
    :autofocus="autofocus"
    :name="name"
    :value="currentValue"
    :maximum-fraction-digits="deposit ? 0 : 2"
    :error="errorLeftMessage"
    :has-error="!!(errorLeftMessage || errorRightMessage)"
    :hint="hintRightMessage"
    :disabled="disabled"
    @input="onInput($event.target.value)"
    @change="onChange($event.target.value)"
    @blur="onBlur"
  />
  <PaymentInput
    v-else
    :autofocus="autofocus"
    :name="name"
    :value="currentValue"
    :error="errorLeftMessage"
    :error-right="errorRightMessage"
    :maximum-fraction-digits="deposit ? 0 : 2"
    :is-label-hidden="!!null"
    :is-hint-hidden="!!null"
    :label="inputLabel"
    :hint-right="hintRightMessage"
    :placeholder="placeholderText"
    :format-money-options="formatMoneyOptions"
    :disabled="disabled"
    @blur="onBlur"
    @change="onChange($event.target.value)"
    @input="onInput($event.target.value)"
    @focus="onFocus"
  />
  <div
    v-if="null && isCryptoWithdrawal"
    :class="$style['payments-input']"
  >
    <span
      v-if="errorLeftMessage || errorRightMessage"
      :class="$style['payments-input__error']"
    >
      {{ errorLeftMessage || errorRightMessage }}
    </span>
    <span
      v-else
      :class="$style['payments-input__balance']"
    >
      <span
        :class="$style['payments-input__balance-label']"
      >
        {{ $t('WEB2_WITHDRAWALS_AVAILABLE_BALANCE') }}
      </span>
      <span
        :class="$style['payments-input__balance-value']"
      >
        {{ formattedBalance }}
      </span>
    </span>
  </div>
</template>

<style lang="scss" module>
.payments-input {
  display: inline-flex;
  padding: 0 12px;

  &__error {
    @include font-caption1-c1;

    color: var(--ColorContent5);
  }

  &__balance {
    padding: 4px 0;

    &-label {
      @include font-caption1-c1;

      color: var(--ColorContent1a);
    }

    &-value {
      @include font-caption1-c2;

      padding-left: 4px;
      color: var(--ColorContent2);
    }
  }
}
</style>
