import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useIsLoggedIn } from '@core/auth';

import type { CustomerFavoritesIdsBackgroundUpdate } from 'web/src/modules/sportline/submodules/favorites/types';
import type { CustomerMatchStreams } from 'web/src/modules/sportline/types/rest';
import { useIsStreamsEnabledRef } from 'web/src/modules/sportline/composables/settings';
import { useSyncSportlineWithoutCTag } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useStreamEventsIdsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { getCustomerStreamsRequest } from 'web/src/modules/sportline/utils/api';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

import { createMatchStreamAccessRef } from '../utils/createMatchStreamAccessRef';

export const useCustomerStreamEventsStore = defineStore('sportline-customer-stream-events', () => {
  const isStreamsEnabled = useIsStreamsEnabledRef();
  const { timeout: streamEventsIdsInterval } = useStreamEventsIdsSportlineUpdateTimeout();
  const { isLoggedIn } = useIsLoggedIn();

  const rawCustomerMatchStreamEvents = ref<CustomerMatchStreams>([]);
  const isBackgroundIdsUpdateAvailableSet = ref<CustomerFavoritesIdsBackgroundUpdate>({});

  const isSyncIdsAvailable = computed(() => (
    Object.values(isBackgroundIdsUpdateAvailableSet.value).some((enable) => enable)
  ));

  const matchStreamAccess = createMatchStreamAccessRef(isStreamsEnabled, rawCustomerMatchStreamEvents);

  async function getCustomerMatchStreamEvents(): Promise<void> {
    if (!isStreamsEnabled.value) {
      rawCustomerMatchStreamEvents.value = [];
      return;
    }

    const response = await getCustomerStreamsRequest({ silent: true });

    rawCustomerMatchStreamEvents.value = response?.customerStreams
      ? [...response.customerStreams]
      : [];
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['streamEventsIds']>({
    streamEventsIds: async () => {
      await getCustomerMatchStreamEvents();
      lastUpdate.update('streamEventsIds');
    },
  });

  async function initialRequests(): Promise<void> {
    await lastUpdate.forceCallSyncAction('streamEventsIds', { silent: true });
  }

  async function syncState({ silent }: { silent?: boolean }): Promise<void> {
    if (!isSyncIdsAvailable.value) {
      return;
    }
    await lastUpdate.callSyncAction('streamEventsIds', streamEventsIdsInterval.value, { silent });
  }

  function setIsBackgroundIdsUpdateAvailable(value: CustomerFavoritesIdsBackgroundUpdate): void {
    isBackgroundIdsUpdateAvailableSet.value = { ...isBackgroundIdsUpdateAvailableSet.value, ...value };
    if (value) {
      void syncState({ silent: true });
    }
  }

  // init store
  watch(isLoggedIn, (value: boolean) => {
    if (value) {
      void initialRequests();
      return;
    }

    // clear store on logout
    rawCustomerMatchStreamEvents.value = [];
  }, { immediate: true });

  useSyncSportlineWithoutCTag((silent) => syncState({ silent }), streamEventsIdsInterval, { condition: isSyncIdsAvailable });

  return {
    initialRequests,
    setIsBackgroundIdsUpdateAvailable,
    matchStreamAccess,
  };
});
