import type { VInputInputEvent } from '@leon-hub/input-types';
import { InputEventType } from '@leon-hub/input-types';

import type { BaseFormWidgetEmits } from 'web/src/components/Form/types';
import { inputExpirationDateToFormDataEvent } from 'web/src/components/Form/utils';

interface UseExpirationDateInputEvents {
  emitChange(event: VInputInputEvent): void;
  emitInput(event: VInputInputEvent): void;
  emitFocus(event: VInputInputEvent): void;
  emitBlur(event: VInputInputEvent): void;
}

function useExpirationDateInputEvents(emit: BaseFormWidgetEmits): UseExpirationDateInputEvents {
  const emitChange = (event: VInputInputEvent): void => {
    emit(InputEventType.CHANGE, inputExpirationDateToFormDataEvent(event));
  };

  const emitInput = (event: VInputInputEvent): void => {
    emit(InputEventType.INPUT, inputExpirationDateToFormDataEvent(event));
  };

  const emitFocus = (event: VInputInputEvent): void => {
    emit(InputEventType.FOCUS, inputExpirationDateToFormDataEvent(event));
  };

  const emitBlur = (event: VInputInputEvent): void => {
    emit(InputEventType.BLUR, inputExpirationDateToFormDataEvent(event));
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
}

export default useExpirationDateInputEvents;
