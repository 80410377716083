import { ApplicationRestClient } from '@core/app-rest-client';

export default class FastTrackApiClient extends ApplicationRestClient {
  constructor() {
    super({
      baseUrl: '',
      method: 'POST',
      credentials: 'same-origin',
      origin: '',
    });
  }
}
