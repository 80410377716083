import type { Router } from 'vue-router';

import { getLocationOrigin } from '@leon-hub/service-locator-env';

import type { AppVueRouter } from '@core/router';

export default function createGetHref(router: Router): AppVueRouter['getHref'] {
  return (to) => {
    const route = router.resolve(to);

    return {
      relative: route.href,
      absolute: `${getLocationOrigin()}${route.href}`,
    };
  };
}
