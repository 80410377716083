import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

export default function getButtonProps(label: string): VButtonProps {
  return {
    label,
    kind: ButtonKind.CANCEL,
    height: ButtonHeight.TINY_EXTRA,
  };
}
