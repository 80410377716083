function getPreviousSlide(active: number, last: number): number {
  return active === 0 ? last : active - 1;
}
function getNextSlide(active: number, last: number): number {
  return active === last ? 0 : active + 1;
}

function filterUnique(values: number[]): number[] {
  return values.filter((value, index, list) => list.indexOf(value) === index);
}

export function getReadySlides (bannersCount: number, activeSlideIndex: number): number[] {
  if (!bannersCount) {
    return [];
  }
  const lastIndex = bannersCount - 1;
  return filterUnique([
    getPreviousSlide(activeSlideIndex, lastIndex),
    activeSlideIndex,
    getNextSlide(activeSlideIndex, lastIndex),
  ]);
}
