import { isObject } from '@leon-hub/guards';

import type { UploadDocumentResponse } from './types';

export function isUploadResponse(argument: unknown): argument is UploadDocumentResponse {
  return isObject(argument)
    && typeof argument.result === 'string'
    && (!argument.uploadResult || typeof argument.uploadResult === 'string')
    && (!argument.errorStatus || typeof argument.errorStatus === 'string')
    && (!argument.msg || typeof argument.msg === 'string');
}
