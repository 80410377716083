import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { CombinedTabItemsProps } from 'web/src/components/Tabs/types';

import type { VTabsProps } from '../types';

export interface VTabsComposable {
  containerRole: Ref<string>;
  isIconTabs: Ref<boolean>;
  activeTabIndex: Ref<number>;
  itemsList: Ref<CombinedTabItemsProps[]>;
}

export default function useVTabs(props: VTabsProps): VTabsComposable {
  const items = toRef(() => props.items);
  const activeId = toRef(() => props.activeId);

  const containerRole = computed<string>(() => (
    props.isNavigation ? 'navigation' : 'tablist'
  ));

  const isIconTabs = computed<boolean>(() => {
    if (!items.value?.length) { return false; }
    const tab = items.value[0];
    return tab && 'icon' in tab && !!tab.icon;
  });

  const activeTabIndex = computed<number>(() => (items.value?.findIndex((item) => item.id === activeId.value) ?? -1));

  const itemsList: ComputedRef<CombinedTabItemsProps[]> = computed(() => items.value?.map((item) => {
    const { isNavigation } = props;
    return {
      ...item,
      active: item.id === activeId.value,
      isNavigation,
    } as CombinedTabItemsProps;
  }) ?? []);

  return {
    activeTabIndex,
    containerRole,
    isIconTabs,
    itemsList,
  };
}
