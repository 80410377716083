import type { Ref } from 'vue';
import { nextTick, ref } from 'vue';

import type { FormUiSchema } from '../../../types';
import getDefaultAutofocus from './utils/getDefaultAutofocus';

interface UseFormAutofocus {
  focusedElementId: Ref<string | undefined>;
  focusStart(): void;
  focus(): void;
  focusByName(name: string): void;
  focusByIndex(index: number): void;
}

export default function useFormAutofocus(
  uiSchema: Ref<FormUiSchema>,
): UseFormAutofocus {
  const focusedElementId = ref<string | undefined>(getDefaultAutofocus(uiSchema.value));

  const focusField = (name?: string): void => {
    const lastFocus = name ?? focusedElementId.value ?? Object.keys(uiSchema.value.fields ?? {})[0];
    if (lastFocus) {
      focusedElementId.value = undefined;
      void nextTick().then(() => {
        focusedElementId.value = lastFocus;
      });
    }
  };

  const defaultFocus = (): void => {
    focusField();
  };

  const focusByName = (name: string): void => {
    if (Object.keys(uiSchema.value.fields ?? {}).includes(name)) {
      focusField(name);
    }
  };

  const focusByIndex = (index: number): void => {
    const keys = Object.keys(uiSchema.value.fields ?? {});
    const matchedIndex = keys[index];
    if (matchedIndex) {
      focusField(matchedIndex);
    }
  };

  const focusStart = (): void => {
    if (focusedElementId.value) {
      defaultFocus();
    }
  };

  return {
    focusedElementId,
    focus: defaultFocus,
    focusByName,
    focusByIndex,
    focusStart,
  };
}
