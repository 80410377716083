<script lang="ts" setup>
import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';
import { useBaseFormWidgetEvents } from 'web/src/components/Form/composables';
import PaymentsFastSumInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/PaymentsFastSumInput.vue';

import useFastSum from './useFastSum';

const props = defineProps<FormWrapperWidgetProperties<PaymentsFastSumInputProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const { componentProps } = useFastSum(props);

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);
</script>

<template>
  <PaymentsFastSumInput v-auto-id="'FastSum'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
