import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import searchSelectOptions from 'web/src/utils/search/searchSelectOptions';

import type { VSelectOption } from '../types';

interface UseDropdownList {
  allPossibleOptions: ComputedRef<VSelectOption[]>;
  optionsToRender: ComputedRef<VSelectOption[]>;
  renderMoreItems(): void;
  resetPagination(): void;
  setPaginationToReachIndex(index: number): void;
}

interface UseDropdownListProps {
  options: Ref<VSelectOption[] | undefined>;
  enableSearch: Ref<boolean | undefined>;
  hideSelectedOption: Ref<boolean | undefined>;
  selectedValue: Ref<string | undefined>;
  itemsPerPage: Ref<number | undefined>;
  searchQuery: Ref<string>;
}

export default function useDropdownList(
  {
    options,
    enableSearch,
    hideSelectedOption,
    searchQuery,
    selectedValue,
    itemsPerPage,
  }: UseDropdownListProps,
): UseDropdownList {
  const allPossibleOptions = computed<VSelectOption[]>(() => {
    if (enableSearch.value && options.value) {
      if (searchQuery.value) {
        return searchSelectOptions(options.value, searchQuery.value);
      }
      if (selectedValue?.value) {
        const matchedIndex = options.value.findIndex(({ value }) => value === selectedValue?.value);
        if (matchedIndex > -1) {
          const head = options.value.slice(0, matchedIndex);
          const tail = options.value.slice(matchedIndex + 1);
          return [
            options.value[matchedIndex],
            ...head,
            ...tail,
          ];
        }
      }
    }
    if (hideSelectedOption.value && !enableSearch.value && options.value) {
      return (options.value || []).filter((option) => option.value !== selectedValue.value);
    }
    return options.value ?? [];
  });

  const paginationCounter = ref<number>(1);

  const safeItemsPerPage = computed<number>(() => itemsPerPage.value || 20);

  const renderCount = computed<number>(() => {
    if (allPossibleOptions.value.length <= safeItemsPerPage.value) {
      return allPossibleOptions.value.length;
    }
    const safeCounter = paginationCounter.value > 0 ? paginationCounter.value : 1;
    const count = safeCounter * safeItemsPerPage.value;
    if (count > allPossibleOptions.value.length) {
      return allPossibleOptions.value.length;
    }
    return count;
  });

  const optionsToRender = computed<VSelectOption[]>(() => allPossibleOptions.value.slice(0, renderCount.value));

  const renderMoreItems = () => {
    if (renderCount.value < allPossibleOptions.value.length) {
      paginationCounter.value += 1;
    }
  };

  const setPaginationToReachIndex = (index: number) => {
    const nextPage = Math.ceil(index / safeItemsPerPage.value);
    if (paginationCounter.value < nextPage) {
      paginationCounter.value = nextPage;
    }
  };

  const resetPagination = () => {
    paginationCounter.value = 1;
  };

  return {
    allPossibleOptions,
    optionsToRender,
    renderMoreItems,
    resetPagination,
    setPaginationToReachIndex,
  };
}
