import type { ComputedRef } from 'vue';

import type { I18nFunctionReactive } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type { VFeedbackCompositionProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';

export default function getSubmitButtonProps(
  props: VFeedbackCompositionProps,
  isSubmitButtonDisabled: ComputedRef<boolean>,
  $t: I18nFunctionReactive,
): VButtonProps {
  const processValue = $t('WEB2_PROCESSING').value;
  const sendValue = $t('WEB2_BUTTON_SEND').value;

  return {
    label: props.submitting ? processValue : sendValue,
    kind: ButtonKind.PRIMARY,
    isLoading: props.submitting,
    disabled: isSubmitButtonDisabled.value,
    fullWidth: true,
  };
}
