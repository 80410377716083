<script lang="ts" setup>
import type {
  FormDataEvent,
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import type {
  PaymentsCreditCardSelectorProps,
} from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/types';
import ThePaymentsCreditCardSelector

  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/ThePaymentsCreditCardSelector.vue';

interface Emits {
  (e: 'input', event: FormDataEvent): void;
}

const props = defineProps<FormWrapperWidgetProperties<PaymentsCreditCardSelectorProps>>();
const emit = defineEmits<Emits>();

function emitChange (id: string): void {
  emit('input', { name: props.name, value: id });
}
</script>

<template>
  <ThePaymentsCreditCardSelector v-auto-id="'CreditCardSelector'"
    :selected-credit-card-token="value"
    :credit-cards="options?.creditCards ?? []"
    :allow-new-card="options?.allowNewCard ?? false"
    @input="emitChange"
  />
</template>
