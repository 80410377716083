import type { Ref } from 'vue';
import {
  computed,
  getCurrentInstance,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import type { ITheme } from '@leon-hub/api-sdk';
import type { IconNameType } from '@leon-hub/icons';
import type { NavigationItem } from '@leon-hub/navigation-config';
import {
  CustomerConfig,
  PinCodePlace,
  PinCodeState as PinCodeStateEnum,
  themeAuto,
} from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useCustomerDataStore } from '@core/customer';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useDynamicTranslation, useI18n, useI18nLanguage } from '@core/i18n';
import { useRootStore } from '@core/root';
import { useSiteConfigStore } from '@core/site-config';
import { useStorageManagerStore } from '@core/storage';
import { useTheme, useThemeStore } from '@core/theme';
import { useUserStore } from '@core/user';

import { LanguageSelectorPlace } from '@modules/localization-utilities';

import type { PinCodeStep } from 'web/src/modules/pin-code/store/types';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import { useUserNavigationStore } from 'web/src/modules/user/submodules/navigation/store';

import useBiometricAuthSettings from './useBiometricAuthSettings';

interface UserSettingsPageComposable {
  isOddsFormatSwitcherEnabled: Ref<boolean>;
  userSettingsNavigation: Ref<readonly NavigationItem[]>;
  isLanguageSwitcherAvailable: Ref<boolean>;
  currentLanguageFlag: Ref<string>;
  currentLanguageName: Ref<string>;
  themeLabel: Ref<string>;
  isHiddenBalance: Ref<boolean>;
  isShowPinCode: Ref<boolean>;
  pinCodeRequestedPlaces: Ref<readonly PinCodePlace[] | null>;
  showLoginPinCode: Ref<boolean>;
  hasPinCode: Ref<boolean>;
  hasLoginPinCode: Ref<boolean>;
  isFingerprintSupported: Ref<Optional<boolean>>;
  isFingerprintAuthenticationEnabled: Ref<Optional<boolean>>;
  openLanguage(): void;
  getIconSettings(item: NavigationItem): Optional<IconNameType>;
  handleChangeTheme(theme: ITheme): Promise<void>;
  changeHiddenBalance(value: boolean): Promise<void>;
  onClickChangeHiddenBalance(): Promise<void>;
  onItemClick(item: NavigationItem): void;
  switchPinCode(): void;
  loginPinCode(): void;
  changePinCode(): void;
  changeFingerSettings(): void;
}

export default function useUserSettingsPage(): UserSettingsPageComposable {
  const { $translate } = useI18n();
  const { setLanguageSelectorPlace } = useI18nLanguage();
  const analytics = useAnalytics();
  const router = useRouter();
  const { showDialog } = useDialogs();
  const { isAuto, theme } = useTheme();
  const rootStore = useRootStore();
  const userStore = useUserStore();
  const customerDataStore = useCustomerDataStore();
  const siteConfigStore = useSiteConfigStore();
  const { copy } = useCopyToClipboard();
  const { isOddsFormatSwitcherEnabled } = useOddsSettings();

  const userSettingsNavigation = toRef(useUserNavigationStore(), 'userProfileSettingsNavigation');
  const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');
  const currentLanguageName = toRef(rootStore, 'currentLanguageName');
  const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
  const isHiddenBalance = toRef(customerDataStore, 'isHiddenBalance');
  const isPinCodeRequestedOnLogin = toRef(customerDataStore, 'isPinCodeRequestedOnLogin');
  const pinCodeRequestedPlaces = toRef(() => siteConfigStore.pinCodeRequestedPlaces);

  const storageManager = useStorageManagerStore();
  const pinCodeModule = usePinCodeStore();

  const {
    isFingerprintSupported,
    isFingerprintAuthenticationEnabled,
    changeFingerSettings,
  } = useBiometricAuthSettings();

  function openLanguage(): void {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      setLanguageSelectorPlace(LanguageSelectorPlace.SETTINGS);
      void router.push({ name: RouteName.LANGUAGE });
    }
  }

  function getIconSettings(item: NavigationItem): Optional<IconNameType> {
    return item.icon?.name;
  }

  const translate = useDynamicTranslation();

  const themeLabel = computed(() => {
    const value = isAuto.value
      ? themeAuto
      : theme.value;
    return translate('WEB2_CORDOVA_THEME_$', value);
  });

  function handleChangeTheme(themeValue: ITheme): Promise<void> {
    return useThemeStore().changeTheme(themeValue);
  }

  async function changeHiddenBalance(value: boolean): Promise<void> {
    const hideBalance = value ? 'on' : 'off';
    analytics.push(AnalyticsEvent.Z_HIDE_BALANCE, {
      hideBalance,
    });
    await userStore.setAdditionalPropsUser({
      value: {
        value,
      },
      config: CustomerConfig.HIDE_BALANCE,
    });
    await userStore.loadUserData(true);
  }

  async function onClickChangeHiddenBalance() {
    await changeHiddenBalance(!isHiddenBalance.value);
  }

  const showLoginPinCode = computed(() => !!pinCodeRequestedPlaces.value?.includes(
    PinCodePlace.PIN_CODE_AT_LOGIN_SETTING,
  ));

  const hasPinCode = computed(() => !!pinCodeModule.status && pinCodeModule.status !== PinCodeStateEnum.RESET);

  const hasLoginPinCode = computed(() => !!pinCodeModule.pinCodeRequestedAtLogin);

  const pinCodeLoading = computed(() => pinCodeModule.loading);

  const isShowPinCode = computed(() => {
    if (!pinCodeRequestedPlaces.value?.length)
      return false;
    return !(pinCodeRequestedPlaces.value?.length === 1 && pinCodeRequestedPlaces.value[0] === PinCodePlace.UNKNOWN);
  });

  function clearLocalStorage(): void {
    showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_CLEAR_CACHE').value,
        confirmMessage: $translate('WEB2_CLEAR_CACHE_MESSAGE').value,
        buttons: [
          {
            label: $translate('WEB2_CLEAR').value,
          },
          {
            label: $translate('WEB2_MOBILE_CANCEL').value,
          },
        ],
        dataTestId: 'clear-cache',
      },
    }).subscribe({
      [DialogAction.CONFIRM]: () => {
        storageManager.clearCache();
        const instance = getCurrentInstance();
        instance?.proxy?.$forceUpdate();
        if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
          void copy({
            notificationText: $translate('WEB2_CLEAR_CACHE_SUCCESS').value,
          });
        } else {
          showDialog({
            presetName: PresetName.ALERT_SUCCESS_NO_BUTTONS,
            options: {
              title: $translate('WEB2_CLEAR_CACHE_SUCCESS').value,
              dataTestId: 'clear-cache-success',
            },
          });
        }
      },
    });
  }

  function onItemClick(item: NavigationItem): void {
    if (item.props?.isCacheCleaner) {
      clearLocalStorage();
    } else {
      void router.push({
        name: item.routeName,
      });
    }
  }

  function switchPinCode(): void {
    if (!pinCodeLoading.value) {
      pinCodeModule.setRouteFrom({ name: RouteName.CUSTOMER_SETTINGS });

      let step: PinCodeStep = 'CREATE';

      if (hasPinCode.value) {
        step = 'DELETE';
      } else if (!pinCodeModule.wasPinCodeSetOnce) {
        step = 'MODAL_CREATE';
      }

      pinCodeModule.setStepWithLS(step);

      void router.replace({ name: RouteName.PIN_CODE_PROFILE });
    }
  }

  function changePinCode(): void {
    pinCodeModule.setRouteFrom({ name: RouteName.CUSTOMER_SETTINGS });
    pinCodeModule.setStepWithLS('UPDATE_PREV');
    void router.replace({ name: RouteName.PIN_CODE_PROFILE });
  }

  function loginPinCode(): void {
    pinCodeModule.setPinCodePlace(PinCodePlace.PIN_CODE_AT_LOGIN_SETTING);
    pinCodeModule.setRouteFrom({ name: RouteName.CUSTOMER_SETTINGS });
    pinCodeModule.setStepWithLS('VERIFY');
    void router.replace({ name: RouteName.PIN_CODE_PROFILE });
  }

  watch(isPinCodeRequestedOnLogin, () => {
    pinCodeModule.setPinCodeRequestedAtLogin();
  });

  return {
    isOddsFormatSwitcherEnabled,
    userSettingsNavigation,
    isLanguageSwitcherAvailable,
    currentLanguageName,
    currentLanguageFlag,
    themeLabel,
    isHiddenBalance,
    isShowPinCode,
    pinCodeRequestedPlaces,
    showLoginPinCode,
    hasPinCode,
    hasLoginPinCode,
    isFingerprintSupported,
    isFingerprintAuthenticationEnabled,
    openLanguage,
    getIconSettings,
    handleChangeTheme,
    changeHiddenBalance,
    onItemClick,
    switchPinCode,
    changePinCode,
    loginPinCode,
    changeFingerSettings,
    onClickChangeHiddenBalance,
  };
}
