import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  CustVerifFromLbCustomerRequest,
  VerificationTokenFieldsFragment,
} from '@leon-hub/api-sdk';
import {
  getVerificationReasons,
  makeCustomerVerification,
} from '@leon-hub/api-sdk';
import {
  assert,
  isArrayOf,
} from '@leon-hub/guards';

import { useGraphqlClient } from '@core/app-rest-client';

import type { VerificationReason } from 'web/src/modules/profile/submodules/verification/store/types';
import { isVerificationReason } from 'web/src/modules/profile/submodules/verification/store/guards';

export const useVerificationDefaultStore = defineStore('verification-default', () => {
  const api = useGraphqlClient();

  const reasons = ref<VerificationReason[]>([]);

  async function fetchReasons(): Promise<void> {
    const response = await getVerificationReasons(api, (node) => node.queries.verification.getReasons, {
      options: {},
    });

    const responseReasons = response.reasons.filter((reason) => !!reason.id && !!reason.localizedName);
    assert(isArrayOf<VerificationReason>(isVerificationReason, responseReasons));

    reasons.value = responseReasons;
  }

  function makeVerification(request: CustVerifFromLbCustomerRequest): Promise<VerificationTokenFieldsFragment> {
    return makeCustomerVerification(api, (node) => node.mutations.verification.makeCustomerVerification, {
      options: request,
    });
  }

  return {
    reasons,
    fetchReasons,
    makeVerification,
  };
});
