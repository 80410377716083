import type { RouteRecordName } from 'vue-router';
import {
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  toRef,
  watch,
} from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

import { useRouterStore } from '@core/router';

import { useKeepAliveStore } from 'web/src/modules/core/store';

export function useKeepAlive(routeNames?: BaseRouteNameType[]): void {
  const currentRouteName = toRef(useRouterStore(), 'currentRouteName');
  const { addKeepAliveComponent, removeKeepAliveComponent } = useKeepAliveStore();

  let componentName: string | undefined;
  let mountedRouteName: RouteRecordName | null | undefined;

  onMounted(() => {
    componentName = getCurrentInstance()?.type?.__name;
    mountedRouteName = currentRouteName.value;

    if (componentName) {
      addKeepAliveComponent(componentName);
    }
  });

  function removeComponentFromKeepAlive(): void {
    if (componentName) {
      removeKeepAliveComponent(componentName);
    }
  }

  onBeforeUnmount(removeComponentFromKeepAlive);

  // Add watcher only if routeNames provided
  if (routeNames) {
    watch(currentRouteName, (newValue) => {
      if (newValue && ![...routeNames, mountedRouteName].includes(newValue as BaseRouteNameType)) {
        removeComponentFromKeepAlive();
      }
    });
  }
}
