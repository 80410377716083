import type { Ref } from 'vue';
import { computed } from 'vue';

import type { HighlightedTextLine, HighlightedTextProps } from '../types';
import { getParsedQueryString, getTextSplitedByQuery, isMatchedSubstring } from '../utils';

interface UseHighlightedText {
  substrings: Ref<HighlightedTextLine[]>;
}

export function useHighlightedText(props: HighlightedTextProps): UseHighlightedText {
  const computedQuery = computed<string>(() => getParsedQueryString(props.query, props.exploded));
  const inputAsSubstrings = computed<string[]>(() => getTextSplitedByQuery(props.text ?? '', computedQuery.value));
  const substrings = computed<HighlightedTextLine[]>(() => {
    return inputAsSubstrings.value.map((text) => ({
      text,
      isHighlighted: props.query ? isMatchedSubstring(text, computedQuery.value) : false,
    }));
  });
  return {
    substrings,
  };
}
