import { getAchievementsAvatarsAndBackgrounds } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  GetAvatarsAndBackgroundsDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAvatarsAndBackgroundsRequest(): Promise<GetAvatarsAndBackgroundsDataDocument> {
  const apiClient = useGraphqlClient();

  return getAchievementsAvatarsAndBackgrounds(
    apiClient,
    (node) => node.queries.achievements.getAvatarsAndBackgrounds,
  );
}
