import type { Ref } from 'vue';
import { ref } from 'vue';

import type { Optional } from '@leon-hub/types';

import type { ExtendedEventBlock } from '../types';
import { extendedEventBlockDefault } from '../constants';
import { useCreateEventIsOpenedRefProvider } from './useCreateEventIsOpenedRefProvider';

interface UseIsEventExtendedProps {
  sportlineEventId: Ref<string>;
  blockType?: Ref<Optional<ExtendedEventBlock>>;
}

interface UseIsEventExtendedComposable {
  isEventExtended: Ref<boolean>;
}

export function useIsEventExtended(
  { sportlineEventId, blockType }: UseIsEventExtendedProps,
): UseIsEventExtendedComposable {
  const createEventIsOpenedRef = useCreateEventIsOpenedRefProvider();
  const type = blockType ?? ref(extendedEventBlockDefault);
  const isEventExtended = createEventIsOpenedRef(sportlineEventId, type);

  return { isEventExtended };
}
