import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import type { LobbyGameType } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names';

import {
  AllGamesCategoryUrl,
  AllGamesGroupUrl,
  FavoriteGamesCategoryUrl,
} from 'web/src/modules/egs/constants';

import getLobbyRouteName from './getLobbyRouteName';
import isLiveEgsRoute from './isLiveEgsRoute';

export default function getLobbyLocation(
  route: RouteLocationNormalized,
  groupUrl?: string,
  categoryUrl?: string,
  lobbyType?: Maybe<LobbyGameType>,
): RouteLocationRaw {
  if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED && categoryUrl === FavoriteGamesCategoryUrl) {
    return {
      name: CasinoRouteName.CASINO_MY_GAMES,
    };
  }

  const baseName = getLobbyRouteName(lobbyType, isLiveEgsRoute(route));
  const urlGroupParam = groupUrl || AllGamesGroupUrl;

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && categoryUrl === AllGamesCategoryUrl) {
    return {
      name: baseName,
      params: urlGroupParam !== AllGamesGroupUrl ? {
        groupUrl: urlGroupParam,
      } : {},
    };
  }

  const parameters: Record<string, string> = {};

  parameters.groupUrl = urlGroupParam;
  if (!categoryUrl && !groupUrl) {
    delete parameters.groupUrl;
  }

  if (categoryUrl) {
    parameters.categoryUrl = categoryUrl;
  }

  return {
    name: baseName,
    params: parameters,
  };
}
