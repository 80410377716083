import { DecodeSymbol, DecodeValue } from '@leon-hub/utils';

import type { UiFieldRawOptions } from '../types';

export default function decodeDefaultValue(currentProperties: UiFieldRawOptions): UiFieldRawOptions {
  const defaultValue = currentProperties.default ? String(currentProperties.default) : '';
  const symbol = defaultValue.includes('+') ? DecodeSymbol.DEFAULT : DecodeSymbol.BULL;
  const decodedDefaultValue = new DecodeValue(defaultValue, { symbol }).toDecodedString();
  return {
    ...currentProperties,
    default: decodedDefaultValue,
  };
}
