<script lang="ts" setup>
import { ref, toRef, watch } from 'vue';

import {
  onComponentActivated,
  onComponentDeactivated,
  useControllableResizeObserver,
} from '@leon-hub/vue-utils';

import type {
  ResizeObserverEmits,
  ResizeObserverProps,
  ResizeObserverSlots,
} from './types';

const props = defineProps<ResizeObserverProps>();
const emit = defineEmits<ResizeObserverEmits>();
defineSlots<ResizeObserverSlots>();

const isDisabled = toRef(() => props.disabled);

const element = ref<Optional<HTMLElement>>();

function emitResize(): void {
  const height = element.value?.offsetHeight;
  const width = element.value?.offsetWidth;
  emit('resize', { height, width });
}

const { start, stop, isEnabled } = useControllableResizeObserver(element, emitResize);

function applyDisabledState(): void {
  if (isDisabled.value) {
    stop();
  } else {
    start();
  }
}

watch(isDisabled, applyDisabledState);
watch(isEnabled, (value) => {
  if (value) { return; }
  emit('stopped');
});

onComponentActivated(applyDisabledState);
onComponentDeactivated(stop);
</script>

<template>
  <div v-auto-id="'ResizeObserver'" ref="element">
    <slot />
  </div>
</template>
