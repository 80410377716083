import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SharedRouteNameType } from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import { useSiteConfigStore } from '@core/site-config';

export interface HomePageTypeComposable {
  homePageType: Ref<HomePageType>;
  isSportType: Ref<boolean>;
  routeNameToSportLine: Ref<SharedRouteNameType>;
}
export default function useHomePageType(): HomePageTypeComposable {
  const siteConfigStore = useSiteConfigStore();
  const homePageType = toRef(siteConfigStore, 'homePageType');
  const isSportType = computed(() => homePageType.value === HomePageType.SPORTS);
  const routeNameToSportLine = computed(() => (isSportType.value ? RouteName.HOME : RouteName.SPORTLINE_LIVE_EVENTS));
  return {
    homePageType,
    isSportType,
    routeNameToSportLine,
  };
}
