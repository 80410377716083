import { InputEventType } from '@leon-hub/input-types';

import type { NativeSelectEmits, NativeSelectProps } from '../types';
import nativeSelectItemToVInputInputEvent from '../utils/nativeSelectItemToVInputInputEvent';

interface UseNativeSelectEvents {
  emitChange(event: InputEvent): void;
  emitFocus(event: FocusEvent): void;
  emitBlur(event: FocusEvent): void;
}

export default function useNativeSelectEvents(props: NativeSelectProps, emit: NativeSelectEmits): UseNativeSelectEvents {
  const emitChange = (event: InputEvent): void => {
    emit(InputEventType.CHANGE, nativeSelectItemToVInputInputEvent(event, props.name));
  };

  const emitFocus = (event: FocusEvent): void => {
    emit(InputEventType.FOCUS, nativeSelectItemToVInputInputEvent(event, props.name));
  };

  const emitBlur = (event: FocusEvent): void => {
    emit(InputEventType.BLUR, nativeSelectItemToVInputInputEvent(event, props.name));
  };

  return {
    emitChange,
    emitFocus,
    emitBlur,
  };
}
