import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import { DateTime } from '@leon-hub/utils';

import type { VIconProps } from '@components/v-icon';

import type {
  SearchListItemEmits,
  SearchListItemProps,
} from '../types';

interface SearchListItemComposable {
  onClick(): void;
  date: ComputedRef<string>;
  time: ComputedRef<string>;
  streamIconProps: VIconProps;
}

export default function useSearchListItem(
  props: RequiredField<SearchListItemProps, 'searchText'>,
  emits: SearchListItemEmits,
): SearchListItemComposable {
  const streamIconProps: VIconProps = {
    name: IconName.STREAM,
    size: IconSize.SIZE_16,
  };

  const date = computed(() => DateTime.withTimeStamp(props.kickoff).toDate());
  const time = computed(() => DateTime.withTimeStamp(props.kickoff).toTime());

  function onClick(): void {
    emits('click');
  }

  return {
    onClick,
    date,
    time,
    streamIconProps,
  };
}
