<script lang="ts" setup>
/**
 * @deprecated use VIcon instead
 * @TODO delete
 */
import { computed } from 'vue';

import {
  IconDirection,
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VIcon, type VIconProps } from '@components/v-icon';

import type { ChevronIconEmits, ChevronIconProps } from './types';

const props = withDefaults(defineProps<ChevronIconProps>(), {
  direction: IconDirection.EAST,
  size: IconSize.SIZE_20,
});
const emit = defineEmits<ChevronIconEmits>();

const iconProperties = computed<VIconProps>(() => ({
  name: IconName.CHEVRON,
  direction: props.direction,
  size: props.size,
}));

function onClick(event: PointerEvent): void {
  emit('click', event);
}
</script>

<template>
  <VIcon v-auto-id="'ChevronIcon'"
    v-bind="iconProperties"
    @click="onClick"
  />
</template>
