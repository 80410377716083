import { FormControlType } from '@leon-hub/form-utils';
import { assert, isObject } from '@leon-hub/guards';

import type { FormUiSchemaField } from 'web/src/components/Form/types';
import { isFormUiSchemaField } from 'web/src/components/Form/guards';

import type { UiFieldRawOptions } from '../types';
import mapFieldRawOptions from './mapFieldRawOptions';
import modifyFieldPropertiesByWidget from './modifyFieldPropertiesByWidget';

/** function to merge all passed properties and map it into single field of formUiSchema[fields] */
export default function getUiSchemaField(
  commonProperties: UiFieldRawOptions,
  customProperties: UiFieldRawOptions,
  customOptions: UiFieldRawOptions,
): FormUiSchemaField {
  const rawFieldCommon: UiFieldRawOptions = {
    ...commonProperties,
    ...customProperties,
  };
  const { options, ...rawFieldCommonBase } = rawFieldCommon;
  const defaultOptions = isObject(options) ? { ...options } : {};

  const mappedFieldCommon = mapFieldRawOptions(rawFieldCommonBase);
  const mappedOptions = mapFieldRawOptions({
    ...defaultOptions,
    ...customOptions,
  });
  const optionsIsNotEmpty = !!Object.keys(mappedOptions).length;
  const result = modifyFieldPropertiesByWidget({
    ...mappedFieldCommon,
    ...(optionsIsNotEmpty ? { options: mappedOptions } : {}),
  });

  // TODO: Temporary solution. Default comes as an empty string
  if (result.widget === FormControlType.AsoTermsCheckbox) {
    return result;
  }

  assert(isFormUiSchemaField(result), `getUiSchemaField: final shape of ${result.widget} doesn't matches FormUiSchemaField`);
  return result;
}
