<script lang="ts" setup>
import { computed } from 'vue';

import { InputEventType } from '@leon-hub/input-types';

import type { CaptchaToken } from 'web/src/modules/captcha/store/types';
import VCaptchaVerificationWatcher
  from 'web/src/modules/captcha/components/VCaptchaVerificationWatcher/VCaptchaVerificationWatcher.vue';

import type VCaptchaProperties from '../../../Input/types/VCaptchaProperties';
import type {
  FormDataEvent,
  FormWrapperWidgetProperties,
} from '../../types';
import useFormCaptcha from './useFormCaptcha';

interface Emits {
  (e: 'input', event: FormDataEvent): void;
}

const props = defineProps<FormWrapperWidgetProperties<VCaptchaProperties>>();

const emit = defineEmits<Emits>();

const { componentProps } = useFormCaptcha(props);

const shouldRenderComponent = computed<boolean>(
  () => !!('1' && componentProps.value.captchaEnabled),
);

function onVerify(captchaToken: CaptchaToken): void {
  emit(InputEventType.INPUT, {
    value: captchaToken,
    name: props.name,
  });
}
</script>

<template>
  <VCaptchaVerificationWatcher v-auto-id="'FormCaptcha'"
    v-if="shouldRenderComponent"
    v-bind="componentProps"
    @verify="onVerify"
  />
</template>
