<script lang="ts" setup>
import VPhotoPlaceholder from 'web/src/components/PhotoPlaceholder/VPhotoPlaceholder/VPhotoPlaceholder.vue';

import type { VPhotoInputEmits, VPhotoInputProps } from './types';
import { useVPhotoInput } from './composables';

const props = withDefaults(defineProps<VPhotoInputProps>(), {
  placeholder: () => ({}),
});
const emit = defineEmits<VPhotoInputEmits>();
const { onChange } = useVPhotoInput(props, emit);
const bemClassName = 'photo-input';
</script>

<template>
  <div v-auto-id="'VPhotoInput'" :class="$style[bemClassName]">
    <input
      :id="id"
      :class="$style[`${bemClassName}__file`]"
      type="file"
      name="name"
      accept="image/*"
      @change.stop="onChange"
      @input.stop
    >
    <label
      :for="id"
      :class="$style[`${bemClassName}__label`]"
    >
      <VPhotoPlaceholder v-bind="placeholder" />
    </label>
  </div>
</template>

<style lang="scss" module>
.photo-input {
  display: flex;

  &__file {
    display: none;
  }

  &__label {
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
