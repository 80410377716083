import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';

import type { CasinoLoyaltyProgramLevelProps } from '../types';

export interface CasinoLoyaltyProgramLevelComposable {
  iconName: ComputedRef<IconNameType>;
  label: ComputedRef<string>;
}

export default function useCasinoLoyaltyProgramLevel(props: CasinoLoyaltyProgramLevelProps):
CasinoLoyaltyProgramLevelComposable {
  const { $translate } = useI18n();

  const label = computed(() => {
    switch (props.status) {
      case CasinoLoyaltyProgramLevelStatus.SUCCESS:
        return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_SUCCESS').value;
      case CasinoLoyaltyProgramLevelStatus.PROCESS:
        return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_PROCESS').value;
      case CasinoLoyaltyProgramLevelStatus.LOCK:
      default:
        return $translate('WEB2_LOYALTY_PROGRAM_LEVEL_STATUS_LOCK').value;
    }
  });

  const iconName = computed(() => {
    if (props.status === CasinoLoyaltyProgramLevelStatus.PROCESS) {
      return IconName.TIMER_SAND;
    }
    if (props.status === CasinoLoyaltyProgramLevelStatus.LOCK) {
      return IconName.LOCK;
    }
    return IconName.CHECK_FILLED;
  });

  return {
    iconName,
    label,
  };
}
