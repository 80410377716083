<script lang="ts" setup>
import type { Ref } from 'vue';
import { inject, ref } from 'vue';

import type { VScrollbarDesktopProvidableContext } from '@components/v-scrollbar';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';

import type { SearchRoutePageEmits, SearchRoutePageProps } from 'web/src/modules/search/pages/SearchRoutePage/types';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import { useSearchRoutePage } from 'web/src/modules/search/pages/SearchRoutePage/composables';
import SearchTabsSwitcher from 'web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/CasinoSearchTabsSwitcher.vue';

const props = defineProps<SearchRoutePageProps>();

const emits = defineEmits<SearchRoutePageEmits>();

const customScrollbarContext: Ref<VScrollbarDesktopProvidableContext> = inject(ScrollbarProvidableContext.ScrollbarContext, ref({
  isReady: true,
  scrollTop: 0,
}));

const {
  autofocus,
  input,
  result,
  searchInputProperties,
  onInput,
  onClear,
  onFocus,
  selectedTabIndex,
  onActiveTabChanged,
  resultsComponent,
  resultsComponentProps,
  setSearchText,
  emitItemClick,
} = useSearchRoutePage(props, emits);
</script>

<template>
  <div v-auto-id="'SearchRoutePage'"
    :class="$style['search']"
  >
    <VSearchInput
      v-bind="searchInputProperties"
      ref="input"
      v-data-test="{ el: 'search-input' }"
      :autofocus="customScrollbarContext.isReady && autofocus"
      :is-large="!!null"
      :class="$style['search__input']"
      @input="onInput"
      @clear="onClear"
    />
    <SearchTabsSwitcher
      v-if="'1' && '1'"
      :class="$style['search__switcher']"
      :active-tab="selectedTabIndex"
      @change="onActiveTabChanged"
    />
    <component
      :is="resultsComponent"
      v-if="resultsComponent"
      v-bind="resultsComponentProps"
      ref="result"
      @focus="onFocus"
      @search-text="setSearchText"
      @item-click="emitItemClick"
    />
  </div>
</template>

<style lang="scss" module>
.search {
  padding: 0 16px 8px;

  &__input {
    margin: 0 8px;
  }

  @include is-app-layout-desktop {
    padding: $searchRoutePageDesktopPadding;
  }
}
</style>
