import type { Ref } from 'vue';
import { computed } from 'vue';

import type { FormDataEvent, FormOutput, FormSnapshot } from '../../../types';
import type { UseFormOutput, UseFormProps } from './types';
import useExternalErrors from './useExternalErrors';
import useFocusOnError from './useFocusOnError';
import useFormData from './useFormData';
import useFormFields from './useFormFields';
import useFormValidator from './useFormValidator';
import useIsFormReady from './useIsFormReady';
import getErrorsMapForOutput from './utils/getErrorsMapForOutput';

export default function useForm({
  schema,
  uiSchema,
  customErrors,
  validationDisabled,
  errorPatterns,
  isPending,
  focusOnError,
  focusByName,
}: UseFormProps): UseFormOutput {
  const {
    formData,
    formDataMap,
    touched,
    resetFormData,
    getSnapshot,
    restoreFormDataFromSnapShot,
    setTouchedOnSubmit,
    handleFieldInput,
    handleFieldBlur,
    refreshFormData,
  } = useFormData({ schema, uiSchema });

  const { externalErrors } = useExternalErrors({
    customErrors,
    uiSchema,
    validationDisabled,
    formDataMap,
    schema,
  });

  const {
    validate,
    schemaErrors,
    resetErrors,
    haveAnyValidationErrors,
  } = useFormValidator({
    schema,
    uiSchema,
    validationDisabled,
    errorPatterns,
    touched,
  });

  const createFormOutput = (field = ''): FormOutput => ({
    formData: formData.value,
    errors: getErrorsMapForOutput(schemaErrors.value),
    customErrors: getErrorsMapForOutput(externalErrors.value),
    field,
  });

  const getCurrentOutput = () => createFormOutput();

  const hasAnyErrors: Ref<boolean> = computed(() => {
    if (validationDisabled) {
      return false;
    }
    return !!(haveAnyValidationErrors.value || Object.keys(externalErrors.value || {}).length);
  });

  const { isFormReady } = useIsFormReady({
    validationDisabled,
    uiSchema,
    schema,
    hasAnyErrors,
    formDataMap,
  });

  const restoreFromSnapShot = (snapshot: FormSnapshot, showErrorsAtRestore = false) => {
    restoreFormDataFromSnapShot(snapshot);
    if (showErrorsAtRestore) {
      validate(formData.value);
    }
  };

  const showValidationErrors = (): void => {
    setTouchedOnSubmit();
    validate(formData.value);
  };

  const reset = (): void => {
    resetFormData();
    resetErrors();
  };

  const {
    fields,
    buttonProps,
    captchaField,
  } = useFormFields({
    schema,
    uiSchema,
    isFormReady,
    isPending,
    externalErrors,
    schemaErrors,
    formData,
  });

  const {
    scrollToFirstError,
    setLinesRef,
    resetFocusOnError,
  } = useFocusOnError(fields, externalErrors, focusOnError, focusByName);

  const handleFocus = (data: FormDataEvent): FormOutput => createFormOutput(data.name);

  const handleBlur = (data: FormDataEvent): FormOutput => {
    resetFocusOnError();
    handleFieldBlur(data);
    validate(formData.value, data.name);
    return createFormOutput(data.name);
  };

  const handleInput = (data: FormDataEvent): FormOutput => {
    handleFieldInput(data);
    validate(formData.value, data.name);
    return createFormOutput(data.name);
  };

  const handleSubmit = (): FormOutput => {
    setTouchedOnSubmit();
    validate(formData.value);
    scrollToFirstError();
    return createFormOutput();
  };

  return {
    reset,
    getSnapshot,
    restoreFromSnapShot,
    getCurrentOutput,
    handleFocus,
    handleBlur,
    handleInput,
    handleSubmit,
    refreshFormData,
    showValidationErrors,
    setLinesRef,
    fields,
    captchaField,
    buttonProps,
  };
}
