import type { ToRefs } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { SiteConfigDocument } from '@core/site-config';
import { useSiteConfigStore } from '@core/site-config';

export type UseDowntimeConfig = ToRefs<SiteConfigDocument['downtime']>;

export default function useDowntimeConfig(): UseDowntimeConfig {
  const siteConfigStore = useSiteConfigStore();
  const downtime = toRef(siteConfigStore, 'downtimeBlock');

  return {
    enabled: computed(() => !!downtime.value?.enabled),
    interval: computed(() => downtime.value?.interval ?? { from: 0, to: 0, shown: false }),
    visibleBeforeHours: computed(() => downtime.value?.visibleBeforeHours || 0),
  };
}
