<script lang="ts" setup>
import { onBeforeUnmount } from 'vue';

import VFeedbackComposition from 'web/src/components/FeedbackComposition/VFeedbackComposition/VFeedbackComposition.vue';

import type { SupportFeedbackLegacyRoutePageEmits } from './types';
import { useSupportFeedbackLegacyRoutePage } from './composables';

const emit = defineEmits<SupportFeedbackLegacyRoutePageEmits>();
const {
  feedbackFormMaxChars,
  haveValidEmailValue,
  submitting,
  isClearTextarea,
  isApiErrorEmailMessage,
  savedErrorDuplicateEmail,
  submit,
  closeSuccessDialog,
} = useSupportFeedbackLegacyRoutePage(emit);

onBeforeUnmount(() => {
  closeSuccessDialog();
});
</script>

<template>
  <div v-auto-id="'SupportFeedbackLegacyRoutePage'" :class="$style['feedback-legacy-page']">
    <VFeedbackComposition
      :description="$t('WEB2_YOUR_FEEDBACK_DESCRIPTION')"
      :feedback-form-max-chars="feedbackFormMaxChars"
      :have-valid-email-value="haveValidEmailValue"
      :submitting="submitting"
      :is-clear-textarea="isClearTextarea"
      :is-api-error-email-message="isApiErrorEmailMessage"
      :saved-error-duplicate-email="savedErrorDuplicateEmail"
      @submit="submit"
    />
  </div>
</template>

<style lang="scss" module>
.feedback-legacy-page {
  padding: 16px;

  @include is-app-layout-desktop {
    padding: 16px 32px 32px;
  }
}
</style>
