<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { ButtonKind, VButton } from '@components/buttons';
import { VImage } from '@components/image';
import { VSwitch } from '@components/switch';
import { VIcon } from '@components/v-icon';

import type {
  WelcomeBonusSelectEmits,
  WelcomeBonusSelectProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelect/types';

import useWelcomeBonusSelect from './composables/useWelcomeBonusSelect';

withDefaults(defineProps<WelcomeBonusSelectProps>(), {
  isSimplifyView: false,
  isLowMoneyAmount: false,
});

const emit = defineEmits<WelcomeBonusSelectEmits>();

const {
  handleSelectClick,
  handleBonusClick,
} = useWelcomeBonusSelect(emit);
</script>

<template>
  <div v-auto-id="'WelcomeBonusSelect'"
    :class="{
      [$style['welcome-bonus-select']]: true,
      [$style['welcome-bonus-select--selected']]: isSelected,
    }"
  >
    <div :class="$style['welcome-bonus-select__top']">
      <VSwitch
        :label="$t('WELCOME_BONUS_CARD_USE_BONUS')"
        :checked="isSelected"
        @change="handleSelectClick"
      />
    </div>
    <div
      v-if="isSimplifyView"
      :class="$style['welcome-bonus-select__body']"
    >
      <div :class="$style['welcome-bonus-select__image']">
        <VImage
          v-if="image"
          :src="image.src"
          :x1="image?.x1"
          :x2="image?.x2"
          :x3="image?.x3"
          :x1webp="image?.x1webp"
          :x2webp="image?.x2webp"
          :x3webp="image?.x3webp"
          height="100%"
        />
      </div>
      <VButton
        :class="$style['welcome-bonus-select__button']"
        :kind="ButtonKind.SECONDARY"
        :label="$t('WEB2_BONUS_CARD_CHOOSE')"
        @click="handleBonusClick"
      />
    </div>
    <div
      v-else
      :class="$style['welcome-bonus-select__body']"
      @click="handleBonusClick"
    >
      <div
        :class="{
          [$style['welcome-bonus-select__image']]: true,
          [$style['welcome-bonus-select__image--unaccented']]: isLowMoneyAmount,
        }"
      >
        <VImage
          v-if="image"
          :src="image.src"
          :x1="image.x1"
          :x2="image.x2"
          :x3="image.x3"
          :x1webp="image.x1webp"
          :x2webp="image.x2webp"
          :x3webp="image.x3webp"
          height="100%"
        />
      </div>
      <div :class="$style['welcome-bonus-select__paragraph']">
        <p
          :class="{
            [$style['welcome-bonus-select__title']]: true,
            [$style['welcome-bonus-select__title--unaccented']]: isLowMoneyAmount,
          }"
        >
          {{ campaignName }}
        </p>
        <p
          :class="{
            [$style['welcome-bonus-select__label']]: true,
            [$style['welcome-bonus-select__label--accent']]: isLowMoneyAmount && isSelected,
          }"
        >
          <span>{{ $t('WELCOME_BONUS_CARD_MINIMUM') }}</span> <span :class="$style['welcome-bonus-select__money']">{{ minimumDepositValue }}</span>
        </p>
      </div>
      <VIcon
        :name="IconName.DROPDOWN"
        :size="IconSize.SIZE_20"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.welcome-bonus-select {
  $self: &;
  $opacity: 0.4;

  &__top {
    padding: 8px;
    margin-bottom: 1px;
    background: var(--OpacityLayer1);
    border-radius: 5px 5px 0 0;

    @include for-hover {
      cursor: pointer;
    }
  }

  &__body {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 8px;
    text-decoration: none;
    background: var(--OpacityLayer1);
    border-radius: 0 0 5px 5px;
  }

  &__image {
    flex-grow: 0;
    width: 96px;
    height: 56px;
    overflow: hidden;
    border-radius: 5px;
    opacity: $opacity;
  }

  &__button {
    width: calc(100% - 108px);
  }

  &__paragraph {
    width: calc(100% - 140px);
    opacity: $opacity;
  }

  &__title {
    @include medium\14\16\025;

    margin: 0 0 8px;
    color: var(--TextDefault);
    overflow-wrap: break-word;
    text-decoration: none;

    &--unaccented {
      opacity: 0.4;
    }
  }

  &__label {
    @include regular\14\16\025;

    margin: 0;
    color: var(--TextPrimary);

    &--accent {
      color: var(--AlertText);

      #{$self}__money {
        color: var(--AlertText);
      }
    }
  }

  &__money {
    @include medium\14\16\025;

    color: var(--TextDefault);
  }

  &--selected {
    #{$self}__paragraph,
    #{$self}__image {
      opacity: 1;

      &--unaccented {
        opacity: 0.4;
      }
    }

    #{$self}__body {
      @include for-hover {
        &:hover {
          cursor: pointer;
          background: var(--Layer1);
        }
      }
    }
  }
}
</style>
