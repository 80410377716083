<script lang="ts" setup>
import { computed } from 'vue';

import { useCurrency, useFormatMoney } from '@core/money';

export interface CompanyFeePercentProps {
  percent?: number;
  amount?: string;
}

const props = withDefaults(defineProps<CompanyFeePercentProps>(), {});

const { currency } = useCurrency();
const formatMoney = useFormatMoney();

const value = computed(() => formatMoney(
  Number(Number(props.amount || 0) * (props.percent || 0)) / 100,
  {
    currency: currency.value,
  },
));
</script>

<template>
  <span v-auto-id="'CompanyFeePercent'"
    :class="{
      [$style['bonus-calculator']]: true,
    }"
  >
    <span>{{ $t('WEB2_COMPANY_FEE_HOURS_COMMISSION_CALCULATOR') }} </span>
    <span>{{ value }}</span>
  </span>
</template>

<style lang="scss" module>
  .bonus-calculator {
    @include medium\12\12;

    display: inline-flex;
    column-gap: 2px;
    padding: 4px;
    color: var(--AlertText);
    background-color: rgba(255, 214, 10, 0.2);
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  }
</style>
