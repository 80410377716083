<script setup lang="ts">
import { computed } from 'vue';

import type { BaseInputEmits } from '@leon-hub/input-types';

import { useI18n } from '@core/i18n';

import type { TextInputProps } from 'web/src/components/Input';
import { TextInput } from 'web/src/components/Input';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import PasswordValidator from 'web/src/components/PasswordValidator';

const props = defineProps<TextInputProps>();
const emit = defineEmits<BaseInputEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseInputEvents(emit);

const { $translate } = useI18n();

const defaultGroups = computed(() => [{
  rules: [{
    message: $translate('WEB2_CARDHOLDER_VALIDATION').value,
    regexp: '^((?:[A-Za-z-.]+ ?){1,})$',
  }],
  requiredValidRules: 1,
},
]);
</script>

<template>
  <TextInput v-auto-id="'CardNameInput'"
    v-bind="props"
    autocomplete="cc-name"
    is-ym-disable-keys
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  >
    <template #hint="{ isFocus, value: inputValue }">
      <PasswordValidator
        v-if="isFocus || inputValue"
        :groups="defaultGroups"
        :password="inputValue"
        :is-focused="isFocus"
        :success-message="$t('WEB2_CARDHOLDER_VALIDATION')"
      />
    </template>
  </TextInput>
</template>
