<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import {
  IconName,
} from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';

import type {
  WelcomeBonusSelectorModalEmits,
  WelcomeBonusSelectorModalProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelectorModal/types';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal/LazyDefaultModal';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import WelcomeBonuses from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonuses/WelcomeBonuses.vue';

withDefaults(defineProps<WelcomeBonusSelectorModalProps>(), {});

const emit = defineEmits<WelcomeBonusSelectorModalEmits>();

const { $translate } = useI18n();
const router = useRouter();

const headerProperties = computed(() => ({
  title: $translate('WEB2_BONUSES_AVAILABLE').value,
  prefixIconName: IconName.ARROW_LEFT,
  suffixIconName: '1' ? IconName.CROSS : undefined,
}));

function handleBonusClick(campaignId: Maybe<number>) {
  emit('select', campaignId);
}

function handleDetailsClick(value: { categoryId?: Maybe<string>; actionUrl?: Maybe<string> }) {
  if (value.categoryId && value.actionUrl) {
    void router.push({
      name: RouteName.PROMOTION_DETAILS,
      params: { categoryId: value.categoryId, actionUrl: value.actionUrl },
    });
  }
}
</script>

<template>
  <LazyDefaultModal v-auto-id="'WelcomeBonusSelectorModal'"
    is-full-height
    :is-full-height-centered="false"
    :is-overlay-close-allowed="false"
    :is-inner-modal="!!'1'"
    :is-profile-layout="!!'1'"
    data-test-id="deposit-bonus-selector"
    @close="emit('back')"
  >
    <template #header>
      <ModalWindowHeader
        v-bind="headerProperties"
        @prefix-click="emit('back')"
        @suffix-click="emit('close')"
      />
    </template>
    <template #inner-content>
      <WelcomeBonuses
        :bonus-list="bonusList"
        :selected-option-id="selectedOptionId"
        @click="handleBonusClick"
        @details="handleDetailsClick"
      />
    </template>
  </LazyDefaultModal>
</template>
