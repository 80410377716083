import type { Ref } from 'vue';
import {
  onBeforeMount,
  onBeforeUnmount,
  toRef,
} from 'vue';

import { CustomerConfig } from '@leon-hub/api-sdk';

import { useUserStore } from '@core/user';

import type { PaymentBonus } from 'web/src/modules/bonuses/store/types/types';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { DEPOSIT_BONUS_DEFAULT_VALUE, DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';

import type {
  PaymentBonusesBaseProps,
  PaymentBonusesEmits,
} from '../types';

export interface PaymentBonusesComposable {
  selectedBonus: Ref<number>;
  emitBonusSelection(selectedId: Pick<PaymentBonus, 'campaignId' | 'bonusCode'>): void;
  handleSelectionComplete(): void;
  onNoDepositClick(): void;
}

export default function usePaymentBonuses(props: PaymentBonusesBaseProps, emit: PaymentBonusesEmits): PaymentBonusesComposable {
  const userStore = useUserStore();
  const bonusStore = useBonusStore();
  const depositStore = useDepositStore();

  const selectedBonus = toRef(() => bonusStore.selectedBonus);
  const isBonusChooserShownBeforeDeposits = toRef(() => bonusStore.isBonusChooserShownBeforeDeposits);
  const amount = toRef(depositStore, 'amount');
  const amountAsNumber = toRef(() => depositStore.amountAsNumber);

  function setMinDepositAmount(campaignId: number) {
    const bonus = props.bonusList.find((item) => item.campaignId === campaignId);

    if (bonus?.minDepositAmount?.amount && bonus.minDepositAmount.amount > amountAsNumber.value) {
      amount.value = `${bonus.minDepositAmount.amount}`;
    }
  }

  async function beforeMount() {
    if (!isBonusChooserShownBeforeDeposits.value) {
      await userStore.setAdditionalPropsUser({
        config: CustomerConfig.BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS,
        value: {
          value: true,
        },
      });
    }

    if (props.autoSelectFirst && props.bonusList.length > 0 && (selectedBonus.value === DEPOSIT_BONUS_NONE_SELECTED || selectedBonus.value === DEPOSIT_BONUS_DEFAULT_VALUE)) {
      bonusStore.setBonusSelection(props.bonusList[0]?.campaignId);
    }
  }

  onBeforeUnmount(() => {
    if (!isBonusChooserShownBeforeDeposits.value) {
      bonusStore.setIsBonusChooserShownBeforeDeposits(true);
    }
  });

  function emitBonusSelection({ campaignId, bonusCode }: Pick<PaymentBonus, 'campaignId' | 'bonusCode'>): void {
    bonusStore.setBonusSelection(campaignId);
    emit('change', bonusCode);
    setMinDepositAmount(campaignId);
  }

  function handleSelectionComplete(): void {
    bonusStore.setChoosingBonusBeforeDeposit(false);
    if (!isBonusChooserShownBeforeDeposits.value) {
      emit('change-complete', `${selectedBonus.value}`);
      bonusStore.setIsBonusChooserShownBeforeDeposits(true);
    }
  }

  function onNoDepositClick(): void {
    emitBonusSelection({
      campaignId: DEPOSIT_BONUS_NONE_SELECTED,
      bonusCode: `${DEPOSIT_BONUS_NONE_SELECTED}`,
    });
    handleSelectionComplete();
  }

  onBeforeMount(beforeMount);

  return {
    selectedBonus,
    emitBonusSelection,
    handleSelectionComplete,
    onNoDepositClick,
  };
}
