<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VCountryFlag, VCountryFlagSize, VFlagIcon } from '@components/country-flag';
import { SListItem, VListItem } from '@components/list';
import { VIcon } from '@components/v-icon';

import { useIsGlobeIcon } from '@modules/localization-utilities';

import type {
  LanguageSwitcherListItemEmits,
  LanguageSwitcherListItemProps,
} from './types';

withDefaults(defineProps<LanguageSwitcherListItemProps>(), {
  name: 'RU',
  title: 'Россия',
});

const emit = defineEmits<LanguageSwitcherListItemEmits>();

const { isGlobeIcon } = useIsGlobeIcon();

const iconProperties: VIconProps = {
  name: IconName.GLOBE,
  size: IconSize.SIZE_20,
};

const pointingProperties: VIconProps = {
  name: null ? IconName.EXPAND_RIGHT : IconName.EXPAND_DOWN,
  size: null ? IconSize.SIZE_20 : IconSize.SIZE_16,
};
</script>

<template>
  <SListItem
    v-if="null"
    :title="title"
    type="primary"
    @click="emit('open-language')"
  >
    <template #icon>
      <VIcon
        v-if="name && isGlobeIcon(name.toLowerCase())"
        v-bind="iconProperties"
      />
      <template v-else>
        <VFlagIcon
          v-if="null"
          :country-code="name"
          :size="IconSize.SIZE_20"
          :flag-size="VCountryFlagSize.SIZE_12"
        />
        <VCountryFlag
          v-else
          :name="name"
          :size="VCountryFlagSize.SIZE_16"
          rounded
        />
      </template>
    </template>
    <template #suffix>
      <VIcon
        v-bind="pointingProperties"
      />
    </template>
  </SListItem>
  <VListItem
    v-else
    :title="title"
    :class="$style['language-switcher-list-item']"
    @click="emit('open-language')"
  >
    <template #prefix>
      <VIcon
        v-if="name && isGlobeIcon(name.toLowerCase())"
        v-bind="iconProperties"
      />
      <template v-else>
        <VFlagIcon
          v-if="null"
          :country-code="name"
          :size="IconSize.SIZE_20"
          :flag-size="VCountryFlagSize.SIZE_16"
          rounded
        />
        <VCountryFlag
          v-else
          :name="name"
          :size="VCountryFlagSize.SIZE_16"
          rounded
        />
      </template>
    </template>
    <template #inner>
      <div
        :class="$style['language-switcher-list-item__content']"
      >
        <span>{{ title }}</span>
        <VIcon
          v-bind="pointingProperties"
        />
      </div>
    </template>
  </VListItem>
</template>

<style lang="scss" module>
.language-switcher {
  content: '';

  &-list-item__content {
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
