import type { VInputFileEvent, VInputInputEvent } from '@leon-hub/input-types';
import { assert } from '@leon-hub/guards';

import type { FormDataEvent } from 'web/src/components/Form/types';
import type { FileMultipleEvent } from 'web/src/components/Input';

import formatExpirationDate from './formatExpirationDate';

export function inputEventToFormDataEvent(event: VInputInputEvent): FormDataEvent {
  const { name, value } = event.target;
  return { name, value };
}

export function inputExpirationDateToFormDataEvent(event: VInputInputEvent): FormDataEvent {
  const { name, value } = event.target;
  return { name, value: formatExpirationDate(value) };
}

export function inputFileEventToFormDataEvent(event: VInputFileEvent): FormDataEvent {
  const { files, name } = event.target;
  const value = files ? files[0] : undefined;
  return { name, value };
}

export function fileMultipleEventToFormDataEvent(event: FileMultipleEvent, returnOriginalName = false): FormDataEvent {
  const { selectedIndex, name, files } = event.target;
  const output: FormDataEvent = {
    name: `${name}/${selectedIndex}`,
    value: files[selectedIndex],
  };
  if (returnOriginalName) {
    output.fieldOriginalName = name;
  }
  return output;
}

export function checkboxEventToDataEvent(event: Event, name: string): FormDataEvent {
  const { target } = event;
  assert(target instanceof HTMLInputElement);
  return { name, value: target.checked };
}
