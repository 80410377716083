<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';
import { TextInputTypes } from '@leon-hub/input-types';

import { TextInput } from 'web/src/components/Input';

import type { AutocompleteAddressProps } from '../types';
import { useAutocompleteAddress } from '../composables';
import { LazyModalAddressSearch } from './LazyModalAddressSearch';

const props = withDefaults(defineProps<AutocompleteAddressProps>(), {
  name: 'address',
});

const emits = defineEmits<BaseInputEmits>();

const {
  inputValue,
  options,
  predefinedOptions,
  onInput,
  onChange,
  onClear,
  onBlur,
  onFocus,
  isOptionsShown,
  selectOption,
  preselectedIndex,
  isPendingSearch,
  onKeyDown,
  setPreselectedIndex,
  debouncedAddressSearch,
  showModalSearch,
  hideModalSearch,
  isModalSearchShown,
  focusable,
} = useAutocompleteAddress(props, emits);
</script>

<template>
  <fieldset v-auto-id="'AutocompleteAddress'" :class="$style['address']">
    <TextInput
      ref="focusable"
      :autofocus="autofocus"
      :value="inputValue"
      :label="label"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :hint="hint"
      :hint-right="hintRight"
      :error="error"
      :error-right="errorRight"
      :hint-link="hintLink"
      :hint-link-right="hintLinkRight"
      :type="TextInputTypes.TEXT"
      :autocomplete="autocomplete"
      :regexp="regexp"
      :is-button-mode="disallowUserInput"
      :readonly="disallowUserInput"
      @keydown="onKeyDown"
      @input="onInput"
      @change="onChange"
      @clear="onClear"
      @focus="onFocus"
      @blur="onBlur"
      @click="showModalSearch"
    >
      <template
        v-if="!disallowUserInput"
        #dropdown
      >
        <template
          v-if="isOptionsShown"
        >
          <div :class="$style['address__options']">
            <button
              v-for="(option, index) in options"
              :key="option.id"
              :class="{
                [$style['address__options-item']]: true,
                [$style['address__options-item--preselected']]: preselectedIndex === index,
              }"
              type="button"
              @mousedown="selectOption(option)"
              @mouseenter="setPreselectedIndex(index)"
            >
              {{ option.fullText }}
            </button>
          </div>
        </template>
      </template>
      <template
        v-else
        #dropdown
      >
        <template v-if="isModalSearchShown">
          <LazyModalAddressSearch
            :current-value="inputValue"
            :options="options"
            :placeholder="modalPlaceholder"
            :predefined-options="predefinedOptions"
            :is-pending="isPendingSearch"
            :modal-caption="modalCaption"
            :empty-label="$t('WEB2_AUTOCOMPLETE_ADDRESS_NEED_TO_SELECT_CITY')"
            :nothing-found-label="$t('WEB2_AUTOCOMPLETE_ADDRESS_NOT_FOUND')"
            :mandatory-choose-label="$t('WEB2_AUTOCOMPLETE_ADDRESS_SELECT_CITY')"
            :predefined-options-label="$t('WEB2_AUTOCOMPLETE_ADDRESS_CLOSEST_CITY')"
            :preselected-index="preselectedIndex"
            @search="debouncedAddressSearch"
            @select-option="selectOption"
            @close="hideModalSearch"
            @set-preselected-index="setPreselectedIndex"
          />
        </template>
      </template>
    </TextInput>
  </fieldset>
</template>

<style lang="scss" module>
.address {
  padding: 0;
  margin: 0;
  border: none;

  &__options {
    @include z-index(absolute-item);

    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: -14px 0 0;
    background-color: var(--Layer0);
  }

  &__options-item {
    @include regular\14\20\025;

    display: block;
    width: 100%;
    padding: 12px;
    margin: 4px 0 0;
    color: var(--TextPrimary);
    text-align: left;
    cursor: pointer;
    background: var(--Layer1);
    border: none;
    border-radius: 5px;

    &--preselected {
      background: var(--Highlight);
    }
  }
}
</style>
