import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  VerificationTokenFieldsFragment,
} from '@leon-hub/api-sdk';
import {
  makeVipFastTrackVerification,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export const useVerificationVipStore = defineStore('verification-vip', () => {
  const api = useGraphqlClient();
  const isVipVerificationStarted = ref<boolean>(false);

  function makeVerification(): Promise<VerificationTokenFieldsFragment> {
    return makeVipFastTrackVerification(api, (node) => node.mutations.verification.makeVipFastTrackVerification, {
      options: {},
    });
  }

  const setIsVipVerificationStarted = (value: boolean): void => {
    isVipVerificationStarted.value = value;
  };

  return {
    makeVerification,
    isVipVerificationStarted,
    setIsVipVerificationStarted,
  };
});
