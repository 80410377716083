import type {
  WelcomeBonusSelectEmits,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelect/types';

export interface WelcomeBonusSelectComposable {
  handleSelectClick(): void;
  handleBonusClick(): void;
}

export default function useWelcomeBonusSelect(
  emit: WelcomeBonusSelectEmits,
): WelcomeBonusSelectComposable {
  const handleSelectClick = () => {
    emit('select');
  };

  const handleBonusClick = () => {
    emit('bonus-click');
  };

  return {
    handleSelectClick,
    handleBonusClick,
  };
}
