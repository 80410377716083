import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import type {
  CoreSportlineFetchOptions,
  GetCountersResponse,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getCounters } from 'web/src/modules/sportline/utils/api';

interface SportlineApiLoadCountersComposable {
  loadCounters(options: CoreSportlineFetchOptions): Promise<Maybe<GetCountersResponse>>;
}

export default function useSportlineApiLoadCounters(
  props: UseSportlineApiLoadEventsProps,
): SportlineApiLoadCountersComposable {
  const {
    tryRequestWithCTag,
    isZeroMarginEnabled,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  let loadCountersPending: Maybe<Promise<Maybe<GetCountersResponse>>> = null;

  function loadCounters(options: {
    silent?: boolean;
  }): Promise<Maybe<GetCountersResponse>> {
    if (loadCountersPending) { return loadCountersPending; }

    const { silent } = options;

    loadCountersPending = new Promise((resolve, reject) => {
      tryRequestWithCTag((ctag) => getCounters({
        betline: BetlineType.All,
        ctag,
        silent,
        hideClosed: true,
        zeroMarginMarkets: isZeroMarginEnabled.value,
        flags: [...defaultRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
      })).finally(() => {
        loadCountersPending = null;
      }).then(resolve).catch(reject);
    });

    return loadCountersPending;
  }

  return {
    loadCounters,
  };
}
