import type { BaseInputEmits, VInputInputEvent } from '@leon-hub/input-types';
import { InputEventType } from '@leon-hub/input-types';

type Emits<T = object> = T & BaseInputEmits;

interface UseBaseInputEvents {
  emitChange(event: VInputInputEvent): void;
  emitInput(event: VInputInputEvent): void;
  emitFocus(event: VInputInputEvent): void;
  emitBlur(event: VInputInputEvent): void;
}

export default function useBaseInputEvents(emit: Emits): UseBaseInputEvents {
  const emitInput = (event: VInputInputEvent): void => {
    emit(InputEventType.INPUT, event);
  };

  const emitChange = (event: VInputInputEvent): void => {
    emit(InputEventType.CHANGE, event);
  };

  const emitFocus = (event: VInputInputEvent): void => {
    emit(InputEventType.FOCUS, event);
  };

  const emitBlur = (event: VInputInputEvent): void => {
    emit(InputEventType.BLUR, event);
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
}
