import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { doConfirmations, doRewarding } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';
import { useFormatMoney } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import type {
  AvailabilityGetDataDocument,
  ProgramDetailsGetDataDocument,
  ReferralDataGetDataDocument,
  RewardOptions,
  RewardsGetDataDocument,
} from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import getAvailabilityData from 'web/src/modules/referral-program/utils/api/getAvailability';
import getDetailsData from 'web/src/modules/referral-program/utils/api/getDetails';
import getReferralData from 'web/src/modules/referral-program/utils/api/getReferral';
import getRewardsData from 'web/src/modules/referral-program/utils/api/getRewards';
import sortByRegistrationDate from 'web/src/modules/referral-program/utils/sortByRegistrationDate';

const useReferralProgramStore = defineStore('referral-program', () => {
  // State
  const programReferral = ref<Maybe<ReferralDataGetDataDocument>>(null);
  const programDetails = ref<Maybe<ProgramDetailsGetDataDocument>>(null);
  const rewards = ref<Maybe<RewardsGetDataDocument>>(null);
  const availability = ref<Maybe<AvailabilityGetDataDocument>>(null);
  const isAccessGranted = ref(false);
  const isConfirmed = ref(false);

  const apiClient = useGraphqlClient();

  const viewMode = ref<ReferralProgramViewMode>(ReferralProgramViewMode.MAIN);

  const formatMoney = useFormatMoney();
  const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');

  // Actions
  async function setProgramReferral(): Promise<void> {
    try {
      if (isProfileReferralProgramEnabled.value) {
        programReferral.value = await getReferralData();
      }
    } catch {
      clearProgramReferral();
    }
  }

  function clearProgramReferral(): void {
    programReferral.value = null;
  }

  async function setRewards(referralCode: string): Promise<void> {
    try {
      if (isProfileReferralProgramEnabled.value) {
        rewards.value = await getRewardsData(referralCode);
      }
    } catch (error) {
      clearRewards();
      throw error;
    }
  }

  function clearRewards(): void {
    rewards.value = null;
  }

  async function getRefDetails(): Promise<void> {
    if (isProfileReferralProgramEnabled.value) {
      let response = await getDetailsData();

      if (response?.referrals) {
        response = sortByRegistrationDate(response);
      }

      programDetails.value = response;
      isAccessGranted.value = !programDetails.value?.playerRequirements.some(
        (item) => (!item.satisfied),
      );
    }
  }

  async function getAvailability(): Promise<void> {
    availability.value = await getAvailabilityData();
  }

  async function confirmation(silent?: boolean): Promise<boolean> {
    let confirmed = false;

    if (isProfileReferralProgramEnabled.value) {
      const response = await doConfirmations(
        apiClient,
        (node) => node.mutations.refProgram.confirmations,
        { options: {} },
        { silent },
      );

      confirmed = response.confirmed;

      await getRefDetails();

      if (confirmed) {
        isConfirmed.value = true;
      }
    }

    return confirmed;
  }

  async function rewarding(rewardOptions: RewardOptions, silent?: boolean): Promise<void> {
    if (isProfileReferralProgramEnabled.value) {
      let response = await doRewarding(
        apiClient,
        (node) => node.mutations.refProgram.reward,
        { options: rewardOptions },
        { silent },
      );

      if (response?.referrals) {
        response = sortByRegistrationDate(response);
      }

      programDetails.value = response;
    }
  }

  const totalReward = computed(() => formatMoney(programDetails.value?.totalRewardAmount || 0));

  function setViewMode(value: ReferralProgramViewMode): void {
    viewMode.value = value;
  }

  return {
    programReferral,
    programDetails,
    isAccessGranted,
    rewards,
    availability,
    setProgramReferral,
    clearProgramReferral,
    setRewards,
    clearRewards,
    getAvailability,
    getRefDetails,
    confirmation,
    totalReward,
    viewMode,
    setViewMode,
    rewarding,
    isConfirmed,
  };
});

export default useReferralProgramStore;
