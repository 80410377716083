<script lang="ts" setup>
import { CustomInputEventType, TextInputTypes } from '@leon-hub/input-types';

import { TextInput } from 'web/src/components/Input';

import type {
  FormEmitsWithIconEvents,
  FormWrapperWidgetProperties,
} from '../../types';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<FormEmitsWithIconEvents>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

function emitIconPrefixClick(): void {
  emit(CustomInputEventType.ICON_PREFIX_CLICK, props.name);
}

function emitIconSuffixClick(): void {
  emit(CustomInputEventType.ICON_SUFFIX_CLICK, props.name);
}

const { componentProps } = useCommonFormWidgetProps(props, TextInputTypes.TEXT);
</script>

<template>
  <TextInput v-auto-id="'FormText'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
    @icon-prefix-click="emitIconPrefixClick"
    @icon-suffix-click="emitIconSuffixClick"
  />
</template>
