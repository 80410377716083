import { DateTime } from '@leon-hub/utils';

import { DateInputCustomDate } from 'web/src/components/Input/enums';

export function getMaxDate(max: string | number | DateInputCustomDate | undefined): number | undefined {
  if (typeof max === 'string') {
    if (max === DateInputCustomDate.Now) {
      return DateTime.now().toTimestamp();
    }

    return undefined;
  }

  return max;
}
