import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';

import type { BalanceUserEmits, BalanceUserProps } from '../types';

export interface BalanceUserComposable {
  isSuccessModal: Ref<boolean>;
  balanceSnapshot: Ref<string>;
  isSingleMode: Ref<boolean>;
  isMultipleMode: Ref<boolean>;
  emitClose(): void;
  handleCloseSuccessModal(): void;
  onWithdrawBonus(balance: string): void;
  beforeMount(): void;
}

export default function useBalanceUser(props: BalanceUserProps, emit: BalanceUserEmits): BalanceUserComposable {
  const paymentStore = usePaymentsStore();

  const isSuccessModal = ref(false);
  const balanceSnapshot = ref('');

  const isSingleMode = toRef(() => props.mode === BalanceMode.Single);
  const isMultipleMode = toRef(() => props.mode === BalanceMode.Multiple);

  function emitClose() {
    emit('close');
  }

  function handleCloseSuccessModal(): void {
    isSuccessModal.value = false;
    emitClose();
  }

  function onWithdrawBonus(balance: string): void {
    isSuccessModal.value = true;
    balanceSnapshot.value = balance;
  }

  function beforeMount() {
    void paymentStore.loadWithdrawalStats();
  }

  return {
    isSuccessModal,
    balanceSnapshot,
    isSingleMode,
    isMultipleMode,
    emitClose,
    handleCloseSuccessModal,
    onWithdrawBonus,
    beforeMount,
  };
}
