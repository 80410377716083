import type { Component } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import { hideSplashscreen } from '@leon-hub/cordova';
import { EnvironmentPlugin } from '@leon-hub/plugins';

import { I18nPlugin } from '@core/i18n';
import { createStoreThemeGetter, ThemePlugin } from '@core/theme';

import SimpleApp from '../components/SimpleApp/SimpleApp.vue';

export default function createSimpleApp(component: Component): void {
  const app = createApp(SimpleApp);

  const pinia = createPinia();
  app.use(pinia);

  app.use(createHead());
  app.use(ThemePlugin, createStoreThemeGetter());
  app.use(I18nPlugin);
  app.use(EnvironmentPlugin);

  app.component('SimpleAppDynamicComponent', component);

  app.mount('#app');

  // Simple apps must be screens with disabled scrolls
  document.body.classList.add('disable-scroll');

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    hideSplashscreen();
  }
}
