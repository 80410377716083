export { default as LayoutSizes } from './LayoutSizes';

export const enum ModalSelector {
  BODY = 'body',
  DESKTOP_INNER_MODAL = '#desktop-modal',
  APP = '#app',
}

export enum FeedbackApiErrorCode {
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
}
