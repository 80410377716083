import { themeDark, themeLight } from '@leon-hub/api-sdk';
import { AppError } from '@leon-hub/app-errors';

import { useErrorsConverter } from '@core/errors';

import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import type {
  CoreSportEventResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  EventDetailsSportlineApiService,
  GetEventDetailsOptions,
} from 'web/src/modules/sportline/types/services';
import { BetlineFlag, BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';
import { getEvent } from 'web/src/modules/sportline/utils/api';

export function useSportlineApiLoadEventDetails(
  props: UseSportlineApiLoadEventsProps,
): EventDetailsSportlineApiService {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const errorConverter = useErrorsConverter();

  async function getEventDetails(options: GetEventDetailsOptions): Promise<Maybe<CoreSportEventResponse>> {
    try {
      const {
        id,
        silent,
        doAddWidgetFlags,
        themeFlag,
      } = options;

      if (process.env.NODE_ENV === 'development') {
        const isMissingEvent = (window as unknown as { missing?: boolean }).missing;

        if (isMissingEvent) {
          // eslint-disable-next-line no-console
          console.log('Enabled local override getEvent: missing event');
          throw new AppError({ code: SportlineRestErrorCode.MISSING_EVENT });
        }
      }

      const flags = [
        ...defaultRestApiFlags.value,
        BetlineFlag.WithSportMarketGroupsV2,
        BetlineFlag.OutrightExtendedMarketLayout,
      ];

      if (doAddWidgetFlags) {
        flags.push(BetlineFlag.AllowedWidgetsVersion2);
      }

      if (themeFlag === themeLight) {
        flags.push(BetlineFlag.ThemeLight);
      } else if (themeFlag === themeDark) {
        flags.push(BetlineFlag.ThemeDark);
      }

      return await tryRequestWithCTag((ctag) => getEvent({
        eventId: id,
        betline: BetlineType.All,
        ctag,
        silent,
        flags,
        LMCE: isLiveOutrightsEnabled.value,
      }));
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);

      if (error.code.equals(SportlineRestErrorCode.MISSING_EVENT)) {
        return null;
      }

      throw rawError;
    }
  }

  return {
    getEventDetails,
  };
}
