import {
  isObject,
  isOptionalArray,
  isOptionalNumber,
  isOptionalString,
  isUndefined,
} from '@leon-hub/guards';
import { isInputHintProps } from '@leon-hub/input-types';

import type { FileMultipleDragAndDropInputProps } from '../types/FileMultipleDragAndDropInputProps';

export default function isFileMultipleDragAndDropInputProperties(value: unknown): value is FileMultipleDragAndDropInputProps {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.name)
    && isOptionalNumber(value.max)
    && (isOptionalArray(value.formErrors))
    && (isUndefined(value.fileTypes) || isObject(value.fileTypes))
    && isInputHintProps(value);
}
