import { computed } from 'vue';

import type {
  GetHeadlineMatchesChangesResponse,
  GetHeadlineMatchesResponse,
  HeadlineChangesFetchOptions,
  HeadlineMatchesFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { BetlineFlag } from 'web/src/modules/sportline/enums/rest';
import {
  getHeadlineMatchesChanges as getHeadlineMatchesChangesRequest,
  getHeadlineMatches as getHeadlineMatchesRequest,
} from 'web/src/modules/sportline/utils/api';
import { isInvalidVTagError } from 'web/src/modules/sportline/utils/response';

import type { UseSportlineApiLoadHeadlineMatchesProps } from './types';

type FetchOptions = HeadlineMatchesFetchOptions & HeadlineChangesFetchOptions;

type FetchResponse = GetHeadlineMatchesResponse | GetHeadlineMatchesChangesResponse;

interface SportlineApiLoadHeadlineMatchesComposable {
  loadHeadlineMatches(options: FetchOptions): Promise<Maybe<FetchResponse>>;
}

export function useSportlineApiLoadHeadlineMatches(
  props: UseSportlineApiLoadHeadlineMatchesProps,
): SportlineApiLoadHeadlineMatchesComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
    isTopEventsEnabled,
  } = props;

  const flags = computed(() => (
    defaultRestApiFlags.value.filter((flag) => flag !== BetlineFlag.NoDuplicate)
  ));

  async function loadHeadlineMatches(options: FetchOptions): Promise<Maybe<FetchResponse>> {
    const {
      inplayVTag,
      prematchVTag,
      allVTag,
      silent,
      doForceDisableLiveOutrights,
      doForceMergeLists,
    } = options;
    const isMakeUpdateRequest = (!!inplayVTag && !!prematchVTag) || !!allVTag;

    let result: Maybe<FetchResponse> = null;

    if (isMakeUpdateRequest) {
      result = await tryRequestWithCTag((ctag) => getHeadlineMatchesChangesRequest({
        ctag,
        inplayHeadlineVtag: inplayVTag,
        prematchHeadlineVtag: prematchVTag,
        allVtag: allVTag,
        silent,
        flags: [...flags.value],
        LMCE: !doForceDisableLiveOutrights && isLiveOutrightsEnabled.value,
      }));
    }

    const isInvalidRequest = result && 'events' in result && !!result.events
      ? isInvalidVTagError(result.events)
      : (isInvalidVTagError(result?.live) || isInvalidVTagError(result?.prematch));

    if (!isMakeUpdateRequest || isInvalidRequest) {
      result = await tryRequestWithCTag((ctag) => getHeadlineMatchesRequest({
        ctag,
        silent,
        flags: [...flags.value],
        merged: !!doForceMergeLists || isTopEventsEnabled.value,
        LMCE: !doForceDisableLiveOutrights && isLiveOutrightsEnabled.value,
      }));
    }

    return result ?? null;
  }

  return {
    loadHeadlineMatches,
  };
}
