import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert, isOptionalString } from '@leon-hub/guards';
import { TextInputTypes } from '@leon-hub/input-types';

import type { TextInputProps } from 'web/src/components/Input';

import type { FormWrapperWidgetProperties } from '../../../types';
import getWrapperHintProperties from '../../../utils/getWrapperHintProperties';

interface UseFormCPFNumber {
  componentProps: ComputedRef<TextInputProps>;
}

export default function useFormCPFNumber(
  props: FormWrapperWidgetProperties,
): UseFormCPFNumber {
  const componentProps = computed<TextInputProps>(() => {
    const {
      name,
      value,
      options,
      title,
      error,
      disabled,
      hintMessage,
      autofocus,
    } = props;

    assert(isOptionalString(value), `Unexpected value type: ${typeof value}`);

    const parsedValue = value ? value.replace(/\D/g, '') : '';
    return {
      name,
      label: title,
      disabled,
      value: parsedValue,
      ...(options ?? {}),
      ...getWrapperHintProperties({ options, error, hintMessage }),
      mask: {
        pattern: '999.999.999-99',
        placeholder: '0',
      },
      placeholder: '000.000.000-00',
      type: TextInputTypes.NUMBER,
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
