import type {
  Ref,
} from 'vue';
import {
  computed,
} from 'vue';

import type { VIconProps } from '@components/v-icon';

import type { VStepDescriptionProps } from '../types';
import { getIconProperties } from './utils';

export interface VStepDescriptionComposable {
  iconProperties: Ref<VIconProps | null>;
}

export default function useVStepDescription(props: VStepDescriptionProps): VStepDescriptionComposable {
  const iconProperties = computed(() => getIconProperties(props));

  return {
    iconProperties,
  };
}
