import {
  LobbyGridType,
  LobbyItemWidget,
} from '@leon-hub/api-sdk';

import type { LobbyGridBaseProps } from 'web/src/modules/lobby/types';
import { LobbyGridLayoutType } from 'web/src/modules/lobby/enums';

export default function getModalGamesLobbyGridType(
  props: LobbyGridBaseProps,
): LobbyGridLayoutType | undefined {
  if (process.env.VUE_APP_LAYOUT_DESKTOP && props.isModal) {
    if (props.lobbyGridType === LobbyGridType.SMALL) {
      return props.widgetId === LobbyItemWidget.SQUARE_SWIPE_LIST
        ? LobbyGridLayoutType.COLUMN_MODAL_GAME_SQUARE_SMALL
        : LobbyGridLayoutType.COLUMN_MODAL_GAME_SMALL;
    }

    if (props.lobbyGridType === LobbyGridType.LARGE) {
      return LobbyGridLayoutType.COLUMN_2;
    }

    return LobbyGridLayoutType.COLUMN_MODAL_GAME_DEFAULT;
  }

  return undefined;
}
