import type {
  doRewardingQueryVariables,
  getAvailabilityQuery,
  getDetailsQuery,
  getReferralQuery,
  getRewardsQuery,
  getSiteConfigSettingsQuery,
} from '@leon-hub/api-sdk';

export type ReferralDataGetDataDocument = Exclude<getReferralQuery['queries']['refProgram']['getReferral']['data'], null>;

export type ProgramDetailsGetDataDocument = Exclude<getDetailsQuery['queries']['refProgram']['getDetails']['data'], null>;

export type RewardsGetDataDocument = Exclude<getRewardsQuery['queries']['refProgram']['getRewards']['data'], null>;

export type AvailabilityGetDataDocument = Exclude<getAvailabilityQuery['queries']['refProgram']['getAvailability']['data'], null>;

export type Referral = ProgramDetailsGetDataDocument['referrals'][0];

export type Requirement = Referral['requirements'][0];

export type SocialSharingNetworksGetDataDocument = Exclude<getSiteConfigSettingsQuery['queries']['system']['siteConfig']['data'], null>;
export type SocialSharingNetworks = SocialSharingNetworksGetDataDocument['profile']['referralProgramSocialItems'];
export type RewardOptions = doRewardingQueryVariables['options'];

export type ExtendedSocialSharingNetworkItem = SocialSharingNetworks[number] & {
  redirectUrl?: string;
  isFromAchievements?: boolean;
};

export type ExtendedSocialSharingNetworks = ExtendedSocialSharingNetworkItem[];

export interface ExtendedRequirement extends Requirement {
  label: string;
}

export interface ExtendedReferral extends Referral {
  initials: string;
  date: string;
  longDate: string;
  formattedAmount: string;
  amount: number;
  name: string;
  isIncognito: boolean;
  currentStep?: number;
  totalSteps?: number;
}
export enum ReferralProgramViewMode {
  MAIN = 'MAIN',
  LIST = 'LIST',
  LIST_BONUSES = 'LIST_BONUSES',
  DETAILS = 'DETAILS',
}
