<script lang="ts" setup>
import type { VReCaptchaBadgeProps } from './types';
import { useVReCaptchaBadge } from './composables';

const props = withDefaults(defineProps<VReCaptchaBadgeProps>(), {
  termsLinkTemplate: 'https://policies.google.com/terms?hl={lang}',
  privacyLinkTemplate: 'https://policies.google.com/privacy?hl={lang}',
});

const { privacyLink, termsLink } = useVReCaptchaBadge(props);
</script>

<template>
  <div v-auto-id="'VReCaptchaBadge'" :class="$style['captcha-badge']">
    {{ $t('JSP_PROTECTED_RECAPTCHA') }}<a
      target="_blank"
      :href="privacyLink"
      :class="$style['captcha-badge__link']"
    >{{ $t('JSP_CONDITIONS') }}</a>{{ $t('JSP_AND') }}<a
      target="_blank"
      :href="termsLink"
      :class="$style['captcha-badge__link']"
    >{{ $t('JSP_RULES') }}</a>
  </div>
</template>

<style lang="scss" module>
.captcha-badge {
  @include captchaBadgeFontStyle;

  padding: 12px 0;
  margin-top: 20px;
  color: $captchaBadgeFontColor;
  text-align: center;

  &__link {
    @include captchaBadgeFontStyle;

    padding: 0 3px;
    color: $captchaBadgeLinkFontColor;
    text-decoration: $linkDecoration;
    border-bottom: none;

    &:hover {
      color: $captchaBadgeLinkFontColorHover;
    }
  }
}
</style>
