import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  nextTick,
  ref,
  watch,
} from 'vue';

import { useWindowResize } from '@leon-hub/browser-composables';
import { useDebounce } from '@leon-hub/debounce';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useIntersectionObserver, useLifecycleResizeObserver } from '@leon-hub/vue-utils';

import type { VSwitcherProps } from '../types';

export interface VSwitcherComposable {
  grid: Ref<HTMLElement | undefined>;
  innerActiveIndex: Ref<number>;
  positionCalculated: Ref<boolean>;
  beforeMount(): Promise<void>;
  calculateActiveOffsetWidth(): void;
  selectionStyle: ComputedRef<Record<string, string | undefined>>;
}

export default function useVSwitcher(props: VSwitcherProps): VSwitcherComposable {
  const grid = ref<HTMLElement>();
  const innerActiveIndex = ref(0);
  const activeSlideTranslate = ref(0);
  const activeSlideWidth = ref(0);
  const positionCalculated = ref(false);
  const isTransitionAvailable = ref<boolean>(false);

  function setActiveIndex(activeId: string | undefined) {
    if (!activeId) return;
    const index = props?.options?.findIndex((item) => activeId === item.id) || -1;
    innerActiveIndex.value = index > -1 ? index : 0;
  }

  const selectionStyle = computed<Record<string, string | undefined>>(() => ({
    transform: `translateX(${activeSlideTranslate.value}px)`,
    transition: isTransitionAvailable.value ? 'transform 0.2s ease' : undefined,
    width: activeSlideWidth.value ? `${activeSlideWidth.value}px` : undefined,
  }));

  watch(() => props.activeId, (newValue: string) => {
    setActiveIndex(newValue);
    calculateActiveOffsetWidth();
  });

  watch(() => props.options, async () => {
    await nextTick();
    calculateActiveOffsetWidth();
  }, {
    deep: true,
  });

  const handleResize = useDebounce(({ deltaX }: { deltaX: number }) => {
    if (Math.abs(deltaX) > 0) {
      calculateActiveOffsetWidth();
    }
  }, 100);

  function calculateActiveOffsetWidth(): void {
    const slides = grid.value?.children;
    if (slides?.[innerActiveIndex.value]) {
      const activeSlideElement = slides[innerActiveIndex.value];
      if (activeSlideElement && activeSlideElement instanceof HTMLElement) {
        activeSlideTranslate.value = activeSlideElement.offsetLeft;
        activeSlideWidth.value = activeSlideElement.offsetWidth;
        positionCalculated.value = true;
      }
    } else {
      activeSlideTranslate.value = 0;
      activeSlideWidth.value = 0;
    }
  }

  async function beforeMount(): Promise<void> {
    setActiveIndex(props.activeId);
    calculateActiveOffsetWidth();

    await nextAnimationFrame();
    calculateActiveOffsetWidth();
  }

  useLifecycleResizeObserver(grid, calculateActiveOffsetWidth);
  useWindowResize(handleResize);
  useIntersectionObserver(grid, async (isIntersecting) => {
    calculateActiveOffsetWidth();
    if (isIntersecting) {
      await nextAnimationFrame();
      isTransitionAvailable.value = true;
    }
  }, undefined, true);

  return {
    grid,
    innerActiveIndex,
    positionCalculated,
    beforeMount,
    calculateActiveOffsetWidth,
    selectionStyle,
  };
}
