import { IconName, SlottIconName } from '@leon-hub/icons';

import type { PinButtonProps } from '../components/PinButton/types';
import { PinButtonEvent } from '../enums';

export const availableKeys = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  'Backspace',
];

export const pinButtonsProps: PinButtonProps[] = [
  {
    title: '1',
    value: PinButtonEvent.VALUE_1,
  },
  {
    title: '2',
    value: PinButtonEvent.VALUE_2,
  },
  {
    title: '3',
    value: PinButtonEvent.VALUE_3,
  },
  {
    title: '4',
    value: PinButtonEvent.VALUE_4,
  },
  {
    title: '5',
    value: PinButtonEvent.VALUE_5,
  },
  {
    title: '6',
    value: PinButtonEvent.VALUE_6,
  },
  {
    title: '7',
    value: PinButtonEvent.VALUE_7,
  },
  {
    title: '8',
    value: PinButtonEvent.VALUE_8,
  },
  {
    title: '9',
    value: PinButtonEvent.VALUE_9,
  },
  {
    value: PinButtonEvent.VISIBILITY,
    isFilled: false,
    iconName: IconName.VISIBILITY_OFF,
  },
  {
    title: '0',
    value: PinButtonEvent.VALUE_0,
  },
  {
    value: PinButtonEvent.BACKSPACE,
    isFilled: false,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? SlottIconName.SLOTT_BACKSPACE : IconName.BACKSPACE,
  },
];
