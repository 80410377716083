import { useRoute, useRouter } from 'vue-router';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import type { LobbyEgsNoGamesProps } from 'web/src/modules/lobby/components/LobbyEgsNoGames/types';
import { getDefaultLobbyLocationByRouteName } from 'web/src/modules/egs/utils';

export interface LobbyEgsNoGamesComposable {
  noGamesButtonProperties: Readonly<VButtonProps>;
  onClick(): void;
}

export default function useLobbyEgsNoGames(props: LobbyEgsNoGamesProps): LobbyEgsNoGamesComposable {
  const router = useRouter();
  const route = useRoute();

  const noGamesButtonProperties: Readonly<VButtonProps> = {
    rounded: true,
    isUppercase: false,
    kind: ButtonKind.BASE,
  };

  function onClick(): void {
    void router.push(props.routeName ? { name: props.routeName } : getDefaultLobbyLocationByRouteName(route));
  }

  return {
    noGamesButtonProperties,
    onClick,
  };
}
