<script setup lang="ts">
import { VCountryFlag, VFlagIcon } from '@components/country-flag';
import { VScrollbar } from '@components/v-scrollbar';

import type { DropdownMenuEmits, DropdownMenuProps } from '../types';
import { useDropdownMenu } from '../composables';
import getFreeBetAmount from '../utils/getFreeBetAmount';

const props = withDefaults(defineProps<DropdownMenuProps>(), {
  name: 'DropdownMenu',
  options: () => [],
  selectedValue: '',
  preselectedListIndex: -1,
});
const emits = defineEmits<DropdownMenuEmits>();

const {
  onAppear,
  onClick,
  onOptionHover,
  setButtonsRef,
  scrollbarRef,
} = useDropdownMenu(props, emits);
</script>

<template>
  <div v-auto-id="'DropdownMenu'"
    :class="{
      [$style['drop-menu']]: true,
      [$style['drop-menu--light']]: forcedLightTheme,
      [$style['drop-menu--direction-top']]: directionTop,
    }"
  >
    <VScrollbar
      ref="scrollbarRef"
      test-el="dropdown-select"
      :style="maxHeight ? { 'max-height': `${maxHeight}px` } : {}"
      use-scroll-listener
      @reached-bottom="emits('reached-bottom')"
    >
      <ul
        :class="$style['drop-menu__list']"
        @vue:mounted="onAppear"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          :class="$style['drop-menu__item']"
        >
          <button
            :ref="(el) => setButtonsRef(el, index)"
            type="button"
            :class="{
              [$style['drop-menu-button']]: true,
              [$style['drop-menu-button--spaced']]: props.isFreebet,
              [$style['drop-menu-button--light']]: props.forcedLightTheme,
              [$style['drop-menu-button--selected']]: preselectedListIndex === index,
            }"
            :data-value="option.value"
            @focus.prevent.stop
            @click="onClick(option.value)"
            @mouseenter="onOptionHover(index)"
          >
            <template v-if="props.isFreebet">
              <span :class="$style['drop-menu-button__left']">{{ option.label }}</span>
              <span :class="$style['drop-menu-button__right']">{{ getFreeBetAmount(option, isFreebet) }}</span>
            </template>
            <template v-else-if="isCountrySelector">
              <template v-if="null">
                <VFlagIcon
                  :country-code="option.value"
                  :class="$style['drop-menu-button__flag']"
                />{{ option.label }}
              </template>
              <template v-else>
                <VCountryFlag
                  :name="option.value"
                  :class="$style['drop-menu-button__flag']"
                />{{ option.label }}
              </template>
            </template>
            <template v-else>
              {{ option.label }}
            </template>
          </button>
        </li>
        <li
          v-if="searchQuery && !options?.length"
          :class="$style['drop-menu__item']"
        >
          <button
            type="button"
            :class="[
              $style['drop-menu-button'],
              $style['drop-menu-button--empty'],
            ]"
            @focus.prevent.stop
            @click="emits('clear-search-query')"
          >
            {{ $t('WEB2_NOTHING_FOUND') }}
          </button>
        </li>
      </ul>
    </VScrollbar>
  </div>
</template>

<style lang="scss" module>
.drop-menu {
  @include dropMenu;

  margin-top: 4px;
  overflow: hidden;
  background: var(--Layer1);

  &--light {
    background: var(--LLayer1);
  }

  &--direction-top {
    margin-top: -2px;
    box-shadow: 0 -8px 8px var(--ModalShadow);
  }

  &__list {
    padding: $dropMenuListPadding;
    margin: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
  }
}

.drop-menu-button {
  $self: &;

  @include dropMenuButton;

  display: flex;
  flex: 1;
  align-items: center;
  max-width: 100%;
  color: var(--TextDefault);
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  background: transparent;
  border: none;

  &--spaced {
    justify-content: space-between;
  }

  &--light {
    color: var(--LTextDefault);
  }

  &--selected {
    @include dropMenuButtonSelected;
  }

  &--light#{$self}--selected {
    background-color: var(--LHighlight);
  }

  &--empty {
    color: var(--TextSecondary);
  }

  &__left {
    @include regular\14\16\025;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__right {
    @include medium\18\20;
  }

  &__flag {
    margin-right: 12px;
  }
}
</style>
