import { toRef } from 'vue';

import type { SportlineApiService } from 'web/src/modules/sportline/types/services';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

import type {
  UseSportlineApiLoadEventsProps,
  UseSportlineApiLoadHeadlineMatchesProps,
  UseSportlineApiLoadTreeEntitiesProps,
} from './composables/types';
import { useCTagController } from './composables/useCTagController';
import { useSportlineApiLoadComingEvents } from './composables/useSportlineApiLoadComingEvents';
import useSportlineApiLoadCounters from './composables/useSportlineApiLoadCounters';
import { useSportlineApiLoadEventDetails } from './composables/useSportlineApiLoadEventDetails';
import { useSportlineApiLoadFavoritesEvents } from './composables/useSportlineApiLoadFavoritesEvents';
import { useSportlineApiLoadFavoritesLeaguesEvents } from './composables/useSportlineApiLoadFavoritesLeaguesEvents';
import { useSportlineApiLoadHeadlineMatches } from './composables/useSportlineApiLoadHeadlineMatches';
import { useSportlineApiLoadLeague } from './composables/useSportlineApiLoadLeague';
import useSportlineApiLoadLeagueEvents from './composables/useSportlineApiLoadLeagueEvents';
import { useSportlineApiLoadLiveEvents } from './composables/useSportlineApiLoadLiveEvents';
import { useSportlineApiLoadRegion } from './composables/useSportlineApiLoadRegion';
import { useSportlineApiLoadRegionEvents } from './composables/useSportlineApiLoadRegionEvents';
import { useSportlineApiLoadRelatedEvents } from './composables/useSportlineApiLoadRelatedEvents';
import { useSportlineApiLoadSport } from './composables/useSportlineApiLoadSport';
import { useSportlineApiLoadSportsTree } from './composables/useSportlineApiLoadSportsTree';
import { useSportlineApiLoadZeroMarginEvents } from './composables/useSportlineApiLoadZeroMarginEvents';

export default function createSportlineApiService(): SportlineApiService {
  const { tryRequestWithCTag } = useCTagController();

  const settingsStore = useSportlineSettingsStore();
  const defaultRestApiFlags = toRef(() => settingsStore.defaultRestApiFlags);
  const sportsRestApiFlags = toRef(() => settingsStore.sportsRestApiFlags);
  const isZeroMarginEnabled = toRef(() => settingsStore.isZeroMarginEnabled);
  const isLiveOutrightsEnabled = toRef(() => settingsStore.isLiveOutrightsEnabled);
  const isExtraLiveEnabled = toRef(() => settingsStore.isExtraLiveEnabled);
  const isTopEventsEnabled = toRef(() => settingsStore.isTopEventsEnabled);

  const options: UseSportlineApiLoadEventsProps = {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isZeroMarginEnabled,
    isLiveOutrightsEnabled,
    isExtraLiveEnabled,
  };
  const treeOptions: UseSportlineApiLoadTreeEntitiesProps = {
    tryRequestWithCTag,
    sportsRestApiFlags,
    isLiveOutrightsEnabled,
    isExtraLiveEnabled,
  };
  const headlineOptions: UseSportlineApiLoadHeadlineMatchesProps = {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
    isTopEventsEnabled,
  };

  const loadSportsTree = useSportlineApiLoadSportsTree(treeOptions);
  const loadEventDetails = useSportlineApiLoadEventDetails(options);
  const loadRelatedEvents = useSportlineApiLoadRelatedEvents(options);
  const loadComingEvents = useSportlineApiLoadComingEvents(options);
  const loadLiveEvents = useSportlineApiLoadLiveEvents(options);
  const loadZeroMarginEvents = useSportlineApiLoadZeroMarginEvents(options);
  const loadCounters = useSportlineApiLoadCounters(options);
  const loadLeagueEvents = useSportlineApiLoadLeagueEvents(options);
  const loadRegionEvents = useSportlineApiLoadRegionEvents(options);
  const loadFavoriteLeaguesEvents = useSportlineApiLoadFavoritesLeaguesEvents(options);
  const loadFavoriteEvents = useSportlineApiLoadFavoritesEvents(options);
  const loadSport = useSportlineApiLoadSport(treeOptions);
  const loadRegion = useSportlineApiLoadRegion(treeOptions);
  const loadLeague = useSportlineApiLoadLeague(treeOptions);
  const loadHeadline = useSportlineApiLoadHeadlineMatches(headlineOptions);

  return {
    ...loadSportsTree,
    ...loadRelatedEvents,
    ...loadLiveEvents,
    ...loadComingEvents,
    ...loadZeroMarginEvents,
    ...loadCounters,
    ...loadLeagueEvents,
    ...loadRegionEvents,
    ...loadEventDetails,
    ...loadFavoriteLeaguesEvents,
    ...loadFavoriteEvents,
    ...loadSport,
    ...loadRegion,
    ...loadLeague,
    ...loadHeadline,
  };
}
