import debounce from 'lodash/debounce';
import {
  defineStore,
} from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useSiteConfigStore } from '@core/site-config';

import type {
  EgsGame,
  EgsGroup,
  GamesPaginationCursorItem,
} from 'web/src/modules/egs/types';
import { useEgsApi } from 'web/src/modules/egs/composables';

const useSearchCasinoStore = defineStore('search-casino', () => {
  const analytics = useAnalytics();
  const { getGroups, getGames } = useEgsApi();
  const searchMinCharsSize = toRef(useSiteConfigStore(), 'searchMinCharsSize');

  const searchText = ref('');
  const games = ref<EgsGame[] | null>(null);
  const groups = ref<EgsGroup[] | null>(null);
  const gamesCursor = ref<GamesPaginationCursorItem | null>(null);
  const isMoreItemsLoading = ref(false);

  const isSearchAvailable = computed(() => searchText.value.length >= searchMinCharsSize.value);
  const isSearchInProgress = computed(() => isSearchAvailable.value && (
    !games.value || !groups.value
  ));
  const isNoSearchResults = computed(() => (
    isSearchAvailable.value
    && !!games.value && games.value.length === 0
    && !!groups.value && groups.value.length === 0
  ));
  const isMoreLoadingAvailable = computed(() => !isMoreItemsLoading.value && !!gamesCursor.value);

  async function fetchGames(isMore = false): Promise<void> {
    if (!isMore) {
      games.value = null;
    }

    const result = await getGames({
      gameName: searchText.value,
      limit: isMore ? gamesCursor.value?.limit : null,
      offset: isMore ? gamesCursor.value?.offset : null,
    });

    games.value = isMore ? (games.value ?? []).concat(result.games) : result.games;
    gamesCursor.value = result.next;
  }

  async function fetchGroups(): Promise<void> {
    groups.value = null;

    groups.value = await getGroups({
      groupName: searchText.value,
    });
  }

  async function fetchMoreGames(): Promise<void> {
    if (!gamesCursor.value) {
      return;
    }

    isMoreItemsLoading.value = true;
    await fetchGames(true);
    isMoreItemsLoading.value = false;
  }

  const doSearch = debounce(() => {
    void Promise.all([
      fetchGames(),
      fetchGroups(),
    ]);
  }, 500);

  function setSearchText(value: string): void {
    searchText.value = value;
    games.value = null;
    groups.value = null;
    gamesCursor.value = null;
    isMoreItemsLoading.value = false;

    if (!isSearchAvailable.value) {
      return;
    }

    doSearch();
  }

  function sendAnalytics(isClicked = false): void {
    const results = isSearchAvailable.value && !isNoSearchResults.value;

    analytics.push(AnalyticsEvent.Z_SEARCH_GAME, {
      searchDetails: {
        games: {
          [searchText.value]: {
            [results ? 'true' : 'false']: isClicked ? 'clicked' : 'nonClicked',
          },
        },
      },
    });
  }

  return {
    searchText,
    games,
    groups,
    isSearchInProgress,
    isNoSearchResults,
    isMoreLoadingAvailable,
    isSearchAvailable,
    setSearchText,
    fetchMoreGames,
    sendAnalytics,
  };
});

export default useSearchCasinoStore;
