import type { Ref } from 'vue';
import { nextTick, ref } from 'vue';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

interface UseMarketsListReadyToRenderObserverProps {
  marketHolder: Ref<Optional<HTMLDivElement>>;
  recalculateColumnsLayout(): void;
}

interface UseMarketsListReadyToRenderObserverComposable {
  isReadyToRender: Ref<boolean>;
  setIsActivated(value: boolean): void;
}

export function useMarketsListReadyToRenderObserver(
  props: UseMarketsListReadyToRenderObserverProps,
): UseMarketsListReadyToRenderObserverComposable {
  const {
    marketHolder,
    recalculateColumnsLayout,
  } = props;

  const isActivated = ref(false);
  const isReadyToRender = ref(false);

  useIntersectionObserver(marketHolder, (isIntersecting: boolean) => {
    if (isIntersecting) {
      isReadyToRender.value = true;
      void nextTick().then(recalculateColumnsLayout);
    }
  }, {
    rootMargin: '0px',
    threshold: 0.1,
  }, true);

  function setIsActivated(value: boolean): void {
    isActivated.value = value;
    void nextTick().then(recalculateColumnsLayout);
  }

  return {
    isReadyToRender,
    setIsActivated,
  };
}
