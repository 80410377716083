import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineType } from 'web/src/modules/sportline/enums';
import type { MarketsGridCell } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { Market, Runner, SportlineEvent } from 'web/src/modules/sportline/types';
import type { SlipInfoCaption } from 'web/src/modules/sportline/views/runner/types';
import { getSlipCaption } from 'web/src/modules/sportline/views/runner/utils';

interface MarketsListMarketProps {
  group: Ref<MarketsGridCell>;
  sportEvent: Ref<SportlineEvent>;
}

interface MarketRow {
  market: Market;
  runners: Maybe<Runner>[];
}

interface MarketsListMarketComposable {
  slipCaption: Ref<SlipInfoCaption>;
  sportlineEventId: Ref<string>;
  isOutrightSportlineEvent: Ref<boolean>;
  sportlineEventType: Ref<SportlineType>;
  isDynamicColumns: Ref<boolean>;
  flatRows: Ref<MarketRow[]>;
}

export function useMarketsListMarket(
  props: MarketsListMarketProps,
): MarketsListMarketComposable {
  const {
    group,
    sportEvent,
  } = props;

  const slipCaption = computed<SlipInfoCaption>(() => getSlipCaption(sportEvent.value));
  const sportlineEventId = computed<string>(() => sportEvent.value.id);
  const sportlineEventType = computed(() => sportEvent.value.type);
  const isOutrightSportlineEvent = computed(() => sportEvent.value.isOutright);
  // @TODO check this. It seems deprecated
  const isDynamicColumns = computed<boolean>(() => group.value.markets[0]?.columns === 1);
  // @TODO review this mapping
  const flatRows = computed<MarketRow[]>(() => group.value.markets.flatMap((market) => {
    const { columns } = market.type;
    const rows: {
      runner: Runner | null;
    }[][] = [];

    for (const runner of market.runners) {
      rows[runner.row] = rows[runner.row] || ((Array.from({ length: columns })).fill(null));
      rows[runner.row][runner.column] = { runner };
    }

    return rows.map((row) => ({
      market,
      runners: row.flatMap((r) => (r?.runner ?? null)),
    }));
  }));

  return {
    slipCaption,
    sportlineEventId,
    sportlineEventType,
    isOutrightSportlineEvent,
    isDynamicColumns,
    flatRows,
  };
}
