<script setup lang="ts">
import { IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';

import InputHint from 'web/src/components/Input/components/InputHint';
import FileInputPlaceholder from 'web/src/components/PhotoPlaceholder/VFileInputPlaceholder/VFileInputPlaceholder.vue';

import type { FileMultipleDragAndDropInputProps, FileMultipleEmits } from '../../types';
import { useHintParentProps } from '../../composables';
import { defaultFileTypes } from '../../utils/defaultFileTypes';
import useFileMultipleDragAndDropInput from './useFileMultipleDragAndDropInput';

const props = withDefaults(defineProps<FileMultipleDragAndDropInputProps>(), {
  name: 'fileDragAndDrop',
  formErrors: () => [],
  fileTypes: () => defaultFileTypes,
});

const emits = defineEmits<FileMultipleEmits>();

const { hintProperties } = useHintParentProps(props);

const {
  errorList,
  placeholderPropsList,
  uniqueId,
  acceptedFileTypes,
  filesRecords,
  isInDropZone,
  addMoreAvailable,
  onLabelKeydown,
  removeByIndex,
  onChange,
  onBlur,
  onDragenter,
  onDragleave,
  onDragover,
  onDrop,
} = useFileMultipleDragAndDropInput(props, emits);
</script>

<template>
  <div v-auto-id="'FileDragAndDropInput'">
    <div
      :class="{
        [$style['drag-and-drop-input']]: true,
        [$style['drag-and-drop-input--drag-over']]: isInDropZone,
        [$style['drag-and-drop-input--empty']]: filesRecords.length === 0,
        [$style['drag-and-drop-input--error']]: !!error || !!errorRight || errorList?.length > 0,
      }"
      @dragenter="onDragenter"
      @drop="onDrop"
    >
      <FileInputPlaceholder
        v-for="(placeholderProps, index) in placeholderPropsList"
        :key="index"
        :class="[
          $style['drag-and-drop-input__item'],
          $style['drag-and-drop-input__item--placeholder'],
        ]"
        v-bind="placeholderProps"
        @clear="removeByIndex(index)"
      />
      <div
        v-if="addMoreAvailable"
        :class="[
          $style['drag-and-drop-input__item'],
          $style['drag-and-drop-input__item--controls'],
        ]"
      >
        <VButton
          :class="$style['drag-and-drop-input__add-button']"
          :kind="ButtonKind.BASE"
          :height="ButtonHeight.LARGE"
          :icon-name="IconName.PLUS_SMALL"
          :tag="Tag.LABEL"
          :for="uniqueId"
          rounded
        />
        <div :class="$style['drag-and-drop-input__text-primary']">
          {{ $t('WEB2_ADD_FILE_INPUT_TEXT_PRIMARY') }}
        </div>
        <div :class="$style['drag-and-drop-input__text-secondary']">
          {{ $t('WEB2_ADD_FILE_INPUT_TEXT_SECONDARY') }}
        </div>
        <VButton
          :class="$style['drag-and-drop-input__add-button']"
          :label="$t('WEB2_ADD_FILE_INPUT_BUTTON')"
          :kind="ButtonKind.PRIMARY"
          :tag="Tag.LABEL"
          :for="uniqueId"
          full-width
          tabindex="0"
          @keydown.enter.space="onLabelKeydown"
        />
        <input
          :id="uniqueId"
          :name="name"
          :class="$style['drag-and-drop-input__input']"
          type="file"
          multiple
          :accept="acceptedFileTypes"
          @change.stop="onChange"
          @input.stop
          @blur="onBlur(filesRecords.length)"
        >
      </div>
      <div
        :class="$style['drag-and-drop-input__drag-zone']"
        @dragleave="onDragleave"
        @dragover="onDragover"
      />
    </div>
    <InputHint
      v-bind="hintProperties"
    >
      <slot name="hint" />
      <template #hintLeft>
        <slot name="hintLeft" />
      </template>
      <template #hintRight>
        <slot name="hintRight" />
      </template>
      <template #errorLeft>
        <div
          v-for="(message, index) in errorList"
          :key="index"
          :class="$style['drag-and-drop-input__error']"
        >
          {{ message }}
        </div>
      </template>
    </InputHint>
  </div>
</template>

<style lang="scss" module>
.drag-and-drop-input {
  @import '../../common';

  $self: &;

  display: grid;
  grid-template-columns: repeat(auto-fit, 164px);
  gap: 12px 16px;
  padding: 30px 20px;
  margin-top: 20px;
  border: 1px dashed $fileDragAndDropInputBorderColor;
  border-radius: 5px;

  &--empty {
    display: flex;
    justify-content: center;
  }

  &--error {
    border-color: $fileDragAndDropInputColorError;
  }

  &--drag-over {
    position: relative;
    border-color: $fileDragAndDropInputDragOver;
  }

  &__drag-zone {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;

    #{$self}--drag-over & {
      display: block;
    }
  }

  &__item {
    width: 164px;
    overflow: hidden;

    &--placeholder {
      max-height: 180px;
      background: $fileDragAndDropInputPlaceholder;
    }

    &--controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      min-height: 180px;
    }
  }

  &__text-primary {
    @include fileDragAndDropInputFontPrimary;

    text-align: center;
  }

  &__text-secondary {
    @include fileDragAndDropInputFontSecondary;

    text-align: center;
  }

  &__add-button {
    flex-shrink: 0;
  }

  &__input {
    display: none;
  }

  &__error {
    margin-bottom: 8px;

    &:first-letter {
      text-transform: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 4px;
    }
  }
}
</style>
