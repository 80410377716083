import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import type { VBalanceProps } from 'web/src/components/Balance/VBalance/types';

function getButtonHeight(
  props: Pick<VBalanceProps, 'isBigIcon' | 'isBalanceHidden'>,
): ButtonHeight {
  if (props.isBigIcon) {
    return ButtonHeight.LARGE;
  }

  if (props.isBalanceHidden) {
    return ButtonHeight.SMALL;
  }

  return ButtonHeight.TINY;
}

export default function baseButtonProps(
  props: VBalanceProps,
): VButtonProps {
  const heightButton = computed(() => getButtonHeight(props));
  return {
    height: heightButton.value,
    rounded: true,
    iconSize: IconSize.SIZE_16,
    kind: process.env.VUE_APP_FEATURE_DARK_HEADER_ENABLED ? ButtonKind.PRIMARY_DARK_VERSION : ButtonKind.PRIMARY,
  } as VButtonProps;
}
