<script lang="ts" setup>
import { useFocusableElement } from '@leon-hub/focus';
import { IconName } from '@leon-hub/icons';

import TextInput from 'web/src/components/Input/components/TextInput/TextInput.vue';

import type { VSearchInputEmits, VSearchInputProps, VSearchInputRef } from './types';
import { useVSearchInput } from './composables';

withDefaults(defineProps<VSearchInputProps>(), {
  placeholder: '',
  value: '',
});
const emit = defineEmits<VSearchInputEmits>();

const {
  onInput,
  onClear,
} = useVSearchInput(emit);

const { focus, focusable } = useFocusableElement();

defineExpose<VSearchInputRef>({
  focusInputElement: focus,
});
const iconSearch = null ? IconName.SLOTT_SEARCH : IconName.SEARCH;
</script>

<template>
  <TextInput v-auto-id="'VSearchInput'"
    ref="focusable"
    name="search"
    :autofocus="autofocus"
    v-bind="isIconRight ? { suffixIconName: iconSearch } : { prefixIconName: iconSearch }"
    :value="value"
    :placeholder="placeholder"
    :is-large="isLarge"
    autocomplete="off"
    @input="onInput"
    @clear="onClear"
  />
</template>
