<script lang="ts" setup>
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';
import VSwiperBannerNavigationButtons
  from 'web/src/modules/banners/components/VSwiperBannerNavigation/VSwiperBannerNavigationButtons.vue';

import type { SwiperBannerEmits, SwiperBannerProps } from './types';
import { useSwiperBanner } from './composables';

const props = withDefaults(defineProps<SwiperBannerProps>(), {
  bannerType: BannerType.MAIN_BANNER,
  aspectRatio: 1.75,
});
const emit = defineEmits<SwiperBannerEmits>();

const {
  swiper,
  banners,
  emitBannerItemClick,
  isGroupBannersDev,
  activeIndex,
  bannersKey,
  isMouseOver,
  emitSlideChanged,
  onMouseEnter,
  onMouseLeave,
  onActivatedAction,
  onDeactivatedAction,
} = useSwiperBanner(props, emit);

onComponentActivated(onActivatedAction);
onComponentDeactivated(onDeactivatedAction);
</script>

<template>
  <VSwiper v-auto-id="'SwiperBanner'"
    ref="swiper"
    :key="bannersKey"
    is-infinite-loop
    is-scroll-snap-enabled
    :class="$style['swiper-banner']"
    @scroll="emitSlideChanged"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <VSwiperSlide
      v-for="(banner, index) in banners"
      :key="banner.id"
      :class="$style['swiper-banner__slide']"
    >
      <MainBannerItem
        :banner="banner"
        :is-group-banners-dev="isGroupBannersDev"
        :group-id="bannerGroup?.id"
        :is-active="!isMouseOver && index === activeIndex"
        :banner-type="bannerType"
        is-visible-image
        is-half-width
        is-prerender-optimized
        @click="emitBannerItemClick($event, banner)"
      />
    </VSwiperSlide>
    <template #pagination-footer>
      <VSwiperBannerNavigationButtons />
    </template>
  </VSwiper>
</template>

<style lang="scss" module>
$spaceBetween: 16px;

.swiper-banner {
  &__slide {
    width: calc(50% + (#{$spaceBetween} / 2));
    padding-right: #{$spaceBetween};

    &:first-child:last-child {
      width: 100%;
      padding-right: 0;
    }
  }
}
</style>
