import type { Web2Image } from '@leon-hub/api-sdk';
import type { AlertIconName, AlertIconSize, IconSize, OptionalAnyIconName } from '@leon-hub/icons';

import type { JumbotronIconKind } from '@components/jumbotron';

import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import { getImageOrIcon } from 'web/src/modules/icons';

export function getJumbotronProps({
  alertIconSize,
  alertIcon,
  image,
  iconKind,
  iconSize,
  icon,
}: {
  iconKind?: JumbotronIconKind;
  iconSize?: IconSize;
  icon?: OptionalAnyIconName;
  image?: Web2Image;
  alertIcon?: AlertIconName;
  alertIconSize?: AlertIconSize;
}): Pick<VJumbotronProps, 'image' | 'icon' | 'iconKind' | 'iconSize' | 'alertIconSize'> {
  const props = getImageOrIcon({ icon, alertIcon, image });
  // alert icon is active when there is no image (overrides icon).
  const isAlertIconActive = alertIcon && !image && props.image;
  return {
    ...props,
    icon: props.iconName,
    alertIconSize: isAlertIconActive ? alertIconSize : undefined,
    iconKind: props.iconName ? iconKind : undefined,
    iconSize: props.iconName ? iconSize : undefined,
  };
}
