import { toRef } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import { useCountryStore } from '@core/country';

import sanitize from 'web/src/utils/content/sanitize';

import type { AddressOption } from '../types';
import useAddressApi from '../../../services/api/useAddressApi';
import { mapAddressPredictionToAddressOption } from '../utils';

interface SearchAddressPayload {
  address: string;
  countryCode?: string;
  types?: string[];
}

interface UseAddressSearch {
  findAddressByQuery(payload: SearchAddressPayload): Promise<AddressOption[]>;
}

export function useAddressSearch(): UseAddressSearch {
  const countryStore = useCountryStore();
  const defaultCountryCode = toRef(countryStore, 'countryCode');

  const { autocompleteAddress } = useAddressApi();

  const findAddressByQuery = async ({
    address,
    countryCode,
    types,
  }: SearchAddressPayload): Promise<AddressOption[]> => {
    const safeAddress = sanitize(address);
    if (!safeAddress) {
      return [];
    }
    try {
      const typesString = types?.length ? types.join(',') : undefined;
      const response = await autocompleteAddress({
        countryCode: countryCode || defaultCountryCode.value.toLowerCase(),
        address: safeAddress,
        types: typesString,
      });
      if (response?.predictions?.length) {
        return response.predictions.map((item) => mapAddressPredictionToAddressOption(item));
      }
      return [];
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
      return [];
    }
  };

  return {
    findAddressByQuery,
  };
}
