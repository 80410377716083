import { CustomInputEventType, InputEventType } from '@leon-hub/input-types';

import type { FormDataEvent, FormOutput } from '../../../types';
import type { VFormEmits } from '../types';

type UseFormEmitsAddEventListenerCallbackEventName = 'blur';
type UseFormEmitsAddEventListenerCallback = (event: FormOutput) => void;
export type UseFormEmitsAddEventListener = (name: UseFormEmitsAddEventListenerCallbackEventName, cb: UseFormEmitsAddEventListenerCallback) => void;

interface UseFormEmits {
  addEventListener: UseFormEmitsAddEventListener;
  onChange(event: FormDataEvent): void;
  onInput(event: FormDataEvent): void;
  onFocus(event: FormDataEvent): void;
  onBlur(event: FormDataEvent): void;
  onSubmit(event?: Event): void;
  onFilled(): void;
  onIconPrefixClick(field: string): void;
  onIconSuffixClick(field: string): void;
  // LEONWEB-9747
  onDisabledButtonClick(): void;
}

type HandlerFunc = (event: FormDataEvent) => FormOutput;

interface Options {
  handleInput: HandlerFunc;
  handleFocus: HandlerFunc;
  handleBlur: HandlerFunc;
  handleSubmit(): FormOutput;
  getCurrentOutput(): FormOutput;
}

export default function useFormEmits(emit: VFormEmits, {
  handleInput,
  handleFocus,
  handleBlur,
  handleSubmit,
  getCurrentOutput,
}: Options): UseFormEmits {
  const listeners: Partial<Record<UseFormEmitsAddEventListenerCallbackEventName, UseFormEmitsAddEventListenerCallback[]>> = {};
  const addEventListener: UseFormEmitsAddEventListener = (name, callback) => {
    const handlers: UseFormEmitsAddEventListenerCallback[] = listeners[name] ?? [];
    listeners[name] = handlers.concat(callback);
  };

  function onChange(event: FormDataEvent): void {
    const data = handleInput(event);
    emit(InputEventType.CHANGE, data);
  }

  function onInput(event: FormDataEvent): void {
    const data = handleInput(event);
    emit(InputEventType.INPUT, data);
  }

  function onFocus(event: FormDataEvent): void {
    const data = handleFocus(event);
    emit(InputEventType.FOCUS, data);
  }

  function onBlur(event: FormDataEvent): void {
    const data = handleBlur(event);
    emit(InputEventType.BLUR, data);
    listeners.blur?.forEach((callback) => callback(data));
  }

  function onSubmit(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    const data = handleSubmit();
    emit(InputEventType.SUBMIT, data);
  }

  function onFilled(): void {
    emit(CustomInputEventType.FILLED);
  }

  function onIconPrefixClick(field: string): void {
    emit(CustomInputEventType.ICON_PREFIX_CLICK, field);
  }
  function onIconSuffixClick(field: string): void {
    emit(CustomInputEventType.ICON_SUFFIX_CLICK, field);
  }

  // LEONWEB-9747
  function onDisabledButtonClick(): void {
    emit('disabled-button-click', getCurrentOutput());
  }

  return {
    addEventListener,
    onChange,
    onInput,
    onFocus,
    onBlur,
    onSubmit,
    onFilled,
    onIconPrefixClick,
    onIconSuffixClick,
    onDisabledButtonClick,
  };
}
