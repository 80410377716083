import type { MaybeRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { SportlineEventId } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

import { checkMatchStreamAccess } from '../utils';
import { createIsPreviewOnlyRef } from './createIsPreviewOnlyRef';
import { useMatchStreamAccess } from './useMatchStreamAccess';

interface StreamUrlAvailableProps {
  sportlineEventId: MaybeRef<Maybe<SportlineEventId>>;
  sportlineType: MaybeRef<Maybe<SportlineType>>;
}

interface StreamUrlAvailableComposable {
  isStreamAvailable: Ref<boolean>;
  isPreviewOnly: Ref<boolean>;
}

export function useStreamUrlAvailable(
  props: StreamUrlAvailableProps,
): StreamUrlAvailableComposable {
  const sportlineEventId = toRef(props.sportlineEventId);
  const sportlineType = toRef(props.sportlineType);

  const isStreamsEnabled = toRef(() => useSportlineSettingsStore().isLiveStreamEnabled);
  const matchStreamAccess = useMatchStreamAccess();

  const isStreamAvailable = computed<boolean>(() => {
    if (!isStreamsEnabled.value || !sportlineEventId.value) {
      return false;
    }

    return checkMatchStreamAccess(matchStreamAccess.value, {
      id: sportlineEventId.value,
      type: sportlineType.value ?? SportlineType.Live,
    });
  });

  const isPreviewOnly = createIsPreviewOnlyRef(sportlineEventId);

  return {
    isStreamAvailable,
    isPreviewOnly,
  };
}
