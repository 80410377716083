<script lang="ts" setup>
/* eslint-disable vue/no-duplicate-attr-inheritance */
import {
  computed,
  inject,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type {
  CmsContentEmits,
  CmsContentProps,
  CmsContentSlots,
} from 'web/src/modules/cms/components/types';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useBaseCmsContent } from 'web/src/modules/cms/components/composables';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';
import { setHashWithoutRouter } from 'web/src/utils/url/hash';

const props = withDefaults(defineProps<CmsContentProps>(), {
  doNotUpdateIfExists: true,
});
const emit = defineEmits<CmsContentEmits>();
defineSlots<CmsContentSlots>();

const route = useRoute();
const bus = useEventsBus();

const currentCmsKey = computed<string>(() => props.cmsKey || props.cmsData?.keys[0] || '');

function goToHash(): void {
  if (props.stopHashAutoScroll) {
    return;
  }

  const { hash } = route;
  if (hash) {
    const modalContext = inject(ModalProvidable.modalContext, {
      insideModal: false,
    });

    bus.emit(BusEvent.SCROLL_TO_ELEMENT_ID, {
      id: hash.slice(1),
      inModal: modalContext.insideModal,
    });
    if (modalContext.insideModal) {
      setHashWithoutRouter('');
    }
  }
}

const {
  content,
  isLoaded,
  className,
  loadData,
} = useBaseCmsContent(props, {
  currentCmsKey,
  onLoadCallback: () => {
    goToHash();
    emit('loaded');
  },
});

watch(() => props.cmsKey, () => {
  void loadData();
});
</script>

<template>
  <VLoaderDelayed
    v-if="loader && !isLoaded"
    class="component-wrapper"
  />
  <!-- eslint-disable vue/no-duplicate-attr-inheritance -->
  <VDynamicContent
    v-else-if="content"
    v-bind="$attrs"
    is-cms-content
    :content="content"
    :class="className"
    @click-link="$emit('click-link')"
  />
  <template v-else-if="!content">
    <slot name="empty" />
  </template>
  <div
    v-else
    class="component-wrapper"
  />
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  @include cmsContentLocalStyles($isDesktop);
}
</style>
