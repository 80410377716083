import type { Ref } from 'vue';
import { toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';

import { useSlipRootStore } from '../../slip-root/store';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { useGetSharedSlipStore } from '../store';

interface UseGetSharedSlip {
  isReceiveFromCodeEnabled: Ref<boolean>;
  isWaitingToSlipFromCode: Ref<boolean>;
  isSlipFromBookingCodeOverlayVisible: Ref<boolean>;
  isAllSharedEventsExpired: Ref<boolean>;
  isLoggedIn: Ref<boolean>;
  isBookingCodeFromLink: Ref<boolean>;
  bookingCodeError: Ref<string>;
  sharedBookingCode: Ref<string>;
  getSlipFromBookingCode(isCodeFromLink?: boolean): Promise<void>;
  onBookingCodeInput(value: string): void;
  onOverlayButtonClick(): void;
  setSharedBookingCode(value: string): void;
  resetBookingCodeForm(): void;
}

export function useGetSharedSlip(): UseGetSharedSlip {
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();

  const getSharedSlipStore = useGetSharedSlipStore();

  const isReceiveFromCodeEnabled = toRef(getSharedSlipStore, 'isReceiveFromCodeEnabled');

  const isWaitingToSlipFromCode = toRef(getSharedSlipStore, 'isWaitingToSlipFromCode');
  const bookingCodeError = toRef(getSharedSlipStore, 'bookingCodeError');
  const isAllSharedEventsExpired = toRef(getSharedSlipStore, 'isAllSharedEventsExpired');
  const sharedBookingCode = toRef(getSharedSlipStore, 'sharedBookingCode');
  const isBookingCodeFromLink = toRef(getSharedSlipStore, 'isBookingCodeFromLink');

  const {
    getSlipFromBookingCode,
    clearBonusCodeError,
    setSharedBookingCode,
    resetBookingCodeForm,
  } = getSharedSlipStore;

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const isSlipFromBookingCodeOverlayVisible = toRef(slipViewSettingsStore, 'isSlipFromBookingCodeOverlayVisible');

  const { handleSlipClose } = useSlipRootStore();

  const { hideSlipFromBookingCodeOverlay } = slipViewSettingsStore;

  const onOverlayButtonClick = (): void => {
    if (isAllSharedEventsExpired.value) {
      handleSlipClose();
      void router.push({ name: isLoggedIn.value ? RouteName.HOME : RouteName.LOGIN });
    } else {
      hideSlipFromBookingCodeOverlay();
    }
  };

  const onBookingCodeInput = (value: string): void => {
    clearBonusCodeError();
    setSharedBookingCode(value);
  };

  return {
    isReceiveFromCodeEnabled,
    isWaitingToSlipFromCode,
    bookingCodeError,
    isSlipFromBookingCodeOverlayVisible,
    isAllSharedEventsExpired,
    isLoggedIn,
    sharedBookingCode,
    isBookingCodeFromLink,
    getSlipFromBookingCode,
    onBookingCodeInput,
    onOverlayButtonClick,
    setSharedBookingCode,
    resetBookingCodeForm,
  };
}
