<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import type {
  NativeSelectEmits,
  NativeSelectProps,
} from '../types';
import useNativeSelectEvents from '../composables/useNativeSelectEvents';
import DropdownSelectIcon from './DropdownSelectIcon.vue';

const props = withDefaults(defineProps<NativeSelectProps>(), {
  options: () => [],
  name: 'select',
});
const emits = defineEmits<NativeSelectEmits>();
const { emitChange, emitBlur, emitFocus } = useNativeSelectEvents(props, emits);
const { focusable, focus } = useAutofocus(props);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <div v-auto-id="'NativeSelect'"
    :class="{
      [$style['native-select']]: true,
      [$style['native-select--disabled']]: disabled,
      [$style['native-select--empty']]: isEmpty,
      [$style['native-select--error']]: hasError,
      [$style['native-select--large']]: isLarge,
    }"
  >
    <select
      ref="focusable"
      v-data-test="{
        el: 'select',
        selectedLabel: selectedLabel,
        selectedValue: selectedValue,
      }"
      :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
      :name="name"
      :disabled="disabled"
      :class="{
        [$style['native-select__dropdown']]: true,
        [$style['native-select__dropdown--icon-suffix']]: true,
        [$style['native-select__dropdown--icon-prefix']]: hasIconPrefix,
        [$style['native-select__dropdown--theme-bg-color']]: themeBgColor,
      }"
      @change.stop="emitChange"
      @focus.stop="emitFocus"
      @blur.stop="emitBlur"
    >
      <option
        v-if="placeholder"
        value=""
        disabled
        :selected="isEmpty || !options?.length"
      >
        {{ placeholder }}
      </option>
      <option
        v-for="(item, index) in options"
        :key="index"
        :value="item.value"
        :selected="item.value === selectedValue"
      >
        {{ item.label }}
      </option>
    </select>
    <DropdownSelectIcon
      v-if="!disabled"
      :is-large="isLarge"
    />
  </div>
</template>

<style lang="scss" module>
.native-select {
  @import 'web/src/components/Input/common';

  $self: &;

  position: relative;

  &__dropdown {
    @include inputCommon;
    @include inputAutoFillFlat;
    @include inputAutoFillFlatColors;

    // the height was written for the Firefox browser
    height: $inputHeight;
    text-overflow: ellipsis;
    appearance: none;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }

    &--theme-bg-color {
      background-color: var(--Layer0);
    }

    &--icon-prefix {
      padding-left: $inputIconWidth;

      #{$self}--large & {
        padding-left: $inputIconWidthLarge;
      }
    }

    &--icon-suffix {
      padding-right: $inputIconWidth;

      #{$self}--large & {
        padding-right: $inputIconWidthLarge;
      }
    }

    #{$self}--error & {
      color: $inputValueErrorColor;
    }

    #{$self}--disabled & {
      color: $inputValueDisabledColor;
      cursor: default;
      opacity: 1;
    }

    // placeholder
    #{$self}--empty & {
      color: $inputPlaceholderDefaultColor;
    }

    // placeholder error
    #{$self}--empty#{$self}--error & {
      color: $inputPlaceholderErrorColor;
    }
  }

  &--large &__dropdown {
    @include inputLarge;

    // the height was written for the Firefox browser
    height: $inputHeightLarge;
  }
}
</style>
