import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import { FrontNotificationType } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';
import { Timer } from '@leon-hub/utils';

import { useI18nStore } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import { ButtonKind } from '@components/buttons';

import type { VSnackBarProps } from 'web/src/components/SnackBar/VSnackBar/types';
import type { FomoBonusCreatedNotification } from 'web/src/modules/front-notifications/types';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';

export interface FomoBonusNotificationComposable {
  isVisible: Ref<boolean>;
  snackBarProperties: ComputedRef<VSnackBarProps>;
  isFomoBonusAlert: ComputedRef<boolean>;
  isTimerEnd: ComputedRef<boolean>;
  fomoBonusNotifications: Ref<FomoBonusCreatedNotification[]>;
  onClose(): void;
  onClick(): void;
  onTermsClick(): void;
  checkAndHideNotification(bonusId: number): void;
}

export default function useFomoBonusNotification(): FomoBonusNotificationComposable {
  const { $t } = useI18nStore();
  const router = useRouter();
  const siteConfigStore = useSiteConfigStore();
  const frontNotificationsStore = useFrontNotificationsStore();

  const isVisible = ref(true);
  const time = ref({
    hours: '',
    minutes: '',
    seconds: '',
  });

  let intervalId: number;

  const fomoBonusNotifications = computed(
    () => (frontNotificationsStore.frNotification?.filter(
      (item) => item.type === FrontNotificationType.FOMO_BONUS_CREATED,
    ) as FomoBonusCreatedNotification[]) || [],
  );

  const isFomoBonusAlert = computed(
    () => !!fomoBonusNotifications.value.length,
  );

  const isTimerEnd = computed(() => !time.value.hours && !time.value.minutes && !time.value.seconds);

  const timer = computed(() => `${time.value.hours}:${time.value.minutes}:${time.value.seconds}`);

  const snackBarProperties = computed<VSnackBarProps>(() => {
    const {
      bonusCampaignName,
      categoryId,
      actionUrl,
      message,
      terms,
      availableBefore,
    } = fomoBonusNotifications.value[0];

    const result: VSnackBarProps = {
      type: 'default',
      image: {
        src: require('web/src/assets/images/temporary-bonus.png'),
      },
    };

    if (bonusCampaignName) {
      result.title = bonusCampaignName;
    }

    if (message) {
      result.text = message;
    }

    if (availableBefore) {
      result.secondaryButton = {
        label: timer.value,
        kind: ButtonKind.SECONDARY,
        iconName: IconName.TIMER,
        disabled: true,
      };
    }

    if (categoryId && actionUrl) {
      result.primaryButton = {
        label: $t('WEB2_FOMO_BONUS_READ_MORE').value,
        kind: ButtonKind.PRIMARY,
      };
    } else {
      result.primaryButton = {
        label: $t('WEB2_FOMO_BONUS_DEPOSIT').value,
        kind: ButtonKind.PRIMARY,
      };

      if (terms) {
        result.linkButton = {
          label: $t('WEB2_FOMO_BONUS_TERMS').value,
          iconName: IconName.QUESTION_OUTLINE,
        };
      }
    }

    return result;
  });

  async function markFomoBonusNotification(): Promise<void> {
    await frontNotificationsStore.markNotification({ id: fomoBonusNotifications.value[0].id });
    const unmarkedNotificationIds: string[] = [];
    if (fomoBonusNotifications.value.length > 0) {
      for (const element of fomoBonusNotifications.value) {
        frontNotificationsStore.removeFrontNotification(element.id);
        unmarkedNotificationIds.push(element.id);
      }
    }
    if (unmarkedNotificationIds.length) {
      for (const id of unmarkedNotificationIds) {
        void frontNotificationsStore.markNotification({ id });
      }
    }
  }

  function onClick() {
    const { categoryId, actionUrl } = fomoBonusNotifications.value[0];

    if (categoryId && actionUrl) {
      void router.push({
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId,
          actionUrl,
        },
      });
    } else {
      void router.push({ name: RouteName.DEPOSITS });
    }
  }
  function onTermsClick() {
    void router.push({
      name: RouteName.CMS_PROMO_TERMS,
      params: {
        cmsKey: fomoBonusNotifications.value[0].terms,
      },
    });
  }

  function onClose() {
    void markFomoBonusNotification();
    isVisible.value = false;
  }

  function checkAndHideNotification(bonusId: number) {
    if (!!fomoBonusNotifications.value.length && fomoBonusNotifications.value[0].bonusId === bonusId) {
      onClose();
    }
  }

  onMounted(() => {
    if (fomoBonusNotifications.value?.[0]?.availableBefore) {
      intervalId = Timer.setInterval(() => {
        const now = Date.now();
        const diff = (fomoBonusNotifications.value[0].availableBefore || 0) - now;

        if (diff <= 0) {
          clearInterval(intervalId);
          time.value = {
            hours: '00',
            minutes: '00',
            seconds: '00',
          };
          onClose();
          return;
        }

        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        time.value = {
          hours: hours.toString().padStart(2, '0'),
          minutes: minutes.toString().padStart(2, '0'),
          seconds: seconds.toString().padStart(2, '0'),
        };
      }, 1000);

      void siteConfigStore.updateSiteConfigSettings({ silent: true });
    }
  });

  onUnmounted(() => {
    clearInterval(intervalId);
  });

  return {
    isFomoBonusAlert,
    isVisible,
    snackBarProperties,
    isTimerEnd,
    fomoBonusNotifications,
    onClose,
    onClick,
    onTermsClick,
    checkAndHideNotification,
  };
}
