import { isNullOrUndefined, isObject, isFunction, isUndefined, isArray, isNumber, isBoolean, isString } from "@leon-hub/guards";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import { logger } from "@leon-hub/logging";
import { Json, getPixelRatio, IdleTimeQueueManager, promiseTimeout } from "@leon-hub/utils";
var ComponentKey = /* @__PURE__ */ ((ComponentKey2) => {
  ComponentKey2["Plugins"] = "plugins";
  ComponentKey2["Fonts"] = "fonts";
  ComponentKey2["UserAgent"] = "userAgent";
  ComponentKey2["ScreenResolution"] = "screenResolution";
  ComponentKey2["Timezone"] = "timezone";
  ComponentKey2["TimezoneOffset"] = "timezoneOffset";
  ComponentKey2["Language"] = "language";
  ComponentKey2["ColorDepth"] = "colorDepth";
  ComponentKey2["DeviceMemory"] = "deviceMemory";
  ComponentKey2["PixelRatio"] = "pixelRatio";
  ComponentKey2["HardwareConcurrency"] = "hardwareConcurrency";
  ComponentKey2["Platform"] = "platform";
  ComponentKey2["DoNotTrack"] = "doNotTrack";
  ComponentKey2["WebglVendorAndRenderer"] = "webglVendorAndRenderer";
  ComponentKey2["TouchSupport"] = "touchSupport";
  ComponentKey2["Canvas"] = "canvas";
  ComponentKey2["Audio"] = "audio";
  ComponentKey2["EnumerateDevices"] = "enumerateDevices";
  return ComponentKey2;
})(ComponentKey || {});
var ComponentStatus = /* @__PURE__ */ ((ComponentStatus2) => {
  ComponentStatus2["NotAvailable"] = "NotAvailable";
  ComponentStatus2["NoData"] = "NoData";
  ComponentStatus2["Error"] = "Error";
  ComponentStatus2["Timeout"] = "TimeoutDFB";
  ComponentStatus2["Applicable"] = "Applicable";
  ComponentStatus2["NotApplicable"] = "NotApplicable";
  return ComponentStatus2;
})(ComponentStatus || {});
const theMaximumRecursionDepth = 6;
const stringJoinerSeparator = "~~~";
class AbstractFingerprintComponent {
  constructor(key) {
    this.key = key;
  }
  result(value) {
    let result = "";
    if (Array.isArray(value)) {
      result = value.flat(theMaximumRecursionDepth).join(stringJoinerSeparator);
    } else if (typeof value === "string" && value.length > 0) {
      result = value;
    } else if (!isNullOrUndefined(value)) {
      result = String(value);
    } else if (isObject(value)) {
      const stringifiedResult = Json.stringify(value, {
        circular: true,
        circularPlaceholder: "[Circular]",
        defaultValue: ComponentStatus.NoData
      });
      result = isNullOrUndefined(stringifiedResult) ? ComponentStatus.NoData : stringifiedResult;
    } else {
      result = ComponentStatus.NoData;
    }
    return Promise.resolve({
      key: this.key,
      value: result
    });
  }
}
class AudioComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Audio);
  }
  getComponentResult() {
    return new Promise((resolve) => {
      try {
        const AudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
        if (!AudioContext) {
          resolve(this.result(ComponentStatus.NotAvailable));
        }
        let context = new AudioContext(1, 44100, 44100);
        const oscillator = context.createOscillator();
        oscillator.type = "triangle";
        oscillator.frequency.setValueAtTime(1e4, context.currentTime);
        const compressor = context.createDynamicsCompressor();
        compressor.threshold.setValueAtTime(-50, context.currentTime);
        compressor.knee.setValueAtTime(40, context.currentTime);
        compressor.ratio.setValueAtTime(12, context.currentTime);
        compressor.attack.setValueAtTime(0, context.currentTime);
        compressor.release.setValueAtTime(0.25, context.currentTime);
        oscillator.connect(compressor);
        compressor.connect(context.destination);
        oscillator.start(0);
        context.startRendering();
        const audioTimeoutId = setTimeout(() => {
          context = null;
          return resolve(this.result(ComponentStatus.Timeout));
        }, 1e3);
        context.oncomplete = (event) => {
          let fingerprint;
          try {
            clearTimeout(audioTimeoutId);
            fingerprint = event.renderedBuffer.getChannelData(0).slice(4500, 5e3).reduce((accumulator, value) => accumulator + Math.abs(value), 0).toString();
            oscillator.disconnect();
            compressor.disconnect();
          } catch {
            return resolve(this.result(ComponentStatus.Error));
          }
          return resolve(this.result([fingerprint]));
        };
      } catch {
        resolve(this.result(ComponentStatus.Error));
      }
    });
  }
}
function isCanvasSupported() {
  const element = document.createElement("canvas");
  return !!(element.getContext && element.getContext("2d"));
}
function getWebglCanvas() {
  const canvas = document.createElement("canvas");
  try {
    return canvas.getContext("webgl");
  } catch {
    return null;
  }
}
function loseWebglContext(context) {
  if (context) {
    const loseContextExtension = context.getExtension("WEBGL_lose_context");
    if (loseContextExtension) {
      loseContextExtension.loseContext();
    }
  }
}
class CanvasComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Canvas);
  }
  getComponentResult() {
    if (isCanvasSupported()) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        const sample = "how-does-it-work";
        context.textBaseline = "top";
        context.font = "14px 'Arial'";
        context.textBaseline = "alphabetic";
        context.fillStyle = "#f60";
        context.fillRect(125, 1, 62, 20);
        context.fillStyle = "#069";
        context.fillText(sample, 2, 15);
        context.fillStyle = "rgba(102, 204, 0, 0.7)";
        context.fillText(sample, 4, 17);
        return this.result([canvas.toDataURL()]);
      }
      return this.result(ComponentStatus.NotAvailable);
    }
    return this.result([ComponentStatus.NotAvailable]);
  }
}
class WebGlVendorAndRendererComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.WebglVendorAndRenderer);
  }
  getComponentResult() {
    try {
      const glContext = getWebglCanvas();
      if (glContext) {
        const rendererInfo = glContext.getExtension("WEBGL_debug_renderer_info");
        if (rendererInfo) {
          const result = [];
          result.push(
            glContext.getParameter(rendererInfo.UNMASKED_VENDOR_WEBGL),
            glContext.getParameter(rendererInfo.UNMASKED_RENDERER_WEBGL)
          );
          loseWebglContext(glContext);
          return this.result(result);
        }
      }
    } catch {
      return this.result([ComponentStatus.Error]);
    }
    return this.result([ComponentStatus.NotAvailable]);
  }
}
class ColorDepthComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.ColorDepth);
  }
  getComponentResult() {
    return this.result([window.screen.colorDepth || ComponentStatus.NotAvailable]);
  }
}
class DeviceMemoryComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.DeviceMemory);
  }
  getComponentResult() {
    return this.result([window.navigator.deviceMemory || ComponentStatus.NotAvailable]);
  }
}
class DoNotTrackComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.DoNotTrack);
  }
  getComponentResult() {
    return this.result([window.navigator.doNotTrack || window.doNotTrack || ComponentStatus.NotAvailable]);
  }
}
class FontsComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Fonts);
  }
  getComponentResult() {
    const baseFonts = ["monospace", "sans-serif", "serif"];
    const fontList = [
      "Andale Mono",
      "Arial",
      "Arial Black",
      "Arial Hebrew",
      "Arial MT",
      "Arial Narrow",
      "Arial Rounded MT Bold",
      "Arial Unicode MS",
      "Bitstream Vera Sans Mono",
      "Book Antiqua",
      "Bookman Old Style",
      "Calibri",
      "Cambria",
      "Cambria Math",
      "Century",
      "Century Gothic",
      "Century Schoolbook",
      "Comic Sans",
      "Comic Sans MS",
      "Consolas",
      "Courier",
      "Courier New",
      "Geneva",
      "Georgia",
      "Helvetica",
      "Helvetica Neue",
      "Impact",
      "Lucida Bright",
      "Lucida Calligraphy",
      "Lucida Console",
      "Lucida Fax",
      "LUCIDA GRANDE",
      "Lucida Handwriting",
      "Lucida Sans",
      "Lucida Sans Typewriter",
      "Lucida Sans Unicode",
      "Microsoft Sans Serif",
      "Monaco",
      "Monotype Corsiva",
      "MS Gothic",
      "MS Outlook",
      "MS PGothic",
      "MS Reference Sans Serif",
      "MS Sans Serif",
      "MS Serif",
      "MYRIAD",
      "MYRIAD PRO",
      "Palatino",
      "Palatino Linotype",
      "Segoe Print",
      "Segoe Script",
      "Segoe UI",
      "Segoe UI Light",
      "Segoe UI Semibold",
      "Segoe UI Symbol",
      "Tahoma",
      "Times",
      "Times New Roman",
      "Times New Roman PS",
      "Trebuchet MS",
      "Verdana",
      "Wingdings",
      "Wingdings 2",
      "Wingdings 3"
    ];
    const testString = "mmmmmmmmmmlli";
    const testSize = "72px";
    const h = document.querySelectorAll("body")[0];
    const baseFontsDiv = document.createElement("div");
    const fontsDiv = document.createElement("div");
    const defaultWidth = {};
    const defaultHeight = {};
    const createSpan = () => {
      const s = document.createElement("span");
      s.style.position = "absolute";
      s.style.left = "-9999px";
      s.style.fontSize = testSize;
      s.style.fontStyle = "normal";
      s.style.fontWeight = "normal";
      s.style.letterSpacing = "normal";
      s.style.lineBreak = "auto";
      s.style.lineHeight = "normal";
      s.style.textTransform = "none";
      s.style.textAlign = "left";
      s.style.textDecoration = "none";
      s.style.textShadow = "none";
      s.style.whiteSpace = "normal";
      s.style.wordBreak = "normal";
      s.style.wordSpacing = "normal";
      s.innerHTML = testString;
      return s;
    };
    const createSpanWithFonts = (fontToDetect, baseFont) => {
      const s = createSpan();
      s.style.fontFamily = `'${fontToDetect}',${baseFont}`;
      return s;
    };
    const initializeBaseFontsSpans = () => {
      const spans = [];
      for (let index = 0, { length } = baseFonts; index < length; index += 1) {
        const s = createSpan();
        s.style.fontFamily = baseFonts[index];
        baseFontsDiv.append(s);
        spans.push(s);
      }
      return spans;
    };
    const initializeFontsSpans = () => {
      const spans = {};
      for (let index1 = 0, l = fontList.length; index1 < l; index1 += 1) {
        const fontSpans = [];
        for (let index2 = 0, numberDefaultFonts = baseFonts.length; index2 < numberDefaultFonts; index2 += 1) {
          const s = createSpanWithFonts(fontList[index1], baseFonts[index2]);
          fontsDiv.append(s);
          fontSpans.push(s);
        }
        spans[fontList[index1]] = fontSpans;
      }
      return spans;
    };
    const isFontAvailable = (fontSpans) => {
      let detected = false;
      for (const [index, baseFont] of baseFonts.entries()) {
        detected = fontSpans[index].offsetWidth !== defaultWidth[baseFont] || fontSpans[index].offsetHeight !== defaultHeight[baseFont];
        if (detected) {
          return detected;
        }
      }
      return detected;
    };
    const baseFontsSpans = initializeBaseFontsSpans();
    h.append(baseFontsDiv);
    for (let index = 0, { length } = baseFonts; index < length; index += 1) {
      defaultWidth[baseFonts[index]] = baseFontsSpans[index].offsetWidth;
      defaultHeight[baseFonts[index]] = baseFontsSpans[index].offsetHeight;
    }
    const fontsSpans = initializeFontsSpans();
    h.append(fontsDiv);
    const available = [];
    for (let index = 0, l = fontList.length; index < l; index += 1) {
      if (isFontAvailable(fontsSpans[fontList[index]])) {
        available.push(fontList[index]);
      }
    }
    fontsDiv.remove();
    baseFontsDiv.remove();
    return this.result(available.length ? available : ComponentStatus.NoData);
  }
}
class HardwareConcurrencyComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.HardwareConcurrency);
  }
  getComponentResult() {
    return this.result(
      window.navigator.hardwareConcurrency ? [window.navigator.hardwareConcurrency] : ComponentStatus.NotAvailable
    );
  }
}
class LanguageComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Language);
  }
  getComponentResult() {
    return this.result([window.navigator.language || ComponentStatus.NotAvailable]);
  }
}
class PixelRatioComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.PixelRatio);
  }
  getComponentResult() {
    return this.result([getPixelRatio() ?? ComponentStatus.NotAvailable]);
  }
}
class PlatformComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Platform);
  }
  getComponentResult() {
    return this.result([
      window.navigator.platform ? window.navigator.platform : ComponentStatus.NotAvailable
    ]);
  }
}
class PluginsComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Plugins);
  }
  getComponentResult() {
    const navigatorPlugins = window.navigator.plugins;
    if (isNullOrUndefined(navigatorPlugins)) {
      return this.result({
        key: this.key,
        value: ComponentStatus.NotAvailable
      });
    }
    const plugins = [];
    for (let index = 0, l = navigatorPlugins.length; index < l; index += 1) {
      if (navigatorPlugins[index]) {
        plugins.push(navigatorPlugins[index]);
      }
    }
    const value = plugins.map((plugin) => {
      const mimeTypes = [];
      for (let index = 0, l = plugin.length; index < l; index += 1) {
        if (plugin[index] instanceof MimeType) {
          mimeTypes.push([plugin[index].type, plugin[index].suffixes, plugin[index].description]);
        }
      }
      return [plugin.name, plugin.description, mimeTypes];
    });
    return this.result(value.length ? value : ComponentStatus.NoData);
  }
}
class ScreenResolutionComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.ScreenResolution);
  }
  getComponentResult() {
    return this.result([window.screen.width, window.screen.height]);
  }
}
class TimezoneComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Timezone);
  }
  getComponentResult() {
    if (window.Intl && window.Intl.DateTimeFormat) {
      return this.result([new window.Intl.DateTimeFormat().resolvedOptions().timeZone]);
    }
    return this.result([ComponentStatus.NotAvailable]);
  }
}
class TimezoneOffsetComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.TimezoneOffset);
  }
  getComponentResult() {
    return this.result([(/* @__PURE__ */ new Date()).getTimezoneOffset()]);
  }
}
class TouchSupportComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.TouchSupport);
  }
  getComponentResult() {
    let maxTouchPoints = 0;
    let touchEvent;
    if (typeof navigator.maxTouchPoints !== "undefined") {
      maxTouchPoints = navigator.maxTouchPoints;
    } else if (typeof navigator.msMaxTouchPoints !== "undefined") {
      maxTouchPoints = navigator.msMaxTouchPoints;
    }
    try {
      document.createEvent("TouchEvent");
      touchEvent = true;
    } catch {
      touchEvent = false;
    }
    const touchStart = "ontouchstart" in window;
    return this.result([maxTouchPoints, touchEvent, touchStart]);
  }
}
class UserAgentComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.UserAgent);
  }
  async getComponentResult() {
    return this.result([window.navigator.userAgent]);
  }
}
const pad = (time, digits = 2) => `00${time}`.slice(-digits);
function millisecondsToPrettyTime(milliseconds) {
  const ms = milliseconds % 1e3;
  const seconds = (milliseconds - ms) / 1e3 % 60;
  const minutes = (milliseconds - seconds) / 60 % 60;
  return `${pad(minutes)}:${pad(seconds)}.${pad(ms, 3)}`;
}
const FBR_STORAGE_KEY = "fbrVisitorId";
const idleTimeQueueManager = /* @__PURE__ */ IdleTimeQueueManager.getInstance();
const compoundStringJoiner = ":";
const componentsSet = /* @__PURE__ */ new Set([
  PluginsComponent,
  UserAgentComponent,
  FontsComponent,
  TimezoneComponent,
  TimezoneOffsetComponent,
  ScreenResolutionComponent,
  CanvasComponent,
  WebGlVendorAndRendererComponent,
  HardwareConcurrencyComponent,
  LanguageComponent,
  ColorDepthComponent,
  DeviceMemoryComponent,
  PixelRatioComponent,
  DoNotTrackComponent,
  TouchSupportComponent,
  AudioComponent,
  PlatformComponent
]);
function isFingerprintBrowserConfig(value) {
  return isObject(value) && isFunction(value.hasher) && (isUndefined(value.doNotAllowComponentsList) || isArray(value.doNotAllowComponentsList)) && (isUndefined(value.components) || isObject(value.components)) && isNumber(value.componentExecutingTimeoutMs) && isBoolean(value.debug) && (isUndefined(value.runInIdleTimeQueue) || isBoolean(value.runInIdleTimeQueue)) && isNumber(value.visitorIdTtl);
}
function isStoredFingerprintBrowser(value) {
  return isObject(value) && isString(value.visitorId) && isNumber(value.timestamp);
}
class DeviceFingerprintBrowserService {
  init(config) {
    this.config = config;
  }
  async generateFingerprint() {
    const localStorageManager = useLocalStorageManager();
    if (!isFingerprintBrowserConfig(this.config)) {
      logger.error(`FingerprintBrowser config is invalid: config=${Json.stringify(this.config)}`);
      return null;
    }
    const startTime = Date.now();
    if (this.config.debug) {
      console.info("[FPB] has started executing components.");
    }
    if (this.hasNotApplicableComponents(this.config.doNotAllowComponentsList ?? [])) {
      if (this.config.debug) {
        console.info("[FPB] doesn't apply to your device.");
      }
      return null;
    }
    const runInIdleTimeQueue = typeof this.config.runInIdleTimeQueue === "undefined" ? true : this.config.runInIdleTimeQueue;
    const computedComponentsResult = await this.computeComponents({
      runInIdleTimeQueue,
      components: this.config.components ?? componentsSet,
      componentExecutingTimeoutMs: this.config.componentExecutingTimeoutMs,
      debug: this.config.debug
    });
    const compoundArrayResult = [...computedComponentsResult].map(([key, componentResult]) => `${key}:${componentResult.value}`);
    const compoundStringResult = compoundArrayResult.join(compoundStringJoiner);
    if (this.config.debug) {
      console.info(`[FPB] components executing took ${millisecondsToPrettyTime(Math.floor(Date.now() - startTime))}.`);
    }
    const result = this.config.hasher(compoundStringResult);
    if (this.config.debug) {
      console.info(`[FPB] computing result is ${result}.`);
    }
    localStorageManager.setItem(FBR_STORAGE_KEY, Json.stringify({
      visitorId: result,
      timestamp: Date.now()
    }) || "{}");
    return result;
  }
  getFingerprintFromStorage() {
    const localStorageManager = useLocalStorageManager();
    if (!isFingerprintBrowserConfig(this.config)) {
      logger.error(`FingerprintBrowser config is invalid: config=${Json.stringify(this.config)}`);
      return null;
    }
    const storedFbr = Json.parse(
      localStorageManager.getItem(FBR_STORAGE_KEY) || "{}",
      { defaultValue: {} }
    );
    if (isStoredFingerprintBrowser(storedFbr)) {
      if (this.config.visitorIdTtl > 0 && (Date.now() - storedFbr.timestamp) / 1e3 / 60 < this.config.visitorIdTtl) {
        return storedFbr.visitorId;
      }
      localStorageManager.removeItem(FBR_STORAGE_KEY);
    }
    return null;
  }
  async computeComponents(options) {
    const componentsResult = /* @__PURE__ */ new Map();
    const awaitingTasks = [];
    for (const ComponentClass of options.components) {
      const task = (done, ComponentClass2) => {
        const component = new ComponentClass2();
        promiseTimeout({
          timeout: options.componentExecutingTimeoutMs,
          promise: new Promise((resolve) => {
            try {
              void component.getComponentResult().then(resolve);
            } catch (error) {
              if (options.debug) {
                console.info(`[FPB] error occurred at component: ${component.key}.`, error);
              }
              componentsResult.set(component.key, { key: component.key, value: ComponentStatus.Error });
              done();
            }
          })
        }).then((result) => {
          componentsResult.set(component.key, result);
          done();
        }).catch(() => {
          if (options.debug) {
            console.info(`[FPB] ${options.componentExecutingTimeoutMs}ms timeout exceeded at component: ${component.key}.`);
          }
          componentsResult.set(component.key, { key: component.key, value: ComponentStatus.Timeout });
          done();
        });
      };
      if (options.runInIdleTimeQueue) {
        awaitingTasks.push(new Promise((resolve) => {
          idleTimeQueueManager.enqueueTask(
            task.bind(void 0, resolve, ComponentClass)
          );
        }));
      } else {
        awaitingTasks.push(new Promise((resolve) => {
          task(resolve, ComponentClass);
        }));
      }
    }
    await Promise.allSettled(awaitingTasks);
    return componentsResult;
  }
  hasNotApplicableComponents(components) {
    return components.some(
      (component) => component() === ComponentStatus.NotApplicable
    );
  }
}
export {
  DeviceFingerprintBrowserService
};
