import type { CoreSportlineFetchOptions, GetSportsResponse } from 'web/src/modules/sportline/types/rest';
import type { ExtendedDateTimeRange, SportlineApiService } from 'web/src/modules/sportline/types/services';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getSports } from 'web/src/modules/sportline/utils/api';

import type { UseSportlineApiLoadTreeEntitiesProps } from './types';
import type { ApiPending, ApiPendingParams } from './useSportlineApiPending';
import useSportlineApiPending from './useSportlineApiPending';

export function useSportlineApiLoadSportsTree(
  props: UseSportlineApiLoadTreeEntitiesProps,
): Pick<SportlineApiService, 'loadSportsTree' | 'loadFullSportsTree' | 'loadLiveFiltersSportsTree'> {
  const {
    tryRequestWithCTag,
    sportsRestApiFlags,
    isLiveOutrightsEnabled,
    isExtraLiveEnabled,
  } = props;

  const sportsApiPendings = useSportlineApiPending<GetSportsResponse>();

  function loadSportsTree({ dateTime, silent, betlines }: CoreSportlineFetchOptions & {
    dateTime?: ExtendedDateTimeRange;
    betlines?: BetlineType[];
  }): Promise<GetSportsResponse> {
    const filter = dateTime ?? { from: undefined, to: undefined };
    const { from, to } = filter;
    const pendingFilter: ApiPendingParams = { filter, betlines: betlines ?? [] };
    const apiPendingIndex = sportsApiPendings.getApiPendingIndexForFilter(pendingFilter);

    if (apiPendingIndex > -1) {
      return sportsApiPendings.getApiPendingByIndex(apiPendingIndex).request;
    }

    const request = new Promise<GetSportsResponse>((resolve, reject) => {
      tryRequestWithCTag((ctag) => getSports({
        ctag,
        from,
        to,
        silent,
        flags: [...sportsRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
        betlines,
      })).then((result) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        resolve(result);
        return result;
      }).catch((error) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        reject(error);
      });
    });
    const apiPending: ApiPending<GetSportsResponse> = {
      ...pendingFilter,
      request,
    };

    sportsApiPendings.addApiPending(apiPending);

    return apiPending.request;
  }

  function loadFullSportsTree({ silent }: CoreSportlineFetchOptions): Promise<GetSportsResponse> {
    return loadSportsTree({ silent });
  }

  function loadLiveFiltersSportsTree({ silent, dateTime }: CoreSportlineFetchOptions & {
    dateTime?: ExtendedDateTimeRange;
  }): Promise<GetSportsResponse> {
    if (isExtraLiveEnabled.value) {
      const betlines = [BetlineType.Live, BetlineType.Prematch, BetlineType.Outright, BetlineType.Virtual];

      return loadSportsTree({ silent, dateTime, betlines });
    }

    return loadSportsTree({ silent, dateTime });
  }

  return { loadSportsTree, loadFullSportsTree, loadLiveFiltersSportsTree };
}
