import type { Placeholder } from '@leon-hub/api-sdk';

import type { FormatMoneyFunc } from '@core/money';
import { useI18n } from '@core/i18n';

import { PlaceholderType } from 'web/src/modules/bonuses/store/enums';

export function replacePlaceholders({
  text = '',
  placeholders = [],
  formatMoney,
}: {
  text: string;
  placeholders: ReadonlyArray<Placeholder>;
  formatMoney: FormatMoneyFunc;
}): string {
  const { $translate } = useI18n();

  let returnValue = `${text}`;

  if (placeholders.length) {
    for (const {
      type,
      name,
      value,
      currency,
    } of placeholders) {
      if (type === PlaceholderType.MONEY) {
        const textReplace = formatMoney(Number(value), { currency: currency ?? '' });
        returnValue = returnValue.split(name).join(textReplace);
      } else if (type === PlaceholderType.COUNT) {
        returnValue = returnValue.split(name).join(`${value} ${$translate('WEB2_BONUSES_FREESPIN_COUNT').value}`);
      }
    }
    return returnValue;
  }
  return text;
}
