<script lang="ts" setup>
import { TextInputTypes } from '@leon-hub/input-types';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from 'web/src/components/Form/composables';

import CardNameInput from './components/CardNameInput/CardNameInput.vue';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const { componentProps } = useCommonFormWidgetProps(props, TextInputTypes.TEXT);

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);
</script>

<template>
  <CardNameInput v-auto-id="'FormCardNameInput'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
