import type { VInputInputEvent } from '@leon-hub/input-types';

import type { VSearchInputEmits } from '../types';

export interface UseVSearchInputComposable {
  onClear(): void;
  onInput(event: VInputInputEvent): void;
}

export default function useVSearchInput(emit: VSearchInputEmits): UseVSearchInputComposable {
  const onInput = (event: VInputInputEvent): void => {
    emit('input', event.target.value);
  };

  const onClear = (): void => {
    emit('clear', true);
  };

  return {
    onInput,
    onClear,
  };
}
