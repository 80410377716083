import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { MarkAchievementAsViewedResponse } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import type {
  AchievementItem,
  AchievementsCategoryData,
  AchievementsData,
  GetAchievementDataDocument,
  GetAchievementsAvailabilityDataDocument,
  GetAchievementsProfileDataDocument,
  GetAvatarsAndBackgroundsDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';
import { AchievementStatus } from 'web/src/modules/profile/submodules/achievements/enums';
import doAchievementMarkAsViewedRequest
  from 'web/src/modules/profile/submodules/achievements/utils/api/doAchievementMarkAsViewed';
import doAchievementRewardRequest from 'web/src/modules/profile/submodules/achievements/utils/api/doAchievementReward';
import getAchievementRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementRequest';
import getAchievementsProfileRequest
  from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementsProfileRequest';
import getAchievementsRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementsRequest';
import getAchievementsAvailabilityRequest
  from 'web/src/modules/profile/submodules/achievements/utils/api/getAvailabilityRequest';
import getAvatarsAndBackgroundsRequest
  from 'web/src/modules/profile/submodules/achievements/utils/api/getAvatarsAndBackgroundsRequest';
import getCategoriesRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getCategoriesRequest';

const useAchievementsStore = defineStore('achievements', () => {
  const avatarsAndBackgrounds = ref<Optional<GetAvatarsAndBackgroundsDataDocument>>();
  const achievements = ref<Maybe<AchievementsData>>();
  const categories = ref<Maybe<AchievementsCategoryData>>();
  const profile = ref<Optional<GetAchievementsProfileDataDocument>>();
  const availability = ref<Optional<GetAchievementsAvailabilityDataDocument>>();
  const isFromNotification = ref<boolean>(false);
  const isFromFilter = ref<boolean>(false);
  const achievementScrollTo = ref<number | undefined>(undefined);
  const rewardTrigger = ref<symbol>(Symbol('rewardTrigger'));

  async function fetchAvailability(): Promise<void> {
    try {
      availability.value = await getAchievementsAvailabilityRequest();
    } catch (error) {
      logger.error('An error occurred while updating achievements availability', error);
    }
  }

  async function fetchAvatarsAndBackgrounds(): Promise<void> {
    try {
      avatarsAndBackgrounds.value = await getAvatarsAndBackgroundsRequest();
    } catch (error) {
      logger.error('An error occurred while updating achievements avatars and background', error);
    }
  }

  async function fetchProfileData(publicId?: string): Promise<GetAchievementsProfileDataDocument | undefined> {
    try {
      const data = await getAchievementsProfileRequest(publicId);

      if (!publicId) {
        profile.value = data;
      }
      return data;
    } catch (error) {
      logger.error('An error occurred while updating achievements profile data', error);
      throw error;
    }
  }

  async function fetchAchievements(publicId?: string): Promise<void> {
    try {
      const result = await getAchievementsRequest(publicId);
      achievements.value = result.items;
    } catch (error) {
      logger.error('An error occurred while updating achievements', error);
      throw error;
    }
  }

  async function fetchCategories(): Promise<void> {
    try {
      const result = await getCategoriesRequest();
      categories.value = result.categories;
    } catch (error) {
      logger.error('An error occurred while updating categories', error);
    }
  }

  async function doAchievementReward(id: number): Promise<GetAchievementDataDocument | undefined> {
    try {
      return await doAchievementRewardRequest(id);
    } catch (error) {
      logger.error('An error occurred while updating reward', error);
      return undefined;
    }
  }

  async function doAchievementMarkAsViewed(id: number): Promise<MarkAchievementAsViewedResponse | undefined> {
    try {
      return await doAchievementMarkAsViewedRequest(id);
    } catch (error) {
      logger.error('An error occurred while updating achievement', error);
      return undefined;
    }
  }

  async function getAchievementById(id: number): Promise<GetAchievementDataDocument | null> {
    try {
      return await getAchievementRequest(id);
    } catch (error) {
      logger.error('An error occurred while updating current achievement', error);
      return null;
    }
  }

  function getAchievementsByCategory(selectedCategories: string[]) {
    return achievements.value?.filter((item) => selectedCategories.includes(item.category));
  }

  function getAchievementsByStatus(selectedStatuses: AchievementStatus[]): AchievementsData {
    return achievements.value?.filter((item) => selectedStatuses.some((status) => checkAchievementStatus(item, status))) ?? [];
  }

  function getFilteredAchievements(selectedCategories: string[], statuses: AchievementStatus[]) {
    const filteredByCategory = achievements.value?.filter((item) => selectedCategories.includes(item.category));

    return filteredByCategory?.filter((item) => statuses.some((status) => checkAchievementStatus(item, status)));
  }

  function getGroupedAchievements(selectedCategories: string[], selectedStatuses: AchievementStatus[]) {
    if (selectedCategories.length === 0) {
      return {
        all: achievements.value?.filter((item) => selectedStatuses.length === 0 || selectedStatuses.some((status) => checkAchievementStatus(item, status))) ?? [],
      };
    }

    return selectedCategories.reduce((acc, category) => {
      const filteredByCategory = achievements.value?.filter((item) => item.category === category) ?? [];

      acc[category] = selectedStatuses.length > 0
        ? filteredByCategory.filter((item) => selectedStatuses.some((status) => checkAchievementStatus(item, status)))
        : filteredByCategory;

      return acc;
    }, {} as Record<string, AchievementsData>);
  }

  function checkAchievementStatus(item: AchievementItem, status: AchievementStatus) {
    switch (status) {
      case AchievementStatus.IN_PROGRESS:
        return item.progress?.actual && !item.satisfied;
      case AchievementStatus.LOCKED:
        return !item.progress?.actual && !item.satisfied;
      case AchievementStatus.UNLOCKED:
        return item.satisfied;
      default:
        return false;
    }
  }

  function setAchievementScrollTo(value: number | undefined): void {
    achievementScrollTo.value = value;
  }

  function setIsFromFilter(value: boolean): void {
    isFromFilter.value = value;
  }

  function cleanData() {
    avatarsAndBackgrounds.value = undefined;
    achievements.value = null;
    categories.value = null;
    profile.value = undefined;
  }

  function cleanAvailability(): void {
    availability.value = undefined;
  }

  return {
    availability,
    profile,
    avatarsAndBackgrounds,
    achievements,
    achievementScrollTo,
    isFromNotification,
    isFromFilter,
    rewardTrigger,
    categories,
    fetchAvailability,
    fetchAvatarsAndBackgrounds,
    getAchievementById,
    doAchievementReward,
    doAchievementMarkAsViewed,
    fetchAchievements,
    fetchCategories,
    getAchievementsByCategory,
    getAchievementsByStatus,
    getFilteredAchievements,
    getGroupedAchievements,
    fetchProfileData,
    cleanData,
    setIsFromFilter,
    setAchievementScrollTo,
    cleanAvailability,
  };
});

export default useAchievementsStore;
