import type { Ref } from 'vue';
import { computed } from 'vue';

import type { MatchStreamAccess } from 'web/src/modules/sportline/submodules/streams/types';
import type { CustomerMatchStream, CustomerMatchStreams } from 'web/src/modules/sportline/types/rest';
import { SportlineType } from 'web/src/modules/sportline/enums';

export function createMatchStreamAccessRef(isStreamsEnabled: Ref<boolean>, rawCustomerMatchStreamEvents: Ref<CustomerMatchStreams>): Ref<MatchStreamAccess[]> {
  return computed<MatchStreamAccess[]>(() => (isStreamsEnabled.value
    ? rawCustomerMatchStreamEvents.value.map(({ eventId, isPreview, isAvailableForPrematch }: CustomerMatchStream) => ({
        eventId: String(eventId),
        sportlineTypes: [SportlineType.Live],
        // @see LEONWEB-14247 Previews are available only for guests and only for RU version (The env flag is used for this purpose)
        isPreview: !!process.env.VUE_APP_FEATURE_SPORTLINE_STREAM_PREVIEW_ENABLED && !!isPreview,
        isUpcoming: !!isAvailableForPrematch,
      }))
    : []));
}
