import type {
  BaseModalEmits,
  DialogButtonClickAction,
} from '@core/dialogs';

export interface BaseModalActionsComposable {
  onClose(): void;
  onButtonClick(event: DialogButtonClickAction): void;
}

export default function useBaseModalActions(emit: BaseModalEmits): BaseModalActionsComposable {
  function onClose(): void {
    emit('close');
  }

  function onButtonClick(event: DialogButtonClickAction): void {
    emit('button-click', event);
  }

  return {
    onClose,
    onButtonClick,
  };
}
