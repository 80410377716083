import type { Ref, WatchStopHandle } from 'vue';
import { defineStore } from 'pinia';
import {
  computed,
  ref,
  watch,
} from 'vue';

import type {
  SeoMetaParameters,
  SeoMetaParametersConfig,
} from 'web/src/modules/seo/types';
import mergeSeoMetaParameters from 'web/src/modules/seo/utils/mergeSeoMetaParameters';

import { useSpintaxSeoMetaData } from './composables/useSpintaxSeoMetaData';

const useSeoMetaStorage = defineStore('seo-meta-storage', () => {
  const {
    metaData: spintaxMetaData,
    metaInfo: spintaxMetaInfo,
    updateActualMetaData: updateSpintaxMetaData,
  } = useSpintaxSeoMetaData();

  const refsToComponentsData: Ref<Record<string, SeoMetaParametersConfig>> = ref({});
  const stopWatch: Record<string, WatchStopHandle> = {};

  const metaParameters = computed<SeoMetaParameters>(() => {
    let result: SeoMetaParameters = {};

    for (const key of Object.keys(refsToComponentsData.value)) {
      result = mergeSeoMetaParameters(
        result,
        refsToComponentsData.value[key] || {},
        () => {},
      );
    }

    return result;
  });

  /**
   * @TODO check components data after destroy component. Seems it couldn't clear because unref is not trigger watch effect
   */
  function setMetaParameter(uid: number, config: Maybe<Ref<SeoMetaParametersConfig>>): void {
    const UID = String(uid);

    if (!config) {
      refsToComponentsData.value[UID] = {};
      stopWatch[UID]?.();
      delete stopWatch[UID];
      delete refsToComponentsData.value[UID];
      return;
    }

    if (stopWatch[UID]) {
      stopWatch[UID]();
    }

    stopWatch[UID] = watch(config, (newValue) => {
      refsToComponentsData.value = { ...refsToComponentsData.value, [UID]: newValue };
    }, { immediate: true, flush: 'sync' });
  }

  return {
    spintaxMetaData,
    spintaxMetaInfo,
    metaParameters,
    setMetaParameter,
    updateSpintaxMetaData,
  };
});

export default useSeoMetaStorage;
