import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VSwiperNavigationButtonsProps } from 'web/src/components/Swiper/VSwiper/types';
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';

import { getNavigationProperties, getNextSlideIndexOffset, getPreviousSlideIndexOffset } from '../utils';

interface useVSwiperBannerNavigationButtonsComposable {
  navigationProperties: ComputedRef<VSwiperNavigationButtonsProps>;
  onNextClick(): void;
  onPreviousClick(): void;
}

export default function useVSwiperBannerNavigationButtons(): useVSwiperBannerNavigationButtonsComposable {
  const { swiperState, slideToNextSlide, slideToPreviousSlide } = useSwiperNavigation();

  const navigationProperties = computed(() => getNavigationProperties(swiperState));

  function onNextClick(): void {
    if (swiperState) {
      slideToNextSlide(getNextSlideIndexOffset(swiperState.activeSlideIndex.value, swiperState.slidesCounter.value));
    }
  }

  function onPreviousClick(): void {
    if (swiperState) {
      slideToPreviousSlide(getPreviousSlideIndexOffset(swiperState.activeSlideIndex.value, swiperState.slidesCounter.value));
    }
  }

  return {
    navigationProperties,
    onNextClick,
    onPreviousClick,
  };
}
