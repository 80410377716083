import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import type { SportElement } from 'web/src/modules/sportline/types';
import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';

interface UseMixedSportsListComposable {
  sportlineElements: Ref<SportElement[]>;
  totalCount: Ref<number>;
}

interface UseMixedHeadlineEventsListComposable {
  headlineElements: Ref<HeadlineMatchesSportListElement[]>;
  totalCount: Ref<number>;
}

interface UseSeparateHeadlineEventsListComposable {
  totalLiveCount: Ref<number>;
  totalPrematchCount: Ref<number>;
  liveHeadlineElements: Ref<HeadlineMatchesSportListElement[]>;
  prematchHeadlineElements: Ref<HeadlineMatchesSportListElement[]>;
}

export function useMixedSportsList(): UseMixedSportsListComposable {
  const sportlineTopStore = useSportlineTopStore();
  const sportlineElements = toRef(sportlineTopStore, 'sportlineElements');
  const totalCount = toRef(sportlineTopStore, 'totalCount');

  return {
    sportlineElements,
    totalCount,
  };
}

export function useMixedHeadlineEventsList(): UseMixedHeadlineEventsListComposable {
  const sportlineTopStore = useSportlineTopStore();
  const headlineElements = toRef(sportlineTopStore, 'headlineElements');
  const totalCount = toRef(sportlineTopStore, 'totalCount');

  return {
    headlineElements,
    totalCount,
  };
}

export function useSeparateHeadlineEventsLists(): UseSeparateHeadlineEventsListComposable {
  const sportlineTopStore = useSportlineTopStore();
  const totalLiveCount = toRef(sportlineTopStore, 'totalLiveCount');
  const totalPrematchCount = toRef(sportlineTopStore, 'totalPrematchCount');
  const liveHeadlineElements = toRef(sportlineTopStore, 'liveHeadlineElements');
  const prematchHeadlineElements = toRef(sportlineTopStore, 'prematchHeadlineElements');

  return {
    totalLiveCount,
    totalPrematchCount,
    liveHeadlineElements,
    prematchHeadlineElements,
  };
}
