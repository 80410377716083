import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { assert, isNumber } from '@leon-hub/guards';

import { useI18n } from '@core/i18n';

import type { SwitcherOption } from 'web/src/components/Switcher/VSwitcher/types';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
import copyTextToClipboard from 'web/src/utils/browser/copyTextToClipboard';
import createUniqueId from 'web/src/utils/createUniqueId';

import type { CryptoPaymentDataProps } from '../types';
import { TabId } from '../enums/TabId';
import { isTabId } from '../guards/isTabId';

interface UseCryptoPaymentData {
  switcherOptions: ComputedRef<SwitcherOption[]>;
  activeTab: ComputedRef<TabId>;
  selectTab(value: string): void;
  showExpires: ComputedRef<boolean>;
  timeLeft: ComputedRef<string>;
  uniqueId: ComputedRef<string>;
  timerIsEnded: ComputedRef<boolean>;
  isTooltipShown: Ref<boolean>;
  handleCopyToClipboard(value: string): Promise<void>;
}

export function useCryptoPaymentData(props: CryptoPaymentDataProps): UseCryptoPaymentData {
  const { $translate } = useI18n();
  const { now } = useSafeTimeBus(1000);
  const uniqueId = computed<string>(() => createUniqueId(props.name ?? ''));
  const switcherOptions = computed<SwitcherOption[]>(() => [
    {
      id: TabId.Details,
      label: $translate('WEB2_PAYMENTS_DETAILS').value,
    },
    {
      id: TabId.QrCode,
      label: $translate('WEB2_PAYMENTS_QR_CODE').value,
    },
  ]);

  const selectedTab = ref<TabId>(TabId.Details);
  const isTooltipShown = ref(false);

  async function handleCopyToClipboard(value: string): Promise<void> {
    await copyTextToClipboard(value);

    if (!isTooltipShown.value) {
      isTooltipShown.value = true;

      setTimeout(() => {
        isTooltipShown.value = false;
      }, 1500);
    }
  }

  const selectTab = (value?: string): void => {
    if (value) {
      assert(isTabId(value), 'unexpected tab id value');
      selectedTab.value = value;
    }
  };

  const activeTab = computed<TabId>(() => {
    if (!props.qrCodeSrc) {
      return TabId.Details;
    }
    return selectedTab.value;
  });

  const showExpires = computed<boolean>(() => isNumber(props.expires));

  const safeExpires = computed<number>(() => {
    const safeValue = showExpires.value ? props.expires : 0;
    assert(isNumber(safeValue));
    return safeValue * 1000;
  });

  const { displayedTimeLeft, timeLeftValue } = useCountdown(safeExpires, now, {
    displaySecondsOnMaxMinutes: ref(false),
    useLeadingZero: ref(true),
  });

  const timeLeft = computed<string>(() => {
    const { hours, minutes, seconds } = displayedTimeLeft.value;
    const days = timeLeftValue.value.days > 0 ? `${displayedTimeLeft.value.days}:` : '';
    return `${days}${hours}:${minutes}:${seconds}`;
  });

  const timerIsEnded = computed(() => {
    if (!showExpires.value) {
      return false;
    }

    const {
      days,
      hours,
      minutes,
      seconds,
    } = timeLeftValue.value;

    return !days && !hours && !minutes && !seconds;
  });

  return {
    uniqueId,
    switcherOptions,
    activeTab,
    selectTab,
    showExpires,
    timeLeft,
    timerIsEnded,
    isTooltipShown,
    handleCopyToClipboard,
  };
}
