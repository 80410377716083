import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

import { useI18n } from '@core/i18n';

import type {
  VLoadMoreObserverEmits,
  VLoadMoreObserverProps,
} from '../types';

export interface UseVLoadMoreObserverComposable {
  collision: Ref<HTMLElement | undefined>;
  buttonText: ComputedRef<string>;
  showFallbackButton: boolean;
}

export default function useVLoadMoreObserver(
  props: VLoadMoreObserverProps,
  emit: VLoadMoreObserverEmits,
): UseVLoadMoreObserverComposable {
  const { $translate } = useI18n();

  const collision = ref<HTMLElement>();

  const buttonText = computed(() => props.fallbackButtonText || $translate('WEB2_LOAD_MORE').value);

  const showFallbackButton = typeof IntersectionObserver === 'undefined';

  useIntersectionObserver(collision, (isIntersecting) => {
    if (isIntersecting) {
      emit('load-more');
    }
  }, { threshold: 1 });

  return {
    collision,
    buttonText,
    showFallbackButton,
  };
}
