import type {
  Ref,
  ShallowRef,
} from 'vue';
import noop from 'lodash/noop';
import {
  computed,
  toRef,
} from 'vue';

import { whenClientNetworkIdle } from '@leon-hub/idle';
import { getLocationHost } from '@leon-hub/service-locator-env';
import { ScriptElement } from '@leon-hub/utils';
import {
  onComponentDeactivated,
  useIntersectionObserver,
} from '@leon-hub/vue-utils';

import { useSiteConfigStore } from '@core/site-config';
import { useTheme } from '@core/theme';

import type { FooterLicenseProps, FooterLogoType } from '../types';

const landingResizeCoefficient = 1.25;

export interface FooterLicenseComposable {
  doShowLogos: Ref<boolean>;
  doShowGenericLogos: Ref<boolean>;
  isKwkLogoEnabled: Ref<boolean | undefined>;
  isCrcLogoEnabled: Ref<boolean | undefined>;
  isCrcEgamingLogoEnabled: Ref<boolean | undefined>;
  kwkUrl: Ref<Maybe<string> | undefined>;
  crcUrl: Ref<Maybe<string> | undefined>;
  crcEgamingUrl: Ref<Maybe<string> | undefined>;
  crcEgamingLogoUrl: Ref<Maybe<string> | undefined>;
  isAnjouanLBZLogoEnabled: boolean;
  isAnjouanTBZLogoEnabled: boolean;
  logos: Ref<FooterLogoType[]>;
  getLogo(logo: FooterLogoType): string;
  dynamicSize(size?: Maybe<number>): number | undefined;
}

export default function useFooterLicense(
  props: FooterLicenseProps,
  wrapper: ShallowRef<HTMLElement | null>,
): FooterLicenseComposable {
  const { isDark } = useTheme();
  const scgStore = useSiteConfigStore();
  const licenseBlock = toRef(scgStore, 'licenseBlock');
  const footerLogotypesBlock = toRef(scgStore, 'footerLogotypesBlock');
  const isFooterLogotypesEnabled = toRef(scgStore, 'isFooterLogotypesEnabled');
  const isKwkLogoEnabled = computed(() => licenseBlock.value?.kwkIconEnabled);
  const kwkUrl = computed(() => licenseBlock.value?.kwkUrl);
  const isCrcLogoEnabled = computed(() => licenseBlock.value?.crcIconEnabled);
  const crcUrl = computed(() => licenseBlock.value?.crcUrl);
  const isCrcEgamingLogoEnabled = computed(() => licenseBlock.value?.crcEgamingIconEnabled);
  const crcEgamingUrl = computed(() => licenseBlock.value?.crcEgamingUrl);
  const crcEgamingLogoUrl = computed(() => licenseBlock.value?.crcEgamingLogoUrl);
  const host = getLocationHost();
  const isAnjouanLBZLogoEnabled = host === 'leonbz.bet';
  const isAnjouanTBZLogoEnabled = host === 'twinbz.bet';

  const doShowGenericLogos = computed(() => isFooterLogotypesEnabled.value);
  const newLogos = computed<FooterLogoType[]>(() => footerLogotypesBlock.value?.footerLogotypesByLocale ?? []);
  const legacyLogos = computed<FooterLogoType[]>(() => footerLogotypesBlock.value?.footerLogotypes ?? []);
  const logos = computed(() => (
    newLogos.value.length > 0 ? newLogos.value : legacyLogos.value
  ));

  const doShowLogos = computed(() => (doShowGenericLogos.value && logos.value.length > 0)
    || !!(process.env.VUE_APP_FEATURE_LICENSE_KWK_ENABLED && isKwkLogoEnabled.value)
    || !!(process.env.VUE_APP_FEATURE_LICENSE_CURACAO_ANTILLEPHONE_ENABLED && isCrcLogoEnabled.value)
    || !!(process.env.VUE_APP_FEATURE_LICENSE_ANJOUAN_ENABLED && isAnjouanLBZLogoEnabled)
    || !!(process.env.VUE_APP_FEATURE_LICENSE_ANJOUAN_ENABLED && isAnjouanTBZLogoEnabled));

  function dynamicSize(size?: Maybe<number>): number | undefined {
    if (!size) {
      return undefined;
    }

    return props.isLanding ? size * landingResizeCoefficient : size;
  }

  function getLogo(logo: FooterLogoType): string {
    return isDark.value ? logo.iconDark : logo.iconLight;
  }

  let licenseScript: ScriptElement | undefined;

  useIntersectionObserver(wrapper, (isIntersecting) => {
    if (licenseScript || (!process.env.VUE_APP_PRERENDER && !isIntersecting)) {
      return;
    }

    if (process.env.VUE_APP_FEATURE_LICENSE_CURACAO_ANTILLEPHONE_ENABLED
      && isCrcLogoEnabled.value
      && crcUrl.value) {
      const crcValue = crcUrl.value;
      void whenClientNetworkIdle({ interval: 300 }).then(() => {
        licenseScript = new ScriptElement({
          src: crcValue,
        });
        licenseScript.addScript().catch(noop); // do not log script load errors
      });
    }

    if (process.env.VUE_APP_FEATURE_LICENSE_ANJOUAN_ENABLED && isAnjouanLBZLogoEnabled) {
      licenseScript = new ScriptElement({
        src: 'https://ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5.snippet.anjouangaming.org/anj-seal.js',
      });
      licenseScript.addScript().catch(noop); // do not log script load errors
    }

    if (process.env.VUE_APP_FEATURE_LICENSE_ANJOUAN_ENABLED && isAnjouanTBZLogoEnabled) {
      licenseScript = new ScriptElement({
        src: 'https://7ad78d18-350b-4099-bf1e-6ba20df24adf.snippet.anjouangaming.org/anj-seal.js',
      });
      licenseScript.addScript().catch(noop); // do not log script load errors
    }
  }, {
    rootMargin: '500px 500px 500px 500px',
    threshold: 0.1,
  });

  onComponentDeactivated(() => {
    licenseScript?.removeScript();
    licenseScript = undefined;
  });

  return {
    doShowLogos,
    doShowGenericLogos,
    isKwkLogoEnabled,
    isCrcLogoEnabled,
    isCrcEgamingLogoEnabled,
    kwkUrl,
    crcUrl,
    crcEgamingUrl,
    crcEgamingLogoUrl,
    isAnjouanLBZLogoEnabled,
    isAnjouanTBZLogoEnabled,
    logos,
    getLogo,
    dynamicSize,
  };
}
