import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  EgsGame,
  EgsGroup,
  EgsTopWinner,
} from 'web/src/modules/egs/types';
import type { LobbyBanner, LobbyPromotion } from 'web/src/modules/lobby/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';

export function isEgsGame(item: unknown, typeId: LobbyItemType): item is EgsGame {
  return !!item && typeId === LobbyItemType.GAMES_CATEGORY;
}

export function isPromotion(item: unknown, typeId: LobbyItemType): item is LobbyPromotion {
  return !!item && typeId === LobbyItemType.PROMOTIONS;
}

export function isEgsGroup(item: unknown, typeId: LobbyItemType): item is EgsGroup {
  return !!item && typeId === LobbyItemType.GROUPS;
}

export function isEgsTopWinner(item: unknown, typeId: LobbyItemType): item is EgsTopWinner {
  return !!item && typeId === LobbyItemType.TOP_WINNERS;
}

export function isEgsBannerItem(item: unknown, typeId: LobbyItemType): item is LobbyBanner {
  return !!item && typeId === LobbyItemType.BANNERS;
}

export function isSportlineTopMatchItem(item: unknown, typeId: LobbyItemType): item is SportEventDetails {
  return !!item && typeId === LobbyItemType.SPORTLINE_TOP_MATCHES;
}
