<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import FooterCuracao from 'web/src/modules/core/views/FooterLicense/FooterCuracao.vue';

import type { FooterCopyrightProps } from './types';
import { useFooterCopyright } from './composables';

withDefaults(defineProps<FooterCopyrightProps>(), {});
const { copyright1, copyright2 } = useFooterCopyright();
</script>

<template>
  <div v-auto-id="'FooterCopyright'"
    :class="{
      [$style['footer-copyright']]: true,
      [$style['footer-copyright--is-landing']]: isLanding,
    }"
  >
    <VDynamicContent
      v-if="!null"
      :class="$style['footer-copyright__year']"
      :content="copyright1"
    />
    <div
      v-if="null"
      :class="$style['footer-copyright__container']"
    >
      <div :class="$style['footer-copyright__container-inside']">
        <FooterCuracao />
        <VDynamicContent
          :class="$style['footer-copyright__text--left']"
          :content="copyright2"
        />
      </div>
      <div :class="$style['footer-copyright__inside-year']">
        <VDynamicContent
          :class="$style['footer-copyright__inside-year-text']"
          :content="copyright1"
        />
        <VIcon
          :name="IconName.ADULT"
          :size="IconSize.SIZE_20"
        />
      </div>
    </div>
    <VDynamicContent
      v-else
      :class="$style['footer-copyright__text']"
      :content="copyright2"
    />
  </div>
</template>

<style lang="scss" module>
.footer-copyright {
  @include footerCopyrightLabelDesktop;

  color: $footerCopyrightColor;
  text-align: center;

  &__year {
    padding: $footerCopyrightYearPadding;
    margin-bottom: $footerCopyrightYearMargin;
    background-color: $footerCopyrightBackground;
  }

  &__text {
    @include footerCopyrightStyle;

    &--left {
      @include font-caption2-c1;

      text-align: left;
    }
  }

  &--is-landing {
    @media (min-width: 1990px) {
      @include footerCopyrightLabelLanding;
    }
  }

  &__container {
    @include font-caption1-c1;

    padding: 16px;
    color: var(--ColorContent1a);
    background-color: var(--ColorBackground2);
    border-radius: 0 0 20px 20px;

    &-inside {
      display: flex;
      gap: 16px;
      padding-bottom: 16px;
    }

    @include is-app-layout-desktop {
      padding: 24px;
    }
  }

  &__inside-year {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    color: var(--ColorContent1);
    border-top: 1px solid var(--ColorBackground1);

    &-text {
      @include font-body-b4;

      width: 100%;
    }
  }
}
</style>
