import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { sleep } from '@leon-hub/utils';

import type { DefaultModalProps } from '@components/dialogs';
import { ModalWidth } from '@components/dialogs';

import type { VSearchInputRef } from 'web/src/components/SearchInput/VSearchInput/types';

import type { AddressOption, ModalAddressSearchEmits, ModalAddressSearchProps } from '../types';

interface UseModalAddressSearch {
  isEmpty: Ref<boolean>;
  isNothingFound: Ref<boolean>;
  isLoaderVisible: Ref<boolean>;
  searchValue: Ref<string>;
  modalProps: Ref<DefaultModalProps>;
  innerFocus: Ref<HTMLInputElement | undefined>;
  searchInput: Ref<VSearchInputRef | undefined>;
  onSearchInput(value: string): void;
  onSearchClear(): void;
  onOptionSelect(option: AddressOption): void;
  onSearchBlur(): void;
  focusOnSearch(): void;
  handleKeydown(event: KeyboardEvent): void;
}

export function useModalAddressSearch(props: ModalAddressSearchProps, emit: ModalAddressSearchEmits): UseModalAddressSearch {
  const modalProps = computed<DefaultModalProps>(() => ({
    isFullHeight: !process.env.VUE_APP_LAYOUT_DESKTOP,
    width: ModalWidth.SMALL,
    isOverlayVisible: true,
    isCentered: false,
    isFullHeightCentered: false,
    isCloseAsBack: true,
    isScrollBarEnabled: true,
    isAlert: !!process.env.VUE_APP_LAYOUT_DESKTOP,
    topBarText: props.modalCaption,
  }));

  const innerFocus = ref<HTMLInputElement>();

  const searchInput = ref<VSearchInputRef>();

  const searchValue = ref<string>(props.currentValue ?? '');

  const isEmpty = computed<boolean>(() => {
    return !searchValue.value && !props.isPending && !props.options.length;
  });

  const isNothingFound = computed<boolean>(() => {
    return !!searchValue.value && !props.isPending && !props.options.length;
  });

  const isLoaderVisible = computed<boolean>(() => {
    return !!(props.isPending && !props.options.length);
  });

  const emitSearch = (value: string): void => {
    emit('search', value);
  };

  const onSearchInput = (value: string): void => {
    searchValue.value = value;
    emitSearch(value);
  };

  const onSearchClear = (): void => {
    searchValue.value = '';
    emitSearch('');
  };

  const onOptionSelect = (option: AddressOption): void => {
    emit('select-option', option);
    emit('close');
  };

  const allOptions = computed<AddressOption[]>(() => {
    return [...props.options ?? [], ...props.predefinedOptions ?? []];
  });

  const preselectByIndex = (index: number): void => {
    const matched = allOptions.value[index];
    if (matched) {
      onOptionSelect(matched);
    }
  };

  const isIndexIsSafe = (index: number): boolean => {
    return index > -1 && index < allOptions.value.length;
  };

  const emitPreselectedIndex = (index: number): void => {
    if (isIndexIsSafe(index)) {
      emit('set-preselected-index', index);
    }
  };

  // TODO: preselect does not work with predefined entries
  const handleKeydown = (event: KeyboardEvent): void => {
    const { code } = event;
    if (code === 'ArrowDown') {
      emitPreselectedIndex((props.preselectedIndex ?? -1) + 1);
    }
    if (code === 'ArrowUp') {
      emitPreselectedIndex((props.preselectedIndex ?? 0) - 1);
    }
    if (code === 'Enter' && isIndexIsSafe(props.preselectedIndex ?? -1)) {
      event.preventDefault();
      preselectByIndex(props.preselectedIndex ?? 0);
    }
  };

  const focusOnSearch = async (): Promise<void> => {
    // manual because autofocus is blocked in this context
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      await sleep(100);
      searchInput.value?.focusInputElement();
    }
  };

  const onSearchBlur = (): void => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      innerFocus.value?.focus();
    }
  };

  return {
    isEmpty,
    isNothingFound,
    isLoaderVisible,
    modalProps,
    searchValue,
    innerFocus,
    searchInput,
    focusOnSearch,
    onSearchInput,
    onSearchClear,
    onOptionSelect,
    onSearchBlur,
    handleKeydown,
  };
}
