<script lang="ts" setup>
import type { VPhotoInputProps } from 'web/src/components/PhotoInput/VPhotoInput/types';
import VPhotoInput from 'web/src/components/PhotoInput/VPhotoInput/VPhotoInput.vue';

import type {
  FileFormWidgetsEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useFormFileSingleEvents } from '../../composables';
import useFormPhotoInput from './useFormPhotoInput';

const props = defineProps<FormWrapperWidgetProperties<VPhotoInputProps>>();
const emit = defineEmits<FileFormWidgetsEmits>();

const {
  emitChange,
  emitBlur,
  emitFocus,
} = useFormFileSingleEvents(emit);

const { componentProps } = useFormPhotoInput(props);
</script>

<template>
  <VPhotoInput v-auto-id="'FormPhotoInput'"
    v-bind="componentProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
