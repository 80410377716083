<script setup lang="ts">
import { IconName } from '@leon-hub/icons';

import { InputButton } from 'web/src/components/Input/components';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';

import type { CryptoPaymentDataProps } from '../types';
import { useCryptoPaymentData } from '../composables/useCryptoPaymentData';
import { TabId } from '../enums/TabId';
// todo: probably should use helper below for qr src
// import getImageQr from '../../PaymentsCrypto/composables/utils/getImageQr';

const props = withDefaults(defineProps<CryptoPaymentDataProps>(), {
  name: 'payment-data',
  fields: () => [],
});
const {
  selectTab,
  activeTab,
  switcherOptions,
  showExpires,
  timeLeft,
  uniqueId,
  timerIsEnded,
  isTooltipShown,
  handleCopyToClipboard,
} = useCryptoPaymentData(props);
</script>

<template>
  <fieldset v-auto-id="'CryptoPaymentData'" :class="$style['crypto-payments-data']">
    <div
      v-if="qrCodeSrc"
      :class="$style['crypto-payments-data__switch']"
    >
      <VSwitcher
        :active-id="activeTab"
        :options="switcherOptions"
        @change="selectTab"
      />
    </div>
    <div :class="$style['crypto-payments-data__content']">
      <p
        v-if="timerIsEnded"
        :class="$style['crypto-payments-data__hint-ended']"
      >
        {{ $t('WEB2_PAYMENTS_EXPIRES_TIMER_END') }}
      </p>
      <template v-else>
        <p :class="$style['crypto-payments-data__hint']">
          {{ activeTab === TabId.Details ? $t('WEB2_PAYMENTS_PASTE_DATA_DESCRIPTION') : $t('WEB2_PAYMENTS_SCAN_QR_DESCRIPTION') }}
        </p>
        <p
          v-if="showExpires"
          :class="$style['crypto-payments-data__expires']"
        >
          {{ $t('WEB2_PAYMENTS_EXPIRES') }}: <span :class="$style['crypto-payments-data__timer']">{{ timeLeft }}</span>
        </p>
      </template>
      <template v-if="activeTab === TabId.Details">
        <ul :class="$style['crypto-payments-data__list']">
          <li
            v-for="(item, index) in fields"
            :key="index"
            :class="$style['crypto-payments-data__row']"
          >
            <div
              :class="{
                [$style['crypto-payments-data__item']]: true,
                [$style['crypto-payments-data__item--with-button']]: item.isCopyEnabled,
              }"
            >
              <label
                :class="$style['crypto-payments-data__label']"
                :for="`${uniqueId}${index}`"
              >{{ item.label }}</label>
              <input
                :id="`${uniqueId}${index}`"
                type="text"
                :class="$style['crypto-payments-data__value']"
                readonly
                :value="item.value"
              >
            </div>
            <div
              v-if="item.isCopyEnabled && item.value"
              :class="$style['crypto-payments-data__button']"
            >
              <InputButton
                :title="$t('WEB2_COPY')"
                :icon-name="null ? IconName.SLOTT_DUPLICATE : IconName.FILES"
                @click="handleCopyToClipboard(item.value)"
              />
            </div>
          </li>
        </ul>
      </template>
      <template v-if="activeTab === TabId.QrCode">
        <div :class="$style['crypto-payments-data__qr-frame']">
          <img
            :src="qrCodeSrc"
            alt="qr code"
            :class="$style['crypto-payments-data__qr']"
          >
        </div>
      </template>

      <VTooltip
        v-if="isTooltipShown"
        :class="$style['crypto-payments-data__tooltip']"
        :text="$t('WEB2_DEFAULT_COPY_NOTIFICATION')"
      />
    </div>
  </fieldset>
</template>

<style module lang="scss">
  .crypto-payments-data {
    position: relative;
    flex: none;
    padding: 0;
    margin: 0;
    border: none;

    &__tooltip {
      position: absolute;
    }

    &__switch {
      margin: 0 0 4px;
    }

    &__content {
      @include contentStyles;
    }

    &__hint {
      @include hintStyles;

      margin: 0 0 4px;

      &-ended {
        @include regular\13\16;

        margin: 8px 0;
        color: var(--AlertText);
        text-align: center;
      }
    }

    &__expires {
      @include expiresStyles;

      margin: 0 0 8px;
    }

    &__hint,
    &__expires {
      padding: 0;
      text-align: center;
    }

    &__timer {
      @include timerStyles;
    }

    &__list {
      @include listStyles;

      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      list-style: none inside;
    }

    &__row {
      @include rowStyles;

      display: flex;
      flex: none;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow: hidden;
    }

    &__item {
      @include itemStyles;

      flex: 1;
      overflow: hidden;

      &--with-button {
        padding-right: 0;
      }
    }

    &__label {
      @include labelStyles;

      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__value {
      @include valueStyles;

      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      background: transparent;
      border: none;
    }

    &__button {
      @include buttonStyles;

      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
    }

    &__qr-frame {
      width: 140px;
      height: 140px;
      margin: 0 auto;
      overflow: hidden;
      background: var(--White);
      border-radius: 5px;

      img {
        width: 154px;
        height: 154px;
        margin-top: -7px;
        margin-left: -7px;
      }
    }

    &__qr {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
