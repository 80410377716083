import { getOnboarding } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { FetchOnboardingType, OnboardingQueryDocument } from 'web/src/modules/onboarding/types';

export default function fetchOnboarding(
  { onboardingId, withSlides = false }: FetchOnboardingType,
): Promise<OnboardingQueryDocument> {
  const apiClient = useGraphqlClient();

  return getOnboarding(
    apiClient,
    (node) => node.queries.onboarding.getItems,
    {
      options: {
        ...(onboardingId ? { id: onboardingId } : {}),
        withSlides,
      },
    },
  );
}
