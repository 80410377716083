import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import type { FooterLogotype } from '@leon-hub/api-sdk';
import type { NavigationItem } from '@leon-hub/navigation-config';
import { HomePageType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useI18nLanguage, useI18nLocale } from '@core/i18n';
import { useNavigationItemsStore } from '@core/navigation';
import { useRootStore } from '@core/root';
import { useSiteConfigStore } from '@core/site-config';
import { useThemeStore } from '@core/theme';

import { LanguageSelectorPlace } from '@modules/localization-utilities';

import type {
  FooterPartner,
  SocialMediaListItem,
} from 'web/src/modules/core/types';
import { SocialType } from 'web/src/components/Social/VSocial/enums';
import {
  useScrollStore,
} from 'web/src/modules/core/store';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';

import { getPartnershipLogos } from './utils';
import getTsupisLogos from './utils/getTsupisLogos';

export interface FooterRouteComponentComposable {
  isOddsFormatSwitcherEnabled: Ref<boolean>;
  isCustomHomePage: Ref<boolean>;
  isLoggedIn: Ref<boolean>;
  showScrollTopButton: Ref<boolean>;
  genericInfoNavigationItems: Ref<NavigationItem[]>;
  bootstrapNavigationItems: Ref<NavigationItem[]>;
  doShowAppDownloadButtons: Ref<boolean>;
  affiliateFooterNavigationItem: Ref<NavigationItem | undefined>;
  socialButtonsList: Ref<SocialMediaListItem[]>;
  telegramBotNavigationItem: Ref<NavigationItem | undefined>;
  tsupisLogos: FooterPartner[];
  partnershipLogos: FooterPartner[];
  bookmakerRatingsUrl: Ref<Maybe<string | undefined>>;
  isLanguageSwitcherAvailable: Ref<boolean>;
  isThemeFooterSwitcherEnabled: Ref<boolean>;
  currentLanguageFlag: Ref<string>;
  doShowSponsoredClubsLogos: Ref<boolean>;
  followUsList: Ref<FooterLogotype[]>;
  routeClass: Ref<string>;
  goTop(): void;
  openLanguage(): void;
}

export default function useFooterRouteComponent(): FooterRouteComponentComposable {
  const route = useRoute();
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const { lang } = useI18nLocale();
  const bus = useEventsBus();
  const themeStore = useThemeStore();

  const { setLanguageSelectorPlace } = useI18nLanguage();
  const { isOddsFormatSwitcherEnabled } = useOddsSettings();

  const navigationStore = useNavigationItemsStore();
  const genericInfoNavigationItems = toRef(navigationStore, 'genericInfoNavigationItems');
  const bootstrapNavigationItems = toRef(navigationStore, 'bootstrapNavigationItems');
  const affiliateFooterNavigationItem = toRef(navigationStore, 'affiliateFooterNavigationItem');
  const telegramBotNavigationItem = toRef(navigationStore, 'telegramBotNavigationItem');

  const scrollStore = useScrollStore();
  const hasScrollableContent = toRef(scrollStore, 'hasScrollableContent');
  const scrollTop = toRef(scrollStore, 'scrollTop');

  const showScrollTopButton = computed(() => hasScrollableContent.value && scrollTop.value > 0);

  const siteConfigStore = useSiteConfigStore();
  const bookmakerRatingsUrl = toRef(siteConfigStore, 'bookmakerRatingsUrl');
  const doShowSponsoredClubsLogos = toRef(siteConfigStore, 'isPartnersEnabled');
  const mobileAppCordovaBlock = toRef(siteConfigStore, 'mobileAppCordovaBlock');
  const homePageType = toRef(siteConfigStore, 'homePageType');
  const socialMediaVkUrl = toRef(siteConfigStore, 'socialMediaVkUrl');
  const socialMediaInstagramUrl = toRef(siteConfigStore, 'socialMediaInstagramUrl');
  const socialMediaRssUrl = toRef(siteConfigStore, 'socialMediaRssUrl');
  const socialMediaTelegramUrl = toRef(siteConfigStore, 'socialMediaTelegramUrl');
  const isFooterSocialNetworksEnabled = toRef(siteConfigStore, 'isFooterSocialNetworksEnabled');
  const footerLogotypesBlock = toRef(siteConfigStore, 'footerLogotypesBlock');
  const doShowAppDownloadButtons = computed(() => !!mobileAppCordovaBlock.value?.mobileAppDownloadEnabled);

  const rootStore = useRootStore();
  const currentLanguageFlag = toRef(rootStore, 'currentLanguageFlag');
  const isLanguageSwitcherAvailable = toRef(rootStore, 'isLanguageSwitcherAvailable');

  const isThemeFooterSwitcherEnabled = toRef(themeStore, 'isThemeFooterSwitcherEnabled');

  const isCustomHomePage = computed(() => (
    homePageType.value === HomePageType.CUSTOM && route.name === RouteName.HOME
  ));

  const followUsList = computed<FooterLogotype[]>(() => {
    if (isFooterSocialNetworksEnabled.value) {
      return footerLogotypesBlock.value?.socialNetworkLogotypesByLocale ?? [];
    }
    return [];
  });

  const socialButtonsList = computed<SocialMediaListItem[]>(() => {
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      return [];
    }

    const list: SocialMediaListItem[] = [];
    const commonProps: Pick<SocialMediaListItem, 'size'> = {
      size: IconSize.SIZE_24,
    };

    if (socialMediaVkUrl.value) {
      list.push({
        name: 'VKontakte',
        clickCollectName: 'VKontakte',
        socialType: SocialType.VK,
        url: socialMediaVkUrl.value,
        ...commonProps,
      });
    }
    if (socialMediaInstagramUrl.value) {
      list.push({
        name: 'Instagram',
        clickCollectName: 'Instagram',
        socialType: SocialType.INSTAGRAM,
        url: socialMediaInstagramUrl.value,
        ...commonProps,
      });
    }
    if (socialMediaTelegramUrl.value) {
      list.push({
        name: 'Telegram',
        clickCollectName: 'Telegram',
        socialType: SocialType.TELEGRAM,
        url: socialMediaTelegramUrl.value,
        ...commonProps,
      });
    }
    if (socialMediaRssUrl.value) {
      list.push({
        name: 'Telegram',
        clickCollectName: 'Telegram',
        socialType: SocialType.RSS,
        url: socialMediaRssUrl.value.replace('{LANGUAGE_TAG}', lang.value),
        ...commonProps,
      });
    }

    return list;
  });

  const tsupisLogos = getTsupisLogos();
  const partnershipLogos = getPartnershipLogos();

  const routeClass = computed(() => route.name as string);

  function goTop(): void {
    bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
  }

  function openLanguage(): void {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      setLanguageSelectorPlace(LanguageSelectorPlace.FOOTER);
      void router.push({
        name: RouteName.LANGUAGE,
      });
    }
  }

  return {
    isOddsFormatSwitcherEnabled,
    isCustomHomePage,
    showScrollTopButton,
    isLoggedIn,
    genericInfoNavigationItems,
    bootstrapNavigationItems,
    doShowAppDownloadButtons,
    affiliateFooterNavigationItem,
    socialButtonsList,
    telegramBotNavigationItem,
    tsupisLogos,
    partnershipLogos,
    bookmakerRatingsUrl,
    isLanguageSwitcherAvailable,
    isThemeFooterSwitcherEnabled,
    currentLanguageFlag,
    doShowSponsoredClubsLogos,
    followUsList,
    goTop,
    openLanguage,
    routeClass,
  };
}
