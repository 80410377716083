import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getFAQCategories, getFAQItems } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { FaqCategoriesDocument, FaqCategory } from 'web/src/modules/support/types';

const useSupportFaqStore = defineStore('supportFaq', () => {
  const apiClient = useGraphqlClient();

  const faqCategories = ref<FaqCategory[]>([]);
  const faqs = ref<FaqCategoriesDocument>([]);
  const searchText = ref('');
  const isYandexMetrikaAllowed = ref(false);

  async function fetchCategories(silent?: boolean): Promise<void> {
    const response = await getFAQItems(apiClient, (node) => node.queries.faq.getList, { options: {} }, {
      silent,
    });

    const faqCategs: FaqCategory[] = [];
    for (const item of response.items) {
      for (const categoryName of item.categories) {
        if (categoryName) {
          let faqCategory = faqCategs.find((category) => category.name === categoryName);
          if (!faqCategory) {
            faqCategory = {
              name: categoryName,
              items: [],
            };

            faqCategs.push(faqCategory);
          }

          faqCategory.items.push(item);
        }
      }
    }

    faqCategories.value = faqCategs;
  }

  async function fetchFaqCategories(): Promise<void> {
    const response = await getFAQCategories(apiClient, (node) => node.queries.faq.getCategories, {});

    faqs.value = response.categories;
  }

  function setSearchText(value: string): void {
    searchText.value = value;
  }

  function setIsYandexMetrikaAllowed(value: boolean): void {
    isYandexMetrikaAllowed.value = value;
  }

  return {
    faqCategories,
    faqs,
    searchText,
    isYandexMetrikaAllowed,
    fetchCategories,
    fetchFaqCategories,
    setSearchText,
    setIsYandexMetrikaAllowed,
  };
});

export default useSupportFaqStore;
