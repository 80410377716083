import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import { useCurrencyStore, useFormatMoney } from '@core/money';

interface UseSmartCurrencyInput {
  currencyRightSign: ComputedRef<string>;
  currencyLeftSign: ComputedRef<string>;
}

export default function useSmartCurrencyInput(): UseSmartCurrencyInput {
  const formatMoney = useFormatMoney();

  const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');

  const isCurrencyOnRightSide = computed<boolean>(() => {
    const formattedValue = formatMoney(8);
    const index = formattedValue.indexOf('8');
    return index === 0;
  });

  const currencyRightSign = computed<string>(() => (isCurrencyOnRightSide.value ? formattedCurrency.value : ''));

  const currencyLeftSign = computed<string>(() => (!isCurrencyOnRightSide.value ? formattedCurrency.value : ''));

  return {
    currencyLeftSign,
    currencyRightSign,
  };
}
