import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  doSendFeedback,
  getFeedbackTypes,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { FeedbackTypeDocument, SendFeedbackInput } from 'web/src/modules/support/submodules/feedback/types';
import useUserFeedbackResponse from 'web/src/modules/feedback/composables/useUserFeedbackResponse';

const useSupportFeedbackStore = defineStore('supportFeedback', () => {
  const apiClient = useGraphqlClient();
  const feedbackTypes = ref<FeedbackTypeDocument[]>([]);
  const { sendUserResponse } = useUserFeedbackResponse();

  async function fetchTypes(silent?: boolean): Promise<void> {
    const { feedbackTypes: fbTypes } = await getFeedbackTypes(
      apiClient,
      (node) => node.queries.feedback.getDetails,
      { options: {} },
      { silent },
    );
    feedbackTypes.value = [...fbTypes];
  }

  async function sendFeedback(input: SendFeedbackInput): Promise<boolean> {
    const { result } = await doSendFeedback(
      apiClient,
      (node) => node.mutations.feedback.send,
      {
        options: {
          doAction: '1',
          email: input.email,
          betId: input.betId,
          description: input.description,
          failedResponsesLogs: '',
          feedbackUniqKey: input.type,
        },
      },
    );
    return result === 'OK';
  }

  return {
    feedbackTypes,
    fetchTypes,
    sendFeedback,
    sendUserResponse,
  };
});

export default useSupportFeedbackStore;
