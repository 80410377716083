<script lang="ts" setup>
import { provide } from 'vue';

import { dataTest as vDataTest } from '@leon-hub/directives';

import type { VScrollbarDesktopEmits, VScrollbarDesktopProps, VScrollbarRef } from '../types';
import { ScrollbarProvidableContext } from '../enums';
import { useVScrollbarDesktop } from './composables';

const props = withDefaults(defineProps<VScrollbarDesktopProps>(), {});
const emit = defineEmits<VScrollbarDesktopEmits>();

const {
  testAttrs,
  element,
  providableContext,
  onScroll,
  scrollTo,
  scrollTop,
  scrollDown,
  scrollRight,
  scrollUpdate,
  scrollToElement,
  recompose,
} = useVScrollbarDesktop(props, emit);

provide(ScrollbarProvidableContext.ScrollbarContext, providableContext);

defineExpose<VScrollbarRef>({
  scrollTo,
  scrollTop,
  scrollDown,
  scrollRight,
  scrollUpdate,
  scrollToElement,
  recompose,
});
</script>

<template>
  <div v-auto-id="'VScrollbarDesktop'"
    ref="element"
    v-data-test="testAttrs"
    :class="{
      [$style['scrollbar']]: true,
      [$style['scrollbar--flex-fill']]: flexFill,
      [$style['scrollbar--dropdown']]: isDropdown,
    }"
    @wheel="onScroll"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
.scrollbar {
  :global(.scrollbar-thumb) {
    background-color: transparent;

    &:before {
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      content: '';
      background-color: var(--Highlight);
      border-radius: 4px;
    }
  }

  :global(.scrollbar-thumb-y) {
    width: 16px;
  }

  :global(.scrollbar-thumb-x) {
    height: 16px;
  }

  :global(.scrollbar-track) {
    background-color: transparent;
  }

  :global(.scrollbar-track-y) {
    width: 16px;
  }

  :global(.scrollbar-track-x) {
    height: 16px;
  }

  :global(.scroll-content) {
    min-height: 100%;
    will-change: transform;
  }

  :global(html.app__browser--firefox) :local & {
    :global(.scrollbar-thumb:before) {
      background-color: rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    }

    :global(.scrollbar-thumb-y:before) {
      width: 5px;
    }

    :global(.scrollbar-thumb-x:before) {
      height: 5px;
    }
  }

  :global(html.app__browser--firefox.app__theme--light) :local & {
    :global(.scrollbar-thumb:before) {
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
    }
  }

  :global(html.app__os--windows.app__browser--firefox) :local & {
    :global(.scrollbar-thumb:before) {
      background-color: var(--Highlight);
      border-radius: 0;
      box-shadow: none;
    }

    :global(.scrollbar-thumb-y:before) {
      top: 0;
      bottom: 0;
      left: 8px;
      width: 8px;
    }

    :global(.scrollbar-thumb-x:before) {
      top: 8px;
      right: 0;
      left: 0;
      height: 8px;
    }
  }

  :global(html.app__os--macos) :local & {
    :global(.scrollbar-thumb:before) {
      background-color: var(--White);
      opacity: 0.55;
    }

    :global(.scrollbar-thumb-y:before) {
      left: 5px;
      width: 7px;
    }

    :global(.scrollbar-thumb-x:before) {
      top: 5px;
      height: 7px;
    }
  }

  :global(html.app__os--macos.app__theme--light) :local & {
    :global(.scrollbar-thumb:before) {
      background-color: var(--Black);
    }
  }

  &--flex-fill {
    flex: 1;

    :global(.scroll-content) {
      display: flex;
      flex-direction: column;
    }
  }

  &--dropdown {
    max-height: 296px;
  }
}
</style>
