import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useBalance } from '@core/money';

import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';
import usePaymentsMaxValueFastChoiceDeposit
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoiceDeposit';

export interface PaymentsMaxValueFastChoiceComposable {
  maxValueFastChoice: Ref<number>;
}
export default function usePaymentsMaxValueFastChoice(
  props: PaymentsFastSumInputProps,
): PaymentsMaxValueFastChoiceComposable {
  const isDeposit = toRef(props, 'isDeposit');
  const maxAmount = toRef(props, 'maxAmount');
  const { balance } = useBalance();
  const { maxValueFastChoiceDeposit } = usePaymentsMaxValueFastChoiceDeposit(props);

  const maxValueFastChoice = computed(() => {
    if (isDeposit.value) {
      return maxValueFastChoiceDeposit.value;
    }

    if (maxAmount.value) {
      return balance.value < maxAmount.value ? balance.value : maxAmount.value;
    }
    return balance.value;
  });

  return {
    maxValueFastChoice,
  };
}
