import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import { isString } from '@leon-hub/guards';

import type { ApplicationRestClient } from '@core/app-rest-client';

import type {
  CasBaseRequest,
  DeleteLoginCasRequest,
  GetCasUserResponse,
  LoginCasRequest,
  LoginResponse,
} from './types';
import {
  isCasUserResponse,
  isLoginResponse,
} from './guards';

function encodeFormData(data: Record<string, string | boolean>) {
  return Object.keys(data).reduce((parameters, key) => {
    parameters.append(key, String(data[key]));
    return parameters;
  }, new URLSearchParams());
}

interface CasApiClient {
  loginCas(data: LoginCasRequest): Promise<LoginResponse>;
  getSavedLogins(data: CasBaseRequest): Promise<GetCasUserResponse>;
  checkCookie(data: CasBaseRequest): Promise<string>;
  deleteSavedLogins(data: DeleteLoginCasRequest): Promise<GetCasUserResponse>;
}

export default function createCasApi(client: ApplicationRestClient): CasApiClient {
  return {
    async loginCas({ username, password, endpoint }: LoginCasRequest) {
      return client.request<LoginResponse>({
        id: 'cas-api/login-cas',
        silent: true,
        priority: RequestOptionsPriority.LOW,
        method: 'POST',
        endpoint: `${endpoint}rest/auth/login/`,
        data: password
          ? encodeFormData({ username, password })
          : encodeFormData({ username, 'saved-password': true }),
        guard: isLoginResponse,
      });
    },
    async getSavedLogins(data: CasBaseRequest) {
      return client.request<GetCasUserResponse>({
        id: 'cas-api/get-saved-logins',
        silent: true,
        priority: RequestOptionsPriority.LOW,
        endpoint: `${data.endpoint}rest/auth/saved-passwords/`,
        guard: isCasUserResponse,
      });
    },
    async checkCookie(data: CasBaseRequest) {
      return client.request<string>({
        id: 'cas-api/check-cookie',
        silent: true,
        priority: RequestOptionsPriority.LOW,
        endpoint: `${data.endpoint}rest/auth/cookie-check`,
        guard: isString,
      });
    },
    async deleteSavedLogins({ username, endpoint }: DeleteLoginCasRequest) {
      return client.request<GetCasUserResponse>({
        id: 'cas-api/delete-saved-logins',
        silent: true,
        priority: RequestOptionsPriority.LOW,
        method: 'POST',
        endpoint: `${endpoint}rest/auth/saved-passwords/delete`,
        data: encodeFormData({ username }),
        guard: isCasUserResponse,
      });
    },
  };
}
