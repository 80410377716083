<script lang="ts" setup>
import { VButton } from '@components/buttons';

import type { VLoadMoreObserverEmits, VLoadMoreObserverProps } from './types';
import { useVLoadMoreObserver } from './composables';

const props = withDefaults(defineProps<VLoadMoreObserverProps>(), {
  fallbackButtonText: '',
});

const emit = defineEmits<VLoadMoreObserverEmits>();

const {
  collision,
  buttonText,
  showFallbackButton,
} = useVLoadMoreObserver(props, emit);

function onButtonClick(): void {
  emit('load-more');
}
</script>

<template>
  <div v-auto-id="'VLoadMoreObserver'"
    ref="collision"
    :class="$style['load-more-observer']"
  >
    <VButton
      v-if="showFallbackButton"
      :label="buttonText"
      @click="onButtonClick"
    />
  </div>
</template>

<style lang="scss" module>
.load-more-observer {
  min-height: 1px;
  margin: 20px 0;
  text-align: center;
}
</style>
