<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';
import { TextInputTypes } from '@leon-hub/input-types';

import { PieTimerSize } from 'web/src/components/PieTimer/VPieTimer/enums';
import VPieTimer from 'web/src/components/PieTimer/VPieTimer/VPieTimer.vue';

import type { PieTimerInputProps } from '../../types';
import { useBaseInputEvents } from '../../composables';
import TextInput from '../TextInput';

defineOptions({
  inheritAttrs: false,
});

withDefaults(defineProps<PieTimerInputProps>(), {
  name: 'pie-timer-input',
  timerPercentage: 0,
});

const emits = defineEmits<BaseInputEmits>();

const {
  emitBlur,
  emitInput,
  emitFocus,
  emitChange,
} = useBaseInputEvents(emits);
</script>

<template>
  <TextInput v-auto-id="'PieTimerInput'"
    v-data-test="{
      el: 'pie-timer-input',
      name,
    }"
    :autofocus="autofocus"
    :name="name"
    :label="label"
    :value="value"
    :disabled="disabled"
    :placeholder="placeholder"
    :hint="hint"
    :hint-link="hintLink"
    :hint-right="hintRight"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :type="TextInputTypes.TEL"
    :regexp="regexp"
    :is-large="isLarge"
    autocomplete="off"
    @blur="emitBlur"
    @input="emitInput"
    @focus="emitFocus"
    @change="emitChange"
  >
    <template #iconSuffix>
      <VPieTimer
        :percentage="timerPercentage"
        :size="PieTimerSize.SMALL"
      />
    </template>
  </TextInput>
</template>
