import type { Ref } from 'vue';
import { onBeforeUnmount, ref, watch } from 'vue';

import { assert } from '@leon-hub/guards';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { InputEventType } from '@leon-hub/input-types';
import { Timer } from '@leon-hub/utils';

import type { VScrollbarRef } from '@components/v-scrollbar';

import type { DropdownMenuEmits, DropdownMenuProps, TemplateRef } from '../types';

interface UseDropdownMenu {
  onClick(value: string): void;
  onAppear(): void;
  onOptionHover(index: number): void;
  setButtonsRef(templateRef: TemplateRef, index: number): void;
  scrollbarRef: Ref<VScrollbarRef | undefined>;
}

export default function useDropdownMenu(
  props: DropdownMenuProps,
  emit: DropdownMenuEmits,
): UseDropdownMenu {
  const buttonsRef = ref<HTMLButtonElement[]>([]);
  const scrollbarRef = ref<VScrollbarRef>();

  const onClick = (value: string): void => {
    emit(InputEventType.CHANGE, value);
  };

  let lastHoverIndex: number | null = null;

  let isScrollingIntoView = false;

  let scrollTimer = 0;

  /** scroll to option */
  const onPreselectedIndexChange = (index: number, oldIndex: number): void => {
    if (index < 0) {
      return;
    }
    Timer.clearTimeout(scrollTimer);
    const safeIndex = buttonsRef.value?.[index] ? index : 0;
    if (safeIndex === lastHoverIndex) {
      // prevent scroll on hover
      return;
    }
    lastHoverIndex = null;
    const button = buttonsRef.value?.[safeIndex];
    isScrollingIntoView = true;
    scrollTimer = Timer.setTimeout(async () => {
      scrollbarRef.value?.scrollToElement(button, {
        onlyIfNeeded: true,
        alignToTop: oldIndex > safeIndex,
      });
      await nextAnimationFrame();
      isScrollingIntoView = false;
    }, 0);
  };

  const onAppear = (): void => {
    emit('mounted');
  };

  onBeforeUnmount(() => Timer.clearTimeout(scrollTimer));

  const onOptionHover = (index: number): void => {
    if (!isScrollingIntoView) {
      lastHoverIndex = index;
      emit('option-hover', index);
    }
  };

  const setButtonsRef = (templateRef: TemplateRef, index: number): void => {
    if (templateRef) {
      assert(templateRef instanceof HTMLButtonElement);
      buttonsRef.value[index] = templateRef;
    }
  };

  watch(() => props.options, (value, oldValue) => {
    const itemsIsInOverflowSize = 20;
    if (value && value?.length > itemsIsInOverflowSize && oldValue && oldValue?.length > itemsIsInOverflowSize) {
      /**
       * in this case height is limited by screen / scroll. Avoid extra styles recalculation
       */
      return;
    }
    if (value?.length !== oldValue?.length) {
      emit('list-size-updated');
    }
  });

  watch(() => props.preselectedListIndex, onPreselectedIndexChange);

  return {
    onClick,
    onAppear,
    onOptionHover,
    setButtonsRef,
    scrollbarRef,
  };
}
