import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { RequestGroupScore24 } from '@leon-hub/api';
import {
  getCmsContentSpintaxBatchTranslations,
  getCmsContentTranslations,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';
import { useI18nStore } from '@core/i18n';

const useCmsStore = defineStore('cms', () => {
  const api = useGraphqlClient();
  const i18nStore = useI18nStore();
  const locale = toRef(i18nStore, 'locale');

  const cmsData: Record<string, Record<string, Ref<string | undefined>>> = {};
  const cmsSpintaxData: Record<string, Ref<string>> = {};

  function checkCmsData(lang: string, key: string): void {
    if (!cmsData[lang]) {
      cmsData[lang] = {};
    }

    if (!cmsData[lang][key]) {
      cmsData[lang][key] = ref(undefined);
    }
  }

  function checkSpintaxData(lang: string): void {
    if (!cmsSpintaxData[lang]) {
      cmsSpintaxData[lang] = ref('');
    }
  }

  async function fetchCmsContentTranslation({ key, silent }: { key: string; silent?: boolean }): Promise<void> {
    const response = await getCmsContentTranslations(
      api,
      (node) => node.queries.system.cms.content,
      { options: { key } },
      {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : undefined,
      },
    );

    if (response) {
      for (const translation of response.translations) {
        checkCmsData(translation.lang, key);
        cmsData[translation.lang][key].value = translation.html || '';
      }
    }
  }

  async function fetchCmsContentSpintaxBatchTranslations(
    { keys, cmsParams, silent }: { keys: string[]; cmsParams: Record<string, string>; silent?: boolean },
  ): Promise<void> {
    try {
      const response = await getCmsContentSpintaxBatchTranslations(
        api,
        (node) => node.queries.system.cms.getSpintaxBatch,
        {
          options: {
            contentKeys: [...keys],
            ...cmsParams,
          },
        },
        {
          silent,
          priority: silent ? RequestOptionsPriority.LOW : undefined,
          group: RequestGroupScore24,
        },
      );

      if (response) {
        for (const translation of response.translations) {
          checkSpintaxData(translation.lang);
          cmsSpintaxData[translation.lang].value = translation.html || '';
        }
      }
    } catch {
    // empty
    }
  }

  function hasCmsItem(key: string): boolean {
    return cmsData[locale.value]?.[key]?.value !== undefined;
  }

  function getCmsContent(isSpintaxEnabled: Ref<boolean>, key: Ref<string | undefined>): Ref<string | undefined> {
    return computed(() => {
      if (isSpintaxEnabled.value) {
        checkSpintaxData(locale.value);
        return cmsSpintaxData[locale.value].value;
      }

      if (!key.value) {
        return undefined;
      }

      checkCmsData(locale.value, key.value);
      return cmsData[locale.value][key.value].value;
    });
  }

  function resetCmsSpintaxData(): void {
    for (const item of Object.values(cmsSpintaxData)) {
      item.value = '';
    }
  }

  return {
    fetchCmsContentTranslation,
    fetchCmsContentSpintaxBatchTranslations,
    hasCmsItem,
    resetCmsSpintaxData,
    getCmsContent,
  };
});

export default useCmsStore;
