import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  Banner,
  BannerGroup,
} from 'web/src/modules/banners/store/types';
import type { AbstractBannerEmits, AbstractBannerProps } from 'web/src/modules/banners/types';

export interface UseAbstractBannerComposable {
  isGroupBannersDev: Ref<Optional<BannerGroup['isNT']>>;
  banners: Ref<BannerGroup['banners']>;
  autoplayTimeout: Ref<number>;
  linkTitle: Ref<Optional<BannerGroup['name']>>;
  emitBannerItemClick(path: string, banner: Banner): void;
}

export function useAbstractBanner(props: AbstractBannerProps, emit: AbstractBannerEmits): UseAbstractBannerComposable {
  const isGroupBannersDev = computed<Optional<BannerGroup['isNT']>>(() => props.bannerGroup?.isNT);
  const banners = computed<BannerGroup['banners']>(() => props.bannerGroup?.banners ?? []);
  const autoplayTimeout = computed<number>(() => props.bannerGroup?.autoplayDelay || 0);
  const linkTitle = computed<Optional<BannerGroup['name']>>(() => props.bannerGroup?.name);

  function emitBannerItemClick(path: string, banner: Banner) {
    emit('banner-item-click', {
      path,
      banner,
    });
  }

  return {
    isGroupBannersDev,
    banners,
    autoplayTimeout,
    linkTitle,
    emitBannerItemClick,
  };
}
