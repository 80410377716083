import type {
  Bonus,
  BonusAccumulative,
  BonusFirstDeposit,
  doActivateBonusCodeQuery,
  doWithdrawAllSportBonusesQuery,
  getAvailableBonusesForTransferQuery,
  getBonusesQuery,
  getBonusWagersQuery,
} from '@leon-hub/api-sdk';
import {
  BonusCategory,
  BonusState,
} from '@leon-hub/api-sdk';
import {
  isBoolean,
  isEnumOfType,
  isNull,
  isNumber,
  isString,
  isValidObject,
} from '@leon-hub/guards';

export type AvailableBonusesDocument =
  Exclude<getAvailableBonusesForTransferQuery['queries']['bonuses']['getAvailableBonusesForTransfer']['data'], null>;

export type CustomerWalletFrozenDocuments = AvailableBonusesDocument['frozen'][number];

export type BonusWithdrawResponseDocument =
  Exclude<doWithdrawAllSportBonusesQuery['mutations']['bonuses']['withdrawAllSportBonuses']['data'], null>;

export type BonusWagersResponseDocument =
  Exclude<getBonusWagersQuery['queries']['bonuses']['getBonusWagers']['data'], null>;

export type ActivateBonusCodeDocument =
  Exclude<doActivateBonusCodeQuery['mutations']['bonuses']['joinByBonusCode']['data'], null>;

export type BonusItemSdk = BonusWagersResponseDocument['bonuses'][number];

export interface ActivateBonusCodeError {
  message: string;
  value: string;
}

export interface LoadingState {
  bonusList: boolean;
  bonusCBC: boolean;
}

export enum BonusLoadingKey {
  BONUS_LIST = 'bonus-list',
  BONUS_CBC = 'bonus-cbc',
}

export function isBonusLoadingKey(value: unknown): value is BonusLoadingKey {
  return isEnumOfType(BonusLoadingKey)(value);
}

export enum BonusPanels {
  CBC = 'cbc',
  BALANCE = 'balance',
  PROGRAM = 'program',
  NONE = 'none',
}

export interface BonusLoading {
  key: BonusLoadingKey;
  status: boolean;
}

const BASE_BONUS_RULES = {
  walletBalanceNumber: [isNumber],
  category: [isEnumOfType(BonusCategory)],
  widgetName: [isString],
  state: [isEnumOfType(BonusState)],
  currency: [isString],
  progress: [isNumber],
};

export function isBonusAccumulative(input: unknown, silent = false): input is BonusAccumulative {
  return isValidObject<BonusAccumulative>(input, {
    ...BASE_BONUS_RULES,
    isLeonshopShown: [isBoolean],
    bonusCurrency: [isString, isNull],
    remainingAmountNumber: [isNumber],
    bonusAmountNumber: [isNumber],
  }, silent);
}

export function isBonusFirstDeposit(input: unknown, silent = false): input is BonusFirstDeposit {
  return isValidObject<BonusFirstDeposit>(input, {
    ...BASE_BONUS_RULES,
    isLeonshopShown: [isBoolean],
    bonusCurrency: [isString, isNull],
    requiredAmountNumber: [isNumber],
    bonusAmountNumber: [isNumber],
  }, silent);
}

export function isBonus(input: unknown, silent = false): input is Bonus {
  return isValidObject<Bonus>(input, {
    ...BASE_BONUS_RULES,
    amountNumber: [isNumber],
    availableAmountNumber: [isNumber],
    requiredAmountNumber: [isNumber],
    campaignName: [isString, isNull],
    expiredAt: [isString, isNull],
    expiringThrough: [isString, isNull],
    expiredTimestamp: [isNumber],
    isExpired: [isBoolean],
    bonusId: [isNumber],
    actionUrl: [isString, isNull],
    categoryId: [isString, isNull],
  }, silent);
}

export interface UpdateBonusDataProperties {
  bonusCategory: BonusCategory;
  newProperties: Partial<Bonus>;
}

export type PaymentBonusArray = Exclude<getBonusesQuery['queries']['bonuses']['getBonuses']['data'], null>['bonuses'];

export type PaymentBonus = PaymentBonusArray[0];
