<script lang="ts" setup>
import type {
  CasinoLoyaltyProgramCardEmits,
  CasinoLoyaltyProgramCardProps,
} from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCard/types';
import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import CasinoLoyaltyProgramLevel
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevel/CasinoLoyaltyProgramLevel.vue';
import {
  CasinoLoyaltyProgramCardSizes,
  CasinoLoyaltyProgramIconSizes,
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';

import { useCasinoLoyaltyProgramCard } from './composables';

const props = withDefaults(defineProps<CasinoLoyaltyProgramCardProps>(), {
  currentValue: 0,
  total: 0,
  cardSize: CasinoLoyaltyProgramCardSizes.SIZE_124,
  levelStatus: CasinoLoyaltyProgramLevelStatus.SUCCESS,
});

const emits = defineEmits<CasinoLoyaltyProgramCardEmits>();

const { isInactive, clickCard } = useCasinoLoyaltyProgramCard(props, emits);
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramCard'"
    :class="{
      [$style['casino-loyalty-program-card']]: true,
      [$style[`casino-loyalty-program-card--${cardSize}`]]: cardSize !== CasinoLoyaltyProgramCardSizes.SIZE_124,
    }"
    @click="clickCard"
  >
    <CasinoLoyaltyProgramIcon
      :class="$style['casino-loyalty-program-card__icon']"
      :image-src="imageSrc"
      :image="image"
      :size="CasinoLoyaltyProgramIconSizes.SIZE_92"
      :is-inactive="isInactive"
    />
    <p :class="$style['casino-loyalty-program-card__title']">
      {{ title }}
    </p>
    <CasinoLoyaltyProgramLevel
      :status="levelStatus"
    />
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 192px;
  padding: 0 8px;
  background-color: var(--Layer1);
  border-radius: 5px;

  @include for-hover {
    &:hover {
      cursor: pointer;
      background-color: var(--Highlight);
    }
  }

  &--size-116 {
    width: 116px;
  }

  &__title {
    @include medium\18\24;

    max-width: 100%;
    margin: 0 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    margin-bottom: 8px;
    color: var(--TextDefault);
  }
}
</style>
