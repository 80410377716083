<script lang="ts" setup>
import { onBeforeMount, useSlots } from 'vue';

import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { VRadioEmits, VRadioProps } from './types';
import { useVRadio } from './composables';

const props = withDefaults(defineProps<VRadioProps>(), {});
const emit = defineEmits<VRadioEmits>();
const slots = useSlots();
const {
  isChecked,
  hasLabel,
  hasSubTitle,
  onChange,
  beforeMount,
} = useVRadio(props, emit, slots);

onBeforeMount(beforeMount);
</script>

<template>
  <label v-auto-id="'VRadio'"
    :class="{
      [$style.radio]: true,
      [$style[`radio--disabled`]]: disabled,
      [$style[`radio--checked`]]: checked,
      [$style[`radio--error`]]: error,
      [$style[`radio--has-icon`]]: hasIcon,
      [$style[`radio--has-no-label`]]: hasNoLabel,
    }"
    :for="id"
  >
    <span :class="$style.radio__button">
      <input
        :id="id"
        :checked="isChecked"
        :disabled="disabled"
        :name="name"
        :tabindex="tabIndex"
        :value="value"
        :class="$style.radio__input"
        type="radio"
        @change="onChange"
        @click.stop
      >
      <span
        :class="$style.radio__bg"
      >
        <VIcon
          v-if="hasIcon"
          :name="IconName.CHECK_ROUNDED"
          :class="$style.radio__icon"
        />
      </span>
    </span>
    <span
      v-if="hasLabel"
      :class="$style.radio__label"
    >
      <slot>{{ label }}</slot>
    </span>
    <span
      v-if="hasSubTitle"
      :class="$style.radio__subtitle"
    >
      <slot name="content">{{ subTitle }}</slot>
    </span>
  </label>
</template>

<style lang="scss" module>
.radio {
  $self: &;

  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 44px;
  padding: 10px 0;
  color: $radioColor;
  cursor: pointer;

  @include for-hover {
    &:not(#{$self}--disabled):hover {
      #{$self}__bg {
        box-shadow: inset 0 0 0 2px $radioInputHover;
      }

      #{$self}__input:checked + #{$self}__bg {
        box-shadow: inset 0 0 0 2px $radioInputCheckedColor;
      }
    }
  }

  &--has-no-label {
    min-height: 20px;
    padding: 0;
  }

  &__input {
    @include z-index(base);

    position: absolute;
    top: -10px;
    left: -10px;
    width: 0;
    height: 0;
    opacity: 0;

    &:not(:disabled) {
      cursor: pointer;
    }

    &:checked + #{$self}__bg {
      background-color: transparent;
      box-shadow: inset 0 0 0 2px $radioInputCheckedHoverColor;

      &:after {
        transform: scale(1);
      }

      #{$self}__icon {
        opacity: 1;
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  &__bg {
    @include nullTransformHack;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px currentcolor;
    transition: box-shadow 0.15s $animationEaseInOut;

    &:after {
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      background-color: $radioInputCheckedColor;
      border-radius: 50%;
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform: scale(0);
    }
  }

  &__label {
    @include radioLabelFont;

    flex: 1;
    padding: 2px 0 2px 12px;
    color: $radioLabelColor;
  }

  &__icon {
    @include z-index(base);

    position: relative;
    opacity: 0;
    fill: $radioIconFill;
  }

  &__subtitle {
    @include radioSubtitleFont;

    flex-basis: 100%;
    padding-left: 36px;
    margin-top: $radioSubtitleMarginTop;
    color: $radioSubtitleColor;
  }

  &--disabled {
    cursor: default;
    opacity: $radioDisabledOpacity;

    &#{$self}--checked {
      #{$self}__bg {
        box-shadow: inset 0 0 0 2px $radioDisabledCheckedColor;

        &:after {
          background-color: $radioDisabledCheckedColorAfter;
        }
      }
    }
  }

  &--error {
    #{$self}__bg {
      box-shadow: inset 0 0 0 2px $radioInputError;
    }
  }

  &--has-icon {
    #{$self}__bg:after {
      width: 24px;
      height: 24px;
    }

    &#{$self}--checked {
      #{$self}__bg {
        background-color: $radioInputChecked;
      }
    }
  }
}
</style>
