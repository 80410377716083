import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import type {
  CustomerFavoritesIdsBackgroundUpdateKey,
} from 'web/src/modules/sportline/submodules/favorites/enums';
import type { SportElement } from 'web/src/modules/sportline/types';
import { filterSportListRegions, isSportFamilyEquals } from 'web/src/modules/sportline/utils';

import useCustomerFavoritesService from './useCustomerFavoritesService';

interface UseSportlineFavoritesStoreComposableProps {
  backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey;
  prepareEventsList(fullList: SportElement[]): SportElement[];
}

interface UseSportlineFavoritesStoreComposable {
  favoriteSelectedRegionFamily: Ref<Maybe<string>>;
  favoriteSelectedSportFamily: Ref<Maybe<string | SportFamily>>;
  favoriteEventsFullList: Ref<SportElement[]>;
  favoriteEventsListForDisplay: Ref<SportElement[]>;
  onInit(): void;
  initialRequests(): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  setFavoriteSelectedSportFamily(sportFamily: Maybe<string | SportFamily>): void;
  setFavoriteSelectedRegionFamily(regionFamily: Maybe<string>): void;
  selectFirstAvailableFavoriteSportFamily(): void;
}

/**
 * Contains shared logic for pages with a favourite tab
 */
export function useSportlineFavoritesStoreComposable(
  props: UseSportlineFavoritesStoreComposableProps,
): UseSportlineFavoritesStoreComposable {
  const {
    backgroundFavoritesId,
    prepareEventsList,
  } = props;

  const {
    fullCustomerSportEventsList,
    initialRequests,
    setIsBackgroundIdsUpdateAvailable,
  } = useCustomerFavoritesService();

  const favoriteSelectedSportFamily = ref<Maybe<string | SportFamily>>(null);
  const favoriteSelectedRegionFamily = ref<Maybe<string>>(null);

  /**
   * Filtered favorites events list from favorites events request and favorites leagues
   * Used for favorites regions filter
   */
  const favoriteEventsFullList = computed<SportElement[]>(() => (
    prepareEventsList(fullCustomerSportEventsList.value ?? [])
  ));

  const favoritesAvailableSportFamilies = computed<string[]>(() => (
    favoriteEventsFullList.value
      .flatMap((sportElement) => sportElement.sport.representation.family)
  ));
  const favoritesAvailableSportFamiliesHash = computed<string>(() => favoritesAvailableSportFamilies.value.join(','));

  const favoritesAvailableRegionFamilies = computed<string[]>(() => (
    favoriteEventsFullList.value
      .flatMap((sportElement) => sportElement.regions
        .flatMap((regionElement) => regionElement.region.regionFamily))
  ));
  const favoritesAvailableRegionFamiliesHash = computed<string>(() => favoritesAvailableRegionFamilies.value.join(','));

  /**
   * Actual list for display at favorites tab
   */
  const favoriteEventsListForDisplay = computed<SportElement[]>(() => {
    let result = [...favoriteEventsFullList.value];

    if (favoriteSelectedSportFamily.value) {
      result = result.filter((sportElement) => isSportFamilyEquals(sportElement.sport, favoriteSelectedSportFamily.value));
    }

    if (favoriteSelectedRegionFamily.value) {
      result = filterSportListRegions(
        result,
        (regionElement) => (regionElement.region.regionFamily === favoriteSelectedRegionFamily.value),
      );
    }

    return result;
  });

  async function setBackgroundUpdateEnabled(value: boolean): Promise<void> {
    await setIsBackgroundIdsUpdateAvailable(backgroundFavoritesId, value);
  }

  function setFavoriteSelectedRegionFamily(regionFamily: Maybe<string>): void {
    favoriteSelectedRegionFamily.value = regionFamily;
  }

  function setFavoriteSelectedSportFamily(sportFamily: Maybe<string | SportFamily>): void {
    favoriteSelectedSportFamily.value = sportFamily;
  }

  function selectFirstAvailableFavoriteSportFamily(): void {
    setFavoriteSelectedSportFamily(favoritesAvailableSportFamilies.value[0] ?? null);
  }

  /**
   * @TODO check logic, maybe it must be placed in a Page composable to prevent calculations after leave page
   */
  function onInit(): void {
    watch(favoritesAvailableSportFamiliesHash, () => {
      const sportFamilies = favoritesAvailableSportFamilies.value;
      const selectedSportFilter = favoriteSelectedSportFamily.value;

      if (selectedSportFilter && !sportFamilies.includes(selectedSportFilter)) {
        setFavoriteSelectedSportFamily(null);
      }
    }, { immediate: true });

    watch(favoritesAvailableRegionFamiliesHash, () => {
      const regionFamilies = favoritesAvailableRegionFamilies.value;
      const selectedRegionFilter = favoriteSelectedRegionFamily.value;

      if (selectedRegionFilter && !regionFamilies.includes(selectedRegionFilter)) {
        setFavoriteSelectedRegionFamily(null);
      }
    }, { immediate: true });
  }

  return {
    favoriteSelectedRegionFamily,
    favoriteSelectedSportFamily,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    onInit,
    initialRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedSportFamily,
    setFavoriteSelectedRegionFamily,
    selectFirstAvailableFavoriteSportFamily,
  };
}
