<script lang="ts" setup>
import { VBadge } from '@components/badge';
import { VButton } from '@components/buttons';
import { ObjectFitOption, VImage } from '@components/image';
import { VIcon } from '@components/v-icon';

import type {
  LobbyEgsTopWinnerItemEmits,
  LobbyEgsTopWinnerItemProps,
} from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/types';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import { useLobbyEgsTopWinnerItem } from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/composables';

const props = defineProps<LobbyEgsTopWinnerItemProps>();
const emits = defineEmits<LobbyEgsTopWinnerItemEmits>();

const {
  imageProperties,
  iconProperties,
  playButtonProperties,
  ntBadgeProperties,
  moneyAmount,
  href,
} = useLobbyEgsTopWinnerItem(props);
</script>

<template>
  <component v-auto-id="'LobbyEgsTopWinnerItem'"
    :is="href ? 'a' : 'div'"
    :href="href"
    :class="{
      [$style['lobby-egs-top-winner-item']]: true,
      [$style['lobby-egs-top-winner-item--small']]: isSmall,
    }"
    @click.prevent="emits('click', $event)"
  >
    <div :class="$style['lobby-egs-top-winner-item__image-wrapper']">
      <template v-if="item">
        <VImage
          v-bind="imageProperties"
          :object-fit="ObjectFitOption.COVER"
          is-lazy
          show-loader
          :class="$style['lobby-egs-top-winner-item__image']"
          is-prerender-optimized
        />
        <div
          v-if="'1'"
          :class="$style['lobby-egs-top-winner-item__hover']"
        >
          <VButton
            v-bind="playButtonProperties"
            :class="$style['lobby-egs-top-winner-item__hover-btn']"
            @click.stop.prevent="emits('click', $event)"
          />
        </div>
        <VBadge
          v-if="item?.egsGame.ntBadge"
          v-bind="ntBadgeProperties"
          :class="$style['lobby-egs-top-winner-item__nt-badge']"
        />
      </template>
      <VSkeletonImage
        v-else
        v-data-test="{ el: 'lobby-egs-top-winner-skeleton-image' }"
        :class="$style['lobby-egs-top-winner-item__skeleton-image']"
      />
    </div>
    <div :class="$style['lobby-egs-top-winner-item__right']">
      <div
        :class="{
          [$style['lobby-egs-top-winner-item__name']]: true,
          [$style['lobby-egs-top-winner-item__name--skeleton']]: !item,
        }"
      >
        <template v-if="item">
          <span>{{ item.egsGame.name }}</span>
        </template>
      </div>
      <div
        v-if="!isSmall"
        :class="{
          [$style['lobby-egs-top-winner-item__group']]: true,
          [$style['lobby-egs-top-winner-item__group--skeleton']]: !item,
        }"
      >
        <template v-if="item">
          {{ item.egsGame.group.name }}
        </template>
      </div>
      <div
        :class="{
          [$style['lobby-egs-top-winner-item__amount']]: true,
          [$style['lobby-egs-top-winner-item__amount--skeleton']]: !item,
        }"
      >
        {{ moneyAmount }}
      </div>
      <div
        :class="{
          [$style['lobby-egs-top-winner-item__player']]: true,
          [$style['lobby-egs-top-winner-item__player--skeleton']]: !item,
        }"
      >
        <template v-if="item">
          <VIcon
            :class="$style['lobby-egs-top-winner-item__player-icon']"
            v-bind="iconProperties"
          />
          <span :class="$style['lobby-egs-top-winner-item__player-label']">{{ item.player.name }}</span>
        </template>
      </div>
    </div>
  </component>
</template>

<style lang="scss" module>
@import 'web/src/components/Skeleton/styles/skeleton';

@mixin cropLongLabel() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include for-layout using($isDesktop) {
  .lobby-egs-top-winner-item {
    $colorPrimary: var(--TextPrimary);
    $colorSecondary: var(--TextSecondary);
    $colorBrand: var(--BrandDefault);
    $colorDefault: var(--TextDefault);

    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 164px;
    overflow: hidden;
    text-decoration: none;
    background-color: var(--Layer1);
    border-radius: var(--BorderRadius);
    isolation: isolate;

    &__image-wrapper {
      position: relative;
      width: 110px;
      overflow: hidden;
    }

    &__image,
    &__skeleton-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: transform 0.5s ease;
      will-change: transform;
    }

    &--small {
      min-height: 136px;

      .lobby-egs-top-winner-item__image-wrapper {
        width: 91px;
      }
    }

    &:hover {
      .lobby-egs-top-winner-item__image {
        transform: scale(1.05);
      }

      .lobby-egs-top-winner-item__hover {
        opacity: 1;

        &:after {
          opacity: 1;
        }
      }
    }

    &__right {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      min-width: 0;
      padding: 0 16px 0 20px;
    }

    &__name {
      @include medium\16\20\025;
      @include cropLongLabel;

      color: $colorDefault;

      &--skeleton {
        @include skeletonFont(160px, 16px, 8px, $colorDefault);
      }
    }

    &__amount {
      @include medium\24\28;
      @include cropLongLabel;

      margin: 16px 0;
      color: $colorBrand;

      &--skeleton {
        @include skeletonFont(100px, 20px, 10px, $colorBrand);
      }
    }

    &__player {
      @include regular\13\16;

      display: flex;
      gap: 4px;
      align-items: center;
      color: $colorPrimary;

      &--skeleton {
        @include skeletonFont(40px, 16px, 7px, $colorPrimary);
      }

      &-icon {
        color: $colorSecondary;
      }

      &-label {
        @include cropLongLabel;

        width: calc(100% - 20px);
        color: $colorPrimary;
      }
    }

    &__hover-btn {
      @include z-index(base);
    }

    &__hover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    &__nt-badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &__group {
      @include regular\12\12;

      margin-top: 8px;
      color: var(--TextPrimary);

      &--skeleton {
        @include skeletonFont(80px, 12px, 7px, $colorSecondary);
      }
    }
  }
}
</style>
