import { DateTime } from '@leon-hub/utils';

import { DateInputRoundDateTo } from 'web/src/components/Input/enums';

export function applyLimitationsTo(
  timestamp: number,
  minDate?: number | undefined,
  maxDate?: number | undefined,
  roundTo?: string | DateInputRoundDateTo | undefined,
): number {
  let result = timestamp;

  if (minDate) {
    result = Math.max(result, minDate);
  }

  if (maxDate) {
    result = Math.min(result, maxDate);
  }

  if (roundTo === DateInputRoundDateTo.DayStart) {
    const rounded = DateTime.roundToDayStart(result);

    result = minDate ? Math.max(rounded, minDate) : rounded;
  }

  if (roundTo === DateInputRoundDateTo.DayEnd) {
    const rounded = DateTime.roundToDayEnd(result);

    result = maxDate ? Math.min(rounded, maxDate) : rounded;
  }

  return result;
}
