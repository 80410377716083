import type { BetSlipRequest, GetBetSlipReturnType } from '@leon-hub/api-sdk';
import { getBetSlip } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

export function requestGetBetSlip(input: BetSlipRequest, apiClient: ApplicationGqlClient): Promise<GetBetSlipReturnType> {
  return getBetSlip(apiClient, (node) => node.queries.betSlip.getBetSlip, {
    options: input,
  });
}
