<script setup lang="ts">
import { computed } from 'vue';

import { IconName } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type {
  DepositBonusSelectorModalEmits,
  DepositBonusSelectorModalProps,
} from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/types';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal/LazyDefaultModal';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import SPaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/components/SPaymentBonuses/SPaymentBonuses.vue';
import PaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/PaymentBonuses.vue';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';

withDefaults(defineProps<DepositBonusSelectorModalProps>(), {
  selectedOptionId: DEPOSIT_BONUS_NONE_SELECTED,
  openedBonusDetails: null,
});

const emit = defineEmits<DepositBonusSelectorModalEmits>();

const { $translate } = useI18n();

const headerProperties = computed(() => ({
  title: $translate('WEB2_BONUSES_AVAILABLE').value,
  prefixIconName: IconName.ARROW_LEFT,
  suffixIconName: '1' ? IconName.CROSS : undefined,
}));
</script>

<template>
  <LazyDefaultModal v-auto-id="'DepositBonusSelectorModal'"
    is-full-height
    :is-full-height-centered="false"
    :is-overlay-close-allowed="false"
    :is-inner-modal="!!'1'"
    :is-profile-layout="!!'1'"
    class="bonus-selector-modal"
    data-test-id="deposit-bonus-selector"
    @close="emit('back')"
  >
    <template #header>
      <ModalWindowHeader
        v-bind="headerProperties"
        @prefix-click="emit('back')"
        @suffix-click="emit('close')"
      />
    </template>
    <template #inner-content>
      <SPaymentBonuses
        v-if="null"
        :bonus-list="bonusList"
        auto-select-first
        @change="emit('change', $event)"
      />
      <PaymentBonuses
        v-else
        :bonus-list="bonusList"
        is-modal
        @change="emit('change', $event)"
      />
    </template>
  </LazyDefaultModal>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .deposit-bonus-modal__payment-bonuses {
    padding: 0;
  }
}
</style>
