import type {
  SeoMetaParameterConfig,
  SeoMetaParameters,
  SeoMetaParametersConfig,
  SeoMetaParameterValue,
} from 'web/src/modules/seo/types';
import isSeoMetaParametersConfig from 'web/src/modules/seo/guards/isSeoMetaParametersConfig';
import isEqualSeoMetaParameterValue from 'web/src/modules/seo/utils/isEqualSeoMetaParameterValue';

type DuplicatedValuesReaction = (
  key: string,
  oldValue?: SeoMetaParameterValue,
  newValue?: SeoMetaParameterValue,
) => void;

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function mergeSeoMetaParameters(
  metaParameters: SeoMetaParameters,
  dataFromComponent: SeoMetaParametersConfig,
  duplicatedValuesReaction: DuplicatedValuesReaction,
): SeoMetaParameters {
  const computedComponentMeta: SeoMetaParameters = {};

  for (const key of Object.keys(dataFromComponent)) {
    const config = dataFromComponent[key];
    let value: SeoMetaParameterValue;
    let unique = true;
    let mergeMethod: Optional<SeoMetaParameterConfig['merge']>;

    if (isSeoMetaParametersConfig(config)) {
      value = config.value;
      unique = config.unique ?? true;
      if (config.merge) {
        mergeMethod = config.merge;
      }
    } else {
      value = <SeoMetaParameterValue>config;
    }

    if (value === undefined) {
      continue;
    }

    if (
      unique
      && key in metaParameters
      && !isEqualSeoMetaParameterValue(metaParameters[key], value)
    ) {
      if (mergeMethod) {
        value = mergeMethod(metaParameters[key], value);
      } else {
        duplicatedValuesReaction(key, metaParameters[key], value);
      }
    }

    computedComponentMeta[key] = value;
  }

  return {
    ...metaParameters,
    ...computedComponentMeta,
  };
}
