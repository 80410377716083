import { getRewards, RequestHeader } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { RewardsGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default async function getRewardsData(referralCode: string): Promise<RewardsGetDataDocument> {
  const apiClient = useGraphqlClient();

  const response = await getRewards(
    apiClient,
    (node) => node.queries.refProgram.getRewards,
    {
      options: {
        referralCode,
      },
    },
    {
      headers: { [RequestHeader.X_REFTAG]: referralCode },
      silent: false,
    },
  );

  return response || {};
}
