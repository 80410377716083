import { defineStore } from 'pinia';
import { ref } from 'vue';

import type {
  SportlinePersistentLocation,
  SportlinePersistentLocationKey,
  SportlinePersistentLocationValue,
} from '../types';
import { useDoSavePersistLocation } from './composables';

export const useSportlinePersistFilterStore = defineStore('sportline-persist-filter', () => {
  const doSavePersistLocation = useDoSavePersistLocation();

  const persistLocation = ref<SportlinePersistentLocation>({});

  function setPersistLocation(
    key: SportlinePersistentLocationKey,
    value?: Maybe<SportlinePersistentLocationValue>,
    permissionKey?: SportlinePersistentLocationKey,
  ): void {
    const current = persistLocation.value;
    const permission = doSavePersistLocation[permissionKey ?? key];

    if (permission.value === null) { return; }

    if (!value || !permission.value) {
      delete current[key];
    } else {
      current[key] = value;
    }

    persistLocation.value = current;
  }

  function resetPersistLocation(key?: SportlinePersistentLocationKey): void {
    if (key) {
      const current = persistLocation.value;
      delete current[key];
      persistLocation.value = current;
      return;
    }

    persistLocation.value = {};
  }

  return {
    persistLocation,
    setPersistLocation,
    resetPersistLocation,
  };
});
