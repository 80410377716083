<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import type { ButtonAsReadonlyInputEmits, ButtonAsReadonlyInputProps } from '../../types';

const props = defineProps<ButtonAsReadonlyInputProps>();

const emit = defineEmits<ButtonAsReadonlyInputEmits>();

const { focusable, focus } = useAutofocus(props);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <button v-auto-id="'ButtonAsReadonlyInput'"
    ref="focusable"
    type="button"
    :class="{
      [$style['button-as-readonly-input']]: true,
      [$style['button-as-readonly-input--error']]: hasError,
      [$style['button-as-readonly-input--placeholder']]: !hasError && !value && placeholder,
      [$style['button-as-readonly-input--error-placeholder']]: hasError && !value && placeholder,
    }"
    :disabled="disabled"
    @mouseover="emit('mouseover', $event)"
    @mouseleave="emit('mouseleave', $event)"
    @focus="emit('focus', $event)"
    @blur="emit('blur', $event)"
    @click="emit('click')"
  >
    {{ value || placeholder }}
  </button>
</template>

<style lang="scss" module>
@import '../../common';

.button-as-readonly-input {
  @include inputCommon;

  text-align: left;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &--placeholder {
    color: $inputPlaceholderDefaultColor;

    &:focus {
      color: $inputPlaceholderFocusColor;
    }
  }

  &--error {
    color: $inputValueErrorColor;
  }

  &--error-placeholder {
    color: $inputPlaceholderErrorColor;

    &:focus {
      color: $inputPlaceholderFocusColor;
    }
  }
}
</style>
