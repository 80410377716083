import type { RefuseBonusRequest, RefuseBonusResponse } from '@leon-hub/api-sdk';
import { doRefuseBonus } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export default function doRefuseBonusApi(options: RefuseBonusRequest): Promise<RefuseBonusResponse> {
  const apiClient = useGraphqlClient();

  return doRefuseBonus(
    apiClient,
    (node) => node.mutations.bonuses.refuseBonus,
    { options },
  );
}
