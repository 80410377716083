import type { RouteLocationRaw } from 'vue-router';

import type { IconNameType } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

export enum MenuLevel {
  ONE = 1,
  TWO,
  FREE,
}

export interface MenuLevelClickPayload {
  level: MenuLevel;
  id?: string;
}

export interface SidebarMenuL3 {
  active: boolean;
  id: string;
  key: string;
  location: RouteLocationRaw;
  externalUrl?: string;
  name: string;
  suffix?: Optional<VIconProps>;
}

export interface SidebarMenuL2 {
  id?: string;
  active: boolean;
  key: string;
  location: RouteLocationRaw | null;
  externalUrl?: string;
  menu: SidebarMenuL3[];
  name: string;
  suffix?: Optional<VIconProps>;
}

export interface SidebarMenuL1 {
  active: boolean;
  badge?: string;
  canOpen: boolean;
  id: string;
  icon?: { name?: IconNameType };
  key: string;
  location: RouteLocationRaw | null;
  externalUrl?: string;
  menu: SidebarMenuL2[];
  name: string;
  props?: {
    iconStyle?: string;
    isRewardCenter?: boolean;
    badge?: string;
  } & Record<string, number | string | boolean>;
  suffix?: Optional<VIconProps>;
  progress?: number;
  suffixText?: string;
}

export type SidebarMenu = SidebarMenuL1[];
