import type { BonusCampaignType } from '@leon-hub/api-sdk';
import type { CustomerBonusesQueryResult } from '@leon-hub/api-types';
import { getCustomerBonuses } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export default function fetchCustomerBonuses(bonusCampaignType?: Optional<BonusCampaignType>): Promise<CustomerBonusesQueryResult> {
  const apiClient = useGraphqlClient();

  return getCustomerBonuses(
    apiClient,
    (node) => node.queries.bonuses.getCustomerBonuses,
    {
      options: { bonusCampaignType },
    },
  );
}
