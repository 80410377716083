import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import type { MaskOptions } from '@leon-hub/input-types';
import { toPattern } from '@leon-hub/vanilla-masker';

import type { ChangeInputValueResult } from './types';
import {
  formatValueToPattern,
  getOnlyDigitsFromString,
  handleMaskedInput,
  handleMaskedKeydown,
} from './utils';

interface UseMaskedInput {
  formattedValue: ComputedRef<string>;
  maskedValue: ComputedRef<string>;
  isMaskedPlaceholderHidden: ComputedRef<boolean>;
  getValueCutToPattern(): string;
  getMaskedInputResult(event: InputEvent): ChangeInputValueResult;
  getMaskedKeydownResult(event: KeyboardEvent): ChangeInputValueResult;
}

interface Props {
  maskOptions: Ref<MaskOptions | undefined>;
  inputValue: Ref<string>;
  isFocus: Ref<boolean>;
}
export default function useMaskedInput({
  maskOptions,
  inputValue,
  isFocus,
}: Props): UseMaskedInput {
  const formattedValue = computed<string>(() => (
    maskOptions.value ? formatValueToPattern(inputValue.value, maskOptions.value) : inputValue.value));

  const isMaskedPlaceholderHidden = computed<boolean>(
    () => !isFocus.value && !inputValue.value,
  );

  const maskedValue = computed<string>(() => {
    if (!maskOptions.value) {
      return inputValue.value;
    }
    const placeholder = maskOptions.value?.placeholder;
    if (placeholder && placeholder.length > 1) {
      return formattedValue.value + placeholder.slice(formattedValue.value.length);
    }
    // if placeholder is one symbol for filling empty data: phone
    return toPattern(inputValue.value || '', maskOptions.value);
  });

  const getValueCutToPattern = (): string => {
    if (!maskOptions.value) {
      return inputValue.value;
    }
    const { pattern } = maskOptions.value;
    const patternLength = getOnlyDigitsFromString(pattern).length;
    return inputValue.value.slice(0, patternLength);
  };

  const getMaskedInputResult = (event: InputEvent): ChangeInputValueResult => {
    if (!maskOptions.value) {
      return null;
    }
    return handleMaskedInput({
      event,
      maskOptions: maskOptions.value,
      currentValue: formattedValue.value,
    });
  };

  const getMaskedKeydownResult = (event: KeyboardEvent): ChangeInputValueResult => {
    if (!maskOptions.value) {
      return null;
    }
    return handleMaskedKeydown({
      event,
      currentValue: formattedValue.value,
      maskOptions: maskOptions.value,
    });
  };

  return {
    maskedValue,
    formattedValue,
    isMaskedPlaceholderHidden,
    getValueCutToPattern,
    getMaskedInputResult,
    getMaskedKeydownResult,
  };
}
