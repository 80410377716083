<script lang="ts" setup>
import { ButtonKind, VButton } from '@components/buttons';

import type { SearchTagsEmits, SearchTagsProps } from 'web/src/modules/search/components/SearchTags/types';
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';

defineProps<SearchTagsProps>();

const emits = defineEmits<SearchTagsEmits>();

const buttonProperties = {
  kind: ButtonKind.BASE,
  rounded: true,
  isUppercase: false,
};

function emitClick(value: string): void {
  emits('click', value);
}
</script>

<template>
  <div v-auto-id="'SearchTags'" :class="$style['search-tags']">
    <SearchSubHeader
      :text="$t('WEB2_SEARCH_TAGS_TITLE')"
    />
    <div :class="$style['search-tags__buttons']">
      <VButton
        v-for="tag in tags"
        v-bind="buttonProperties"
        :key="tag"
        :label="tag"
        @click="emitClick(tag)"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.search-tags {
  &__title {
    @include medium\13\16;

    margin: 16px 8px 12px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}
</style>
