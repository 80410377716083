import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import type { PhoneInput } from '@leon-hub/api-sdk';
import {
  checkRestorePasswordSms,
  checkRestorePasswordUid,
  doRestorePasswordByEmail,
  doRestorePasswordByPhone,
  LoginType,
  PhoneCodeMethod,
  sendRestorePasswordLinkToEmail,
  sendRestorePasswordSmsOrCallToPhone,
  sendRestorePasswordSmsToPhone,
} from '@leon-hub/api-sdk';
import { AppError } from '@leon-hub/app-errors';
import { assert } from '@leon-hub/guards';

import { useGraphqlClient } from '@core/app-rest-client';
import { useSiteConfigStore } from '@core/site-config';

import { CaptchaRequesterStrategy } from '@modules/captcha-utilities';

import type { FormPhoneValue } from 'web/src/components/Form/types';
import type { CaptchaSettingsForStrategy } from 'web/src/modules/captcha/store/types';
import formPhoneValueToApiPhoneInput from 'web/src/components/Input/utils/formPhoneValueToApiPhoneInput';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { isCaptchaSettingsForStrategy } from 'web/src/modules/captcha/store/constants';
import { useFormsStore } from 'web/src/modules/forms/store';

import type {
  ChangePasswordByEmailOptions,
  ChangePasswordByPhoneOptions,
  EmailFormOutputType,
  PasswordFormOutputType,
  PhoneFormOutputType,
  RequestRestorePasswordLinkToEmailOptions,
  ResendEmailOutput,
  RestorePasswordCheckCodeOptions,
  RestorePasswordSendSmsCodeOptions,
  RestorePasswordSendUnknownMethodCodeOptions,
  RestorePasswordType,
  RestorePasswordVerifyUidOptions,
  SmsFormOutputType,
} from './types';

const useRestorePasswordStore = defineStore('restore-password', () => {
  const apiClient = useGraphqlClient();
  const formsStore = useFormsStore();
  const captchaStore = useCaptchaStore();
  const siteConfigStore = useSiteConfigStore();

  const captchaSettingsForStrategy = toRef(captchaStore, 'captchaSettingsForStrategy');
  const smsResendCodeTimer = toRef(siteConfigStore, 'smsResendCodeTimer');
  const isPhoneTabEnabledOnPasswordRestorePage = toRef(siteConfigStore, 'isPhoneTabEnabledOnPasswordRestorePage');
  const isEmailTabEnabledOnPasswordRestorePage = toRef(siteConfigStore, 'isEmailTabEnabledOnPasswordRestorePage');

  const activeTab = ref<RestorePasswordType>(isPhoneTabEnabledOnPasswordRestorePage.value ? LoginType.PHONE : LoginType.EMAIL);
  const retrySmsCodeTimestamp = ref<number>(0);
  /** User request restore link to this email */
  const currentEmail = ref<Maybe<string>>(null);
  /** User request restore link to this phone */
  const currentPhone = ref<Maybe<PhoneInput>>(null);
  /** We got this uid from restore link */
  const willVerifiedUid = ref<Maybe<string>>(null);
  /** We got this email from restore link */
  const willVerifiedEmail = ref<Maybe<string>>(null);
  /** Set after we check uid and email are actual. false - an error occurred */
  const verifiedUid = ref<Maybe<string> | false>(null);
  /** Set after we check uid and email are actual. false - an error occurred */
  const verifiedEmail = ref<Maybe<string> | false>(null);
  /** Set after we check phone is actual */
  const verifiedPhone = ref<Maybe<PhoneInput>>(null);
  const phoneCheckType = ref<Maybe<PhoneCodeMethod>>(null);
  const fetchInitErrorMessage = ref<Maybe<string>>(null);

  const captchaSettings = computed<CaptchaSettingsForStrategy>(() => {
    const settings = captchaSettingsForStrategy.value(
      CaptchaRequesterStrategy.RESTORE_PASSWORD,
    );
    assert(isCaptchaSettingsForStrategy(settings.value), 'captchaSettings must be present');
    return settings.value;
  });
  const captchaEnabled = computed<boolean>(() => Boolean(captchaSettings.value?.isEnabledToShow));
  const retrySmsCodeTime = computed<number | null>(() => {
    const currentTime = Date.now();

    if (currentTime < retrySmsCodeTimestamp.value) {
      const retrySmsCodeTimeInSeconds = (retrySmsCodeTimestamp.value - currentTime) / 1000;
      return Math.floor(retrySmsCodeTimeInSeconds);
    }

    return null;
  });
  const restorePasswordLastStepType = computed<Maybe<RestorePasswordType>>(() => {
    // we get email and uid from url,
    // so we have to validate them before, but it is already the "Restore By Email" last step
    if (willVerifiedEmail.value?.length && willVerifiedUid.value?.length) {
      return LoginType.EMAIL;
    }

    // But we use the verified phone value to find the step,
    // because we check it in the second step (check sms form)
    if (verifiedPhone.value) {
      return LoginType.PHONE;
    }

    return null;
  });
  const isNewTel = computed<boolean>(() => phoneCheckType.value === PhoneCodeMethod.CALL_PASSWORD_CODE);
  const isPhoneTabEnabled = computed<boolean>(() => isPhoneTabEnabledOnPasswordRestorePage.value);
  const isEmailTabEnabled = computed<boolean>(() => isEmailTabEnabledOnPasswordRestorePage.value);
  const isMainFormReady = computed<boolean>(() => Boolean(formsStore.restoreBySendSms && formsStore.restoreBySendLinkToEmail));

  const setActiveTab = (value: RestorePasswordType): void => {
    activeTab.value = value;
  };

  const setRetrySmsCodeTime = (seconds = 0): void => {
    retrySmsCodeTimestamp.value = Date.now() + seconds * 1000;
  };

  const setEmail = (value: Maybe<string>): void => {
    currentEmail.value = value;
  };

  const setPhone = (value: Maybe<PhoneInput>): void => {
    currentPhone.value = value;
  };

  const setWillVerifiedUid = (value: Maybe<string>): void => {
    willVerifiedUid.value = value;
  };

  const setWillVerifiedEmail = (value: Maybe<string>): void => {
    willVerifiedEmail.value = value;
  };

  const setVerifiedEmail = (value: Maybe<string> | false): void => {
    verifiedEmail.value = value;
  };

  const setVerifiedPhone = (value: Maybe<FormPhoneValue>): void => {
    verifiedPhone.value = value ? formPhoneValueToApiPhoneInput(value) : null;
  };

  const setVerifiedUid = (value: Maybe<string> | false): void => {
    verifiedUid.value = value;
  };

  const setPhoneCodeMethod = (value: PhoneCodeMethod): void => {
    phoneCheckType.value = value;
  };

  const setFetchInitErrorMessage = (value: string | null): void => {
    fetchInitErrorMessage.value = value;
  };

  /**
   * Clear user form data (Main route page)
   */
  const clearCurrentRestoreData = (): void => {
    currentEmail.value = null;
    currentPhone.value = null;
    phoneCheckType.value = null;
  };

  /**
   * Clear data after complete flow (last step)
   */
  const clearVerifiedData = (): void => {
    willVerifiedUid.value = null;
    willVerifiedEmail.value = null;
    verifiedUid.value = null;
    verifiedPhone.value = null;
    verifiedEmail.value = null;
  };

  const sendRestorePasswordLinkToEmailStore = async ({ formData }: EmailFormOutputType): Promise<void> => {
    const options: RequestRestorePasswordLinkToEmailOptions = {
      email: formData.email,
      captchaAnswer: formData.captchaAnswer,
    };

    await sendRestorePasswordLinkToEmail(
      apiClient,
      (node) => node.mutations.pwdRecoveryEmail.sendUid,
      { options },
    );
    setEmail(options.email);
  };

  /**
   * Resend email with restore link to last email address
   */
  const resendRestorePasswordLinkToEmail = async ({ formData }: ResendEmailOutput): Promise<void> => {
    const email = currentEmail.value;

    if (!email) {
      throw new AppError({ originalError: new Error('Couldn\'t find email for resend restore link') });
    }

    const options: RequestRestorePasswordLinkToEmailOptions = {
      email,
      captchaAnswer: formData.captchaAnswer,
    };

    await sendRestorePasswordLinkToEmail(apiClient, (node) => node.mutations.pwdRecoveryEmail.sendUid, { options });
  };

  /**
   * Try to change password for uid
   */
  const doRestorePasswordByEmailStore = async ({ formData }: PasswordFormOutputType): Promise<void> => {
    const email = verifiedEmail.value;
    const uid = verifiedUid.value;
    const { pwd } = formData;

    if (!email) {
      throw new AppError({ originalError: new Error('Couldn\'t find email for change password') });
    }

    if (!uid) {
      throw new AppError({ originalError: new Error('Couldn\'t find uid for change password') });
    }

    const options: ChangePasswordByEmailOptions = { pwd, email, uid };
    await doRestorePasswordByEmail(
      apiClient,
      (node) => node.mutations.pwdRecoveryEmail.changePwdByEmail,
      { options },
    );
  };

  /**
   * Change password by last entered phone number
   */
  const doRestorePasswordByPhoneStore = async ({ formData }: PasswordFormOutputType): Promise<void> => {
    const phone = verifiedPhone.value;
    const { pwd, captchaAnswer } = formData;

    if (!phone) {
      throw new AppError({ originalError: new Error('Couldn\'t find phone for change password') });
    }

    const options: ChangePasswordByPhoneOptions = { phone, pwd, captchaAnswer };
    await doRestorePasswordByPhone(
      apiClient,
      (node) => node.mutations.pwdRecoveryPhone.changePwdByPhone,
      { options },
    );
  // @TODO check PHONE_NOT_CHECKED error
  // @TODO move response validation from View to Actions
  };

  /**
   * Check is current uid is still valid or customer must request new email
   */
  const verifyRestorePasswordUid = async (): Promise<void> => {
    const email = willVerifiedEmail.value;
    const uid = willVerifiedUid.value;

    if (!email) {
      throw new AppError({ originalError: new Error('Couldn\'t find email for verify uid') });
    }

    if (!uid) {
      throw new AppError({ originalError: new Error('Couldn\'t find uid for verify uid') });
    }

    const options: RestorePasswordVerifyUidOptions = { email, uid };
    await checkRestorePasswordUid(apiClient, (node) => node.mutations.pwdRecoveryEmail.isValidUid, { options })
      .then(() => {
        setVerifiedUid(options.uid);
        setVerifiedEmail(options.email);
        setFetchInitErrorMessage(null);
      })
      .catch(({ message }) => {
        setVerifiedUid(false);
        setVerifiedEmail(false);
        setFetchInitErrorMessage(message);
      });
  };

  const sendRestorePasswordSmsOrCallToPhoneStore = async (
    options: RestorePasswordSendUnknownMethodCodeOptions,
  ): Promise<void> => {
    const response = await sendRestorePasswordSmsOrCallToPhone(apiClient, (node) => node.mutations.pwdRecoveryPhone.sendPasswordResetVerificationCode, { options });
    const { formStep } = response;
    setPhone(options.phone);
    setPhoneCodeMethod(formStep as PhoneCodeMethod);
    setRetrySmsCodeTime(smsResendCodeTimer.value);
  };

  const sendRestorePasswordSmsToPhoneStore = async (options: RestorePasswordSendSmsCodeOptions): Promise<void> => {
    await sendRestorePasswordSmsToPhone(apiClient, (node) => node.mutations.pwdRecoveryPhone.sendSms, { options });
    setPhoneCodeMethod(PhoneCodeMethod.SMS);
    setRetrySmsCodeTime(smsResendCodeTimer.value);
  };

  /**
   * Try to check phone number
   */
  const checkRestorePasswordSmsStore = async ({ formData }: SmsFormOutputType): Promise<void> => {
    const phone = currentPhone.value;

    if (!phone) {
      throw new AppError({ originalError: new Error('Couldn\'t find phone for check sms') });
    }

    const { sms, captchaAnswer } = formData;
    const options: RestorePasswordCheckCodeOptions = { phone, sms, captchaAnswer };
    await checkRestorePasswordSms(apiClient, (node) => node.mutations.pwdRecoveryPhone.checkSms, { options });
    setVerifiedPhone(options.phone);
  };

  /**
   * The first one request when we haven't any info about check type
   */
  const requestRestorePasswordSms = async ({ formData }: PhoneFormOutputType): Promise<void> => {
    await sendRestorePasswordSmsOrCallToPhoneStore({
      phone: formPhoneValueToApiPhoneInput(formData.phone),
      captchaAnswer: formData.captchaAnswer,
    });
  };

  /**
   * Retry send sms for last entered phone number
   */
  const resendRestorePasswordSms = async ({ method }: { method?: PhoneCodeMethod }): Promise<void> => {
    if (!currentPhone.value) {
      throw new AppError({ originalError: new Error('Couldn\'t find phone for resend code') });
    }

    const phone = formPhoneValueToApiPhoneInput(currentPhone.value);

    const useMethod = method ?? phoneCheckType.value;

    if (useMethod === PhoneCodeMethod.SMS) {
      await sendRestorePasswordSmsToPhoneStore({ phone, captchaAnswer: null });
    } else {
      await sendRestorePasswordSmsOrCallToPhoneStore({ phone, captchaAnswer: null });
    }
  };

  return {
    activeTab,
    captchaSettings,
    retrySmsCodeTime,
    restorePasswordLastStepType,
    phoneCheckType,
    isNewTel,
    smsResendCodeTimer,
    currentEmail,
    currentPhone,
    verifiedEmail,
    verifiedPhone,
    verifiedUid,
    willVerifiedUid,
    retrySmsCodeTimestamp,
    willVerifiedEmail,
    captchaEnabled,
    isMainFormReady,
    fetchInitErrorMessage,
    isPhoneTabEnabled,
    isEmailTabEnabled,
    setActiveTab,
    setEmail,
    setPhone,
    setPhoneCodeMethod,
    setRetrySmsCodeTime,
    setWillVerifiedEmail,
    setWillVerifiedUid,
    setVerifiedEmail,
    setVerifiedPhone,
    setVerifiedUid,
    clearVerifiedData,
    clearCurrentRestoreData,
    setFetchInitErrorMessage,
    doRestorePasswordByPhoneStore,
    doRestorePasswordByEmailStore,
    resendRestorePasswordLinkToEmail,
    sendRestorePasswordLinkToEmailStore,
    verifyRestorePasswordUid,
    requestRestorePasswordSms,
    resendRestorePasswordSms,
    checkRestorePasswordSmsStore,
  };
});

export default useRestorePasswordStore;
