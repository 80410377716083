<script lang="ts" setup>
import { toRef, useTemplateRef } from 'vue';

import { VSwitch } from '@components/switch';

import type {
  MarketsGridFilter,
  MarketsGridFilterId,
} from 'web/src/modules/sportline/submodules/markets-grid/types';
import type {
  MarketListToolbarReference,
  MarketsListTabsReference,
} from 'web/src/modules/sportline/views/markets-list/types';
import MarketsTabs from 'web/src/modules/sportline/views/markets-list/components/MarketsTabs.vue';

import type { MarketsListToolbarEmits } from './composables/useMarketsListToolbar';
import { useMarketsListToolbar } from './composables/useMarketsListToolbar';

interface MarketsListToolbarProps {
  hasStatisticWidget?: boolean;
  hasStatistic?: boolean;
  doShowInlineStatisticSwitch?: boolean;
  doShowAllMarketsTab?: boolean;
  isStatisticWidgetSelected?: boolean;
  isStatisticSelected?: boolean;
  gridFilters: MarketsGridFilter[];
  activeMarketGroupId: MarketsGridFilterId;
}

const props = defineProps<MarketsListToolbarProps>();
const emit = defineEmits<MarketsListToolbarEmits>();

const tabsRef = useTemplateRef<MarketsListTabsReference>('tabs');

const isStatisticSelected = toRef(() => props.isStatisticSelected);

const {
  slideToActiveTab,
  onMarketTabClick,
  onChangeStatisticSelected,
  onChangeStatisticWidgetSelected,
} = useMarketsListToolbar({
  isStatisticSelected,
  tabsRef,
}, emit);

defineExpose<MarketListToolbarReference>({
  slideToActiveTab,
});
</script>

<template>
  <div v-auto-id="'MarketsListToolbar'" :class="$style['sport-event-details__controls']">
    <MarketsTabs
      ref="tabs"
      :grid-filters="gridFilters"
      :active-tab-id="activeMarketGroupId"
      :has-statistic-widget="hasStatisticWidget && !doShowInlineStatisticSwitch"
      :has-statistic="hasStatistic && !doShowInlineStatisticSwitch"
      :has-all-tab="doShowAllMarketsTab"
      @tab-click="onMarketTabClick"
    />
    <div
      v-if="hasStatisticWidget && doShowInlineStatisticSwitch && '1'"
      :class="$style['sport-event-details__switch-block']"
    >
      <VSwitch
        :checked="isStatisticWidgetSelected"
        :label="$t('WEB2_MATCH_STATISTIC')"
        :class="$style['sport-event-details__switch']"
        @change="onChangeStatisticWidgetSelected"
      />
    </div>
    <div
      v-else-if="hasStatistic && doShowInlineStatisticSwitch && '1'"
      :class="$style['sport-event-details__switch-block']"
    >
      <VSwitch
        :checked="isStatisticSelected"
        :label="$t('WEB2_MATCH_STATISTIC')"
        :class="$style['sport-event-details__switch']"
        @change="onChangeStatisticSelected"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-event-details {
    &__controls {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
    }

    &__switch-block {
      background-color: var(--OpacityLayer1);
      border-radius: 5px;
    }

    &__switch {
      padding: 10px 8px 10px 12px;
    }
  }
}
</style>
