<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';

import type { BaseModalEmits } from '@core/dialogs';

import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';

import type {
  ConfirmModalProps,
  ConfirmModalSlots,
} from './types';

const props = withDefaults(defineProps<ConfirmModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

defineSlots<ConfirmModalSlots>();

function onButtonClick(index: number): void {
  const button = props.modal.buttons?.[index];
  if (button?.action) {
    emit('button-click', {
      action: button.action,
    });
  }
}
</script>

<template>
  <LazyDefaultModal v-auto-id="'ConfirmModal'"
    :image="modal.image"
    :alert-icon-size="modal.alertIconSize"
    :icon="modal.iconName"
    :icon-size="modal.iconSize ?? (modal.fullHeight ? IconSize.SIZE_80 : IconSize.SIZE_60)"
    :header="modal.title"
    :content="modal.confirmMessage"
    :top-bar-text="modal.topBarText"
    :height="modal.height"
    :width="modal.width"
    :buttons="modal.buttons"
    :is-alert="isAlert"
    :is-scroll-bar-enabled="isScrollBarEnabled"
    :is-full-height="modal.fullHeight"
    :is-close-allowed="modal.isCloseAllowed"
    :is-full-height-centered="modal.isFullHeightCentered"
    :icon-kind="modal.iconKind"
    :is-inner-modal="modal.isInnerModal"
    :is-top-bar-hidden="isAlert && !modal.topBarText"
    :is-centered="modal.isCentered"
    :is-overlay-close-allowed="modal.isOverlayCloseAllowed"
    :is-profile-layout="modal.isProfileLayout"
    :custom-background="modal.customBackground"
    :is-small-width-content="modal.isSmallWidthContent"
    :data-test-id="modal.dataTestId"
    :overlay-type="modal.overlayType"
    :header-type="modal.headerType"
    @close="emit('close')"
    @button-click="onButtonClick"
  >
    <template
      v-if="$slots.default"
      #content
    >
      <slot />
    </template>
    <template
      v-if="$slots.icon"
      #icon
    >
      <slot name="icon" />
    </template>
    <template
      v-if="$slots.footer"
      #footer
    >
      <slot name="footer" />
    </template>
  </LazyDefaultModal>
</template>
