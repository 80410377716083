export enum RewardType {
  MY_BONUS = 'my-bonus',
  PROMO = 'promo',
  BONUS_CODE = 'bonus-code',
  CASHBACK = 'cashback',
  REFERRAL = 'referral',
  LOYALTY_POINT = 'loyalty-point',
  CASINO_LEVEL = 'casino-level',
  ACHIEVEMENTS = 'achievements',
  WHEEL_FORTUNE = 'wheel-fortune',
}

export enum CashbackStatusType {
  NOT_ACTIVE = 'not-active',
  COLLECT = 'collect',
  WAGERING = 'wagering',
}
