import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';
import { AlertIconName, getAlertIcon, IconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics, useGoogleAnalytics } from '@core/analytics';
import { DialogAction } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import type { SButtonProps, VButtonProps } from '@components/buttons';
import type { DefaultModalProps } from '@components/dialogs';

import type { ModalWindowHeaderProps } from 'web/src/components/Modal/ModalWindowHeader/types';
import type { ExtendedSocialSharingNetworkItem } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';

import type { VSocialSharingEmits, VSocialSharingProps } from '../types';

export interface VSocialSharingComposable {
  modalProps: Ref<DefaultModalProps>;
  headerProps: ModalWindowHeaderProps;
  buttonProps: ComputedRef<VButtonProps | SButtonProps>;
  handleCopy(): void;
  close(isCross?: boolean): void;
  sendAnalytic(value: ExtendedSocialSharingNetworkItem): void;
}

export default function useVSocialSharing(
  props: VSocialSharingProps,
  emit: VSocialSharingEmits,
): VSocialSharingComposable {
  const { $translate } = useI18n();
  const { copy } = useCopyToClipboard();
  const analytics = useAnalytics();
  const { pushGTM } = useGoogleAnalytics();

  const headerProps: ModalWindowHeaderProps = {
    title: '',
    prefixIconName: undefined,
    suffixIconName: IconName.CROSS,
    type: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'default' : 'tertiary',
  };

  const shareSocialImage = computed<Web2Image | undefined>(() => {
    let src: string | undefined;
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      src = getAlertIcon(AlertIconName.Share);
    }
    return src ? { src } : undefined;
  });

  const modalProps: Ref<DefaultModalProps> = computed(
    () => ({
      ...props.modalProperties,
      isProfileLayout: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      isInnerModal: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      isOverlayCloseAllowed: true,
      image: shareSocialImage.value,
    }),
  );

  const buttonProps = computed(() => ({
    action: DialogAction.MODAL_CLOSE,
    label: $translate('JSP_PCL_FBOT_CLOSE').value,
    fullWidth: true,
  }));
  const handleCopy = (): void => {
    void copy({
      data: props.url,
      notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value,
      isProfileModalTooltip: !!process.env.VUE_APP_LAYOUT_DESKTOP,
    });

    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          shareLinkForm: 'copyLink',
        },
      },
    });
  };

  function sendAnalytic(value: ExtendedSocialSharingNetworkItem): void {
    if (value.isFromAchievements) {
      pushGTM(AnalyticsEvent.SHARE, {
        event: AnalyticsEvent.SHARE,
        click_text: value.name,
        event_action: props.eventAction,
        event_label: `Social sharing modal`,
      });
    } else {
      const iconName = value.name;
      analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
        referFriendProgram: {
          mainPage: {
            shareLinkForm: {
              icons: iconName,
            },
          },
        },
      });
    }
  }

  function close(isCross?: boolean): void {
    emit('close');
    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          shareLinkForm: isCross ? 'cross' : 'close',
        },
      },
    });
  }

  return {
    modalProps,
    buttonProps,
    headerProps,
    handleCopy,
    sendAnalytic,
    close,
  };
}
