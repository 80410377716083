import { FormControlType } from '@leon-hub/form-utils';

import type { FormUiSchema, FormWidgetType } from '../../../../types';
import getWidget from '../../../../utils/getWidget';

interface Output {
  widgetTypeList: FormWidgetType[];
  captchaWidget: FormWidgetType | null;
}

export default function getWidgetTypeList(
  uiSchema: FormUiSchema,
): Output {
  // all properties should be defined in ui.order
  const propertyNames = uiSchema.order ?? Object.keys(uiSchema.fields ?? {});
  const widgetTypeList: FormWidgetType[] = [];
  let captchaWidget: FormWidgetType | null = null;
  for (const name of propertyNames) {
    const widget = getWidget(uiSchema, name);
    if (widget === FormControlType.Captcha) {
      captchaWidget = {
        type: FormControlType.Captcha,
        field: name,
      };
    } else {
      widgetTypeList.push({
        type: widget,
        field: name,
      });
    }
  }

  return {
    widgetTypeList,
    captchaWidget,
  };
}
