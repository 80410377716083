import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';

import { useRouterStore } from '@core/router';

import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';

import { getLobbyTypeByRouteName } from './utils';

export interface LobbyTypeComposable {
  lobbyType: Ref<ILobbyType>;
}

export default function useLobbyType(): LobbyTypeComposable {
  const routerStore = useRouterStore();
  const currentRouteName = toRef(() => routerStore.currentRouteName);
  const nextRouteName = toRef(() => routerStore.nextRouteName);
  const { homePageType } = useHomePageType();

  const lobbyType = computed(() => getLobbyTypeByRouteName({
    routeName: nextRouteName.value || currentRouteName.value,
    homePageType: homePageType.value,
  }));

  return {
    lobbyType,
  };
}
