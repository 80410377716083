export { default as VerifyFieldNameEnum } from './VerifyFieldNameEnum';
// other labels available here:
// https://gitlab.leoncorp.net/leoncorp/leoncommon/blob/release/src/com/leoncommon/models/DocumentReason.java
export enum DocumentReason {
  VERIFICATION = '1',
  PURSE_CHANGE = '2',
  EMAIL_CHANGE = '3',
  PHONE_NUMBER_CHANGE = '4',
  OTHER = '7',
  IDENTIFICATION = '9',
  INN_SET = '10',
}

export enum VerificationPageType {
  DEFAULT = 1,
  INTERNAL,
  EXTERNAL,
  VIDEO,
  VIP,
}

export enum VerificationRouteType {
  VIP = 'vip',
}
