<script setup lang="ts">
import type { MultilineTextInputProperties } from 'web/src/components/Input/types';
import { AutoheightTextInput } from 'web/src/components/Input';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';

const props = defineProps<FormWrapperWidgetProperties<MultilineTextInputProperties>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useCommonFormWidgetProps(props);
</script>

<template>
  <AutoheightTextInput v-auto-id="'FormAutoheightTextInput'"
    :max-length="props?.extraProperties?.maxLength"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
