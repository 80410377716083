import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { MatchStreamAccess } from 'web/src/modules/sportline/submodules/streams/types';
import { useCustomerStreamEventsStore } from 'web/src/modules/sportline/submodules/streams/store';

export function useMatchStreamAccess(): Ref<MatchStreamAccess[]> {
  const customerStreamEventsStore = useCustomerStreamEventsStore();
  return toRef(() => customerStreamEventsStore.matchStreamAccess);
}
