import {
  isArray,
  isBoolean,
  isNumber,
  isObject,
  isString,
} from '@leon-hub/guards';
import {
  isFile,
} from '@leon-hub/utils';

import type { FormDataValue } from '../types';

function isFormDataValue (value: unknown): value is FormDataValue {
  return isString(value)
  || isObject(value)
  || isFile(value)
  || isArray(value)
  || isBoolean(value)
  || isNumber(value)
}

export default isFormDataValue;
