<script setup lang="ts">
import { SlottIconName } from '@leon-hub/icons';

import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';

import type { RequestedAmountEmits } from 'web/src/modules/profile/components/RequestedAmount/types';
import VInformer from 'web/src/components/Informer/VInformer/VInformer.vue';
import useRequestedAmount from 'web/src/modules/profile/components/RequestedAmount/composables/useRequestedAmount';
import WithdrawalWidget from 'web/src/modules/profile/components/WithdrawalWidget/WithdrawalWidget.vue';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<RequestedAmountEmits>();

const {
  currency,
  requestedValue,
  $formatMoney,
  onClickRequest,
} = useRequestedAmount(emit);
</script>

<template>
  <template v-auto-id="'RequestedAmount'" v-if="requestedValue > 0">
    <WithdrawalWidget
      v-if="null"
      v-bind="$attrs"
      :title="$t('JSPACC_ACC_REQUESTED')"
      :icon-name="SlottIconName.SLOTT_WALLET_MINUS"
      :label="$formatMoney(requestedValue, { currency })"
      :button-label="$t('WEB2_CUSTOMER_HISTORY_CANCEL')"
      :class="$style['withdrawal-request']"
      is-button
      is-clickable
      @button-click.stop="onClickRequest"
      @container-click="onClickRequest"
    />
    <div
      v-else
      v-data-test="{ el: 'withdrawal-request' }"
      v-bind="$attrs"
      :class="$style['withdrawal-request']"
      @click="onClickRequest"
    >
      <VInformer
        :label="$t('JSPACC_ACC_REQUESTED')"
        :value="requestedValue"
        :currency="currency"
      />
      <VButton
        :label="$t('WEB2_CUSTOMER_HISTORY_CANCEL')"
        :kind="ButtonKind.CANCEL"
        :height="ButtonHeight.SMALL"
        @click.stop="onClickRequest"
      />
    </div>
  </template>
</template>

<style lang="scss" module>
.withdrawal-request {
  @include withdrawalRequest;
}
</style>
