export enum RestorePasswordFieldName {
  PHONE = 'phone',
  EMAIL = 'email',
  CAPTCHA_TOKEN = 'captchaAnswer',
  CAPTCHA_ANSWER_V3 = 'captchaAnswerV3',
}

export enum RestorePasswordStep {
  UNKNOWN = 'UNKNOWN',
  SELECT_TYPE = 'SELECT_TYPE',
  CONFIRM_PHONE = 'CONFIRM_PHONE',
  EMAIL_INFO = 'EMAIL_INFO',
  INVALID_LINK = 'INVALID_LINK',
  ENTER_NEW_PASSWORD = 'ENTER_NEW_PASSWORD',
}
