<script lang="ts" setup>
import { computed } from 'vue';

import type { SwiperNavigationButtonsProps } from '../types';
import { useSwiperNavigation } from '../composables';
import SwiperNavigationButtonsBase from '../SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';
import getNavigationProps from './utils/getNavigationProps';

const props = defineProps<SwiperNavigationButtonsProps>();
const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
const navigationProperties = computed(() => getNavigationProps(props, swiperState));
</script>

<template>
  <SwiperNavigationButtonsBase v-auto-id="'SwiperNavigationButtons'"
    v-bind="navigationProperties"
    @click-previous="slideToPreviousSlide"
    @click-next="slideToNextSlide"
  />
</template>
