import { getBaseHeaders, resolveApi3Url } from '@leon-hub/api';

import { ApplicationRestClient } from '@core/app-rest-client';

export default class CreditCardTokenizationApiClient extends ApplicationRestClient {
  constructor() {
    super({
      baseUrl: resolveApi3Url(),
      method: 'POST',
      origin: '',
      headers: {
        ...getBaseHeaders(),
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  }
}
