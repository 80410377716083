<script lang="ts" setup>
import { toRef } from 'vue';

import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event';
import MarketsList from 'web/src/modules/sportline/views/markets-list/MarketsList.vue';

import type { SportEventListItemExtendedMarketsRef } from './types';
import { useExtendedSportEventInfo } from './composables/useExtendedSportEventInfo';
import SportlineExtendedMarketsSkeleton from './SportlineExtendedMarketsSkeleton.vue';

interface SportEventListItemExtendedMarketsProps {
  sportEventId: string;
  extendedBlockType?: ExtendedEventBlock;
}

interface SportEventListItemExtendedMarketsEmits {
  (e: 'before-open'): void;
  (e: 'after-open'): void;
  (e: 'after-close'): void;
}

const props = defineProps<SportEventListItemExtendedMarketsProps>();
const emit = defineEmits<SportEventListItemExtendedMarketsEmits>();

const sportlineEventId = toRef(props, 'sportEventId');
const extendedBlockType = toRef(props, 'extendedBlockType');

const beforeOpen = () => emit('before-open');
const afterOpen = () => emit('after-open');
const afterClose = () => emit('after-close');

const {
  isEventExtended,
  extendedSportEvent,
  representationSportFamily,
  isMarketGroupsTabsEnabled,
  extendedSportEventDetailsMarketLimit,

  openExtendedSportlineEvent,
  closeExtendedSportlineEvent,
  pauseBackgroundUpdated,
  resumeBackgroundUpdated,
} = useExtendedSportEventInfo({ sportlineEventId, extendedBlockType }, { afterClose });

onComponentActivated(resumeBackgroundUpdated);
onComponentDeactivated(pauseBackgroundUpdated);

defineExpose<SportEventListItemExtendedMarketsRef>({
  open(): void { void openExtendedSportlineEvent(); },
  close(): void { closeExtendedSportlineEvent(); },
});
</script>

<template>
  <Transition v-auto-id="'SportEventListItemExtendedMarkets'"
    name="slide"
    :enter-active-class="$style['slide-enter-active']"
    :enter-from-class="$style['slide-enter-from']"
    :enter-to-class="$style['slide-enter-to']"
    :leave-active-class="$style['slide-leave-active']"
    :leave-from-class="$style['slide-leave-from']"
    :leave-to-class="$style['slide-leave-to']"
    @before-enter="beforeOpen"
    @after-enter="afterOpen"
    @enter-cancelled="afterOpen"
  >
    <div
      v-if="isEventExtended"
      :class="{
        [$style['sport-event-list-item-extended']]: true,
        [$style['sport-event-list-item-extended--loaded']]: !!extendedSportEvent,
      }"
    >
      <SportlineExtendedMarketsSkeleton v-if="!extendedSportEvent" />
      <MarketsList
        v-if="'1'"
        :sport-event="extendedSportEvent"
        :representation-sport-family="representationSportFamily"
        :is-loaded="!!extendedSportEvent"
        :is-market-groups-tabs-enabled="isMarketGroupsTabsEnabled"
        :default-markets-limit="extendedSportEventDetailsMarketLimit"
        is-list-item
      />
    </div>
  </Transition>
</template>

<style lang="scss" module>
.sport-event-list-item-extended {
  height: 52px;

  &--loaded {
    height: auto;
  }
}

.slide-enter-active {
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
}

.slide-leave-active {
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-duration: 0.3s;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 300px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
