<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type {
  FeedbackRatingEmits,
  FeedbackRatingProps,
} from 'web/src/modules/feedback/submodules/feedback-rating/components/FeedbackRating/types';

const props = defineProps<FeedbackRatingProps>();
const emits = defineEmits<FeedbackRatingEmits>();

const isRatingChangeEnabled = computed(() => (props.isRatingChangeAvailable));
const rating = ref<number>(0);
const hoverRating = ref<number>(0);

const isOnlyActiveStartsAvailable = computed(() => (!!props.surveyRating && !props.isRatingChangeAvailable && props.isRounded));
const ratings = computed<number[]>(() => (
  isOnlyActiveStartsAvailable.value ? Array.from({ length: props.surveyRating ? props.surveyRating : 5 }, (v, i) => i + 1) : [1, 2, 3, 4, 5]
));

const starProperties = computed<VIconProps>(() => {
  const iconName = null ? IconName.STAR : IconName.STAR_ALT;

  const iconSize = null
    ? isOnlyActiveStartsAvailable.value ? IconSize.SIZE_24 : IconSize.SIZE_36
    : isOnlyActiveStartsAvailable.value ? IconSize.SIZE_20 : IconSize.SIZE_24;

  return {
    name: iconName,
    size: iconSize,
  };
});

function isRatingActive(value: number): boolean {
  return (isRatingChangeEnabled.value && hoverRating.value >= value) || rating.value >= value;
}

function isRatingNotHovered(value: number): boolean {
  return isRatingChangeEnabled.value ? rating.value < value : false;
}

function onRatingChange(value: number): void {
  if (!isRatingChangeEnabled.value) {
    return;
  }

  setRating(value);
  emits('submit-rating', rating.value);
}

function setRating(value: number): void {
  rating.value = value;
}

function onRatingHover(value: number): void {
  hoverRating.value = value;
}

function resetRatingHover(): void {
  hoverRating.value = 0;
}

onMounted(() => {
  if (props.surveyRating) {
    setRating(props.surveyRating);
  }
});
</script>

<template>
  <div v-auto-id="'FeedbackRating'"
    :class="{
      [$style['feedback-rating']]: true,
      [$style['feedback-rating--inline']]: isOnlyActiveStartsAvailable,
      [$style['feedback-rating--rounded']]: isRounded,
      [$style['feedback-rating--confirm']]: !isRatingChangeEnabled,
    }"
  >
    <div
      v-if="isRatingChangeEnabled"
      :class="$style['feedback-rating__title-box']"
    >
      <template v-if="infoIcon && title">
        <VIcon
          v-bind="infoIcon"
          :class="$style['feedback-rating__info']"
        />
        <div :class="$style['feedback-rating__title-wrapper']">
          <div
            :class="$style['feedback-rating__title']"
          >
            {{ title }}
          </div>
          <div :class="$style['feedback-rating__text']">
            {{ allowedToChangeRatingText }}
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="$style['feedback-rating__text']">
          {{ allowedToChangeRatingText }}
        </div>
      </template>
    </div>
    <div
      v-else
      :class="{
        [$style['feedback-rating__title']]: !isOnlyActiveStartsAvailable,
        [$style['feedback-rating__text']]: isOnlyActiveStartsAvailable,
      }"
    >
      {{ disabledToChangeRatingText }}
    </div>
    <div
      :class="{
        [$style['feedback-rating__stars']]: true,
        [$style['feedback-rating__stars--flexible']]: isOnlyActiveStartsAvailable,
      }"
    >
      <div
        v-for="ratingIndex in ratings"
        :key="ratingIndex"
        :class="{
          [$style['feedback-rating__star']]: true,
          [$style['feedback-rating__star--flexible']]: isOnlyActiveStartsAvailable,
          [$style['feedback-rating__star--active']]: isRatingActive(ratingIndex),
          [$style['feedback-rating__star--not-hovered']]: isRatingNotHovered(ratingIndex),
        }"
        @click="onRatingChange(ratingIndex)"
        @mouseover="onRatingHover(ratingIndex)"
        @mouseleave="resetRatingHover"
      >
        <VIcon
          v-bind="starProperties"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.feedback-rating {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &__text {
    @include feedbackRatingTextFont;

    margin: 0;
    word-break: break-word;
  }

  &__title {
    @include feedbackRatingTitleFont;

    &--left {
      text-align: left;
    }
  }

  &__title-box {
    display: flex;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__stars {
    display: flex;
    gap: $feedbackRatingStarsGap;
    justify-content: center;
    text-align: center;

    &--flexible {
      gap: $feedbackRatingStarsFlexibleGap;
      margin: $feedbackRatingStarsFlexibleMargin;
    }
  }

  &__star {
    padding: $feedbackRatingStarPadding;
    color: $feedbackRatingStarColor;
    cursor: pointer;

    &--active {
      color: $feedbackRatingStarColorActive;
      opacity: 1;
    }

    &--not-hovered {
      opacity: 0.5;
    }

    &--flexible {
      padding: 0;
    }
  }

  &__info {
    margin-right: 8px;
    vertical-align: top;
  }

  &--confirm {
    #{$self}__star {
      cursor: default;
    }
  }

  &--rounded {
    @include feedbackRatingRounded;
  }

  &--inline {
    @include feedbackRatingInlineFlexDirection;

    gap: 8px;
    align-items: center;
    justify-content: space-between;
    #{$self}__text {
      @include feedbackRatingInlineText;
    }
  }
}
</style>
