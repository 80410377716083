import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import type VCaptchaProperties from 'web/src/components/Input/types/VCaptchaProperties';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';

import type { FormWrapperWidgetProperties } from '../../types';

interface UseFormCaptcha {
  componentProps: ComputedRef<VCaptchaProperties>;
}

export default function useFormCaptcha(
  props: FormWrapperWidgetProperties<VCaptchaProperties>,
): UseFormCaptcha {
  const componentProps = computed<VCaptchaProperties>(() => {
    // original: https://gitlab.leoncorp.net/leonweb/leon-front/-/merge_requests/762/diffs
    const {
      options,
      error,
    } = props;
    const captchaProperties = {
      ...(options ?? {}),
      error,
    };
    assert(
      isVCaptchaProperties(captchaProperties),
      'FormCaptcha has to be rendered with correct properties.',
    );
    return captchaProperties;
  });
  return {
    componentProps,
  };
}
