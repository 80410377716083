import { getAchievementsCategories } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  GetAchievementsCategoriesDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getCategoriesRequest(): Promise<GetAchievementsCategoriesDataDocument> {
  const apiClient = useGraphqlClient();

  return getAchievementsCategories(
    apiClient,
    (node) => node.queries.achievements.getCategories,
    { options: {} },
  );
}
