import type {
  HeadlineMatchesSportEventListElement,
  HeadlineMatchesSportListElement,
} from 'web/src/modules/sportline/submodules/top/types';
import type {
  MarketType,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type {
  CoreLeagueResponse,
  CoreRegionResponse,
  CoreSportEventResponse,
  CoreSportResponse,
  RawLeague,
  RawRegion,
  RawSport,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import {
  ParseSportEventError,
} from 'web/src/modules/sportline/errors/rest';
import { AbstractLineFactory } from 'web/src/modules/sportline/utils/rest';
import {
  createSportEvent,
  parseLeagueResponse,
  parseRegionResponse,
  parseSportResponse,
} from 'web/src/modules/sportline/utils/rest/build';

type LeaguesMap = Map<string, {
  league: RawLeague;
}>;

type RegionsMap = Map<string, {
  region: RawRegion;
  leagues: LeaguesMap;
}>;

type SportsMap<SE extends SportlineEvent> = Map<string, {
  sport: RawSport;
  sportEvents: {
    sportEvent: SE;
    region: RawRegion;
    league: RawLeague;
  }[];
}>;

/**
 * Used to prepare top events sportline (home page)
 * @TODO merge with SportlineFactory
 */
export class HeadlineFactory<SE extends SportlineEvent = SportlineEvent>
  extends AbstractLineFactory<
    HeadlineMatchesSportListElement<SE>,
    SportsMap<SE>
  > {
  protected result: SportsMap<SE> = new Map([]);

  protected regions: RegionsMap = new Map([]);

  protected processSportResponse(
    { sportResponse }: { sportResponse: CoreSportResponse },
  ): { sport: RawSport; sportEvents: { sportEvent: SE; region: RawRegion; league: RawLeague }[] } {
    const sportId = String(sportResponse.id);
    let sportInfo = this.result.get(sportId);

    if (!sportInfo) {
      const rawSport = parseSportResponse(sportResponse, this.buildSportOptions);
      sportInfo = {
        sport: rawSport,
        sportEvents: [],
      };
      this.result.set(sportId, sportInfo);
    }

    return sportInfo;
  }

  protected processRegionResponse({ sportResponse, regionResponse }: {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
  }): { region: RawRegion; leagues: LeaguesMap } {
    const regionId = String(regionResponse.id);
    let regionInfo = this.regions.get(regionId);

    if (!regionInfo) {
      const rawRegion = parseRegionResponse({
        sportResponse,
        regionResponse,
        ...this.buildSportOptions,
      });
      regionInfo = {
        region: rawRegion,
        leagues: new Map([]),
      };
      this.regions.set(regionId, regionInfo);
    }

    return regionInfo;
  }

  protected processLeagueResponse({ sportResponse, regionResponse, leagueResponse }: {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
    leagueResponse: CoreLeagueResponse;
  }): { league: RawLeague } {
    const regionInfo = this.processRegionResponse({ sportResponse, regionResponse });
    const leagueId = String(leagueResponse.id);
    let leagueInfo = regionInfo.leagues.get(leagueId);

    if (!leagueInfo) {
      const rawLeague = parseLeagueResponse({
        sportResponse,
        regionResponse,
        leagueResponse,
        ...this.buildSportOptions,
      });
      leagueInfo = {
        league: rawLeague,
      };
      regionInfo.leagues.set(leagueId, leagueInfo);
    }

    return leagueInfo;
  }

  protected processEventResponse({
    sportResponse,
    regionResponse,
    leagueResponse,
    eventResponse,
  }: {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
    leagueResponse: CoreLeagueResponse;
    eventResponse: CoreSportEventResponse;
  }): void {
    if (eventResponse.betline === BetlineType.Outright) {
      throw new ParseSportEventError({
        message: 'Outright in headline is not allowed',
        response: eventResponse,
        buildOptions: this.buildSportOptions,
      });
    }

    const sportInfo = this.processSportResponse({ sportResponse });
    const regionInfo = this.processRegionResponse({ sportResponse, regionResponse });
    const leagueInfo = this.processLeagueResponse({ sportResponse, regionResponse, leagueResponse });
    const sportEvent = createSportEvent(eventResponse, {
      sportResponse,
      regionResponse,
      leagueResponse,
      ...this.buildSportOptions,
      useFullProgress: sportInfo.sport.sport.representation.useFullProgress,
    }) as SE;
    sportInfo.sportEvents.push({
      sportEvent,
      region: regionInfo.region,
      league: leagueInfo.league,
    });
  }

  build(): HeadlineMatchesSportListElement<SE>[] {
    return [...this.parseResponse().values()].map<HeadlineMatchesSportListElement<SE>>((sportInfo) => {
      const sportMarketTypes = new Map<string, MarketType>([]);
      const sportEvents: HeadlineMatchesSportEventListElement<SE>[] = sportInfo.sportEvents.map((sportEventInfo) => {
        for (const market of sportEventInfo.sportEvent.markets) {
          sportMarketTypes.set(market.type.id, market.type);
        }

        return {
          league: sportEventInfo.league.league,
          region: sportEventInfo.region.region,
          sportEvent: sportEventInfo.sportEvent,
        };
      });

      return {
        key: sportInfo.sport.sport.id,
        sport: sportInfo.sport.sport,
        marketTypes: [...sportMarketTypes.values()],
        marketsColumns: sportInfo.sport.marketsColumns,
        sportEvents,
      };
    });
  }
}
