import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { useSiteConfigStore } from '@core/site-config';

import type { UseShieldIntegrationStore } from '../types';
import { getStorage, normalizeUrl } from '../utils';

export const useShieldIntegrationStore = defineStore('shield-integration', (): UseShieldIntegrationStore => {
  const sessionId = ref<string | undefined>(undefined);
  const helmetId = ref<string | undefined>(undefined);
  const sdkUrl = computed<string | undefined>(() => {
    const { helmetAPIUrl } = useSiteConfigStore();
    return helmetAPIUrl ? normalizeUrl(helmetAPIUrl) : undefined;
  });

  return {
    isEnabled: toRef(() => useSiteConfigStore().isShieldEnabled),
    sdkUrl,
    sessionId,
    helmetId,
    setShieldData(data: { session_id: string; helmetId: string }): Promise<void> {
      sessionId.value = data.session_id;
      helmetId.value = data.helmetId;
      return getStorage().set({ session_id: data.session_id, helmetId: data.helmetId });
    },
  };
});
