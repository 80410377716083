export const extendedEventBlockDefault = 'Default';
export const extendedEventBlockTop = 'Top';
export const extendedEventBlockLive = 'Live';
export const extendedEventBlockCybersport = 'Cybersport';
export const extendedEventBlockLeague = 'League';
export const extendedEventBlockRegion = 'Region';
export const extendedEventBlockBreadcrumbs = 'Breadcrumbs';
export const extendedEventBlockRelatedEvents = 'RelatedEvents';
export const extendedEventBlockFavorites = 'Favorites';
export const extendedEventBlockZeroMargin = 'ZeroMargin';

export const createEventIsOpenedRefInjectionSymbol = Symbol('create-event-is-opened-ref');
