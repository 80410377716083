import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName } from '@leon-hub/icons';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';

export interface BalanceSwiperNavigationComposable {
  slideToPreviousSlide(): void;
  slideToNextSlide(): void;
  isShowButtons: ComputedRef<boolean>;
  previousButtonProperties: ComputedRef<VButtonProps>;
  nextButtonProperties: ComputedRef<VButtonProps>;
}

export default function useBalanceSwiperNavigation(): BalanceSwiperNavigationComposable {
  const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();

  const isShowButtons = computed(() => !!swiperState?.isPreviousButtonAllowed.value || !!swiperState?.isNextButtonAllowed.value);

  const previousButtonProperties = computed<VButtonProps>(() => ({
    kind: ButtonKind.BASE,
    height: ButtonHeight.MEDIUM,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT,
    disabled: !swiperState?.isPreviousButtonAllowed.value,
  }));

  const nextButtonProperties = computed<VButtonProps>(() => ({
    kind: ButtonKind.BASE,
    height: ButtonHeight.MEDIUM,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
    disabled: !swiperState?.isNextButtonAllowed.value,
  }));

  return {
    slideToPreviousSlide,
    slideToNextSlide,
    isShowButtons,
    previousButtonProperties,
    nextButtonProperties,
  };
}
