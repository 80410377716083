import type { Ref } from 'vue';
import noop from 'lodash/noop';
import { computed, toRef } from 'vue';

import { assert, isNumber, isString } from '@leon-hub/guards';

import { useIsLoggedIn } from '@core/auth';
import { useSiteConfigStore } from '@core/site-config';

import type { FormData } from 'web/src/components/Form/types';
import { useOnAppContentLoadedCallback } from 'web/src/modules/core/composables/content';

import type { RecaptchaV3Props } from './../types/RecaptchaV3Props';
import { RecaptchaV3Container } from '../services/RecaptchaV3Container';
import { getRecaptchaV3Token, isReCaptchaV3Instance } from '../services/utils';

interface AddRecaptchaV3TokenToFormDataPayload {
  formData: FormData;
  captchaProps: RecaptchaV3Props;
  fieldName: string;
}

export interface RecaptchaV3Composable {
  isRegistrationCaptchaV3Enabled: Ref<boolean>;
  isLoginCaptchaV3Enabled: Ref<boolean>;
  isPasswordRecoveryEnabledV3Enabled: Ref<boolean>;
  addRecaptchaV3TokenToFormData(payload: AddRecaptchaV3TokenToFormDataPayload): Promise<FormData>;
  initRecaptchaV3(): void;
}

export function useRecaptchaV3(): RecaptchaV3Composable {
  if (process.env.VUE_APP_FEATURE_RECAPTCHA_V3_ENABLED) {
    const { isLoggedIn } = useIsLoggedIn();
    const siteConfigStore = useSiteConfigStore();
    const { performOnLoad } = useOnAppContentLoadedCallback();
    const isRegistrationCaptchaV3Enabled = toRef(() => siteConfigStore.captcha?.recaptchaV3?.registrationEnabled ?? false);
    const isLoginCaptchaV3Enabled = toRef(() => siteConfigStore.captcha?.recaptchaV3?.loginEnabled ?? false);
    const isPasswordRecoveryEnabledV3Enabled = toRef(() => siteConfigStore.captcha?.recaptchaV3?.passwordRecoveryEnabled ?? false);
    const siteKey = toRef(() => siteConfigStore.captcha?.recaptchaV3?.siteKey);
    const timeout = toRef(() => siteConfigStore.captcha?.recaptchaV3?.timeout ?? 10_000);

    const isRecaptchaV3enabled = computed<boolean>(() => {
      return isRegistrationCaptchaV3Enabled.value || isLoginCaptchaV3Enabled.value || isPasswordRecoveryEnabledV3Enabled.value;
    });

    const initRecaptchaV3 = (): void => {
      if (!isLoggedIn.value && isRecaptchaV3enabled.value) {
        performOnLoad(() => {
          assert(isString(siteKey.value), 'unexpected value for recaptchaV3 site key');
          void RecaptchaV3Container.init(`https://www.google.com/recaptcha/api.js?render=${siteKey.value}`);
        });
      }
    };

    const getToken = (action?: string): Promise<string> => {
      assert(isReCaptchaV3Instance(RecaptchaV3Container.grecaptchaInstance));
      assert(isString(siteKey.value));
      assert(isNumber(timeout.value));
      return getRecaptchaV3Token({
        captchaInstance: RecaptchaV3Container.grecaptchaInstance,
        siteKey: siteKey.value,
        timeout: timeout.value,
        action,
      });
    };

    const addRecaptchaV3TokenToFormData = async ({ formData, captchaProps, fieldName }: AddRecaptchaV3TokenToFormDataPayload): Promise<FormData> => {
      const token = await getToken(captchaProps.action);
      if (token) {
        formData[fieldName] = token;
      }
      return formData;
    };
    return {
      isRegistrationCaptchaV3Enabled,
      isLoginCaptchaV3Enabled,
      isPasswordRecoveryEnabledV3Enabled,
      addRecaptchaV3TokenToFormData,
      initRecaptchaV3,
    };
  }

  return {
    isRegistrationCaptchaV3Enabled: toRef(() => false),
    isLoginCaptchaV3Enabled: toRef(() => false),
    isPasswordRecoveryEnabledV3Enabled: toRef(() => false),
    addRecaptchaV3TokenToFormData({ formData }: AddRecaptchaV3TokenToFormDataPayload): Promise<FormData> {
      return Promise.resolve(formData);
    },
    initRecaptchaV3: noop,
  };
}
