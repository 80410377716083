import type { ComputedRef, Ref } from 'vue';
import { computed, nextTick, ref, watch } from 'vue';

import type { Focusable } from '@leon-hub/focus';
import type { BaseInputEmits, VInputInputEvent } from '@leon-hub/input-types';
import { useDebounce } from '@leon-hub/debounce';
import { InputEventType } from '@leon-hub/input-types';

import type { AddressOption, AutocompleteAddressProps } from '../types';
import { useAddressSearch } from './useAddressSearch';
import { useNearbyCitiesSearch } from './useNearbyCitiesSearch';

interface UseAutocompleteAddress {
  onInput(event: VInputInputEvent): void;
  onChange(): void;
  onBlur(): void;
  onFocus(): void;
  onClear(): void;
  selectOption(option: AddressOption): void;
  isOptionsShown: ComputedRef<boolean>;
  options: Ref<AddressOption[]>;
  predefinedOptions: Ref<AddressOption[]>;
  inputValue: Ref<string>;
  preselectedIndex: Ref<number>;
  onKeyDown(event: KeyboardEvent): void;
  setPreselectedIndex(index: number): void;
  isPendingSearch: Ref<boolean>;
  debouncedAddressSearch(query: string): void;
  showModalSearch(): void;
  hideModalSearch(): void;
  isModalSearchShown: Ref<boolean>;
  focusable: Ref<Focusable | undefined>;
}

export function useAutocompleteAddress(
  props: AutocompleteAddressProps,
  emit: BaseInputEmits,
): UseAutocompleteAddress {
  const { findAddressByQuery } = useAddressSearch();

  const { nearbyCities, getNearbyCities } = useNearbyCitiesSearch();

  const focusable = ref<Focusable>();

  const isModalSearchShown = ref<boolean>(false);

  const inputValue = ref<string>(props.value || '');

  const isPreselected = ref<boolean>(false);

  const options = ref<AddressOption[]>([]);

  const isFocused = ref<boolean>(false);

  const isPendingSearch = ref<boolean>(false);

  const preselectedIndex = ref<number>(-1);

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: props.disallowUserInput && !isPreselected.value ? '' : inputValue.value,
      name: props.name || '',
    },
  }));

  const placeTypes = computed<string[]>(() => {
    /**
     * @example ['(cities)'] - search only cities
     * @example ['street_address'] - street address
     * @see https://developers.google.com/maps/documentation/javascript/supported_types
     * LEONWEB-9819 - only cities or address is allowed
     * address is default, (cities) is enabled by isCityType prop
     */
    return props.isCityType ? [('(cities)')] : ['address'];
  });

  const setPreselectedIndex = (index = -1): void => {
    preselectedIndex.value = index;
  };

  watch(() => props.value, (value) => {
    inputValue.value = value || '';
  });

  const resetOptions = (): void => {
    setPreselectedIndex(-1);
    options.value = [];
  };

  const resetOptionsOnRelatedDataChange = (newValue: string, oldValue: string): void => {
    if (newValue !== oldValue) {
      resetOptions();
    }
  };

  watch(() => props.countryCode, resetOptionsOnRelatedDataChange);
  watch(() => props.selectedCity, resetOptionsOnRelatedDataChange);

  const emitFocus = (): void => {
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const emitBlur = (): void => {
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  const onChange = (): void => {
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const emitInput = (): void => {
    emit(InputEventType.INPUT, eventToEmit.value);
  };

  const isOptionsShown = computed<boolean>(() => !!(isFocused.value && options.value.length));

  const searchAddress = async (query: string): Promise<void> => {
    const computedQuery = query && props.selectedCity ? `${props.selectedCity}, ${query}` : query;
    isPendingSearch.value = true;
    options.value = await findAddressByQuery({
      address: computedQuery,
      countryCode: props.countryCode,
      types: placeTypes.value,
    });
    isPendingSearch.value = false;
  };

  const debouncedAddressSearch = useDebounce((query: string) => {
    void searchAddress(query);
  }, 500);

  const loadInitialOptions = (): void => {
    if (props.disallowUserInput && !nearbyCities.value.length) {
      void getNearbyCities();
    }
    if (props.value) {
      void searchAddress(props.value);
    }
  };

  const onFocus = (): void => {
    isFocused.value = true;
    emitFocus();
    loadInitialOptions();
  };

  const onBlur = (): void => {
    isFocused.value = false;
    setPreselectedIndex(-1);
    emitBlur();
  };

  const onInput = (event: VInputInputEvent): void => {
    const { value } = event.target;
    inputValue.value = value;
    isPreselected.value = false;
    void debouncedAddressSearch(value);
  };

  const onClear = (): void => {
    options.value = [];
    inputValue.value = '';
    isPreselected.value = false;
    setPreselectedIndex(-1);
    emitInput();
  };

  const movePreselectedIndex = (moveDown = false): void => {
    let nextIndex = moveDown ? preselectedIndex.value + 1 : preselectedIndex.value - 1;
    if (nextIndex >= options.value.length) {
      nextIndex = 0;
    }
    if (nextIndex < 0) {
      nextIndex = options.value.length - 1;
    }
    setPreselectedIndex(nextIndex);
  };

  const showModalSearch = () => {
    if (props.disallowUserInput) {
      document.body.focus(); // reset focus to autofocus feature
      isModalSearchShown.value = true;
      loadInitialOptions();
    }
  };

  const hideModalSearch = () => {
    isModalSearchShown.value = false;
    if (!inputValue.value) {
      resetOptions();
    }
    void nextTick().then(() => {
      focusable?.value?.focus();
    });
  };

  const selectOption = (option: AddressOption): void => {
    options.value = [];
    setPreselectedIndex(-1);
    inputValue.value = option.primaryText;
    isPreselected.value = true;
    emitInput();
    hideModalSearch();
  };

  const preselectByIndex = (index = -1): void => {
    const option = options.value[index];
    if (option) {
      selectOption(option);
    }
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    const { key } = event;
    if ((options.value.length)) {
      if (key === 'Enter' && preselectedIndex.value > -1) {
        event.preventDefault();
        preselectByIndex(preselectedIndex.value);
        return;
      }
      if (key === 'ArrowDown' || key === 'ArrowUp') {
        event.preventDefault();
        movePreselectedIndex(key === 'ArrowDown');
      }
    }
  };

  return {
    inputValue,
    options,
    predefinedOptions: nearbyCities,
    onInput,
    onChange,
    onClear,
    onBlur,
    onFocus,
    isOptionsShown,
    selectOption,
    preselectedIndex,
    isPendingSearch,
    onKeyDown,
    setPreselectedIndex,
    debouncedAddressSearch,
    showModalSearch,
    hideModalSearch,
    isModalSearchShown,
    focusable,
  };
}
