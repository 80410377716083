import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { BaseInputEmits, MaskOptions, VInputInputEvent } from '@leon-hub/input-types';
import { InputEventType } from '@leon-hub/input-types';

import { useI18n } from '@core/i18n';

import type { DateMaskInputProps } from '../../types';
import { formatInputDateToInputString, formatInputStringToInputDate } from './utils/formatDateMaskValue';

interface UseDateMaskInput {
  inputValue: Ref<string>;
  mask: ComputedRef<MaskOptions>;
  maxSymbols: number;
  onChange(event: VInputInputEvent): void;
  onInput(event: VInputInputEvent): void;
  onBlur(): void;
  onFocus(): void;
}

export default function useDateMaskInput(props: DateMaskInputProps, emit: BaseInputEmits): UseDateMaskInput {
  const { $translate } = useI18n();

  const inputValue = ref<string>(formatInputDateToInputString(props.value || ''));

  const setInputValue = (value: string): void => {
    inputValue.value = value;
  };

  const syncValueProp = (value: string): void => {
    setInputValue(formatInputDateToInputString(value));
  };
  watch(() => props.value, (value) => {
    syncValueProp(value || '');
  });

  const pattern = '99.99.9999';

  const maxSymbols = pattern.length;

  const mask = computed<MaskOptions>(() => {
    const placeholder = $translate('WEB2_DATE_INPUT_FORMAT').value;
    return {
      pattern,
      // if there is no translation set 'DD.MM.YYYY'
      placeholder: placeholder === 'WEB2_DATE_INPUT_FORMAT' ? 'DD.MM.YYYY' : placeholder,
    };
  });

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: formatInputStringToInputDate(inputValue.value),
      name: props.name || '',
    },
  }));

  const onInput = ({ target }: VInputInputEvent): void => {
    setInputValue(target.value);
    emit(InputEventType.INPUT, eventToEmit.value);
  };

  const onChange = ({ target }: VInputInputEvent): void => {
    setInputValue(target.value);
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const onFocus = (): void => {
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const onBlur = (): void => {
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  return {
    inputValue,
    mask,
    maxSymbols,
    onInput,
    onChange,
    onFocus,
    onBlur,
  };
}
