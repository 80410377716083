import { storeToRefs } from 'pinia';

import type { UIField } from '@leon-hub/api-sdk';
import { assert, isObject, isString } from '@leon-hub/guards';

import type { CountryData } from '@core/country';
import { useCountryStore } from '@core/country';

import type { FormPhoneValue } from 'web/src/components/Form/types';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
import matchPhoneNumberAndCountryCodeMultiRegion from 'web/src/utils/phoneNumberUtils/matchPhoneNumberAndCountryCode';

function matchPhoneNumberAndCountryCode(input: {
  prefix: string;
  phoneNumber?: string;
  countryCode: string;
  countries: CountryData[];

}) {
  if (!process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED
    || process.env.VUE_APP_STORYBOOK
    || (process.env.NODE_ENV as string) === 'test') {
    return matchPhoneNumberAndCountryCodeMultiRegion(input);
  }

  return process.env.VUE_APP_DEFAULT_COUNTRY_CODE;
}

interface GetDefaultPhoneValueForFormPayload {
  prefix?: string;
  suffix?: string;
}

export interface UseFormDefaultFormPhoneValue {
  getDefaultPhoneValueForForm(payload?: GetDefaultPhoneValueForFormPayload): FormPhoneValue;
  parsePhone(source: string | number): FormPhoneValue;
  parsePhoneNumberByUIField(field: Partial<UIField>): FormPhoneValue;
}

export default function useFormDefaultPhoneValue(): UseFormDefaultFormPhoneValue {
  const { currentCustomerCountryData, countriesData } = storeToRefs(useCountryStore());

  const normalizePhoneInput = (input: string | number = ''): string => {
    if (typeof input === 'number') {
      return String(input);
    }

    return input.replace(/^\+/, '')
      .replace(/\s+/g, '')
      .replace(/\(|\)/g, '')
      .replace(/-/g, '');
  };

  const getPrefix = (countryCode: string): string | null => {
    if (process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED) {
      return process.env.VUE_APP_DEFAULT_PHONE_PREFIX ?? null;
    }
    const matchedPrefix = countriesData.value
      .find(({ code }) => code.toLowerCase() === countryCode.toLowerCase())
      ?.phonePrefix;
    return matchedPrefix ? `${matchedPrefix}` : null;
  };

  const isValidFormPhoneValue = (value: unknown): value is FormPhoneValue => {
    const countryCodeIso2Length = 2;
    return isObject(value)
      && isString(value.prefix)
      && isString(value.suffix)
      && isString(value.countryCode)
      && value.countryCode.length === countryCodeIso2Length;
  };

  const parsePhone = (source: string | number): FormPhoneValue => {
    const normalizedSource = normalizePhoneInput(source);

    const matchedPrefixes = countriesData.value
      .filter(({ phonePrefix }) => normalizedSource.startsWith(`${phonePrefix}`));
    if (!matchedPrefixes.length) {
      throw new Error(`unable to parse phone number: ${normalizedSource}`);
    }
    const prefix = matchedPrefixes[0].phonePrefix.toString();
    const suffix = normalizedSource.slice(prefix.length);

    return {
      prefix,
      suffix,
      countryCode: matchedPrefixes.length === 1
        ? matchedPrefixes[0].code
        : matchPhoneNumberAndCountryCode({
            phoneNumber: suffix,
            countries: countriesData.value,
            countryCode: currentCustomerCountryData.value.code,
            prefix,
          }),
    };
  };

  const parsePhoneNumberByUIField = ({ defaultValue }: Partial<UIField>): FormPhoneValue => {
    let defaultCountryCode: string;
    if (process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED) {
      assert(isString(process.env.VUE_APP_DEFAULT_COUNTRY_CODE), 'process.env.VUE_APP_DEFAULT_COUNTRY_CODE is not set');
      defaultCountryCode = process.env.VUE_APP_DEFAULT_COUNTRY_CODE;
    } else {
      defaultCountryCode = currentCustomerCountryData.value.code;
    }
    const defaultPrefix = getPrefix(defaultCountryCode);
    if (isPartialFormPhoneValue(defaultValue)) {
      const phoneNumber = parsePhone(`${defaultValue.prefix ?? defaultPrefix}${defaultValue.suffix ?? ''}`);
      if (isValidFormPhoneValue(phoneNumber)) {
        return phoneNumber;
      }
    }
    if (defaultValue && isString(defaultValue)) {
      const phoneNumber = parsePhone(defaultValue);
      if (isValidFormPhoneValue(phoneNumber)) {
        return phoneNumber;
      }
    }
    return {
      prefix: defaultPrefix ?? '',
      countryCode: defaultCountryCode,
      suffix: '',
    };
  };

  const getDefaultPhoneValueForForm = (payload: GetDefaultPhoneValueForFormPayload = {}): FormPhoneValue => {
    let defaultPrefix: string;
    if (process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED) {
      assert(isString(process.env.VUE_APP_DEFAULT_PHONE_PREFIX), 'VUE_APP_DEFAULT_PHONE_PREFIX is not set');
      defaultPrefix = process.env.VUE_APP_DEFAULT_PHONE_PREFIX;
    } else {
      defaultPrefix = currentCustomerCountryData.value.phonePrefix.toString();
    }
    const prefix = payload.prefix ?? defaultPrefix;
    const matchedCountryCode = matchPhoneNumberAndCountryCode({
      prefix,
      phoneNumber: payload.suffix,
      countryCode: currentCustomerCountryData.value.code,
      countries: countriesData.value,
    });
    return {
      prefix,
      countryCode: matchedCountryCode,
      suffix: payload.suffix || '',
    };
  };

  return {
    getDefaultPhoneValueForForm,
    parsePhone,
    parsePhoneNumberByUIField,
  };
}
