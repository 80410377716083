import type { FormPhoneValue } from 'web/src/components/Form/types';
import type { RegistrationFormFilledFields } from 'web/src/modules/registration/types';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';

export function getFilledPhoneValue(formFilledFields: Readonly<RegistrationFormFilledFields> = {}): Partial<FormPhoneValue> {
  const phone: Partial<FormPhoneValue> = {};
  if (CustomCustomerFieldType.PHONE_INPUT_PREFIX in formFilledFields) {
    phone.prefix = String(formFilledFields[CustomCustomerFieldType.PHONE_INPUT_PREFIX]);
  }
  if (CustomCustomerFieldType.PHONE_INPUT_COUNTRY in formFilledFields) {
    phone.countryCode = String(formFilledFields[CustomCustomerFieldType.PHONE_INPUT_COUNTRY]);
  }
  if (CustomCustomerFieldType.PHONE_INPUT_SUFFIX in formFilledFields) {
    phone.suffix = String(formFilledFields[CustomCustomerFieldType.PHONE_INPUT_SUFFIX]);
  }
  return phone;
}
