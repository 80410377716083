<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';

import type { CasinoLoyaltyProgramSwiperNavigationButtonsEmits, CasinoLoyaltyProgramSwiperNavigationButtonsProps } from './types';

const props = withDefaults(defineProps<CasinoLoyaltyProgramSwiperNavigationButtonsProps>(), {
  showPreviousButton: false,
  showNextButton: false,
});
const emit = defineEmits<CasinoLoyaltyProgramSwiperNavigationButtonsEmits>();
const previousButtonProperties: VButtonProps = {
  kind: ButtonKind.BASE,
  height: ButtonHeight.MEDIUM,
  iconName: null ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT,
  disabled: props.showPreviousButton,
};

const nextButtonProperties: VButtonProps = {
  kind: ButtonKind.BASE,
  height: ButtonHeight.MEDIUM,
  iconName: null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
  disabled: props.showNextButton,
};

function emitClickPrevious(): void {
  if (!props.showPreviousButton) {
    return;
  }
  emit('click-previous');
}

function emitClickNext(): void {
  if (!props.showNextButton) {
    return;
  }
  emit('click-next');
}
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramSwiperNavigationButtons'"
    v-show="showPreviousButton || showNextButton"
    :class="$style['casino-section-swiper-navigation']"
  >
    <VButton
      v-bind="previousButtonProperties"
      :class="$style['casino-section-swiper-navigation__button']"
      @click="emitClickPrevious"
    />
    <VButton
      v-bind="nextButtonProperties"
      :class="$style['casino-section-swiper-navigation__button']"
      @click="emitClickNext"
    />
  </div>
</template>

<style lang="scss" module>
.casino-section-swiper-navigation {
  display: flex;
  margin-left: 16px;

  &__button {
    &:last-child {
      margin-left: 4px;
    }
  }
}
</style>
