import type {
  GetHeadlineMatchesChangesResponse,
  GetHeadlineMatchesResponse,

  GetMergedHeadlineEventsResponse,
  GetSplitHeadlineEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  isGetHeadlineMatchesChangesResponseCheck,
  isGetMergedHeadlineMatchesChangesResponseCheck,
  isGetMergedHeadlineMatchesResponseCheck,
} from 'web/src/modules/sportline/guards/rest';
import { BetlineReplaceResponse } from 'web/src/modules/sportline/utils/rest';

interface HandleRawSportEventsResponseResult {
  rawMergedSportEventsResponse: Maybe<Readonly<GetMergedHeadlineEventsResponse>>;
  rawSplitSportEventsResponse: Maybe<Readonly<GetSplitHeadlineEventsResponse>>;
}

export function handleRawSportEventsResponse (response: Maybe<GetHeadlineMatchesChangesResponse | GetHeadlineMatchesResponse>): HandleRawSportEventsResponseResult {
  let result: HandleRawSportEventsResponseResult = {
    rawMergedSportEventsResponse: null,
    rawSplitSportEventsResponse: null,
  };
  if (!response) {
    return result;
  }

  if (isGetHeadlineMatchesChangesResponseCheck(response)) {
    if (isGetMergedHeadlineMatchesChangesResponseCheck(response)) {
      result = {
        ...result,
        rawMergedSportEventsResponse: Object.freeze({
          events: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.events),
          total: response.total,
        }),
      };
    } else {
      result = {
        ...result,
        rawSplitSportEventsResponse: Object.freeze({
          live: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.live),
          prematch: BetlineReplaceResponse.unknownResponseToSportEventsResponse(response.prematch),
          liveTotal: response.liveTotal,
          prematchTotal: response.prematchTotal,
        }),
      };
    }

    return result;
  }

  if (isGetMergedHeadlineMatchesResponseCheck(response)) {
    result = {
      ...result,
      rawMergedSportEventsResponse: Object.freeze(response),
    };
  } else {
    result = {
      ...result,
      rawSplitSportEventsResponse: Object.freeze(response),
    };
  }
  return result;
}
