import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { LeaderBoardParticipant } from '@leon-hub/api-sdk';
import { PromoActionType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { logger } from '@leon-hub/logging';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';
import { DateTime, TimeFormats } from '@leon-hub/utils';

import { useI18n, useI18nLocale } from '@core/i18n';

import type { LeaderBoardProps } from 'web/src/modules/promotions/components/LeaderBoard/types';
import type { LeaderBoardIdParameters } from 'web/src/modules/promotions/store/types';
import type { PromotionMergedDetailsDocument, PromotionScrollableElement } from 'web/src/modules/promotions/types';
import { useAppDownloadStore } from 'web/src/modules/app-download/store';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import useFortuneWheelStore from 'web/src/modules/fortune-wheel/store/useFortuneWheelStore';
import { useLeaderBoardStore, usePromotionsStore } from 'web/src/modules/promotions/store';
import {
  getLeaderBoardList,
  getLeaderBoardOptions,
} from 'web/src/modules/promotions/store/utils';

export interface CasinoGameLeaderBoardComposable {
  terms: Ref<PromotionScrollableElement | undefined>;
  board: Ref<PromotionScrollableElement | undefined>;
  currentPromoLeaderboardDate: Ref<string>;
  actionUrl: Ref<string>;
  haveLeaderBoard: Ref<boolean>;
  isLeaderBoardPromo: Ref<boolean>;
  isDoingActionButton: Ref<boolean>;
  leaderBoard: Ref<LeaderBoardParticipant[]>;
  currentPromotion: Ref<PromotionMergedDetailsDocument>;
  promotionButton: Ref<PromotionMergedDetailsDocument['actionButton']>;
  leaderBoardProperties: Ref<LeaderBoardProps | undefined>;

  getLeaderBoard(payload: LeaderBoardIdParameters): Promise<void>;

  loadPromotionDetails(actionUrl: string): Promise<void>;

  setOpenedActiveUrl(activeUrl?: string): void;

  doButtonAction(): Promise<void>;

  scrollToLeaderBoard(): void;

  goToCustomLink(): void;

  goToPlay(): void;

  goToIdentification(): void;

  loadMore(id?: number): Promise<void>;

  scrollToTerms(): void;

  doDeposit(): void;

  setPromotionIsNotAvailable(): void;
}

export default function usePromotionDetailsCore(): CasinoGameLeaderBoardComposable {
  const router = useRouter();
  const { $translate } = useI18n();
  const { locale } = useI18nLocale();
  const bus = useEventsBus();
  const board = ref<PromotionScrollableElement>();
  const terms = ref<PromotionScrollableElement>();

  const promotionsStore = usePromotionsStore();
  const leaderBoardStore = useLeaderBoardStore();
  const fortuneWheelStore = useFortuneWheelStore();
  const bonusStore = useBonusStore();

  const currentPromotion = toRef(promotionsStore, 'promotion');
  const promotionsLoaded = toRef(promotionsStore, 'promotionsLoaded');
  const customerCategory = toRef(promotionsStore, 'customerCategory');

  const { getLeaderBoard } = leaderBoardStore;
  const leaderBoardListMap = toRef(leaderBoardStore, 'leaderBoardListMap');
  const leaderBoardOptionsMapRecord = toRef(leaderBoardStore, 'leaderBoardOptionsMapRecord');

  const promotionButton = computed(() => currentPromotion.value?.actionButton);
  const fortuneWheelId = toRef(fortuneWheelStore, 'fortuneWheelId');
  const { loadPromotionDetails, setOpenedActiveUrl, setPromotionIsNotAvailable } = promotionsStore;

  const actionUrl = ref('');
  const isDoingActionButton = ref(false);

  const currentPromoLeaderboardDate = computed(() => {
    if (currentPromotion.value?.activeTournamentStartDate && currentPromotion.value.activeTournamentEndDate) {
      return `
      ${$translate('WEB2_TOURNAMENT').value}:
      ${DateTime.formatTimeStamp(Date.parse(currentPromotion.value.activeTournamentStartDate), TimeFormats.dayMonthShortYear, locale.value)} -
      ${DateTime.formatTimeStamp(Date.parse(currentPromotion.value.activeTournamentEndDate), TimeFormats.dayMonthShortYear, locale.value)}`;
    }
    return '';
  });

  const leaderBoard = computed<LeaderBoardParticipant[]>(() => {
    const url = currentPromotion.value?.actionUrl || actionUrl.value;
    if (!url) {
      return [];
    }
    return getLeaderBoardList(url, leaderBoardListMap.value);
  });
  const haveLeaderBoard = computed(() => leaderBoard.value.length > 0);
  const isLeaderBoardPromo = computed(() => (
    currentPromotion.value?.promotionType === PromoActionType.LEADERBOARD_EG
    || currentPromotion.value?.promotionType === PromoActionType.LEADERBOARD_SPORTS
  ));
  const leaderBoardProperties = computed<LeaderBoardProps | undefined>(() => {
    const url = currentPromotion.value?.actionUrl || actionUrl.value;
    if (!url) {
      return undefined;
    }
    const options = getLeaderBoardOptions(
      url,
      url,
      leaderBoardOptionsMapRecord.value,
    );

    return {
      list: leaderBoard.value,
      endOfList: options?.nextPage === 0,
      isLoading: options?.isLoading,
      customerPosition: options?.customerPosition ?? undefined,
    };
  });

  function goToCustomLink(): void {
    const customLink = promotionButton.value?.customLink;
    if (customLink) {
      if (process.env.VUE_APP_FEATURE_APP_DOWNLOAD_ENABLED && useAppDownloadStore().isAppDownloadUrl(customLink)) {
        window.location.href = customLink;
      } else {
        void router.push(customLink);
      }
    }
  }

  function goToPlay(): void {
    if (currentPromotion.value?.promotionType === PromoActionType.FORTUNE_WHEEL) {
      fortuneWheelStore.setFortuneWheelId(fortuneWheelId.value || 0);
      void router.push({ name: RouteName.FORTUNE_WHEEL });
    } else if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      void router.push({ name: CasinoRouteName.CASINO_LOBBY });
    }
  }

  function goToIdentification(): void {
    void router.push({ name: RouteName.IDENTIFICATION });
  }

  async function doButtonAction(): Promise<void> {
    const { actionButton, actionUrl: url } = currentPromotion.value || {};
    if (actionButton && url) {
      isDoingActionButton.value = true;

      await promotionsStore.doButtonMutationAction({
        actionUrl: url,
        buttonType: actionButton.buttonType,
      });
      isDoingActionButton.value = false;
    } else {
      logger.error('No actionButton');
    }
  }

  async function loadMore(id?: number): Promise<void> {
    const url = currentPromotion.value?.actionUrl || actionUrl.value || '';
    await leaderBoardStore.getLeaderBoard({
      actionUrl: url,
      id: id ? String(id) : url,
    });
  }

  function scrollToPosition(element: PromotionScrollableElement, scrollTop?: number): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } else {
      const top = scrollTop || element.getOffsetTop();
      bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, { scrollTop: top, smooth: true });
    }
  }

  function scrollToLeaderBoard(): void {
    if (!board.value) {
      return;
    }
    const headerHeight = 60;
    scrollToPosition(board.value, board.value.getOffsetTop() - headerHeight);
  }

  function scrollToTerms(): void {
    if (!terms.value) {
      return;
    }
    const headerHeight = 60;
    scrollToPosition(terms.value, terms.value.getOffsetTop() - headerHeight);
  }

  function doDeposit(): void {
    if (currentPromotion.value?.actionId) {
      bonusStore.setBonusSelection(currentPromotion.value.actionId);
    }
    void router.push({ name: RouteName.DEPOSITS });
  }

  onBeforeMount(() => {
    if (!promotionsLoaded.value && customerCategory.value !== null) {
      void promotionsStore.fetchPromotions(customerCategory.value);
    }
  });

  onBeforeUnmount(() => {
    leaderBoardStore.resetLeaderBoard();
  });

  return {
    board,
    isLeaderBoardPromo,
    actionUrl,
    currentPromotion,
    currentPromoLeaderboardDate,
    haveLeaderBoard,
    leaderBoardProperties,
    doButtonAction,
    scrollToLeaderBoard,
    goToCustomLink,
    loadMore,
    goToPlay,
    goToIdentification,
    getLeaderBoard,
    leaderBoard,
    loadPromotionDetails,
    setOpenedActiveUrl,
    promotionButton,
    isDoingActionButton,
    terms,
    scrollToTerms,
    doDeposit,
    setPromotionIsNotAvailable,
  };
}
