<script setup lang="ts">
import type { SButtonKind } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';

import type { ConfirmEmailEmits, ConfirmEmailProps } from './types';
import { ConfirmEmailStage } from '../../enums';
import useConfirmEmail from './composables/useConfirmEmail';

const props = withDefaults(defineProps<ConfirmEmailProps>(), {
  stage: ConfirmEmailStage.CONFIRM_SUCCESS,
  email: '',
});
defineEmits<ConfirmEmailEmits>();

defineSlots<{ 'email-form'(props: Record<string, never>): void }>();

const {
  isSuccess,
  jumbotronProps,
  isErrorViewVisible,
  withSupportTranslation,
  isLoaderVisible,
  confirmButtonsVisible,
  closeButtonVisible,
  confirmButtonLabel,
} = useConfirmEmail(props);

const closeButtonKind: ButtonKind | SButtonKind = null ? 'tertiary-primary' : ButtonKind.TRANSPARENT;
</script>

<template>
  <VLoaderDelayed
    v-if="isLoaderVisible"
    :hint="$t('WEB2_PLEASE_WAIT_TEXT')"
  />
  <VJumbotron
    v-else
    v-bind="jumbotronProps"
    :class="{
      [$style['confirm-email']]: true,
      [$style['confirm-email--success']]: isSuccess,
    }"
  >
    <template #default>
      <template v-if="stage === ConfirmEmailStage.EMAIL_UNKNOWN">
        <span>
          {{ $t('WEB2_CONFIRM_EMAIL_ENTER_MAIL') }}
        </span>
        <div :class="$style['confirm-email__form']">
          <slot name="email-form" />
        </div>
      </template>
      <template v-else-if="stage === ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED">
        <TextSlotPlaceholder
          :class="$style['confirm-email__label']"
          :text="$t('WEB2_CONFIRM_EMAIL_FOLLOW_LINK')"
        >
          <template #email>
            <span :class="$style['confirm-email__bright']">{{ email }}</span>
          </template>
        </TextSlotPlaceholder>
      </template>
      <template v-else-if="stage === ConfirmEmailStage.WAIT_FOR_CONFIRMATION">
        <template
          v-if="null"
        >
          <TextSlotPlaceholder
            :class="$style['confirm-email__label']"
            :text="$t('WEB2_CONFIRM_EMAIL_SENT')"
          >
            <template #email>
              <span :class="$style['confirm-email__bright']">{{ email }}</span>
            </template>
          </TextSlotPlaceholder>
          <TextSlotPlaceholder
            :class="$style['confirm-email__label']"
            :text="$t('RESTORE_PASSWORD_LETTER_RESEND')"
          />
        </template>
        <template v-else>
          <TextSlotPlaceholder
            :class="$style['confirm-email__label']"
            :text="$t('WEB2_CONFIRM_EMAIL_SENT')"
          >
            <template #email>
              <span :class="$style['confirm-email__bright']">{{ email }}</span>
            </template>
          </TextSlotPlaceholder>
          <br><br>
          <TextSlotPlaceholder
            :class="$style['confirm-email__label']"
            :text="$t('WEB2_CONFIRM_EMAIL_SPAM')"
          >
            <template #retry>
              <span :class="$style['confirm-email__button-container']">
                <button
                  type="button"
                  :class="[
                    $style['confirm-email__inline-button'],
                    $style['confirm-email__bright'],
                  ]"
                  @click="$emit('send-again')"
                >
                  {{ $t('WEB2_CONFIRM_EMAIL_RETRY') }}
                </button>.
              </span>
            </template>
          </TextSlotPlaceholder>
        </template>
      </template>
      <template v-else-if="stage === ConfirmEmailStage.CONFIRM_SUCCESS">
        {{ null ? $t('WEB2_CONFIRM_SUBSCRIPTION_SUCCESS_TEXT') : $t('WEB2_CONFIRM_EMAIL_SUCCESS_TEXT') }}
      </template>
      <template
        v-else-if="isErrorViewVisible"
      >
        <TextSlotPlaceholder
          :class="$style['confirm-email__label']"
          :text="withSupportTranslation"
        >
          <template #support>
            <button
              type="button"
              :class="$style['confirm-email__inline-button']"
              @click="$emit('get-support')"
            >
              {{ $t('WEB2_CONFIRM_EMAIL_MANY_REQUESTS_SUPPORT') }}
            </button>
          </template>
        </TextSlotPlaceholder>
      </template>
    </template>
    <template #footer>
      <template v-if="closeButtonVisible">
        <JumbotronFooterButton
          v-data-test="{ el: 'button-action-close' }"
          full-width
          size="large"
          :kind="ButtonKind.BASE"
          :label="$t('WEB2_CLOSE')"
          @click="$emit('close')"
        />
      </template>
      <template v-if="confirmButtonsVisible">
        <JumbotronFooterButton
          v-data-test="{ el: 'button-action-confirm' }"
          :label="confirmButtonLabel"
          :kind="ButtonKind.PRIMARY"
          full-width
          :is-loading="isPending"
          :disabled="confirmDisabled"
          @click="$emit('confirm')"
        />
        <JumbotronFooterButton
          v-data-test="{ el: 'button-action-cancel' }"
          :kind="closeButtonKind"
          full-width
          :disabled="isPending"
          :label="null ? $t('WEB2_CLOSE') : $t('WEB2_MOBILE_CANCEL')"
          @click="$emit('close')"
        />
      </template>
      <template v-if="null && stage === ConfirmEmailStage.WAIT_FOR_CONFIRMATION">
        <JumbotronFooterButton
          :label="$t('WEB2_CONFIRM_EMAIL_REPEATE_REQUEST')"
          full-width
          @click="$emit('send-again')"
        />
      </template>
    </template>
  </VJumbotron>
</template>

<style lang="scss" module>
.confirm-email {
  padding: $confirmEmailPaddind;

  &--success {
    color: var(--BrandDefault);
  }

  &__form {
    margin-top: 8px;
  }

  &__label {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
  }

  &__button-container {
    white-space: nowrap;
  }

  &__inline-button {
    display: inline;
    padding: 0;
    color: var(--TextPrimary);
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    border: 0;

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
      }
    }
  }

  &__bright {
    color: $confirmEmailBrightText;
  }
}
</style>
