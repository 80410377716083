import type { Ref } from 'vue';
import {
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue';

import type { StakeInputProps } from './types';

interface UseStakeInputLabel {
  widthMeter: Ref<HTMLElement | undefined>;
  isLabelHidden: Ref<boolean>;
}

export default function useStakeInputLabel(
  currentValue: Ref<string>,
  props: StakeInputProps,
): UseStakeInputLabel {
  const isLabelHidden = ref<boolean>(false);

  const widthMeter = ref<HTMLElement | undefined>();

  const checkIsPlaceEnoughForLabel = (): void => {
    if (widthMeter.value) {
      const { offsetWidth, scrollWidth } = widthMeter.value;
      isLabelHidden.value = scrollWidth > offsetWidth;
    }
  };

  watch(() => currentValue.value, () => {
    nextTick().then((checkIsPlaceEnoughForLabel));
  });

  onMounted(checkIsPlaceEnoughForLabel);

  watch(() => props.betMode, (value) => {
    if (value) {
      checkIsPlaceEnoughForLabel();
    }
  });

  return {
    widthMeter,
    isLabelHidden,
  };
}
