import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import type {
  CoreSportlineFetchOptions,
  EventsChangesFetchOptions,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getRelatedEvents } from 'web/src/modules/sportline/utils/api';

interface UseSportlineApiLoadRelatedEventsComposable {
  loadRelatedEvents(options: CoreSportlineFetchOptions & EventsChangesFetchOptions & {
    eventId: string;
  }): Promise<GetSportEventsResponse>;
}

export function useSportlineApiLoadRelatedEvents(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadRelatedEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  async function loadRelatedEvents(options: CoreSportlineFetchOptions & EventsChangesFetchOptions & {
    eventId: string;
  }): Promise<GetSportEventsResponse> {
    const { eventId, vTag, silent } = options;
    let result: Optional<GetSportEventsResponse>;

    if (vTag) {
      result = await tryRequestWithCTag((ctag) => getRelatedEvents({
        eventId,
        betline: BetlineType.All,
        ctag,
        vtag: vTag,
        hideClosed: true,
        silent,
        flags: [...defaultRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
      }));
    }

    if (!result || result.vtag === 'invalid') {
      result = await tryRequestWithCTag((ctag) => getRelatedEvents({
        eventId,
        betline: BetlineType.All,
        ctag,
        hideClosed: true,
        silent,
        flags: [...defaultRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
      }));
    }

    // @see getRelatedEvents (always return value)
    return result ?? { totalCount: 0, events: [] };
  }

  return { loadRelatedEvents };
}
