<script setup lang="ts">
import { onBeforeMount } from 'vue';

import { ButtonHeight, ButtonKind, LButton, SButton } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';

import VFeedbackComposition from 'web/src/components/FeedbackComposition/VFeedbackComposition/VFeedbackComposition.vue';
import LikeFavorite from 'web/src/components/LikeFavorite/LikeFavorite/LikeFavorite.vue';
import PromotionRateButton from 'web/src/modules/promotions/components/PromotionRateButton/PromotionRateButton.vue';

import DefaultModal from '../../../../components/Modal/DefaultModal/DefaultModal.vue';
import { usePromotionRate } from './composables';

const {
  isPromotionsLikeEnabled,
  isRateFeedBackVisible,
  isLikePressed,
  isDislikePressed,
  isRateButtonEnabled,
  isRateFeedBackModal,
  modalSelector,
  promotionLikes,
  feedbackCountdown,
  feedbackFormMaxChars,
  submitting,
  haveValidEmailValue,
  isApiErrorEmailMessage,
  savedErrorDuplicateEmail,
  beforeMount,
  handleLike,
  handleDislike,
  feedbackRate,
  onRateFeedbackSubmit,
  onCloseRateFeedback,
} = usePromotionRate();

onBeforeMount(beforeMount);
</script>

<template>
  <div
    v-if="isPromotionsLikeEnabled"
    :class="$style['promotion-rate']"
  >
    <div
      v-if="isRateFeedBackVisible && feedbackCountdown > 0"
      :class="$style['promotion-rate__button-feedback']"
    >
      <SButton
        v-if="null"
        :label="`${$t('WEB2_RATE_PROMOTION')} (${String(feedbackCountdown)})`"
        size="small"
        kind="tertiary-secondary"
        :class="$style['promotion-details-title__rate-button-feedback']"
        @click="feedbackRate"
      />
      <LButton
        v-else
        :label="`${$t('WEB2_RATE_PROMOTION')} (${String(feedbackCountdown)})`"
        :height="ButtonHeight.TINY"
        :kind="ButtonKind.MONOCHROME"
        :is-uppercase="false"
        :class="$style['promotion__rate-button-feedback']"
        @click="feedbackRate"
      />
    </div>
    <div
      v-if="null"
      :class="$style['promotion-rate__buttons']"
    >
      <LikeFavorite
        icon-type="likes"
        :counter="promotionLikes"
        :is-active="isLikePressed"
        @click="handleLike"
      />
      <LikeFavorite
        icon-type="dislikes"
        :is-active="isDislikePressed"
        @click="handleDislike"
      />
    </div>
    <div
      v-else
      :class="$style['promotion-rate__buttons']"
    >
      <PromotionRateButton
        is-like-button
        :likes="promotionLikes"
        :is-pressed="isLikePressed"
        :disabled="!isRateButtonEnabled"
        @click="handleLike"
      />
      <PromotionRateButton
        :is-pressed="isDislikePressed"
        :disabled="!isRateButtonEnabled"
        @click="handleDislike"
      />
    </div>
  </div>
  <Teleport
    v-if="isRateFeedBackModal"
    :to="modalSelector"
  >
    <DefaultModal
      :is-alert="!!'1'"
      :width="ModalWidth.SMALL"
      :top-bar-text="$t('WEB2_RATE_PROMOTION_FEEDBACK')"
      :buttons="[]"
      :is-centered="!'1'"
      :is-full-height="!'1'"
      :is-full-height-centered="false"
      @close="onCloseRateFeedback"
    >
      <template #content>
        <VFeedbackComposition
          :feedback-form-max-chars="feedbackFormMaxChars"
          :have-valid-email-value="haveValidEmailValue"
          :submitting="submitting"
          :is-api-error-email-message="isApiErrorEmailMessage"
          :saved-error-duplicate-email="savedErrorDuplicateEmail"
          @submit="onRateFeedbackSubmit"
        >
          <div>
            {{ $t('WEB2_RATE_PROMOTION_FEEDBACK_DESCRIPTION_1') }}
          </div>
          <br>
          <div>
            {{ $t('WEB2_RATE_PROMOTION_FEEDBACK_DESCRIPTION_2') }}
          </div>
        </VFeedbackComposition>
      </template>
    </DefaultModal>
  </Teleport>
</template>

<style lang="scss" module>
.promotion-rate {
  position: relative;

  &__button-feedback {
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: $promotionRateButtonFeedBackMarginBottom;
    white-space: nowrap;
  }

  &__buttons {
    display: flex;
    gap: $promotionRateGap;
  }
}
</style>
