import type { Ref } from 'vue';
import { computed } from 'vue';

import { PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ButtonKind } from '@components/buttons';

import type { PromotionDetailsDescriptionProps } from '../types';

export interface PromotionDetailsDescriptionComposable {
  parsedDescription: Ref<string>;
  openSharedInfo(message: string): void;
}

export default function usePromotionDetailsDescription(props: PromotionDetailsDescriptionProps): PromotionDetailsDescriptionComposable {
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();

  const parsedDescription = computed(() => {
    const descriptionString = props.description || '';
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(descriptionString, 'text/html');
    const body = parsedDocument.querySelectorAll('body')[0];
    return body ? body.innerHTML : descriptionString;
  });

  function openSharedInfo(message: string): void {
    showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        confirmMessage: message,
        buttons: [
          {
            kind: process.env.VUE_APP_LAYOUT_PHONE ? ButtonKind.SECONDARY : ButtonKind.SECONDARY_DARK,
            label: $translate('WEB2_CLOSE').value,
          },
        ],
        dataTestId: 'open-shared-info',
      },
    });
  }

  return {
    parsedDescription,
    openSharedInfo,
  };
}
