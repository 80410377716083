import { toRef } from 'vue';

import {
  ApiConnectionError,
  ApiServiceUnavailableError,
  GqlApiAccessDeniedError,
} from '@leon-hub/api';
import { SentryCordova } from '@leon-hub/cordova';
import { AbstractError, normalizeError } from '@leon-hub/errors';
import { isOpenSearchLoggableError, isSentryLoggableError } from '@leon-hub/errors-whitelist';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';
import { captureException } from '@leon-hub/sentry';
import { hashString } from '@leon-hub/utils';
import { Events as AnalyticsEvent, TargetParamEnter } from '@leon-hub/yandex-metrika';

import type { ShowDialogOptions } from '@core/dialogs';
import type { I18nFunctionReactive } from '@core/i18n';
import type { AppVueRouter } from '@core/router';
import { useAnalytics } from '@core/analytics';
import { useAuthStore, useIsLoggedIn } from '@core/auth';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';

import { ModalWidth } from '@components/dialogs';

import { AccessDeniedModalId } from 'web/src/modules/dialogs/consts';
import { getImageOrIcon } from 'web/src/modules/icons';

import { accessDeniedErrorCode, whiteListErrorCodes } from './constants';
import {
  getErrorCode,
  getModalId,
  isMutationRequestError,
  isSilentError,
  logError,
} from './utils';

export interface UseErrorHandler {
  handleError(error: unknown): void;
}

function generateDialogOptions(error: Error, $translate: I18nFunctionReactive): ShowDialogOptions['options'] {
  let options: ShowDialogOptions['options'] = { confirmMessage: error.message };
  if (error instanceof GqlApiAccessDeniedError) {
    options = {
      iconName: IconName.CLOCK,
      confirmMessage: $translate('WEB2_ACCESS_DENIED_ERROR_DESCRIPTION').value,
      title: $translate('WEB2_ACCESS_DENIED_ERROR_TITLE').value,
      buttons: [{ label: $translate('WEB2_ACCESS_DENIED_ERROR_BUTTON').value }],
      width: ModalWidth.MEDIUM,
      image: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? getImageOrIcon({ alertIcon: AlertIconName.Sandglass }).image
        : undefined,
    };
  } else if (error instanceof ApiConnectionError) {
    options.confirmMessage = $translate('CONNECTION_ERROR').value;
  }

  return options;
}

export default function useErrorHandler(router: AppVueRouter): UseErrorHandler {
  const { showDialog } = useDialogs();
  const analytics = useAnalytics();
  const { $translate } = useI18n();
  const { isLoggedIn } = useIsLoggedIn();
  const authStore = useAuthStore();
  const isTemporarySession = toRef(authStore, 'isTemporarySession');
  const isSolidAuth = toRef(() => isLoggedIn.value && !isTemporarySession.value);

  function showErrorDialog(error: Error, options: ShowDialogOptions['options']) {
    const modalId = getModalId(error, options);
    const errorCode = getErrorCode(error);
    const routeName = router.getName();

    showDialog({
      id: modalId,
      presetName: PresetName.ALERT_ERROR,
      options,
    }).subscribe({
      [DialogAction.MODAL_CLOSE]: () => {
        if (errorCode === accessDeniedErrorCode) {
          analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
              enter: TargetParamEnter.SESSION_EXPIRED,
            },
          });
          void router.push({ name: RouteName.LOGIN });
        }
        if (routeName === RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS) {
          void router.push({ name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY });
        }
      },
    });
  }

  function showError(error: Error): void {
    const errorCode = getErrorCode(error);
    const options = generateDialogOptions(error, $translate);
    const modalId = error instanceof GqlApiAccessDeniedError ? AccessDeniedModalId : hashString(`GlobalError${errorCode}-${options.confirmMessage}`);

    if (error instanceof GqlApiAccessDeniedError) {
      options.iconName = IconName.CLOCK;
      options.confirmMessage = $translate('WEB2_ACCESS_DENIED_ERROR_DESCRIPTION').value;
      options.title = $translate('WEB2_ACCESS_DENIED_ERROR_TITLE').value;
      options.buttons = [{
        label: $translate('WEB2_ACCESS_DENIED_ERROR_BUTTON').value,
      }];
      options.width = ModalWidth.MEDIUM;
      options.image = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? getImageOrIcon({
          alertIcon: AlertIconName.Sandglass,
        }).image
        : undefined;
    }

    options.dataTestId = modalId;

    showErrorDialog(error, options);
  }

  function handleError(rawErr: unknown): void {
    const error = rawErr
      ? useErrorsConverter().convertToBaseError(normalizeError(rawErr))
      : new Error(`Unable to handle error: ${rawErr}`);

    if (error instanceof GqlApiAccessDeniedError) {
      if (isSolidAuth.value) {
        error.silent = false; // always display "Session expired" error
        authStore.forgotSession();
      } else {
        return;
      }
    }

    // Connection error is displayed by web/src/modules/snackbars/store/useSnackbarsStore.ts#showConnectionError
    if (!isSilentError(error) && !(error instanceof ApiConnectionError)) {
      handleNonSilentError(error);
    }

    log(error);
  }

  function handleNonSilentError(error: Error): void {
    if (error instanceof ApiServiceUnavailableError && !isMutationRequestError(error)) {
      void router.push({
        name: RouteName.ERROR_SERVICE_UNAVAILABLE_503,
      });

      return;
    }

    if (error.message && !whiteListErrorCodes.includes(getErrorCode(error))) {
      showError(error);
    }
  }

  function log(error: Error): void {
    if (isOpenSearchLoggableError(error)) {
      logError(error);
    }

    if (isSentryLoggableError(error)) {
      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        SentryCordova.captureException(error);
      } else {
        captureException(error, {
          level: 'error',
          tags: {
            errorCode: error instanceof AbstractError ? error.code.toString() : 'UNKNOWN',
          },
        });
      }
    }
  }

  return {
    handleError,
  };
}
