import { defineStore } from 'pinia';
import {
  computed,
  ref,
  watch,
} from 'vue';

import { setDowntimeShown } from '@leon-hub/api-sdk';
import { DateTimeInterval, Timer } from '@leon-hub/utils';

import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';

import { useDowntimeConfig } from 'web/src/modules/core/composables/site-config';

const useDowntimeAlertStore = defineStore('downtime-alert', () => {
  const api = useGraphqlClient();
  const { isLoggedIn } = useIsLoggedIn();

  const { enabled, interval, visibleBeforeHours } = useDowntimeConfig();

  const isShown = ref(false);
  const currentTimestamp = ref(Date.now());

  const isAlertVisible = computed(() => {
    if (!enabled.value
      || interval.value.shown
      || isShown.value
      || currentTimestamp.value > interval.value.to) {
      return false;
    }

    return visibleBeforeHours.value >= 0
      ? DateTimeInterval.fromNowPlusHours(
        visibleBeforeHours.value,
      ).to.toTimestamp() >= interval.value.from
      : true;
  });

  const downtimeFinish = computed(() => interval.value.to);
  const downtimeStart = computed(() => interval.value.from);

  let timer = 0;

  function setCurrentTimestamp(): void {
    currentTimestamp.value = Date.now();
  }

  function clearTimer() {
    if (timer > 0) {
      Timer.clearTimeout(timer);
      timer = 0;
    }
  }

  function startTimer(): void {
    setCurrentTimestamp();
    clearTimer();

    if (downtimeFinish.value > 0) {
      timer = Timer.setTimeout(() => {
        setCurrentTimestamp();
        clearTimer();
      }, downtimeFinish.value - Date.now());
    }
  }

  async function setIsShown(): Promise<void> {
    isShown.value = true;
    await setDowntimeShown(
      api,
      (node) => node.mutations.cookies.setDowntimeShown,
      {
        options: {
          timestamp: downtimeFinish.value,
        },
      },
    );
  }

  watch(downtimeFinish, startTimer, {
    immediate: true,
  });

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      setCurrentTimestamp();
    }
  });

  return {
    isAlertVisible,
    downtimeFinish,
    downtimeStart,
    setIsShown,
    clearTimer,
  };
});

export default useDowntimeAlertStore;
