import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import type {
  IValidationSchema,
} from '@leon-hub/api-sdk';
import type { FormSchema } from '@leon-hub/form-utils';
import {
  getFormSchema,
  validationSchemaChangePassword,
  validationSchemaCustomerLimits,
  validationSchemaRestorePasswordByEmail,
  validationSchemaRestorePasswordByPhone,
  validationSchemaRestorePasswordSendLinkToEmail,
  validationSchemaRestorePasswordSendSmsToPhone,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

const useFormsStore = defineStore('forms', () => {
  const api = useGraphqlClient();

  const schemas = ref<Record<string, FormSchema>>({});

  const restoreBySendSms = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordSendSmsToPhone]);

  const restoreBySendLinkToEmail = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordSendLinkToEmail]);

  const restoreByEmail = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordByEmail]);

  const restoreByPhone = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordByPhone]);

  const customerLimitsSchema = computed<FormSchema | undefined>(() => schemas.value[validationSchemaCustomerLimits]);

  const changePasswordSchema = computed<FormSchema | undefined>(() => schemas.value[validationSchemaChangePassword]);

  async function fetchSchema(key: IValidationSchema): Promise<void> {
    const result = await getFormSchema(api, (node) => node.queries.system.schemaContent, { options: { schema: key } });

    if (result) {
      schemas.value = {
        ...schemas.value,
        [key]: JSON.parse(result.content),
      };
    }
  }

  return {
    restoreBySendSms,
    restoreBySendLinkToEmail,
    restoreByEmail,
    restoreByPhone,
    customerLimitsSchema,
    changePasswordSchema,
    fetchSchema,
  };
});

export default useFormsStore;
