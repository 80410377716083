<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';

import { HighlightedText } from '@components/highlighted-text';
import { VIcon } from '@components/v-icon';

import type {
  SearchListItemEmits,
  SearchListItemProps,
} from './types';
import { useSearchListItem } from './composables';

const props = withDefaults(defineProps<SearchListItemProps>(), {
  href: '',
  searchText: '',
});
const emits = defineEmits<SearchListItemEmits>();

const {
  onClick,
  date,
  time,
  streamIconProps,
} = useSearchListItem(props, emits);
</script>

<template>
  <li v-auto-id="'SearchListItem'"
    :class="$style['search-item']"
  >
    <a
      :class="$style['search-item__link']"
      :href="href"
      @click.prevent="onClick"
    >
      <div :class="$style['search-item__status']">
        <template v-if="liveScore">
          <div
            :class="{
              [$style['search-item__text--medium']]: true,
              [$style['search-item__score']]: true,
            }"
          >{{ liveScore }}
          </div>
          <div
            :class="$style['search-item__progress']"
          >{{ liveProgress }}
          </div>
        </template>
        <template v-else>
          <div
            :class="{
              [$style['search-item__text--medium']]: true,
              [$style['search-item__date']]: true,
            }"
          >{{ date }}
          </div>
          <div
            :class="$style['search-item__time']"
          >{{ time }}
          </div>
        </template>
      </div>
      <div :class="$style['search-item__main']">
        <HighlightedText
          :class="[
            $style['search-item__text--medium'],
            $style['search-item__name'],
          ]"
          :root-tag="Tag.DIV"
          :text="name"
          :query="searchText"
          exploded
        />
        <div :class="$style['search-item__info']">
          <div>
            <HighlightedText
              :class="$style['search-item__sport']"
              :text="`${sportName}&nbsp;`"
              :query="searchText"
              exploded
            />
            <HighlightedText
              :class="$style['search-item__region']"
              :text="`${regionName}&nbsp;`"
              :query="searchText"
              exploded
            />
            <HighlightedText
              :class="$style['search-item__league']"
              :text="leagueName"
              :query="searchText"
              exploded
            />
          </div>
          <VIcon
            v-if="streamAvailable"
            v-bind="streamIconProps"
            :class="$style['search-item__stream']"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<style lang="scss" module>
.search-item {
  list-style: none;

  &__link {
    display: flex;
    align-items: center;
    padding: 8px;
    text-decoration: none;
    background: var(--Layer1);
    border-radius: var(--BorderRadius);

    @include is-app-layout-desktop {
      &:hover {
        background: var(--Highlight);
      }
    }
  }

  &__status {
    width: 48px;
    text-align: center;
  }

  &__text--medium {
    @include medium\14\20\025;
  }

  &__score {
    margin-bottom: 4px;
    color: var(--BrandDefault);
  }

  &__progress,
  &__time {
    @include medium\12\16;

    color: var(--TextPrimary);
  }

  &__main {
    flex: 1;
    margin-left: 16px;
  }

  &__name,
  &__date {
    margin-bottom: 4px;
    color: var(--TextDefault);
  }

  &__info {
    @include medium\12\16;

    display: flex;
    color: var(--TextSecondary);
  }

  &__sport {
    font-weight: 400;
  }

  &__region {
    color: var(--TextPrimary);
  }

  &__league {
    color: var(--TextDefault);
  }

  &__stream {
    margin-left: 8px;
  }
}
</style>
