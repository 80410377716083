<script lang="ts">
import type {
  PropType,
  VNode,
} from 'vue';
import {
  cloneVNode,
  computed,
  defineComponent,
  h,
  nextTick,
  normalizeClass,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  provide,
  ref,
  useCssModule,
  watch,
  withDirectives,
} from 'vue';
import { ObserveVisibility } from 'vue3-observe-visibility';

import { useWindowResize } from '@leon-hub/browser-composables';
import { useDebounce } from '@leon-hub/debounce';
import {
  assert,
} from '@leon-hub/guards';
import { getPixelRatio, Timer } from '@leon-hub/utils';

import { useSwiperManualActiveSlide } from 'web/src/components/Swiper/VSwiper/composables';

import type {
  VSwiperOuterPadding,
  VSwiperTopMargin,
} from '../VSwiper/enums';
import type {
  VSlidesVisibilityLimits,
  VSwiperSlideToProperties,
  VSwiperState,
  VSwiperTouchData,
  VSwiperWrapperProperties,
} from '../VSwiper/types';
import type { SwiperSlideVNode } from '../VSwiper/utils/isSwiperSlideVNode';
import {
  VSwipeDirection,
  VSwiperProvidableKeys,
} from '../VSwiper/enums';
import {
  isSlideVisibleInContainer,
  isSwiperSlideVNode,
} from '../VSwiper/utils';

function shouldSlideBeVisible(limits: VSlidesVisibilityLimits, slide?: SwiperSlideVNode): boolean {
  if (slide) {
    const offsetWidth = slide.component?.exposed?.getOffsetWidth?.() || 0;
    const offsetLeft = slide.component?.exposed?.getOffsetLeft?.() || 0;

    return limits.min <= offsetLeft + offsetWidth && limits.max >= offsetLeft;
  }

  return false;
}

const SWIPER_OPTIONS = {
  bounce: {
    minVelocity: 0.3,
    momentumRatio: 800,
    durationRatio: 0.7,
    momentumVelocityRatio: 0.6,
  },
  visibility: {
    offsetRatio: process.env.VUE_APP_LAYOUT_DESKTOP ? 0.3 : 0.7,
  },
  navigation: {
    visibleSlideSpeed: 600,
  },
  overscroll: {
    durationRatio: 2,
    maxDuration: 1000,
    maxOffsetRatio: process.env.VUE_APP_LAYOUT_DESKTOP ? 0 : 0.4,
    maxWidth: process.env.VUE_APP_LAYOUT_DESKTOP ? 0 : 68,
    minMouseMove: 10,
    minSwipe: 80,
  },
  autoScroll: {
    transitionOffset: process.env.VUE_APP_LAYOUT_DESKTOP ? 1000 : 400,
    durationRatio: 100,
    startTimeout: 600,
    customerSwipingTimeout: 3000,
  },
  loop: {
    additionalSlidesCoefficient: 4,
  },
};

function getAvailableOverscrollWidth(offsetWidth: number): number {
  const width = offsetWidth * SWIPER_OPTIONS.overscroll.maxOffsetRatio;

  return width > SWIPER_OPTIONS.overscroll.maxWidth
    ? SWIPER_OPTIONS.overscroll.maxWidth
    : width;
}

export default defineComponent({
  name: 'VSwiperTranslate',
  props: {
    isAutoScroll: {
      type: Boolean,
    },
    isScrollSnapEnabled: {
      type: Boolean,
    },
    isBlock: {
      type: Boolean,
    },
    isInfiniteLoop: {
      type: Boolean,
    },
    isFullHeight: {
      type: Boolean,
    },
    isOverflowVisible: {
      type: Boolean,
    },
    outerPadding: {
      type: String as PropType<VSwiperOuterPadding>,
    },
    topMargin: {
      type: String as PropType<VSwiperTopMargin>,
    },
    slideVisibilityRate: {
      type: Number,
    },
    // TODO: find better union types for functional component

    wrapperClass: {},

    eventContainerClass: {},
  },
  emits: ['scroll', 'mouseenter', 'mouseleave'],

  setup(props, { emit, slots, expose }) {
    const $style = useCssModule();
    const className = computed(() => ({
      swiper: true,
      [$style.swiper]: true,
      [$style['swiper--full-height']]: props.isFullHeight,
      [$style['swiper--block']]: props.isBlock,
      [$style['swiper--overflow-visible']]: props.isOverflowVisible,
      [$style[`swiper--outer-padding-${props.outerPadding}`]]: !!props.outerPadding,
      [$style[`swiper--top-margin-${props.topMargin}`]]: !!props.topMargin,
    }));

    const swiperState = {
      isSwiping: ref(false),
      slidesCounter: ref(0),
      activeSlideIndex: ref(0),
      isPreviousButtonAllowed: ref(false),
      isNextButtonAllowed: ref(false),
    } as VSwiperState;

    const stateTranslateX = ref(0);
    const isMarginReached = ref(false);
    const swiperWrapperReference = ref<HTMLDivElement>();
    const swiperReference = ref<HTMLDivElement>();
    const prependSlidesCounter = ref(0);
    const appendSlidesCounter = ref(0);
    const isSlideToNextSlide = ref(false);
    const slideDragPosStart = ref(0);

    const {
      manualActiveSlide,
      manualChangeSlideActiveState,
    } = useSwiperManualActiveSlide();

    let swipeInitData: VSwiperTouchData | null = null;
    let swipeCurrentData: VSwiperTouchData | null = null;
    let isTouchEventStarted = false;
    let overscrollCheckTimeout = 0;
    let isVerticalSwipe = false;
    let startAutoScrollTimeout = 0;
    let customerSwipingAutoScrollTimeout = 0;
    let isAutoScrollStarted = false;
    let isAutoScrollReversed = false;
    let isLoopInitialized = false;
    let isTransitionInProgress = false;
    let visibleSlideIndexes: number[] = [];
    let resizeObserver: MutationObserver | null = null;
    let touchIdentifier: null | number = null;

    let defaultSlides = slots.default?.({
      manualChangeSlideActiveState,
    }) ?? [];
    let prependSlides = preparePrependSlides();
    let appendSlides = prepareAppendSlides();

    function getWrapperProperties(): VSwiperWrapperProperties {
      let offsetWidth = 0;
      let scrollWidth = 0;
      let left = 0;
      let paddingLeft = 0;
      let paddingRight = 0;

      if (swiperWrapperReference.value) {
        const boundingRect = swiperWrapperReference.value.getBoundingClientRect();

        offsetWidth = swiperWrapperReference.value.offsetWidth;
        scrollWidth = swiperWrapperReference.value.scrollWidth;
        left = boundingRect.left;

        const styles = window.getComputedStyle(swiperWrapperReference.value, null);
        paddingLeft = Number.parseInt(styles.getPropertyValue('padding-left'), 10);
        paddingRight = Number.parseInt(styles.getPropertyValue('padding-right'), 10);
      }

      return {
        minTranslateX: -scrollWidth + offsetWidth,
        offsetWidth,
        scrollWidth,
        left,
        paddingLeft,
        paddingRight,
      };
    }

    function cloneSwiperSlide(vNode: SwiperSlideVNode, keySuffix: string, originalSlidesIndex: number): SwiperSlideVNode {
      return cloneVNode(vNode, {
        key: vNode.key ? `${String(vNode.key)}-${keySuffix}` : undefined,
        originalSlidesIndex,
      }) as SwiperSlideVNode;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function preparePrependSlides(): SwiperSlideVNode[] {
      const slides: SwiperSlideVNode[] = [];

      if (props.isInfiniteLoop && prependSlidesCounter.value > 0) {
        let counter = prependSlidesCounter.value;
        let doLoop = true;
        let loopIndex = 0;
        const slideEntries = getSlides().reverse();
        if (slideEntries.length > 0) {
          do {
            for (const [index, slide] of slideEntries.entries()) {
              slides.push(cloneSwiperSlide(slide, `prepend-${loopIndex}`, slideEntries.length - 1 - index));
              counter -= 1;

              if (counter === 0) {
                doLoop = false;
                break;
              }
            }
            loopIndex += 1;
          } while (doLoop);
        }
      }

      return slides.reverse();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function prepareAppendSlides(): SwiperSlideVNode[] {
      const slides: SwiperSlideVNode[] = [];

      if (props.isInfiniteLoop && appendSlidesCounter.value > 0) {
        let counter = appendSlidesCounter.value;
        let doLoop = true;
        const slideEntries = getSlides();
        let loopIndex = 0;

        if (slideEntries.length > 0) {
          do {
            for (const [index, slide] of slideEntries.entries()) {
              slides.push(cloneSwiperSlide(slide, `append-${loopIndex}`, index));
              counter -= 1;

              if (counter === 0) {
                doLoop = false;
                break;
              }
            }
            loopIndex += 1;
          } while (doLoop);
        }
      }

      return slides;
    }

    function getCurrentTranslateX(): number {
      return swiperWrapperReference.value
        ? Math.round(new WebKitCSSMatrix(getComputedStyle(swiperWrapperReference.value).transform).m41)
        : 0;
    }

    function getSlides(): SwiperSlideVNode[] {
      const slides: SwiperSlideVNode[] = [];

      for (const slide of defaultSlides) {
        if (isSwiperSlideVNode(slide)) {
          slides.push(slide);
        } else if (slide.children && Array.isArray(slide.children)) {
          for (const child of slide.children) {
            if (isSwiperSlideVNode(child)) {
              slides.push(child);
            }
          }
        }
      }

      return slides;
    }

    function getAllSlides(): SwiperSlideVNode[] {
      return [
        ...prependSlides,
        ...getSlides(),
        ...appendSlides,
      ];
    }

    async function calculatePreviousNextButtons(): Promise<void> {
      await nextTick();
      const { minTranslateX, scrollWidth, offsetWidth } = getWrapperProperties();
      const currentTranslateX = getCurrentTranslateX();
      const fixInaccuracy = process.env.VUE_APP_OS_WINDOWS ? 2 : 0;

      let isMarginReachedInternal = false;
      let isPreviousButtonAllowed = true;
      let isNextButtonAllowed = true;

      if (currentTranslateX >= 0) {
        isMarginReachedInternal = true;
        isPreviousButtonAllowed = false;
      }

      if (currentTranslateX <= minTranslateX + fixInaccuracy) {
        isMarginReachedInternal = true;
        isNextButtonAllowed = false;
      }

      if (scrollWidth <= offsetWidth) {
        isMarginReachedInternal = true;
        isPreviousButtonAllowed = false;
        isNextButtonAllowed = false;
      }

      isMarginReached.value = isMarginReachedInternal;
      swiperState.isPreviousButtonAllowed.value = isPreviousButtonAllowed;
      swiperState.isNextButtonAllowed.value = isNextButtonAllowed;
    }

    function isSlideInView(slide: VNode<HTMLElement>): boolean {
      const scrollLeft = stateTranslateX.value > 0
        ? 0
        : Math.abs(stateTranslateX.value);
      const wrapperProperties = getWrapperProperties();

      return isSlideVisibleInContainer(slide.el, scrollLeft, wrapperProperties.offsetWidth, props.slideVisibilityRate);
    }

    function setActiveSlide(): void {
      let activeSlideIndex = -1;

      const slides = getAllSlides();

      for (const [index, slide] of slides.entries()) {
        if (isSlideInView(slide)) {
          activeSlideIndex = slide.props?.originalSlidesIndex === undefined
            ? index - prependSlides.length
            : slide.props.originalSlidesIndex;
          break;
        }
      }

      if (activeSlideIndex === -1 && slides.length > 0) {
        activeSlideIndex = 0;
        setTranslateX(0);
      }

      swiperState.activeSlideIndex.value = activeSlideIndex;
    }

    function getActiveSlideIndex(): number {
      return swiperState.activeSlideIndex.value;
    }

    function getNumberOfSlides(): number {
      return swiperState.slidesCounter.value;
    }

    function emitScroll(): void {
      if (!swiperWrapperReference.value) {
        return;
      }

      const { offsetWidth, scrollWidth } = swiperWrapperReference.value;
      const scrollLeft = stateTranslateX.value > 0
        ? 0
        : Math.abs(stateTranslateX.value);

      emit('scroll', {
        scrollLeft,
        offsetWidth,
        scrollWidth,
        activeSlide: getActiveSlideIndex(),
      });
    }

    function checkOverScroll(): void {
      const { minTranslateX } = getWrapperProperties();
      let duration = 0;
      let newTranslateX = stateTranslateX.value;
      isSlideToNextSlide.value = false;
      if (stateTranslateX.value > 0) {
        duration = Math.abs(stateTranslateX.value);
        newTranslateX = 0;
      } else if (stateTranslateX.value < minTranslateX) {
        duration = Math.abs(minTranslateX - stateTranslateX.value);
        newTranslateX = minTranslateX;
      }

      duration *= SWIPER_OPTIONS.overscroll.durationRatio;

      if (duration > SWIPER_OPTIONS.overscroll.maxDuration) {
        duration = SWIPER_OPTIONS.overscroll.maxDuration;
      }

      if (newTranslateX !== stateTranslateX.value) {
        setTranslateX(newTranslateX, duration);
      }
    }

    function setSwiperWidth() {
      if (!swiperReference.value) {
        return;
      }
      swiperReference.value.style.width = '100%';
      const { offsetWidth } = swiperReference.value;
      if (offsetWidth % 2 !== 0) {
        const evenWidth = offsetWidth + 1;
        swiperReference.value.style.width = `${evenWidth}px`;
      }
    }

    function setTranslateX(translateX: number, duration = 0, withOverScroll = true): void {
      if (!swiperWrapperReference.value) {
        return;
      }
      const { minTranslateX, offsetWidth } = getWrapperProperties();
      let newTranslateX = translateX;

      const maxTranslateX = withOverScroll ? getAvailableOverscrollWidth(offsetWidth) : 0;
      const minCalculatedTranslateX = minTranslateX - maxTranslateX;

      if (newTranslateX >= maxTranslateX) {
        newTranslateX = maxTranslateX;
      } else if (newTranslateX <= minCalculatedTranslateX) {
        newTranslateX = minCalculatedTranslateX;
      }

      if (stateTranslateX.value !== newTranslateX) {
        const newDuration = Math.round(duration);
        stateTranslateX.value = newTranslateX;
        swiperWrapperReference.value.style.transitionDuration = `${newDuration}ms`;
        swiperWrapperReference.value.style.transform = `translate3d(${newTranslateX}px, 0px, 0px)`;
        setActiveSlide();
        emitScroll();
        void calculatePreviousNextButtons();

        if (newDuration > 0) {
          overscrollCheckTimeout = Timer.setTimeout(() => {
            checkOverScroll();
          }, duration);
        }
      }
    }

    function onTransitionRun(event: TransitionEvent): void {
      if (event.target === swiperWrapperReference.value && event.propertyName === 'transform' && swiperWrapperReference.value) {
        isTransitionInProgress = true;
        updateSlidesVisibility();
      }
    }

    async function onTransitionEnd(event: TransitionEvent): Promise<void> {
      if (event.target === swiperWrapperReference.value && event.propertyName === 'transform' && swiperWrapperReference.value) {
        isTransitionInProgress = false;
        updateSlidesVisibility();
        if (props.isInfiniteLoop) {
          await nextTick();
          fixLoopTranslateX();
        }
      }
    }

    function setTranslateXOffset(offset: number, duration = 0, withOverScroll = true): void {
      setTranslateX(stateTranslateX.value + offset, duration, withOverScroll);
    }

    function stopBounce(): void {
      if (overscrollCheckTimeout) {
        Timer.clearTimeout(overscrollCheckTimeout);
        overscrollCheckTimeout = 0;
      }

      if (getWrapperProperties().offsetWidth > 0) {
        setTranslateX(getCurrentTranslateX());
      }
    }

    function isAutoScrollEnabled(): boolean {
      if (!props.isAutoScroll)
        return false;
      const ratio = getPixelRatio();
      return !(ratio === undefined || ratio < 2);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function fixLoopTranslateX(direction?: VSwipeDirection): void {
      const translateX = getCurrentTranslateX();
      const scrollLeft = translateX > 0 ? 0 : Math.abs(translateX);
      const wrapperProperties = getWrapperProperties();
      const wrapperRightMargin = scrollLeft + wrapperProperties.offsetWidth;
      const slides = getSlides();

      let newTranslateX: number | undefined;

      if (!direction || direction === VSwipeDirection.RIGHT) {
        for (const slide of prependSlides) {
          if (slide.el) {
            const { offsetWidth, offsetLeft } = slide.el;
            const offsetRight = offsetLeft + offsetWidth;

            if (offsetRight > scrollLeft && offsetRight <= wrapperRightMargin) {
              if (slide.props?.originalSlidesIndex !== undefined) {
                const originalSlide = slides[slide.props.originalSlidesIndex];

                if (originalSlide?.el) {
                  const offset = offsetRight - scrollLeft;
                  newTranslateX = -originalSlide.el.offsetLeft - originalSlide.el.offsetWidth + offset;
                }
              }

              break;
            }
          }
        }
      }

      if (newTranslateX === undefined && (!direction || direction === VSwipeDirection.LEFT)) {
        for (const slide of appendSlides) {
          if (slide.el) {
            const { offsetLeft } = slide.el;

            if ((offsetLeft < wrapperRightMargin && offsetLeft >= scrollLeft)) {
              if (slide.props?.originalSlidesIndex !== undefined) {
                const originalSlide = slides[slide.props.originalSlidesIndex];

                if (originalSlide?.el) {
                  const offset = wrapperRightMargin - offsetLeft;
                  newTranslateX = -originalSlide.el.offsetLeft + wrapperProperties.offsetWidth - offset;
                }
              }

              break;
            }
          }
        }
      }

      if (newTranslateX !== undefined) {
        setTranslateX(newTranslateX);
      }
    }

    async function startAutoScroll(): Promise<void> {
      stopAutoScroll();
      if (isAutoScrollEnabled()) {
        if (props.isInfiniteLoop) {
          await nextTick();
          fixLoopTranslateX();
        }

        /*@__PURE__*/ assert(/*@__PURE__*/ swiperWrapperReference.value);
        isAutoScrollStarted = true;
        swiperWrapperReference.value.style.transitionTimingFunction = 'linear';

        const { minTranslateX } = getWrapperProperties();
        let { transitionOffset } = SWIPER_OPTIONS.autoScroll;
        if (minTranslateX === 0) {
          return;
        }

        transitionOffset = isAutoScrollReversed ? transitionOffset : -transitionOffset;

        if (stateTranslateX.value + transitionOffset <= minTranslateX) {
          transitionOffset = minTranslateX - stateTranslateX.value;
        } else if (stateTranslateX.value + transitionOffset >= 0) {
          transitionOffset = -stateTranslateX.value;
        }

        if (transitionOffset === 0) {
          void startAutoScroll();
          return;
        }

        const duration = Math.abs(transitionOffset) * SWIPER_OPTIONS.autoScroll.durationRatio;

        setTranslateXOffset(transitionOffset, duration, false);
        swiperWrapperReference.value.addEventListener('transitionend', onAutoScrollTransitionEnd);
      }
    }

    function onAutoScrollTransitionEnd(event: TransitionEvent): void {
      if (event.target === swiperWrapperReference.value && event.propertyName === 'transform' && isAutoScrollStarted) {
        void startAutoScroll();
      }
    }

    function stopAutoScroll(): void {
      if (startAutoScrollTimeout) {
        Timer.clearTimeout(startAutoScrollTimeout);
        startAutoScrollTimeout = 0;
      }

      if (customerSwipingAutoScrollTimeout) {
        Timer.clearTimeout(customerSwipingAutoScrollTimeout);
        customerSwipingAutoScrollTimeout = 0;
      }

      if (isAutoScrollStarted) {
        const { minTranslateX, offsetWidth } = getWrapperProperties();
        const currentTranslateX = getCurrentTranslateX();

        if (!props.isInfiniteLoop) {
          if (currentTranslateX <= minTranslateX) {
            isAutoScrollReversed = true;
          } else if (currentTranslateX >= 0) {
            isAutoScrollReversed = false;
          }
        }

        if (offsetWidth > 0) {
          setTranslateX(currentTranslateX, 0, false);
        }
        /*@__PURE__*/ assert(/*@__PURE__*/ swiperWrapperReference.value);
        swiperWrapperReference.value.style.transitionTimingFunction = '';
        isAutoScrollStarted = false;
        swiperWrapperReference.value.removeEventListener('transitionend', onAutoScrollTransitionEnd);
      }
    }

    function customerSwiping(): void {
      stopAutoScroll();
      if (isAutoScrollEnabled()) {
        customerSwipingAutoScrollTimeout = Timer.setTimeout(() => {
          void startAutoScroll();
        }, SWIPER_OPTIONS.autoScroll.customerSwipingTimeout);
      }
    }

    function updateSwipeData(clientX: number, clientY: number, timeStamp: number): void {
      if (isVerticalSwipe) {
        return;
      }

      let direction = swipeInitData?.direction;
      const currentClientX = swipeCurrentData?.clientX || null;
      if (swipeCurrentData && clientX !== currentClientX) {
        direction = clientX > (currentClientX || 0) ? VSwipeDirection.RIGHT : VSwipeDirection.LEFT;
      }

      if (swipeInitData && !swipeInitData.direction) {
        swipeInitData.direction = direction;
      }

      const directionChanged = swipeInitData?.direction !== direction;
      const swipeData: VSwiperTouchData = {
        clientY,
        clientX,
        timeStamp,
        direction,
      };

      if (!swipeInitData || directionChanged) {
        swipeInitData = swipeData;
      }

      swipeCurrentData = swipeData;

      if (currentClientX !== null && clientX !== currentClientX && swiperState.isSwiping.value) {
        customerSwiping();

        setTranslateXOffset(swipeCurrentData.clientX - currentClientX);
        updateSlidesVisibility();

        fixLoopTranslateX(swipeData.direction);
      }
    }

    function setTouchData(event: TouchEvent): void {
      const { timeStamp, changedTouches } = event;
      if (!changedTouches.length) {
        return;
      }

      const { clientX, clientY } = changedTouches[0];

      updateSwipeData(clientX, clientY, timeStamp);
    }

    function isCurrentTouch(event: TouchEvent): boolean {
      const { changedTouches } = event;
      if (
        !changedTouches.length
        || !changedTouches[0]
        || changedTouches[0].identifier !== touchIdentifier
      ) {
        if (event.cancelable) {
          event.preventDefault();
        }
        return false;
      }

      return true;
    }

    function onTouchStart(event: TouchEvent): void {
      if (touchIdentifier !== null) {
        event.preventDefault();
        return;
      }

      touchIdentifier = event.changedTouches[0].identifier;

      isTouchEventStarted = true;
      stopBounce();
      setTouchData(event);
    }

    function onTouchMove(event: TouchEvent): void {
      if (!isCurrentTouch(event)) {
        return;
      }

      if (!swiperState.isSwiping.value) {
        if (swipeInitData) {
          const { changedTouches } = event;
          const { clientX, clientY } = changedTouches[0];
          const absX = Math.abs(clientX - swipeInitData.clientX);
          const absY = Math.abs(clientY - swipeInitData.clientY);

          if (absY > absX) {
            isVerticalSwipe = true;
          } else {
            swiperState.isSwiping.value = true;
          }
        }
      } else if (event.cancelable) {
        event.preventDefault();
      }

      setTouchData(event);
    }

    function setMouseData(event: MouseEvent): void {
      updateSwipeData(event.clientX, event.clientY, event.timeStamp);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function getNewOffsetForSnap(offset: number, currentTranslateX?: number): number {
      let newOffset = offset;
      const translateX = currentTranslateX ?? stateTranslateX.value;
      const slideGap = getSlidesGap();

      const { minTranslateX } = getWrapperProperties();
      const newTranslateX = translateX + offset;

      if (!isAutoScrollStarted && props.isScrollSnapEnabled && translateX > minTranslateX) {
        const scrollLeft = -newTranslateX;

        const slides = getAllSlides();
        for (const [index, slide] of slides.entries()) {
          if (slide.el) {
            const offsetLeft = getSlideOffsetLeft(slide.el);
            const slideWidth = slide.el.getBoundingClientRect().width + (index === slides.length - 1 ? 0 : slideGap);

            if (scrollLeft >= offsetLeft && scrollLeft < offsetLeft + slideWidth / 2) {
              newOffset = -offsetLeft - translateX;
              break;
            } else if (scrollLeft >= offsetLeft + slideWidth / 2 && scrollLeft < offsetLeft + slideWidth) {
              newOffset = -offsetLeft - slideWidth - translateX;
              break;
            }
          }
        }
      }

      return newOffset;
    }

    function getSlidesGap() {
      if (!swiperWrapperReference.value)
        return 0;
      const { gap } = getComputedStyle(swiperWrapperReference.value);
      const numericValue = Number.parseFloat(gap);
      return Number.isNaN(numericValue) ? 0 : numericValue;
    }

    function getNextSlide(): VNode<HTMLElement> | undefined {
      let scrollLeft = getCurrentTranslateX();
      scrollLeft = scrollLeft > 0 ? 0 : Math.abs(scrollLeft);

      for (const item of getSlides()) {
        if (item.el) {
          const { offsetLeft } = item.el;

          if (offsetLeft >= scrollLeft) {
            return item;
          }
        }
      }

      return undefined;
    }

    function getCurrentSlide(): VNode<HTMLElement> | undefined {
      let scrollLeft = getCurrentTranslateX();
      scrollLeft = scrollLeft > 0 ? 0 : Math.abs(scrollLeft);

      for (const item of getSlides()) {
        if (item.el) {
          const { offsetLeft, offsetWidth } = item.el;

          if (offsetLeft + offsetWidth >= scrollLeft) {
            return item;
          }
        }
      }

      return undefined;
    }

    function checkSnap(translate?: number): void {
      const snapTranslateXOffset = getNewOffsetForSnap(0);
      if (snapTranslateXOffset !== 0 || translate) {
        const duration = props.isBlock ? 200 : 100;
        setTranslateX(stateTranslateX.value + (translate ?? snapTranslateXOffset), duration);
      }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function doBounce(swipeVelocity: number, direction?: VSwipeDirection): void {
      if (isMarginReached.value) {
        stateTranslateX.value = getCurrentTranslateX();
        return;
      }
      if (swipeVelocity >= SWIPER_OPTIONS.bounce.minVelocity && Math.abs(stateTranslateX.value) >= SWIPER_OPTIONS.overscroll.minSwipe) {
        const wrapperProperties = getWrapperProperties();
        const { offsetWidth, minTranslateX } = wrapperProperties;
        const maxTranslateX = getAvailableOverscrollWidth(offsetWidth);
        let momentumDuration = SWIPER_OPTIONS.bounce.momentumRatio;
        let maxDuration = momentumDuration;

        const velocity = swipeVelocity * SWIPER_OPTIONS.bounce.momentumVelocityRatio;
        let offset = velocity * momentumDuration;
        offset = direction === VSwipeDirection.LEFT ? -offset : offset;
        offset = getNewOffsetForSnap(offset);

        if (props.isBlock) {
          if (direction === VSwipeDirection.LEFT) {
            const slide = getNextSlide();
            if (slide?.el) {
              offset = -slide.el.offsetLeft - stateTranslateX.value;
              maxDuration = 800;
            }
          } else {
            const slide = getCurrentSlide();
            if (slide?.el) {
              offset = -slide.el.offsetLeft - stateTranslateX.value;
              maxDuration = 800;
            }
          }
        }

        const minOverscrollTranslateX = minTranslateX - maxTranslateX;

        if (stateTranslateX.value + offset < minOverscrollTranslateX) {
          offset = minOverscrollTranslateX - stateTranslateX.value;
        } else if (stateTranslateX.value + offset > maxTranslateX) {
          offset = maxTranslateX - stateTranslateX.value;
        }

        momentumDuration = Math.abs(offset / velocity) * SWIPER_OPTIONS.bounce.durationRatio;

        if (momentumDuration > maxDuration) {
          momentumDuration = maxDuration;
        }
        setTranslateXOffset(offset, momentumDuration);
      } else {
        const offset = slideDragPosStart.value - (swipeCurrentData?.clientX || 0);
        setTranslateXOffset(offset, SWIPER_OPTIONS.autoScroll.durationRatio, false);
      }
    }

    function onSwiped(event: MouseEvent | TouchEvent): void {
      if (swiperState.isSwiping.value) {
        if ('touches' in event) {
          setTouchData(event);
        } else {
          setMouseData(event);
        }

        checkOverScroll();
        customerSwiping();

        /*@__PURE__*/ assert(/*@__PURE__*/ swipeCurrentData, 'Swipe current data is undefined');
        /*@__PURE__*/ assert(/*@__PURE__*/ swipeInitData, 'Swipe init data is undefined');
        const velocity = Math.abs(swipeCurrentData.clientX - swipeInitData.clientX)
          / Math.abs(swipeCurrentData.timeStamp - swipeInitData.timeStamp);
        const { direction } = swipeCurrentData;

        doBounce(velocity, direction);
      }

      swipeInitData = null;
      swipeCurrentData = null;
      isVerticalSwipe = false;
      swiperState.isSwiping.value = false;
    }

    function onTouchEnd(event: TouchEvent): void {
      if (!isCurrentTouch(event) && event.touches.length > 0) {
        return;
      }

      isTouchEventStarted = false;
      touchIdentifier = null;
      onSwiped(event);
    }

    function getSlidesVisibilityLimits(): VSlidesVisibilityLimits {
      const currentTranslateX = getCurrentTranslateX();
      const newTranslateX = stateTranslateX.value;
      const { offsetWidth } = getWrapperProperties();
      const { offsetRatio } = SWIPER_OPTIONS.visibility;
      const offset = offsetWidth * offsetRatio;

      const fromTranslateX = currentTranslateX > 0 ? 0 : Math.abs(currentTranslateX);
      const toTranslateX = newTranslateX > 0 ? 0 : Math.abs(newTranslateX);

      return {
        min: (toTranslateX > fromTranslateX ? fromTranslateX : toTranslateX) - offset,
        max: (toTranslateX > fromTranslateX ? toTranslateX : fromTranslateX) + offsetWidth + offset,
      };
    }

    function setVisibleLoopSlides(): void {
      for (const [index, slide] of getSlides().entries()) {
        slide.component?.exposed?.setVisibility?.(visibleSlideIndexes.includes(index));
      }

      for (const slide of ([...prependSlides, ...appendSlides])) {
        const index = slide.props?.originalSlidesIndex === undefined
          ? -1
          : slide.props?.originalSlidesIndex;

        slide.component?.exposed?.setVisibility?.(visibleSlideIndexes.includes(index));
      }
    }

    function updateSlidesVisibility(limits: VSlidesVisibilityLimits | null = null): void {
      void calculatePreviousNextButtons();
      const newLimits = limits ?? getSlidesVisibilityLimits();
      const slides = getSlides();
      const visibleSlideIndexesInner: number[] = [];

      for (const [index, slide] of slides.entries()) {
        const isVisible = shouldSlideBeVisible(newLimits, slide);
        if (isVisible) {
          visibleSlideIndexesInner.push(index);
        }
      }

      for (const slide of ([...prependSlides, ...appendSlides])) {
        const isVisible = shouldSlideBeVisible(newLimits, slide);
        if (isVisible) {
          visibleSlideIndexesInner.push(slide.props?.originalSlidesIndex || 0);
        }
      }

      visibleSlideIndexes = visibleSlideIndexesInner;

      setVisibleLoopSlides();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function getLoopSlidesCounter(slides: VNode<HTMLElement>[]): number {
      const { offsetWidth } = getWrapperProperties();
      let doLoop = true;
      let counter = 0;
      let calculatedWidth = 0;

      if (slides.length > 0) {
        do {
          for (const slide of slides.toReversed()) {
            if (slide.el) {
              const { offsetWidth: slideOffsetWidth } = slide.el;

              if (calculatedWidth >= (offsetWidth * SWIPER_OPTIONS.loop.additionalSlidesCoefficient)) {
                doLoop = false;
                break;
              }
              calculatedWidth += slideOffsetWidth;
              counter += 1;
            } else {
              doLoop = false;
            }
          }
        } while (doLoop);
      }

      return counter;
    }

    async function calculateActiveLoopSlides(): Promise<void> {
      let slides = getSlides();
      const { offsetWidth, scrollWidth, paddingLeft } = getWrapperProperties();

      if (offsetWidth < scrollWidth) {
        prependSlidesCounter.value = getLoopSlidesCounter(slides.toReversed());
        appendSlidesCounter.value = getLoopSlidesCounter(slides);

        await nextTick();
        slides = getSlides();
        if (!isLoopInitialized && slides.length > 0 && slides[0].el && slides[0].el.offsetLeft > paddingLeft) {
          setTranslateX(-slides[0].el.offsetLeft + paddingLeft);
          isLoopInitialized = true;
        }
      } else {
        appendSlidesCounter.value = 0;
        prependSlidesCounter.value = 0;
      }
    }

    function initLoopInner(): void {
      const slides = getSlides();
      const { offsetWidth, scrollWidth } = getWrapperProperties();

      if (slides.length > 1 && props.isInfiniteLoop && offsetWidth < scrollWidth) {
        if (!isTransitionInProgress) {
          void calculateActiveLoopSlides();
        }
      } else {
        isLoopInitialized = false;
      }
    }

    const initLoop = useDebounce(initLoopInner, 200);

    function onSlideToggleInner(): void {
      updateSlidesVisibility();
      if (!isLoopInitialized) {
        initLoop();
      }

      setActiveSlide();
      swiperState.slidesCounter.value = getSlides().length;
    }

    const onSlideToggle = useDebounce(onSlideToggleInner, 50);

    let wheelTimeout: number | null = null;

    function onWheel(event: WheelEvent): void {
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
        if (event.cancelable) {
          event.preventDefault();
        }

        customerSwiping();
        setTranslateXOffset(-event.deltaX, 0, false);
        fixLoopTranslateX(event.deltaX > 0 ? VSwipeDirection.LEFT : VSwipeDirection.RIGHT);
        updateSlidesVisibility();
      }

      // Forced onWheelStop
      if (wheelTimeout) {
        clearTimeout(wheelTimeout);
      }
      wheelTimeout = window.setTimeout(() => {
        checkSnap();
        wheelTimeout = null;
      }, 75);
    }

    function getSlideOffsetLeft(element: HTMLElement): number {
      const { left, paddingLeft } = getWrapperProperties();
      return element.getBoundingClientRect().left - left - paddingLeft;
    }

    function getSlideTranslateToRight(element: HTMLElement): number {
      const slideGap = getSlidesGap();
      return getCurrentTranslateX() - element.getBoundingClientRect().width - slideGap;
    }

    function getSlideTranslateToLeft(element: HTMLElement): number {
      const slideGap = getSlidesGap();
      return getCurrentTranslateX() + element.getBoundingClientRect().width + slideGap;
    }

    function onMouseMove(event: MouseEvent): void {
      if (Math.abs(event.clientX - (swipeInitData?.clientX || 0)) > SWIPER_OPTIONS.overscroll.minMouseMove) {
        swiperState.isSwiping.value = true;
        setMouseData(event);
      }
    }

    function onMouseUp(event: MouseEvent): void {
      onSwiped(event);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }

    function onMouseDown(event: MouseEvent): void {
      if (!isSlideToNextSlide.value) {
        slideDragPosStart.value = event.clientX;
        stopBounce();
        setMouseData(event);
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
      }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function slideToNextSlide(indexOffset = props.isBlock ? 1 : 2): void {
      if (!isSlideToNextSlide.value) {
        isSlideToNextSlide.value = true;
        const slides = getAllSlides();
        for (const [index, slide] of slides.entries()) {
          const nextIndex = index + indexOffset;
          if (isSlideInView(slide)) {
            const nextSlide = slides[nextIndex];

            if (nextSlide && nextSlide.el) {
              customerSwiping();
              if (props.isBlock) {
                setTranslateX(-getSlideOffsetLeft(nextSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                return;
              }
              setTranslateX(getSlideTranslateToRight(nextSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
              return;
            }
            break;
          }
        }
        setTranslateX(0, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
      }
    }

    function slideToNextVisibleSlide(): void {
      if (props.isBlock) {
        slideToNextSlide(1);
        return;
      }

      let offset = 0;
      let slideFound = false;

      for (const slide of getAllSlides()) {
        if (slide.el) {
          if (isSlideInView(slide)) {
            slideFound = true;
            offset = getSlideOffsetLeft(slide.el);
          } else if (slideFound) {
            break;
          }
        }
      }

      customerSwiping();
      setTranslateX(-offset, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function slideToPreviousSlide(indexOffset = 1): void {
      if (!isSlideToNextSlide.value) {
        isSlideToNextSlide.value = true;
        const slides = getAllSlides();
        for (const [index, slide] of slides.entries()) {
          if (isSlideInView(slide)) {
            const previousIndex = index - indexOffset;
            const previousSlide = slides[previousIndex];
            if (previousSlide && previousSlide.el) {
              customerSwiping();
              if (props.isBlock) {
                setTranslateX(-getSlideOffsetLeft(previousSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                return;
              }
              setTranslateX(getSlideTranslateToLeft(previousSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
              return;
            }
            break;
          }
        }

        setTranslateX(0, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
      }
    }

    function slideToPreviousVisibleSlide(): void {
      if (props.isBlock) {
        slideToPreviousSlide(1);
        return;
      }

      let offset = 0;
      for (const slide of getAllSlides()) {
        if (slide.el && isSlideInView(slide)) {
          const { offsetWidth, paddingLeft, paddingRight } = getWrapperProperties();
          offset = getSlideOffsetLeft(slide.el) - offsetWidth + paddingLeft + paddingRight + slide.el.offsetWidth;
          break;
        }
      }

      customerSwiping();
      setTranslateX(-offset, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
    }

    function slideToSlide(index: number, options?: VSwiperSlideToProperties): void {
      const slide = getSlides()[index];

      if (slide && slide.el) {
        customerSwiping();
        const { offsetLeft, offsetWidth: elementOffsetWidth } = slide.el;
        let offset = -offsetLeft;
        if (options?.isCentered) {
          const { offsetWidth } = getWrapperProperties();
          if (offsetWidth > elementOffsetWidth) {
            offset += (offsetWidth - elementOffsetWidth) / 2;
          }

          if (props.isScrollSnapEnabled) {
            offset += getNewOffsetForSnap(0, offset);
          }
        }

        setTranslateX(
          offset,
          options?.smooth ? (options?.speed ?? SWIPER_OPTIONS.navigation.visibleSlideSpeed) : 0,
          false,
        );

        if (!options?.smooth) {
          updateSlidesVisibility();
        }
      }
    }

    function fixTranslateX(): void {
      const { minTranslateX } = getWrapperProperties();

      if (minTranslateX > stateTranslateX.value) {
        setTranslateX(minTranslateX);
        updateSlidesVisibility();
      }
    }

    function initResizeObserver(): void {
      resizeObserver = new MutationObserver(() => {
        if (stateTranslateX.value === getCurrentTranslateX()
          && !swiperState.isSwiping.value && !isTouchEventStarted) {
          checkSnap();
          fixTranslateX();
        }
      });

      if (swiperWrapperReference.value) {
        resizeObserver.observe(swiperWrapperReference.value, { childList: true });
      }
    }

    function onWindowResize({ deltaX }: { deltaX: number }): void {
      if (Math.abs(deltaX) > 0) {
        if (props.isBlock) {
          slideToSlide(swiperState.activeSlideIndex.value);
        } else {
          const { offsetWidth, scrollWidth } = getWrapperProperties();
          setTranslateX(scrollWidth > offsetWidth ? getCurrentTranslateX() : 0);
        }
        setSwiperWidth();
        updateSlidesVisibility();
      }
    }

    function disconnectResizeObserver(): void {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    }

    function onComponentActivated(): void {
      initResizeObserver();
    }

    function onComponentDeactivated(): void {
      stopAutoScroll();
      stopBounce();
      disconnectResizeObserver();
    }

    function resetScrollPosition(): void {
      if (props.isInfiniteLoop) {
        isLoopInitialized = false;
        initLoop();
      } else {
        setTranslateX(0);
      }
      updateSlidesVisibility();
    }

    function visibilityChanged(value: boolean): void {
      if (!isLoopInitialized) {
        initLoop();
      }
      void calculatePreviousNextButtons();

      if (value) {
        startAutoScrollTimeout = Timer.setTimeout(startAutoScroll, SWIPER_OPTIONS.autoScroll.startTimeout);
      } else {
        stopAutoScroll();
      }
    }

    useWindowResize(onWindowResize);
    onMounted(onComponentActivated);
    onActivated(() => {
      onComponentActivated();
      if (stateTranslateX.value === 0) {
        resetScrollPosition();
      }
    });
    onBeforeUnmount(onComponentDeactivated);
    onDeactivated(onComponentDeactivated);

    provide(VSwiperProvidableKeys.State, swiperState);
    provide(VSwiperProvidableKeys.OnSlideToggle, onSlideToggle);
    provide(VSwiperProvidableKeys.SlideToNextSlide, slideToNextSlide);
    provide(VSwiperProvidableKeys.SlideToNextVisibleSlide, slideToNextVisibleSlide);
    provide(VSwiperProvidableKeys.SlideToPreviousSlide, slideToPreviousSlide);
    provide(VSwiperProvidableKeys.SlideToPreviousVisibleSlide, slideToPreviousVisibleSlide);
    provide(VSwiperProvidableKeys.SlideToSlide, slideToSlide);

    watch(() => props.isInfiniteLoop, async (newValue) => {
      await nextTick();
      appendSlidesCounter.value = 0;
      prependSlidesCounter.value = 0;
      isLoopInitialized = false;
      await nextTick();
      if (newValue) {
        initLoop();
      }
    });

    watch(() => props.isAutoScroll, async (newValue) => {
      stopAutoScroll();
      setTranslateX(0);
      if (newValue) {
        await nextTick();
        void startAutoScroll();
      }
    });

    expose({
      resetScrollPosition,
      slideToSlide,
      slideToNextSlide,
      slideToPreviousSlide,
      getActiveSlideIndex,
      getNumberOfSlides,
    });

    const listeners: Record<string, (event: Event) => void> = {
      onTransitionrun: onTransitionRun,
      onTransitionend: onTransitionEnd,
    };

    return () => {
      defaultSlides = slots.default?.({
        manualChangeSlideActiveState,
      }) ?? [];
      prependSlides = preparePrependSlides();
      appendSlides = prepareAppendSlides();

      return withDirectives(h('div', {
        class: className.value,
        ref: swiperReference,
        onMouseenter: () => {
          emit('mouseenter');
        },
        onMouseleave: () => {
          emit('mouseleave');
        },
      }, [
        ...(slots['pagination-header']?.() ?? []),
        h('div', {
          class: [
            $style['swiper__event-container'],
            normalizeClass(props.eventContainerClass) ?? '',
          ].join(' '),
          onWheel,
          onTouchstart: onTouchStart,
          onTouchmove: onTouchMove,
          onTouchend: onTouchEnd,
          onMousedown: onMouseDown,
        }, [
          h('div', {
            class: [
              $style.swiper__wrapper,
              normalizeClass(props.wrapperClass) ?? '',
            ].join(' '),
            ref: swiperWrapperReference,
            ...listeners,
          }, [
            ...prependSlides,
            ...defaultSlides,
            ...appendSlides,
          ]),
        ]),
        ...(slots['pagination-footer']?.({
          manualActiveSlide: manualActiveSlide.value,
        }) ?? []),
      ]), [
        [ObserveVisibility, {
          callback: visibilityChanged,
          intersection: {
            threshold: 0.5,
          },
        }],
      ]);
    };
  },
});
</script>

<style module lang="scss">
@import 'web/src/components/Swiper/VSwiper/styles/outer-padding';

.swiper {
  $self: &;

  @include swiperOuterPaddings($self);

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--overflow-visible {
    overflow: visible;
  }

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    transition-timing-function: ease-out;
    transition-property: transform;
    will-change: transform;
  }

  &--full-height {
    display: flex;
    flex-direction: column;

    #{$self}__wrapper {
      flex: 1;
      height: 100%;
    }

    #{$self}__event-container {
      flex: 1;
      height: 100%;
    }
  }

  &--top-margin {
    &-8 {
      #{$self}__wrapper {
        margin-top: 8px;
      }
    }
  }

  &--block {
    #{$self}__wrapper > {
      :global(.swiper-slide) {
        width: 100%;
      }
    }
  }
}
</style>
