import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import type { FileMultipleInputProps } from 'web/src/components/Input';
import isFileMultipleInputProps from 'web/src/components/Input/guards/isFileMultipleInputProps';

import type {
  FormWrapperWidgetProperties,
} from '../../types';
import getErrorArrayFields from '../../utils/getErrorArrayFields';
import getHintFields from '../../utils/getHintFields';
import getHintLinkFields from '../../utils/getHintLinkFields';

interface UseFormFileMultipleInput {
  componentProps: ComputedRef<FileMultipleInputProps>;
}

export default function useFormFileMultipleInput(props: FormWrapperWidgetProperties<FileMultipleInputProps>)
  : UseFormFileMultipleInput {
  const componentProps = computed<FileMultipleInputProps>(
    () => {
      const {
        name,
        error,
        title,
        hintMessage,
        disabled,
        options,
        autofocus,
      } = props;

      assert(isFileMultipleInputProps(options));

      return {
        label: title,
        disabled,
        ...options,
        ...getErrorArrayFields({ ...options }, error),
        ...getHintFields(options, hintMessage),
        ...getHintLinkFields(options),
        autofocus,
        name,
      };
    },
  );
  return {
    componentProps,
  };
}
