import type {
  ComputedRef,
  Ref,
  Slots,
} from 'vue';
import { computed } from 'vue';

import { IconName } from '@leon-hub/icons';
import { hasSlot } from '@leon-hub/utils';

import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import type { ModalWindowHeaderProps } from 'web/src/components/Modal/ModalWindowHeader/types';

import type { ModalWindowProps } from '../types';

export interface ModalWindowComposable {
  isShowCloseButton: ComputedRef<boolean>;
  headerProperties: ComputedRef<ModalWindowHeaderProps>;
  isHeaderHidden: ComputedRef<boolean>;
  jumbotronProperties: ComputedRef<VJumbotronProps>;
  titleText: Ref<string | undefined>;
}

export default function useModalWindow(props: ModalWindowProps, slots: Slots): ModalWindowComposable {
  const isShowCloseButton = computed(
    () => !!process.env.VUE_APP_LAYOUT_DESKTOP && !!props.isFullHeight && !!props.isProfileLayout,
  );

  const titleText = computed(
    () => {
      const title = props.topBarText
        || ((!props.isTopBarHidden && (props.isAlert || props.isFullHeight)) ? '&nbsp;' : undefined);

      if (process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_OUTSIDE_CLOSE_BUTTON_ENABLED && process.env.VUE_APP_LAYOUT_DESKTOP && props.isAlert && !title) {
        return '&nbsp;';
      }

      return title;
    },
  );

  const prefixIconName = computed(() => {
    if (props.isCloseAsBack) {
      return IconName.ARROW_LEFT;
    }

    if (process.env.VUE_APP_LAYOUT_PHONE && props.isFullHeight && props.isCloseAllowed) {
      return IconName.CROSS;
    }

    return undefined;
  });

  const isCloseIconAllowed = computed(
    () => (!!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED || !process.env.VUE_APP_LAYOUT_DESKTOP || !!props.isAlert || !!props.isFullHeight)
      && !!props.isCloseAllowed && !hasSlot(slots, 'default'),
  );

  const suffixIconName = computed(() => {
    if (process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_OUTSIDE_CLOSE_BUTTON_ENABLED && process.env.VUE_APP_LAYOUT_DESKTOP && (props.isAlert || !props.isCloseAllowed)) {
      return undefined;
    }

    if (props.isCloseAsBack) {
      return undefined;
    }

    if ((isCloseIconAllowed.value && !prefixIconName.value) || isShowCloseButton.value || props.isCloseAllowed) {
      return IconName.CROSS;
    }

    return undefined;
  });

  const jumbotronProperties: ComputedRef<VJumbotronProps> = computed(() => ({
    image: props.image,
    icon: props.icon,
    iconSize: props.iconSize,
    heading: props.header,
    text: props.content,
    isModal: true,
    iconKind: props.iconKind,
    alertIconSize: props.alertIconSize,
  }));

  const headerProperties = computed<ModalWindowHeaderProps>(() => ({
    prefixIconName: prefixIconName.value,
    suffixIconName:
      (process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_OUTSIDE_CLOSE_BUTTON_ENABLED && props.isFullHeight)
      || (prefixIconName.value === suffixIconName.value)
        ? undefined
        : suffixIconName.value,
    title: titleText.value,
    type: props.headerType
      ?? (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        || props.isAlert || props.isFullHeight
        ? 'default'
        : 'tertiary'),
  }));
  const isHeaderHidden = computed(
    () => (!prefixIconName.value && !suffixIconName.value && !titleText.value),
  );

  return {
    titleText,
    isShowCloseButton,
    headerProperties,
    isHeaderHidden,
    jumbotronProperties,
  };
}
