<script setup lang="ts">
import { toRef } from 'vue';

import type { Sport } from 'web/src/modules/sportline/types';
import type { SportElementHeadlineExpandState, SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { BasePrimaryHeadlineEmits, BasePrimaryHeadlineProps } from './types';
import PrimaryHeadline from './PrimaryHeadline.vue';
import { useSportHeadline } from './useSportHeadline';

interface SportHeadlineProps extends BasePrimaryHeadlineProps {
  sport?: Maybe<Sport>;
  customTitle?: Maybe<string>;
  basisKey: SportlineFragmentBasisKey;
}

const props = withDefaults(defineProps<SportHeadlineProps>(), {
  sport: null,
  customTitle: null,
  expandKey: null,
  countEvents: 0,
});
const emit = defineEmits<BasePrimaryHeadlineEmits>();

const sport = toRef(() => props.sport);
const customTitle = toRef(() => props.customTitle);
const isComing = toRef(() => props.isComing);
const expandKey = toRef(() => props.expandKey);
const isExpanded = toRef(() => props.isExpanded);
const countEvents = toRef(() => props.countEvents);
const hideEventsCounter = toRef(() => props.hideEventsCounter);
const isMarketTypesSelectionEnabled = toRef(() => props.isMarketTypesSelectionEnabled);

const {
  title,
  backgroundColor,
  onCatalogClick,
} = useSportHeadline({ sport, customTitle, isComing });

function toggleExpandState(payload: SportElementHeadlineExpandState): void {
  emit('toggle-expand-state', payload);
}
</script>

<template>
  <PrimaryHeadline v-auto-id="'SportHeadline'"
    :title="title"
    :background-color="backgroundColor"
    :expand-key="expandKey"
    :is-expanded="isExpanded"
    :count-events="countEvents"
    :hide-events-counter="hideEventsCounter"
    :is-coming="isComing"
    :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
    :basis-key="basisKey"
    @toggle-expand-state="toggleExpandState"
    @catalog-click="onCatalogClick"
  />
</template>
