import type {
  BroadcastEventInfoSubscription,
} from 'web/src/modules/sportline/types';
import type {
  SportEventElementCache,
  SportEventMarketsCache,
} from 'web/src/modules/sportline/types/cache-storage';
import type {
  BroadcastListElement,
} from 'web/src/modules/sportline/types/sportline-broadcast';

export function getBroadcastListElementFromCache(
  key: BroadcastEventInfoSubscription['key'],
  eventCache?: Maybe<SportEventElementCache>,
  eventMarketsCache?: Maybe<SportEventMarketsCache>,
): Maybe<BroadcastListElement> {
  if (!eventCache?.sportEvent) { return null; }
  if (!eventMarketsCache?.markets) { return null; }

  const markets = eventMarketsCache.markets?.markets || [];
  const market = markets.find((marketInList) => marketInList.id === String(key.market));
  const runner = market?.runners.find((runnerInList) => runnerInList.id === String(key.runner));

  if (!market || !runner) { return null; }

  return {
    key,
    market,
    runner,
    event: eventCache.sportEvent,
  };
}
