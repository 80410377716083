import type { RouteLocationNormalized, RouteLocationPathRaw } from 'vue-router';

import { useIntercomEvents } from 'web/src/modules/intercom/composables/index';
import useSupport from 'web/src/modules/support/composables/useSupport';

interface IntercomBasePrefetch {
  prefetch(from: RouteLocationNormalized): RouteLocationPathRaw | undefined;
}

export function useBaseIntercom(): IntercomBasePrefetch {
  return {
    prefetch(from) {
      if (process.env.VUE_APP_FEATURE_INTERCOM_ENABLED) {
        const { openIntercom } = useIntercomEvents();
        const { isChatMode, isIntercomAvailable } = useSupport();
        if (isChatMode.value && isIntercomAvailable.value) {
          openIntercom();
          return {
            ...from,
            replace: true,
          };
        }
      }
    },
  };
}
