import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind, ButtonType } from '@components/buttons';

import type { VSmsNumberCheckProps } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/types';

export default function getButtonProperties(
  props: Pick<VSmsNumberCheckProps, 'isNewTel'>,
): VButtonProps {
  const { $translate } = useI18n();

  return {
    label: props.isNewTel ? $translate('WEB2_CALL_BACK').value : $translate('WEB2_RESEND_CODE').value,
    kind: ButtonKind.BASE,
    type: ButtonType.BUTTON,
    fullWidth: true,
  };
}
