<script setup lang="ts">
import type { VCheckboxEmits } from '@components/checkbox';
import type { TermsCheckboxProps } from '@components/terms-special-checkboxes';
import { useCheckboxEvents, VCheckbox } from '@components/checkbox';
import {
  SpecialCheckboxesDataTest,

  useTermsCheckbox,
} from '@components/terms-special-checkboxes';
import { VIcon } from '@components/v-icon';

const props = withDefaults(defineProps<TermsCheckboxProps>(), {
  name: 'FlexepinTermsCheckbox',
});

const emit = defineEmits<VCheckboxEmits>();

const {
  toggleExpand,
  isExpanded,
  checkboxProps,
  chevronProperties,
} = useTermsCheckbox(props);

const {
  onChange,
  onBlur,
  onFocus,
  onInput,
} = useCheckboxEvents(emit);
</script>

<template>
  <div v-auto-id="'FlexepinTermsCheckbox'" :class="$style['flexepin-terms']">
    <div :class="$style['flexepin-terms__content']">
      <VCheckbox
        v-data-test="{ el: SpecialCheckboxesDataTest.AcceptFlexepinTermsElement }"
        v-bind="checkboxProps"
        :class="$style['flexepin-terms__checkbox']"
        :input-data-test="{ el: SpecialCheckboxesDataTest.AcceptFlexepinTermsCheckbox }"
        @change="onChange"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template #label>
          <span v-data-test="{ el: SpecialCheckboxesDataTest.AcceptFlexepinTermsText, expanded: isExpanded }">
            <slot
              name="short"
            >{{ $t('WEB2_AGREE_FLEXEPIN_TERMS_SHORT_TEXT') }} <a
              href="https://www.flexepin.com/flexepin-terms-and-conditions-eea/"
              target="_blank"
            >{{ $t('WEB2_AGREE_FLEXEPIN_TERMS_LINK_TEXT') }}</a> </slot>
            <span
              v-if="isExpanded"
              :class="$style['flexepin-terms__description']"
            >
              {{ $t('WEB2_AGREE_FLEXEPIN_TERMS_FULL_TEXT') }}
            </span>
          </span>
        </template>
      </VCheckbox>
    </div>
    <div
      v-if="expandable"
      v-data-test="{ el: SpecialCheckboxesDataTest.AcceptFlexepinTermsExpandToggle }"
      :class="$style['flexepin-terms__button']"
      @click="toggleExpand"
    >
      <VIcon
        v-bind="chevronProperties"
        :class="$style['flexepin-terms__button-chevron']"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.flexepin-terms {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__content {
    flex: 1;
  }

  &__button {
    position: relative;
    top: 10px;
    width: 24px;
    height: 24px;

    &-chevron {
      color: var(--TextSecondary);
      cursor: pointer;
    }
  }

  &__description {
    display: block;
    padding-top: 20px;
    color: var(--TextPrimary);
  }
}
</style>
