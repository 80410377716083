import type { UIFormSchema } from '@leon-hub/api-sdk';
import { CustomerFieldType } from '@leon-hub/api-sdk';

const countryDefaultNotAvailable = '-';
function getFormSelectorCountryCode(geoIpCountryCode: string, uiSchema?: UIFormSchema): string {
  if (!uiSchema?.fields) {
    return geoIpCountryCode;
  }
  let geoIpCountry = geoIpCountryCode;
  const countrySelectField = uiSchema.fields.find(
    (i) => i.id === CustomerFieldType.COUNTRY_SELECT,
  );
  if (countrySelectField && !countrySelectField.disabled) {
    const isCountryInList = countrySelectField.labels.some(
      (item) => item.key === geoIpCountry,
    );
    if (!isCountryInList) {
      geoIpCountry = countryDefaultNotAvailable;
    }
  } else if (countrySelectField && countrySelectField.disabled) {
    geoIpCountry = countrySelectField.labels[0].key || countryDefaultNotAvailable;
  }
  return geoIpCountry;
}
export default getFormSelectorCountryCode;
