import type { Ref } from 'vue';
import { toRef } from 'vue';
import { useRouter } from 'vue-router';

import {
  uiAccountTypeMain,
  uiAccountTypeSbc,
} from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { ShareBetByIdPayload, ShareSuccessLogPayload } from '../types';
import { useFreebetStore } from '../../freebet/store';
import { usePlaceBetStore } from '../../place-bet/store';
import { useSlipShareStore } from '../store';

export interface UseSlipShare {
  isCurrentSlipShareEnabled: Ref<boolean>;
  isCustomerBetShareEnabled: Ref<boolean>;
  showSlipShareDialog(): void;
  showBetShareDialog(payload: ShareBetByIdPayload, logData: ShareSuccessLogPayload): void;
  shareLastBet(): void;
  showAmountSwitcher(): void;
}

export function useSlipShare(): UseSlipShare {
  const router = useRouter();

  const slipShareStore = useSlipShareStore();

  const isCurrentSlipShareEnabled = toRef(slipShareStore, 'isCurrentSlipShareEnabled');

  const isCustomerBetShareEnabled = toRef(slipShareStore, 'isCustomerBetShareEnabled');
  const currentSuccessData = toRef(slipShareStore, 'currentSuccessData');

  const lastBetId = toRef(usePlaceBetStore(), 'lastBetId');

  const currentFreeBet = toRef(useFreebetStore(), 'currentFreeBet');

  const {
    shareBetById,
    shareCurrentSlipData,
    shareLastPlacedSingles,
    showSharedModal,
    showAmountSwitcher,
  } = slipShareStore;

  const initShareModal = (): void => {
    showSharedModal();
    void router.push({ name: RouteName.SLIP_SHARE });
  };

  const showSlipShareDialog = (): void => {
    shareCurrentSlipData();
    initShareModal();
  };

  const showBetShareDialog = (payload: ShareBetByIdPayload, logData: ShareSuccessLogPayload): void => {
    void shareBetById(payload, logData);
    initShareModal();
  };

  const shareLastBet = (): void => {
    if (lastBetId.value) {
      showAmountSwitcher();
      showBetShareDialog({
        betId: lastBetId.value,
        accountType: currentFreeBet.value ? uiAccountTypeSbc : uiAccountTypeMain,
        showAmount: true,
      }, currentSuccessData.value);
    } else {
      shareLastPlacedSingles();
      initShareModal();
    }
  };

  return {
    isCurrentSlipShareEnabled,
    isCustomerBetShareEnabled,
    showSlipShareDialog,
    showBetShareDialog,
    shareLastBet,
    showAmountSwitcher,
  };
}
