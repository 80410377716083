import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import type {
  EgsBlockType,
  EgsGameCategoriesFieldsFragment,
  ILobbyType,
} from '@leon-hub/api-sdk';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';
import { CasinoRouteName, RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from '@core/auth';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import type {
  EgsCategory,
  EgsGroup,
} from 'web/src/modules/egs/types';
import { isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';
import {
  AllGamesGroupUrl,
} from 'web/src/modules/egs/constants';
import { useEgsCategoriesStore } from 'web/src/modules/egs/submodules/categories/store';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { EgsRouterParametersHandler } from 'web/src/modules/egs/submodules/navigation/utils';
import {
  getCategoryByRoute,
  getGroupByUrl,
  isLiveEgsRoute,
} from 'web/src/modules/egs/utils';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import useSupport from 'web/src/modules/support/composables/useSupport';

export interface UseCasinoLobbyComposable {
  lobbyType: Ref<ILobbyType>;
  hasGroup: Ref<boolean>;
  groupId: Ref<string | undefined>;
  categoryId: Ref<string | undefined>;
  groupName: Ref<string>;
  group: Ref<EgsGroup | undefined>;
  category: Ref<EgsCategory | undefined>;
  isLoadingCategories: Ref<boolean>;
  isLoggedIn: Ref<boolean>;
  isLoadingLobby: Ref<boolean>;
  isHelpMode: Ref<boolean>;
  isEgsLoyaltyEnabled: Ref<boolean>;
  isRewardCenterEnabled: Ref<boolean>;
  groupBlockType: Ref<Maybe<EgsBlockType> | undefined>;
  filteredGroupId: Ref<string | undefined>;
  groupUrl: Ref<string | undefined>;
  categories: Ref<readonly EgsGameCategoriesFieldsFragment[]>;
  onClickLoyaltyProgram(): void;
  onClickSearch(): void;
  onClickGroup(): void;
  onClickHelp(): void;
  onClickReward(): void;
  loadCategories(groupId?: string, lobbyType?: ILobbyType, silent?: boolean): Promise<void>;
}

export function useCasinoLobby(): UseCasinoLobbyComposable {
  const { $translate } = useI18n();
  const route = useRoute();
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const { isHelpMode } = useSupport();
  const siteConfigStore = useSiteConfigStore();
  const lobbyStore = useLobbyStore();

  const homePageType = toRef(siteConfigStore, 'homePageType');
  const rewardCenterConfig = toRef(siteConfigStore, 'rewardCenter');
  const lobbyType = toRef(lobbyStore, 'activeType');
  const groupId = toRef(lobbyStore, 'activeGroupId');
  const categoryId = toRef(lobbyStore, 'activeCategoryId');

  const isLoadingLobby = computed(() => lobbyStore.getIsLoading(lobbyType.value, groupId.value, categoryId.value).value);
  const isRewardCenterEnabled = computed<boolean>(() => (!!process.env.VUE_APP_FEATURE_REWARD_CENTER_ENABLED && rewardCenterConfig.value?.enabled) ?? false);
  const isEgsLoyaltyEnabled = toRef(siteConfigStore, 'isEgsLoyaltyEnabled');

  const { getGroupsByLobbyType } = useEgsGroupsStore();
  const currentGroups = computed(() => getGroupsByLobbyType(lobbyType.value).value);

  const group = computed(() => {
    if (!isCasinoLobbyRouteName(route.name, homePageType.value)) {
      return undefined;
    }
    const { groupUrl } = route.params;
    assert(isOptionalString(groupUrl));
    return getGroupByUrl(currentGroups.value, groupUrl);
  });
  const groupBlockType = computed(() => group.value?.blockType);
  const filteredGroupId = computed(() => (groupBlockType.value ? undefined : groupId.value));

  const hasGroup = computed(() => !!group.value);
  const groupName = computed(() => group.value?.name || $translate('WEB2_ALL_PROVIDERS').value);
  const groupUrl = computed(() => group.value?.url);

  const categoriesStore = useEgsCategoriesStore();
  const { getCategoriesByGroupIdAndType } = categoriesStore;
  const categories = computed(() => getCategoriesByGroupIdAndType(lobbyType.value, groupId.value).value);
  const isLoadingCategories = toRef(categoriesStore, 'isLoading');
  const { loadCategories } = categoriesStore;
  const category = computed(() => {
    if (!isCasinoLobbyRouteName(route.name, homePageType.value)) {
      return undefined;
    }
    return getCategoryByRoute(categories.value, route);
  });

  function onClickLoyaltyProgram(): void {
    void router.push({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM,
    });
  }

  function onClickSearch(): void {
    void router.push({
      name: CasinoRouteName.CASINO_SEARCH,
    });
  }

  function onClickReward(): void {
    void router.push({
      name: isLoggedIn.value ? RouteName.REWARD_CENTER : RouteName.REWARD_CENTER_ABOUT,
    });
  }

  function onClickGroup(): void {
    void router.push({
      name: isLiveEgsRoute(route)
        ? CasinoRouteName.CASINO_LIVE_GROUPS
        : CasinoRouteName.CASINO_GROUPS,
    });

    EgsRouterParametersHandler.setSelectedGroup(
      route.params.groupUrl === AllGamesGroupUrl
        ? undefined
        : groupUrl.value,
    );
  }

  function onClickHelp(): void {
    void router.push({ name: RouteName.SUPPORT });
  }

  return {
    lobbyType,
    groupId,
    categoryId,
    hasGroup,
    groupName,
    group,
    category,
    groupBlockType,
    filteredGroupId,
    isLoadingCategories,
    isLoggedIn,
    isLoadingLobby,
    isHelpMode,
    onClickLoyaltyProgram,
    onClickSearch,
    onClickGroup,
    onClickHelp,
    onClickReward,
    loadCategories,
    isEgsLoyaltyEnabled,
    groupUrl,
    categories,
    isRewardCenterEnabled,
  };
}
