const fileDelimiter = '.';
export function getFileExtension (file: File): string {
  const extension = file.name.split(fileDelimiter).pop()?.toLowerCase();

  if (!extension || extension.length === 0) {
    return '';
  }

  return extension;
}
