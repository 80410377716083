import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import {
  CustomerVerificationStatus,
  CustomerVerificationType,
  VideoVerificationStatus,
} from '@leon-hub/api-sdk';

import { useCustomerDataStore } from '@core/customer';
import { useSiteConfigStore } from '@core/site-config';

export interface VerificationComposable {
  isEkycRequestable: Ref<boolean>;
  isVerificationNavigationItemEnabled: Ref<boolean>;
  isVideoVerificationEnabled: Ref<boolean>;
  isVerificationStatusActive: Ref<boolean>;
  isAccountVerificationRequired: Ref<boolean>;
  isShowHintVideoVerificationProfile: Ref<boolean>;
}
export default function useVerification(): VerificationComposable {
  const siteConfigStore = useSiteConfigStore();
  const customerDataStore = useCustomerDataStore();

  const isVideoVerificationFeatureEnabled = toRef(siteConfigStore, 'isVideoVerificationFeatureEnabled');

  const latestRequestedVerificationType = toRef(customerDataStore, 'latestRequestedVerificationType');
  const videoVerificationStatus = toRef(customerDataStore, 'videoVerificationStatus');
  const verificationStatus = toRef(customerDataStore, 'verificationStatus');
  const isEkycRequestable = toRef(() => customerDataStore.isEkycRequestable);
  const isAdditionalVerificationNeeded = toRef(customerDataStore, 'isAdditionalVerificationNeeded');
  const showVerification = toRef(customerDataStore, 'showVerification');

  const isVideoVerificationLatest = computed(

    () => latestRequestedVerificationType.value as CustomerVerificationType === CustomerVerificationType.VIDEO_VERIFICATION_SERVICE,
  );

  const isVerificationStatusActive = computed<boolean>(() => !!verificationStatus.value && [
    CustomerVerificationStatus.STARTED,
    CustomerVerificationStatus.IN_PROCESS,
    CustomerVerificationStatus.RETRY,
    CustomerVerificationStatus.FAILED,
  ].includes(verificationStatus.value));

  const isAccountVerificationRequired = computed<boolean>(() => isAdditionalVerificationNeeded.value
    || isVerificationStatusActive.value);

  const isVideoVerificationEnabled: Ref<boolean> = computed(() => (isVideoVerificationFeatureEnabled.value
    && videoVerificationStatus.value !== VideoVerificationStatus.NONE
    && videoVerificationStatus.value !== VideoVerificationStatus.UNKNOWN
    && videoVerificationStatus.value !== VideoVerificationStatus.PENDING
    && isVideoVerificationLatest.value));

  const isVerificationNavigationItemEnabled = computed<boolean>(
    () => isAccountVerificationRequired.value || showVerification.value
      || isVideoVerificationEnabled.value,
  );

  const isShowHintVideoVerificationProfile = computed<boolean>(() => (
    isVideoVerificationEnabled.value && videoVerificationStatus.value !== VideoVerificationStatus.PASSED
  ));

  return {
    isEkycRequestable,
    isVideoVerificationEnabled,
    isVerificationNavigationItemEnabled,
    isAccountVerificationRequired,
    isVerificationStatusActive,
    isShowHintVideoVerificationProfile,
  };
}
