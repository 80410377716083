import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert, isObject, isOptionalString } from '@leon-hub/guards';

import { usePaymentsConfig } from '@core/site-config';

import type {
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

interface UseFastSum {
  componentProps: ComputedRef<PaymentsFastSumInputProps>;
}

export default function useFastSum(props: FormWrapperWidgetProperties<PaymentsFastSumInputProps>): UseFastSum {
  const {
    isPaymentsFastSumEnabled,
    isPaymentsFastSumMaxEnabled,
  } = usePaymentsConfig();

  const componentProps = computed(() => {
    const {
      name,
      title,
      hintMessage,
      error,
      options,
      autofocus,
    } = props;
    assert(isOptionalString(error));
    assert(isObject(options), 'options is not an object');
    return {
      ...options,
      name,
      error,
      hint: hintMessage,
      label: title,
      autofocus,
      showSuggestedAmount: isPaymentsFastSumEnabled.value,
      isFastSumMaxEnabled: isPaymentsFastSumMaxEnabled.value,
    };
  });
  return {
    componentProps,
  };
}
