<script setup lang="ts">
import {
  computed,
} from 'vue';

import { useTheme } from '@core/theme';

import type { PushNotificationsOnboardingImageEmits } from './types';

const emit = defineEmits<PushNotificationsOnboardingImageEmits>();

const { isLight } = useTheme();

const imageSrc = computed(() => (
  isLight.value
    ? require('web/src/assets/images/li/default/push-onboarding-light.svg')
    : require('web/src/assets/images/li/default/push-onboarding.svg')
));
</script>

<template>
  <img v-auto-id="'PushNotificationsOnboardingImage'"
    :src="imageSrc"
    alt="main"
    @load="emit('load')"
    @error="emit('error')"
  >
</template>
