import type { Ref } from 'vue';
import {
  nextTick,
  ref,
  watch,
} from 'vue';

import { Timer } from '@leon-hub/utils';

import type { VSelectOption } from 'web/src/components/Select';
import { Keys } from 'web/src/components/Select/constants/keyboardEvents';

interface UseDropdownKeydownAndFocusIndexProps {
  openDropdown(): Promise<void>;
  closeDropdown(): Promise<void>;
  onOptionSelect(value: string): void;
  selectedValue: Ref<string | undefined>;
  isDropdownShown: Ref<boolean>;
  options: Ref<VSelectOption[] | undefined>;
  disableKeySearch: Ref<boolean | undefined>;
  searchQuery: Ref<string>;
  setPaginationToReachIndex(index: number): void;
}

interface UseDropdownKeydownAndFocusIndex {
  hiddenSubmit: Ref<HTMLButtonElement | undefined>;
  preselectedListIndex: Ref<number>;
  handleKeyDown(event: KeyboardEvent): void;
  setPreselectedIndex(index: number): void;
  setHiddenSubmit(element?: HTMLButtonElement): void;
}

export default function useDropdownKeydownAndFocusIndex({
  openDropdown,
  closeDropdown,
  selectedValue,
  isDropdownShown,
  options,
  disableKeySearch,
  onOptionSelect,
  searchQuery,
  setPaginationToReachIndex,
}: UseDropdownKeydownAndFocusIndexProps): UseDropdownKeydownAndFocusIndex {
  const hiddenSubmit = ref<HTMLButtonElement | undefined>();

  const setHiddenSubmit = (element?: HTMLButtonElement): void => {
    hiddenSubmit.value = element;
  };

  const getSelectedIndex = (): number => (selectedValue.value
    ? (options.value ?? [])
        .findIndex((option) => option.value === selectedValue.value)
    : -1);

  const preselectedListIndex = ref<number>(getSelectedIndex());

  const setPreselectedIndex = (index: number) => {
    preselectedListIndex.value = index;
  };

  const confirmPreselect = (): void => {
    const selectedOption = options.value?.[preselectedListIndex.value];
    if (selectedOption) {
      onOptionSelect(selectedOption.value);
    }
  };

  let searchTimer = 0;

  let keySearchQuery = '';

  const resetSearch = () => {
    keySearchQuery = '';
  };

  const onKeySearch = async (event: KeyboardEvent) => {
    Timer.clearTimeout(searchTimer);
    const currentValue = keySearchQuery;
    if (event.code === Keys.Backspace) {
      keySearchQuery = currentValue.slice(0, currentValue.length - 1);
    } else {
      keySearchQuery = `${currentValue}${event.key}`.toLowerCase();
    }
    const match = (options.value ?? [])?.findIndex(({ label }) => label.toLowerCase().startsWith(keySearchQuery));
    if (match > -1) {
      setPaginationToReachIndex(match);
      await nextTick();
      setPreselectedIndex(match);
      searchTimer = Timer.setTimeout(resetSearch, 1000);
    } else {
      resetSearch();
    }
  };

  const handleEnter = (): void => {
    if (!isDropdownShown.value) {
      if (selectedValue.value && hiddenSubmit.value) {
        // submit form LEONWEB-5979
        hiddenSubmit.value?.click();
      } else {
        void openDropdown();
      }
    } else {
      confirmPreselect();
    }
  };

  const onArrowPress = async (isDown: boolean): Promise<void> => {
    if (!isDropdownShown.value) {
      void openDropdown();
      return;
    }
    let nextIndex;
    const lastIndex = (options.value ?? []).length - 1;
    if (isDown) {
      nextIndex = preselectedListIndex.value >= lastIndex ? 0 : preselectedListIndex.value + 1;
      setPaginationToReachIndex(nextIndex + 2);
    } else {
      nextIndex = preselectedListIndex.value <= 0 ? lastIndex : preselectedListIndex.value - 1;
      setPaginationToReachIndex(nextIndex);
    }
    await nextTick();
    setPreselectedIndex(nextIndex);
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    const keysToHandle: string[] = [
      Keys.ArrowUp,
      Keys.ArrowDown,
      Keys.Enter,
      Keys.Escape,
    ];
    if (!isDropdownShown.value && event.code === Keys.Space) {
      void openDropdown();
    }
    if ((!disableKeySearch.value && (event.key.length === 1 || event.code === Keys.Backspace))
      || keysToHandle.includes(event.code)
      || (event.code === Keys.Tab && isDropdownShown.value)
    ) {
      event.stopPropagation();
      event.preventDefault();
      switch (event.code) {
        case Keys.Escape:
          void closeDropdown();
          break;
        case Keys.Enter:
          handleEnter();
          break;
        case Keys.ArrowDown:
        case Keys.ArrowUp:
          void onArrowPress(event.code === Keys.ArrowDown);
          break;
        default:
          void onKeySearch(event);
          break;
      }
    }
  };

  const focusOnFirstResult = () => {
    if (searchQuery.value && options.value?.length) {
      setPreselectedIndex(0);
    }
  };

  watch(() => searchQuery.value, focusOnFirstResult);

  watch(() => isDropdownShown.value, () => {
    if (!isDropdownShown.value) {
      void nextTick().then(() => setPreselectedIndex(getSelectedIndex()));
    }
  });

  return {
    hiddenSubmit,
    handleKeyDown,
    preselectedListIndex,
    setPreselectedIndex,
    setHiddenSubmit,
  };
}
