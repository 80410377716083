import { normalizeError } from '@leon-hub/errors';
import { isObject } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { ChunkLoadError } from '@leon-hub/routing-config';
import { sleep } from '@leon-hub/utils';

import { useSnackbarsStore } from 'web/src/modules/snackbars/store';

type ResolvedComponent<ResolvedImport> = { default: ResolvedImport } | ResolvedImport;

export default function importComponent<ResolvedImport>(
  importFunction: () => Promise<ResolvedComponent<ResolvedImport>>,
): Promise<ResolvedImport> {
  const { showConnectionError } = useSnackbarsStore();

  function getImportedComponent(result: ResolvedComponent<ResolvedImport>): ResolvedImport {
    if (isObject(result) && result.default) {
      return result.default;
    }

    return result as ResolvedImport;
  }

  return new Promise((resolve, reject) => {
    const retry = (counter = 1) => importFunction().then((result) => {
      resolve(getImportedComponent(result));
    }).catch((error) => {
      if (counter < 3) {
        void sleep(1000).then(() => {
          void retry(counter + 1);
        });
        return;
      }

      logger.error(normalizeError(error));
      showConnectionError().then(() => {
        void retry();
      }).catch(() => {
        reject(new ChunkLoadError({
          message: error.message,
          originalError: error,
          chunkPath: '',
        }));
      });
    });

    void retry();
  });
}
