import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { MarketsGridFilter, MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
import {
  allMarketsGridFilterCustomId,
  statisticMarketsGridFilterCustomId,
  statisticWidgetMarketsGridFilterCustomId,
} from 'web/src/modules/sportline/submodules/markets-grid/constants';

export interface MarketsListTabsControlProps {
  hasStatistic: Ref<boolean>;
  hasStatisticWidget: Ref<boolean>;
  gridFilters: Ref<MarketsGridFilter[]>;
  allowedToSelectLayoutTypes: Ref<DetailsLayoutType[]>;
}

export interface MarketsListTabsControlComposable {
  isStatisticWidgetSelected: Ref<boolean>;
  isStatisticSelected: Ref<boolean>;
  isAllTabSelected: Ref<boolean>;
  isSelectedTabExistInMarketGroups: Ref<boolean>;
  activeGridFilter: Ref<Maybe<MarketsGridFilter>>;
  doShowInlineStatisticSwitch: Ref<boolean>;
  displayedMarketGroupTabId: Ref<MarketsGridFilterId>;
  recalculateActiveTabId(): void;
  resetSelectedTabId(): void;
  setSelectedTabId(value: MarketsGridFilterId): void;
  setIsStatisticSelected(value: boolean): void;
  setIsStatisticWidgetSelected(value: boolean): void;
}

export function useMarketsListTabsControl(
  props: MarketsListTabsControlProps,
): MarketsListTabsControlComposable {
  const {
    hasStatistic,
    hasStatisticWidget,
    gridFilters,
    allowedToSelectLayoutTypes,
  } = props;

  const isStatisticWidgetSelected = ref(false);
  const isStatisticSelected = ref(false);
  const internalMarketGroupTabId = ref<MarketsGridFilterId>(allMarketsGridFilterCustomId);

  const activeGridFilter = computed<Maybe<MarketsGridFilter>>(() => (
    gridFilters.value.find((group) => group.id === internalMarketGroupTabId.value) ?? null
  ));

  const doShowInlineStatisticSwitch = computed<boolean>(() => allowedToSelectLayoutTypes.value.length > 1);
  const isAllTabSelected = computed<boolean>(() => internalMarketGroupTabId.value === allMarketsGridFilterCustomId);
  // displayed market group
  const displayedMarketGroupTabId = computed<MarketsGridFilterId>(() => {
    if (doShowInlineStatisticSwitch.value) {
      // show real active tab for exist separate switch
      return internalMarketGroupTabId.value;
    }

    // fake selected tab without real switch
    if (isStatisticWidgetSelected.value && hasStatisticWidget.value) {
      return statisticWidgetMarketsGridFilterCustomId;
    }

    // fake selected tab without real switch
    if (isStatisticSelected.value && hasStatistic.value) {
      return statisticMarketsGridFilterCustomId;
    }

    return internalMarketGroupTabId.value;
  });
  const isSelectedTabExistInMarketGroups = computed<boolean>(() => {
    if (internalMarketGroupTabId.value === allMarketsGridFilterCustomId) {
      return true;
    }

    if (
      internalMarketGroupTabId.value === statisticMarketsGridFilterCustomId
      || internalMarketGroupTabId.value === statisticWidgetMarketsGridFilterCustomId
    ) {
      // uniq case for empty markets groups
      // used for do not change isStatisticSelected after user turned off it
      return true;
    }

    return gridFilters.value.some((group) => group.id === internalMarketGroupTabId.value);
  });

  function recalculateActiveTabId(): void {
    if (internalMarketGroupTabId.value === allMarketsGridFilterCustomId) {
      internalMarketGroupTabId.value = gridFilters.value.length > 0 && !process.env.VUE_APP_PRERENDER
        ? gridFilters.value[0].id
        : allMarketsGridFilterCustomId;
    }
  }

  function resetSelectedTabId(): void {
    internalMarketGroupTabId.value = allMarketsGridFilterCustomId;
  }

  function setSelectedTabId(value: MarketsGridFilterId): void {
    if (value === statisticMarketsGridFilterCustomId) {
      isStatisticSelected.value = true;
    } else if (value === statisticWidgetMarketsGridFilterCustomId) {
      isStatisticWidgetSelected.value = true;
    } else {
      internalMarketGroupTabId.value = value;
      if (!doShowInlineStatisticSwitch.value) {
        isStatisticSelected.value = false;
        isStatisticWidgetSelected.value = false;
      }
    }
  }

  return {
    isAllTabSelected,
    activeGridFilter,
    isStatisticSelected,
    isStatisticWidgetSelected,
    isSelectedTabExistInMarketGroups,
    doShowInlineStatisticSwitch,
    displayedMarketGroupTabId,
    recalculateActiveTabId,
    resetSelectedTabId,
    setSelectedTabId,
    setIsStatisticSelected(value: boolean): void {
      isStatisticSelected.value = value;
    },
    setIsStatisticWidgetSelected(value: boolean): void {
      isStatisticWidgetSelected.value = value;
    },
  };
}
