import type { Ref } from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';

import type {
  AddToCustomerLeaguesParameters,
  CustomerFavoritesIdsBackgroundUpdate,
} from 'web/src/modules/sportline/submodules/favorites/types';
import type { CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useFavoriteLeaguesSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import arrayEquals from 'web/src/utils/array/arrayEquals';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface UseFavoriteLeaguesBackgroundUpdatesProps {
  customerLeaguesIds: Ref<Maybe<string[]>>;
  pendingToFavoriteLeaguesList: Ref<AddToCustomerLeaguesParameters[]>;
  tryToAddPendingLeagues(): Promise<boolean>;
  fetchFavoriteLeaguesEvents(options: CoreSportlineFetchOptions): Promise<void>;
  fetchCustomerLeaguesIds(options: CoreSportlineFetchOptions): Promise<void>;
  clearPendingLeagues(): void;
}

interface UseFavoriteLeaguesBackgroundUpdatesComposable {
  onInit(): void;
  initialRequests(): Promise<void>;
  setIsBackgroundIdsUpdateAvailable(value: CustomerFavoritesIdsBackgroundUpdate): void;
  setIsEventsBackgroundUpdateAvailable(value: boolean): void;
  synchronizeLeaguesIds({ force, silent }: { force?: boolean; silent?: boolean }): Promise<void>;
}

export function useFavoriteLeaguesBackgroundUpdates(
  props: UseFavoriteLeaguesBackgroundUpdatesProps,
): UseFavoriteLeaguesBackgroundUpdatesComposable {
  const {
    customerLeaguesIds,
    pendingToFavoriteLeaguesList,
    tryToAddPendingLeagues,
    fetchFavoriteLeaguesEvents,
    fetchCustomerLeaguesIds,
    clearPendingLeagues,
  } = props;

  const { timeout: favoriteLeaguesUpdateInterval } = useFavoriteLeaguesSportlineUpdateTimeout();

  const isLeaguesEventsBackgroundUpdateAvailable = ref<boolean>(false);
  const isBackgroundIdsUpdateAvailableSet = ref<CustomerFavoritesIdsBackgroundUpdate>({});

  const isSyncIdsAvailable = computed(() => (
    Object.values(isBackgroundIdsUpdateAvailableSet.value).some((enable) => enable)
  ));

  const lastUpdate = new BackgroundUpdateStopwatch<['favoriteLeaguesEvents', 'favoriteLeaguesIds']>({
    favoriteLeaguesEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchFavoriteLeaguesEvents({ silent });
      lastUpdate.update('favoriteLeaguesEvents');
    },
    favoriteLeaguesIds: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchCustomerLeaguesIds({ silent });
      lastUpdate.update('favoriteLeaguesIds');
    },
  });

  async function synchronizeLeaguesIds({ force, silent }: { force?: boolean; silent?: boolean }): Promise<void> {
    await (
      force
        ? lastUpdate.forceCallSyncAction('favoriteLeaguesIds', { silent })
        : lastUpdate.callSyncAction('favoriteLeaguesIds', favoriteLeaguesUpdateInterval.value, { silent })
    );
  }

  async function synchronizeLeaguesEvents({ force, silent }: { force?: boolean; silent?: boolean }): Promise<void> {
    await (
      force
        ? lastUpdate.forceCallSyncAction('favoriteLeaguesEvents', { silent })
        : lastUpdate.callSyncAction('favoriteLeaguesEvents', favoriteLeaguesUpdateInterval.value, { silent })
    );
  }

  async function syncState({ silent }: { silent?: boolean }): Promise<void> {
    const isSyncListAvailable = isLeaguesEventsBackgroundUpdateAvailable.value;

    if (pendingToFavoriteLeaguesList.value.length > 0) {
      // after login with pending list
      // ids list will be sync in add action
      const isAddedPending = await tryToAddPendingLeagues();
      if (isSyncListAvailable) { await synchronizeLeaguesEvents({ silent }); }
      const isSimilarEvents = compareSimilarEvents();
      if (isAddedPending && isSimilarEvents) { clearPendingLeagues(); }
    } else {
      // just sync state
      await Promise.allSettled([
        isSyncIdsAvailable.value ? synchronizeLeaguesIds({ silent }) : Promise.resolve(),
        isSyncListAvailable ? synchronizeLeaguesEvents({ silent }) : Promise.resolve(),
      ]);
    }
  }

  function compareSimilarEvents(): boolean {
    return pendingToFavoriteLeaguesList.value.every((event) => customerLeaguesIds.value?.includes(event.leagueId));
  }

  function setIsBackgroundIdsUpdateAvailable(value: CustomerFavoritesIdsBackgroundUpdate): void {
    isBackgroundIdsUpdateAvailableSet.value = { ...isBackgroundIdsUpdateAvailableSet.value, ...value };
    if (value) { void synchronizeLeaguesIds({ silent: true }); }
  }

  function setIsEventsBackgroundUpdateAvailable(value: boolean): void {
    if (value === isLeaguesEventsBackgroundUpdateAvailable.value) { return; }
    isLeaguesEventsBackgroundUpdateAvailable.value = value;
    if (value) { void synchronizeLeaguesEvents({ silent: true }); }
  }

  // @TODO check inits and watchers, it could make init requests twice
  async function initialRequests(): Promise<void> {
    await synchronizeLeaguesIds({ silent: true, force: true });
  }

  function onInit(): void {
    watch(customerLeaguesIds, (list, oldList) => {
      const isFavoritesChanged = !arrayEquals(oldList ?? [], list ?? []);

      if (!isFavoritesChanged) { return; }

      if (isLeaguesEventsBackgroundUpdateAvailable.value) {
        void synchronizeLeaguesEvents({ silent: true, force: true });
      } else {
        lastUpdate.invalidate('favoriteLeaguesEvents');
      }
    });

    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, favoriteLeaguesUpdateInterval);
  }

  return {
    onInit,
    initialRequests,
    setIsBackgroundIdsUpdateAvailable,
    setIsEventsBackgroundUpdateAvailable,
    synchronizeLeaguesIds,
  };
}
