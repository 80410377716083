import type { VInputFileEvent } from '@leon-hub/input-types';
import { assert, isObject } from '@leon-hub/guards';
import { InputEventType } from '@leon-hub/input-types';

import type { VPhotoInputEmits, VPhotoInputProps } from '../types';

export interface VPhotoInputComposable {
  onChange(event: Event): void;
}

export default function useVPhotoInput(props: VPhotoInputProps, emit: VPhotoInputEmits): VPhotoInputComposable {
  const isVInputFileEvent = (value: unknown): value is VInputFileEvent => isObject(value)
    && 'target' in value;

  function takeFiles(event: Event): FileList | null {
    assert(
      isVInputFileEvent(event),
      '[PhotoInput] event.target should be of type HTMLInputElement',
    );
    return event.target?.files ?? null;
  }

  function onChange(event: Event): void {
    const { target } = event;

    assert(target instanceof HTMLInputElement);
    const files = takeFiles(event);

    emit(InputEventType.CHANGE, {
      target: {
        name: props.name,
        files,
      },
    });
  }

  return {
    onChange,
  };
}
