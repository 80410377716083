import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert, isNumber, isOptionalString } from '@leon-hub/guards';

import type { CurrencySelectProps } from 'web/src/modules/money/components/CurrencySelect/types';

import type { FormWrapperWidgetProperties, RelatedCountryCodeField } from '../../../types';
import { getOptionsRelatedToSiblingFields, getWrapperHintProperties } from '../../../utils';

interface UseFormCurrencySelect {
  componentProps: ComputedRef<CurrencySelectProps>;
}

export default function useFormCurrencySelect(
  props: FormWrapperWidgetProperties<CurrencySelectProps & RelatedCountryCodeField>,
): UseFormCurrencySelect {
  const componentProps = computed<CurrencySelectProps>(() => {
    const {
      name,
      value,
      options,
      title,
      error,
      disabled,
      hintMessage,
      extraProperties,
      autofocus,
    } = props;
    assert(isOptionalString(value), `Unexpected value type: ${typeof value}`);

    const computedOptions: Partial<CurrencySelectProps & RelatedCountryCodeField> = options ?? {};
    const { relatedCountryFieldId, ...restOptions } = computedOptions;
    let combinedOptions: Partial<CurrencySelectProps> = { ...restOptions };
    combinedOptions = getOptionsRelatedToSiblingFields<CurrencySelectProps>('countryCode', relatedCountryFieldId, extraProperties?.formData, combinedOptions);

    return {
      name,
      selectedValue: value,
      label: title,
      disabled,
      ...combinedOptions,
      loaderDelay: options?.loaderDelay && !isNumber(options.loaderDelay)
        ? parseInt(options.loaderDelay, 10) : undefined,
      ...getWrapperHintProperties({ options, error, hintMessage }),
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
