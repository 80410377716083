<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';

import type { CurrencyInputProps } from 'web/src/components/Input';
import { CurrencyInput } from 'web/src/components/Input/components';
import { useBaseInputEvents } from 'web/src/components/Input/composables';

import useSmartCurrencyInput from './useSmartCurrencyInput';

withDefaults(defineProps<Omit<CurrencyInputProps, 'currency' | 'currencyRight'>>(), {
  maximumFractionDigits: 2,
});

const emits = defineEmits<BaseInputEmits>();

const { currencyRightSign, currencyLeftSign } = useSmartCurrencyInput();

const {
  emitFocus,
  emitBlur,
  emitChange,
  emitInput,
} = useBaseInputEvents(emits);
</script>

<template>
  <CurrencyInput v-auto-id="'SmartCurrencyInput'"
    :autofocus="autofocus"
    :name="name"
    :disabled="disabled"
    :label="label"
    :value="value"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :maximum-fraction-digits="maximumFractionDigits"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :currency="currencyLeftSign"
    :currency-right="currencyRightSign"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
