import { AppEmitter } from "@leon-hub/app-emitter";
import { assert } from "@leon-hub/guards";
import { AbstractErrorCode, AbstractError, normalizeError } from "@leon-hub/errors";
import { Deferred } from "@leon-hub/utils";
import { defineModule } from "@leon-hub/define-module";
class AppModuleApiProvider {
  static reset() {
    AppModuleApiProvider.api = {
      bus: new AppEmitter()
    };
  }
  static getApi() {
    const { api } = AppModuleApiProvider;
    assert(api, `Call explicitly ${AppModuleApiProvider.name}.reset() before using.`);
    return api;
  }
}
const _AppModuleErrorCode = class _AppModuleErrorCode extends AbstractErrorCode {
  constructor(code) {
    super(code);
  }
};
_AppModuleErrorCode.UNEXPECTED_APP_MODULE_ERROR = /* @__PURE__ */ new _AppModuleErrorCode("UNEXPECTED_APP_MODULE_ERROR");
let AppModuleErrorCode = _AppModuleErrorCode;
class AppModuleError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: (options == null ? void 0 : options.code) ?? AppModuleErrorCode.UNEXPECTED_APP_MODULE_ERROR
    });
  }
}
function useAsyncWrapper(asyncEsModuleCallback, getApi, useArgs) {
  const d = new Deferred();
  const cache = {};
  function wrapGetter(key) {
    if (!cache[key]) {
      cache[key] = async function(...rest) {
        const outerApi = await d.promise;
        if (typeof outerApi === "undefined") {
          throw new TypeError(`Unable to get property '${key}' of undefined`);
        }
        return outerApi[key].apply(this, rest);
      };
    }
    return cache[key];
  }
  if (typeof asyncEsModuleCallback !== "undefined") {
    const asyncEsModule = asyncEsModuleCallback();
    const calculatedArgs = useArgs ? useArgs() : [];
    asyncEsModule.then(({ useModule }) => {
      const result = useModule.call(null, getApi(), ...calculatedArgs);
      if (result instanceof Promise) {
        result.then(d.resolve, d.reject);
      } else {
        d.resolve(result);
      }
    });
  }
  return new Proxy({}, {
    get(target, key) {
      if (typeof key === "string") {
        return wrapGetter(key);
      }
      throw new Error(`Unexpected module key: ${String(key)}`);
    }
  });
}
function loadModule(getApi, asyncModule, condition, options) {
  if (condition) {
    const api = getApi();
    if (options == null ? void 0 : options.preload) {
      asyncModule().catch((err) => {
        api.bus.emit("log:error", new AppModuleError({
          message: "Unable to preload async app module",
          cause: err
        }));
      });
    }
    return () => useAsyncWrapper(
      asyncModule,
      getApi,
      options == null ? void 0 : options.args
    );
  }
  return () => useAsyncWrapper(void 0, getApi, options == null ? void 0 : options.args);
}
function createAppModuleLoaderFactory(getApi) {
  return loadModule.bind(null, getApi);
}
function defineAppModule(name, cb) {
  return defineModule((...rest) => {
    const options = rest[0];
    try {
      return cb(...[
        options,
        ...rest.slice(1)
      ]);
    } catch (err) {
      throw new AppModuleError({
        message: `Unable to initialize "${name}" module`,
        cause: normalizeError(err)
      });
    }
  });
}
export {
  AppModuleApiProvider,
  createAppModuleLoaderFactory,
  defineAppModule
};
