import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyGamesCategoryItem,
  LobbyItemSdk,
} from 'web/src/modules/lobby/types';

export default function isLobbyGamesItem(input: LobbyItemSdk): input is Writeable<LobbyGamesCategoryItem> {
  return input.typeId === LobbyItemType.GAMES_CATEGORY;
}
