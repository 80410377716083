import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { EventActionGoogleAnalytics, useAnalytics, useGoogleAnalytics } from '@core/analytics';
import { useI18n } from '@core/i18n';
import { useUserStore } from '@core/user';

import type { VButtonProps } from '@components/buttons';

import type { VSwiperRef, VSwiperScrollEvent } from 'web/src/components/Swiper/VSwiper/types';
import type { OnboardingItem, OnboardingSlide, OnboardingSlides } from 'web/src/modules/onboarding/types';
import type { OnboardingConfig } from 'web/src/modules/onboarding/utils/getOnboardingConfig';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useOnboardingStore from 'web/src/modules/onboarding/store/useOnboardingStore';
import getOnboardingConfig from 'web/src/modules/onboarding/utils/getOnboardingConfig';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';

interface OnboardingComposable {
  swiper: Ref<VSwiperRef | undefined>;
  isContentLoaded: Ref<boolean>;
  onboardingSlides: Ref<readonly Pick<OnboardingSlide, 'content'>[]>;
  buttonLabel: Ref<string>;
  isReferralProgram: Ref<boolean>;
  buttonProperties: Ref<Pick<VButtonProps, 'fullWidth' | 'isLoading' | 'label'>>;
  onboardingId: Ref<string>;
  onboardingConfig: Ref<OnboardingConfig>;
  isLastSlide: Ref<boolean>;
  isPreventOtherPushGTM: Ref<boolean>;

  handleClickSwiperButton(): Promise<void>;

  runOnMounted(): Promise<void>;

  runBeforeUnmount(): void;

  onScroll({ activeSlide }: VSwiperScrollEvent): void;

  getOnboardingById(id: string): Ref<Optional<OnboardingItem>>;

  getOnboardingSlides(id: string): Ref<OnboardingSlides>;

  handleReferralProgramClick(): void;
}

export default function useOnboarding(): OnboardingComposable {
  const { pushGTM } = useGoogleAnalytics();
  const onBoardingStore = useOnboardingStore();
  const router = useRouter();
  const userStore = useUserStore();
  const analytics = useAnalytics();
  const { $translate } = useI18n();
  const referralProgramStore = useReferralProgramStore();

  const onboardingItems = toRef(onBoardingStore, 'onboardingItems');
  const swiper = toRef(onBoardingStore, 'swiper');
  const isPreventOtherPushGTM = toRef(onBoardingStore, 'isPreventOtherPushGTM');

  const onboardingType = String(router?.getParam('id') || '');
  const activeSlideIndex = ref(0);
  const isContentLoaded = ref(false);
  const isButtonLoading = ref(false);
  const isSwiperFinished = ref(false);

  const onboardingId = computed(() => String(router?.getParam('id') || '') as OnboardingIdsEnum);
  const onboardingSlides = computed(() => getOnboardingSlides(onboardingId.value).value);
  const isLastSlide = computed(() => activeSlideIndex.value === onboardingSlides.value.length - 1);
  const isReferralProgram = computed(() => onboardingId.value === OnboardingIdsEnum.REFERRAL_PROGRAM);
  const onboardingConfig = computed(() => getOnboardingConfig(onboardingId.value));

  const buttonLabel = computed(() => {
    let title = $translate('WEB2_ONBOARDING_SWIPER_BUTTON').value;
    if (isLastSlide.value) {
      title = $translate('WEB2_ONBOARDING_SWIPER_LAST_BUTTON').value;
    }
    if (isReferralProgram.value) {
      title = $translate('WEB2_REFERRAL_ONBOARDING_BUTTON_NEXT').value;
    }
    if (isReferralProgram.value && isLastSlide.value) {
      title = $translate('WEB2_REFERRAL_ONBOARDING_BUTTON_JOIN').value;
    }
    return title;
  });

  const buttonProperties = computed(() => ({
    fullWidth: true,
    isLoading: isButtonLoading.value && isLastSlide.value,
    label: buttonLabel.value,
  }));

  const analyticsOptions = computed(() => ({
    event_label: onboardingId.value,
    step: `${activeSlideIndex.value + 1}/${onboardingSlides.value.length}`,
  }));

  function handleClickSwiperButtonLast(): void {
    if (onboardingConfig.value?.routeBack) {
      void router.replace({ name: onboardingConfig.value?.routeBack });
      return;
    }

    router.back();
  }

  async function handleClickSwiperButton(): Promise<void> {
    if (isLastSlide.value) {
      pushGTM(
        AnalyticsEvent.ONBOARDING_COMPLETED,
        {
          ...analyticsOptions.value,
          click_text: buttonLabel.value,
          event_action: EventActionGoogleAnalytics.ONBOARDING_COMPLETION,
        },
      );
      isSwiperFinished.value = true;

      if (isReferralProgram.value) {
        await handleClickForReferralProgram();
      } else {
        handleClickSwiperButtonLast();
      }
    } else {
      swiper.value?.slideToNextSlide();
    }
  }

  async function handleClickForReferralProgram(): Promise<void> {
    isButtonLoading.value = true;
    try {
      const confirmed = await referralProgramStore.confirmation();

      if (confirmed) {
        void router.replace({ name: RouteName.REFERRAL_PROGRAM });
      }
    } finally {
      isButtonLoading.value = false;
    }
  }

  function onScroll({ activeSlide }: VSwiperScrollEvent): void {
    if (
      activeSlideIndex.value !== activeSlide
      && Math.abs(activeSlideIndex.value - activeSlide) > 0
      && !isPreventOtherPushGTM.value
      && !!onboardingSlides.value.length
    ) {
      pushGTM(
        AnalyticsEvent.ONBOARDING,
        {
          ...analyticsOptions.value,
          click_text: activeSlide > activeSlideIndex.value ? 'Next' : 'Previous',
          event_action: EventActionGoogleAnalytics.ONBOARDING_BUTTON_CLICK,
        },
      );
    }
    activeSlideIndex.value = (activeSlide < 0 && !isLastSlide.value) ? activeSlideIndex.value : activeSlide;
  }

  async function runOnMounted() {
    if (!onboardingSlides.value.length) {
      handleClickSwiperButtonLast();
      return;
    }

    isContentLoaded.value = true;

    if (!isReferralProgram.value)
      await setAsShown();
  }

  async function setAsShown(): Promise<void> {
    const autoShow = router.getQuery('autoShow');
    let value = true;
    if (typeof autoShow === 'string' && (autoShow.toLowerCase() === 'true' || autoShow.toLowerCase() === 'false')) {
      value = JSON.parse(autoShow.toLowerCase());
    }

    const config = getOnboardingConfig(onboardingId.value);
    if (config.customerConfig) {
      await userStore.setAdditionalPropsUser({
        value: {
          value,
        },
        config: config.customerConfig,
      });
    }
  }

  function runBeforeUnmount(): void {
    handleOnboardingMetrika();
  }

  function handleOnboardingMetrika(): void {
    const slides = getOnboardingSlides(onboardingType).value.length;
    const onboarding: Record<string, Record<string, string>> = {};
    onboarding[onboardingType] = isSwiperFinished.value ? { clickFinalButton: 'true' } : { closeOnboarding: `${activeSlideIndex.value + 1}/${slides}` };
    analytics.push(AnalyticsEvent.Z_ONBOARDING, {
      onboarding,
    });
  }

  function getOnboardingById(id: string): Ref<Optional<OnboardingItem>> {
    return computed(() => onboardingItems.value[id]);
  }

  function getOnboardingSlides(id: string): Ref<OnboardingSlides> {
    const currentOnboarding = getOnboardingById(id);
    return computed(() => (currentOnboarding && currentOnboarding.value ? currentOnboarding.value.slides : []));
  }

  function handleReferralProgramClick(): void {
    if (process.env.VUE_APP_FEATURE_REFERRAL_BANNER_LEONRU_STYLE_ENABLED) {
      void router.push({
        name: RouteName.CMS_PROMO_TERMS,
        params: { cmsKey: 'FRIEND_LEON_RULES' },
      });
    } else {
      void router.push({ name: RouteName.REFERRAL_PROGRAM_AGREEMENT });
    }
  }

  return {
    swiper,
    isContentLoaded,
    onboardingSlides,
    buttonLabel,
    isReferralProgram,
    buttonProperties,
    onboardingId,
    onboardingConfig,
    isLastSlide,
    isPreventOtherPushGTM,
    handleClickSwiperButton,
    runBeforeUnmount,
    runOnMounted,
    onScroll,
    getOnboardingById,
    getOnboardingSlides,
    handleReferralProgramClick,
  };
}
