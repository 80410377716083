<script setup lang="ts">
import { computed } from 'vue';

import { AlertIconName, IconName } from '@leon-hub/icons';

import type { ConfirmDialog } from '@core/dialogs';
import { DialogAction } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ButtonKind } from '@components/buttons';
import { JumbotronIconKind } from '@components/jumbotron';

import type {
  PaymentsWithdrawalCancelSuccessModalEmits,
  PaymentsWithdrawalCancelSuccessModalProps,
} from 'web/src/modules/payments/components/PaymentsWithdrawalCancelSuccessModal/types';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { getImageOrIcon } from 'web/src/modules/icons';

const props = defineProps<PaymentsWithdrawalCancelSuccessModalProps>();
const emit = defineEmits<PaymentsWithdrawalCancelSuccessModalEmits>();

const { $translate } = useI18n();

function onButtonClick({ action }: { action: DialogAction }): void {
  if (action === DialogAction.MODAL_CLOSE) {
    emit('close');
  }
}

const modalProperties = computed<ConfirmDialog>(() => ({
  isInnerModal: !!'1',
  ...getImageOrIcon({
    icon: IconName.ATTENTION_OUTLINE,
    alertIcon: AlertIconName.Success,
  }),
  iconKind: JumbotronIconKind.SUCCESS,
  fullHeight: false,
  isProfileLayout: !!'1' && props.isInProfile,
  title: $translate('WEB2_TRANSACTION_HISTORY_CANCELED_PAYMENT').value,
  buttons: [
    {
      action: DialogAction.MODAL_CLOSE,
      kind: ButtonKind.PRIMARY,
      label: $translate('WEB2_CLOSE').value,
    },
  ],
}));
</script>

<template>
  <ConfirmModal v-auto-id="'PaymentsWithdrawalCancelSuccessModal'"
    :modal="modalProperties"
    @button-click="onButtonClick"
    @close="emit('close')"
  >
    <div :class="$style['deposits-success']">
      <div :class="$style['deposits-success__text']">
        {{ text }}
      </div>
      <div :class="$style['deposits-success__value']">
        +{{ secondaryText }}
      </div>
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.deposits-success {
  &__text {
    @include paymentModalSuccessText;
  }

  &__value {
    @include paymentModalSuccessValue;
  }
}
</style>
