import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fromMillisecondsToSeconds } from 'web/src/modules/registration/utils/fromMillisecondsToSeconds';

const useRegistrationIdleRedirectStore = defineStore('registration-idle-redirect', () => {
  const startTimeRegistration = ref(0);
  const isRedirectCalled = ref(false);

  function setStartTimeRegistration(value: number): void {
    startTimeRegistration.value = value;
  }

  function getDurationRegistrationInSeconds(): number {
    return fromMillisecondsToSeconds(Date.now() - startTimeRegistration.value);
  }

  return {
    isRedirectCalled,
    startTimeRegistration,
    getDurationRegistrationInSeconds,
    setStartTimeRegistration,
  };
});

export default useRegistrationIdleRedirectStore;
