import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import { GqlApiAccessDeniedError, GqlApiBatchedSubRequestError } from '@leon-hub/api';
import {
  doCalculateRound,
  getActiveOrAvailableCampaigns,
  getRounds,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';
import { useWebSocketsConfig } from '@core/site-config';
import { useWebSockets } from '@core/websockets';

import type {
  ActiveOrAvailableCampaigns,
  RoundResultDetails,
  RoundsQueryDocument,
} from 'web/src/modules/fortune-wheel/types/types';

const useFortuneWheelStore = defineStore('fortune-wheel', () => {
  const apiClient = useGraphqlClient();
  const { subscribeAuthorized } = useWebSockets();
  const { isWheelNotificationEnabled } = useWebSocketsConfig();
  const { isLoggedIn } = useIsLoggedIn();

  const salt = '-20503cfe';

  // state
  const roundState = ref<RoundsQueryDocument | null>(null);
  const roundWin = ref(0);
  const roundWinIndex = ref(0);
  const roundWinDetails = ref<RoundResultDetails | null>(null);
  const campaign = ref<ActiveOrAvailableCampaigns | null>(null);
  const fortuneWheelId = ref(0);
  const campaignState = ref('');
  const categoryId = ref('');
  const actionUrl = ref('');
  const showNotification = ref(false);
  const isAdditionalRoundActive = ref(false);
  const showSpinButton = ref(false);
  const isFortuneWheelAvailable = ref(true);

  const socketPollingTimeout = ref(5000);

  // getter
  const roundKey = computed(() => `${roundState.value?.key}${salt}` || '');

  // actions
  async function fetchActiveOrAvailableCampaigns(): Promise<void> {
    if (!isLoggedIn.value) {
      return;
    }

    try {
      const response = await getActiveOrAvailableCampaigns(
        apiClient,
        (node) => node.queries.promotions.getActiveOrAvailableCampaigns,
        undefined,
        {
          silent: true,
          priority: RequestOptionsPriority.LOW,
        },
      );

      const { campaigns } = response;

      campaign.value = [
        ...campaigns,
      ];

      const campaignComputed = campaign.value.at(0);
      if (campaignComputed) {
        setFortuneWheelId(campaignComputed.campaignId);
        setCampaignState(campaignComputed.state);
        setActionUrl(campaignComputed.actionUrl);
        setCategoryId(campaignComputed.categoryId);
        setShowNotificationStatus(campaignComputed.showNotification);
      }
    } catch (error) {
      if (error instanceof GqlApiBatchedSubRequestError && !(error instanceof GqlApiAccessDeniedError)) {
        logger.error('Something goes wrong with active or available campaigns', error);
      }
    }
  }

  async function fetchRounds(campaignId: number): Promise<void> {
    try {
      const response = await getRounds(
        apiClient,
        (node) => node.queries.bonusGame.getRounds,
        {
          options: {
            campaignId,
          },
        },
        {
          silent: true,
        },
      );

      roundState.value = {
        ...response,
      };

      setFortuneWheelAvailable(roundState.value.round.active);
    } catch (error) {
      if (error instanceof GqlApiBatchedSubRequestError) {
        logger.error('Something goes wrong with fetch rounds', error);
      }
    }
  }

  async function calculateRound(campaignId: number, key: string): Promise<void> {
    try {
      const response = await doCalculateRound(
        apiClient,
        (node) => node.mutations.bonusGame.calculateRound,
        {
          options: {
            campaignId,
            key,
          },
        },
      );

      if (response.rewardId) {
        roundWin.value = response.rewardId;

        const rewards = roundState.value?.round.rewards ?? [];
        roundWinIndex.value = rewards.findIndex((reward) => reward.rewardId === roundWin.value);

        roundWinDetails.value = response.details;
      }
    } catch (error) {
      if (error instanceof GqlApiBatchedSubRequestError) {
        logger.error('Something goes wrong with calculate round', error);
      }
    }
  }

  function setFortuneWheelId(id: number): void {
    fortuneWheelId.value = id;
  }

  function setCampaignState(state: string): void {
    campaignState.value = state;
  }

  function setActionUrl(url: string): void {
    actionUrl.value = url;
  }

  function setCategoryId(id: string): void {
    categoryId.value = id;
  }

  function setShowNotificationStatus(status: boolean): void {
    showNotification.value = status;
  }

  function setFortuneWheelAvailable(value: boolean): void {
    isFortuneWheelAvailable.value = value;
  }

  function setShowSpinButton(value: boolean): void {
    showSpinButton.value = value;
  }

  function subscribeOnBonusGameRoundEvent(): void {
    subscribeAuthorized({
      method: 'onBonusGameRoundEvent',
      onMessage: (data) => {
        const {
          additional,
        } = data.onBonusGameRoundEvent;

        if (additional) {
          setShowSpinButton(true);
          isAdditionalRoundActive.value = true;
        }
      },
      isEnabled: isWheelNotificationEnabled,
      polling: {
        timeout: socketPollingTimeout,
        callback: () => fetchActiveOrAvailableCampaigns(),
      },
    });
  }

  function init(): void {
    subscribeOnBonusGameRoundEvent();
  }

  init();

  return {
    roundState,
    roundKey,
    roundWinIndex,
    roundWinDetails,
    campaign,
    campaignState,
    fortuneWheelId,
    categoryId,
    actionUrl,
    showNotification,
    isAdditionalRoundActive,
    showSpinButton,
    isFortuneWheelAvailable,
    fetchRounds,
    calculateRound,
    fetchActiveOrAvailableCampaigns,
    setFortuneWheelId,
    setCampaignState,
    setActionUrl,
    setCategoryId,
    setShowNotificationStatus,
    setShowSpinButton,
  };
});

export default useFortuneWheelStore;
