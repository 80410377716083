import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

import type { PasswordValidateTemplate, PasswordValidateValidRule, PasswordValidatorProps } from '../types';
import { PasswordValidateTemplateItemStatus } from '../enums';
import getRulesFromNotPassedGroups from './utils/getRulesFromNotPassedGroups';

interface VPasswordValidatorComposable {
  templates: Ref<PasswordValidateTemplate[]>;
  isValidPassword: Ref<boolean>;
}

export default function useVPasswordValidator(
  props: RequiredField<PasswordValidatorProps, 'groups' | 'password'>,
): VPasswordValidatorComposable {
  const { $translate } = useI18n();

  const rules = computed<PasswordValidateValidRule[]>(
    () => getRulesFromNotPassedGroups(props.groups, props.password),
  );
  const isValidPassword = computed<boolean>(() => {
    for (const rule of rules.value) {
      if (!rule.isValid) {
        return false;
      }
    }
    return true;
  });

  const templates = computed<PasswordValidateTemplate[]>(() => {
    if (!isValidPassword.value) {
      return rules.value.map((rule) => ({
        message: rule.message,

        status: rule.isValid
          ? PasswordValidateTemplateItemStatus.Success
          : (props.isFocused
              ? PasswordValidateTemplateItemStatus.Default
              : PasswordValidateTemplateItemStatus.Error),
      }));
    }

    if (props.error) {
      return [
        {
          message: props.error,
          status: PasswordValidateTemplateItemStatus.Error,
          icon: false,
        },
      ];
    }

    return [
      {
        message: props.successMessage || $translate('WEB2_VALID_STRONG_PASSWORD').value,
        status: PasswordValidateTemplateItemStatus.Success,
      },
    ];
  });

  return {
    isValidPassword,
    templates,
  };
}
