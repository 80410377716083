<script lang="ts" setup>
import { onMounted } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonHeight, ButtonKind, VButton } from '@components/buttons';
import { VImage } from '@components/image';
import { VIcon } from '@components/v-icon';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';

import type { PaymentBonusItemEmits, PaymentBonusItemProps } from './types';
import { usePaymentBonusItem } from './composables';

const props = withDefaults(defineProps<PaymentBonusItemProps>(), {
  isSelected: false,
});
const emit = defineEmits<PaymentBonusItemEmits>();

const {
  animatedMask,
  isExpanded,
  isStatic,
  isImageLoaded,
  isDetailsAvailable,
  bonusImage,
  description,
  emitSelectComplete,
  emitSelectedBonus,
  onMount,
  onToggleExpand,
  onDetailClick,
  chooseButtonHandle,
  setImageLoaded,
} = usePaymentBonusItem(props, emit);

onMounted(onMount);
</script>

<template>
  <div v-auto-id="'PaymentBonusItem'" :class="$style['payment-bonus-item']">
    <VImage
      :class="$style['payment-bonus-item__img']"
      v-bind="bonusImage"
      @click="emitSelectedBonus"
      @image-loaded="setImageLoaded"
    />
    <div
      v-show="isSelected"
      ref="animatedMask"
      :class="{
        [$style['payment-bonus-item__mask']]: true,
        [$style['payment-bonus-item__mask--static']]: isStatic,
      }"
      @click="emitSelectComplete"
    />
    <VIcon
      v-show="isSelected"
      :class="{
        [$style['payment-bonus-item__mask-icon']]: true,
        [$style['payment-bonus-item__mask-icon--clicked']]: isSelected,
      }"
      :size="IconSize.SIZE_44"
      :name="IconName.CHECK_FILLED"
      @click="emitSelectComplete"
    />
    <div :class="$style['payment-bonus-item__wrapper']">
      <div
        :class="$style['payment-bonus-item__text-box']"
        @click="onToggleExpand"
      >
        <div
          :class="{
            [$style['payment-bonus-item__name']]: true,
            [$style['payment-bonus-item__name--skeleton']]: !isImageLoaded,
          }"
        >
          <template v-if="isImageLoaded">
            {{ bonusItem.campaignName }}
          </template>
        </div>
        <div :class="{ [$style['payment-bonus-item__desc--skeleton']]: !isImageLoaded }">
          <template v-if="isImageLoaded">
            <VDynamicContent
              :class="{
                [$style['payment-bonus-item__desc']]: true,
                [$style['payment-bonus-item__desc--hidden']]: !isExpanded,
              }"
              no-margins
              :content="description"
            />
            <div
              v-show="isExpanded"
              :class="$style['payment-bonus-item__buttons']"
            >
              <VButton
                v-if="isDetailsAvailable"
                :kind="ButtonKind.SECONDARY"
                :icon-name="IconName.INFO_OUTLINE"
                :height="ButtonHeight.MEDIUM"
                :is-uppercase="false"
                :label="$t('WEB2_CBC_PROMO_MORE')"
                @click.stop="onDetailClick"
              />
              <VButton
                :class="{ [$style['single']]: !isDetailsAvailable }"
                :icon-name="IconName.PROMOS"
                :height="ButtonHeight.MEDIUM"
                :is-uppercase="false"
                :label="$t('WEB2_FEEDBACK_FORM_THEME_PLACEHOLDER')"
                @click.stop="chooseButtonHandle"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="isImageLoaded"
        :class="{
          [$style['payment-bonus-item__button']]: true,
          [$style['payment-bonus-item__button--expand']]: isExpanded,
        }"
      >
        <VButton
          :height="ButtonHeight.TINY"
          :kind="isExpanded ? ButtonKind.SECONDARY : ButtonKind.TRANSPARENT"
          :icon-name="isExpanded ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
          @click="onToggleExpand"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/components/Skeleton/styles/skeleton';

@keyframes mask-animation {
  from {
    border-radius: 50%;
    transform: scaleX(0.5);
  }

  to {
    border-radius: 5px 5px 0 0;
    transform: scaleX(1);
  }
}

.payment-bonus-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  margin: 12px 0;

  &__img {
    position: relative;
    width: 100%;
    height: 110px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: calc(100% - 110px);
    left: 0;
    cursor: pointer;
    background-color: var(--BrandDefault);
    opacity: 0.6;
    animation: mask-animation 200ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards;

    &--static {
      animation: none;
    }

    &-icon {
      position: absolute;
      top: 30px;
      right: calc(50% - 22px);
      color: var(--BrandText);
      cursor: pointer;
      opacity: 0;
      transform: scale(0.6);

      &--clicked {
        opacity: 1;
        transform: scale(1);
        transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 12px;
    background-color: var(--Layer1);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__text-box {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  &__name {
    @include medium\18\20;

    padding-right: 44px;
    margin-bottom: 4px;
    color: var(--TextDefault);

    &--skeleton {
      @include skeletonFont(40%, 20px, 10px, var(--TextDefault));
    }
  }

  &__desc {
    @include regular\14\24\025;

    min-height: 16px;
    padding-right: 44px;
    color: var(--TextPrimary);

    &--hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--expand {
      white-space: initial;
    }

    &--skeleton {
      @include skeletonFont(100%, 24px, 7px, var(--TextPrimary));
    }
  }

  &__button {
    position: absolute;
    top: 28px;
    right: 12px;
    transition: all 0.15s ease-in-out;

    &--expand {
      top: 12px;
      color: var(--Layer2);
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    padding: 12px 44px 0 0;

    > :first-child {
      margin-right: 12px;
    }

    > :last-child {
      flex: 1;
    }

    > button.single {
      margin-right: 44px;
    }
  }
}
</style>
