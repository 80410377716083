import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useI18n } from '@core/i18n';

import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/modules/core/utils/countdown/types';
import type {
  PromotionCountdownEmits,
  PromotionCountdownProps,
} from 'web/src/modules/promotions/components/PromotionCountdown/types';
import useCashbackTimeCountdown from 'web/src/modules/promotions/components/PromotionCountdown/composable/useCashbackTimeCountdown';

interface PromotionCountdownComposable {
  counterTitle: Ref<string>;
  timeName: Ref<DisplayedTimeLeft | null>;
  timeNameAlternative: Ref<DisplayedTimeLeft | null>;
  isHourShown: Ref<boolean>;
  isMinutesShown: Ref<boolean>;
  timeLeft: Ref<ValueOfTimeLeft>;
  isShown: Ref<boolean>;
}
export default function usePromotionCountdown(
  props: PromotionCountdownProps,
  emit: PromotionCountdownEmits,
): PromotionCountdownComposable {
  const { $translate } = useI18n();

  const targetTime = computed(() => props.timestamp);

  const {
    timeName,
    timeNameAlternative,
    isHourShown,
    isMinutesShown,
    timeLeft,
    isShown,
  } = useCashbackTimeCountdown(
    targetTime,
    toRef(props, 'isCashback'),
    () => emit('send'),
  );

  const counterTitle = computed(() => {
    if (props.isBeforeStart) {
      return props.isCashback
        ? $translate('WEB2_CASHBACK_BEFORE_START').value
        : $translate('WEB2_PROMOTION_BEFORE_START').value;
    }
    return props.isCashback ? $translate('WEB2_CASHBACK_BEFORE_END').value : $translate('WEB2_PROMOTION_BEFORE_END').value;
  });

  return {
    counterTitle,
    timeName,
    timeNameAlternative,
    isHourShown,
    isMinutesShown,
    timeLeft,
    isShown,
  };
}
