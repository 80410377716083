import type { RouteLocationRaw } from 'vue-router';
import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import {
  CustomerConfig,
  doCreatePinCode,
  doDeletePinCode,
  doVerifyPinCode,
  getPinCodeStatus,
  PinCodePlace,
  PinCodeState as PinCodeStateEnum,
  PreviousRequestHasNotExpiredExceptionCode,
  RemoteApiErrorExceptionCode,
  resetPinCodeEmail,
  resetPinCodePhone,
  sendRestorePinLinkToEmail,
  sendRestorePinLinkToPhone,
} from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';
import { useCustomerDataStore } from '@core/customer';
import { useErrorsConverter } from '@core/errors';
import { useSiteConfigStore } from '@core/site-config';
import { useUserStore } from '@core/user';

import type { PinCodeErrorType, PinCodeStep, RestorePinByPhoneMethod } from 'web/src/modules/pin-code/store/types';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';

export const IS_PIN_CODE_MODAL_CREATE_AVAILABLE = 'IS_PIN_CODE_MODAL_CREATE_AVAILABLE';
export const PIN_CODE_DATA = 'PIN_CODE_DATA';

const usePinCodeStore = defineStore('pin-code', () => {
  const prevStep = ref<Maybe<PinCodeStep>>(null);
  const step = ref<Maybe<PinCodeStep>>(null);
  const loading = ref<boolean>(false);
  const error = ref<string>('');
  const status = ref<Maybe<PinCodeStateEnum> | undefined>(null);
  const pinCodeModalCounter = ref<number>(0);
  const pinCodeRequestedAtLogin = ref<boolean>(false);
  const bannedUntil = ref<number>(0);
  const pinCodePlace = ref<Maybe<PinCodePlace>>(null);
  const resetToken = ref<string>('');
  const isPhoneReset = ref<boolean>(false);
  const resetErrorMessage = ref<string>('');
  const routeFrom = ref<Maybe<RouteLocationRaw>>(null);
  const showCompleteRegistrationFields = ref<boolean>(false);
  const wasPinCodeSetOnce = ref<boolean>(false);
  const isRestoreByEmailSent = ref<boolean>(false);
  const isRestoreByPhoneSent = ref<boolean>(false);
  const code = ref<string>('');
  const isStatusIgnored = ref(false);

  const customerDataStore = useCustomerDataStore();

  const apiClient = useGraphqlClient();

  const localStorageManager = useLocalStorageManager();

  const { isLoggedIn } = useIsLoggedIn();

  const {
    convertToBaseError,
  } = useErrorsConverter();

  const {
    setAdditionalPropsUser,
    doPinCodeLogin,
  } = useUserStore();

  const pinCodeRequestedPlaces = toRef(useSiteConfigStore(), 'pinCodeRequestedPlaces');

  const createPinCodeModalCounter = computed(() => customerDataStore.createPinCodeModalCounter);
  const isPinCodeRequestedOnLogin = computed(() => customerDataStore.isPinCodeRequestedOnLogin);

  const setStep = (value: Maybe<PinCodeStep>): void => {
    prevStep.value = step.value;
    step.value = value;
  };

  const setStepWithLS = (value: Maybe<PinCodeStep>): void => {
    setStep(value);

    const data = JSON.stringify({
      prevStep: prevStep.value,
      routeFrom: routeFrom.value,
    });

    localStorageManager.setItem(PIN_CODE_DATA, data);
  };

  const setStepCountdown = () => {
    if (!isStatusIgnored.value) {
      setStepWithLS('COUNTDOWN');
    }
  };

  const setCountdown = (value: string): void => {
    const date = new Date(value);

    bannedUntil.value = date.getTime();
    setStepCountdown();
  };

  const setStatusIgnored = (value: boolean) => {
    isStatusIgnored.value = value;
  };

  const setPinCodeModalCounter = (value: number): void => {
    pinCodeModalCounter.value = value;
  };

  const setPinCodeRequestedAtLogin = (value?: boolean): void => {
    pinCodeRequestedAtLogin.value = value ?? isPinCodeRequestedOnLogin.value;
  };

  const setBannedUntil = (value: number): void => {
    bannedUntil.value = value;
  };

  const setShowCompleteRegistrationFields = (value: boolean): void => {
    showCompleteRegistrationFields.value = value;
  };

  const setCode = (value: string): void => {
    code.value = value;
  };

  const setResetErrorMessage = (value: string): void => {
    resetErrorMessage.value = value;
  };

  const setError = (value: string): void => {
    error.value = value;
  };

  const setPinCodePlace = (value: PinCodePlace): void => {
    pinCodePlace.value = value;
  };

  const setIsRestoreByEmailSent = (value: boolean): void => {
    isRestoreByEmailSent.value = value;
  };

  const setIsRestoreByPhoneSent = (value: boolean): void => {
    isRestoreByPhoneSent.value = value;
  };

  const setRouteFrom = (value: Maybe<RouteLocationRaw>): void => {
    routeFrom.value = value;

    const data = JSON.stringify({
      prevStep: prevStep.value,
      routeFrom: routeFrom.value,
    });

    localStorageManager.setItem(PIN_CODE_DATA, data);
  };

  const handleError = (rawError: unknown): void => {
    const err = convertToBaseError(rawError);
    const errorCode = err.code.toString();
    const { extensions } = rawError as PinCodeErrorType;
    const availableErrorTypeForCountdown: string[] = [
      RegistrationApiErrorCode.TOO_MANY_ATTEMPTS,
      RemoteApiErrorExceptionCode.PIN_CODE_IS_BANNED,
    ];

    if (availableErrorTypeForCountdown.includes(errorCode) && extensions.bannedUntil) {
      setCountdown(extensions.bannedUntil);
    } else {
      error.value = err.message;
    }

    loading.value = false;
  };

  const resetPinCodeToken = ({ value, phoneReset = false }: { value: string; phoneReset?: boolean }): void => {
    resetToken.value = value;
    isPhoneReset.value = phoneReset;
  };

  const refreshPinCodeStatus = async (): Promise<Maybe<PinCodeStateEnum> | undefined> => {
    if (pinCodeRequestedPlaces.value?.length && isLoggedIn.value) {
      try {
        const data = await getPinCodeStatus(apiClient, (node) => node.queries.pinCode.getPinCodeStatus);

        status.value = data.status;
        wasPinCodeSetOnce.value = data.wasPinCodeSetOnce;

        if (data.status && data.status === PinCodeStateEnum.RESET && step.value !== 'CREATE') {
          setStepWithLS('MODAL_CREATE');
        }

        if (data.bannedUntil) {
          setCountdown(data.bannedUntil);
        } else {
          setBannedUntil(0);
        }

        return data.status;
      } catch (rawError) {
        handleError(rawError);
        throw rawError;
      }
    }

    return undefined;
  };

  const createPinCode = async (value: string): Promise<void> => {
    loading.value = true;

    try {
      await doCreatePinCode(apiClient, (node) => node.mutations.pinCode.createPinCode, {
        options: {
          code: value,
        },
      });
      await refreshPinCodeStatus();
      loading.value = false;
    } catch (rawError) {
      handleError(rawError);
      throw rawError;
    }
  };

  const deletePinCode = async (value: string): Promise<void> => {
    loading.value = true;

    try {
      await doDeletePinCode(apiClient, (node) => node.mutations.pinCode.deletePinCode, {
        options: {
          code: value,
        },
      });
      await refreshPinCodeStatus();
      loading.value = false;
    } catch (rawError) {
      handleError(rawError);
      throw rawError;
    }
  };

  const verifyPinCode = async (value: string): Promise<void> => {
    loading.value = true;

    try {
      await doVerifyPinCode(apiClient, (node) => node.mutations.pinCode.verifyPinCode, {
        options: {
          code: value,
          place: pinCodePlace.value ?? PinCodePlace.UNKNOWN,
        },
      });

      if (pinCodePlace.value === PinCodePlace.PIN_CODE_AT_LOGIN_SETTING) {
        await setAdditionalPropsUser({
          value: {
            value: !pinCodeRequestedAtLogin.value,
          },
          config: CustomerConfig.IS_PIN_CODE_REQUESTED_ON_LOGIN,
        });
        pinCodeRequestedAtLogin.value = !pinCodeRequestedAtLogin.value;
      }

      pinCodePlace.value = null;
      loading.value = false;
    } catch (rawError) {
      handleError(rawError);
      throw rawError;
    }
  };

  const loginPinCode = async (value: string): Promise<void> => {
    loading.value = true;

    try {
      await doPinCodeLogin(value);
      await refreshPinCodeStatus();

      loading.value = false;
      setStepWithLS(null);
    } catch (rawError) {
      handleError(rawError);
      throw rawError;
    }
  };

  const handleSendRestorePinTokenPhone = async (method: RestorePinByPhoneMethod): Promise<void> => {
    await sendRestorePinLinkToPhone(apiClient, (node) => node.mutations.pinCode.sendResetTokenPhone, { options: { method } })
      .catch((originalError) => {
        const err = convertToBaseError(originalError);
        if (!err.code.equals(PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED)) {
          throw originalError;
        }
      });
  };

  const handleSendRestorePinLinkToEmail = async (): Promise<void> => {
    await sendRestorePinLinkToEmail(apiClient, (node) => node.mutations.pinCode.sendResetTokenEmail, { options: {} });
  };

  const resetPinCodeByEmail = async (token: string): Promise<void> => {
    await resetPinCodeEmail(apiClient, (node) => node.mutations.pinCode.resetPinCodeEmail, { options: { token } });
  };

  const resetPinCodeByPhone = async (token: string): Promise<void> => {
    await resetPinCodePhone(apiClient, (node) => node.mutations.pinCode.resetPinCodePhone, { options: { token } });

    await refreshPinCodeStatus();
  };

  const processInit = async () => {
    const pinCodeData = localStorageManager.getItem(PIN_CODE_DATA);

    if (pinCodeData) {
      const data = JSON.parse(pinCodeData);
      setStep(data.prevStep);
      routeFrom.value = data.routeFrom;
    }

    await refreshPinCodeStatus();
    pinCodeModalCounter.value = createPinCodeModalCounter.value;

    const isModalCreateAvailable = localStorageManager.getItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE);

    if (!isModalCreateAvailable) {
      localStorageManager.setItem(IS_PIN_CODE_MODAL_CREATE_AVAILABLE, '1');
    }

    setPinCodeRequestedAtLogin();
  };

  function init(): void {
    if (pinCodeRequestedPlaces.value?.length) {
      void processInit();

      watch(isLoggedIn, (newValue: boolean) => {
        if (newValue) {
          void processInit();
        } else {
          setStepWithLS(null);
          setRouteFrom(null);
          status.value = null;
          pinCodeRequestedAtLogin.value = false;
          showCompleteRegistrationFields.value = false;

          void processInit();
        }
      });
    }
  }

  init();

  return {
    pinCodeRequestedAtLogin,
    pinCodePlace,
    isPhoneReset,
    resetErrorMessage,
    wasPinCodeSetOnce,
    isRestoreByEmailSent,
    isRestoreByPhoneSent,
    refreshPinCodeStatus,
    deletePinCode,
    verifyPinCode,
    loginPinCode,
    step,
    prevStep,
    showCompleteRegistrationFields,
    pinCodeModalCounter,
    routeFrom,
    loading,
    status,
    error,
    bannedUntil,
    code,
    resetToken,
    isStatusIgnored,
    setStep,
    setStepWithLS,
    setRouteFrom,
    setPinCodeModalCounter,
    setBannedUntil,
    setShowCompleteRegistrationFields,
    createPinCode,
    resetPinCodeToken,
    setCode,
    resetPinCodeByEmail,
    resetPinCodeByPhone,
    setResetErrorMessage,
    setPinCodeRequestedAtLogin,
    setPinCodePlace,
    setError,
    setIsRestoreByEmailSent,
    setIsRestoreByPhoneSent,
    handleSendRestorePinTokenPhone,
    handleSendRestorePinLinkToEmail,
    handleError,
    setStatusIgnored,
    setStepCountdown,
    processInit,
  };
});

export default usePinCodeStore;
