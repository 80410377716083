import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';
import { getUserAgent } from '@leon-hub/service-locator-env';

import type {
  VSocialSharingItemEmits,
  VSocialSharingItemProps,
} from 'web/src/components/SocialSharing/VSocialSharingItem/types';

const $window = typeof window !== 'undefined' ? window : null;
export interface VSocialSharingItemComposable {
  networkName: Ref<string>;
  handleClick(): void;
  iconName: Ref<IconNameType>;
}

export default function useVSocialSharingItem(
  props: RequiredField<VSocialSharingItemProps, 'popup'>,
  emit: VSocialSharingItemEmits,
): VSocialSharingItemComposable {
  const popupTop = ref(0);
  const popupLeft = ref(0);
  const popupWindow = ref();
  const popupInterval = ref();

  const networkName = computed(() => props.name.toLowerCase());
  const iconName = computed(() => props.icon?.replace(/_/g, '-') as IconNameType || IconName.GLOBE);

  const resizePopup = () => {
    if ($window) {
      const width = $window.innerWidth
        || document.documentElement.clientWidth
        || $window.screenX;
      const height = $window.innerHeight
        || document.documentElement.clientHeight
        || $window.screenY;
      const systemZoom = width / $window.screen.availWidth;

      popupLeft.value = (width - props.popup.width) / 2 / systemZoom
        + ($window.screenLeft ?? $window.screenX);
      popupTop.value = (height - props.popup.height) / 2 / systemZoom
        + ($window.screenTop ?? $window.screenY);
    }
  };

  const rawLink = computed(() => {
    const userAgent = getUserAgent().toLowerCase();
    const link: string = props.url;

    if (
      networkName.value === 'sms'
      && (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1)
    ) {
      return link.replace(':?', ':&');
    }

    return link;
  });

  const encodedHashtags = computed(() => {
    if (networkName.value === 'facebook' && props.hashtags.length) {
      return `%23${props.hashtags.split(',')[0]}`;
    }
    return props.hashtags;
  });

  const shareLink = computed(() => {
    let link = rawLink.value;

    if (networkName.value === 'twitter' && !props.hashtags.length) {
      link = link.replace('&hashtags=@h', '');
    }

    return link
      .replace(/@u/g, encodeURIComponent(props.urlForSharing))
      .replace(/@t/g, encodeURIComponent(props.title))
      .replace(/@d/g, encodeURIComponent(props.description))
      .replace(/@q/g, encodeURIComponent(props.quote))
      .replace(/@h/g, encodedHashtags.value)
      .replace(/@m/g, encodeURIComponent(props.media));
  });

  const touch = () => {
    window.open(shareLink.value, '_blank');
    emit('open');
  };

  const share = () => {
    resizePopup();

    // If a popup window already exist, we close it and trigger a change event.
    if (popupWindow.value) {
      clearInterval(popupInterval.value);

      // Force close (for Facebook)
      popupWindow.value.close();
      emit('change');
    }

    if ($window) {
      popupWindow.value = $window.open(
        shareLink.value,
        `sharer-${networkName.value}`,
        `,height=${
          props.popup.height
        },width=${
          props.popup.width
        },left=${
          popupLeft.value
        },top=${
          popupTop.value
        },screenX=${
          popupLeft.value
        },screenY=${
          popupTop.value}`,
      );
    }

    // If popup are prevented (AdBlocker, Mobile App context..), popup.window stays undefined and we can't display it
    if (!popupWindow.value) return;

    popupWindow.value.focus();

    // Create an interval to detect popup closing event
    popupInterval.value = setInterval(() => {
      if (!popupWindow.value || popupWindow.value.closed) {
        clearInterval(popupInterval.value);

        popupWindow.value = null;

        emit('close');
      }
    }, 500);

    emit('open');
  };

  const handleClick = () => {
    const action = rawLink.value.startsWith('http') ? 'share' : 'touch';
    return action === 'share' ? share() : touch();
  };

  return {
    networkName,
    handleClick,
    iconName,
  };
}
