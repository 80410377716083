import { AbstractErrorCode, AbstractError, normalizeError } from "@leon-hub/errors";
import { useI18n } from "@core/i18n";
class FileReaderErrorCode extends AbstractErrorCode {
  constructor(code = "FILE_READER_ERROR") {
    super(code);
  }
}
const fileReaderErrorCode = /* @__PURE__ */ new FileReaderErrorCode();
class FileReaderError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: fileReaderErrorCode,
      message: useI18n().$translate("WEB2_FILE_READER_ERROR").value
    });
  }
}
var DataType = /* @__PURE__ */ ((DataType2) => {
  DataType2["TEXT"] = "TEXT";
  DataType2["BASE64"] = "BASE64";
  return DataType2;
})(DataType || {});
function readFileContent(file, dataType) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const loadCallback = () => {
      if (file.name === "b5c0cf02-9d4c-4408-8b87-23378a834f7c.png") {
        reject(
          new FileReaderError({
            originalError: normalizeError(new Error("Test FileReader error"))
          })
        );
        return;
      }
      resolve(reader.result);
    };
    const abortCallback = () => reject(new FileReaderError());
    const errorCallback = () => reject(
      new FileReaderError({
        originalError: normalizeError(reader.error ?? new Error("Unknown FileReader error"))
      })
    );
    if (process.env.VUE_APP_PLATFORM_WEB) {
      reader.addEventListener("load", loadCallback);
      reader.addEventListener("abort", abortCallback);
      reader.addEventListener("error", errorCallback);
    }
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      reader.onload = loadCallback;
      reader.onabort = abortCallback;
      reader.onerror = errorCallback;
    }
    switch (dataType) {
      case "BASE64":
        reader.readAsDataURL(file);
        break;
      case "TEXT":
      default:
        reader.readAsText(file);
        break;
    }
  });
}
const fileReaderUtils = {
  DataType,
  readFileContent
};
export {
  fileReaderUtils as FileReaderUtils
};
