import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  inject,
  ref,
  watch,
} from 'vue';

import type { FadeCarouselState } from '../../types';
import type { VFadeCarouselSlideProps } from '../types';

interface UseVFadeCarouselSlideComposable {
  isSlideShown: Ref<boolean>;
  isSlideVisible: ComputedRef<boolean>;
}

export default function useVFadeCarouselSlide(props: VFadeCarouselSlideProps): UseVFadeCarouselSlideComposable {
  const isSlideShown = ref<boolean>(false);
  const carouselOptions = inject<FadeCarouselState>('carouselOptions');

  const isSlideVisible = computed(() => props.currentSlideIndex !== null && props.currentSlideIndex === carouselOptions?.activeSlideIndex);

  watch(isSlideVisible, () => {
    isSlideShown.value = true;
  }, { immediate: true });

  return {
    isSlideShown,
    isSlideVisible,
  };
}
