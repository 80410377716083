import type {
  ComputedRef,
  Ref,
} from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { Web2ImageFieldsFragment } from '@leon-hub/api-sdk';
import { IconSize, SystemIconName } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { VBadgeProps } from '@components/badge';
import type { VButtonProps } from '@components/buttons';
import type { VIconProps } from '@components/v-icon';
import { BadgeKind } from '@components/badge';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import type {
  LobbyEgsTopWinnerItemProps,
} from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/types';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';

export interface LobbyEgsTopWinnerItemComposable {
  imageProperties: ComputedRef<Web2ImageFieldsFragment | { src: string }>;
  iconProperties: VIconProps;
  playButtonProperties: ComputedRef<VButtonProps>;
  ntBadgeProperties: ComputedRef<VBadgeProps>;
  moneyAmount: ComputedRef<string>;
  href: Ref<string | undefined>;
}

export default function useLobbyEgsTopWinnerItem(
  props: LobbyEgsTopWinnerItemProps,
): LobbyEgsTopWinnerItemComposable {
  const { $translate } = useI18n();
  const formatMoney = useFormatMoney();
  const router = useRouter();
  const { getEgsGameHref } = useEgsNavigation(router);

  const iconProperties: VIconProps = {
    name: SystemIconName.PERSON,
    size: IconSize.SIZE_16,
  };

  const imageProperties = computed(() => props.item?.egsGame.images.preview ?? {

    src: require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/noimage-portrait.png`),
  });

  const playButtonProperties = computed<VButtonProps>(() => ({
    rounded: true,
    kind: ButtonKind.PRIMARY,
    label: $translate('BUTTON_PLAY').value,
    height: ButtonHeight.TINY,
  }));

  const ntBadgeProperties = computed<VBadgeProps>(() => ({
    kind: BadgeKind.SQUARE_ERROR,
    label: props.item?.egsGame.ntBadge || '',
  }));

  const moneyAmount = computed(() => (props.item
    ? formatMoney(props.item.winAmount.value, {
        currency: props.item.winAmount.currency,
        reduceAmount: 1_000_000,
      })
    : ''));

  const href = computed(() => (
    props.item ? getEgsGameHref(props.item.egsGame) : undefined
  ));

  return {
    imageProperties,
    iconProperties,
    playButtonProperties,
    ntBadgeProperties,
    moneyAmount,
    href,
  };
}
