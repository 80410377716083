<script lang="ts" setup>
import { computed } from 'vue';

import { IconName } from '@leon-hub/icons';

import { useCurrency, useFormatMoney } from '@core/money';

import { ButtonKind, VButton } from '@components/buttons';
import { VImage } from '@components/image';

import type {
  WelcomeBonusCardEmits,
  WelcomeBonusCardProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusCard/types';

const props = withDefaults(defineProps<WelcomeBonusCardProps>(), {
  bonusType: '',
  isSelected: false,
});

const emit = defineEmits<WelcomeBonusCardEmits>();

const formatMoney = useFormatMoney();
const { currency } = useCurrency();

const minDeposit = computed(() => formatMoney(props.minimumDepositValue?.amount ?? 0, {
  currency: props.minimumDepositValue?.currency || currency.value,
}));

function handleBonusClick() {
  emit('bonus-click', props.campaignId);
}

function handleDetailsClick() {
  emit('details-click', { categoryId: props.categoryId, actionUrl: props.actionUrl });
}
</script>

<template>
  <div v-auto-id="'WelcomeBonusCard'"
    :class="{
      [$style['welcome-bonus-card']]: true,
      [$style['welcome-bonus-card--selected']]: isSelected,
    }"
  >
    <div :class="$style['welcome-bonus-card__top']">
      <div :class="$style['welcome-bonus-card__image']">
        <VImage
          v-if="image"
          :src="image.src"
          :x1="image.x1"
          :x2="image.x2"
          :x3="image.x3"
          :x1webp="image.x1webp"
          :x2webp="image.x2webp"
          :x3webp="image.x3webp"
          height="100%"
        />
      </div>
      <div :class="$style['welcome-bonus-card__paragraph']">
        <p :class="$style['welcome-bonus-card__title']">
          {{ campaignName }}
        </p>
        <a
          v-if="campaignId && actionUrl"
          :class="$style['welcome-bonus-card__link']"
          @click="handleDetailsClick"
        >
          {{ $t('WELCOME_BONUS_CARD_MORE') }}
        </a>
      </div>
    </div>
    <div :class="$style['welcome-bonus-card__table']">
      <div :class="$style['welcome-bonus-card__table-row']">
        <span :class="$style['welcome-bonus-card__table-label']">
          {{ $t('WELCOME_BONUS_CARD_TYPE') }}
        </span>
        <span :class="$style['welcome-bonus-card__table-value']"> {{ bonusType }} </span>
      </div>
      <div :class="$style['welcome-bonus-card__table-row']">
        <span :class="$style['welcome-bonus-card__table-label']">{{ $t('WELCOME_BONUS_CARD_MINIMUM') }}</span>
        <span :class="$style['welcome-bonus-card__table-value']">{{ minDeposit }}</span>
      </div>
    </div>
    <VButton
      :class="$style['welcome-bonus-card__button']"
      :icon-name="isSelected ? IconName.CHECK_ROUNDED : undefined"
      :label="isSelected ? $t('WELCOME_BONUS_CARD_BUTTON_SELECTED') : $t('WELCOME_BONUS_CARD_BUTTON')"
      :kind="isSelected ? ButtonKind.PRIMARY : ButtonKind.PRIMARY_OPACITY"
      full-width
      @click="handleBonusClick"
    />
  </div>
</template>

<style lang="scss" module>
.welcome-bonus-card {
  $self: &;

  padding: 12px;
  background-color: var(--Layer1);
  border-radius: 5px;

  &__image {
    display: inline-flex;
    flex-shrink: 0;
    width: 129px;
    height: 76px;
    overflow: hidden;
    border-radius: 5px;
  }

  &__top {
    display: flex;
    gap: 12px;
  }

  &__paragraph {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  &__title {
    @include medium\16\24\025;

    margin: 0;
    color: var(--TextDefault);
    overflow-wrap: break-word;
  }

  &__link {
    @include font-underline-u1;

    color: var(--TextSecondary);
    text-decoration: underline;
    cursor: pointer;
  }

  &__button {
    width: 100%;
  }

  &__table-row {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 24px);
    padding: 8px 0;
    margin: 0 12px;
    border-bottom: 1px solid var(--Layer2);

    &:last-child {
      border-bottom: none;
    }
  }

  &__table-label {
    @include regular\14\16\025;

    color: var(--TextPrimary);
  }

  &__table-value {
    @include medium\14\16\025;

    color: var(--TextDefault);
  }

  &__table {
    margin-bottom: 8px;
  }

  &--selected {
    background: var(--OpacityBrandDefault);

    #{$self}__title {
      color: var(--BrandDefault);
    }

    #{$self}__table-row {
      border-bottom: 1px solid var(--OpacityBrandDefault);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
