<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import BalanceCard from 'web/src/modules/profile/components/BalanceCard/BalanceCard.vue';
import BalanceValue from 'web/src/modules/profile/components/BalanceValue/BalanceValue.vue';

import type { MainBalanceEmits, MainBalanceProps } from './types';
import { useMainBalance } from './composables';

const props = withDefaults(defineProps<MainBalanceProps>(), {
  mode: BalanceMode.Single,
});
const emit = defineEmits<MainBalanceEmits>();

const {
  buttonProperties,
  formattedBalance,
  balanceLabel,
  handleClick,
} = useMainBalance(props, emit);
</script>

<template>
  <BalanceCard v-auto-id="'MainBalance'"
    :mode="mode"
    :class="$style['main-balance']"
    @click="handleClick"
  >
    <template v-if="null">
      <VIcon
        :class="$style['main-balance__icon']"
        :size="IconSize.SIZE_36"
        :name="IconName.SLOTT_WALLET"
      />
      <BalanceValue
        :value="formattedBalance"
        :caption="$t('WEB2_MAIN_BALANCE')"
        :balance-label="balanceLabel"
      />
    </template>
    <template v-else>
      <VButton v-bind="buttonProperties" />
      <BalanceValue
        :value="formattedBalance"
        :caption="$t('WEB2_MAIN_BALANCE')"
        :balance-label="balanceLabel"
      />
    </template>
  </BalanceCard>
</template>

<style lang="scss" module>
.main-balance {
  flex-direction: row-reverse;
  cursor: pointer;
  background-color: $mainBalanceBackgroundColor;

  &__icon {
    color: var(--ColorBackground3);
  }
}
</style>
