import type { BetSlipSaveByBetRequest } from '@leon-hub/api-sdk';
import { doSaveBetSlipByBetId } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

import type { DataForSharing } from '../types';

export function requestSavePlacedBet(input: BetSlipSaveByBetRequest, apiClient: ApplicationGqlClient): Promise<DataForSharing> {
  return doSaveBetSlipByBetId(apiClient, (node) => node.mutations.betSlip.saveBetSlipByBet, {
    options: input,
  });
}
