import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { InputEventType } from '@leon-hub/input-types';

import type {
  PaymentsCreditCardSelectorEmits,
  PaymentsCreditCardSelectorProps,
} from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/types';
import type { PaymentsCreditCard } from 'web/src/modules/payments/types/documentTypes';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';

export interface PaymentsCreditCardSelectorComposable {
  setSelectedCreditCardToken(id: string): void;
  showCreditCardSelector: ComputedRef<boolean>;
  handleDeleteClick(token: string): void;
  onCreditCardSelectedMounted(): void;
}

export default function usePaymentsCreditCardSelector(
  props: PaymentsCreditCardSelectorProps,
  emit: PaymentsCreditCardSelectorEmits,
): PaymentsCreditCardSelectorComposable {
  const isVisible = ref(true);

  const { deleteCreditCard } = useDepositStore();

  function setSelectedCreditCardToken(id: string): void {
    emitInput(id);
  }

  const showCreditCardSelector = computed(() => isVisible.value && !!props.creditCards.length);

  function onCreditCardSelectedMounted(): void {
    setSelectedCreditCardToken(props.creditCards[0]?.token
      ? props.creditCards[0].token
      : PAYMENTS_UNDEFINED_CREDIT_CARD);
  }

  function getPaymentsCreditCardByToken(token: string): PaymentsCreditCard | undefined {
    return props.creditCards.find((item: PaymentsCreditCard) => item.token === token) ?? undefined;
  }

  function handleDeleteClick(token: string): void {
    const bankCard: PaymentsCreditCard | undefined = getPaymentsCreditCardByToken(token);
    if (!bankCard)
      return;

    void deleteCreditCard(bankCard);
  }

  function emitInput(id: string): void {
    emit(InputEventType.INPUT, id);
  }

  return {
    onCreditCardSelectedMounted,
    setSelectedCreditCardToken,
    handleDeleteClick,
    showCreditCardSelector,
  };
}
