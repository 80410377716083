import type { Ref } from 'vue';
import { computed } from 'vue';

import type { VListItemCountriesProps } from '../types';

export interface VListItemCountriesComposable {
  isSelected: Ref<boolean>;
}

export default function useVListItemCountries(props: VListItemCountriesProps): VListItemCountriesComposable {
  const isSelected = computed(() => props.code === props.selectedItemCode);
  return {
    isSelected,
  };
}
