import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import { useIsLoggedIn } from '@core/auth';
import { useCustomerDataStore } from '@core/customer';

import type { LeagueId } from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { getFavoritesLeaguesIdsRequest } from 'web/src/modules/sportline/utils/api';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';

interface UseFavoritesLeaguesEventsStoreComposable {
  customerLeaguesIds: Ref<Maybe<LeagueId[]>>;
  rawFavoriteLeaguesEventsResponse: Ref<Maybe<GetSportEventsResponse> | false>;
  setRawFavoriteEventsResponse(response: GetSportEventsResponse | GetSportEventsChangesResponse | null | false): void;
  fetchFavoriteLeaguesEvents(options: CoreSportlineFetchOptions): Promise<void>;
  fetchCustomerLeaguesIds(options: CoreSportlineFetchOptions): Promise<void>;
  clearCustomerLeaguesIds(): void;
}

export function useFavoritesLeaguesEventsStoreComposable(): UseFavoritesLeaguesEventsStoreComposable {
  const { isLoggedIn } = useIsLoggedIn();
  const customerDataStore = useCustomerDataStore();
  const customerLogin = toRef(customerDataStore, 'login');
  const broadcastSelected = useBroadcastSelected();
  const apiService = useSportlineApiService();

  const customerLeaguesIds = ref<Maybe<LeagueId[]>>(null);
  const rawFavoriteLeaguesEventsResponse = ref<Maybe<GetSportEventsResponse> | false>(null);

  function setRawFavoriteEventsResponse(
    response: GetSportEventsResponse | GetSportEventsChangesResponse | null | false,
  ): void {
    rawFavoriteLeaguesEventsResponse.value = response
      ? normalizeEventsChangesResponseToDefaultResponse(response)
      : response;
  }

  async function fetchFavoriteLeaguesEvents({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isLoggedIn.value) {
      rawFavoriteLeaguesEventsResponse.value = null;
      return;
    }

    // eslint-disable-next-line ts/prefer-nullish-coalescing
    const vTag = (rawFavoriteLeaguesEventsResponse.value || null)?.vtag;
    const isUpdateRequest = !!vTag;

    try {
      const response = await apiService.loadFavoriteLeaguesEvents({
        login: `${customerLogin.value}-${Date.now()}`,
        vTag: isUpdateRequest ? vTag : undefined,
        silent,
      });

      rawFavoriteLeaguesEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
      broadcastSelected.updateDataInStorageByResponse({ response });
    } catch (error: unknown) {
      rawFavoriteLeaguesEventsResponse.value = false;
      throw error;
    }
  }

  function clearCustomerLeaguesIds(): void {
    customerLeaguesIds.value = [];
  }

  async function fetchCustomerLeaguesIds({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isLoggedIn.value) {
      clearCustomerLeaguesIds();
      return;
    }

    const response = await getFavoritesLeaguesIdsRequest({ dateFrom: null, dateTo: null }, { silent });

    customerLeaguesIds.value = (response?.myLeagues?.leagues || [])
      .map((league) => String(league.leaguesId));
  }

  return {
    customerLeaguesIds,
    rawFavoriteLeaguesEventsResponse,
    setRawFavoriteEventsResponse,
    fetchFavoriteLeaguesEvents,
    fetchCustomerLeaguesIds,
    clearCustomerLeaguesIds,
  };
}
