import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { BetCashoutMode } from 'web/src/modules/cashout/enums';

import type { BetCashOutInlineEmits, BetCashOutProps } from '../../types';

interface UseBetCashOutInline {
  isError: ComputedRef<boolean>;
  emitCashOut(): void;
}

export default function useBetCashOutInline(
  props: BetCashOutProps,
  emit: BetCashOutInlineEmits,
): UseBetCashOutInline {
  const isError = computed<boolean>(() => props.mode === BetCashoutMode.ERROR);

  const emitCashOut = (): void => {
    emit('cash-out');
  };

  return {
    isError,
    emitCashOut,
  };
}
