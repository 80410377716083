import type { UseBiometricAuthSettings } from '../types';
import useCordovaBiometricAuthSettings from './useCordovaBiometricAuthSettings';
import useWebBiometricAuthSettings from './useWebBiometricAuthSettings';

export default function useBiometricAuthSettings(): UseBiometricAuthSettings {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return useCordovaBiometricAuthSettings();
  }

  return useWebBiometricAuthSettings();
}
