<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VCountryFlag, VFlagIcon } from '@components/country-flag';
import { HighlightedText } from '@components/highlighted-text';
import { SListItem, VListItem } from '@components/list';
import { VIcon } from '@components/v-icon';

import type {
  VListItemCountriesEmits,
  VListItemCountriesProps,
} from './types';
import { useVListItemCountries } from './composables';

const props = withDefaults(defineProps<VListItemCountriesProps>(), {
  code: 'RU',
  prefix: '7',
});
const emit = defineEmits<VListItemCountriesEmits>();

const { isSelected } = useVListItemCountries(props);

function emitClick(): void {
  emit('click', {
    prefix: props.prefix,
    code: props.code,
  });
}
</script>

<template>
  <SListItem
    v-if="null"
    :is-active="isSelected"
    type="primary"
    :class="$style['countries-selector-item']"
    is-word-wrap
    @click="emitClick"
  >
    <template #icon>
      <VFlagIcon
        v-if="null"
        :country-code="code"
        :size="IconSize.SIZE_24"
      />
      <VCountryFlag
        v-else
        :name="code"
      />
    </template>
    <template #content>
      <div>
        <HighlightedText
          :text="name"
          :query="searchText"
          :class="$style['countries-selector-item__name']"
        />
        <span>&nbsp;</span>
        <HighlightedText
          :text="prefix"
          :query="searchText"
          :class="$style['countries-selector-item__code']"
        />
      </div>
    </template>
    <template
      v-if="isSelected"
      #suffix
    >
      <VIcon :name="IconName.SLOTT_CHECK_CIRCLE" />
    </template>
  </SListItem>
  <VListItem
    v-else
    :title="name"
    :icon-suffix-name="isSelected ? IconName.CHECK_FILLED : undefined"
    :is-active="isSelected"
    have-hover
    @click="emitClick"
  >
    <template #prefix>
      <div :class="$style['list-item__prefix-flag']">
        <VFlagIcon
          v-if="null"
          :country-code="code"
          :class="$style['list-item__prefix-flag-img']"
        />
        <VCountryFlag
          :name="code"
          :class="$style['list-item__prefix-flag-img']"
        />
      </div>
    </template>
    <template #inner>
      <div>
        <HighlightedText
          :class="$style['countries-selector-item__name']"
          :text="name"
          :query="searchText"
        />
        <span>&nbsp;</span>
        <HighlightedText
          :class="$style['countries-selector-item__code']"
          :text="prefix"
          :query="searchText"
        />
      </div>
    </template>
  </VListItem>
</template>

<style lang="scss" module>
.countries-selector-item {
  @include countriesSelectorItem;

  &__code {
    @include countriesSelectorItemCode;

    color: $listItemCountriesColor;
  }

  &__name {
    @include countriesSelectorItemName;
  }
}

.list-item__prefix-flag {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;

  &-img {
    width: 100%;
    height: 16px;
  }
}
</style>
