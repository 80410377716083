import { FormControlType } from '@leon-hub/form-utils';

import type { FormWidgetList } from 'web/src/components/Form/utils/widgetList';
import FormFlexepinTermsCheckbox from 'web/src/components/TermsSpecialCheckboxes/FormFlexepinTermsCheckbox';
import CreditCardSelector from 'web/src/modules/payments/form-widgets/CreditCardSelector';
import FormDepositBonusSelector from 'web/src/modules/payments/form-widgets/DepositBonusSelector';
import FastSum from 'web/src/modules/payments/form-widgets/FastSum';
import FormCardCvv from 'web/src/modules/payments/form-widgets/FormCardCvv';
import FormCardExpirationDate from 'web/src/modules/payments/form-widgets/FormCardExpirationDate';
import FormCardNameInput from 'web/src/modules/payments/form-widgets/FormCardNameInput';
import FormCardNumber from 'web/src/modules/payments/form-widgets/FormCardNumber';
import PhoneDisabledInput from 'web/src/modules/payments/form-widgets/PhoneDisabledInput';
import WalletDisabledInput from 'web/src/modules/payments/form-widgets/WalletDisabledInput';
import WebmoneyDisabledInput from 'web/src/modules/payments/form-widgets/WebmoneyDisabledInput';

import FormCryptoPaymentData from './FormCryptoPaymentData';

const customPaymentsFormWidgets: FormWidgetList = {
  [FormControlType.FastSum]: FastSum,
  [FormControlType.CreditCardSelector]: CreditCardSelector,
  [FormControlType.PhoneDisabledInput]: PhoneDisabledInput,
  [FormControlType.WalletDisabledInput]: WalletDisabledInput,
  [FormControlType.WebmoneyDisabledInput]: WebmoneyDisabledInput,
  [FormControlType.CardCvv]: FormCardCvv,
  [FormControlType.CardExpirationDate]: FormCardExpirationDate,
  [FormControlType.CardNumber]: FormCardNumber,
  [FormControlType.DepositBonus]: FormDepositBonusSelector,
  [FormControlType.CardName]: FormCardNameInput,
  [FormControlType.FlexepinTermsCheckbox]: FormFlexepinTermsCheckbox,
};

if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED || process.env.VUE_APP_STORYBOOK) {
  // not for leonru
  customPaymentsFormWidgets[FormControlType.CryptoPaymentData] = FormCryptoPaymentData;
}

export default Object.freeze(customPaymentsFormWidgets);
