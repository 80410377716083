<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { Theme } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { VIcon } from '@components/v-icon';

import type { ThemeSwitcherButtonProps } from 'web/src/modules/theme/views/types';

const props = withDefaults(defineProps<ThemeSwitcherButtonProps>(), {});

const router = useRouter();

function openThemePage(): void {
  void router.push({
    name: RouteName.THEME_SELECT_MODAL,
  });
}

const iconName = computed(() => {
  if (props.theme === Theme.DARK) {
    return IconName.NIGHT;
  }
  if (props.theme === Theme.LIGHT) {
    return IconName.DAY;
  }
  return IconName.BRIGHTNESS_AUTO;
});
</script>

<template>
  <button v-auto-id="'ThemeSwitcherButton'"
    type="button"
    :class="$style['theme-switcher-button']"
    @click="openThemePage"
  >
    <span :class="$style['theme-switcher-button__visual']">
      <VIcon
        :name="iconName"
        :size="IconSize.SIZE_16"
        ::class="$style['theme-switcher-button__icon']"
      />
      <VIcon
        :name="IconName.TRIANGLE_DOWN"
        :size="IconSize.SIZE_16"
        :class="$style['theme-switcher-button__arrow']"
      />
    </span>
  </button>
</template>

<style lang="scss" module>
.theme-switcher-button {
  $self: &;

  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: inherit;
  color: var(--TextPrimary);
  background: none;
  border: none;

  @include for-hover {
    &:hover &__visual {
      color: var(--TextDefault);
      cursor: pointer;
      background-color: var(--Highlight);
    }

    &--is-header {
      &:hover .theme-switcher-button__visual {
        color: if($isEnvFeatureDarkHeader, var(--DTextDefault), var(--TextDefault));
        background-color: if($isEnvFeatureDarkHeader, var(--DHighlight), var(--Highlight));
      }
    }
  }

  &__visual {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    background-color: var(--Button);
    border-radius: 18px;
    transition: background-color 0.15s $animationEaseInOut, color 0.15s $animationEaseInOut;

    #{$self}--no-background & {
      background-color: transparent;
    }
  }

  &__title {
    @include medium\12\16;
  }
}
</style>
