import type { Ref } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import type { VLinearProgressProps } from 'web/src/components/LinearProgress/VLinearProgress/types';
import type { BonusLoyaltyProgramPanelProps } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramPanel/types';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';

interface BonusLoyaltyProgramPanelComposable {
  progressProperties: Ref<VLinearProgressProps>;
  buttonProperties: Ref<VButtonProps>;
  participateButtonProperties: Ref<VButtonProps>;
  pointsLabelProperties: Ref<string>;
  requiredAmount: Ref<string>;
}

export default function useBonusLoyaltyProgramPanel(
  props: BonusLoyaltyProgramPanelProps,
): BonusLoyaltyProgramPanelComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();

  const progressProperties = computed<VLinearProgressProps>(() => ({
    height: ProgressHeight.TINY,
    value: Number.parseInt(props.progressText || '', 10),
    of: 100,
  }));

  const buttonProperties = computed<VButtonProps>(() => ({
    label: $translate('WEB2_JSPACC_ACC_EXCHANGE').value,
    kind: ButtonKind.PRIMARY,
    height: ButtonHeight.MEDIUM,
    fullWidth: true,
  }));

  const participateButtonProperties = computed<VButtonProps>(() => ({
    label: $translate('WEB2_TO_PARTICIPATE').value,
    kind: ButtonKind.PRIMARY,
    height: ButtonHeight.MEDIUM,
    fullWidth: true,
  }));

  const pointsLabelProperties = computed<string>(() => $formatMoney(props.amountNumber || 0, { currency: 'L', hideCurrency: true }));

  const requiredAmount = computed<string>(() => $formatMoney(props.remainingAmountNumber || 0, { currency: 'L' }));

  return {
    progressProperties,
    buttonProperties,
    participateButtonProperties,
    pointsLabelProperties,
    requiredAmount,
  };
}
