import { getPromotionsByEgsGame } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { EgsGame } from 'web/src/modules/egs/types';

import getLatestCasinoPromotionUrl from './getLatestCasinoPromotionUrl';

export default async function getPromoDetailsUrlForGame(game: EgsGame): Promise<string | null> {
  const { id, providerId } = game;
  const result = await getPromotionsByEgsGame(
    useGraphqlClient(),
    (node) => node.queries.promotions.getPromotionsByEgsGame,
    {
      options: {
        gameId: id,
        providerId,
      },
    },
    { silent: true },
  );
  if (result?.promotions?.length) {
    return getLatestCasinoPromotionUrl([...result.promotions]);
  }

  return null;
}
