import {
  LobbyGridType,
  LobbyItemWidget,
} from '@leon-hub/api-sdk';

import type { LobbyGridBaseProps } from 'web/src/modules/lobby/types';
import { LobbyGridLayoutType } from 'web/src/modules/lobby/enums';

export default function getNoBackgroundGamesLobbyGridType(
  props: LobbyGridBaseProps,
): LobbyGridLayoutType | undefined {
  if (props.noBackground && (props.widgetId === LobbyItemWidget.SQUARE_SWIPE_LIST || props.widgetId === LobbyItemWidget.SWIPE_LIST)) {
    if (props.lobbyGridType === LobbyGridType.SMALL) {
      return props.widgetId === LobbyItemWidget.SQUARE_SWIPE_LIST
        ? LobbyGridLayoutType.COLUMN_NO_BG_GAME_SQUARE_SMALL
        : LobbyGridLayoutType.COLUMN_NO_BG_GAME_SMALL;
    }

    if (props.lobbyGridType === LobbyGridType.DEFAULT) {
      return LobbyGridLayoutType.COLUMN_NO_BG_GAME_DEFAULT;
    }

    if (props.lobbyGridType === LobbyGridType.LARGE) {
      return LobbyGridLayoutType.COLUMN_NO_BG_GAME_LARGE;
    }
  }

  return undefined;
}
