import type { Schema } from 'jsonschema';

import type { JSONSchemaProperties } from '@leon-hub/form-utils';
import { emailPattern } from '@leon-hub/form-utils';

import type { FormSchema } from 'web/src/components/Form/types';

export const defaultSchema: FormSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  title: 'Login',
  required: ['login', 'password', 'captchaAnswer'],
  properties: {
    login: {
      type: 'string',
      pattern: `${emailPattern}|^\\d{6,9}$|^\\+?\\d{11}$`,
    },
    password: {
      type: 'string',
      pattern: '^(?!.*(.)\\\\1{2,}).+$',
    },
    ...(process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      ? {}
      : {
          checkbox: {
            type: 'boolean',
          },
        }),
    captchaAnswer: {
      type: 'string',
    },
    captchaType: {
      type: 'string',
    },
  },
};

function phoneSchemaLoginProperties(): JSONSchemaProperties {
  if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
    return {
      suffix: {
        type: 'string',
      },
      prefix: {
        type: 'string',
      },
      countryCode: {
        type: 'string',
      },
    };
  }

  return {
    suffix: {
      type: 'string',
      pattern: '^9\\d{9}$',
    },
    prefix: {
      type: 'string',
      enum: [process.env.VUE_APP_DEFAULT_PHONE_PREFIX],
    },
  };
}

function phoneSchemaLoginConditions(): Schema {
  if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
    return {
      allOf: [
        {
          if: {
            properties: {
              prefix: {
                const: '66',
              },
            },
            required: ['prefix'],
          },
          then: {
            properties: {
              suffix: {
                type: 'string',
                pattern: '^[0-9]{5,15}$',
              },
            },
          },
        },
        {
          if: {
            properties: {
              prefix: {
                not: {
                  const: '66',
                },
              },
            },
          },
          then: {
            properties: {
              suffix: {
                type: 'string',
                pattern: '^[1-9][0-9]{4,14}$',
              },
            },
          },
        },
      ],
    };
  }

  return {};
}

export const phoneSchema: FormSchema = {
  ...defaultSchema,
  properties: {
    ...defaultSchema.properties,
    login: {
      required: ['suffix', 'prefix'],
      type: 'object',
      properties: phoneSchemaLoginProperties(),
      ...phoneSchemaLoginConditions(),
    },
    loginType: {
      type: 'string',
    },
  },
};

export const emailSchema: FormSchema = {
  ...defaultSchema,
  properties: {
    ...defaultSchema.properties,
    login: {
      type: 'string',
      pattern: emailPattern,
    },
    loginType: {
      type: 'string',
    },
  },
};

export const accountSchema: FormSchema = {
  ...defaultSchema,
  properties: {
    ...defaultSchema.properties,
    login: {
      type: 'string',
      pattern: '^[0-9]+$',
    },
    loginType: {
      type: 'string',
    },
  },
};
