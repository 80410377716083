<script lang="ts" setup>
import { normalizeClass } from 'vue';

import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { BadgeKind, VBadge } from '@components/badge';
import { VIcon } from '@components/v-icon';

import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';

import type {
  VTabsEmits,
  VTabsProps,
  VTabsRef,
  VTabsSlots,
} from './types';
import useSwiperToActiveScroll from '../../Swiper/VSwiper/composables/useSwiperToActiveScroll';
import { useVTabs } from './composables';
import { TabsType } from './enums';

const props = withDefaults(defineProps<VTabsProps>(), {
  type: TabsType.NORMAL,
  badgeNumber: 1,
});

const emit = defineEmits<VTabsEmits>();

defineSlots<VTabsSlots>();

const {
  activeTabIndex,
  isIconTabs,
  itemsList,
  containerRole,
} = useVTabs(props);

const { swiper, slideToActive, scrollToSlide } = useSwiperToActiveScroll(activeTabIndex);

defineExpose<VTabsRef>({
  slideToActive,
  scrollToSlide,
});
</script>

<template>
  <VSwiper v-auto-id="'VTabs'"
    ref="swiper"
    :class="{
      [$style['tabs--with-navigation']]: !!$slots.button,
    }"
    :wrapper-class="swiperWrapperClass ? normalizeClass(props.swiperWrapperClass) : {
      [$style.tabs__wrapper]: true,
      [$style['tabs__wrapper--with-gap']]: withGap,
      [$style['tabs__wrapper--for-rounded-frame-btn']]: isRoundedFrameButton,
    }"
    :role="containerRole"
  >
    <VSwiperSlide
      v-for="{ badge, icon, label, active, isPromotionsHighlighted, ntBadge, ...buttonProps } in itemsList"
      :key="buttonProps.id"
      :class="{
        [$style['tabs__slide--full-width']]: fullWidth,
      }"
    >
      <VTabsButton
        v-data-test="{ el: 'tab', id: buttonProps.id, active: activeId === buttonProps.id }"
        v-bind="buttonProps"
        :class="{
          [$style['tabs__slide--highlighted']]: isPromotionsHighlighted,
        }"
        :type="type"
        :active="active"
        :flexible="fullWidth"
        :s-tab-button-kind="sTabButtonKind"
        :single="single"
        :is-rounded-frame="isRoundedFrameButton"
        @click="emit('tab-click', $event)"
      >
        <template v-if="isPromotionsHighlighted">
          <VIcon
            :name="IconName.PROMOS"
            :size="IconSize.SIZE_16"
            :class="[
              $style.tabs__icon,
              [$style['tabs__icon--highlighted']],
            ]"
          />
          <span>
            {{ label }}
          </span>
        </template>
        <template v-else>
          <VIcon
            v-if="isIconTabs"
            v-bind="icon"
            :class="{
              [$style.tabs__icon]: true,
              [$style['tabs__icon--active']]: active,
            }"
          />
          <template v-else>
            <span :class="$style.tabs__label">
              {{ label }}
            </span>
            <VBadge
              v-if="badge && type === 'normal'"
              v-bind="badge"
              :class="$style.tabs__badge"
            />
            <VBadge
              v-if="ntBadge"
              :label="ntBadge"
              :kind="BadgeKind.SQUARE_ERROR"
              :class="$style['tabs__nt-badge']"
            />
          </template>
        </template>
      </VTabsButton>
    </VSwiperSlide>
    <template
      v-if="$slots.button"
      #pagination-footer
    >
      <div
        :class="$style.tabs__menu"
      >
        <slot name="button" />
      </div>
    </template>
  </VSwiper>
</template>

<style lang="scss" module>
.tabs {
  &--with-navigation {
    display: flex;
  }

  &__slide {
    &--full-width {
      flex: 1;
    }

    &--highlighted {
      gap: 4px;
    }
  }

  &__wrapper {
    padding: $tabsDefaultPadding;

    &--with-gap {
      gap: $tabsGap;
    }

    &--for-rounded-frame-btn {
      gap: $tabsGapRoundedFrameBtn;
    }
  }

  &__badge {
    margin-left: 4px;
  }

  &__nt-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    max-width: calc(100% - 4px);
  }

  &__menu {
    display: flex;
    flex: none;
    align-items: center;
  }

  &__icon {
    color: var(--TextSecondary);

    &--active {
      color: var(--TextDefault);
    }

    &--highlighted {
      color: var(--PinkHighLight);
    }
  }
}
</style>
