import type { RouteLocationNormalized, Router } from 'vue-router';

import type { EgsCategory } from 'web/src/modules/egs/types';
import { AllGamesGroupUrl, FavoriteGamesCategoryUrl } from 'web/src/modules/egs/constants';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';

import navigateToLobbyPage from './navigateToLobbyPage';

export default function navigateToCategoryPage(
  router: Router,
  route: RouteLocationNormalized,
  category: EgsCategory,
  groupUrl?: string,
): void {
  navigateToLobbyPage(
    router,
    route,
    groupUrl || AllGamesGroupUrl,
    category.id === EgsGamesCategoryId.FAVOURITE_GAMES
    || category.id === EgsGamesCategoryId.HISTORY_GAMES
    || category.id === EgsGamesCategoryId.HISTORY_LIVE_GAMES
      ? FavoriteGamesCategoryUrl
      : category.url,
    category.type,
  );
}
