/* eslint-disable ts/ban-ts-comment */
import type { SubmitPaymentRequest, UIFormSchema } from '@leon-hub/api-sdk';
import type { BaseError } from '@leon-hub/errors';

import type { FormExternalErrors } from 'web/src/components/Form/types';
import {
  InvalidCreditCardTokenizeCode,
  InvalidPaymentsExceptionCode,
  PaymentsCardField,
} from 'web/src/modules/payments/enums';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';

export default function addCustomerFormPaymentsErrors(
  error: BaseError,
  uiFormSchema: UIFormSchema,
  payload: SubmitPaymentRequest,
): Maybe<FormExternalErrors> {
  const code = error.code.toString();
  const { message } = error;

  // @ts-ignore
  const formData: Record<string, string> = payload.formParams.reduce((accumulator, field) => {
    accumulator[field.key] = field.value;
    return accumulator;
  }, {} as Record<string, unknown>);

  const customerFormErrors = extractCustomerFormErrors(uiFormSchema, formData);
  let currentError: Maybe<FormExternalErrors> = null;

  const cardNumberValue = formData[PaymentsCardField.CARD_NUMBER];
  const cardExpirationValue = formData[PaymentsCardField.CARD_EXPIRATION];
  const cardHolderValue = formData[PaymentsCardField.CARD_HOLDER];

  switch (code) {
    case InvalidCreditCardTokenizeCode.TOKENIZE_ERROR_INVALID_PAN:
      if (formData[PaymentsCardField.CARD]) {
        currentError = {
          [PaymentsCardField.CARD]: [{
            message,
            value: formData[PaymentsCardField.CARD].toString(),
          }],
        };
      }
      if (formData[PaymentsCardField.CARD_NUMBER]) {
        currentError = {
          [PaymentsCardField.CARD_NUMBER]: [{
            message,
            value: formData[PaymentsCardField.CARD_NUMBER].toString(),
          }],
        };
      }
      break;
    case InvalidPaymentsExceptionCode.PAYMENT_ERROR_WRONG_PAN:
      if (cardNumberValue) {
        currentError = {
          [PaymentsCardField.CARD_NUMBER]: [{
            message,
            value: cardNumberValue.toString(),
          }],
        };
      }
      break;
    case InvalidPaymentsExceptionCode.PAYMENT_ERROR_DATE_EXPIRED:
    case InvalidPaymentsExceptionCode.PAYMENT_ERROR_WRONG_EXP_DATE:
      if (cardExpirationValue) {
        currentError = {
          [PaymentsCardField.CARD_EXPIRATION]: [{
            message: ' ',
            value: cardExpirationValue.toString(),
          }],
        };
      }
      break;
    case InvalidPaymentsExceptionCode.PAYMENT_ERROR_WRONG_NAME:
      if (cardHolderValue) {
        currentError = {
          [PaymentsCardField.CARD_HOLDER]: [{
            message: ' ',
            value: cardHolderValue.toString(),
          }],
        };
      }
      break;
  }

  if (currentError) {
    return {
      ...customerFormErrors,
      ...currentError,
    };
  }

  return null;
}
