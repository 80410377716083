import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { LanguageMicroSettings } from '@leon-hub/locale';

import { useI18n, useI18nStore } from '@core/i18n';

import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
import { CountdownLabelType } from 'web/src/modules/sportline/composables/countdown/enums';

interface CountdownDateTimeLabelsProps {
  toTimestamp: Ref<number>;
  fromTimestamp: Ref<number>;
  displaySecondsOnMaxMinutes?: Ref<number>;
  daysLabelType?: Ref<CountdownLabelType>;
  hoursLabelType?: Ref<CountdownLabelType>;
  minutesLabelType?: Ref<CountdownLabelType>;
  secondsLabelType?: Ref<CountdownLabelType>;
}

interface CountdownDateTimeLabelsComposable {
  displayedTimeLeft: Ref<DisplayedTimeLeft>;
  timeLeft: Ref<ValueOfTimeLeft>;
  daysLabel: Ref<Optional<string>>;
  hoursLabel: Ref<Optional<string>>;
  minutesLabel: Ref<Optional<string>>;
  secondsLabel: Ref<Optional<string>>;
  startsInLabel: Ref<string>;
}

const defaultLabelType = {
  days: CountdownLabelType.Full,
  hours: CountdownLabelType.Full,
  minutes: CountdownLabelType.Full,
  seconds: CountdownLabelType.Short,
};

export function useCountdownDateTimeLabels(
  props: CountdownDateTimeLabelsProps,
): CountdownDateTimeLabelsComposable {
  const {
    toTimestamp,
    fromTimestamp,
    displaySecondsOnMaxMinutes,
    daysLabelType,
    hoursLabelType,
    minutesLabelType,
    secondsLabelType,
  } = props;

  const { $translate } = useI18n();
  const actualLocale = toRef(useI18nStore(), 'locale');
  const {
    timeLeftValue: timeLeft,
    displayedTimeLeft,
  } = useCountdown(toTimestamp, fromTimestamp, {
    displaySecondsOnMaxMinutes: (displaySecondsOnMaxMinutes ?? ref(1)),
    useLeadingZero: ref(false),
  });

  const daysCount = computed(() => ({ count: displayedTimeLeft.value.days }));
  const hoursCount = computed(() => ({ count: displayedTimeLeft.value.hours }));
  const minutesCount = computed(() => ({ count: displayedTimeLeft.value.minutes }));
  const secondsCount = computed(() => ({ count: displayedTimeLeft.value.seconds }));

  const daysLabelOne = $translate('WEB2_PLURAL_DAYS_ONE', daysCount);
  const daysLabelTwo = $translate('WEB2_PLURAL_DAYS_TWO', daysCount);
  const daysLabelMany = $translate('WEB2_PLURAL_DAYS_MANY', daysCount);
  const daysLabelExtraShort = $translate('WEB2_DAYS_EXTRA_SHORT');

  const hoursLabelOne = $translate('WEB2_PLURAL_HOURS_ONE', hoursCount);
  const hoursLabelTwo = $translate('WEB2_PLURAL_HOURS_TWO', hoursCount);
  const hoursLabelMany = $translate('WEB2_PLURAL_HOURS_MANY', hoursCount);
  const hoursLabelExtraShort = $translate('WEB2_HOURS_EXTRA_SHORT');

  const minutesLabelOne = $translate('WEB2_PLURAL_MINUTES_ONE', minutesCount);
  const minutesLabelTwo = $translate('WEB2_PLURAL_MINUTES_TWO', minutesCount);
  const minutesLabelMany = $translate('WEB2_PLURAL_MINUTES_MANY', minutesCount);
  const minutesLabelShort = $translate('WEB2_MINUTES_SHORT');
  const minutesLabelExtraShort = $translate('WEB2_MINUTES_EXTRA_SHORT');

  const secondsLabelShort = $translate('WEB2_SECONDS_SHORT');

  const daysLabel = computed<Optional<string>>(() => {
    const daysLeft = timeLeft.value.days;
    if (daysLeft <= 0) {
      return undefined;
    }

    const daysCountValue = daysCount.value.count;
    if (!daysCountValue) {
      return undefined;
    }

    const displayType = daysLabelType?.value ?? defaultLabelType.days;

    if (displayType === CountdownLabelType.Full) {
      return LanguageMicroSettings.plural([
        daysLabelOne.value,
        daysLabelTwo.value,
        daysLabelMany.value,
      ], daysLeft, actualLocale.value);
    }

    if (displayType === CountdownLabelType.ExtraShort) {
      return `${daysCountValue} ${daysLabelExtraShort.value}.`;
    }

    return undefined;
  });
  const hoursLabel = computed<string | undefined>(() => {
    if (!timeLeft.value || !hoursCount.value.count) {
      return undefined;
    }
    if (timeLeft.value.hours <= 0) {
      return undefined;
    }

    const displayType = hoursLabelType?.value ?? defaultLabelType.hours;

    if (displayType === CountdownLabelType.Full) {
      return LanguageMicroSettings.plural([
        hoursLabelOne.value,
        hoursLabelTwo.value,
        hoursLabelMany.value,
      ], timeLeft.value.hours, actualLocale.value);
    }

    if (displayType === CountdownLabelType.ExtraShort) {
      return `${hoursCount.value.count} ${hoursLabelExtraShort.value}.`;
    }

    return undefined;
  });
  const minutesLabel = computed<Optional<string>>(() => {
    const minutesLeft = timeLeft.value.minutes;
    if (minutesLeft <= 0) {
      return undefined;
    }

    const minutesCountValue = minutesCount.value.count;
    if (!minutesCountValue) {
      return undefined;
    }

    const displayType = minutesLabelType?.value ?? defaultLabelType.minutes;

    if (displayType === CountdownLabelType.Full) {
      return LanguageMicroSettings.plural([
        minutesLabelOne.value,
        minutesLabelTwo.value,
        minutesLabelMany.value,
      ], timeLeft.value.minutes, actualLocale.value);
    }

    if (displayType === CountdownLabelType.Short) {
      return `${minutesCount.value.count} ${minutesLabelShort.value}.`;
    }

    if (displayType === CountdownLabelType.ExtraShort) {
      return `${minutesCount.value.count} ${minutesLabelExtraShort.value}.`;
    }

    return undefined;
  });
  const secondsLabel = computed<Optional<string>>(() => {
    if (!timeLeft.value) {
      return undefined;
    }

    const secondsCountValue = secondsCount.value.count;
    if (!secondsCountValue) {
      return undefined;
    }

    const displayType = secondsLabelType?.value ?? defaultLabelType.seconds;

    if (displayType === CountdownLabelType.Short) {
      return `${secondsCountValue} ${secondsLabelShort.value}.`;
    }

    return undefined;
  });

  const startsInLabel = computed(() => [daysLabel.value, hoursLabel.value, minutesLabel.value, secondsLabel.value]
    .filter((label) => !!label)
    .join(' '));

  return {
    displayedTimeLeft,
    timeLeft,
    daysLabel,
    hoursLabel,
    minutesLabel,
    secondsLabel,
    startsInLabel,
  };
}
