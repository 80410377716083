import type { Ref } from 'vue';
import Scrollbar from 'smooth-scrollbar';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  ref,
} from 'vue';

import type { ScrollToElementOptions } from '@leon-hub/scrollbar-types';
import { useDebounce } from '@leon-hub/debounce';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useIntersectionObserver } from '@leon-hub/vue-utils';

import type { VScrollbarDesktopEmits, VScrollbarDesktopProps, VScrollbarDesktopProvidableContext } from '../../types';
import getTestDataAttrs from '../../utils/getTestDataAttrs';

export interface VScrollbarDesktopComposable {
  testAttrs: Ref<Record<string, string>>;
  element: Ref<HTMLElement | undefined>;
  onScroll(event: Event): void;
  scrollToElement(el: HTMLElement, options?: ScrollToElementOptions): void;
  scrollUpdate(): void;
  scrollRight(): void;
  scrollDown(): void;
  scrollTop(): void;
  recompose(): Promise<void>;
  scrollTo(top: number): void;
  providableContext: Ref<VScrollbarDesktopProvidableContext>;
}

export default function useVScrollbarDesktop(
  props: VScrollbarDesktopProps,
  emit: VScrollbarDesktopEmits,
): VScrollbarDesktopComposable {
  const element = ref<HTMLElement>();
  let $scrollbar: Scrollbar | null = null;
  let postScrollElement: HTMLElement | undefined;
  let postScrollOptions: ScrollToElementOptions | undefined;

  const isReady = ref<boolean>(false);
  const topOffset = ref<number>(0);

  useBusSafeSubscribe(BusEvent.QA_SCROLLBAR_SCROLL_TO, onScrollTo);
  useBusSafeSubscribe(BusEvent.MODAL_CONTENT_SCROLL_TOP, scrollTop);

  function destroyScrollbar(): void {
    if ($scrollbar) {
      $scrollbar.destroy();
      $scrollbar = null;
    }
  }

  function clearPostScrollElement(): void {
    postScrollElement = undefined;
    postScrollOptions = undefined;
  }

  async function initScrollBar(): Promise<void> {
    destroyScrollbar();

    await nextTick();
    const el = element.value;

    if (el) {
      $scrollbar = Scrollbar.init(el, {
        damping: 1,
        renderByPixels: true,
      });

      $scrollbar.addListener((status) => {
        const limit = status.limit.y;
        const offset = status.offset.y;
        topOffset.value = offset;
        if (props.useScrollListener) {
          emit('scroll', {
            limit,
            offset,
          });
          if (limit <= offset) {
            emit('reached-bottom');
          }
        }
      });

      if (postScrollElement) {
        await nextAnimationFrame();
        scrollToElement(postScrollElement, postScrollOptions);
        clearPostScrollElement();
      }

      await nextTick();
      isReady.value = true;
    }
  }

  function updateTracks() {
    $scrollbar?.update();
    $scrollbar?.track.update();
  }

  const debouncedUpdateTracks = useDebounce(updateTracks, 100);

  useIntersectionObserver(element, (isIntersecting) => {
    if (isIntersecting) {
      void initScrollBar();
    }
  }, undefined, true);

  onBeforeUnmount(() => {
    destroyScrollbar();
    clearPostScrollElement();
  });

  const testAttrs = computed(
    () => getTestDataAttrs('scrollbar', props),
  );
  function isElementFullyVisible(el: HTMLElement): boolean {
    if (!$scrollbar) {
      return false;
    }

    const { bounding } = $scrollbar;
    const targetBounding = el.getBoundingClientRect();

    return targetBounding.bottom <= bounding.bottom
      && targetBounding.top >= bounding.top;
  }

  function onScrollTo({
    scrollbar,
    el,
    options,
  }: {
    scrollbar: string;
    el: HTMLElement | null;
    options?: ScrollToElementOptions;
  }): void {
    if (scrollbar === props.testEl) {
      scrollToElement(el, options);
    }
  }

  function onScroll(event: Event): void {
    if (props.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // exposed function
  function scrollTo(top: number): void {
    if ($scrollbar) {
      $scrollbar.scrollTo(0, top, 0);
    }
  }

  function scrollTop(): void {
    scrollTo(0);
  }

  function scrollDown(): void {
    scrollTo($scrollbar?.contentEl.scrollHeight || 0);
  }

  function scrollRight(): void {
    if ($scrollbar) {
      $scrollbar.scrollTo($scrollbar.contentEl.scrollWidth, 0, 0);
    }
  }

  function scrollUpdate(): void {
    $scrollbar?.update();
  }

  function scrollToElement(el: HTMLElement | null, options?: ScrollToElementOptions): void {
    if (el) {
      if ($scrollbar) {
        let onlyScrollIfNeeded = !!options?.onlyIfNeeded;

        if (!isElementFullyVisible(el)) {
          onlyScrollIfNeeded = false;
        }

        $scrollbar.scrollIntoView(el, {
          onlyScrollIfNeeded,
          alignToTop: options?.alignToTop,
          offsetTop: options?.offsetTop,
        });
      } else {
        postScrollElement = el;
        postScrollOptions = options;
      }
    }
  }

  const providableContext = computed<VScrollbarDesktopProvidableContext>(() => ({
    isReady: isReady.value,
    scrollTop: topOffset.value,
  }));

  async function recompose(): Promise<void> {
    await nextTick();
    await nextAnimationFrame();
    debouncedUpdateTracks();
  }

  return {
    testAttrs,
    element,
    providableContext,
    onScroll,
    scrollTo,
    scrollTop,
    scrollDown,
    scrollRight,
    scrollUpdate,
    scrollToElement,
    recompose,
  };
}
