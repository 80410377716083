const chunkSize = 5;

export default async function promisesInBatch<
  T,
  K extends (argument: T) => Promise<unknown>,
>(messages: T[], callback: K): Promise<void> {
  const messagesCopy = [...messages];

  while (messagesCopy.length > 0) {
    const batch = messagesCopy.splice(0, chunkSize);

    await Promise.allSettled(batch.map((message) => callback(message)));
  }
}
