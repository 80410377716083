import type { RouteRecordName } from 'vue-router';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

interface GoToRouteNameComposable {
  goToRouteName(routeName: RouteRecordName): void;

}
// hack to avoid navigation issues
export function useGoToRouteName(): GoToRouteNameComposable {
  const router = useRouter();
  const goToRouteName = (routeName: RouteRecordName): void => {
    void router.push({ name: RouteName.HOME }).then(() => {
      void router.push({ name: routeName });
    });
  };

  return {
    goToRouteName,
  };
}
