import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';
import { InputEventType } from '@leon-hub/input-types';

import type { DropListSelectOption, DropListSelectProps } from 'web/src/components/DropListSelect/types';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';

interface UseFormDropListSelect {
  emitChange(value: string): void;
  emitFocus(): void;
  emitBlur(): void;
  componentProps: ComputedRef<DropListSelectProps>;
}

export function useFormDropListSelect(props: FormWrapperWidgetProperties<DropListSelectProps>, emit: BaseFormWidgetEmits): UseFormDropListSelect {
  const isMultiple = computed<boolean>(() => !!props.options?.isMultiselectMode);

  const selectedAsArray = computed<string[]>(() => {
    assert(isOptionalString(props.value), `Unexpected DropListSelect value type: ${typeof props.value}`);
    if (!isMultiple.value) {
      return [];
    }
    return (props.value ?? '').split(',').map((value) => value.trim());
  });

  /**
   * TODO: to real usage of multiple selection in form, need to add real string[] as form data
   * or not to relay on schema enum
   */
  const selected = computed(() => {
    assert(isOptionalString(props.value), `Unexpected DropListSelect value type: ${typeof props.value}`);
    if (!isMultiple.value) {
      return props.value ?? '';
    }
    return selectedAsArray.value;
  });

  const getValue = (eventValue: string): string => {
    if (!isMultiple.value) {
      return eventValue;
    }
    if (selectedAsArray.value.includes(eventValue)) {
      return selectedAsArray.value
        .filter((value) => value !== eventValue)
        .join(', ');
    }
    selectedAsArray.value.push(eventValue);
    return selectedAsArray.value.join(', ');
  };

  const componentProps = computed<DropListSelectProps>(() => {
    const {
      name,
      title,
      extraProperties,
      value,
      error,
      hintMessage,
      disabled,
      options,
      autofocus,
    } = props;

    const values = extraProperties?.enum ?? [];
    assert(isOptionalString(value), `Unexpected DropListSelect value type: ${typeof value}`);
    const uiLabels = extraProperties?.labels;
    const selectOptions: DropListSelectOption[] = values.map((currentValue) => ({
      label: uiLabels ? uiLabels[currentValue] : currentValue,
      value: currentValue,
    }));

    return {
      options: selectOptions,
      selected: selected.value,
      placeholder: title,
      disabled,
      ...(options ?? {}),
      ...getWrapperHintProperties({ options, error, hintMessage }),
      name,
      autofocus,
    };
  });

  const emitChange = (value: string): void => {
    emit(InputEventType.CHANGE, {
      name: props.name,
      value: getValue(value),
    });
  };

  const emitFocus = (): void => {
    emit(InputEventType.FOCUS, {
      name: props.name,
      value: props.value,
    });
  };

  const emitBlur = (): void => {
    emit(InputEventType.BLUR, {
      name: props.name,
      value: props.value,
    });
  };

  return {
    componentProps,
    emitChange,
    emitFocus,
    emitBlur,
  };
}
