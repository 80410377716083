import type { MaybeRef } from 'vue';
import { toValue } from 'vue';

import type { SportlinePersistentLocationKey } from '../types';
import { useSportlinePersistFilterStore } from '../store';
import { useResetPersistLocationSettings } from '../store/composables';

interface UseResetPersistLocationComposable {
  /** Force reset saved location */
  forcePersistLocationReset(key?: MaybeRef<SportlinePersistentLocationKey>): void;

  /** Do reset location only for web2.sportline.family.filter.reset.status */
  handlePersistLocationReset(key?: MaybeRef<SportlinePersistentLocationKey>): void;
}

export function useResetPersistLocation(): UseResetPersistLocationComposable {
  const { isResetLocationEnabled } = useResetPersistLocationSettings();

  const sportlinePersistFilterStore = useSportlinePersistFilterStore();

  const { resetPersistLocation } = sportlinePersistFilterStore;

  function forcePersistLocationReset(key?: MaybeRef<SportlinePersistentLocationKey>): void {
    resetPersistLocation(toValue(key));
  }

  function handlePersistLocationReset(key?: MaybeRef<SportlinePersistentLocationKey>): void {
    if (!isResetLocationEnabled.value) { return; }
    resetPersistLocation(toValue(key));
  }

  return {
    forcePersistLocationReset,
    handlePersistLocationReset,
  };
}
