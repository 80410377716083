import type { RawEntryInput } from '@leon-hub/api-sdk';
import { isBoolean, isObject } from '@leon-hub/guards';

import type { FormData } from 'web/src/components/Form/types';

export function mapFormData(formData: FormData): RawEntryInput[] {
  return Object.keys(formData)
    .map<RawEntryInput>((key) => ({
      key,

      value: isObject(formData[key])
        ? formData[key]
        : (isBoolean(formData[key]) ? formData[key] : String(formData[key])),
    }));
}
