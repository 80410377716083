import { defineStore } from 'pinia';
import { computed, ref, toRef, watch, watchEffect } from 'vue';

import type { CancelWithdrawalRouteInfo } from '@leon-hub/api-sdk';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { delay, Timer } from '@leon-hub/utils';

import { useIsLoggedIn } from '@core/auth';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';
import { useRouterStore } from '@core/router';
import { usePaymentsConfig } from '@core/site-config';

import { ModalWidth } from '@components/dialogs';
import { JumbotronIconKind } from '@components/jumbotron';

import { useAppFullscreenStore } from 'web/src/modules/core/store';
import { useCustomerFinalBalance } from 'web/src/modules/customer/composables';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useWithdrawalCancel } from 'web/src/modules/payments/composables/useWithdrawalCancel';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';

type WithdrawalCancelPopup = Pick<CancelWithdrawalRouteInfo, 'routeName' | 'btrSubject' | 'btrMessage' | 'btrButton' | 'onlyZeroBalanceShow'>;

export const useWithdrawalCancelPopupsStore = defineStore('withdrawal-cancel-popups', () => {
  const { $translate } = useI18n();
  const paymentsStore = usePaymentsStore();
  const routerStore = useRouterStore();
  const appFullScreenStore = useAppFullscreenStore();
  const { showDialog, closeDialog } = useDialogs();
  const { isLoggedIn } = useIsLoggedIn();
  const {
    paymentsWithdrawalCancelPopupRoutes,
    paymentsWithdrawalCancelPopupDelay,
    paymentsWithdrawalCancelPopupInterval,
    isPaymentsWithdrawalCancelButtonInSuccessModalEnabled: isWithdrawalCancelEnabled,
    zeroBalanceLimit,
  } = usePaymentsConfig();
  const {
    totalBalance,
  } = useCustomerFinalBalance();
  const { handleCancelWithdrawal, sendAnalyticsWhenPopupSetVisible } = useWithdrawalCancel();

  const currentRouteName = toRef(() => routerStore.currentRouteName);
  const withdrawalStatsData = toRef(() => paymentsStore.withdrawalStatsData);
  const isGameInFullscreenMode = toRef(() => appFullScreenStore.isAppInFullscreenMode);

  let timeout = 0;
  const lastPopupTimestamp = ref<number | null>(null);
  const isModalVisible = ref(false);
  const isPopupConfirmedClicked = ref(false);
  const withdrawalCancelPopupTitle = ref('');
  const withdrawalCancelPopupMessage = ref('');
  const withdrawalCancelPopupButton = ref('');
  const isModalVisibleFirstTimeWhenBalanceChanged = ref(false);
  const isInitialLoad = ref(true);

  const withdrawalCancelPopup = computed<WithdrawalCancelPopup | undefined>(() => {
    return paymentsWithdrawalCancelPopupRoutes.value.find(
      (route) => currentRouteName.value === route.routeName,
    );
  });

  const isTimeToShowPopup = computed(() => {
    if (!withdrawalStatsData.value?.minPaymentCreatedAt || withdrawalStatsData.value?.requestedCount <= 0) {
      return false;
    }

    const timeSincePayment = Date.now() - withdrawalStatsData.value?.minPaymentCreatedAt;

    if (!lastPopupTimestamp.value) {
      return timeSincePayment >= paymentsWithdrawalCancelPopupDelay.value * 1000;
    }

    const timeSinceLastPopup = Date.now() - lastPopupTimestamp.value;
    return timeSinceLastPopup >= paymentsWithdrawalCancelPopupInterval.value * 1000;
  });

  function setPopupConfirmed(value: boolean): void {
    isPopupConfirmedClicked.value = value;
  }

  async function showWithdrawalDefaultPopup(): Promise<void> {
    if (!isLoggedIn.value && (withdrawalStatsData.value && withdrawalStatsData.value?.requestedCount > 0)) {
      return;
    }

    if (isModalVisible.value) {
      return;
    }

    if (isGameInFullscreenMode.value) {
      appFullScreenStore.setFullscreenMode(false);
    }

    isModalVisible.value = true;
    await delay(500);
    sendAnalyticsWhenPopupSetVisible();
    lastPopupTimestamp.value = Date.now();

    const { subscribe, id } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: withdrawalCancelPopupTitle.value,
        confirmMessage: withdrawalCancelPopupMessage.value,
        width: ModalWidth.SMALL,
        iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? undefined : IconName.CLOCK,
        iconKind: JumbotronIconKind.SUCCESS,
        image: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? getImageOrIcon({ alertIcon: AlertIconName.Sandglass }).image : undefined,
        isCentered: false,
        buttons: [
          {
            label: withdrawalCancelPopupButton.value,
            action: DialogAction.CONFIRM,
          },
          {
            label: $translate('WEB2_BACK_LABEL').value,
          },
        ],
      },
    });

    subscribe({
      [DialogAction.CONFIRM]: async () => {
        setPopupConfirmed(true);
        handleCancelWithdrawal(true);
        resetFlow(true);
        clearTimer();
        await paymentsStore.loadWithdrawalStats();
      },
      [DialogAction.MODAL_CLOSE]: () => {
        closeDialog(id);
        isModalVisible.value = false;
      },
    });
  }

  async function handlePopupDisplay(): Promise<void> {
    if (!withdrawalCancelPopup.value || isModalVisible.value) {
      return;
    }

    await paymentsStore.loadWithdrawalStats();

    if (withdrawalStatsData.value && withdrawalStatsData.value?.requestedCount <= 0) {
      clearTimer();
      resetFlow(true);
    }

    // First time show popup on initial page if balance <= 0
    if (withdrawalCancelPopup.value?.onlyZeroBalanceShow && !totalBalance.value && isTimeToShowPopup.value) {
      await showDefaultPopupManually();
      return;
    }

    if (!isTimeToShowPopup.value || !shouldShowPopup()) {
      return;
    }

    await displayPopup();
  }

  function shouldShowPopup(): boolean {
    if (withdrawalCancelPopup.value?.onlyZeroBalanceShow) {
      return isModalVisibleFirstTimeWhenBalanceChanged.value;
    }
    return !!withdrawalStatsData.value?.requestedCount && withdrawalStatsData.value.requestedCount > 0;
  }

  async function displayPopup(): Promise<void> {
    await showWithdrawalDefaultPopup();
    startTimer();
  }

  function startTimer(): void {
    clearTimer();
    timeout = Timer.setInterval(() => {
      if (!isModalVisible.value && withdrawalCancelPopup.value && withdrawalStatsData.value && withdrawalStatsData.value?.requestedCount > 0) {
        void handlePopupDisplay();
      }
    }, paymentsWithdrawalCancelPopupInterval.value * 1000);
  }

  function clearTimer(): void {
    if (timeout) {
      clearInterval(timeout);
      timeout = 0;
    }
  }

  function resetFlow(removeTimestamp = false): void {
    isModalVisible.value = false;
    isModalVisibleFirstTimeWhenBalanceChanged.value = false;
    if (removeTimestamp) {
      lastPopupTimestamp.value = null;
    }
  }

  async function showDefaultPopupManually(): Promise<void> {
    await displayPopup();
    isModalVisibleFirstTimeWhenBalanceChanged.value = true;
  }

  watch(currentRouteName, async (newRouteName) => {
    if (newRouteName && withdrawalCancelPopup.value) {
      await handlePopupDisplay();
    } else {
      resetFlow();
    }
  });

  watch(totalBalance, async (newBalance, oldBalance) => {
    if (isInitialLoad.value) {
      isInitialLoad.value = false;
      return;
    }

    if (oldBalance > newBalance) {
      const limit = zeroBalanceLimit?.value ?? 0;
      const isZeroBalanceRequired = withdrawalCancelPopup.value?.onlyZeroBalanceShow;
      const isZeroCondition
        = withdrawalStatsData.value
          && withdrawalStatsData.value?.requestedCount > 0
          && isZeroBalanceRequired
          && newBalance <= limit;

      if (isZeroCondition && !isModalVisible.value && !isModalVisibleFirstTimeWhenBalanceChanged.value) {
        void showDefaultPopupManually();
      }
    }
  });

  watch(isLoggedIn, (loggedIn) => {
    if (!loggedIn) {
      resetFlow(true);
    } else {
      void handlePopupDisplay();
    }
  });

  watchEffect(() => {
    const popup = withdrawalCancelPopup.value;
    if (popup) {
      withdrawalCancelPopupTitle.value = $translate(popup.btrSubject).value;
      withdrawalCancelPopupMessage.value = $translate(popup.btrMessage).value;
      withdrawalCancelPopupButton.value = $translate(popup.btrButton).value;
    }
  });

  return {
    isPopupConfirmedClicked,
    setPopupConfirmed,
    handlePopupDisplay,
    isWithdrawalCancelEnabled,
  };
});
