import { InputFileTypes } from '@leon-hub/input-types';

import type { InputFileTypeRecords } from 'web/src/components/Input';

export const defaultFileTypes: InputFileTypeRecords = {
  'jpg': {
    type: InputFileTypes.Image,
  },
  'jpeg': {
    type: InputFileTypes.Image,
  },
  'png': {
    type: InputFileTypes.Image,
  },
  'pdf': {
    type: InputFileTypes.Pdf,
  },
  'mp4': {
    type: InputFileTypes.Video,
  },
  'mov': {
    type: InputFileTypes.Video,
  },
  '3gp': {
    type: InputFileTypes.Video,
  },
  'webm': {
    type: InputFileTypes.Video,
  },
  'm4v': {
    type: InputFileTypes.Video,
  },
};
