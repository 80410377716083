import type { Ref } from 'vue';
import { ref } from 'vue';

import type { BaseError } from '@leon-hub/errors';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ModalWidth } from '@components/dialogs';

import RegistrationApiErrorCode from '../../enums/RegistrationApiErrorCode';

interface RegistrationErrorsComposable {
  registrationError: Ref<string>;
  registrationErrorCode: Ref<string>;
  setRegistrationError(error: string): void;
  setRegistrationErrorCode(code: string): void;
  handleDuplicateError(
    error: BaseError,
    onConfirm: () => void,
    onAdditionalButton: () => void,
    onClose: () => void,
  ): boolean;
}

export function useRegistrationErrors(): RegistrationErrorsComposable {
  const registrationError = ref<string>('');
  const registrationErrorCode = ref<string>('');
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();

  function setRegistrationError(error = ''): void {
    registrationError.value = error;
  }

  function setRegistrationErrorCode(code = ''): void {
    registrationErrorCode.value = code;
  }

  function handleDuplicateError(
    error: BaseError,
    onConfirm: () => void,
    onAdditionalButton: () => void,
    onClose: () => void,
  ): boolean {
    if (error.code.equals(RegistrationApiErrorCode.DUPLICATE_EMAIL) || error.code.equals(RegistrationApiErrorCode.DUPLICATE_PHONE_NUMBER)) {
      const { subscribe } = showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          title: $translate('WEB2_REGISTRATION_ALREADY_HAVE_SCORE_TITLE').value,
          analyticsModalType: undefined,
          confirmMessage: error.message,
          width: ModalWidth.SMALL,
          isOverlayCloseAllowed: !process.env.VUE_APP_LAYOUT_DESKTOP,
          buttons: [
            {
              label: $translate('JSPACC_LOGIN_ENTER').value,
            },
            {
              action: DialogAction.ADDITIONAL_BUTTON,
              label: $translate('WEB2_SUPPORT').value,
            },
          ],
        },
      });
      subscribe({
        [DialogAction.CONFIRM]: () => {
          onConfirm();
        },
        [DialogAction.ADDITIONAL_BUTTON]: () => {
          onAdditionalButton();
        },
        [DialogAction.MODAL_CLOSE]: () => {
          onClose();
        },
      });
      return true;
    }
    return false;
  }

  return {
    registrationError,
    registrationErrorCode,
    setRegistrationError,
    setRegistrationErrorCode,
    handleDuplicateError,
  };
}
