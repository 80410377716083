<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';
import { TextInputTypes } from '@leon-hub/input-types';

import type { SuggestEmailInputProps } from '../../types';
import { useBaseInputEvents } from '../../composables';
import TextInput from '../TextInput';
import useSuggestEmailInput from './useSuggestEmailInput';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<SuggestEmailInputProps>(), {
  name: 'email',
  suggestion: () => ([]),
});

const emits = defineEmits<BaseInputEmits>();

const {
  emitInput,
  emitBlur,
  emitFocus,
  emitChange,
} = useBaseInputEvents(emits);

const { getEmailValue, listId } = useSuggestEmailInput(props);
</script>

<template>
  <TextInput v-auto-id="'SuggestEmailInput'"
    :autofocus="autofocus"
    :name="name"
    :list="listId"
    :type="TextInputTypes.EMAIL"
    :placeholder="placeholder"
    :label="label"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :value="value"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :suffix-icon-name="suffixIconName"
    :prefix-icon-name="prefixIconName"
    :is-large="isLarge"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  >
    <template #dropdown="{ value: inputValue }">
      <datalist :id="listId">
        <template v-if="getEmailValue(inputValue)">
          <option
            v-for="(suggest) in suggestion"
            :key="suggest"
            :value="`${getEmailValue(inputValue)}@${suggest}`"
          />
        </template>
      </datalist>
    </template>
  </TextInput>
</template>
