import { getAchievementsAvailability } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  GetAchievementsAvailabilityDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAchievementsAvailabilityRequest(): Promise<GetAchievementsAvailabilityDataDocument> {
  const apiClient = useGraphqlClient();

  return getAchievementsAvailability(
    apiClient,
    (node) => node.queries.achievements.getAvailability,
    {
      options: {},
    },
  );
}
