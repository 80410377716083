import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type {
  BonusMatrixRequest,
  BonusMatrixResponse,
  BonusWithdrawResponse,
  EgsCustomerBonusFragment,
  RefuseBonusRequest,
  RefuseBonusResponse,
  SportCustomerBonusFragment,
} from '@leon-hub/api-sdk';
import type { CustomerBonusesQueryResult } from '@leon-hub/api-types';
import { BonusCampaignType } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';
import { toLowerCase } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';

import type { CollectSubmitData } from 'web/src/modules/profile/submodules/bonuses/types';
import { ProfileButtonsTab } from 'web/src/modules/profile/submodules/bonuses/enums';

import {
  doRefuseBonusApi,
  doWithdrawBonusCBC,
  doWithdrawSportBonusApi,
  fetchCustomerBonuses,
  fetchSdkBonusMatrix,
} from '../utils';

const useProfileBonusesStore = defineStore('profile-bonuses', () => {
  const { $translate } = useI18n();

  /** state */
  const loading = ref<boolean>(true);
  const sportBonuses = ref<SportCustomerBonusFragment[]>([]);
  const egsBonuses = ref<EgsCustomerBonusFragment[]>([]);
  const bonusCampaignType = ref<Optional<BonusCampaignType>>();
  const isBonusPageVisited = ref<boolean>(false);
  const isOnboardingIconShown = ref<boolean>(true);
  const hideOnBoardingPage = ref<boolean>(false);
  const complexData = ref<Maybe<BonusMatrixRequest>>(null);
  const activeTab = ref<Optional<ProfileButtonsTab>>();
  const waitingRefreshDelay = ref<boolean>(false);

  /** getters */
  const initialTab = computed<ProfileButtonsTab>(() => {
    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && (!sportBonuses.value.length || egsBonuses.value.length)) {
      return ProfileButtonsTab.EGS;
    }

    return ProfileButtonsTab.SPORTS;
  });

  const totalBonusesLength = computed(() => sportBonuses.value.length + egsBonuses.value.length);

  /** Mutations */
  function setLoading(value: boolean): void {
    loading.value = value;
  }

  function setSportBonuses(bonuses: SportCustomerBonusFragment[]): void {
    sportBonuses.value = bonuses;
  }

  function setEgsBonuses(bonuses: EgsCustomerBonusFragment[]): void {
    egsBonuses.value = bonuses;
  }

  function setBonusCampaignType(value: Optional<BonusCampaignType>): void {
    bonusCampaignType.value = value;
  }

  function setIsBonusesPageVisited(value: boolean): void {
    isBonusPageVisited.value = value;
  }

  function setOnboardingIconState(value: boolean): void {
    isOnboardingIconShown.value = value;
  }

  function setHideOnBoardingPageState(value: boolean): void {
    hideOnBoardingPage.value = value;
  }

  function setComplexData(data: Maybe<BonusMatrixRequest>): void {
    complexData.value = data;
  }

  function setActiveTab(value: Optional<ProfileButtonsTab>): void {
    activeTab.value = value;
  }

  function setWaitingRefreshDelay(value: boolean): void {
    waitingRefreshDelay.value = value;
  }

  /** Actions */
  async function fetchBonuses(bonusCampaignTypeValue?: Optional<BonusCampaignType>): Promise<void> {
    try {
      const payload: CustomerBonusesQueryResult = await fetchCustomerBonuses(bonusCampaignTypeValue);
      if (payload) {
        const sportKey = toLowerCase(BonusCampaignType.SPORT);
        const egsKey = toLowerCase(BonusCampaignType.EGS);
        switch (bonusCampaignTypeValue) {
          case BonusCampaignType.SPORT: {
            setSportBonuses(payload[sportKey]);
            break;
          }
          case BonusCampaignType.EGS: {
            setEgsBonuses(payload[egsKey]);
            break;
          }
          default: {
            setSportBonuses(payload[sportKey]);
            setEgsBonuses(payload[egsKey]);
            break;
          }
        }

        if (bonusCampaignType.value !== bonusCampaignTypeValue) {
          setBonusCampaignType(bonusCampaignTypeValue);
        }

        if (payload.hideOnBoardingPage) {
          setHideOnBoardingPageState(true);
        }
      }
    } finally {
      if (loading.value) {
        setLoading(false);
      }
    }
  }

  async function doWithdrawSportBonus(campaignId: number): Promise<BonusWithdrawResponse> {
    const response = await doWithdrawSportBonusApi(campaignId);

    if (!response.withdrawnAmount || response.withdrawnAmount === 0) {
      throw new Error($translate('WEB2_SPORT_BONUS_EXCHANGE_ERROR_MESSAGE').value);
    }

    return response;
  }

  async function collectBonus(options: CollectSubmitData): Promise<void> {
    const { bonus } = options;
    switch (bonus.campaignType) {
      case BonusCampaignType.SPORT:
        await doWithdrawSportBonus(bonus.campaignId);
        setSportBonuses(sportBonuses.value.filter(({ bonusId }) => bonus.bonusId !== bonusId));
        break;
      case BonusCampaignType.EGS:
        await doWithdrawBonusCBC();
        setEgsBonuses(egsBonuses.value.filter(({ bonusId }) => bonus.bonusId !== bonusId));
        break;
      default:
        logger.error('Failed to collect bonus: BonusCampaignType is missing');
        break;
    }
  }

  async function refuseBonus(options: RefuseBonusRequest): Promise<RefuseBonusResponse> {
    return doRefuseBonusApi(options);
  }

  function getBonusMatrix(options: {
    bonusId: number;
    bonusCampaignType: BonusCampaignType;
  }): Promise<BonusMatrixResponse> {
    return fetchSdkBonusMatrix(options);
  }

  function switchOnboardingTopBarIcon(value: boolean): void {
    setOnboardingIconState(value);
  }

  return {
    loading,
    sportBonuses,
    egsBonuses,
    bonusCampaignType,
    isBonusPageVisited,
    isOnboardingIconShown,
    hideOnBoardingPage,
    complexData,
    initialTab,
    activeTab,
    waitingRefreshDelay,
    totalBonusesLength,
    setIsBonusesPageVisited,
    setHideOnBoardingPageState,
    setComplexData,
    fetchBonuses,
    collectBonus,
    refuseBonus,
    getBonusMatrix,
    switchOnboardingTopBarIcon,
    setActiveTab,
    setWaitingRefreshDelay,
  };
});

export default useProfileBonusesStore;
