import { normalizeError } from '@leon-hub/errors';

import type { DialogsComposable } from '@core/dialogs';
import { PresetName } from '@core/dialogs';

import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';
import type { SportlineApiService } from 'web/src/modules/sportline/types/services';
import { DetailsAdapter } from 'web/src/modules/sportline/utils/rest';

import type { UseExtendedEventSettingsComposable } from '../composables';
import type { FetchEventDetails, FetchEventDetailsProps } from '../types';

export function createFetchEventDetailsMethod(
  apiService: SportlineApiService,
  { parseSportlineSettings }: Pick<UseExtendedEventSettingsComposable, 'parseSportlineSettings'>,
  { showDialog }: Pick<DialogsComposable, 'showDialog'>,
): FetchEventDetails {
  return async function getSportEventDetails({ id, silent }: FetchEventDetailsProps): Promise<Maybe<SportlineEventDetails>> {
    try {
      const rawSportEvent = await apiService.getEventDetails({ id, silent });

      if (!rawSportEvent) {
        return null;
      }

      return new DetailsAdapter(rawSportEvent, parseSportlineSettings.value).sportEventDetails;
    } catch (rawError) {
      showDialog({
        id: 'extended-sport-event-details-error',
        presetName: PresetName.ALERT_ERROR,
        options: {
          confirmMessage: normalizeError(rawError).message,
          dataTestId: 'sport-event-details-error',
        },
      });

      return null;
    }
  };
}
