<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { SBadge } from '@components/badge';
import { ListType, SList, SListItem, VList, VListItem } from '@components/list';
import { VIcon } from '@components/v-icon';

import type { UserProfileNavigation } from 'web/src/modules/profile/types';

import useProfileNavList from './composables/useProfileNavList';

interface NavListProps {
  items?: UserProfileNavigation[];
  oneLevelList?: boolean;
  isRewardCenter?: boolean;
}
defineProps<NavListProps>();

const {
  getIconSettings,
  getBadgeLabel,
  checkHasNewNotifications,
  getCounterLabel,
  onClick,
  getBonusesValue,
} = useProfileNavList();
</script>

<template>
  <SList
    v-if="null"
    :list-type="ListType.GROUP"
    :is-reward-center="isRewardCenter"
  >
    <SListItem
      v-for="item in items"
      :key="item.id"
      v-data-test="{ el: 'navigation-item', navigationId: item.id }"
      :icon-name="getIconSettings(item)"
      :is-reward-center="isRewardCenter"
      type="primary"
      :title="item.caption"
      @click="onClick(item)"
    >
      <template #suffix>
        <VIcon
          v-if="!getBadgeLabel(item)"
          :name="null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT"
          :size="IconSize.SIZE_20"
        />
        <SBadge
          v-else
          is-counter
          :label="getBadgeLabel(item)"
          type="secondary"
        />
      </template>
    </SListItem>
  </SList>
  <VList
    v-else
    :list-type="ListType.GROUP"
  >
    <VListItem
      v-for="item in items"
      :key="item.id"
      v-data-test="{ el: 'navigation-item', navigationId: item.id }"
      :icon-name="getIconSettings(item)"
      :is-new="checkHasNewNotifications(item)"
      :title="item.caption"
      :have-expand="!item.done"
      :badge-label="getBadgeLabel(item)"
      :counter="getCounterLabel(item)"
      :suffix-text="getBonusesValue(item)"
      :icon-colored="!oneLevelList"
      :is-reward-center="item.props?.isRewardCenter ? !!item.props.isRewardCenter : false"
      :icon-custom-styles="item.props && item.props?.iconStyle ? String(item.props.iconStyle) : undefined"
      @click="onClick(item)"
    />
  </VList>
</template>
