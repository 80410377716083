<script setup lang="ts">
import type { BaseInputEmits, InputWrappedProps } from '@leon-hub/input-types';
import { TextInputTypes } from '@leon-hub/input-types';

import TextInput from '../TextInput';
import useCardExpirationDateInput from './useCardExpirationDateInput';

defineOptions({
  inheritAttrs: false,
});
withDefaults(defineProps<InputWrappedProps>(), {
  name: 'CardExpirationDate',
});
const emits = defineEmits<BaseInputEmits>();

const {
  onFocus,
  onBlur,
  onChange,
  onInput,
  maskOptions,
} = useCardExpirationDateInput(emits);
</script>

<template>
  <TextInput v-auto-id="'CardExpirationDateInput'"
    v-data-test="{
      el: 'card-expiration-date-input',
      name,
    }"
    :autofocus="autofocus"
    :value="value"
    :name="name"
    :label="label"
    :disabled="disabled"
    :hint="hint"
    :hint-link="hintLink"
    :hint-right="hintRight"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :mask="maskOptions"
    :type="TextInputTypes.TEL"
    :is-large="isLarge"
    is-ym-disable-keys
    :placeholder="$t('WEB2_CARD_EXPIRATION_DATE_PLACEHOLDER')"
    autocomplete="cc-exp"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>
