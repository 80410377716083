<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { ButtonKind, VButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';

import type {
  VFastMoneyChoiceEmits,
  VFastMoneyChoiceProperties,
} from '../types';
import { useFastMoneyChoice } from '../composables';
import { FastMoneyMaxPosition } from '../enums';

const props = withDefaults(defineProps<VFastMoneyChoiceProperties>(), {
  items: () => [],
  minAvailableValue: 0,
  maxValue: 0,
  balance: 0,
  maxLabel: 'MAX',
  maxPosition: FastMoneyMaxPosition.START,
});
const emit = defineEmits<VFastMoneyChoiceEmits>();

const {
  selectValue,
  computedItems,
  formattedBalance,
} = useFastMoneyChoice(props, emit);
</script>

<template>
  <div v-auto-id="'FastMoneyChoice'" :class="$style['fast-money-choice']">
    <div :class="$style['fast-money-choice__swiper']">
      <div
        v-if="null && showBalance && balance > 0"
        :class="$style['fast-money-choice__balance']"
      >
        <span
          :class="$style['fast-money-choice__balance-icon']"
        >
          <VIcon
            :name="IconName.SLOTT_INFO"
            :size="IconSize.SIZE_16"
          />
        </span>
        <span
          :class="$style['fast-money-choice__balance-label']"
        >
          {{ $t('WEB2_WITHDRAWALS_AVAILABLE_BALANCE') }}
        </span>
        <span
          :class="$style['fast-money-choice__balance-value']"
        >
          {{ formattedBalance }}
        </span>
      </div>
      <VSwiper>
        <VSwiperSlide
          v-if="showEditButton"
          :class="{
            [$style['fast-money-choice__slide']]: true,
            [$style['fast-money-choice__slide--spaced']]: isBordered,
            [$style['fast-money-choice__slide--not-editable']]: !isBordered && !showEditButton,
          }"
        >
          <VButton
            :class="$style['fast-money-choice__button-edit']"
            :icon-name="IconName.PENCIL"
            :kind="ButtonKind.TRANSPARENT"
            @click="emit('edit-click')"
          />
        </VSwiperSlide>
        <template
          v-for="({ isActive, value, label, isDisabled }, index) in computedItems"
          :key="index"
        >
          <VSwiperSlide
            :class="{
              [$style['fast-money-choice__slide']]: true,
              [$style['fast-money-choice__slide--spaced']]: isBordered,
              [$style['fast-money-choice__slide--not-editable']]: !isBordered && !showEditButton,
            }"
          >
            <button
              :class="{
                [$style['fast-money-choice__button--active']]: isActive,
                [$style['fast-money-choice__button--disabled']]: isDisabled,
                [$style['fast-money-choice__button']]: true,
                [$style['fast-money-choice__button--bordered']]: isBordered,
                [$style['fast-money-choice__button--active-bordered']]: isBordered && isActive,
              }"
              :disabled="isDisabled"
              type="button"
              @click="selectValue(value)"
            >
              {{ label }}
            </button>
          </VSwiperSlide>
        </template>
      </VSwiper>
    </div>
  </div>
</template>

<style lang="scss" module>
.fast-money-choice {
  position: relative;
  user-select: $fastMoneyChoiceUserSelect;

  &__swiper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__slide {
    &--spaced {
      flex: 1 0 auto;
      max-width: calc(50% - 4px);
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }

    &--not-editable {
      &:first-of-type {
        padding-left: 10px;
      }
    }
  }

  &__button {
    @include medium\14\16\025;

    width: 100%;
    height: $fastMoneyChoiceButtonHeight;
    padding: 0 8px;
    color: var(--TextPrimary);
    cursor: pointer;
    background-color: $fastMoneyChoiceDefaultBg;
    border: none;

    @include for-hover {
      &:hover {
        color: $fastMoneyChoiceHoverColor;
      }
    }

    &--bordered {
      @include regular\14\20\025;

      padding: 0 16px;
      color: $fastMoneyChoiceDefaultColor;
      border-radius: 22px;
      box-shadow: inset 0 0 0 $fastMoneyChoiceDefaultShadowWidth $fastMoneyChoiceDefaultShadow;

      @include for-hover {
        &:hover {
          color: $fastMoneyChoiceHoverColor;
          cursor: pointer;
          background-color: $fastMoneyChoiceHoverBg;
          box-shadow: inset 0 0 0 $fastMoneyChoiceDefaultShadowWidth $fastMoneyChoiceHoverShadow;
        }
      }
    }

    &--disabled {
      cursor: default;

      &,
      &:hover {
        color: var(--Highlight);
      }

      &-bordered {
        opacity: 0.3;

        @include for-hover {
          &:hover {
            box-shadow: inset 0 0 0 1px var(--Highlight);
          }
        }
      }
    }

    &--active {
      font-weight: 500;
      color: var(--BrandDefault);
      cursor: default;

      @include for-hover {
        &:hover {
          color: var(--BrandDefault);
          cursor: default;
        }
      }

      &-bordered {
        color: $fastMoneyChoiceActiveColor;
        background-color: $fastMoneyChoiceActiveBg;
        box-shadow: inset 0 0 0 $fastMoneyChoiceDefaultShadowWidth $fastMoneyChoiceActiveShadow;

        @include for-hover {
          &:hover {
            color: $fastMoneyChoiceActiveColor;
            box-shadow: inset 0 0 0 $fastMoneyChoiceDefaultShadowWidth $fastMoneyChoiceActiveShadow;
          }
        }
      }
    }
  }

  &__balance {
    display: flex;
    gap: $fastMoneyChoiceBalanceGap;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
  }

  &__balance-value {
    @include fastMoneyChoiceBalanceValueFont;

    color: $fastMoneyChoiceBalanceValueColor;
  }

  &__balance-label {
    @include fastMoneyChoiceBalanceLabelFont;

    color: $fastMoneyChoiceBalanceLabelColor;
  }

  &__balance-icon {
    color: $fastMoneyChoiceBalanceIconColor;
  }
}
</style>
