import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { InputEventType } from '@leon-hub/input-types';
import { DateTime } from '@leon-hub/utils';

import { DateInputType } from 'web/src/components/Input/enums';
import createUniqueId from 'web/src/utils/createUniqueId';

import type { NativeDateInputEmits, NativeDateInputProps } from './types';
import {
  applyLimitationsTo,
  getMaxDate,
  getMinDate,
  getTargetValue,
} from '../../utils';

interface UseNativeDateInput {
  uniqueId: ComputedRef<string>;
  systemInputValue: Ref<string>;
  systemInputMin: Ref<string>;
  systemInputMax: Ref<string>;
  displayingValue: Ref<string>;
  onChange(event: InputEvent): void;
  onInput(event: InputEvent): void;
  onFocus(event: FocusEvent): void;
  onBlur(event: FocusEvent): void;
}

export default function useNativeDateInput(
  props: NativeDateInputProps,
  emit: NativeDateInputEmits,
): UseNativeDateInput {
  const uniqueId = computed<string>(() => createUniqueId(props.name || ''));

  const systemInputValue = ref<string>('');

  const systemInputMin = ref<string>('');

  const systemInputMax = ref<string>('');

  const displayingValue = ref<string>('');

  const minDate = computed<number | undefined>(() => getMinDate(props.min));

  const maxDate = computed<number | undefined>(() => getMaxDate(props.max));

  const setSystemInputValue = (value?: number): void => {
    if (!value) {
      systemInputValue.value = '';
      displayingValue.value = '';
      return;
    }

    const timestamp = applyLimitationsTo(value, minDate.value, maxDate.value, props.roundTo);

    switch (props.type) {
      case DateInputType.Date: {
        systemInputValue.value = DateTime.withTimeStamp(timestamp).toInputDate();
        displayingValue.value = DateTime.withTimeStamp(timestamp).toFullDate();
        break;
      }
      case DateInputType.DateTime: {
        systemInputValue.value = DateTime.withTimeStamp(timestamp).toInputDateTimeLocal();
        displayingValue.value = DateTime.withTimeStamp(timestamp).toDateTime();
        break;
      }
      default: {
        throw new Error(`Unknown DateInput type "${props.type}"`);
      }
    }
  };

  const setSystemInputMin = (value?: number): void => {
    if (!value) {
      systemInputMin.value = '';
      return;
    }

    switch (props.type) {
      case DateInputType.Date: {
        systemInputMin.value = DateTime.withTimeStamp(value).toInputDate();
        break;
      }
      case DateInputType.DateTime: {
        systemInputMin.value = DateTime.withTimeStamp(value).toInputDateTimeLocal();
        break;
      }
      default: {
        throw new Error(`Unknown DateInput type "${props.type}"`);
      }
    }

    setSystemInputValue(applyLimitationsTo(props.value || 0, minDate.value, maxDate.value, props.roundTo));
  };

  const setSystemInputMax = (value?: number): void => {
    if (!value) {
      systemInputMax.value = '';
      return;
    }

    switch (props.type) {
      case DateInputType.Date: {
        systemInputMax.value = DateTime.withTimeStamp(value).toInputDate();
        break;
      }
      case DateInputType.DateTime: {
        systemInputMax.value = DateTime.withTimeStamp(value).toInputDateTimeLocal();
        break;
      }
      default: {
        throw new Error(`Unknown DateInput type "${props.type}"`);
      }
    }

    setSystemInputValue(applyLimitationsTo(props.value || 0, minDate.value, maxDate.value, props.roundTo));
  };

  watch(() => props.value, (to) => {
    setSystemInputValue(to);
  }, { immediate: true });

  watch(() => props.min, () => {
    setSystemInputMin(minDate.value);
  }, { immediate: true });

  watch(() => props.max, () => {
    setSystemInputMax(maxDate.value);
  }, { immediate: true });

  watch(() => props.type, () => {
    setSystemInputValue(props.value);
    setSystemInputMin(minDate.value);
    setSystemInputMax(maxDate.value);
  });

  const onChange = (event: InputEvent): void => {
    const value = getTargetValue(event);
    if (value) {
      const timestamp = applyLimitationsTo(DateTime.toTimestamp(value), minDate.value, maxDate.value, props.roundTo);
      setSystemInputValue(timestamp);
      emit(InputEventType.CHANGE, { ...event, timestamp });
    } else {
      // clear button
      emit(InputEventType.CHANGE, { ...event });
    }
  };

  const onInput = (event: InputEvent): void => {
    emit(InputEventType.INPUT, event);
  };

  const onBlur = (event: FocusEvent): void => {
    emit(InputEventType.BLUR, event);
  };

  const onFocus = (event: FocusEvent): void => {
    emit(InputEventType.FOCUS, event);
  };

  return {
    uniqueId,
    systemInputMax,
    systemInputMin,
    systemInputValue,
    displayingValue,
    onBlur,
    onInput,
    onFocus,
    onChange,
  };
}
