import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

export interface LobbyEgsGamesItemButtonsHoverComposable {
  playButtonProperties: ComputedRef<VButtonProps>;
  demoButtonProperties: ComputedRef<VButtonProps>;
}

export default function useLobbyEgsGamesItemButtonsHover(): LobbyEgsGamesItemButtonsHoverComposable {
  const { $translate } = useI18n();

  const playButtonProperties = computed<VButtonProps>(() => ({
    rounded: true,
    kind: ButtonKind.PRIMARY,
    label: $translate('BUTTON_PLAY').value,
  }));

  const demoButtonProperties = computed<VButtonProps>(() => ({
    rounded: true,
    isUppercase: false,
    kind: ButtonKind.SECONDARY,
    height: ButtonHeight.TINY,
    label: $translate('WEB2_EGS_BUTTON_DEMO').value,
  }));

  return {
    playButtonProperties,
    demoButtonProperties,
  };
}
