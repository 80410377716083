<script lang="ts" setup>
import { SButton } from '@components/buttons';

import type { RestorePasswordContentEmits, RestorePasswordContentProps } from './types';

const props = defineProps<RestorePasswordContentProps>();
const emits = defineEmits<RestorePasswordContentEmits>();
const { restoreTitle } = props;
</script>

<template>
  <template
    v-if="null"
  >
    <p
      :class="{
        [$style['restore__helper-text']]: true,
        [$style['restore__helper-text--block']]: true,
      }"
    >
      {{ `${restoreTitle} ${$t('RESTORE_PASSWORD_LETTER_RESEND')}` }}
    </p>
    <SButton
      size="large"
      :label="$t('WEB2_RESTORE_PASSWORD_RESEND_MAIL')"
      full-width
      @click="emits('click');"
    />
  </template>
  <template
    v-else
  >
    <p
      :class="{
        [$style['restore__helper-text']]: true,
        [$style['restore__helper-text--block']]: true,
      }"
    >
      {{ restoreTitle }}
    </p>
    <p
      :class="{
        [$style['restore__helper-text']]: true,
        [$style['restore__helper-text--resend']]: true,
      }"
    >
      {{ $t('RESTORE_PASSWORD_LETTER_RESEND') }}
    </p>
    <span
      :class="$style['restore-text--link']"
      @click="emits('click');"
    >
      {{ $t('WEB2_RESTORE_PASSWORD_RESEND_MAIL') }}.
    </span>
  </template>
</template>

<style lang="scss" module>
.restore {
  .restore__helper-text--block {
    margin-bottom: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__helper-text {
    &--resend {
      display: inline;
    }

    &--link {
      @include regular\14\24\025;

      display: inline-flex;
      height: initial;
      padding: 0;
      margin: 0 auto;
      color: var(--TextDefault);
      text-transform: none;
      white-space: nowrap;
      text-decoration: underline;
    }
  }

  &-text--link {
    color: var(--TextDefault);
    text-decoration: underline;

    @include for-hover {
      &:hover {
        color: var(--BrandHighlight);
        cursor: pointer;
      }
    }
  }
}
</style>
