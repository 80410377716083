<script setup lang="ts">
import { computed, toRef } from 'vue';

import type { Market, MarketType } from 'web/src/modules/sportline/types';
import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';

import { createShownMarketNameRef } from '../utils';

interface MainMarketContainerProps {
  actualMarket?: Maybe<Market>;
  actualMarketType?: Maybe<MarketType>;
  actualMarketTypePriority?: number;
  runnersPlacesCount?: Optional<number>;
}

interface MainMarketContainerSlots {
  default(props: Dictionary<never>): void;
}

const props = withDefaults(defineProps<MainMarketContainerProps>(), {
  actualMarket: null,
  actualMarketType: null,
  actualMarketTypePriority: 0,
});
defineSlots<MainMarketContainerSlots>();

const actualMarket = toRef(() => props.actualMarket);
const actualMarketType = toRef(() => props.actualMarketType);
const actualMarketTypePriority = toRef(() => props.actualMarketTypePriority);
const runnersPlacesCount = toRef(() => props.runnersPlacesCount);

const shownMarketName = createShownMarketNameRef({ actualMarket, actualMarketType, actualMarketTypePriority });

const isHighestOdds = computed<boolean>(() => !!actualMarket.value?.hasZeroMargin);
const hasExtraLines = computed<boolean>(() => (!!shownMarketName.value || isHighestOdds.value));
</script>

<template>
  <div v-auto-id="'MainMarketContainer'"
    :class="{
      [$style['main-market-container']]: true,
      [$style['main-market-container--with-top-line']]: shownMarketName,
      [$style['main-market-container--with-bottom-line']]: isHighestOdds,
    }"
  >
    <div
      v-if="shownMarketName"
      v-data-test="{ el: 'main-market-name' }"
      :class="{
        [$style['market-name-line']]: true,
        [$style['fit-to-columns']]: true,
        [$style[`fit-to-columns--${runnersPlacesCount}`]]: !!runnersPlacesCount,
      }"
    >
      {{ shownMarketName }}
    </div>

    <div
      :class="{
        [$style['main-market-container__main-content']]: true,
        [$style['main-market-container__main-content--short']]: hasExtraLines,
      }"
    >
      <slot />
    </div>

    <div
      v-if="isHighestOdds"
      v-data-test="{ el: 'main-market-highest-odds' }"
      :class="{
        [$style['highest-odds-line']]: true,
        [$style['fit-to-columns']]: true,
        [$style[`fit-to-columns--extra-${runnersPlacesCount}`]]: !!runnersPlacesCount,
      }"
    >
      <HighestOddsBadge
        highest-odds-icon-color="gradient"
        badge-background="transparent"
        do-hide-text-for-small-size
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-event-list-columns';

@include for-layout using ($isDesktop) {
  .main-market-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 8px 10px 0;

    &--with-top-line {
      padding-top: 4px;
    }

    &--with-bottom-line {
      padding-bottom: 4px;
    }

    &__main-content {
      display: flex;
      gap: if($isDesktop, $runnerColumnGapDesktop, $runnerColumnGap);
      height: 56px;
      min-height: 40px;

      &--short {
        height: 48px;
      }
    }
  }

  .market-name-line {
    @include regular\12\12;

    padding: 2px;
    margin-bottom: 4px;
    overflow: hidden;
    color: var(--TextPrimary);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--Layer2);
    border-radius: 5px;
  }

  .highest-odds-line {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
  }

  .fit-to-columns {
    // @see LEONWEB-14684 hack for hold whole text in the line on small devices
    $extraWidth: if($isDesktop, 0, 2px);

    width: 100%;

    &--2 {
      @include columnsWidth($isDesktop, 2, false);
    }

    &--3 {
      @include columnsWidth($isDesktop, 3, false);
    }

    &--extra-2 {
      @include columnsWidth($isDesktop, 2, false, $extraWidth);
    }

    &--extra-3 {
      @include columnsWidth($isDesktop, 3, false, $extraWidth);
    }
  }
}
</style>
