import type { ComputedRef, Slots } from 'vue';
import { computed, watch } from 'vue';

import type { IInputMode, ITextInputTypes, MaskOptions } from '@leon-hub/input-types';
import {
  isString,
} from '@leon-hub/guards';
import { CustomInputEventType, InputMode, TextInputTypes } from '@leon-hub/input-types';
import { toNumber } from '@leon-hub/vanilla-masker';

import type { UseInputCore } from '../../composables/types';
import type {
  TextInputEmits,
  TextInputProps,
  UseHintParentPropsOutput,
} from '../../types';
import {
  useHintParentProps,
  useInputCore,
  useMaskedInput,
} from '../../composables';
import {
  handleMaskedValuePaste,
  handleRegexpLimitedInput,
  handleRegexpLimitedPaste,
  handleRegularInput,
} from '../../composables/utils';

interface UseTextInput extends UseHintParentPropsOutput,
  Omit<UseInputCore, 'setInputValue' | 'emitChange' | 'emitInput' | 'clearValue'> {
  hasIconSuffix: ComputedRef<boolean>;
  isMaskedPlaceholderHidden: ComputedRef<boolean>;
  inputType: ComputedRef<ITextInputTypes>;
  inputMode: ComputedRef<IInputMode>;
  maskedValue: ComputedRef<string>;
  formattedValue: ComputedRef<string>;
  clickablePrefixIcon: ComputedRef<boolean>;
  clickableSuffixIcon: ComputedRef<boolean>;
  inputMaxLength: ComputedRef<number | undefined>;
  emitIconSuffixClick(): void;
  emitIconPrefixClick(): void;
  onClear(): void;
  onChange(event: InputEvent): void;
  onInput(event: InputEvent): void;
  onPaste(event: ClipboardEvent): void;
  onKeyDown(event: KeyboardEvent): void;
}

export default function useTextInput(
  props: TextInputProps,
  emit: TextInputEmits,
  slots?: Slots,
): UseTextInput {
  const {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    showClearButton,
    clearValue,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    setInputValue,
    emitInput,
    emitChange,
  } = useInputCore(props, emit, false);

  const { hintProperties, hasError } = useHintParentProps(props);

  const hasIconSuffix = computed<boolean>(
    () => !!props.suffixIconName || showClearButton.value,
  );

  const inputType = computed<ITextInputTypes>(() => {
    if (props.type === TextInputTypes.NUMBER) {
      return TextInputTypes.TEL;
    }

    return props.type ?? TextInputTypes.TEXT;
  });

  const inputMode = computed<IInputMode>(() => {
    switch (props.type) {
      case TextInputTypes.TEL:
        return InputMode.TEL;
      case TextInputTypes.NUMBER:
        return InputMode.NUMERIC;
      case TextInputTypes.EMAIL:
        return InputMode.EMAIL;
      default:
        return InputMode.TEXT;
    }
  });

  const emitIconSuffixClick = (): void => {
    emit(CustomInputEventType.ICON_SUFFIX_CLICK);
  };

  const emitIconPrefixClick = (): void => {
    emit(CustomInputEventType.ICON_PREFIX_CLICK);
  };

  const emitFilled = (): void => {
    emit(CustomInputEventType.FILLED);
  };

  const onClear = (): void => {
    clearValue();
    emit(CustomInputEventType.CLEAR);
  };

  const maskOptions = computed<MaskOptions | undefined>(() => {
    if (!props.mask) {
      return undefined;
    }
    const defaultOptions: Partial<MaskOptions> = {
      placeholder: 'X',
    };
    return isString(props.mask)
      ? { ...defaultOptions, pattern: props.mask }
      : { ...defaultOptions, ...props.mask };
  });

  const {
    isMaskedPlaceholderHidden,
    formattedValue,
    maskedValue,
    getValueCutToPattern,
    getMaskedInputResult,
    getMaskedKeydownResult,
  } = useMaskedInput({
    inputValue,
    isFocus,
    maskOptions,
  });

  watch(() => props.mask, () => {
    const nextValue = getValueCutToPattern();
    if (nextValue !== inputValue.value) {
      setInputValue(nextValue);
      emitInput();
    }
  });

  const inputMaxLength = computed<number | undefined>(() => {
    if (!props.maxSymbols && !maskOptions.value) {
      return undefined;
    }
    if (props.maxSymbols && maskOptions.value) {
      return Math.max(props.maxSymbols, maskOptions.value?.pattern.length);
    }
    return props.maxSymbols ?? maskOptions.value?.pattern.length;
  });

  const onPropValueChange = (value: string): void => {
    if (inputValue.value !== value) {
      setInputValue(maskOptions.value ? toNumber(value) : value);
    }
  };

  watch(() => props.value, (value) => onPropValueChange(value ?? ''));

  const onKeyDown = (event: KeyboardEvent): void => {
    emit('keydown', event);
    if (!maskOptions.value) {
      return;
    }

    const result = getMaskedKeydownResult(event);
    if (result && result.nextValue !== maskedValue.value) {
      setInputValue(result.nextValue);
      emitInput();
    }
  };

  const limitingRegexp = computed<RegExp | null>(() => {
    if (props.regexp) {
      return new RegExp(props.regexp, 'm');
    }
    return null;
  });

  const onRegexpPaste = (event: ClipboardEvent): void => {
    if (!limitingRegexp.value) {
      return;
    }
    const currentValue = inputValue.value;
    const nextValue = handleRegexpLimitedPaste({
      event,
      regexp: limitingRegexp.value,
      currentValue,
    });
    setInputValue(nextValue);
    emitInput();
    if ((nextValue !== currentValue) && (props.maxSymbols && nextValue.length >= props.maxSymbols)) {
      emitFilled();
    }
  };

  const onMaskedPaste = (event: ClipboardEvent): void => {
    if (!maskOptions.value) {
      return;
    }
    const result = handleMaskedValuePaste({
      event,
      maskOptions: maskOptions.value,
      currentValue: inputValue.value,
    });
    if (result) {
      const { nextValue, isFilled } = result;
      setInputValue(nextValue);
      emitInput();
      if (isFilled) {
        emitFilled();
      }
    }
  };

  const onPaste = (event: ClipboardEvent): void => {
    if (maskOptions.value) {
      onMaskedPaste(event);
      return;
    }
    if (limitingRegexp.value) {
      onRegexpPaste(event);
    }
    // no custom handling by default
  };

  const onRegularInput = (event: InputEvent): void => {
    let nextValue: string;
    if (!limitingRegexp.value) {
      nextValue = handleRegularInput(event);
    } else {
      nextValue = handleRegexpLimitedInput({
        event,
        regexp: limitingRegexp.value,
        currentValue: inputValue.value,
      });
    }
    setInputValue(nextValue);
    emitInput();
    if (props.maxSymbols && inputValue.value.length >= props.maxSymbols) {
      emitFilled();
    }
  };

  const onMaskedInput = (event: InputEvent): void => {
    const result = getMaskedInputResult(event);
    if (result) {
      const { nextValue, isFilled } = result;
      setInputValue(nextValue);
      emitInput();
      if (isFilled) {
        emitFilled();
      }
    }
  };

  const onInput = (event: InputEvent): void => {
    if (!maskOptions.value) {
      onRegularInput(event);
    } else {
      onMaskedInput(event);
    }
  };

  const clickablePrefixIcon = computed<boolean>(() => !!((props.prefixIconName ?? slots?.iconPrefix) && props.clickableIcon));

  const clickableSuffixIcon = computed<boolean>(() => showClearButton.value || !!((props.suffixIconName ?? slots?.iconSuffix) && props.clickableIcon));

  return {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    hintProperties,
    hasError,
    hasIconSuffix,
    inputType,
    inputMode,
    isMaskedPlaceholderHidden,
    showClearButton,
    formattedValue,
    maskedValue,
    inputMaxLength,
    clickablePrefixIcon,
    clickableSuffixIcon,
    emitIconSuffixClick,
    emitIconPrefixClick,
    onClear,
    onBlur,
    onFocus,
    onMouseOver,
    onMouseLeave,
    onKeyDown,
    onPaste,
    onChange: emitChange,
    onInput,
  };
}
