import type { SharedSlipEntry } from '@leon-hub/api-sdk';

import type {
  BetInfoObject,
  SlipEntry,
} from '../../slip-info/types';
import { getSlipEntryId } from '../../../utils';
import { getBetsInfoFromSharedSlipEntry } from './getBetsInfoFromSharedSlipEntry';
import { getSlipEntryFromSharedSlipEntry } from './getSlipEntryFromSharedSlipEntry';

export interface SharedSlipData {
  betsInfo: BetInfoObject;
  slipEntries: SlipEntry[];
}

export function convertSharedSlip(sharedSlipEntries: SharedSlipEntry[]): SharedSlipData {
  const slipEntries = [];
  const betsInfo: BetInfoObject = {};

  for (const sharedEntry of sharedSlipEntries) {
    const slipEntry = getSlipEntryFromSharedSlipEntry(sharedEntry);
    const betsInfoItem = getBetsInfoFromSharedSlipEntry(sharedEntry);
    const itemId = getSlipEntryId(slipEntry);
    slipEntries.push(slipEntry);
    betsInfo[itemId] = betsInfoItem;
  }

  return {
    slipEntries,
    betsInfo,
  };
}
