import type { ChangePasswordRequest } from '@leon-hub/api-sdk';

import { useUserStore } from '@core/user';

import { useCordovaBiometricAuthStore } from 'web/src/modules/biometric-auth/store';

export interface UseChangePasswordComposable {
  changePassword(input: ChangePasswordRequest): Promise<void>;
}

export function useChangePassword(): UseChangePasswordComposable {
  const userStore = useUserStore();

  async function changePassword(input: ChangePasswordRequest): Promise<void> {
    await userStore.changePassword(input);
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await useCordovaBiometricAuthStore().updatePassword(input.newPassword);
    }
  }

  return {
    changePassword,
  };
}
