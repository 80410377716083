import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  ref,
  useSlots,
  watch,
} from 'vue';

import type { InputHintProps } from '@leon-hub/input-types';
import { assert } from '@leon-hub/guards';
import { CustomInputEventType } from '@leon-hub/input-types';

import type { MultilineTextInputEmits } from 'web/src/components/Input/types/MultilineTextInputEmits';

import type { UseInputCore } from '../../composables/types';
import type { MultilineTextInputProperties } from '../../types';
import { useHintParentProps, useInputCore } from '../../composables';
import { getStyleNumberValue } from '../../utils';
import { getMinMaxRow } from './utils';

interface UseMultilineTextInput extends Omit<UseInputCore, 'showClearButton'
  | 'emitChange'
  | 'emitInput'
  | 'setInputValue'> {
  hintProps: ComputedRef<InputHintProps>;
  hasError: ComputedRef<boolean>;
  rows: Ref<number>;
  clickableSuffixIcon: ComputedRef<boolean>;
  onChange(event: InputEvent): void;
  onInput(event: InputEvent): void;
  emitIconSuffixClick(): void;
  setRowsOnMount(): void;
}

export default function useMultilineTextInput(
  props: MultilineTextInputProperties,
  emits: MultilineTextInputEmits,
  textAreaRef?: Ref<HTMLElement | undefined>,
): UseMultilineTextInput {
  const slots = useSlots();

  const {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    clearValue,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    emitChange,
    emitInput,
    setInputValue,
  } = useInputCore(props, emits);

  const { hintProperties, hasError } = useHintParentProps(props);

  const hintProps = computed<InputHintProps>(() => ({
    ...hintProperties.value,
    hintRight: props.maxLength ? `${(inputValue.value || '').length}/${props.maxLength}` : props.hintRight,
  }));

  const rows = ref<number>(props.rowsMin || 1);

  const watchRowsMin = (): void => {
    if (props.rowsMin) {
      rows.value = Math.max(props.rowsMin, rows.value);
    }
  };

  const watchRowsMax = (): void => {
    if (props.rowsMax) {
      rows.value = getMinMaxRow(rows.value, props.rowsMax, (props.rowsMin || 0));
    }
  };

  watch(() => props.rowsMin, watchRowsMin);
  watch(() => props.rowsMax, watchRowsMax);

  const calculateRows = (target: HTMLTextAreaElement, value: string): void => {
    const { rowsMax, rowsMin } = props;
    const computedStyle = window.getComputedStyle(target);
    const padding = getStyleNumberValue(computedStyle, 'paddingBottom')
      + getStyleNumberValue(computedStyle, 'paddingTop');
    const lineHeight = getStyleNumberValue(computedStyle, 'lineHeight');
    const computedRows = value ? Math.floor((target.scrollHeight - padding) / lineHeight) : 1;
    if (rowsMax || rowsMin) {
      rows.value = getMinMaxRow(computedRows, (rowsMax || 0), (rowsMin || 0));
    } else {
      rows.value = computedRows;
    }
  };

  const setRowsOnMount = (): void => {
    if (textAreaRef?.value) {
      assert(textAreaRef.value instanceof HTMLTextAreaElement, 'unexpected textAreaRef');
      calculateRows(textAreaRef.value, textAreaRef.value.value);
    }
  };

  const handleUserInput = (event: InputEvent, emitFunction: () => void): void => {
    const { target } = event;
    assert(target instanceof HTMLTextAreaElement);
    const { value } = target;
    if (value === inputValue.value) {
      return;
    }
    calculateRows(target, value);
    setInputValue(value);
    emitFunction();
  };

  const handleChange = (event: InputEvent): void => handleUserInput(event, emitChange);
  const handleInput = (event: InputEvent): void => handleUserInput(event, emitInput);

  const emitIconSuffixClick = (): void => {
    emits(CustomInputEventType.ICON_SUFFIX_CLICK);
  };

  const clickableSuffixIcon = computed<boolean>(() => !!((props.suffixIconName ?? slots?.iconSuffix) && props.clickableIcon));

  return {
    uniqueId,
    hintProps,
    hasError,
    rows,
    isEmpty,
    inputValue,
    isFocus,
    isHover,
    clearValue,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    onChange: handleChange,
    onInput: handleInput,
    emitIconSuffixClick,
    clickableSuffixIcon,
    setRowsOnMount,
  };
}
