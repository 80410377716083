import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type {
  BaseInputEmits,
  InputWrapperProps,
  VInputInputEvent,
} from '@leon-hub/input-types';

import type { InputBorderWrapperProps } from 'web/src/components/Input/types';
import { useBaseInputEvents, useHintParentProps } from 'web/src/components/Input/composables';
import createUniqueId from 'web/src/utils/createUniqueId';

import type { DropdownSelectProperties, SingleSelectProperties } from '../types';

interface UseSingleSelect {
  selectSelectedValue: Ref<string>;
  selectedLabel: ComputedRef<string>;
  mouseover(): void;
  mouseleave(): void;
  onChange(event: VInputInputEvent): void;
  onFocus(): void;
  onBlur(): void;
  borderWrapperProps: ComputedRef<InputBorderWrapperProps>;
  wrapperInputProps: ComputedRef<InputWrapperProps>;
  selectProperties: ComputedRef<DropdownSelectProperties>;
}

export default function useSingleSelect(props: SingleSelectProperties, emits: BaseInputEmits): UseSingleSelect {
  const isFocused = ref<boolean>(false);
  const isHovered = ref<boolean>(false);
  const selectSelectedValue = ref<string>(props.selectedValue || '');
  const { emitChange, emitFocus, emitBlur } = useBaseInputEvents(emits);

  const uniqueId = computed<string>(() => createUniqueId(props.name || 'select'));

  watch(() => props.selectedValue, () => {
    selectSelectedValue.value = props.selectedValue || '';
  });

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      name: props.name || '',
      value: selectSelectedValue.value,
    },
  }));

  const selectedLabel = computed<string>(() => {
    if (!selectSelectedValue.value || !props.options) {
      return '';
    }
    const selectedItem = props.options.find((item) => item.value === selectSelectedValue.value);
    return selectedItem ? selectedItem.label : '';
  });

  const { hasError, hintProperties } = useHintParentProps(props);

  const borderWrapperProps = computed<InputBorderWrapperProps>(() => ({
    isFocus: isFocused.value,
    isHover: isHovered.value,
    isDisabled: props.disabled,
    hasError: hasError.value,
  }));

  const wrapperInputProps = computed<InputWrapperProps>(() => ({
    ...hintProperties.value,
    inputId: uniqueId.value,
    label: props.label,
    disabled: props.disabled,
    isFocus: isFocused.value,
    isEmpty: !selectSelectedValue.value,
    isLarge: props.isLarge,
  }));

  const selectProperties = computed<DropdownSelectProperties>(() => ({
    autofocus: props.autofocus,
    id: uniqueId.value,
    disabled: props.disabled,
    dropdownMinWidth: props.dropdownMinWidth,
    hasError: hasError.value,
    isEmpty: !selectSelectedValue.value,
    name: props.name,
    options: props.options,
    placeholder: props.placeholder,
    selectedLabel: selectedLabel.value,
    selectedValue: selectSelectedValue.value,
    isCountrySelector: props.isCountrySelector,
    isLarge: props.isLarge,
  }));

  const mouseover = (): void => {
    isHovered.value = true;
  };

  const mouseleave = (): void => {
    isHovered.value = false;
  };

  const onChange = (event: VInputInputEvent): void => {
    const { value } = event.target;
    if (value === selectSelectedValue.value) {
      return;
    }
    selectSelectedValue.value = value;
    emitChange(eventToEmit.value);
  };

  const onFocus = (): void => {
    isFocused.value = true;
    emitFocus(eventToEmit.value);
  };

  const onBlur = (): void => {
    isFocused.value = false;
    emitBlur(eventToEmit.value);
  };

  return {
    mouseleave,
    mouseover,
    onChange,
    onFocus,
    onBlur,
    selectedLabel,
    selectSelectedValue,
    borderWrapperProps,
    wrapperInputProps,
    selectProperties,
  };
}
