import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { useAnalytics } from '@core/analytics';
import { useI18n } from '@core/i18n';
import { useCurrency, useCustomerFinanceStore, useFormatMoney } from '@core/money';

import type { VButtonProps } from '@components/buttons';
import { ButtonKind } from '@components/buttons';

import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { usePaymentsDepositsNavigation } from 'web/src/modules/core/composables/navigation';

import type { MainBalanceEmits, MainBalanceProps } from '../types';

export interface MainBalanceComposable {
  buttonProperties: ComputedRef<VButtonProps>;
  formattedBalance: ComputedRef<string>;
  balanceLabel: ComputedRef<string>;
  handleClick(): void;
}

export default function useMainBalance(props: MainBalanceProps, emit: MainBalanceEmits): MainBalanceComposable {
  const { $translate } = useI18n();
  const formatMoney = useFormatMoney();
  const analytics = useAnalytics();
  const { pushToDepositsPage } = usePaymentsDepositsNavigation();
  const { currency } = useCurrency();

  const isSingleMode = computed(() => props.mode === BalanceMode.Single);

  const buttonProperties = computed<VButtonProps>(() => ({
    label: isSingleMode.value ? $translate('WEB2_REPLENISH_SHORT').value : '',
    kind: ButtonKind.PRIMARY,
    iconName: isSingleMode.value ? IconName.ADD : IconName.PLUS_SMALL,
    iconSize: isSingleMode.value ? IconSize.SIZE_16 : IconSize.SIZE_24,
    rounded: !isSingleMode.value,
  }));

  const formattedBalance = computed(() => formatMoney(
    useCustomerFinanceStore().balance,
    { hideCurrency: !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED },
  ));

  const balanceParams = computed(() => ({ currency: currency.value }));

  const balanceLabel = computed(() => {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return $translate('WEB2_PROFILE_BALANCE', balanceParams).value;
    }
    return `${$translate('JSPACC_ACC_BALANCE').value} ${formattedBalance.value}`;
  });

  function handleClick(): void {
    analytics.clickMap({ deposit: 'userProfileDeposit' });
    pushToDepositsPage();
    emit('open-deposit');
  }

  return {
    buttonProperties,
    formattedBalance,
    balanceLabel,
    handleClick,
  };
}
