import type { FormatMoneyFunc } from '@core/money';

export default function getCurrentPointsToString(
  currentPoints: number | undefined,
  formatMoney: FormatMoneyFunc,
): string {
  return formatMoney(currentPoints || 0, {
    hideCurrency: true,
    currency: 'L',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
