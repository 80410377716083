import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  ref,
} from 'vue';

import { useWindowEvent } from '@leon-hub/browser-composables';
import { IconName } from '@leon-hub/icons';

import { PinButtonEvent } from 'web/src/modules/pin-code/enums';

import type { PinButtonProps } from '../../PinButton/types';
import type { PinButtonsEmits, PinButtonsProps } from '../types';
import { availableKeys, pinButtonsProps } from '../../../utils/utils';

export interface PinButtonsComposable {
  activeButton: Ref<string>;
  pinButtonsList: ComputedRef<PinButtonProps[]>;
  onKeyUp(e: KeyboardEvent): void;
  onKeyDown(e: KeyboardEvent): void;
}

export default function usePinButtons(
  props: PinButtonsProps,
  emits: PinButtonsEmits,
): PinButtonsComposable {
  const activeButton = ref('');

  const pinButtonsList = computed(() => {
    if (props.pinButtonsListInitial?.length) {
      return props.pinButtonsListInitial;
    }

    if (!props.visible) {
      return pinButtonsProps;
    }

    return pinButtonsProps.map((button: PinButtonProps): PinButtonProps => {
      if (button.value === PinButtonEvent.VISIBILITY) {
        return {
          ...button,
          iconName: IconName.VISIBILITY_ON,
          isActive: !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED,
        };
      }

      return button;
    });
  });

  function onKeyUp(e: KeyboardEvent): void {
    const { key } = e;

    if (availableKeys.includes(key)) {
      activeButton.value = '';
    }
  }

  function onKeyDown(e: KeyboardEvent): void {
    const { key } = e;

    if (availableKeys.includes(key)) {
      activeButton.value = key.toUpperCase();
      emits('click', key.toUpperCase());
    }
  }

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    useWindowEvent('keyup', onKeyUp);
    useWindowEvent('keydown', onKeyDown);
  }

  return {
    activeButton,
    pinButtonsList,
    onKeyDown,
    onKeyUp,
  };
}
