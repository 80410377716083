<script setup lang="ts">
import { IconSize } from '@leon-hub/icons';

import { ButtonHeight, ButtonKind, LButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type { CashoutButtonEmits, CashoutButtonProps } from '../types';
import { useCashoutButton } from '../composables/useCashoutButton';

const props = defineProps<CashoutButtonProps>();

defineEmits<CashoutButtonEmits>();

const {
  formattedPriceCashout,
  iconArrowCashout,
  isShowIconArrow,
  isHover,
  onPointerIn,
  onPointerOut,
} = useCashoutButton(props);
</script>

<template>
  <LButton v-auto-id="'CashoutButton'"
    :class="{
      [$style['cashout-button']]: true,
      [$style['cashout-button--hover']]: isHover,
    }"
    :kind="isHover ? ButtonKind.PRIMARY : ButtonKind.PRIMARY_OPACITY"
    :height="ButtonHeight.LARGE"
    :is-uppercase="false"
    full-width
    @click.prevent.stop="$emit('click')"
    @mouseenter="onPointerIn"
    @mouseleave="onPointerOut"
    @touchstart="onPointerIn"
    @touchend="onPointerOut"
    @touchcancel="onPointerOut"
  >
    <span :class="$style['cashout-button__label']">{{ $t('WEB2_CASHOUT_DETAILS_BUTTON_LABEL') }}</span>
    <span :class="$style['cashout-button__suffix']">
      <span
        v-if="isShowIconArrow"
        :class="$style['cashout-button__icon']"
      >
        <VIcon
          :size="IconSize.SIZE_16"
          :name="iconArrowCashout"
        />
      </span>
      <span :class="$style['cashout-button__amount']">
        {{ formattedPriceCashout }}
      </span>
    </span>
  </LButton>
</template>

<style lang="scss" module>
.cashout-button {
  flex: 1;
  justify-content: space-between;
  overflow: hidden;

  &__label {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__suffix {
    display: flex;
    flex: none;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    background: var(--OpacityBrandDefault);
    border-radius: 4px;
  }

  &--hover &__icon {
    background: var(--OpacityLayer2);
  }

  &__amount {
    color: var(--TextDefault);
  }
}
</style>
