import type { JSONSchema } from '@leon-hub/form-utils';

export enum FormSchemaTypes {
  String = 'string',
  Number = 'number',
  Integer = 'integer',
  Object = 'object',
  Array = 'array',
  Boolean = 'boolean',
  Null = 'null',
}

export function isFormTypeString(property: JSONSchema): boolean {
  return `${property?.type}`
    .toLowerCase() === FormSchemaTypes.String;
}

export function isFormTypeNumber(property: JSONSchema): boolean {
  return `${property?.type}`
    .toLowerCase() === FormSchemaTypes.Number;
}

export function isFormTypeBoolean(property: JSONSchema): boolean {
  return `${property?.type}`
    .toLowerCase() === FormSchemaTypes.Boolean;
}

export function isFormTypeArray(property: JSONSchema): boolean {
  return `${property?.type}`
    .toLowerCase() === FormSchemaTypes.Array;
}

export function isFormTypeObject(property: JSONSchema): boolean {
  return `${property?.type}`
    .toLowerCase() === FormSchemaTypes.Object;
}

export function isFormPrimitiveType(property: JSONSchema): boolean {
  const propertyType = `${property.type}`.toLowerCase();
  switch (propertyType) {
    case FormSchemaTypes.Array:
    case FormSchemaTypes.Object:
      return false;
    default:
      return true;
  }
}
