<script setup lang="ts">
import type {
  WelcomeBonusesEmits,
  WelcomeBonusesProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonuses/types';
import WelcomeBonusCard from 'web/src/modules/payments/form-widgets/WelcomeBonusCard/WelcomeBonusCard.vue';

withDefaults(defineProps<WelcomeBonusesProps>(), {});

const emit = defineEmits<WelcomeBonusesEmits>();

function handleBonusClick(value: Maybe<number>) {
  emit('click', value);
}

function handleDetailsClick(value: { categoryId?: Maybe<string>; actionUrl?: Maybe<string> }) {
  emit('details', value);
}
</script>

<template>
  <div v-auto-id="'WelcomeBonuses'" :class="$style['welcome-bonuses']">
    <WelcomeBonusCard
      v-for="bonus in bonusList"
      :key="bonus.campaignId"
      :campaign-name="bonus.campaignName"
      :campaign-id="bonus.campaignId"
      :category-id="bonus.categoryId"
      :action-url="bonus.actionUrl"
      :is-selected="selectedOptionId === bonus.campaignId"
      :minimum-deposit-value="bonus.minDepositAmount"
      :bonus-type="bonus.campaignType"
      :image="bonus.image"
      @bonus-click="handleBonusClick"
      @details-click="handleDetailsClick"
    />
  </div>
</template>

<style lang="scss" module>
.welcome-bonuses {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
</style>
