<script lang="ts"  setup>
import { VIcon } from '@components/v-icon';

import type { ReferralProgramPopupRewardProps } from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/types';
import {
  useReferralProgramPopupReward,
} from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/composables';

withDefaults(defineProps<ReferralProgramPopupRewardProps>(), {});

const {
  iconProps,
} = useReferralProgramPopupReward();
</script>

<template>
  <div v-auto-id="'ReferralProgramPopupReward'" :class="$style['referral-program-popup-reward']">
    <p
      v-if="showTitle"
      :class="$style['referral-program-popup-reward__title']"
    >
      {{ $t('WEB2_REFFERAL_PROGRAM_POPUP_REWARD') }}
    </p>
    <div
      v-if="referralReward"
      :class="$style['referral-program-popup-reward__list']"
    >
      <div :class="$style['referral-program-popup-reward__list-header']">
        <span
          :class="{
            [$style['referral-program-popup-reward__list-cell']]: true,
            [$style['referral-program-popup-reward__list-cell--left']]: true,
          }"
        >
          {{ $t('WEB2_REFFERAL_PROGRAM_TABLE_BONUS') }}
        </span>
        <span
          :class="{
            [$style['referral-program-popup-reward__list-cell']]: true,
            [$style['referral-program-popup-reward__list-cell--right']]: true,
          }"
        >
          {{ $t('WEB2_REFFERAL_PROGRAM_TABLE_DEPOSIT') }}
        </span>
      </div>
      <div
        v-for="(item, index) in table"
        :key="index"
        :class="$style['referral-program-popup-reward__list-item']"
      >
        <span
          :class="{
            [$style['referral-program-popup-reward__list-cell']]: true,
            [$style['referral-program-popup-reward__list-cell--left']]: true,
          }"
        >
          {{ item.deposit }}
        </span>
        <span
          :class="{
            [$style['referral-program-popup-reward__list-cell']]: true,
            [$style['referral-program-popup-reward__list-cell--right']]: true,
          }"
        >
          {{ item.reward }}
        </span>
      </div>
    </div>
    <div
      v-else
      :class="$style['referral-program-popup-reward__block']"
    >
      <VIcon
        v-bind="iconProps"
        :class="$style['referral-program-popup-reward__block-icon']"
      />
      <span :class="$style['referral-program-popup-reward__block-label']">{{ rewardLabel }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .referral-program-popup-reward {
    @include refPopup;

    &__title {
      @include medium\13\16;

      display: flex;
      gap: 2px;
      align-items: center;
      height: 44px;
      padding-left: if($isDesktop, 0, 0 0 0 8px);
      margin: 0;
      color: var(--AlertButtonText);
    }

    &__list {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &-header {
        display: flex;
        gap: 12px;
        width: 100%;
      }

      &-cell {
        display: block;
        width: calc(50% - 6px);

        &--left {
          font-weight: 400;
        }
      }

      &-item {
        display: flex;
        gap: 12px;
        width: 100%;

        &:nth-child(odd) {
          background-color: var(--OpacityLayer1);
        }
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
      width: 100%;
      padding: 12px;
      background-color: var(--OpacityLayer1);
      border-radius: 5px;

      &-icon {
        color: var(--BrandDefault);
      }

      &-label {
        @include medium\14\16\025;

        color: var(--TextDefault);
      }
    }
  }
}
</style>
