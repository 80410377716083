<script lang="ts" setup>
import { defineAsyncComponent, onBeforeMount } from 'vue';

import { VEmpty } from 'web/src/components/Empty';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import PaymentsModalsController from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import BalanceSwiperNavigation
  from 'web/src/modules/profile/components/BalanceSwiperNavigation/BalanceSwiperNavigation.vue';
import MainBalance from 'web/src/modules/profile/components/MainBalance/MainBalance.vue';

import type { BalanceUserEmits, BalanceUserProps } from './types';
import { useBalanceUser } from './composables';

const props = withDefaults(defineProps<BalanceUserProps>(), {
  mode: BalanceMode.Single,
  isInProfile: true,
});
const emit = defineEmits<BalanceUserEmits>();

const CBCBalance = '1'
  ? defineAsyncComponent(() => import('web/src/modules/profile/components/CBCBalance/CBCBalance.vue'))
  : defineAsyncComponent(() => Promise.resolve(VEmpty));

const CashbackBalance = '1'
  ? defineAsyncComponent(() => import('web/src/modules/profile/components/CashbackBalance/CashbackBalance.vue'))
  : defineAsyncComponent(() => Promise.resolve(VEmpty));

const {
  isSuccessModal,
  balanceSnapshot,
  isSingleMode,
  isMultipleMode,
  emitClose,
  handleCloseSuccessModal,
  onWithdrawBonus,
  beforeMount,
} = useBalanceUser(props, emit);

onBeforeMount(beforeMount);
</script>

<template>
  <div v-auto-id="'BalanceUser'"
    :class="{
      [$style['balance']]: true,
      [$style['balance--modal']]: !isInProfile,
    }"
  >
    <template v-if="isSingleMode">
      <MainBalance
        :mode="mode"
        @open-deposit="emitClose"
      />
    </template>
    <template v-else-if="isMultipleMode">
      <div :class="$style['balance__multiple-wrapper']">
        <MainBalance
          :mode="mode"
          @open-deposit="emitClose"
        />
        <CBCBalance
          :mode="mode"
          :is-in-profile="isInProfile"
          @withdraw-bonus="onWithdrawBonus"
          @click-card="emitClose"
        />
        <CashbackBalance v-if="'1'" />
      </div>
    </template>
    <template v-else>
      <div :class="$style['balance__swiper']">
        <VSwiper
          ref="swiper"
          :class="$style['balance__swiper-list']"
        >
          <template
            v-if="'1'"
            #pagination-header
          >
            <BalanceSwiperNavigation />
          </template>
          <VSwiperSlide
            :class="$style['balance__swiper-item']"
          >
            <MainBalance
              :mode="BalanceMode.Slider"
              @open-deposit="emitClose"
            />
          </VSwiperSlide>
          <VSwiperSlide
            :class="$style['balance__swiper-item']"
          >
            <CBCBalance
              mode="slider"
              :is-in-profile="isInProfile"
              @withdraw-bonus="onWithdrawBonus"
              @click-card="emitClose"
            />
          </VSwiperSlide>
          <VSwiperSlide
            :class="$style['balance__swiper-item']"
          >
            <CashbackBalance />
          </VSwiperSlide>
        </VSwiper>
      </div>
    </template>
    <PaymentsModalsController
      v-if="isSuccessModal"
      :modal-type="PaymentsModalType.DEPOSITS_OTHER_SUCCESS_MODAL"
      :modal-text="balanceSnapshot"
      :is-in-profile="isInProfile"
      @close="handleCloseSuccessModal"
    />
  </div>
</template>

<style lang="scss" module>
.balance {
  &__multiple-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: $balanceColumnGap;
  }

  &__swiper-item {
    width: 159.5px;
    margin-right: 8px;
  }
}
</style>
