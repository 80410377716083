import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import { ApiError } from '@leon-hub/api';
import {
  checkPushTokenExists,
  doPostponeWebPushToken,
  doRegisterPushToken,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';
import { PushNotificationPermission } from '@leon-hub/app-types';
import { assert } from '@leon-hub/guards';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { Timer } from '@leon-hub/utils';

import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';
import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';

import {
  POSTPONE_PUSH_STORAGE_KEY,
  PUSH_TOKEN_STORAGE_KEY,
} from '../constants';
import { useFirebaseMessaging } from '../utils';
import usePushNotificationsStore from './usePushNotificationsStore';

const useWebPushNotificationsStore = defineStore('web-push-notifications', () => {
  const siteConfigStore = useSiteConfigStore();
  const pushNotificationsBlock = toRef(siteConfigStore, 'pushNotificationsBlock');
  const pushNotificationsWebBlock = toRef(siteConfigStore, 'pushNotificationsWebBlock');
  const { isLoggedIn } = useIsLoggedIn();
  const routerStore = useRouterStore();
  const isCurrentRouteHasTopBar = toRef(routerStore, 'isCurrentRouteHasTopBar');
  const firebaseMessaging = useFirebaseMessaging();
  const { getDeviceFingerprint } = useIdentityStore();
  const api = useGraphqlClient();

  const localStorage = useLocalStorageManager();

  const pushNotificationsStore = usePushNotificationsStore();
  const postponeHours = computed(() => siteConfigStore.pushNotificationsWebBlock?.postponeHours || 0);
  const isSupported = toRef(pushNotificationsStore, 'isSupported');
  const isAllowed = toRef(pushNotificationsStore, 'isAllowed');

  function isModalPostponed(): boolean {
    const date = localStorage.getItem(POSTPONE_PUSH_STORAGE_KEY);
    return !!date && Number(date) + postponeHours.value * 60 * 60 * 1000 > Date.now();
  }

  const isModalVisibilityVisible = ref(false);
  const isPostponed = ref(false);
  const isConfigSynchronized = ref(false);

  const isModalVisible = computed(() => (
    isModalVisibilityVisible.value && !isCurrentRouteHasTopBar.value && !isPostponed.value
    && isConfigSynchronized.value && isSupported.value && !isAllowed.value
  ));

  watch(postponeHours, () => {
    isPostponed.value = isModalPostponed();
  }, {
    immediate: true,
  });

  const isWebPushNotificationsDisabled = computed(() => (
    !pushNotificationsBlock.value?.enabled || (
      !pushNotificationsBlock.value?.isAnonymousRegistrationEnabled && !isLoggedIn.value
    )
  ));

  let timeout = 0;

  function clearTimer(): void {
    if (timeout) {
      Timer.clearTimeout(timeout);
      timeout = 0;
    }
  }

  function hideModal(): void {
    isModalVisibilityVisible.value = false;
  }

  async function updateToken(forceUpdate = false): Promise<void> {
    try {
      if (!await firebaseMessaging.isSupported()) {
        return;
      }

      const token = await firebaseMessaging.getToken();
      pushNotificationsStore.setToken(token);
      pushNotificationsStore.setIsAllowed(true);
      if (token && (forceUpdate || token !== localStorage.getItem(PUSH_TOKEN_STORAGE_KEY))) {
        const deviceId = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
        pushNotificationsStore.setDeviceUUID(deviceId);

        if (!pushNotificationsBlock.value?.stopRequests) {
          await doRegisterPushToken(api, (node) => node.mutations.customer.notifications.registerV2, {
            options: {
              token,
              deviceId,
              deviceType: 'web',
            },
          });
        }

        localStorage.setItem(PUSH_TOKEN_STORAGE_KEY, token);
      }
    } catch (error) {
      pushNotificationsStore.setIsAllowed(false);
      pushNotificationsStore.setIsSupported(false);

      console.warn('[PushNotifications]: Unable to get push notifications token', error);
    }
  }

  async function subscribe(forceUpdate = false): Promise<PushNotificationPermission> {
    hideModal();
    try {
      await updateToken(forceUpdate);
    } catch (error) {
      console.warn('[PushNotifications]: Unable to init push notification client', error);
    }

    return PushNotificationPermission.GRANTED;
  }

  async function isPushTokenRegistered(): Promise<boolean> {
    try {
      if (process.env.VUE_APP_PRODUCT_LEONRU) {
        return !!localStorage.getItem(PUSH_TOKEN_STORAGE_KEY) && window.Notification?.permission === 'granted';
      }

      if (pushNotificationsBlock.value?.stopRequests) {
        return true;
      }

      const deviceId = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
      await checkPushTokenExists(api, (node) => node.queries.customer.notifications.check, {
        options: {
          deviceId,
          deviceType: 'web',
        },
      }, {
        priority: RequestOptionsPriority.LOW,
      });
    } catch (error) {
      assert(error instanceof ApiError);
      if (String(error.code) === 'UNKNOWN_DEVICE') {
        return false;
      }
    }

    return true;
  }

  async function syncConfig(): Promise<void> {
    if (!isConfigSynchronized.value) {
      isConfigSynchronized.value = true;

      await firebaseMessaging.init({
        apiKey: siteConfigStore.firebaseBlock?.webApiKey || '',
        projectId: siteConfigStore.firebaseBlock?.webProjectId || '',
        senderId: siteConfigStore.firebaseBlock?.webMessagingSenderId || '',
        appId: siteConfigStore.firebaseBlock?.webAppId || '',
        measurementId: siteConfigStore.firebaseBlock?.webMeasurementId || '',
        vapidKey: siteConfigStore.firebaseBlock?.webVapidKey || '',
      });

      const isFirebaseSupported = await firebaseMessaging.isSupported();
      pushNotificationsStore.setIsSupported(isFirebaseSupported);
      if (isFirebaseSupported) {
        const isTokenRegistered = await isPushTokenRegistered();
        pushNotificationsStore.setIsAllowed(isTokenRegistered);
      }
    }
  }

  function showModal(forceUpdate = false): Promise<void> {
    return new Promise<void>((resolve) => {
      clearTimer();

      if (isWebPushNotificationsDisabled.value) {
        resolve();
        return;
      }

      timeout = Timer.setTimeout(async () => {
        await syncConfig();
        clearTimer();
        if (window.Notification?.permission !== 'granted') {
          if (pushNotificationsWebBlock.value?.modalEnabled) {
            isModalVisibilityVisible.value = true;
          } else {
            await subscribe(forceUpdate);
          }
        } else {
          await subscribe(forceUpdate);
        }

        resolve();
      }, (pushNotificationsWebBlock.value?.openTimeout || 30) * 1000);
    });
  }

  async function postpone(): Promise<void> {
    hideModal();
    localStorage.setItem(POSTPONE_PUSH_STORAGE_KEY, Date.now().toString());
    if (isLoggedIn.value) {
      await doPostponeWebPushToken(api, (node) => node.mutations.webPushNotifications.postpone, { options: {} });
    }
  }

  watch(isLoggedIn, async () => {
    void syncConfig();
    localStorage.removeItem(PUSH_TOKEN_STORAGE_KEY);
    hideModal();
    await siteConfigStore.updateSiteConfigSettings({ silent: true });
    void showModal(true);
  });

  return {
    isModalVisible,
    syncConfig,
    showModal,
    subscribe,
    postpone,
  };
});

export default useWebPushNotificationsStore;
