import type { ApplicationRestClient } from '@core/app-rest-client';

import type { CreditCardTokenizationData, CreditCardTokenizationRequest, CreditCardTokenizationService } from './types';
import { isCreditCardTokenizationData } from './guards';

export default function createCreditCardTokenizationApi(client: ApplicationRestClient): CreditCardTokenizationService {
  return {
    async tokenize({ pan }: CreditCardTokenizationRequest) {
      return client.request<CreditCardTokenizationData>({
        id: 'cards/tokenize',
        endpoint: '/v1/cards/tokenize',
        data: { pan },
        silent: false,
        guard: isCreditCardTokenizationData,
      });
    },
  };
}
