import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useSiteConfigStore } from '@core/site-config';

interface UseSlipShareDialogRoute {
  isShareRouteEnabled: Ref<boolean>;
}

export function useSlipShareDialogRoute(): UseSlipShareDialogRoute {
  const siteConfigStore = useSiteConfigStore();

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const isShareRouteEnabled = computed<boolean>(() => !!(slipBlock.value?.sharedBetSlipBookingCodeEnabled

    || slipBlock.value?.sharedBetSlipLinkEnabled));

  return {
    isShareRouteEnabled,
  };
}
