import type { ActiveButton, VButtonPropsWithId } from '../types';

function buttonDisabledLoadingState (active: Maybe<ActiveButton>, current: ActiveButton): Partial<VButtonPropsWithId> {
  const data = {
    disabled: false,
    isLoading: false,
  };

  if (!active) {
    return data;
  }

  if (active.id === current.id && active.type === current.type) {
    data.isLoading = true;
  } else {
    data.disabled = true;
  }

  return data;
}

export default buttonDisabledLoadingState;
