<script lang="ts" setup>
import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import type { LogoName } from 'web/src/components/Logo/VLogo/enums';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from 'web/src/components/Form/composables';
import CardNumberInput from 'web/src/modules/payments/form-widgets/FormCardNumber/components/CardNumberInput/CardNumberInput.vue';

const props = defineProps<FormWrapperWidgetProperties<{ selectedCardTypeLogo?: LogoName }>>();
const emit = defineEmits<BaseFormWidgetEmits>();
const { componentProps } = useCommonFormWidgetProps<{ selectedCardTypeLogo?: LogoName }>(props);

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);
</script>

<template>
  <CardNumberInput v-auto-id="'FormCardNumber'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
