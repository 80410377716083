import type { BonusWithdrawResponse } from '@leon-hub/api-sdk';
import { doWithdrawSportBonus } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export default function doWithdrawSportBonusApi(campaignId: number): Promise<BonusWithdrawResponse> {
  const apiClient = useGraphqlClient();

  return doWithdrawSportBonus(
    apiClient,
    (node) => node.mutations.bonuses.withdrawSportBonus,
    { options: { campaignId } },
  );
}
