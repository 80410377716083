<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type {
  LobbyEgsGamesItemEmits,
  LobbyEgsGamesItemProps,
} from 'web/src/modules/lobby/components/LobbyEgsGamesItem/types';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
import LobbyEgsGamesItemImage from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/LobbyEgsGamesItemImage.vue';

const props = defineProps<LobbyEgsGamesItemProps>();
const emit = defineEmits<LobbyEgsGamesItemEmits>();

const router = useRouter();
const { getEgsGameHref } = useEgsNavigation(router);

const href = computed(() => (
  props.item ? getEgsGameHref(props.item) : undefined
));

function onClick(event: MouseEvent): void {
  if (props.item) {
    emit('click', event);
  }
}
</script>

<template>
  <component v-auto-id="'LobbyEgsGamesItem'"
    :is="href ? 'a' : 'div'"
    :class="$style['lobby-egs-games-item']"
    :href="href"
    @click.prevent="onClick"
  >
    <LobbyEgsGamesItemImage
      :item="item"
      :has-image-force-load="hasImageForceLoad"
      :is-removed="isRemoved"
      :is-edit-mode="isEditMode"
      :jackpot-animation-duration="jackpotAnimationDuration"
      :is-jackpot="isJackpot"
      :is-square-image="isSquareImage"
      :is-panel-visible="isPanelVisible"
      :is-description-hidden="isDescriptionHidden"
      @click-play.stop.prevent="emit('click-play', $event)"
      @click-demo.stop.prevent="emit('click-demo', $event)"
      @click.stop.prevent="emit('click', $event)"
    />
    <div
      v-if="!null && isNameVisible"
      :class="$style['lobby-egs-games-item__name-wrapper']"
    >
      <div
        v-if="item"
        :class="$style['lobby-egs-games-item__name']"
      >
        {{ item.name }}
      </div>
      <div
        v-else
        :class="$style['lobby-egs-games-item__name--skeleton']"
      />
    </div>
  </component>
</template>

<style lang="scss" module>
@import 'web/src/components/Skeleton/styles/skeleton';

.lobby-egs-games-item {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  text-decoration: none;

  &__name-wrapper {
    position: relative;
    height: 12px;

    @include screen-desktop-small-min {
      height: 16px;
    }
  }

  &__name {
    @include medium\12\12;

    position: absolute;
    width: 100%;
    overflow: hidden;
    color: var(--TextDefault);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include screen-desktop-small-min {
      @include medium\14\16\025;
    }

    &--skeleton {
      @include skeletonFont(64px, 12px, 7px, var(--TextDefault));

      margin: auto;

      @include screen-desktop-small-min {
        @include skeletonFont(64px, 16px, 8px, var(--TextDefault));
      }
    }
  }

  &:hover {
    #{$self}__name {
      color: var(--BrandDefault);
    }
  }
}
</style>
