import type { VerificationApi } from './createVerificationApi';
import createVerificationApi from './createVerificationApi';

let verificationApi: VerificationApi | undefined;

export default function useVerificationApi(): VerificationApi {
  if (!verificationApi) {
    verificationApi = createVerificationApi();
  }

  return verificationApi;
}
