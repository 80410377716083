import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { setBannerClosed } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';
import { useI18nLocale } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

export const useAppDownloadStore = defineStore('app-download', () => {
  const apiClient = useGraphqlClient();
  const { locale } = useI18nLocale();
  const siteConfigStore = useSiteConfigStore();
  const mobileAppCordovaBlock = toRef(siteConfigStore, 'mobileAppCordovaBlock');
  const appDownloadAdBlock = toRef(siteConfigStore, 'appDownloadAdBlock');

  const isBannerClosed = ref(false);

  const isDownloadAppBannerVisible = computed(() => (
    !!mobileAppCordovaBlock.value?.mobileAppDownloadTopBannerEnabled
    && !isBannerClosed.value
    && !!appDownloadAdBlock.value?.isVisible
  ));

  const mobileAppDownloadEnabled = computed(() => !!mobileAppCordovaBlock.value?.mobileAppDownloadEnabled);
  const isIosAppEnabled = computed(() => (
    mobileAppDownloadEnabled.value && !!mobileAppCordovaBlock.value?.mobileAppIosDownloadEnabled
  ));
  const isAndroidAppEnabled = computed(() => (
    mobileAppDownloadEnabled.value && !!mobileAppCordovaBlock.value?.mobileAppAndroidDownloadEnabled
  ));

  const iosAppUrl = computed(() => (
    mobileAppCordovaBlock.value?.mobileAppIosDownloadUrl ?? '/appi'
  ));

  const androidAppBaseUrl = computed(() => mobileAppCordovaBlock.value?.mobileAppAndroidDownloadUrl ?? '/app');
  const androidAppUrl = computed(() => (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED
    ? `${androidAppBaseUrl.value}?l=${locale.value}`
    : androidAppBaseUrl.value));

  function isAppDownloadUrl(url: string): boolean {
    const appDownloadUrlRegex = new RegExp(`^${androidAppBaseUrl.value}(\\?l=[a-z]{2}_[A-Z]{2})?$|^${iosAppUrl.value}$`);

    return appDownloadUrlRegex.test(url);
  }

  function closeBanner(): void {
    isBannerClosed.value = true;

    void setBannerClosed(apiClient, (node) => node.mutations.banners.setBannerClosed, {
      options: {
        id: 'download-app-banner',
      },
    });
  }

  return {
    isDownloadAppBannerVisible,
    isIosAppEnabled,
    isAndroidAppEnabled,
    iosAppUrl,
    androidAppUrl,
    isAppDownloadUrl,
    closeBanner,
  };
});

export default useAppDownloadStore;
