import type { JSONSchema } from '@leon-hub/form-utils';
import { FormFieldTouchedStrategy, isJsonSchema } from '@leon-hub/form-utils';
import {
  assert,
  isArray,
  isObject,
  isString,
  isUndefined,
} from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { isFile } from '@leon-hub/utils';

import type {
  FormData,
  FormDataMap,
  FormDataValue,
  FormSchema,
  FormUiSchema,
  FormUiSchemaField,
  FormUiSchemaFieldDefaultValue,
  PartialFormPhoneValue,
} from 'web/src/components/Form/types';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
import { mergeArray, mergeObject, mergeString } from 'web/src/components/Form/utils/merge';
import mapToObject from 'web/src/utils/map/mapToObject';

import type { TouchedStrategyMap } from '../types';
import { isFormTypeArray, isFormTypeObject } from './formSchemaUtils';

export function mergeFormDataMap (dataMap: FormDataMap, dataPath: string, value: string): FormDataMap {
  const pathFragments = dataPath.split('/');
  const pathFragmentsLength = pathFragments.length;
  if (pathFragmentsLength > 2) {
    logger.error('Form: error in margeFormDataMap, unexpected path length', { dataPath });
  }
  if (pathFragments.length === 1) {
    return mergeString<string>(pathFragments, dataMap as Map<string, string>, value);
  }
  if (Number.isNaN(parseInt(pathFragments[pathFragmentsLength - 1], 10))) {
    return mergeObject<Record<string, string>, string>(
      pathFragments,
      dataMap as Map<string, Record<string, string>>,
      value,
    );
  }
  return mergeArray<string>(pathFragments, dataMap as Map<string, string[]>, value);
}

/** provides default values for object/array type */
export function getDefaultEmptyObject(schema: JSONSchema): FormDataMap {
  const { properties = {} } = schema;
  return Object.keys(properties).reduce<FormDataMap>((result, key) => {
    const schemaField = properties[key];

    if (!isJsonSchema(schemaField)) {
      return result;
    }

    if (isFormTypeObject(schemaField) && 'properties' in schemaField) {
      result.set(key, {});
    } else if (isFormTypeArray(schemaField)) {
      result.set(key, []);
    }
    return result;
  }, new Map());
}

export function getUiSchemaFieldDefaultValue (uiSchema?: FormUiSchemaField): FormUiSchemaFieldDefaultValue {
  if (uiSchema && 'default' in uiSchema) {
    return uiSchema.default;
  }
  return undefined;
}

export function getDefaultEnumValue (property: JSONSchema): string | null {
  const schemaEnum = property.enum;
  if (isArray(schemaEnum) && schemaEnum.length) {
    const value = schemaEnum[0];
    assert(isString(value) || value === null, 'Invalid schema enum value');
    return value;
  }
  return null;
}

export function isEmptyDefaultValue ({ value, name, uiSchema }:
{ value: FormDataValue | undefined; name: string; uiSchema: FormUiSchema }): boolean {
  if (isUndefined(value)) {
    return false;
  }
  const field = uiSchema?.fields?.[name];

  if (!field) {
    return false;
  }

  const defaultValue = getUiSchemaFieldDefaultValue(field);

  return value === '' && defaultValue === '';
}

// for getInitialFormDataMap
export function addObjectValueToDataMap (target: FormDataMap, fieldName: string, objectValue: Object): FormDataMap {
  assert(isPartialFormPhoneValue(objectValue)); // only phone is currently existing object value
  const keys = Object.keys(objectValue);
  for (const key of keys) {
    const value = objectValue[key as keyof PartialFormPhoneValue];
    if (value) {
      // not empty string
      assert(isString(value));
      target.set(`${fieldName}/${key}`, value);
    }
  }
  return target;
}

function getDefaultPrimitiveValue (schemaProperty: JSONSchema, uiSchemaProperty?: FormUiSchemaField, isRequired = false): FormUiSchemaFieldDefaultValue {
  const defaultFromUiSchema = getUiSchemaFieldDefaultValue(uiSchemaProperty);
  if (defaultFromUiSchema) {
    return defaultFromUiSchema;
  }
  /**
   * there is no check for widget type, coz it can be select-like widgets. Another fields do not have enum list values, so its safe enough
   * */
  // LEONWEB-6703
  const isUnlabeledSelect = uiSchemaProperty && !uiSchemaProperty?.title;
  // LEONWEB-13642
  const requiredSingleValueSelect = isRequired && schemaProperty.enum && schemaProperty.enum?.length === 1;
  if (isUnlabeledSelect || requiredSingleValueSelect) {
    return getDefaultEnumValue(schemaProperty);
  }
  return undefined;
}

function getDefaultEnumForObject (properties: JSONSchema): Dictionary<string> {
  const keys = Object.keys(properties);
  const result: Dictionary<string> = {};
  for (const key of keys) {
    assert(isObject(properties));
    const childSchema = properties[key];
    if (childSchema) {
      const relatedEnum = getDefaultEnumValue(childSchema);
      if (relatedEnum) {
        result[key] = relatedEnum;
      }
    }
  }
  return result;
}

function getDefaultValue (schemaProperty: JSONSchema, uiSchemaProperty?: FormUiSchemaField, isRequired = false): FormUiSchemaFieldDefaultValue {
  if (isFormTypeObject(schemaProperty)) {
    const defaultUiValue = getUiSchemaFieldDefaultValue(uiSchemaProperty);
    const defaultEnumValue = isObject(schemaProperty.properties)
      ? getDefaultEnumForObject(schemaProperty.properties) : {};
    const combinedValue = {
      ...defaultEnumValue,
      ...(isObject(defaultUiValue) ? defaultUiValue : {}),
    };
    if (Object.keys(combinedValue).length) {
      return combinedValue;
    }
    return isObject(schemaProperty.properties) ? {} : undefined;
  }
  return getDefaultPrimitiveValue(schemaProperty, uiSchemaProperty, isRequired);
}

// for getInitialFormDataMap
export function addArrayValueToDataMap (target: FormDataMap, fieldName: string, arrayValue: string[] | File[]):
FormDataMap {
  arrayValue.forEach((value, index) => {
    target.set(`${fieldName}/${index}`, value);
  });
  return target;
}

export function getInitialFormDataMap (schema: FormSchema, uiSchema: FormUiSchema): FormDataMap {
  const { properties } = schema;
  assert(isObject(properties));
  const { fields } = uiSchema;
  if (!fields) {
    return new Map();
  }
  const propertyKeys = new Set(Object.keys(properties));
  const requiredFields: string[] = isArray(schema.required) ? schema.required : [];

  let result: FormDataMap = new Map();

  for (const key of propertyKeys) {
    const currentProperty = properties[key] || {};
    const currentField = fields[key];
    const isRequired = requiredFields.includes(key);
    if (currentField) {
      const defaultValue = getDefaultValue(currentProperty, currentField, isRequired);
      if (isArray(defaultValue)) {
        assert(defaultValue.every(isString || isFile));
        result = addArrayValueToDataMap(result, key, defaultValue);
      } else if (isObject(defaultValue)) {
        result = addObjectValueToDataMap(result, key, defaultValue);
      } else if (defaultValue) {
        result.set(key, defaultValue);
      }
    }
  }
  return result;
}

// also knows as getFormData in original
export function getFormDataObject(
  formDataMap: FormDataMap,
  schema: FormSchema,
): FormData {
  const keys = Object.keys(Object.fromEntries(formDataMap));
  const newFormData = keys.reduce<FormDataMap>((result, key) => {
    result = mergeFormDataMap(result, key, formDataMap.get(key) as string);
    return result;
  }, new Map());
  const mergedMap: FormDataMap = new Map([
    ...Array.from(getDefaultEmptyObject(schema)),
    ...Array.from(newFormData),
  ]);
  return mapToObject(mergedMap);
}

export function getDefaultTouchedStrategy(): FormFieldTouchedStrategy {
  return FormFieldTouchedStrategy.Blur;
}

export function getAllFieldNames (schema: FormSchema): Set<string> {
  const rootProperties = schema.properties || {};
  const result = new Set<string>();
  const propertiesList = Object.keys(rootProperties);
  for (const propertyName of propertiesList) {
    const relatedProperties = rootProperties[propertyName];
    const childProperties = relatedProperties.properties;
    if (isFormTypeObject(relatedProperties) && childProperties) {
      const childKeys = Object.keys(childProperties);
      for (const childKey of childKeys) {
        result.add(`${propertyName}/${childKey}`);
      }
    } else {
      result.add(propertyName);
    }
  }
  return result;
}

export function getTouchedStrategyMap (uiSchema: FormUiSchema): TouchedStrategyMap {
  const { fields = {} } = uiSchema;
  const result: TouchedStrategyMap = new Map();
  const keys = Object.keys(fields);
  for (const key of keys) {
    result.set(key, fields[key].touchedStrategy ?? getDefaultTouchedStrategy());
  }
  return result;
}

export function getTouchedStrategy (dataPath: string, touchedStrategyMap: TouchedStrategyMap): FormFieldTouchedStrategy {
  const rootField = dataPath.split('/')[0];
  return touchedStrategyMap.get(rootField) ?? getDefaultTouchedStrategy();
}

// copy from CustomFormInternal

export function updateFormDataDefaultValues(payload: {
  fieldNames: Set<string>;
  initialFormData: FormDataMap;
  updatedDefaults: FormDataMap;
  currentFormData: FormDataMap;
  objectTypeFields: string[];
}): FormDataMap {
  const {
    fieldNames,
    updatedDefaults,
    currentFormData,
    initialFormData,
    objectTypeFields,
  } = payload;
  const updatedData: FormDataMap = new Map();

  const setUpdatedData = (field: string): void => {
    const currentValue = currentFormData.get(field);
    const initialValue = initialFormData.get(field);
    const updatedDefaultValue = updatedDefaults.get(field);
    // can't relay on touched here
    const wasEdited = currentFormData.has(field) && currentValue !== initialValue;
    const valueToSet = wasEdited ? currentValue : updatedDefaultValue;
    if (isUndefined(valueToSet)) {
      updatedData.delete(field);
    } else {
      updatedData.set(field, valueToSet);
    }
  };

  for (const field of fieldNames) {
    if (currentFormData.has(field) || updatedDefaults.has(field)) {
      setUpdatedData(field);
    }
  }

  // File fields
  for (const field of currentFormData.keys()) {
    if (field.includes('/') && objectTypeFields.includes(field)) {
      const fieldName = field.split('/')[0];
      if (fieldNames.has(fieldName)) {
        setUpdatedData(field);
      }
    }
  }

  return updatedData;
}
