import { RequestOptionsPriority } from '@leon-hub/api-sdk';

import useLiveChatApiClient from 'web/src/modules/live-chat/services/api/useLiveChatApiClient';

import type {
  LiveChatActionRequest,
  LiveChatBaseRequest,
  LiveChatBaseResponse,
  LiveChatCheckData,
  LiveChatGetData,
  LiveChatGetRequest,
  LiveChatMessageRequest,
  LiveChatOpenData,
  LiveChatOpenRequest,
  LiveChatRemoveFileRequest,
  LiveChatService,
  LiveChatStartData,
  LiveChatStartRequest,
  LiveChatSurveyRequest,
  LiveChatUploadData,
  LiveChatUploadRequest,
} from './types';
import {
  isLiveChatBaseResponse,
  isLiveChatCheckData,
  isLiveChatErrorData,
  isLiveChatGetData,
  isLiveChatOpenData,
  isLiveChatStartData,
} from './guards';

export default function createLiveChatApi(): LiveChatService {
  const client = useLiveChatApiClient();

  return {
    async openLiveChat(data: LiveChatOpenRequest) {
      return client.request<LiveChatOpenData>({
        id: 'live-chat-api/open',
        endpoint: `${data.endpoint}chat/open`,
        data,
        guard: isLiveChatOpenData,
        guardError: isLiveChatErrorData,
        silent: data.silent,
        priority: data.silent ? RequestOptionsPriority.LOW : undefined,
      });
    },

    async startLiveChat(data: LiveChatStartRequest) {
      return client.request<LiveChatStartData>({
        id: 'live-chat-api/start',
        endpoint: `${data.endpoint}chat/start`,
        data,
        guard: isLiveChatStartData,
        guardError: isLiveChatErrorData,
        silent: data.silent,
        priority: data.silent ? RequestOptionsPriority.LOW : undefined,
      });
    },

    async checkLiveChat(data: LiveChatBaseRequest) {
      return client.request<LiveChatCheckData>({
        id: 'live-chat-api/check',
        endpoint: `${data.endpoint}chat/check`,
        data,
        guard: isLiveChatCheckData,
        guardError: isLiveChatErrorData,
        silent: data.silent,
        priority: data.silent ? RequestOptionsPriority.LOW : undefined,
      });
    },

    async getLiveChat(data: LiveChatGetRequest) {
      return client.request<LiveChatGetData>({
        id: 'live-chat-api/get',
        endpoint: `${data.endpoint}chat/get`,
        data,
        guard: isLiveChatGetData,
        guardError: isLiveChatErrorData,
        silent: data.silent,
        priority: data.silent ? RequestOptionsPriority.LOW : undefined,
      });
    },

    async sendLiveChatMessage(data: LiveChatMessageRequest) {
      return client.request<LiveChatGetData>({
        id: 'live-chat-api/send-message',
        endpoint: `${data.endpoint}chat/message`,
        data,
        guard: isLiveChatGetData,
        guardError: isLiveChatErrorData,
      });
    },

    async sendLiveChatAction(data: LiveChatActionRequest) {
      return client.request<LiveChatGetData>({
        id: 'live-chat-api/send-action',
        endpoint: `${data.endpoint}chat/bot/payload`,
        data,
        guard: isLiveChatGetData,
        guardError: isLiveChatErrorData,
      });
    },

    async closeLiveChat(data: LiveChatBaseRequest) {
      return client.request<LiveChatBaseResponse>({
        id: 'live-chat-api/close',
        endpoint: `${data.endpoint}chat/close`,
        data,
        guard: isLiveChatBaseResponse,
        guardError: isLiveChatErrorData,
      });
    },

    async uploadLiveChat(data: LiveChatUploadRequest) {
      const formData = new FormData();
      formData.append('session', data.session);
      formData.append('code', data.code);
      formData.append('login', data.login);

      for (const file of data.files) {
        formData.append('files[]', file, file.name);
      }

      return client.request<LiveChatUploadData>({
        id: 'live-chat-api/upload',
        endpoint: `${data.endpoint}chat/mass-upload`,
        data: formData,
        guard: isLiveChatBaseResponse,
        guardError: isLiveChatErrorData,
      });
    },

    async removeFileLiveChat(data: LiveChatRemoveFileRequest): Promise<LiveChatBaseResponse> {
      return client.request<LiveChatBaseResponse>({
        id: 'live-chat-api/remove-file',
        endpoint: `${data.endpoint}chat/remove-file`,
        data,
        guard: isLiveChatBaseResponse,
        guardError: isLiveChatErrorData,
      });
    },

    async surveyLiveChat(data: LiveChatSurveyRequest) {
      return client.request<LiveChatGetData>({
        id: 'live-chat-api/survey',
        endpoint: `${data.endpoint}chat/survey`,
        data,
        guard: isLiveChatBaseResponse,
        guardError: isLiveChatErrorData,
      });
    },
  };
}
