<script setup lang="ts">
import type { PhoneInputEmits, PhoneInputProps } from 'web/src/components/Input';
import { PhoneInput } from 'web/src/components/Input';

import useInternationalPhoneInput from './useInternationalPhoneInput';

const props = withDefaults(defineProps<PhoneInputProps>(), {
  name: 'phone',
});
const emits = defineEmits<PhoneInputEmits>();

const {
  onInput,
  onChange,
  onBlur,
  onFocus,
  onFilled,
  computedPrefixesData,
  computedCountryCode,
} = useInternationalPhoneInput(props, emits);
</script>

<template>
  <PhoneInput v-auto-id="'InternationalPhoneInput'"
    :autofocus="autofocus"
    :name="name"
    :disabled="disabled"
    :label="label"
    :prefix="prefix"
    :value="value"
    :country-code="computedCountryCode"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :mask="mask"
    :disable-placeholder="disablePlaceholder"
    :emit-filled="emitFilled"
    :max-symbols="maxSymbols"
    :prefixes="computedPrefixesData"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :logo="logo"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
    @filled="onFilled"
  />
</template>
