import type { GetCurrenciesByCountryReturnType } from '@leon-hub/api-sdk';
import { getCurrenciesByCountry } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export default function getCurrenciesFromApi(countryCode: string): Promise<GetCurrenciesByCountryReturnType> {
  const api = useGraphqlClient();
  return getCurrenciesByCountry(
    api,
    (node) => node.queries.registration.getCurrenciesByCountry,
    { options: { countryCode } },
    { silent: false },
  );
}
