import type { SlipInfoFieldsFragment } from '@leon-hub/api-sdk';
import { slipTypeIdSingle } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../../slip-info/types';
import type { ShareSuccessLogPayload } from '../../slip-share/types';
import { getMatchedBetMode } from '../../slip-info/utils';
import { getOddsStringForLog } from '../../slip-share/utils';

interface Payload {
  shareCode: string;
  onlyAvailableSlipEntries: SlipEntry[];
  combiOrFirstSlip: SlipInfoFieldsFragment | null;
}

export function getSuccessShareLogPayload({ shareCode, onlyAvailableSlipEntries, combiOrFirstSlip }: Payload): ShareSuccessLogPayload {
  const betMode = getMatchedBetMode(combiOrFirstSlip?.slipType ?? null);
  const odds = betMode === slipTypeIdSingle
    ? getOddsStringForLog(onlyAvailableSlipEntries.map((item) => item.oddsStr ?? '-'))
    : combiOrFirstSlip?.totalOddsStr ?? '-';
  return {
    shareCode,
    odds,
    betMode: betMode.toLowerCase(),
    eventsCount: onlyAvailableSlipEntries.length,
  };
}
