import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ModalWidth } from '@components/dialogs';

export interface UseIdentificationNeededModalComposable {
  showIdentificationNeededModal(): void;
}
export default function useIdentificationNeededModal(): UseIdentificationNeededModalComposable {
  const router = useRouter();

  function goToIdentification(): void {
    void router.push({ name: RouteName.IDENTIFICATION });
  }

  function showIdentificationNeededModal(): void {
    if (process.env.VUE_APP_FEATURE_PROMO_IDENTIFICATION_MODAL_ENABLED) {
      const { $translate } = useI18n();
      const { showDialog, closeDialog } = useDialogs();

      const { subscribe, id } = showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          title: $translate('WEB2_PROMO_CONFIRM_IDENTITY').value,

          image: { src: require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/default/bell.svg`) },
          confirmMessage: $translate('WEB2_PROMO_IDENTIFY_BEFORE_OPTIN').value,
          width: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalWidth.SMALL : ModalWidth.DEFAULT,
          buttons: [
            { label: $translate('WEB2_PROMO_IDENTIFICATION_CONFIRM').value },
            { label: $translate('WEB2_CLOSE').value },
          ],
          dataTestId: 'identification-modal',
        },
      });

      subscribe({
        [DialogAction.CONFIRM]: () => {
          closeDialog(id);
          goToIdentification();
        },
        [DialogAction.MODAL_CLOSE]: () => {
          closeDialog(id);
        },
      });
    } else {
      goToIdentification();
    }
  }
  return {
    showIdentificationNeededModal,
  };
}
