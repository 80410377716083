import type { FilledEmits } from '@leon-hub/input-types';
import { CustomInputEventType } from '@leon-hub/input-types';

interface UseFilledEvent {
  emitFilled(): void;
}

function useFilledEvent(emit: FilledEmits): UseFilledEvent {
  const emitFilled = (): void => emit(CustomInputEventType.FILLED);
  return {
    emitFilled,
  };
}

export default useFilledEvent;
