import type { Ref } from 'vue';
import { computed } from 'vue';

import { DateTime, TimeFormats } from '@leon-hub/utils';

import { useI18n, useI18nLocale } from '@core/i18n';

import type { PromotionDetailsHeaderProps } from '../types';

export interface PromotionDetailsHeaderComposable {
  periodProperties: Ref<string>;
}

export default function usePromotionDetailsHeader(props: PromotionDetailsHeaderProps): PromotionDetailsHeaderComposable {
  const { $translate } = useI18n();
  const { locale } = useI18nLocale();

  const formattedStartDate = computed(() => {
    const format = TimeFormats.dayMonthShortYear;
    if (!props.promotion?.startDateNumber) {
      return '';
    }
    return DateTime.formatTimeStamp(props.promotion.startDateNumber, format, locale.value);
  });

  const formattedEndDate = computed(() => {
    const format = TimeFormats.dayMonthShortYear;
    if (!props.promotion?.endDateNumber) {
      return '';
    }
    return DateTime.formatTimeStamp(props.promotion.endDateNumber, format, locale.value);
  });

  const periodProperties = computed<string>(() => {
    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return `${formattedStartDate.value} - ${formattedEndDate.value}`;
    }
    return !props.promotion.isArchived
      ? `${formattedStartDate.value} - ${formattedEndDate.value}`
      : $translate('WEB2_PROMOTION_FINISHED').value;
  });

  return { periodProperties };
}
