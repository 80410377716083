<script lang="ts" setup>
import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { SportlineEventElement } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type {
  BaseSportlineEventBlockProps,
  SportEventListItemExtendedMarketsRef,
  SportlineEventBlockEmits,
} from 'web/src/modules/sportline/views/EventBlock/types';
import ResizeObserver from 'web/src/components/ResizeObserver/ResizeObserver.vue';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportlineEventLink } from 'web/src/modules/sportline/composables/list';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { useIsEventExtended } from 'web/src/modules/sportline/submodules/extended-event/composbles';
import { useExpandableSportlineEventBlock } from 'web/src/modules/sportline/views/EventBlock/composables';
import SportEventListItemExtendedMarkets from 'web/src/modules/sportline/views/EventBlock/SportEventListItemExtendedMarkets.vue';
import SportlineEventFavoriteToggle from 'web/src/modules/sportline/views/FavoriteToggle/SportlineEventFavoriteToggle.vue';

import SportlineEventChampionshipMainInfo from './SportlineEventChampionshipMainInfo.vue';
import SportlineEventCompetitorsMainInfo from './SportlineEventCompetitorsMainInfo.vue';
import SportlineEventLiveMetaInfo from './SportlineEventLiveMetaInfo.vue';
import SportlineEventMainMarket from './SportlineEventMainMarket.vue';
import SportlineEventPrematchMetaInfo from './SportlineEventPrematchMetaInfo.vue';
import SportlineOutrightEventMainMarket from './SportlineOutrightEventMainMarket.vue';

interface SportlineEventBlockProps extends BaseSportlineEventBlockProps {
  sportEventElement: SportlineEventElement;
  basisKey: SportlineFragmentBasisKey;
}

const props = defineProps<SportlineEventBlockProps>();
const emit = defineEmits<SportlineEventBlockEmits>();

const container: Ref<Optional<HTMLElement>> = ref();
const extendElement: Ref<Optional<SportEventListItemExtendedMarketsRef>> = ref();

const sportlineEventElement = toRef(props, 'sportEventElement');
const activeEventId = toRef(props, 'activeEventId', null);
const isExtendedEventViewDisabled = toRef(props, 'isExtendedEventViewDisabled', false);
const canShowLogos = toRef(props, 'canShowLogos', false);
const canAddToFavorite = toRef(props, 'canAddToFavorite', false);
const isShownLiveIndicator = toRef(props, 'isShownLiveIndicator', false);
const extendedBlockType = toRef(props, 'extendedBlockType');
const upcomingLabelType = toRef(props, 'upcomingLabelType');
const todayLabelType = toRef(props, 'todayLabelType');
const sport = toRef(props, 'sport');
const basisKey = toRef(props, 'basisKey');
const sportEvent = computed(() => sportlineEventElement.value.sportEvent);

const sportlineEventId = computed(() => sportEvent.value.id);
const {
  sportlineType,
  isLive,
  isPrematch,
  isOutright,
} = useSportlineEventType({ sportEvent });
const { isEventExtended } = useIsEventExtended({ sportlineEventId, blockType: extendedBlockType });
const {
  isExtendedSportEventAllowed,
  beforeExtendedOpen,
  afterExtendedOpen,
  afterExtendedClose,
} = useExpandableSportlineEventBlock({
  container,
  isExtendedEventViewDisabled,
});
const {
  onCatalogClick,
} = useSportlineCatalogClick({ eventId: sportlineEventId });
const {
  href,
  onLinkClick,
} = useSportlineEventLink({
  sportEvent,
  activeEventId,
}, {
  onClick(): void { emit('click'); },
});

function onExtendElement(): void {
  extendElement.value?.open();
}

function onCollapseElement(): void {
  extendElement.value?.close();
}

// data-test is used for LEONWEB-6045
</script>

<template>
  <ResizeObserver v-auto-id="'SportlineEventBlock'"
    v-data-test="{
      el: 'sportline-event-block',
      live: isLive,
      prematch: isPrematch,
      outright: isOutright,
    }"
    :class="$style['sportline-event-block']"
    :disabled="!isEventExtended"
    @resize="emit('resize', { ...$event, sportlineEventId })"
  >
    <div
      ref="container"
      :class="$style['sportline-event-block-container']"
    >
      <SportlineEventFavoriteToggle
        v-if="canAddToFavorite"
        :class="$style['sportline-event-block-container__favorite']"
        :sportline-event-id="sportlineEventId"
        :sportline-event-type="sportlineType"
        is-colored
      />

      <a
        :href="href"
        :class="[
          $style['sportline-event-info'],
          $style['sportline-event-block-container__info'],
        ]"
        @click="onCatalogClick($event);onLinkClick($event)"
      >
        <SportlineEventChampionshipMainInfo
          v-if="isOutright"
          :sportline-event="sportEvent"
        />
        <SportlineEventCompetitorsMainInfo
          v-else
          :sportline-event="sportEvent"
          :sport="sport"
          :can-show-logos="canShowLogos"
        />

        <SportlineEventLiveMetaInfo
          v-if="isLive"
          :sportline-event="sportEvent"
          :do-show-live-indicator="isShownLiveIndicator || isOutright"
        />
        <SportlineEventPrematchMetaInfo
          v-else
          :sportline-event="sportEvent"
          :upcoming-label-type="upcomingLabelType"
          :today-label-type="todayLabelType"
        />
      </a>

      <SportlineOutrightEventMainMarket
        v-if="isOutright"
        :sportline-event="sportEvent"
        :sport="sport"
        :active-event-id="activeEventId"
        :basis-key="basisKey"
        @click="emit('click')"
      />
      <SportlineEventMainMarket
        v-else
        :sportline-event="sportEvent"
        :sport="sport"
        :active-event-id="activeEventId"
        :is-extended="isEventExtended"
        :is-extended-sport-event-allowed="!!'1' && isExtendedSportEventAllowed"
        :basis-key="basisKey"
        @click="emit('click')"
        @extend-element="onExtendElement"
        @collapse-element="onCollapseElement"
      />
    </div>

    <SportEventListItemExtendedMarkets
      v-if="'1' && isExtendedSportEventAllowed"
      ref="extendElement"
      :sport-event-id="sportlineEventId"
      :extended-block-type="extendedBlockType"
      @before-open="beforeExtendedOpen"
      @after-open="afterExtendedOpen"
      @after-close="afterExtendedClose"
    />
  </ResizeObserver>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-info';

.sportline-event-block {
  &:first-child {
    overflow: hidden;
    border-top-left-radius: var(--BorderRadius);
    border-top-right-radius: var(--BorderRadius);
  }

  &:last-child {
    overflow: hidden;
    border-bottom-right-radius: var(--BorderRadius);
    border-bottom-left-radius: var(--BorderRadius);
  }
}

.sportline-event-block-container {
  position: relative;
  display: flex;
  background-color: var(--Layer1);

  &__favorite {
    min-width: 44px;
  }

  &__info {
    flex: 1 1 auto;
    flex-wrap: nowrap;
    min-width: 0;
    padding: 0;
  }
}
</style>
