import type { Ref } from 'vue';
import { inject, provide, ref } from 'vue';

import type { GetEventInfoRef } from '../types';
import { createEventIsOpenedRefInjectionSymbol } from '../constants';
import { useExtendedEventStore } from '../store';

function defaultProvider(): Ref<boolean> {
  return ref(false);
}

export function provideCreateEventIsOpenedRef(provider?: GetEventInfoRef<boolean>): void {
  provide<GetEventInfoRef<boolean>>(createEventIsOpenedRefInjectionSymbol, (provider ?? defaultProvider));
}

export function useCreateEventIsOpenedRefProvider(): GetEventInfoRef<boolean> {
  return inject<GetEventInfoRef<boolean>>(createEventIsOpenedRefInjectionSymbol, () => {
    const { createEventIsOpenedRef } = useExtendedEventStore();
    return createEventIsOpenedRef;
  }, true);
}
