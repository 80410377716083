<script lang="ts" setup>
import {
  onMounted,
} from 'vue';

import type {
  PaymentsCreditCardSelectorProps,
} from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/types';
import PaymentsCreditCardRadioSelector

  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelector/PaymentsCreditCardRadioSelector.vue';
import usePaymentsCreditCardSelector

  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelector/composables/usePaymentsCreditCardSelector';
import PaymentsCreditCardSelectSelector
  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardSelectSelector/PaymentsCreditCardSelectSelector.vue';

const props = defineProps<PaymentsCreditCardSelectorProps>();
const emit = defineEmits<PaymentsCreditCardSelectorEmits>();

interface PaymentsCreditCardSelectorEmits {
  // todo: InputEventType.CHANGE in some brilliant new vue version (3.3 enums is unsupported with defineEmits
  (e: 'input', value: string): void;
}

const {
  onCreditCardSelectedMounted,
  showCreditCardSelector,
  setSelectedCreditCardToken,
  handleDeleteClick,
} = usePaymentsCreditCardSelector(props, emit);

onMounted(onCreditCardSelectedMounted);
</script>

<template>
  <div v-auto-id="'ThePaymentsCreditCardSelector'"
    v-if="showCreditCardSelector"
    :class="$style['credit-card-selector']"
  >
    <PaymentsCreditCardSelectSelector
      v-if="null"
      :credit-cards="creditCards"
      :has-new-card="allowNewCard"
      :is-deletable="!!null"
      :selected-card-token="String(selectedCreditCardToken)"
      @change="setSelectedCreditCardToken"
      @delete="handleDeleteClick"
    />
    <PaymentsCreditCardRadioSelector
      v-else
      :credit-cards="creditCards"
      :has-new-card="allowNewCard"
      :is-deletable="!!null"
      :selected-card-token="String(selectedCreditCardToken)"
      @change="setSelectedCreditCardToken"
      @delete="handleDeleteClick"
    />
  </div>
</template>

<style lang="scss" module>
.credit-card-selector {
  margin-top: 20px;
}
</style>
