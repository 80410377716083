<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';

import { VBadge } from '@components/badge';
import { HighlightedText } from '@components/highlighted-text';
import { VImage } from '@components/image';

import type {
  CasinoSearchItemEmits,
  CasinoSearchItemProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/types';
import { useCasinoSearchItem } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables';

const props = withDefaults(defineProps<CasinoSearchItemProps>(), {
  name: '',
  group: '',
  searchText: '',
  href: '',
  ntBadge: '',
  categories: () => ([]),
});

const emits = defineEmits<CasinoSearchItemEmits>();

const {
  onClick,
  imageProps,
  ntBadgeProperties,
  formattedCategories,
} = useCasinoSearchItem(props, emits);
</script>

<template>
  <!-- //NOSONAR -->
  <li v-auto-id="'CasinoSearchItem'" :class="$style['slots-search-item']">
    <a
      :class="$style['slots-search-item__link']"
      :href="href"
      @click.prevent="onClick"
    >
      <div :class="$style['slots-search-item__image-holder']">
        <VImage
          v-bind="imageProps"
          :class="$style['slots-search-item__image']"
        />
        <VBadge
          v-if="ntBadge"
          v-bind="ntBadgeProperties"
          :class="$style['slots-search-item__nt-badge']"
        />
      </div>
      <div :class="$style['slots-search-item__info']">
        <HighlightedText
          :class="$style['slots-search-item__name']"
          :root-tag="Tag.DIV"
          :text="name"
          :query="searchText"
          exploded
        />
        <div :class="$style['slots-search-item__categories']">
          <HighlightedText
            :class="$style['slots-search-item__categories-item--group']"
            :text="group"
            :query="searchText"
          />
          <HighlightedText
            v-for="category in formattedCategories"
            :key="category"
            :text="`&nbsp;•&nbsp;${category}`"
            :query="searchText"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .slots-search-item {
    list-style: none;

    &__link {
      display: flex;
      align-items: center;
      padding: 8px;
      text-decoration: none;
      background: var(--Layer1);
      border-radius: $casinoSearchItemImageRadius;

      @if $isDesktop {
        &:hover {
          background: var(--Highlight);
        }
      }
    }

    &__image-holder {
      position: relative;
      align-self: flex-start;
      width: $casinoSearchItemImageSize;
      min-width: $casinoSearchItemImageSize;
      height: $casinoSearchItemImageSize;
    }

    &__nt-badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &__image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: $casinoSearchItemImageRadius;
    }

    &__info {
      padding-left: 16px;
    }

    &__name {
      @include casinoSearchItemNameFont;

      margin-bottom: 4px;
      color: $casinoSearchItemNameColor;
    }

    &__categories {
      @include casinoSearchItemCategoriesFont;

      display: flex;
      flex-wrap: wrap;
      color: $casinoSearchItemCategoriesColor;
    }

    &__categories-item {
      &--group {
        color: $casinoSearchItemGroupColor;
      }
    }
  }
}
</style>
