import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { FormatMoneyOptions } from '@core/money';
import { useI18n } from '@core/i18n';
import { useFormatMoney } from '@core/money';

import type { VButtonProps } from '@components/buttons';
import { BadgeKind } from '@components/badge';

import type { VInformerProps } from '../types';
import { getButtonProps } from './utils';

interface UseVInformerComposable {
  cancelButtonProperties: ComputedRef<VButtonProps>;
  formattedValue: ComputedRef<string>;
  badgeKind: BadgeKind.DEFAULT;
}

export default function useVInformer(props: VInformerProps): UseVInformerComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();
  const options: FormatMoneyOptions = {};
  const badgeKind = BadgeKind.DEFAULT;

  const cancelButtonProperties = computed(
    () => getButtonProps($translate('WEB2_CUSTOMER_HISTORY_CANCEL').value),
  );
  const formattedValue = computed(() => {
    if (props.currency) {
      options.currency = props.currency;
    }
    return `${$formatMoney(props.value ?? 0, options)}`;
  });

  return {
    cancelButtonProperties,
    formattedValue,
    badgeKind,
  };
}
