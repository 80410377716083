import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { Theme } from '@leon-hub/api-sdk';

import { getMascotLogoByName } from '@components/loader';

import type { VLogoProps } from '../types';
import { ProductSpecificName } from '../enums';

export interface VLogoComposable {
  alt: ComputedRef<string>;
  src: ComputedRef<string>;
}

export default function useVLogo(props: VLogoProps): VLogoComposable {
  const defaultName = ProductSpecificName.LOGO;

  const alt = computed(() => {
    const logoName = props.productSpecificName || props.name || defaultName;
    return logoName.split('.')[0];
  });

  const src = computed(() => {
    const themeSuffix = props.theme === Theme.LIGHT ? '-light' : '';

    const dynamicSrcLogoName = `${props.productSpecificName || defaultName}${themeSuffix}`;
    const dynamicSource = require(`web/src/assets/logotypes/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/brand-default/${dynamicSrcLogoName}.svg`);

    const srcByProductSpecificName = props.productSpecificName && dynamicSource;
    const srcByName = props.name && require(`web/src/assets/logotypes/common/${props.name}.svg`);
    const srcMascot = props.mascotName && getMascotLogoByName(props.mascotName);
    return srcByProductSpecificName || srcByName || srcMascot || dynamicSource;
  });

  return {
    src,
    alt,
  };
}
