import type {
  Ref,
} from 'vue';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { IconName } from '@leon-hub/icons';

import { useCurrencyStore, useFormatMoney } from '@core/money';

import type { VButtonProps } from '@components/buttons';

import type { VBalanceProps } from '../types';
import {
  baseButtonProps,
} from './utils';

export interface VBalanceComposable {
  mainTag: Ref<HTMLElement | undefined>;
  balanceValue: Ref<string>;
  currencyIconProps: Ref<VButtonProps>;
}

export default function useVBalance(props: VBalanceProps): VBalanceComposable {
  const mainTag = ref<HTMLElement>();
  const formatMoney = useFormatMoney();
  const formattedCurrency = toRef(useCurrencyStore(), 'formattedCurrency');

  const balanceValue = computed(() => (formatMoney(props.value, { hideCurrency: props.isAddButtonAsCurrency })));

  const currencyIconProps = computed(() => {
    const buttonBase = baseButtonProps(props);
    if (!props.isAddButtonAsCurrency || props.isBalanceHidden) {
      buttonBase.iconName = props.isBigIcon || props.isBalanceHidden ? IconName.PLUS : IconName.PLUS_SMALL;
    } else {
      buttonBase.label = formattedCurrency.value;
    }

    return buttonBase;
  });

  return {
    mainTag,
    balanceValue,
    currencyIconProps,
  };
}
