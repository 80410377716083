import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
  ref,
} from 'vue';

import { LobbyTitleType } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';

import type { LobbySectionHeaderProps } from 'web/src/modules/lobby/components/LobbySectionHeader/types';
import type {
  LobbyGridBaseProps,
  LobbyItemClickData,
  LobbySectionBaseEmits,
  LobbySectionBaseProps,
} from 'web/src/modules/lobby/types';
import { isEgsGame } from 'web/src/modules/lobby/utils';

interface LobbySectionComposable {
  lobbyGridBaseProps: ComputedRef<LobbyGridBaseProps>;
  isEdit: Ref<boolean>;
  headerProps: ComputedRef<LobbySectionHeaderProps>;
  isSmallHeader: ComputedRef<boolean>;
  isHeaderVisible: ComputedRef<boolean>;
  isEditBtnEnabled: ComputedRef<boolean | undefined>;
  emitActionClick(): void;
  emitClickLobbyItem(data: LobbyItemClickData): void;
  onEditClick(): void;
  onClickLobbyItem(data: LobbyItemClickData): void;
}

export function useLobbySection(
  props: LobbySectionBaseProps,
  emits: LobbySectionBaseEmits,
): LobbySectionComposable {
  const isEdit = ref<boolean>(false);
  const gameIdsToRemove = ref<string[]>([]);

  const isEditBtnEnabled = computed(() => props.isEditable && props.lobbyItems.length > 0);
  const isSmallHeader = computed(() => props.titleType === LobbyTitleType.SMALL);
  const isHeaderVisible = computed(() => props.titleType !== LobbyTitleType.HIDDEN);

  const headerProps = computed<LobbySectionHeaderProps>(() => ({
    actionLabel: props.actionLabel,
    actionHref: props.actionHref,
    title: props.headerName,
    isSmall: isSmallHeader.value,
    isModal: props.isModal,
    iconUrl: props.headerIcon,
    isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
    isLoading: props.showSkeleton,
    noBackground: props.noBackground,
    isCustomHomepage: props.isCustomHomepage,
    ntBadge: props.headerNtBadge,
  }));

  const lobbyGridBaseProps = computed<LobbyGridBaseProps>(() => ({
    items: props.lobbyItems,
    lobbyItemType: props.lobbyItemType,
    widgetId: props.widgetId,
    isModal: props.isModal,
    noBackground: props.noBackground,
    lobbyGridType: props.gridType,
    jackpotAnimationDuration: props.jackpotAnimationDuration,
    hasNext: props.hasNext,
    isItemNameVisible: props.isItemNameVisible,
    isEditMode: isEdit.value,
    gameIdsToRemove: gameIdsToRemove.value,
    isLoadMoreButtonVisible: props.isLoadMoreButtonVisible,
  }));

  function onEditClick(): void {
    if (isEdit.value) {
      if (gameIdsToRemove.value.length > 0) {
        emitDeleteItems(gameIdsToRemove.value);
      }
      gameIdsToRemove.value = [];
    }

    isEdit.value = !isEdit.value;
  }

  function onClickLobbyItem(data: LobbyItemClickData): void {
    if (!isEdit.value) {
      emitClickLobbyItem(data);
      return;
    }

    assert(isEgsGame(data.item, props.lobbyItemType));
    const { id } = data.item;

    if (gameIdsToRemove.value.includes(id)) {
      gameIdsToRemove.value = gameIdsToRemove.value.filter((gameId) => gameId !== id);
    } else {
      gameIdsToRemove.value.push(id);
    }
  }

  function emitClickLobbyItem(data: LobbyItemClickData): void {
    emits('click-lobby-item', data);
  }

  function emitDeleteItems(data: string[]): void {
    emits('delete-items', data);
  }

  function emitActionClick(): void {
    emits('action-click');
  }

  return {
    lobbyGridBaseProps,
    isEdit,
    headerProps,
    isSmallHeader,
    isEditBtnEnabled,
    isHeaderVisible,
    emitActionClick,
    emitClickLobbyItem,
    onEditClick,
    onClickLobbyItem,
  };
}
