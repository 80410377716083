export enum LoginFieldName {
  LOGIN = 'login',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  CAPTCHA_TYPE = 'captchaType',
  CAPTCHA_TOKEN = 'captchaAnswer',
  FINGERPRINT = 'fingerprint',
  LOGIN_SUFFIX = 'login/suffix',
  LOGIN_PREFIX = 'login/prefix',
  CAPTCHA_V3 = 'captchaAnswerV3',
}
