import { useBaseApiClient } from '@core/app-rest-client';

import type { AddressAutocompleteResult, AutocompleteAddressInput } from './types';
import { isAddressAutocompleteResult } from './guards';

export interface AddressApi {
  autocompleteAddress(input: AutocompleteAddressInput): Promise<AddressAutocompleteResult>;
}

export default function createAddressApi(): AddressApi {
  const client = useBaseApiClient();

  return {
    async autocompleteAddress({ address, countryCode, types }: AutocompleteAddressInput) {
      return client.request<AddressAutocompleteResult>({
        id: 'autocomplete/address',
        endpoint: '/autocomplete/address',
        query: {
          address,
          countryCode,
          types,
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isAddressAutocompleteResult,
        silent: true,
      });
    },
  };
}
