import type { ComputedRef, Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';

import type { FocusableElement } from '@leon-hub/focus';
import { getFileList } from '@leon-hub/cordova';
import { assert } from '@leon-hub/guards';
import { CustomInputEventType, InputEventType } from '@leon-hub/input-types';

import type {
  FileSingleInputStatelessEmits,
  FileSingleInputStatelessProps,
} from '../../types';

interface UseFileSingleStateless {
  isHover: Ref<boolean>;
  isFocus: Ref<boolean>;
  debugError: Ref<string>;
  resetIndex: Ref<number>;
  isEmpty: ComputedRef<boolean>;
  onMouseenter(): void;
  onMouseleave(): void;
  onFocus(event: Event): void;
  onBlur(event: Event): void;
  onChange(event: Event): void;
  onClear(): Promise<void>;
  onClick(event: Event): Promise<void>;
  formattedFileName: ComputedRef<string>;
  fileExtension: ComputedRef<string>;
}

export default function useFileSingleStateless(
  props: FileSingleInputStatelessProps,
  emit: FileSingleInputStatelessEmits,
  inputRef: Ref<HTMLInputElement | undefined | FocusableElement>,
): UseFileSingleStateless {
  const isHover = ref<boolean>(false);
  const isFocus = ref<boolean>(false);
  const debugError = ref('');

  const resetIndex = ref<number>(0);

  const isEmpty = computed<boolean>(() => !props.fileName?.length);

  const onMouseenter = (): void => {
    isHover.value = true;
  };

  const onMouseleave = (): void => {
    isHover.value = false;
  };

  const onFocus = (event: Event): void => {
    isFocus.value = true;
    emit(InputEventType.FOCUS, event);
  };

  const onBlur = (event: Event): void => {
    isFocus.value = false;
    emit(InputEventType.BLUR, event);
  };

  const onChange = (event: Event): void => {
    emit(InputEventType.CHANGE, event);
  };

  const onClear = async (): Promise<void> => {
    resetIndex.value += 1;
    await nextTick();
    emit(CustomInputEventType.CLEAR);
  };

  const onClick = async (event: Event): Promise<void> => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      debugError.value = '';
      event.preventDefault();
      const inputElement = inputRef.value;
      assert(inputElement instanceof HTMLInputElement);
      try {
        inputElement.files = await getFileList();
        inputElement.dispatchEvent(new Event('change'));
      } catch (error) {
        debugError.value = String(error);
      }
    }
  };

  const splittedFileName = computed<{ name: string; extension: string }>(() => {
    let name = '';
    let extension = '';
    if (!props.fileName) {
      return { name, extension };
    }
    const latsDot = props.fileName.lastIndexOf('.');
    if (latsDot > -1) {
      name = props.fileName.slice(0, latsDot);
      extension = props.fileName.slice(latsDot);
    } else {
      name = props.fileName;
    }
    return { name, extension };
  });

  const formattedFileName = computed<string>(() => splittedFileName.value.name);

  const fileExtension = computed<string>(() => splittedFileName.value.extension);

  return {
    isHover,
    isFocus,
    isEmpty,
    resetIndex,
    formattedFileName,
    fileExtension,
    debugError,
    onMouseenter,
    onMouseleave,
    onFocus,
    onBlur,
    onClear,
    onClick,
    onChange,
  };
}
