<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useTheme } from '@core/theme';

import { ButtonKind, LButton, SButton } from '@components/buttons';
import { VRouterLink } from '@components/link';
import { VIcon } from '@components/v-icon';

import { LanguageSwitcherButton } from '@modules/localization-components';

import VSocial from 'web/src/components/Social/VSocial/VSocial.vue';
import AppDownloadButtons from 'web/src/modules/app-download/views/AppDownloadButtons/AppDownloadButtons.vue';
import FooterCopyright from 'web/src/modules/core/components/FooterCopyright/FooterCopyright.vue';
import FooterLogos from 'web/src/modules/core/components/FooterLogos/FooterLogos.vue';
import FooterNavigationList from 'web/src/modules/core/components/FooterNavigationList/FooterNavigationList.vue';
import FooterNavigationListItem
  from 'web/src/modules/core/components/FooterNavigationListItem/FooterNavigationListItem.vue';
import FooterPartnershipLogos from 'web/src/modules/core/components/FooterPartnershipLogos/FooterPartnershipLogos.vue';
import FooterResponsibleGambling
  from 'web/src/modules/core/components/FooterResponsibleGambling/FooterResponsibleGambling.vue';
import FooterTsupisLogos from 'web/src/modules/core/components/FooterTsupisLogos/FooterTsupisLogos.vue';
import FooterFollowUs from 'web/src/modules/core/views/FooterFollowUs/FooterFollowUs.vue';
import FooterLicense from 'web/src/modules/core/views/FooterLicense/FooterLicense.vue';
import FooterSeo from 'web/src/modules/core/views/FooterSeo/FooterSeo.vue';
import FooterSponsoredClubsLogos
  from 'web/src/modules/core/views/FooterSponsoredClubsLogos/FooterSponsoredClubsLogos.vue';
import OddsSwitcherButton
  from 'web/src/modules/sportline/submodules/odds/views/OddsSwitcherButton.vue';
import ThemeSwitcherButton from 'web/src/modules/theme/views/ThemeSwitcherButton.vue';

import type { FooterRouteComponentProps } from './types';
import { useFooterRouteComponent } from './composables';

withDefaults(defineProps<FooterRouteComponentProps>(), {});

const {
  isOddsFormatSwitcherEnabled,
  isLoggedIn,
  genericInfoNavigationItems,
  bootstrapNavigationItems,
  doShowAppDownloadButtons,
  affiliateFooterNavigationItem,
  socialButtonsList,
  telegramBotNavigationItem,
  tsupisLogos,
  partnershipLogos,
  bookmakerRatingsUrl,
  isLanguageSwitcherAvailable,
  isThemeFooterSwitcherEnabled,
  currentLanguageFlag,
  doShowSponsoredClubsLogos,
  followUsList,
  showScrollTopButton,
  routeClass,
  goTop,
  openLanguage,
} = useFooterRouteComponent();

const { theme } = useTheme();
</script>

<template>
  <footer v-auto-id="'FooterRouteComponent'"
    :class="{
      [$style['footer']]: true,
      [$style['footer--visible']]: true,
      [$style['footer--full-width']]: !!'1' && isFullWidth,
      [$style['footer--simplified']]: null,
      [$style[`footer--${routeClass}`]]: routeClass,
    }"
  >
    <template v-if="null">
      <section
        v-if="showScrollTopButton"
        :class="{
          [$style['footer__top--simple-layout']]: isSimpleLayout,
        }"
      >
        <SButton
          v-if="null"
          full-width
          :label="$t('WEB2_GO_TOP')"
          kind="tertiary-secondary"
          size="large"
          @click="goTop"
        />
        <LButton
          v-else
          :kind="ButtonKind.BASE"
          full-width
          :label="$t('WEB2_GO_TOP')"
          @click="goTop"
        />
      </section>
      <section
        v-if="null"
      >
        <FooterResponsibleGambling />
      </section>
    </template>
    <template v-else>
      <FooterSeo
        v-if="!null
          && '1'
          && !isLoggedIn"
        :class="$style['footer__special']"
      />
      <section
        v-if="null"
        :class="$style['footer__menu']"
      >
        <FooterNavigationList
          :navigation-items="genericInfoNavigationItems"
          :is-logged-in="isLoggedIn"
        />
      </section>
      <section
        v-if="'1'"
        :class="$style['footer__menu']"
      >
        <div :class="$style['footer__column']">
          <div
            v-if="!null"
            :class="$style['footer__menu-item--title']"
          >
            {{ $t('WEB2_INFORMATION') }}
          </div>
          <FooterNavigationList
            :navigation-items="genericInfoNavigationItems"
            :is-logged-in="isLoggedIn"
            :class="$style['footer__menu-list']"
          />
        </div>
        <div
          v-if="!null"
          :class="$style['footer__column']"
        >
          <div
            :class="$style['footer__menu-item--title']"
          >
            {{ $t('WEB2_WHERE_TO_BEGIN') }}
          </div>
          <FooterNavigationList
            :navigation-items="bootstrapNavigationItems"
            :is-logged-in="isLoggedIn"
          />
        </div>
        <div
          v-if="!null"
          :class="$style['footer__column']"
        >
          <div :class="$style['footer__menu-item--title']">
            {{ $t('WEB2_BRAND_NAME_FULL') }}
          </div>
          <div
            v-if="!null
              && '1'
              && '1'
              && doShowAppDownloadButtons"
            :class="$style['footer__app']"
          >
            <AppDownloadButtons :text="$t('WEB2_GUEST_SLIP_APP')" />
          </div>
          <div
            v-if="affiliateFooterNavigationItem"
            :class="$style['footer__aff']"
          >
            <FooterNavigationListItem
              :item="affiliateFooterNavigationItem"
              :is-logged-in="isLoggedIn"
              :class="$style['footer__aff-title']"
            >
              <span v-collect:click-counter="{ affiliateProgramLink: 'true' }">
                {{ affiliateFooterNavigationItem.caption }}
              </span>
            </FooterNavigationListItem>
            <div
              v-if="affiliateFooterNavigationItem.label"
              :class="$style['footer__aff-text']"
            >
              {{ affiliateFooterNavigationItem.label }}
            </div>
          </div>
          <ul
            v-if="socialButtonsList.length"
            :class="$style['footer__social-list']"
          >
            <li
              v-for="{ name, clickCollectName, ...props } in socialButtonsList"
              :key="clickCollectName"
            >
              <VSocial
                v-if="'1'"
                v-collect:click-counter="{ socialMediaIcons: clickCollectName }"
                :class="$style['footer__social-button']"
                v-bind="props"
                :title="name"
              />
            </li>
          </ul>
          <ul
            v-if="telegramBotNavigationItem"
            :class="$style['footer__social-list']"
          >
            <li>
              <VRouterLink
                :class="$style['footer__social-link']"
                :to="{
                  name: telegramBotNavigationItem.routeName || RouteName.HOME,
                }"
              >
                <VIcon
                  :size="IconSize.SIZE_24"
                  :name="IconName.TELEGRAM_CIRCLE"
                  :class="$style['footer__social-icon']"
                />
                <span
                  v-if="telegramBotNavigationItem.props && telegramBotNavigationItem.props.telegramBotId"
                  :class="$style['footer__social-text']"
                >
                  {{ telegramBotNavigationItem.props.telegramBotId }}
                </span>
              </VRouterLink>
            </li>
          </ul>
          <FooterFollowUs v-if="followUsList.length" />
        </div>
      </section>

      <FooterFollowUs v-if="!null && followUsList.length && null" />

      <section
        v-if="null && showScrollTopButton"
        :class="$style['footer__button']"
      >
        <SButton
          v-if="null"
          full-width
          :label="$t('WEB2_GO_TOP')"
          kind="quaternary-secondary"
          size="large"
          @click="goTop"
        />
        <LButton
          v-else
          :kind="ButtonKind.BASE"
          full-width
          :label="$t('WEB2_GO_TOP')"
          @click="goTop"
        />
      </section>
      <FooterLogos
        v-if="!null
          && !null"
      >
        <template
          v-if="null"
          #tsupis
        >
          <FooterTsupisLogos :tsupis-logos="tsupisLogos" />
        </template>

        <template
          v-if="null && !isLoggedIn"
          #partnership
        >
          <FooterPartnershipLogos
            :partnership-logos="partnershipLogos"
            :bookmaker-ratings-url="bookmakerRatingsUrl"
          />
        </template>

        <template
          v-if="null && doShowSponsoredClubsLogos"
          #sponsored
        >
          <FooterSponsoredClubsLogos />
        </template>
      </FooterLogos>

      <section :class="$style['footer__bottom-container']">
        <section
          :class="{
            [$style['footer__bottom']]: true,
            [$style['footer__bottom--with-lang-switch']]: !null
              && isLanguageSwitcherAvailable,
          }"
        >
          <div :class="$style['footer__bottom--switchers-box']">
            <LanguageSwitcherButton
              v-if="!null && '1'
                && !null && isLanguageSwitcherAvailable"
              :name="currentLanguageFlag"
              :class="$style['footer__language-switcher']"
              @open-language="openLanguage"
            />
            <ThemeSwitcherButton
              v-if="isThemeFooterSwitcherEnabled"
              :theme="theme"
            />
            <OddsSwitcherButton
              v-if="'1' && isOddsFormatSwitcherEnabled"
            />
          </div>

          <FooterFollowUs
            v-if="null && followUsList.length"
            :show-title="false"
          />

          <section :class="$style['footer__copyright']">
            <FooterCopyright />
          </section>
        </section>
        <FooterLicense
          v-if="'1' && !null"
          :is-grayscale="!!null"
          :class="[
            $style['footer__license'],
            $style['footer__license--lower'],
          ]"
        />
      </section>
    </template>
  </footer>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .footer {
    @include z-index(base);

    display: none;
    padding: if($isDesktop, $footerPaddingDesktop, $footerPaddingMobile);
    background-color: $footerBackgroundColor;
    border-radius: if($isDesktop, $footerBorderRadiusDesktop, $footerBorderRadiusPhone);

    // for tablet Galaxy Tab S6
    @media (min-width: 700px) and (max-width: 720px) {
      max-width: calc(100% - 55px);
    }

    // take in account, that property make footer visible is most cases:
    &--visible {
      display: block;
    }

    &__special {
      padding: 0;
      margin: if($isDesktop, $footerSpecialMarginDesktop, $footerSpecialMarginPhone);

      .footer__special {
        margin: 0;
      }
    }

    &__menu {
      @include footerMenu;

      display: if($isDesktop, grid, flex);
      margin-bottom: 16px;

      @if $isDesktop {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 16px;
        justify-content: flex-start;
        padding: 32px 0 16px;
      }

      &-item--title {
        @include medium\18\20;

        margin: 0 0 16px;
        color: var(--TextDefault);
      }
    }

    &__button {
      @include footerButton;
    }

    &__button,
    &__app {
      margin-bottom: $footerButtonMarginBottom;
    }

    &__aff {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      &-title {
        @include regular\14\16\025;

        margin-bottom: 8px;
        color: var(--TextPrimary);

        &:hover {
          color: var(--TextDefault);
        }
      }

      &-text {
        @include regular\13\20;

        margin-bottom: 8px;
        color: var(--TextSecondary);
      }
    }

    &__social {
      &-list {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
      }

      &-item {
        padding: 0;
        margin: 0;
      }

      &-button {
        padding: 10px 4px;
        color: var(--TextPrimary);

        &:hover {
          color: var(--TextDefault);
        }
      }

      &-text {
        @include medium\14\16\025;

        padding: 4px 0;
        margin-left: 8px;
      }

      &-link {
        display: flex;
        align-items: center;
        color: var(--TextPrimary);
        text-decoration: none;

        .footer__social-icon {
          color: var(--TextPrimary);
        }

        &:hover {
          color: var(--TextDefault);

          .footer__social-icon {
            color: var(--TextDefault);
          }
        }
      }
    }

    &__bottom {
      width: 100%;

      &--with-lang-switch {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      &--switchers-box {
        display: flex;
        flex-direction: if($isDesktop, row, column);
        gap: if($isDesktop, 16px, 8px);
        align-items: center;
        justify-content: center;
        margin-bottom: $footerButtonMarginBottom;
      }
    }

    &__language-switcher {
      color: var(--TextPrimary);
      background-color: var(--Button);
      border-radius: 50px;
    }

    &__rewards {
      display: if($isDesktop, block, none);

      @if $isDesktop {
        width: 100%;
        height: 177px;
        padding-top: 24px;

        &-list {
          display: flex;
          padding: 16px 0 0;
          margin-top: 0;
          margin-bottom: 0;
          list-style: none;
        }
      }
    }

    &__reward {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.3%;
      height: 120px;
      background-repeat: no-repeat;
      background-position: bottom left;

      &-text {
        max-width: 114px;
        line-height: 16px;
      }

      &-img {
        margin-top: auto;
        margin-right: 20px;
      }
    }

    &__bottom-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      @if $isDesktop {
        flex-wrap: wrap;
      }
    }

    .app__content--full-width & {
      &__special,
      &__menu,
      &__rewards-list,
      &__partners,
      &__button {
        max-width: 752px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &--full-width {
      width: 100%;
      min-width: $layoutDesktopMinWidth;
      max-width: $layoutDesktopMaxWidth;
      padding-right: 16px;
      padding-left: 16px;
      margin-right: auto;
      margin-left: auto;

      @if $isDesktop {
        padding: 32px 16px 40px;
      }
    }

    &--full-width & {
      &__special,
      &__menu,
      &__rewards-list,
      &__partners,
      &__bottom-container,
      &__button {
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    &__license {
      @include footerLicense;

      &--upper {
        margin: if($isDesktop, 0 0 32px, 0 -8px 16px);
      }
    }

    &--simplified {
      display: flex;
      flex-direction: column;
      gap: if($isDesktop, $footerVerticalGapDesktop, $footerVerticalGapMobile);
      margin-bottom: if($isDesktop, $footerMarginBottomDesktop, $footerMarginBottomMobile);

      .footer__menu {
        display: flex;
        grid-template-columns: 1fr;
        justify-content: center;
        padding: 0;
        margin: 0 if($isDesktop, $footerMenuListMargin, 0);
      }

      .footer__bottom-container {
        gap: if($isDesktop, $footerVerticalGapDesktop, $footerVerticalGapMobile);
      }

      .footer__bottom {
        position: relative;
        padding-top: if($isDesktop, $footerVerticalGapDesktop, $footerVerticalGapMobile);

        &:after {
          @include footerDivider;

          right: $footerDividerPosition;
          left: $footerDividerPosition;
          width: $footerDividerWidth;

          @if $isDesktop {
            right: $footerDividerPositionDesktop;
            left: $footerDividerPositionDesktop;
            width: $footerDividerWidthDesktop;
          }
        }
      }

      .footer__license {
        position: relative;
        gap: if($isDesktop, $footerLogotypesGapDesktop, $footerLogotypesGapMobile);
        width: 100%;
        padding-top: if($isDesktop, $footerLicensePaddingTopGapDesktop, $footerLicensePaddingTopMobile);
        margin-bottom: 0;

        &:after {
          @include footerDivider;

          right: $footerDividerPosition;
          left: $footerDividerPosition;
          width: $footerDividerWidth;

          @if $isDesktop {
            right: $footerDividerPositionDesktop;
            left: $footerDividerPositionDesktop;
            width: $footerDividerWidthDesktop;
          }
        }
      }
    }

    &__top {
      &--simple-layout {
        margin-bottom: $footerBackToTopMarginBottom;
      }
    }
  }
}
</style>
