import { computed, ref } from 'vue';

import { assert, isNumber } from '@leon-hub/guards';
import {
  AlertIconName,
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { InputEventType, isVInputFileEvent } from '@leon-hub/input-types';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ModalWidth } from '@components/dialogs';

import { getImageOrIcon } from 'web/src/modules/icons';
import createUniqueId from 'web/src/utils/createUniqueId';

import type { FileMultipleEmits, FileMultipleEvent, FileRecord } from '../types';
import type { UseMultipleFileInput, UseMultipleFileInputProps } from './types';
import { getFileRecord } from './utils';

export default function useMultipleFileInput(props: UseMultipleFileInputProps, emit: FileMultipleEmits): UseMultipleFileInput {
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();

  const uniqueId = computed<string>(() => createUniqueId(props.name || ''));

  const filesRecords = ref<FileRecord[]>([]);

  const currentFiles = computed<File[]>(() => filesRecords.value.map((item) => item.record));

  const acceptedFileTypes = computed<string | undefined>(() => {
    const accepted = Object.keys(props.fileTypes ?? {})
      .map((type) => `.${type}`)
      .join(', ');
    return accepted.length ? accepted : undefined;
  });

  const updateFileRecords = (records: FileRecord[]): void => {
    filesRecords.value = records;
  };

  const getEventToEmit = (selectedIndex: number): FileMultipleEvent => ({
    target: {
      files: currentFiles.value,
      selectedIndex,
      name: props.name || '',
    },
  });

  const emitChangeEvents = (selectedIndexes: number[]): void => {
    for (const selectedIndex of selectedIndexes) {
      emit(InputEventType.CHANGE, getEventToEmit(selectedIndex));
    }
  };

  const deleteFile = (index: number): void => {
    const file = filesRecords.value[index];

    if (!file) {
      return;
    }
    const changedIndexes: number[] = [];
    for (let fileIndex = filesRecords.value.length - 1; fileIndex >= index; fileIndex -= 1) {
      changedIndexes.push(fileIndex);
    }

    const filesRecordsNew = [...filesRecords.value];
    filesRecordsNew.splice(index, 1);
    updateFileRecords(filesRecordsNew);
    emitChangeEvents(changedIndexes);
  };

  const getOrDeleteFileRecord = (file: File, index: number): Promise<FileRecord> => getFileRecord({
    file,
    fileTypes: props.fileTypes ?? {},
    errorCallback: () => deleteFile(index),
  });

  const onReplace = async (event: Event, index: number): Promise<void> => {
    assert(isVInputFileEvent(event));
    const file = event.target.files ? event.target.files[0] : null;
    if (file && filesRecords.value[index]) {
      const fileRecordsNew = [...filesRecords.value];
      fileRecordsNew[index] = await getOrDeleteFileRecord(file, index);
      updateFileRecords(fileRecordsNew);
      emitChangeEvents([index]);
    }
  };

  const max = computed<number>(() => (isNumber(props.max) ? props.max : Number.POSITIVE_INFINITY));

  const addMoreAvailable = computed<boolean>(() => filesRecords.value.length < max.value);

  const handleFileList = async (files: File[] | FileList): Promise<void> => {
    const fileList = [...files];
    const filesRecordsNew = [...filesRecords.value];

    const selectedIndexes: number[] = [];

    let limit = max.value - filesRecords.value.length;

    if (limit === 0) {
      return;
    }

    for await (const file of fileList) {
      if (limit === 0) {
        return;
      }

      const index = filesRecordsNew.length;

      const newRecord = await getOrDeleteFileRecord(file, index);

      filesRecordsNew.push(newRecord);
      selectedIndexes.push(index);
      limit -= 1;
    }
    updateFileRecords(filesRecordsNew);
    emitChangeEvents(selectedIndexes);
  };

  const removeByIndex = (index: number): void => {
    const confirmMessage = $translate('WEB2_SIDENT_DELETE_FILE_CONFIRMATION').value;
    const buttonLabel = $translate('WEB2_IMAGE_DELETE').value;
    const { subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        confirmMessage,
        image: getImageOrIcon({ alertIcon: AlertIconName.QuestionMark }).image,
        buttons: [{
          label: buttonLabel,
        }],
        width: ModalWidth.SMALL,
        dataTestId: 'file-input-remove-confirmation',
        ...(process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? {
              iconName: IconName.SLOTT_EXCLAMATION,
              iconSize: IconSize.SIZE_60,
            }
          : {}),
      },
    });
    subscribe({
      [DialogAction.CONFIRM]: () => {
        deleteFile(index);
      },
    });
  };

  const onChange = async (event: Event): Promise<void> => {
    const { target } = event;

    assert(target instanceof HTMLInputElement);

    const fileList = target.files ? [...target.files] : [];

    target.value = '';

    await handleFileList(fileList);
  };

  const onBlur = (selectedIndex: number): void => {
    emit(InputEventType.BLUR, getEventToEmit(selectedIndex));
  };

  const onFocus = (selectedIndex: number): void => {
    emit(InputEventType.FOCUS, getEventToEmit(selectedIndex));
  };

  return {
    uniqueId,
    filesRecords,
    addMoreAvailable,
    acceptedFileTypes,
    removeByIndex,
    onChange,
    onReplace,
    onBlur,
    onFocus,
    handleFileList,
  };
}
