<script lang="ts" setup>
import type { FileMultipleDragAndDropInputProps } from 'web/src/components/Input';
import { FileDragAndDropInput } from 'web/src/components/Input';

import type {
  FileFormWidgetsEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useFormFileMultipleEvents } from '../../composables';
import useFormFileDragAndDropInput from './useFormFileDragAndDropInput';

const props = defineProps<FormWrapperWidgetProperties<FileMultipleDragAndDropInputProps>>();
const emit = defineEmits<FileFormWidgetsEmits>();

const {
  emitChange,
  emitFocus,
  emitBlur,
} = useFormFileMultipleEvents(emit);

const { componentProps } = useFormFileDragAndDropInput(props);
</script>

<template>
  <FileDragAndDropInput v-auto-id="'FormFileDragAndDropInput'"
    v-bind="componentProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
