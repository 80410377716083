import { AbstractError, AbstractErrorCode } from '@leon-hub/errors';

import type { FormExternalErrors } from 'web/src/components/Form/types';

export class CustomerFormErrorCode extends AbstractErrorCode {
  public constructor(code: string) {
    super(code);
  }
}

export default class CustomerFormError extends AbstractError {
  constructor(
    public customErrors: FormExternalErrors,
    errorCode: string,
  ) {
    const code = new CustomerFormErrorCode(errorCode);
    super({
      message: Object.keys(customErrors)
        .map((key) => customErrors[key][0].message)
        .join('\n'),
      code,
    });
  }
}
