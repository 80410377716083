import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { assert, isOptionalString } from '@leon-hub/guards';

import { useI18n } from '@core/i18n';

import type { PasswordInputProps } from 'web/src/components/Input';
import type { PasswordValidateGroup } from 'web/src/components/PasswordValidator/types';
import isPasswordInputProps from 'web/src/components/Input/guards/isPasswordInputProps';

import type {
  FormWrapperWidgetProperties,
} from '../../types';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';

interface UseFormPassword {
  componentProps: ComputedRef<PasswordInputProps>;
}

export default function useFormPassword(
  props: FormWrapperWidgetProperties<PasswordInputProps>,
): UseFormPassword {
  const componentProps = computed<PasswordInputProps>(() => {
    const {
      name,
      value,
      disabled,
      title,
      error,
      hintMessage,
      options,
      autofocus,
      extraProperties,
    } = props;

    assert(isOptionalString(value), `Unexpected value type: ${typeof value}`);
    assert(isPasswordInputProps(options));

    const wrapperHintProperties = getWrapperHintProperties({
      options,
      hintMessage,
      error,
    });

    const { $translate } = useI18n();

    const validationGroups = computed<PasswordValidateGroup[]>(() => {
      const groups: PasswordValidateGroup[] = [];
      if (extraProperties?.minLength) {
        groups.push({
          rules: [
            {
              message: $translate('WEB2_PASSWORD_LENGTH_DYNAMIC_LENGTH', ref({ number: extraProperties?.minLength.toString() })).value,
              regexp: `.{${extraProperties?.minLength},}$`,
            },
          ],
          requiredValidRules: 1,
        });
      }
      if (extraProperties?.pattern) {
        groups.push({
          rules: [
            {
              message: $translate('WEB2_PASSWORD_NUMBER').value,
              regexp: extraProperties.pattern.toString(),
            },
          ],
          requiredValidRules: 2,
        });
      }
      return groups;
    });

    return {
      value,
      label: title,
      disabled,
      ...(options || {}),
      autofocus,
      name,
      error: wrapperHintProperties.error,
      hint: wrapperHintProperties.hint,
      hintLink: wrapperHintProperties.hintLink,
      groups: validationGroups.value,
    };
  });
  return {
    componentProps,
  };
}
