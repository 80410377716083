<script lang="ts" setup>
import type { AbstractBannerEmits, AbstractBannerProps } from 'web/src/modules/banners/types';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';

const props = withDefaults(defineProps<AbstractBannerProps>(), {
  bannerType: BannerType.MAIN_BANNER,
  aspectRatio: 1.75,
});

const emit = defineEmits<AbstractBannerEmits>();

const {
  banners,
  isGroupBannersDev,
  emitBannerItemClick,
} = useAbstractBanner(props, emit);
</script>

<template>
  <div v-auto-id="'StaticBanner'">
    <MainBannerItem
      v-for="(banner, index) in banners"
      :key="index"
      :banner="banner"
      :group-id="bannerGroup?.id"
      :aspect-ratio="aspectRatio"
      :is-group-banners-dev="isGroupBannersDev"
      :banner-type="bannerType"
      @click="emitBannerItemClick($event, banner)"
    />
  </div>
</template>
