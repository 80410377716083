import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { safeParseInt, sleep } from '@leon-hub/utils';

import {
  useIsDebugEnabled,
  useSaveToLocalStorage,
} from 'web/src/modules/sportline/submodules/debug/store/composables';

const LS_BET_DELAY_KEY = 'VIRTUAL_SPORT_BET_DELAY';

export const useVirtualSportDebugStore = defineStore('sportline-virtual-sport-debug', () => {
  const { isDebugEnabled } = useIsDebugEnabled();

  const { saveValueToLocalStorage, getValueFromLocalStorage } = useSaveToLocalStorage();

  const delayInProgress = ref(false);
  const betDelayInternal = ref<Maybe<number>>(null);

  const betDelay = computed<Maybe<number>>(() => (isDebugEnabled.value ? betDelayInternal.value : null));

  async function runDebugDelay(): Promise<void> {
    if (!betDelay.value) { return; }

    delayInProgress.value = true;
    await sleep(betDelay.value);
    delayInProgress.value = false;
  }

  if (isDebugEnabled.value) {
    const rawBetDelay = getValueFromLocalStorage(LS_BET_DELAY_KEY);
    if (rawBetDelay) { betDelayInternal.value = safeParseInt(rawBetDelay); }
  }

  return {
    isDebugEnabled,
    delayInProgress,
    betDelay,
    // delay
    setBetDelay(value: Optional<string>): void {
      betDelayInternal.value = value ? safeParseInt(value) : null;
    },
    saveBetDelay(): void { saveValueToLocalStorage(LS_BET_DELAY_KEY, betDelayInternal.value); },
    runDebugDelay,
  };
});
