<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { VSocialProps } from './types';
import { useVSocial } from './composables';
import { SocialType } from './enums';

const props = withDefaults(defineProps<VSocialProps>(), {
  socialType: SocialType.FACEBOOK,
  url: '',
  target: '',
  size: IconSize.SIZE_24,
  title: '',
});
const { iconProps } = useVSocial(props);
</script>

<template>
  <a v-auto-id="'VSocial'"
    :href="url"
    :aria-label="title || undefined"
    :target="target"
    rel="noopener"
  >
    <VIcon
      v-bind="iconProps"
    />
  </a>
</template>
