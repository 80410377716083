import type {
  Ref,
  WatchStopHandle,
} from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';

import type { MarketsGridFilter } from 'web/src/modules/sportline/submodules/markets-grid/types';

interface UseMarketsListTabsShowingStateProps {
  hasStatistic: Ref<boolean>;
  hasStatisticWidget: Ref<boolean>;
  gridFilters: Ref<MarketsGridFilter[]>;
  isStatisticSelected: Ref<boolean>;
  isStatisticWidgetSelected: Ref<boolean>;
  isEmptyMarketsList: Ref<boolean>;
}

interface UseMarketsListTabsShowingStateComposable {
  isDisplayStatisticColumn: Ref<boolean>;
  doShowTabs: Ref<boolean>;
  isDisplayStatisticWidgetComponent: Ref<boolean>;
  isDisplayInlineStatisticComponent: Ref<boolean>;
  stopShowTabsWatchers(): void;
  startShowTabsWatchers(): void;
}

export function useMarketsListTabsShowingState(
  props: UseMarketsListTabsShowingStateProps,
): UseMarketsListTabsShowingStateComposable {
  const {
    hasStatistic,
    hasStatisticWidget,
    isStatisticSelected,
    isStatisticWidgetSelected,
    gridFilters,
    isEmptyMarketsList,
  } = props;

  let doShowTabsWatcher: Maybe<WatchStopHandle> = null;

  const tabsHasBeenShown = ref<boolean>(false);

  const isDisplayStatisticWidgetComponent = computed<boolean>(() => (
    hasStatisticWidget.value
    && (isStatisticWidgetSelected.value || isEmptyMarketsList.value)
  ));
  const isDisplayInlineStatisticComponent = computed<boolean>(() => (
    (hasStatistic.value && !isDisplayStatisticWidgetComponent.value)
    && (isStatisticSelected.value || isEmptyMarketsList.value)
  ));
  const isDisplayStatisticColumn = computed<boolean>(() => (
    isDisplayStatisticWidgetComponent.value
    || isDisplayInlineStatisticComponent.value
  ));
  const doShowTabs = computed<boolean>(() => {
    if (isEmptyMarketsList.value) {
      return false;
    }

    if (tabsHasBeenShown.value) {
      return true;
    }

    if (hasStatistic.value) {
      return true;
    }

    if (hasStatisticWidget.value) {
      return true;
    }

    return process.env.VUE_APP_LAYOUT_PHONE
      ? gridFilters.value.length > 0
      : (!isEmptyMarketsList.value || gridFilters.value.length > 0);
  });

  function stopShowTabsWatchers(): void {
    doShowTabsWatcher?.();
    doShowTabsWatcher = null;
  }

  function startShowTabsWatchers(): void {
    stopShowTabsWatchers();
    doShowTabsWatcher = watch(doShowTabs, (newValue: boolean) => {
      if (newValue) {
        tabsHasBeenShown.value = true;
      }
    }, { immediate: true });
  }

  return {
    isDisplayStatisticColumn,
    doShowTabs,
    isDisplayStatisticWidgetComponent,
    isDisplayInlineStatisticComponent,
    stopShowTabsWatchers,
    startShowTabsWatchers,
  };
}
