import { inject } from 'vue';

import type { VSwiperSlideToProperties, VSwiperState } from 'web/src/components/Swiper/VSwiper/types';
import { SwiperProvidableKeys } from 'web/src/components/Swiper/Navigation/enums';

export interface useSwiperNavigationComposable {
  slideToNextVisibleSlide(): void;
  slideToPreviousVisibleSlide(): void;
  slideToNextSlide(indexOffset?: number): void;
  slideToPreviousSlide(indexOffset?: number): void;
  swiperState?: VSwiperState;
  slideToSlide(index: number, options?: VSwiperSlideToProperties): void;
}

export default function useSwiperNavigation(): useSwiperNavigationComposable {
  const slideToNextVisibleSlide = inject(SwiperProvidableKeys.SlideToNextVisibleSlide, () => {});
  const slideToPreviousVisibleSlide = inject(SwiperProvidableKeys.SlideToPreviousVisibleSlide, () => {});
  const slideToNextSlide = inject(SwiperProvidableKeys.SlideToNextSlide, () => {});
  const slideToPreviousSlide = inject(SwiperProvidableKeys.SlideToPreviousSlide, () => {});
  const swiperState = inject<VSwiperState>(SwiperProvidableKeys.State);
  const slideToSlide = inject(SwiperProvidableKeys.SlideToSlide, () => {});

  return {
    slideToNextVisibleSlide,
    slideToPreviousVisibleSlide,
    slideToNextSlide,
    slideToPreviousSlide,
    swiperState,
    slideToSlide,
  };
}
