import type { ComputedRef, Ref } from 'vue';
import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { CaptchaStatus, CaptchaType } from '@leon-hub/api-sdk';

import { getDefaultCaptchaConfig, useSiteConfigStore } from '@core/site-config';

import type { ReCaptchaTheme } from '@modules/captcha-utilities';
import { CaptchaRequesterStrategy } from '@modules/captcha-utilities';

import type { CaptchaSettingsForStrategy } from 'web/src/modules/captcha/store/types';
import { reCaptchaThemeConfigMapper } from 'web/src/modules/captcha/store/utils';

const useCaptchaStore = defineStore('captcha', () => {
  const siteConfigStore = useSiteConfigStore();
  const captcha = toRef(() => siteConfigStore.captcha);

  // State
  const captchaRequesterStrategy = ref(CaptchaRequesterStrategy.DEFAULT);
  const defaultCaptchaConfig = getDefaultCaptchaConfig();

  // Getters
  const loginCaptchaType = computed<CaptchaType>(() => captcha.value?.loginCaptchaType
    ?? defaultCaptchaConfig.loginCaptchaType);

  const registrationCaptchaType = computed<CaptchaType>(() => captcha.value?.registrationCaptchaType
    ?? defaultCaptchaConfig.registrationCaptchaType);

  const restorePasswordCaptchaType = computed<CaptchaType>(() => captcha.value?.passwordRecoveryCaptchaType
    ?? defaultCaptchaConfig.passwordRecoveryCaptchaType);

  const reCaptchaTheme = computed<ReCaptchaTheme>(() => reCaptchaThemeConfigMapper(
    captcha.value?.recaptcha.theme
    ?? defaultCaptchaConfig.recaptcha.theme,
  ));

  const isLoginEnforced = computed<boolean>(() => (captcha.value?.loginCaptchaStatus
    ?? defaultCaptchaConfig.loginCaptchaStatus) === CaptchaStatus.ENFORCED);

  const isRestorePasswordEnforced = computed<boolean>(() => (captcha.value?.passwordRecoveryCaptchaStatus
    ?? defaultCaptchaConfig.passwordRecoveryCaptchaStatus) === CaptchaStatus.ENFORCED);

  const isRegistrationEnforced = computed<boolean>(() => (captcha.value?.registrationCaptchaStatus
    ?? defaultCaptchaConfig.registrationCaptchaStatus) === CaptchaStatus.ENFORCED);

  const useIframeForCaptcha = computed<boolean>(() => captcha.value?.useIframeForCaptcha
    ?? defaultCaptchaConfig.useIframeForCaptcha);

  const captchaIframeUrl = computed<string>(() => captcha.value?.captchaIframeUrl
    ?? defaultCaptchaConfig.captchaIframeUrl);

  const apiUrl = computed<string>(() => captcha.value?.captchaApiUrl
    ?? defaultCaptchaConfig.captchaApiUrl);

  // Actions

  function getSiteKey(strategy: CaptchaRequesterStrategy): ComputedRef<string> {
    return computed<string>(() => {
      const reCaptchaKey = (captcha.value?.recaptcha.siteKeys ?? [])
        .find((sk) => sk.captchaType === captchaType(strategy).value);

      return reCaptchaKey?.key || '';
    });
  }

  function getSiteKeyByCaptchaType(captchaT: CaptchaType): ComputedRef<string> {
    return computed<string>(() => {
      const reCaptchaKey = (captcha.value?.recaptcha.siteKeys ?? [])
        .find((sk) => sk.captchaType === captchaT);

      return reCaptchaKey?.key || '';
    });
  }

  function isEnforced(strategy: CaptchaRequesterStrategy): Ref<boolean> {
    switch (strategy) {
      case CaptchaRequesterStrategy.LOGIN:
        return isLoginEnforced;
      case CaptchaRequesterStrategy.REGISTRATION:
        return isRegistrationEnforced;
      case CaptchaRequesterStrategy.RESTORE_PASSWORD:
        return isRestorePasswordEnforced;
      case CaptchaRequesterStrategy.DEFAULT:
        return ref(true);
      default:
        return ref(false);
    }
  }

  function captchaType(strategy: CaptchaRequesterStrategy): Ref<CaptchaType> {
    switch (strategy) {
      case CaptchaRequesterStrategy.LOGIN:
        return loginCaptchaType;
      case CaptchaRequesterStrategy.REGISTRATION:
        return registrationCaptchaType;
      case CaptchaRequesterStrategy.RESTORE_PASSWORD:
        return restorePasswordCaptchaType;
      default:
        return ref(CaptchaType.RECAPTCHA);
    }
  }

  function captchaSettingsForStrategy(
    strategy: CaptchaRequesterStrategy,
    ct?: CaptchaType,
  ): ComputedRef<CaptchaSettingsForStrategy> {
    return computed(() => ({
      isEnforced: isEnforced(strategy).value,
      isEnabledToShow: true,
      captchaType: ct || captchaType(strategy).value,
      siteKey: ct ? getSiteKeyByCaptchaType(ct).value : getSiteKey(strategy).value,
      theme: reCaptchaTheme.value,
      captchaRequesterStrategy: captchaRequesterStrategy.value,
    }));
  }

  return {
    useIframeForCaptcha,
    captchaIframeUrl,
    apiUrl,
    registrationCaptchaType,
    getSiteKeyByCaptchaType,
    captchaSettingsForStrategy,
  };
});

export default useCaptchaStore;
