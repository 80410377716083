import type { ComputedRef, Ref, Slots } from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';

import { InputEventType } from '@leon-hub/input-types';
import { hasSlot } from '@leon-hub/utils';

import type { VRadioEmits, VRadioProps } from '../types';

export interface VRadioComposable {
  isChecked: Ref<boolean>;
  hasLabel: ComputedRef<boolean>;
  hasSubTitle: ComputedRef<boolean>;
  onChange(event: InputEvent): void;
  beforeMount(): void;
}

export default function useVRadio(props: VRadioProps, emit: VRadioEmits, slots: Slots): VRadioComposable {
  const isChecked = ref(false);

  const hasLabel = computed(() => hasSlot(slots, 'default') || !!props.label);

  const hasSubTitle = computed(() => hasSlot(slots, 'content') || !!props.subTitle);

  function onChange(event: InputEvent): void {
    const { checked } = event.target as HTMLInputElement;
    isChecked.value = checked;
    emit(InputEventType.CHANGE, event);
  }

  function beforeMount(): void {
    isChecked.value = !!props.checked;
  }

  watch(() => props.checked, (value: boolean) => {
    isChecked.value = value;
  });

  return {
    isChecked,
    hasLabel,
    hasSubTitle,
    onChange,
    beforeMount,
  };
}
