import { CaptchaType } from '@leon-hub/api-sdk';
import { verifyAndroidRecaptcha as verifyAndroidRecaptchaCordova } from '@leon-hub/cordova';
import { normalizeError } from '@leon-hub/errors';
import {
  BusEvent,
  useBusSafeSubscribe,
  useEventsBus,
} from '@leon-hub/event-bus';
import {
  isError,
  isUndefined,
} from '@leon-hub/guards';
import { Json } from '@leon-hub/utils';

import type { DialogActionPayload } from '@core/dialogs';
import { DialogAction, DialogComponent, PresetName, useDialogs } from '@core/dialogs';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';
import { captchaChallengeWasShownKey } from '@core/storage';

import type {
  CaptchaToken,
  ReCaptchaResponse,
} from 'web/src/modules/captcha/store/types';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import {
  isCaptchaToken,
  isReCaptchaResponse,
} from 'web/src/modules/captcha/store/utils';
import CaptchaModal from 'web/src/modules/captcha/views/CaptchaModal/CaptchaModal.vue';

import type { CaptchaVerificationEmits } from '../../types/CaptchaVerificationEmits';
import type { VCaptchaVerificationWatcherProps } from '../types';

export default function useVCaptchaVerificationWatcher(
  props: VCaptchaVerificationWatcherProps,
  emit: CaptchaVerificationEmits,
): void {
  const bus = useEventsBus();
  const { getSiteKeyByCaptchaType } = useCaptchaStore();
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();

  useBusSafeSubscribe(BusEvent.CAPTCHA_VERIFICATION, ({ captchaType }: { captchaType: CaptchaType }) => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA && captchaType === CaptchaType.ANDROID_RECAPTCHA) {
      verifyAndroidRecaptcha();
    } else {
      verifyCaptchaCallback(captchaType);
    }
  });

  function verifyCaptchaCallback(captchaType: CaptchaType): void {
    const { subscribe } = showDialog({
      presetName: PresetName.CUSTOM,
      options: {
        modalComponent: DialogComponent.CustomModal,
        customComponent: CaptchaModal,
        title: $translate('WEB2_CAPTCHA_CHECK_MODAL_CAPTION').value,
        fullHeight: false,
        dataTestId: 'verify-captcha-callback',
        props: {
          captchaRequesterStrategy: props.captchaRequesterStrategy,
          captchaType,
        },
      },
    });

    subscribe({
      [DialogAction.MODAL_CLOSE]: (payload: DialogActionPayload<ReCaptchaResponse>) => {
        if (isReCaptchaResponse(payload.value)) {
          if (isUndefined(payload.value)) {
            bus.emit(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, {});
          } else if (isError(payload.value.captchaResponse)) {
            bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, { error: payload.value.captchaResponse });
          } else {
            emitVerify(payload.value.captchaResponse);
          }
        } else {
          bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
            error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
              log: `[TheCaptchaVerificationWatcher]: unknown response=${Json.stringify(payload.value)}`,
              payload: {
                captchaType,
              },
            })),
          });
        }
      },
    });
  }

  function verifyAndroidRecaptcha() {
    if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
      return;
    }

    window.sessionStorage.setItem(captchaChallengeWasShownKey, String(true));
    verifyAndroidRecaptchaCordova(
      getSiteKeyByCaptchaType(CaptchaType.ANDROID_RECAPTCHA).value,
      (captchaToken) => {
        if (isCaptchaToken(captchaToken)) {
          emitVerify(captchaToken);
        } else {
          bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
            error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
              log: '[TheCaptchaVerificationWatcher]: unknown response',
              payload: {
                captchaType: CaptchaType.ANDROID_RECAPTCHA,
                captchaToken,
              },
            })),
          });
        }
      },
      (error: unknown) => {
        bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
          error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
            originalError: normalizeError(error),
            payload: {
              captchaType: CaptchaType.ANDROID_RECAPTCHA,
            },
          })),
        });
      },
    );
  }

  function emitVerify(captchaToken: CaptchaToken): void {
    emit('verify', captchaToken);
  }
}
