import type { VInputInputEvent } from '@leon-hub/input-types';
import { toPattern } from '@leon-hub/vanilla-masker';

import type { FormDataEvent } from 'web/src/components/Form/types';

export default function getFormDataEventWithFormattedCPFValue(event: VInputInputEvent): FormDataEvent {
  const { name, value } = event.target;
  const maskedValue = toPattern(value, '999.999.999-99');
  return { name, value: maskedValue };
}
