import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { BankCard, SubmitPaymentRequest } from '@leon-hub/api-sdk';
import { SessionExceptionCode } from '@leon-hub/api-sdk';
import { isString } from '@leon-hub/guards';

import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';
import { usePaymentsConfig } from '@core/site-config';

import { InvalidCreditCardTokenizeCode } from 'web/src/modules/payments/enums';
import getCreditCardTokenizationApi from 'web/src/modules/payments/store/utils/getCreditCardTokenizationApi';
import isTokenizedCardField from 'web/src/modules/payments/utils/isTokenizedCardField';
import isValidCreditCardForTokenize from 'web/src/modules/payments/utils/isValidCreditCardForTokenize';

interface UseCreditCardTokenization {
  doTokenize(data: SubmitPaymentRequest, bankcards: BankCard[]): Promise<void>;
  tokenizePayload: Ref<SubmitPaymentRequest>;
  resetToken(): void;
}

export default function useCreditCardTokenization(): UseCreditCardTokenization {
  const tokenizationApiClient = getCreditCardTokenizationApi();
  const { isCcTokensEnabled } = usePaymentsConfig();
  const { $translate } = useI18n();
  const { convertToBaseError } = useErrorsConverter();

  const paymentRequestPayload = ref<SubmitPaymentRequest>({
    formParams: [],
    paymentSystemId: '',
    schemaId: '',
  });
  const token = ref<string>('');

  function setPaymentRequestPayload(payload: SubmitPaymentRequest): void {
    paymentRequestPayload.value = payload;
  }

  function resetToken() {
    token.value = '';
  }

  const pan = computed(() => {
    let value;
    if (isCcTokensEnabled.value && paymentRequestPayload.value !== null) {
      value = paymentRequestPayload.value.formParams.find(isTokenizedCardField)?.value;
    }
    return isString(value) ? value : undefined;
  });

  async function getToken(bankcards: BankCard[]): Promise<void> {
    try {
      if (isValidCreditCardForTokenize({ pan: pan.value, bankcards }) && pan.value) {
        const tokenize = await tokenizationApiClient.tokenize({ pan: pan.value });
        token.value = tokenize.token;
      }
    } catch (rawError) {
      const error = convertToBaseError(rawError);

      if (error.code.equals(InvalidCreditCardTokenizeCode.TOKENIZE_ERROR_INVALID_PAN)) {
        error.message = $translate('WEB2_TOKENIZE_ERROR_INVALID_PAN').value;
      } else if (error.code.equals(SessionExceptionCode.SESSION)) {
        error.message = $translate('WEB2_TOKENIZE_ERROR_SESSION').value;
      }
      throw error;
    }
  }

  const tokenizePayload = computed(() => {
    if (token.value) {
      return {
        ...paymentRequestPayload.value,
        formParams: paymentRequestPayload.value.formParams.map((item) => {
          if (isTokenizedCardField(item)) {
            return {
              ...item,
              value: token.value,
            };
          }
          return item;
        }),
      };
    }
    return paymentRequestPayload.value;
  });

  const doTokenize = async (data: SubmitPaymentRequest, bankcards: BankCard[]): Promise<void> => {
    setPaymentRequestPayload(data);
    await getToken(bankcards);
  };

  return {
    doTokenize,
    tokenizePayload,
    resetToken,
  };
}
