import type { RouteLocationNormalized } from 'vue-router';

import type { AppVueRouter } from '@core/router';

export type GetRouteSeoConfig = Pick<RouteLocationNormalized, 'name' | 'params'>;

export abstract class AbstractPageSeoController {
  abstract getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig>;

  abstract loadSeoMetaDataFor(
    route: GetRouteSeoConfig,
  ): void;
}
