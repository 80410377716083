import debounce from 'lodash/debounce';
import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useSiteConfigStore } from '@core/site-config';

import type { SearchType } from 'web/src/modules/search/submodules/sportline/enums';
import type { SearchSportEvent } from 'web/src/modules/sportline/types';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useMatchStreamAccess } from 'web/src/modules/sportline/submodules/streams';
import { searchEvents } from 'web/src/modules/sportline/utils/api';
import getNormalizedSearchQuery from 'web/src/utils/getNormalizedSearchQuery';

import {
  parseSportlineSearchResult,
} from './utils';

const useSearchSportlineStore = defineStore('search-sportline', () => {
  const analytics = useAnalytics();
  const searchMinCharsSize = toRef(() => useSiteConfigStore().searchMinCharsSize);
  const matchStreamAccess = useMatchStreamAccess();
  const sportlineSettingsStore = useSportlineSettingsStore();
  const searchRestApiFlags = toRef(() => sportlineSettingsStore.searchRestApiFlags);
  const parseSportlineSettings = toRef(() => sportlineSettingsStore.parseSportlineSettings);

  const searchText = ref('');
  const searchType = ref<SearchType>();
  const liveEvents = ref<SearchSportEvent[]>();
  const prematchEvents = ref<SearchSportEvent[]>();

  const isSearchAvailable = computed(
    () => searchText.value.length >= searchMinCharsSize.value,
  );
  const isSearchInProgress = computed(() => isSearchAvailable.value && (
    !liveEvents.value || !prematchEvents.value
  ));
  const isNoSearchResults = computed(() => (
    (!liveEvents.value || liveEvents.value.length === 0)
    && (!prematchEvents.value || prematchEvents.value.length === 0)
  ));

  async function fetchSportEvents(): Promise<void> {
    const response = await searchEvents({
      query: searchText.value,
      flags: [...searchRestApiFlags.value],
    });

    const result = parseSportlineSearchResult(
      response,
      parseSportlineSettings.value,
      matchStreamAccess.value,
    );
    liveEvents.value = result.liveEvents;
    prematchEvents.value = result.prematchEvents;
  }

  const doSearch = debounce(() => {
    void fetchSportEvents();
  }, 500);

  function setSearchText(value: string): void {
    searchText.value = getNormalizedSearchQuery(value);
    liveEvents.value = undefined;
    prematchEvents.value = undefined;

    if (!isSearchAvailable.value) {
      return;
    }

    doSearch();
  }

  function setSearchType(type: SearchType | undefined) {
    searchType.value = type;
  }

  function sendMetrics(clicks: boolean): void {
    const results = isSearchAvailable.value && !isNoSearchResults.value;

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      analytics.push(AnalyticsEvent.Z_SEARCH_SPORT, {
        searchDetails: {
          sport: {
            [searchType.value ?? 'null']: {
              [searchText.value]: {
                [results ? 'true' : 'false']: clicks ? 'clicked' : 'nonClicked',
              },
            },
          },
        },
      });
    } else {
      analytics.push(AnalyticsEvent.Z_SEARCH, {
        searchDetails: {
          results,
          clicks,
          keyWord: {
            [searchText.value || 'null']: searchType.value,
          },
        },
      });
    }
  }

  return {
    searchText,
    liveEvents,
    prematchEvents,
    isSearchAvailable,
    isNoSearchResults,
    isSearchInProgress,
    setSearchText,
    sendMetrics,
    setSearchType,
  };
});

export default useSearchSportlineStore;
