import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { FormFieldTouchedStrategy } from '@leon-hub/form-utils';
import { isArray, isUndefined } from '@leon-hub/guards';

import type {
  FormData,
  FormDataEvent,
  FormDataMap,
  FormDataValue,
  FormSnapshot,
  FormTouched,
} from '../../../types';
import type { TouchedStrategyMap, UseFormDataOutput, UseFormDataProps } from './types';
import {
  getAllFieldNames,
  getFormDataObject,
  getInitialFormDataMap,
  getTouchedStrategy,
  getTouchedStrategyMap,
  isEmptyDefaultValue,
  updateFormDataDefaultValues,
} from './utils/formDataUtils';
import getObjectTypeFields from './utils/getObjectTypeFields';

function useFormData({ schema, uiSchema }: UseFormDataProps): UseFormDataOutput {
  const touched: Ref<FormTouched> = ref(new Set<string>());

  const initialFormData = getInitialFormDataMap(schema.value, uiSchema.value);
  const formDataMap: Ref<FormDataMap> = ref(new Map(initialFormData));
  const defaultFormData: Ref<FormDataMap> = ref(new Map(initialFormData));

  const formDataObject: Ref<FormData> = computed(() => getFormDataObject(formDataMap.value, schema.value));

  const touchedStrategyMap: Ref<TouchedStrategyMap> = computed(() => getTouchedStrategyMap(uiSchema.value));

  const objectTypeFields = computed<string[]>(() => getObjectTypeFields(schema.value));

  const setValue = (name: string, value: FormDataValue): void => {
    formDataMap.value.set(name, value);
  };

  const deleteValue = (name: string): void => {
    formDataMap.value.delete(name);
  };

  const setTouched = (name: string): void => {
    touched.value.add(name);
  };

  const setTouchedRelatedToStrategy = (name: string, touchedStrategy: FormFieldTouchedStrategy): void => {
    if (getTouchedStrategy(name, touchedStrategyMap.value) === touchedStrategy) {
      setTouched(name);
    }
  };

  const removeTouched = (name: string): void => {
    touched.value.delete(name);
  };

  const isChanged = (dataPath: string): boolean => {
    const value = formDataMap.value.get(dataPath);
    const defaultValue = defaultFormData.value.get(dataPath);

    if (value instanceof File && defaultValue instanceof File) {
      return value.name !== defaultValue.name;
    }

    if (value && defaultValue) {
      return value !== defaultValue;
    }

    // value: string | boolean | number | null | undefined;
    return Boolean(value) !== Boolean(defaultValue);
  };

  const resetFormData = (): void => {
    formDataMap.value = new Map(initialFormData);
    touched.value.clear();
  };

  const allFieldNames: Ref<Set<string>> = computed(() => getAllFieldNames(schema.value));

  const getSnapshot = (): FormSnapshot => ({
    formData: new Map(formDataMap.value),
    touched: new Set(touched.value),
  });

  const restoreFormDataFromSnapShot = (snapshot: FormSnapshot): void => {
    const filteredFormData: FormDataMap = new Map();
    const filteredTouched: FormTouched = new Set();
    for (const key of allFieldNames.value) {
      const hasValue = snapshot.formData.has(key);
      const isTouched = snapshot.touched.has(key);
      if (hasValue) {
        const value = snapshot.formData.get(key);
        if (!isUndefined(value)) {
          filteredFormData.set(key, value);
        }
      }
      if (isTouched) {
        filteredTouched.add(key);
      }
    }
    formDataMap.value = filteredFormData;
    touched.value = filteredTouched;
  };

  // partial changeStateOnChange - without output and validation
  const handleFieldInput = (formEvent: FormDataEvent): void => {
    const { name, value, fieldOriginalName } = formEvent;
    const required = isArray(schema.value.required) ? schema.value.required : [];

    const currentValueIsEmptyDefaultValue = isEmptyDefaultValue({
      name,
      value,
      uiSchema: uiSchema.value,
    });

    if (value || (value !== undefined && !required.includes(name))) {
      setValue(name, value);
    } else {
      deleteValue(name);
    }

    if (currentValueIsEmptyDefaultValue) {
      removeTouched(name);
      deleteValue(name);
    } else {
      if (fieldOriginalName) {
        setTouched(fieldOriginalName);
      }
      setTouchedRelatedToStrategy(name, FormFieldTouchedStrategy.Change);
    }
  };

  // partial changeStateOnBlur - without output and validation
  const handleFieldBlur = (formEvent: FormDataEvent): void => {
    const { name } = formEvent;
    if (isChanged(name)) {
      setTouchedRelatedToStrategy(name, FormFieldTouchedStrategy.Blur);
    }
  };

  const setTouchedOnSubmit = (): void => {
    touched.value = new Set([...allFieldNames.value]);
  };

  const refreshFormData = (): void => {
    const updatedDefaults = getInitialFormDataMap(schema.value, uiSchema.value);
    formDataMap.value = updateFormDataDefaultValues({
      initialFormData: defaultFormData.value,
      updatedDefaults,
      fieldNames: allFieldNames.value,
      currentFormData: formDataMap.value,
      objectTypeFields: objectTypeFields.value,
    });
    defaultFormData.value = getInitialFormDataMap(schema.value, uiSchema.value);
  };

  watch(() => uiSchema.value.fields, () => {
    refreshFormData();
  }, { deep: true });

  return {
    formData: formDataObject,
    formDataMap,
    touched,
    handleFieldInput,
    handleFieldBlur,
    getSnapshot,
    restoreFormDataFromSnapShot,
    setTouchedOnSubmit,
    resetFormData,
    refreshFormData,
  };
}

export default useFormData;
