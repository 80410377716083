import type { MaskOptions } from '@leon-hub/input-types';
import { assert, isNumber } from '@leon-hub/guards';

import type { ChangeInputValueResult } from '../types';
import getOnlyDigitsFromString from './getOnlyDigitsFromString';
import handleMaskedValueChange from './handleMaskedValueChange';

interface Payload {
  event: ClipboardEvent;
  currentValue: string;
  maskOptions?: MaskOptions;
}

export default function handlePhonePaste({ event, currentValue, maskOptions }: Payload): ChangeInputValueResult {
  event.preventDefault();
  const { target, clipboardData } = event;
  assert(target instanceof HTMLInputElement);
  const { selectionStart, selectionEnd } = target;
  assert(isNumber(selectionStart));
  assert(isNumber(selectionEnd));

  const data = clipboardData?.getData('text') ?? '';

  const safeSymbolsFromBuffer = getOnlyDigitsFromString(data);

  if (!maskOptions) {
    const valueBefore = currentValue.slice(0, selectionStart);
    const valueAfter = currentValue.slice(selectionEnd);
    return {
      nextValue: [valueBefore, safeSymbolsFromBuffer, valueAfter].join(''),
      isFilled: false,
    };
  }

  return handleMaskedValueChange({
    target,
    selectionStart,
    selectionEnd,
    data,
    currentValue,
    maskOptions,
  });
}
