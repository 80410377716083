import { toRef } from 'vue';

import type { CustomerRegistrationType } from '@leon-hub/api-sdk';
import type { BaseError } from '@leon-hub/errors';
import { assert } from '@leon-hub/guards';
import { ZetaDspService } from '@leon-hub/metrics';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics, useGoogleAnalytics } from '@core/analytics';
import { useCustomerDataStore } from '@core/customer';
import { captchaChallengeWasShownKey } from '@core/storage';
import { LoginMethod } from '@core/user';

import { CaptchaRequesterStrategy } from '@modules/captcha-utilities';

import type { RegistrationMetric } from 'web/src/modules/registration/types/RegistrationMetric';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import useRegistrationIdleRedirectStore from 'web/src/modules/registration/store/useRegistrationIdleRedirectStore';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';

export interface RegistrationAnalytics {
  registrationError(error: BaseError, registrationType?: string): void;
  registrationSuccess(registrationMetrics: RegistrationMetric): void;
  registrationSubmit(): void;
  setMetrikaBonusCode({ registrationType, bonusCode }: { registrationType: CustomerRegistrationType; bonusCode: string }): void;
}

export default function useRegistrationAnalytics(): RegistrationAnalytics {
  const { push, yandexZInit, clickMap } = useAnalytics();
  const { pushGTM } = useGoogleAnalytics();
  const idleStore = useRegistrationIdleRedirectStore();

  const customerData = useCustomerDataStore();
  const countryName = toRef(customerData, 'countryName');
  const vipStatus = toRef(customerData, 'vipStatus');

  const { captchaSettingsForStrategy } = useCaptchaStore();
  const captchaSettings = captchaSettingsForStrategy(CaptchaRequesterStrategy.REGISTRATION);

  function registrationError(error: BaseError, registrationType?: string): void {
    if (process.env.VUE_APP_PRODUCT_LEONRU) {
      push(AnalyticsEvent.Z_REGISTRATION, {
        registration: {
          error: {
            message: error.message,
            code: error.code.toString(),
          },
        },
      });
    } else {
      assert(registrationType);
      push(AnalyticsEvent.Z_LI_REGISTRATION_ERROR, {
        registration: {
          [registrationType]: {
            error: {
              message: error.message,
              code: error.code.toString(),
            },
          },
        },
      });
    }
    push(AnalyticsEvent.Z_LOGGED_IN, {
      login: {
        error: {
          message: error.message,
          code: `${error.code}`,
        },
      },
    });
  }

  function registrationSuccess(registrationMetrics: RegistrationMetric): void {
    registrationSuccessAnalyticsHandler(registrationMetrics);

    if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED
      || process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED
      || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED
    ) {
      push(AnalyticsEvent.Z_DURATION_REGISTRATION, {
        registration: {
          durationRegistration: {
            [idleStore.getDurationRegistrationInSeconds()]: registrationMetrics.userId,
          },
        },
      });
    }

    const loginTypeKey = process.env.VUE_APP_FEATURE_TSUPIS_ENABLED
      ? 'fullRegistration'
      : registrationTypeFormat(registrationMetrics?.registrationType ?? null);

    push(AnalyticsEvent.Z_LOGGED_IN, {
      login: {
        ok: {
          [loginTypeKey]: {
            captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
            captcha: captchaSettings.value.captchaType,
            loginType: LoginMethod.DEFAULT,
          },
        },
      },
    });

    yandexZInit({
      userId: registrationMetrics.userId || '',
      customerType: vipStatus.value,
      country: countryName.value,
      currentTheme: registrationMetrics.theme,
    });
  }

  function setMetrikaBonusCode({ registrationType, bonusCode }: { registrationType: CustomerRegistrationType; bonusCode: string }): void {
    push(AnalyticsEvent.Z_BONUS_CODE, {
      registration: {
        [registrationTypeFormat(registrationType)]: {
          bonusCodeDetails: {
            bonusCode: bonusCode || null,
          },
        },
      },
    });
  }

  function registrationSubmit() {
    clickMap({ registration: 'registrationPage' });
  }

  function registrationSuccessAnalyticsHandler(registrationMetrics: RegistrationMetric): void {
    const {
      userId,
      registrationType,
      firstTheme,
      theme,
    } = registrationMetrics;

    pushGTM(AnalyticsEvent.CUSTOMER_REGISTERED, {
      userId,
      registrationType,
      firstTheme,
      theme,
    });

    push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
      ab_test_light_mode: {
        [firstTheme.toLowerCase()]: {
          registration: theme.toLowerCase(),
        },
      },
    });

    if ((process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) && registrationType) {
      push(AnalyticsEvent.Z_LI_REGISTRATION_OK, {
        registration: {
          [registrationTypeFormat(registrationType)]: 'ok',
        },
      });
    }

    if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) {
      push(AnalyticsEvent.Z_REGISTRATION, {
        registration: {
          ok: true,
          ...(firstTheme ? { defaultMode: firstTheme } : {}),
          ...(theme ? { registrationMode: theme } : {}),
        },
      });
    }
    ZetaDspService.getInstance().customerRegistered({
      userId,
    });
  }

  return {
    registrationError,
    registrationSubmit,
    registrationSuccess,
    setMetrikaBonusCode,
  };
}
