import { toRef, watch } from 'vue';

import { useRootStore } from '@core/root';

interface Options {
  onlyIfNotLoaded?: boolean;
}

interface UseOnAppContentLoadedCallback {
  performOnLoad(callback: () => void, options?: Options): void;
}

export default function useOnAppContentLoadedCallback(): UseOnAppContentLoadedCallback {
  const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');

  function performOnLoad(callback: () => void, { onlyIfNotLoaded }: Options = {}): void {
    if (isAppMainContentLoaded.value) {
      if (!onlyIfNotLoaded) {
        callback();
      }
      return;
    }

    const watcher = watch(isAppMainContentLoaded, (isLoaded) => {
      if (isLoaded) {
        callback();
        watcher();
      }
    });
  }

  return {
    performOnLoad,
  };
}
