import { FormErrorKeyword } from '@leon-hub/form-utils';

import { useI18n } from '@core/i18n';

import type { FormValidatorErrorPattern } from '../../../types';
import type { UseErrorPatternsOutput } from './types';

export default function useErrorPatterns(): UseErrorPatternsOutput {
  const { $translate } = useI18n();
  const errorPatterns: FormValidatorErrorPattern = {
    common: {
      [FormErrorKeyword.Required]: $translate('WEB2_FORM_ERROR_REQUIRED_DEFAULT').value,
      [FormErrorKeyword.Pattern]: $translate('WEB2_FORM_ERROR_PATTERN_DEFAULT').value,
      [FormErrorKeyword.MinLength]: $translate('WEB2_FORM_ERROR_MIN_LENGTH_DEFAULT').value,
      [FormErrorKeyword.MaxLength]: $translate('WEB2_FORM_ERROR_MAX_LENGTH_DEFAULT').value,
      [FormErrorKeyword.MinItems]: $translate('WEB2_FORM_ERROR_MIN_ITEMS_DEFAULT').value,
      [FormErrorKeyword.Format]: $translate('WEB2_FORM_WRONG_FORMAT').value,
      [FormErrorKeyword.InvalidDate]: $translate('WEB2_INCORRECT_DATE').value,
      [FormErrorKeyword.DateUnderMinYear]: $translate('WEB2_ERROR_UNDER_MIN_BIRTH_YEAR').value,
      [FormErrorKeyword.IsNotAnAdultStart]: $translate('WEB2_LEGAL_AGE_START').value,
      [FormErrorKeyword.IsNotAnAdultEnd]: $translate('WEB2_LEGAL_AGE_END').value,
      [FormErrorKeyword.IsFileWrongFormat]: $translate('WEB2_FILE_WRONG_FORMAT').value,
      [FormErrorKeyword.IsFileAlreadyExists]: $translate('WEB2_FILE_ALREADY_CHOSEN').value,
      [FormErrorKeyword.Enum]: $translate('WEB2_SELECT_CHOOSE_VALUE').value,
      [FormErrorKeyword.ForbiddenSymbol]: $translate('WEB2_FORM_FORBIDDEN_SYMBOL').value,
    },
  };
  return { errorPatterns };
}
