import type {
  Ref,
} from 'vue';
import { computed } from 'vue';

import { useFormatMoney } from '@core/money';

import type { FastMoneyChoiceItemProps, VFastMoneyChoiceEmits, VFastMoneyChoiceProperties } from '../types';
import { FastMoneyMaxPosition } from '../enums';

interface UseFastMoneyChoice {
  formattedBalance: Ref<string>;
  selectValue(value: number): void;
  computedItems: Ref<FastMoneyChoiceItemProps[]>;
}

function useFastMoneyChoice(props: VFastMoneyChoiceProperties, emit: VFastMoneyChoiceEmits): UseFastMoneyChoice {
  const formatMoney = useFormatMoney();

  const selectValue = (value: number): void => {
    if (value >= (props.minAvailableValue || 0) && (!props.maxAvailableValue || value <= props.maxAvailableValue)) {
      emit('select-value', value);
      emit('click-value', value);
    }
  };

  const maxItem = computed<FastMoneyChoiceItemProps | null>(() => {
    if (!props.maxEnabled || !props.maxValue) {
      return null;
    }
    return {
      isActive: props.maxValue === props.currentValue,
      label: props.maxLabel || '',
      value: props.maxValue,
    };
  });

  const computedItems = computed<FastMoneyChoiceItemProps[]>(() => {
    const mappedItems: FastMoneyChoiceItemProps[] = [];
    const minValue = props.minAvailableValue ?? 0;
    const itemsFromProps = props.items ?? [];
    for (const item of itemsFromProps) {
      const isAvailable = item.value >= minValue
        && (!props.maxAvailableValue || item.value <= props.maxAvailableValue);
      if (!props.hideUnavailableOptions || isAvailable) {
        mappedItems.push({
          ...item,
          // eslint-disable-next-line ts/prefer-nullish-coalescing
          isDisabled: props.disabled || !isAvailable,
          isActive: item.value === props.currentValue,
        });
      }
    }

    if (!maxItem.value) {
      return mappedItems;
    }

    return props.maxPosition === FastMoneyMaxPosition.START
      ? [maxItem.value, ...mappedItems]
      : [...mappedItems, maxItem.value];
  });

  const formattedBalance = computed(() => formatMoney(props.balance ?? 0, {
    currency: props.currency,
  }));

  return {
    selectValue,
    computedItems,
    formattedBalance,
  };
}

export default useFastMoneyChoice;
