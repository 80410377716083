<script setup lang="ts">
import type { BaseInputEmits, InputWrappedProps } from '@leon-hub/input-types';
import { IconName } from '@leon-hub/icons';
import { TextInputTypes } from '@leon-hub/input-types';

import { useBaseInputEvents } from '../../composables';
import InputButton from '../InputButton/InputButton.vue';
import TextInput from '../TextInput';
import useCardCvvInput from './useCardCvvInput';

defineOptions({
  inheritAttrs: false,
});

withDefaults(defineProps<InputWrappedProps>(), {
  name: 'cvv',
});

const emits = defineEmits<BaseInputEmits>();

const { maskOptions, showHelp } = useCardCvvInput();

const {
  emitChange,
  emitBlur,
  emitInput,
  emitFocus,
} = useBaseInputEvents(emits);
</script>

<template>
  <TextInput v-auto-id="'CardCvvInput'"
    v-data-test="{
      el: 'card-cvv-input',
      name,
    }"
    :autofocus="autofocus"
    :value="value"
    is-ym-disable-keys
    :name="name"
    :label="label"
    :placeholder="$t('WEB2_3_DIGITS')"
    :disabled="disabled"
    :hint="hint"
    :hint-link="hintLink"
    :hint-right="hintRight"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :mask="maskOptions"
    :type="TextInputTypes.TEL"
    :is-large="isLarge"
    clickable-icon
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  >
    <template #iconSuffix>
      <InputButton
        :class="$style['card-cvv-input-button']"
        :title="$t('WEB2_WHAT_IS_CVV_CVC')"
        :icon-name="null ? IconName.SLOTT_INTERROGATION : IconName.QUESTION_OUTLINE"
        @click="showHelp"
      />
    </template>
  </TextInput>
</template>

<style module lang="scss">
.card-cvv-input-button {
  cursor: help;
}
</style>
