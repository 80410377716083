import type { Ref } from 'vue';
import { computed } from 'vue';

import { Theme } from '@leon-hub/api-sdk';

import { useDynamicTranslation } from '@core/i18n';
import { useTheme } from '@core/theme';

export interface ThemeData {
  theme: Theme;
  name: string;
  isActive: boolean;
}

export interface VThemeSwitcherComposable {
  themeList: Ref<ThemeData[]>;
}

export default function useVThemeSwitcher(): VThemeSwitcherComposable {
  const themeSystem = useTheme();

  function isActive(theme: Theme): boolean {
    if (themeSystem.isAuto.value) {
      return theme === Theme.AUTO;
    }

    return theme === themeSystem.theme.value;
  }

  const translate = useDynamicTranslation();

  const themeList = computed(() => [Theme.DARK, Theme.LIGHT, Theme.AUTO].map((theme) => ({
    theme,
    name: translate('WEB2_CORDOVA_THEME_$', theme),
    isActive: isActive(theme),
  })));

  return {
    themeList,
  };
}
