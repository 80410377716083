import type { Ref } from 'vue';
import {
  ref,
} from 'vue';

import { getFreebetData } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

interface FreeBetComposable {
  freeBetActionId: Ref<string>;
  freeBetActionBannerUrl: Ref<string>;
  freeBetTermsPage: Ref<string>;
  freeBetBcode: Ref<string>;
  fetchFreeBetData(freeBetActionUrl: string): Promise<void>;
}

export function useFreeBetData(): FreeBetComposable {
  const gqlClient = useGraphqlClient();

  const freeBetActionId = ref<string>('');
  const freeBetActionBannerUrl = ref<string>('');
  const freeBetTermsPage = ref<string>('');
  const freeBetBcode = ref<string>('');

  async function fetchFreeBetData(freeBetActionUrl: string): Promise<void> {
    const response = await getFreebetData(gqlClient, (node) => node.queries.registration.getAvailableForms, {
      options: {
        freeBetActionUrl,
      },
    });
    freeBetActionId.value = response?.freeBetActionId ?? '';
    freeBetActionBannerUrl.value = response?.freeBetActionBannerUrl ?? '';
    freeBetTermsPage.value = response?.freeBetTermsPage ?? '';
    freeBetBcode.value = response?.freeBetBcode ?? '';
  }

  return {
    freeBetActionId,
    freeBetActionBannerUrl,
    freeBetTermsPage,
    freeBetBcode,
    fetchFreeBetData,
  };
}
