import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import type { FormSchema } from '@leon-hub/form-utils';
import { LoginType } from '@leon-hub/api-sdk';
import {
  FormControlType,
  FormErrorKeyword,
} from '@leon-hub/form-utils';
import { TextInputTypes } from '@leon-hub/input-types';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { BaseRouteName } from '@leon-hub/routing-config-names';

import { IS_CLEAR_PASSWORD_KEY } from '@core/auth';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import { CaptchaRequesterStrategy } from '@modules/captcha-utilities';

import type {
  FormPhoneValue,
  FormUiSchema,
  FormUiSchemaFieldEmail,
  FormUiSchemaFieldPhone,
  FormUiSchemaFieldSuggestEmail,
  FormUiSchemaFieldText,
} from 'web/src/components/Form/types';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useCasAuthStore } from 'web/src/modules/cas/store';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';

import type {
  CommonDefaultField,
  LoginDefaultFieldByTab,
} from './types';
import { LoginFieldName } from './enums';
import {
  accountSchema,
  emailSchema,
  phoneSchema,
} from './schemas';

function getDefaultLoginValue(): LoginDefaultFieldByTab {
  return {
    [LoginType.PHONE]: {
      prefix: process.env.VUE_APP_DEFAULT_PHONE_PREFIX,
      suffix: '',
    },
    [LoginType.EMAIL]: '',
    [LoginType.ACCOUNT]: '',
  };
}

const useLoginStore = defineStore('login', () => {
  const { $translate } = useI18n();
  const { parsePhone, getDefaultPhoneValueForForm } = useFormDefaultFormPhoneValue();
  const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
  const casAuthStore = useCasAuthStore();
  const captchaSettings = useCaptchaStore().captchaSettingsForStrategy(CaptchaRequesterStrategy.LOGIN);
  const isCookiesOk = toRef(casAuthStore, 'isCookiesOk');
  const localStorageManager = useLocalStorageManager();

  const loginType = ref<LoginType>(LoginType.PHONE);
  const phoneNumber = ref<Partial<FormPhoneValue> | null>(null);
  const defaultFieldCommon = ref<Partial<CommonDefaultField>>({
    password: '',
    checkbox: true,
    captchaType: undefined,
    captchaAnswer: undefined,
    fingerprint: undefined,
  });
  const defaultFieldLogin = ref<LoginDefaultFieldByTab>(getDefaultLoginValue());

  const isCaptchaEnabled = computed(() => captchaSettings.value.isEnabledToShow);
  const captchaType = computed(() => captchaSettings.value.captchaType);

  const loginField = computed<FormUiSchemaFieldText
    | FormUiSchemaFieldPhone
    | FormUiSchemaFieldEmail
    | FormUiSchemaFieldSuggestEmail>(() => {
    const commonOptions = {
      autofocus: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      autocomplete: 'off',
    };
    switch (loginType.value) {
      case LoginType.PHONE: {
        return {
          title: $translate('WEB2_LOGIN_PHONE').value,
          widget: FormControlType.Phone,
          default: getDefaultPhoneValueForForm(process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED
            ? (phoneNumber.value ?? {})
            : defaultFieldLogin.value.PHONE),
          options: {
            label: $translate('WEB2_PHONE_NUMBER').value,
            placeholder: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
              ? ''
              : $translate('WEB2_PHONE_INPUT_PLACEHOLDER').value,
            ...commonOptions,
          },
        };
      }
      case LoginType.EMAIL:
        return {
          title: $translate('WEB2_LOGIN_EMAIL').value,
          widget: FormControlType.SuggestEmail,
          default: defaultFieldLogin.value.EMAIL,
          options: {
            placeholder: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
              ? ''
              : $translate('WEB2_EMAIL_INPUT_PLACEHOLDER').value,
            suggestion: useSiteConfigStore().formsEmailHints,
            ...commonOptions,
          },
        };
      case LoginType.ACCOUNT:
        return {
          title: $translate('WEB2_LOGIN_ACCOUNT').value,
          widget: FormControlType.Text,
          default: defaultFieldLogin.value.ACCOUNT,
          options: {
            type: TextInputTypes.TEL,
            placeholder: $translate('WEB2_LOGIN_ACCOUNT').value,
            ...commonOptions,
          },
        };
      default:
        return {
          hidden: true,
        };
    }
  });

  const schema = computed<FormSchema>(() => {
    switch (loginType.value) {
      case LoginType.PHONE:
        return phoneSchema;
      case LoginType.EMAIL:
        return emailSchema;
      case LoginType.ACCOUNT:
        return accountSchema;
      default:
        return accountSchema;
    }
  });

  const uiSchema = computed<FormUiSchema>(() => ({
    order: [
      LoginFieldName.LOGIN,
      LoginFieldName.PASSWORD,
      LoginFieldName.CHECKBOX,
      LoginFieldName.CAPTCHA_TOKEN,
      LoginFieldName.CAPTCHA_TYPE,
    ],
    submitButton: null,
    fields: {
      [LoginFieldName.LOGIN]: loginField.value,
      [LoginFieldName.PASSWORD]: {
        widget: FormControlType.Password,
        title: $translate('JSPNAV_USTAT_PWD').value,
        default: defaultFieldCommon.value.password,
        options: {
          autocomplete: 'off',
          placeholder: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
            ? ''
            : $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value,
          hideValidation: true,
          hintLinkRight: {
            href: { name: BaseRouteName.RESTORE_PASSWORD },
            text: $translate('JSPACC_LOGIN_FORGPWD').value,
          },
        },
      },
      [LoginFieldName.CHECKBOX]: {
        hidden: !useSiteConfigStore().isCasAuthEnabled,
        title: $translate('JSP_LOGIN_SAVE_PASSWORD').value,
        widget: FormControlType.Checkbox,
        default: isCookiesOk.value || defaultFieldCommon.value.checkbox,
        options: {
          id: 'cas-save-password-checkbox',
        },
      },
      [LoginFieldName.CAPTCHA_TYPE]: {
        hidden: true,
        default: captchaSettings.value.captchaType,
      },
      [LoginFieldName.CAPTCHA_TOKEN]: {
        hidden: !captchaSettings.value.isEnforced,
        widget: FormControlType.Captcha,
        options: {
          captchaRequesterStrategy: CaptchaRequesterStrategy.LOGIN,
          captchaEnabled: captchaSettings.value.isEnabledToShow,
          reCaptchaTheme: captchaSettings.value.theme,
          isEnforced: captchaSettings.value.isEnforced,
        },
      },
      [LoginFieldName.FINGERPRINT]: {
        hidden: true,
        default: undefined,
      },
    },
    validatorErrorPatterns: {
      byWidget: {
        [FormControlType.Text]: {
          [FormErrorKeyword.Pattern]: $translate('WEB2_LOGIN_FORM_ERROR_PATTERN_ACCOUNT_NUMBER').value,
        },
        ...phoneErrorPatterns.value,
      },
    },
  }));

  function setLoginType(type: LoginType): void {
    loginType.value = type;
  }

  function setPhoneNumber(value: Maybe<Partial<FormPhoneValue>>): void {
    phoneNumber.value = value;
  }

  function setUiSchemaDefault<K extends keyof CommonDefaultField>(
    { field, value }: { field: K; value: CommonDefaultField[K] },
  ): void {
    defaultFieldCommon.value[field] = value;
  }

  function setUiSchemaLoginDefault<K extends keyof LoginDefaultFieldByTab>(
    { activeTab, value }: { activeTab: K; value: LoginDefaultFieldByTab[K] },
  ): void {
    defaultFieldLogin.value[activeTab] = value;
  }

  function resetUiSchemaLoginDefault(): void {
    defaultFieldLogin.value = getDefaultLoginValue();
  }

  function prefillLoginForm(type: LoginType, value: string, fakePassword: string): void {
    setLoginType(type);
    if (loginType.value === LoginType.PHONE) {
      phoneNumber.value = parsePhone(value);
      setUiSchemaLoginDefault({
        activeTab: LoginType.PHONE,
        value: phoneNumber.value,
      });
    } else {
      setUiSchemaLoginDefault({
        activeTab: loginType.value,
        value,
      });
    }
    if (process.env.VUE_APP_PLATFORM_CORDOVA && !!localStorageManager.getItem(IS_CLEAR_PASSWORD_KEY)) {
      return;
    }
    setUiSchemaDefault({
      field: LoginFieldName.PASSWORD,
      value: fakePassword,
    });
  }

  return {
    loginType,
    phoneNumber,
    schema,
    uiSchema,
    isCaptchaEnabled,
    captchaType,
    defaultFieldLogin,
    setLoginType,
    setPhoneNumber,
    setUiSchemaDefault,
    setUiSchemaLoginDefault,
    resetUiSchemaLoginDefault,
    prefillLoginForm,
  };
});

export default useLoginStore;
