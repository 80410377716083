import type { MaybeRef, Ref } from 'vue';
import { computed, toRef, watch } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import type { TabsItem } from 'web/src/components/Tabs/types';
import type { MarketsGridFilter, MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { MarketGroupStyleType } from 'web/src/modules/sportline/enums';
import {
  allMarketsGridFilterCustomId,
  statisticMarketsGridFilterCustomId,
  statisticWidgetMarketsGridFilterCustomId,
} from 'web/src/modules/sportline/submodules/markets-grid/constants';

interface MarketsListTabsProps {
  hasStatistic?: MaybeRef<Optional<boolean>>;
  hasStatisticWidget?: MaybeRef<Optional<boolean>>;
  hasAllTab?: MaybeRef<Optional<boolean>>;
  gridFilters: Ref<MarketsGridFilter[]>;
  activeTabId: Ref<Optional<MarketsGridFilterId>>;
  tabsRef: Ref<VSwiperRef | undefined>;
}

interface MarketsListTabsEmit {
  (e: 'tab-click', payload: MarketsGridFilterId): void;
}

interface MarketsListTabsComposable {
  activeTabIndex: Ref<number>;
  tabs: Ref<TabsItem[]>;
  slideToActive(smooth?: boolean): void;
  onTabClick(value: string): void;
}

export function useMarketsTabs(
  props: MarketsListTabsProps,
  emit: MarketsListTabsEmit,
): MarketsListTabsComposable {
  const {
    gridFilters,
    activeTabId,
    tabsRef,
  } = props;

  const hasAllTab = toRef(props.hasAllTab ?? false);
  const hasStatistic = toRef(props.hasStatistic ?? false);
  const hasStatisticWidget = toRef(props.hasStatisticWidget ?? false);

  const { $translate } = useI18n();

  const basicTabsList = computed<TabsItem[]>(() => (hasAllTab.value
    ? [{
        id: allMarketsGridFilterCustomId,
        label: $translate('WEB_ALL_MARKETS').value,
      }]
    : []));
  const statisticTabs = computed<TabsItem[]>(() => (hasStatistic.value
    ? [{
        id: statisticMarketsGridFilterCustomId,
        icon: {
          name: IconName.STATISTIC,
          size: IconSize.SIZE_18,
        },
      }]
    : []));
  const statisticWidgetTabs = computed<TabsItem[]>(() => (hasStatisticWidget.value
    ? [{
        id: statisticWidgetMarketsGridFilterCustomId,
        icon: {
          name: IconName.STATISTIC,
          size: IconSize.SIZE_18,
        },
      }]
    : []));

  const tabs = computed<TabsItem[]>(() => [
    ...statisticWidgetTabs.value,
    ...statisticTabs.value,
    ...basicTabsList.value,
    ...gridFilters.value.map((item) => ({
      id: item.id,
      label: item.name,
      type: item.style?.type === MarketGroupStyleType.PROMO ? TabsType.PROMO : undefined,
    })),
  ]);
  const activeTabIndex = computed(() => tabs.value.findIndex((tab) => tab.id === activeTabId.value));

  function slideToActive(smooth = false): void {
    tabsRef.value?.slideToSlide(activeTabIndex.value, {
      smooth,
      speed: 300,
      isCentered: true,
    });
  }

  function onTabClick(value: string): void {
    emit('tab-click', value as MarketsGridFilterId);
  }

  watch(activeTabId, () => {
    slideToActive(true);
  });

  return {
    activeTabIndex,
    tabs,
    slideToActive,
    onTabClick,
  };
}
