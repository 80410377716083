import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlinePersistentLocationKey } from 'web/src/modules/sportline/submodules/persist-filters/types';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';

import { useResetPersistLocationSettings } from './useResetPersistLocationSettings';

/** Return null for the situation when page shouldn't affect the state */
type UseDoSavePersistLocationComposable = Record<SportlinePersistentLocationKey, Ref<Maybe<boolean>>>;

export function useDoSavePersistLocation(): UseDoSavePersistLocationComposable {
  const { isResetLocationEnabled } = useResetPersistLocationSettings();
  const { isBetSwipeNavigationLayoutEnabled } = useSportlineLayoutSettings();

  const isEnabled = computed<Maybe<boolean>>(() => !isResetLocationEnabled.value);
  const isBetSwipeEnabled = computed<Maybe<boolean>>(() => (
    isBetSwipeNavigationLayoutEnabled.value
      ? !isResetLocationEnabled.value
      : null
  ));

  return {
    live: isEnabled,
    cybersport: isEnabled,
    sports: isEnabled,
    region: isBetSwipeEnabled,
    league: isBetSwipeEnabled,
  };
}
