import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type { BaseError } from '@leon-hub/errors';
import {
  CustomerRegistrationType,
  PhoneCodeMethod,
  RegistrationStateExceptionCode,
} from '@leon-hub/api-sdk';
import {
  isEnumOfType,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useAppsflyer } from '@core/appsflyer';
import { useCustomerDataStore } from '@core/customer';
import { useErrorsConverter } from '@core/errors';

import type { FormOutput } from 'web/src/components/Form';
import type { PrepareRegistrationInputOptions } from 'web/src/modules/registration/types';
import { useCountdownTimerManager } from 'web/src/modules/registration/composables/useCountdownTimerManager';
import {

  RegistrationFormStep,
  RegistrationStage,
  RegistrationTypeMetrika,
} from 'web/src/modules/registration/enums';
import { useRegistrationStage } from 'web/src/modules/registration/store/composables';
import { useFreeBetData } from 'web/src/modules/registration/store/composables/useFreeBetData';
import { useGoToRouteName } from 'web/src/modules/registration/store/composables/useGoToRouteName';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import { useRegistrationErrors } from 'web/src/modules/registration/store/composables/useRegistrationErrors';
import { useRegistrationForms } from 'web/src/modules/registration/store/composables/useRegistrationForms';
import {
  SimpleRegistrationState,
} from 'web/src/modules/registration/submodules/simplified-registration/types';
import {
  getRegistrationTypeAppsFlyer,
} from 'web/src/modules/registration/utils/utils';

export const useSimplifiedRegistrationStore = defineStore('simplified-registration', () => {
  const registrationStage = useRegistrationStage();
  const regForms = useRegistrationForms();
  const regErros = useRegistrationErrors();
  const freeBetData = useFreeBetData();
  const routeGo = useGoToRouteName();
  const countdownTimer = useCountdownTimerManager();
  const errorConverter = useErrorsConverter();
  const router = useRouter();
  const isRegistrationComplete = useRegistrationComplete();

  const customerData = useCustomerDataStore();

  const login = toRef(() => customerData.login);
  const country = toRef(() => customerData.country);
  const redirectFrom = ref<string>('');

  const regState = ref<SimpleRegistrationState>(SimpleRegistrationState.ANONYM);
  const phoneCheckType = ref<PhoneCodeMethod>(PhoneCodeMethod.SMS);

  const analytics = useAnalytics();

  async function onLogoutReset(): Promise<void> {
    regErros.setRegistrationError('');
    regState.value = SimpleRegistrationState.ANONYM;
    regForms.setSubmittedPhone(false);
    registrationStage.resetStages();
    isRegistrationComplete.set(false);
    regForms.setSelectedRegistrationType(null);
    await regForms.resetRegistrationState();
  }

  function setCurrentRegistrationType(registrationType: CustomerRegistrationType): void {
    regForms.setSelectedRegistrationType(registrationType);
    regForms.setCurrentForm(regForms.selectedForm.value);
    regForms.resetCaptchaToken();
  }

  const registrationStep = computed<RegistrationFormStep>(() => {
    if (isEnumOfType(RegistrationFormStep)(regForms.registrationStep.value)) {
      return regForms.registrationStep.value;
    }
    return RegistrationFormStep.Initial;
  });

  function setPhoneCheckType(type: PhoneCodeMethod): void {
    phoneCheckType.value = type;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function onSubmitHandler(data: FormOutput, options: PrepareRegistrationInputOptions): Promise<void> {
    try {
      const regStepBeforeSubmit = registrationStep.value;
      await regForms.onSubmitHandler(data, options, registrationStage.stage.value);
      const regStepAfterSubmit = registrationStep.value;

      if (registrationStep.value === RegistrationFormStep.Sms) {
        setPhoneCheckType(PhoneCodeMethod.SMS);
      } else if (registrationStep.value === RegistrationFormStep.CallPasswordCode) {
        setPhoneCheckType(PhoneCodeMethod.CALL_PASSWORD_CODE);
      }
      if (registrationStep.value !== RegistrationFormStep.Initial
        && !regForms.hasCustomErrors.value
        && (regStepBeforeSubmit !== regStepAfterSubmit || registrationStage.stage.value === RegistrationStage.ChangePhone)
      ) {
        registrationStage.goToStage({ stage: RegistrationStage.PhoneCheck });
      }
      if (isRegistrationComplete.value) {
        if (!regForms.generatedPassword.value && regForms.submitResult.value === 'SUCCESS') {
          if (regForms.registrationType.value === CustomerRegistrationType.SIMPLE_EMAIL) {
            regState.value = SimpleRegistrationState.EMAIL_REGISTRATION_DONE;
          } else {
            regState.value = SimpleRegistrationState.AUTHORIZED;
          }
        } else if (regForms.generatedPassword.value) {
          registrationStage.goToStage({ stage: RegistrationStage.Success });
        }
      }
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);

      if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) {
        await regForms.resetRegistrationState();
        registrationStage.goToStage({ stage: RegistrationStage.Main, clearHistory: true });
      }

      if (handleDoRegistrationError({ error })) {
        return;
      }

      throw rawError;
    }
  }

  function handleDoRegistrationError({ error }: { error: BaseError }): boolean {
    const { message } = error;

    regErros.setRegistrationError(message);
    regErros.setRegistrationErrorCode(`${error.code}`);

    return regErros.handleDuplicateError(
      error,
      () => {
        void regForms.resetRegistrationState();

        if (process.env.VUE_APP_LAYOUT_DESKTOP) {
          void router.replace({ name: RouteName.LOGIN });
        } else {
          routeGo.goToRouteName(RouteName.LOGIN);
        }
      },
      () => {
        void regForms.resetRegistrationState();
        regForms.setRegFormCustomErrors({});
        const supportRoute = { name: RouteName.SUPPORT };
        if (registrationStage.stage.value === RegistrationStage.PhoneCheck) {
          void router.replace(supportRoute);
        } else {
          void router.push(supportRoute);
        }
      },
      () => {
        void onLogoutReset();
        // LEONWEB-11962 do not push from registration to registration (to avoid extra pages in history)
        regErros.setRegistrationErrorCode('');
        void router.replace({ name: RouteName.REGISTRATION });
        regState.value = SimpleRegistrationState.FORM_READY;
      },
    );
  }

  function setMetrikaPhoneCheck(action: string): void {
    analytics.push(AnalyticsEvent.Z_PHONE_CHECK, {
      registration: {
        [RegistrationTypeMetrika.BY_PHONE]: {
          phoneCheck: {
            [action]: true,
          },
        },
      },
    });
  }

  function setMetrikaRegistrationSuccess({
    registrationType,
  }: {
    registrationType: CustomerRegistrationType;
  }): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      void useAppsflyer().trackRegistration({
        login: login.value,
        method: getRegistrationTypeAppsFlyer(registrationType),
        country: country.value,
      });
    }
  }

  function setRedirectFrom(route: string): void {
    redirectFrom.value = route;
  }

  function setRegState(state: SimpleRegistrationState): void {
    regState.value = state;
  }

  return {
    ...registrationStage,
    ...regForms,
    ...regErros,
    ...freeBetData,
    ...countdownTimer,
    ...routeGo,
    registrationStep,
    redirectFrom,
    onSubmitHandler,
    phoneCheckType,
    setCurrentRegistrationType,
    regState,
    setRedirectFrom,
    setRegState,
    onLogoutReset,
    setMetrikaPhoneCheck,
    setMetrikaRegistrationSuccess,
  };
});
