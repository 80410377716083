<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import { VIcon } from '@components/v-icon';

import type { MultilineTextInputEmits, MultilineTextInputProperties, MultilineTextInputSlots } from '../../types';
import getInputIconSize from '../../utils/getInputIconSize';
import InputBorderWrapper from '../InputBorderWrapper';
import InputButton from '../InputButton';
import InputWrapper from '../InputWrapper';
import useMultilineTextInput from './useMultilineTextInput';

const props = withDefaults(defineProps<MultilineTextInputProperties>(), {
  name: 'MultilineTextInput',
  maxLength: 0,
  rowsMax: 0,
  rowsMin: 3,
});

const emits = defineEmits<MultilineTextInputEmits>();

defineOptions({
  inheritAttrs: false,
});

defineSlots<MultilineTextInputSlots>();

const { focusable, focus } = useAutofocus(props);

const {
  uniqueId,
  hintProps,
  hasError,
  rows,
  isEmpty,
  inputValue,
  isFocus,
  isHover,
  clickableSuffixIcon,
  onMouseOver,
  onMouseLeave,
  onFocus,
  onBlur,
  onChange,
  onInput,
  emitIconSuffixClick,
  setRowsOnMount,
} = useMultilineTextInput(props, emits, focusable);

defineExpose<FocusableInputRef>({
  focus,
});

const iconSize = getInputIconSize(props.isLarge);
</script>

<template>
  <InputWrapper v-auto-id="'MultilineTextInput'"
    v-data-test="{
      el: 'multiline-text-input',
      name: name,
    }"
    v-bind="hintProps"
    :input-id="uniqueId"
    :label="label"
    :is-hint-hidden="isHintHidden"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :is-large="isLarge"
    :class="{
      [$style['multiline-text-input']]: true,
      [$style['multiline-text-input--error']]: hasError,
      [$style['multiline-text-input--focus']]: isFocus,
      [$style['multiline-text-input--empty']]: isEmpty,
      [$style['multiline-text-input--large']]: isLarge,
    }"
    :clickable-suffix="clickableSuffixIcon"
  >
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="hasError"
      >
        <div
          v-if="readonly"
          :class="{
            [$style['multiline-text-input__input']]: true,
            [$style['multiline-text-input__input--with-suffix']]: !!suffixIconName,
          }"
        >
          {{ inputValue }}
        </div>
        <textarea
          v-else
          :id="uniqueId"
          ref="focusable"
          :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
          :value="inputValue"
          :name="name"
          :disabled="disabled"
          :placeholder="placeholder"
          :readonly="readonly"
          :rows="rows"
          :maxlength="maxLength || undefined"
          :class="$style['multiline-text-input__input']"
          @input.stop="onInput"
          @change.stop="onChange"
          @focus.stop="onFocus"
          @blur.stop="onBlur"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
          @vue:mounted="setRowsOnMount"
        />
      </InputBorderWrapper>
    </template>
    <template #iconSuffix>
      <slot
        name="iconSuffix"
        :is-focus="isFocus"
        :is-empty="isEmpty"
      >
        <template v-if="!!suffixIconName">
          <InputButton
            v-if="clickableIcon"
            :is-large="isLarge"
            :icon-name="suffixIconName"
            @click="emitIconSuffixClick"
          />
          <VIcon
            v-else
            :size="iconSize"
            :name="suffixIconName"
          />
        </template>
      </slot>
    </template>
  </InputWrapper>
</template>

<style lang="scss" module>
.multiline-text-input {
  @import '../../common';

  $self: &;

  &__input {
    @include inputCommon;
    @include inputMultiline;
    @include customScrollbar;

    display: block;
    resize: none;

    &::placeholder {
      color: $inputPlaceholderDefaultColor;
    }

    &:focus {
      &::placeholder {
        color: $inputPlaceholderFocusColor;
      }
    }

    #{$self}--error:not(#{$self}--focus) & {
      color: $inputValueErrorColor;

      &::placeholder {
        color: $inputPlaceholderErrorColor;
      }

      &:-webkit-autofill {
        -webkit-text-fill-color: $inputValueErrorColor;
      }
    }

    &--with-suffix {
      padding-right: 40px;
    }
  }

  &--large &__input {
    @include inputLarge;
    @include inputLargeMultiline;
  }
}
</style>
