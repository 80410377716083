enum RegistrationApiErrorCode {
  DUPLICATE_PHONE_NUMBER = 'DUPLICATE_PHONE_NUMBER',
  REG = 'REG',
  TECH = 'TECH',
  UNDER_MIN_BIRTH_YEAR = 'UNDER_MIN_BIRTH_YEAR',
  UNKNOWN = 'UNKNOWN',
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  INVALID_EMAIL = 'INVALID_EMAIL',
}

export default RegistrationApiErrorCode;
