import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import { getCustomerVerifyData } from '@leon-hub/api-sdk';
import {
  assert,
  isError,
  isString,
} from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import { useGraphqlClient } from '@core/app-rest-client';

import type {
  UploadDocumentResponse,
  UploadDocumentsInterface,
  VerifyDataDocument,
  VerifyDocumentReason,
} from 'web/src/modules/verification/services/api/types';
import useVerificationApi from 'web/src/modules/verification/services/api/useVerificationApi';

const useUploadDocumentsStore = defineStore('upload-documents', () => {
  const apiClient = useGraphqlClient();
  const { uploadDocument } = useVerificationApi();

  const result = ref<UploadDocumentResponse[]>([]);
  const verifyData = ref<VerifyDataDocument>({
    documentReasons: [],
    supportedFormats: [],
  });

  const maxFilesNumber = computed<string>(() => {
    if (verifyData.value.maxFilesNumber) {
      return verifyData.value.maxFilesNumber.toString();
    }

    return '';
  });

  const verifyReasons = computed<VerifyDocumentReason[]>(() => (
    verifyData.value.documentReasons.filter((reason) => !!reason)
  ));

  const maxFileSizeByExtension = computed<{ name: string; value: string }[]>(() => {
    if (verifyData.value.supportedFormats && verifyData.value.maxExtensionSize) {
      const map = new Map();

      const items = verifyData.value.maxExtensionSize
        .replace(/["{}]/g, '')
        .split(',')
        .map((element) => element.split(':'));

      for (const item of items) {
        map.set(item[0], item[1]);
      }

      const defaultSizeValue = map.get('*');

      return verifyData.value.supportedFormats.map((item) => {
        assert(isString(item), 'Unexpected type');
        if (map.has(item)) {
          const value = map.get(item);

          return { name: item, value };
        }
        return { name: item, value: defaultSizeValue };
      });
    }

    return [];
  });

  async function uploadDocuments({ files, reason, comment }: UploadDocumentsInterface): Promise<void> {
    const responses: UploadDocumentResponse[] = [];

    for (let index = 0, { length } = files; index < length; index += 1) {
      const file = files[index];

      try {
        const response = await uploadDocument({ file, reason, comment });
        responses.push(response);
      } catch (error) {
        assert(isError(error));
        logger.error('Failed to upload document', error);
        responses.push({ result: 'FAIL', errorStatus: 'FETCH_ERROR', msg: error.message });
      }
    }

    result.value = responses;
  }

  async function fetchVerifyData(): Promise<void> {
    const response = await getCustomerVerifyData(apiClient, (node) => node.queries.customer.getDocVerifyData, {});

    if (response.verifyData?.documentReasons) {
      verifyData.value = response.verifyData;
    }
  }

  function clearResultData(): void {
    result.value = [];
  }

  return {
    result,
    verifyData,
    maxFilesNumber,
    maxFileSizeByExtension,
    verifyReasons,
    fetchVerifyData,
    uploadDocuments,
    clearResultData,
  };
});

export default useUploadDocumentsStore;
