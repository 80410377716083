import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { SearchListItemProps } from 'web/src/modules/search/submodules/sportline/components/SearchListItem/types';
import type {
  SearchListSectionEmits,
  SearchListSectionProps,
} from 'web/src/modules/search/submodules/sportline/components/SearchListSection/types';
import type { SearchSportEvent } from 'web/src/modules/sportline/types';

interface SearchListSectionComposable {
  captionText: ComputedRef<string>;
  getItemProperties(event: SearchSportEvent): SearchListItemProps;
  emitItemClick(event: SearchSportEvent): void;
}

export default function useSearchListSection(
  props: RequiredField<SearchListSectionProps, 'list' | 'caption' | 'searchText'>,
  emits: SearchListSectionEmits,
): SearchListSectionComposable {
  const router = useRouter();

  const eventsCounter = computed(() => props.list.length);
  const captionText = computed(() => `${props.caption} (${eventsCounter.value})`);

  function getItemProperties(event: SearchSportEvent): SearchListItemProps {
    const { id, ...uiProps } = event;

    return {
      ...uiProps,
      href: getHref(event),
      searchText: props.searchText,
    };
  }

  function getHref(item: SearchSportEvent): string {
    return router.resolve({
      name: RouteName.SPORT_EVENT_DETAILS,
      params: { ...item.navigationParameters },
    }).href;
  }

  function emitItemClick(event: SearchSportEvent): void {
    emits('item-click', event);
  }

  return {
    captionText,
    getItemProperties,
    emitItemClick,
  };
}
