<script setup lang="ts">
import { IconName } from '@leon-hub/icons';

import { BadgeKind, VBadge } from '@components/badge';
import { SButton } from '@components/buttons';
import { ObjectFitOption, VImage } from '@components/image';

import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import EgsExclusiveBadge from 'web/src/modules/egs/components/EgsExclusiveBadge/EgsExclusiveBadge.vue';
import EgsFavorite from 'web/src/modules/egs/components/EgsFavorite/EgsFavorite.vue';
import EgsGameInfoOverlay from 'web/src/modules/egs/components/EgsGameInfoOverlay/EgsGameInfoOverlay.vue';
import EgsGameItemBottomBadge from 'web/src/modules/egs/components/EgsGameItemBottomBadge/EgsGameItemBottomBadge.vue';
import EgsJackpotAnimatedAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/EgsJackpotAnimatedAmount.vue';
import LobbyEgsGamesBadge from 'web/src/modules/lobby/components/LobbyEgsGamesBadge/LobbyEgsGamesBadge.vue';
import LobbyEgsGamesItemButtonsHover from 'web/src/modules/lobby/components/LobbyEgsGamesItemButtonsHover/LobbyEgsGamesItemButtonsHover.vue';

import type {
  LobbyEgsGamesItemImageEmits,
  LobbyEgsGamesItemImageProps,
} from './types';
import { useLobbyEgsGamesItemImage } from './composables';

const props = withDefaults(defineProps<LobbyEgsGamesItemImageProps>(), {});
const emit = defineEmits<LobbyEgsGamesItemImageEmits>();

const {
  isDemoAvailable,
  showPanel,
  imageProperties,
  betRange,
  onLazyImageMounted,
  onImageError,
  onImageLoaded,
} = useLobbyEgsGamesItemImage(props);
</script>

<template>
  <div v-auto-id="'LobbyEgsGamesItemImage'"
    :class="{
      [$style['lobby-egs-games-item-image']]: true,
      [$style['lobby-egs-games-item-image--square']]: isSquareImage,
      [$style['lobby-egs-games-item-image--jackpot']]: isJackpot,
    }"
  >
    <div :class="$style['lobby-egs-games-item-image__image-content']">
      <template v-if="item">
        <VImage
          v-bind="imageProperties"
          is-lazy
          show-loader
          :object-fit="ObjectFitOption.COVER"
          :class="$style['lobby-egs-games-item-image__image']"
          is-prerender-optimized
          @lazy-image-mounted="onLazyImageMounted"
          @error="onImageError"
          @image-loaded="onImageLoaded"
        />
        <div :class="$style['lobby-egs-games-item-image__badges']">
          <EgsExclusiveBadge
            v-if="'1' && item.exclusive"
            :class="$style['lobby-egs-games-item-image__exclusive']"
          />
          <EgsGameInfoOverlay
            :availability="item.availability"
            :badge="item.ntBadge"
          />
        </div>
        <div
          v-if="showPanel"
          :class="{
            [$style['lobby-egs-games-item-image__panel']]: true,
            [$style['lobby-egs-games-item-image__panel--small']]: isDescriptionHidden,
          }"
        >
          <VBadge
            v-if="item.isNew"
            :kind="BadgeKind.SQUARE_LIGHT"
            :label="$t('WEB2_FASTGAMES_LOBBY_BADGE_NEW')"
            :class="$style['lobby-egs-games-item-image__badge']"
          />
          <div
            :class="$style['lobby-egs-games-item-image__title']"
          >
            {{ item.name }}
          </div>
          <div
            v-if="!isDescriptionHidden"
            :class="$style['lobby-egs-games-item-image__desc']"
          >
            {{ item?.shortDescription || '' }}
          </div>
        </div>
        <div :class="$style['lobby-egs-games-item-image__provider-badge']">
          <LobbyEgsGamesBadge
            v-if="null && item.group.name && !isSquareImage"
            :text="item.exclusive ? $t('WEB2_ONLY_ON_SITE_NAME') : item.group.name"
            :is-origins="item.exclusive"
          />
        </div>
        <EgsGameItemBottomBadge
          v-if="!null && !isJackpot && (betRange || item.jackpot)"
          :text="betRange"
        >
          <template
            v-if="item.jackpot"
            #default
          >
            <EgsJackpotAnimatedAmount
              v-bind="item.jackpot.amount"
              has-background
              :jackpot-animation-duration="jackpotAnimationDuration"
              is-plain-text
            />
          </template>
        </EgsGameItemBottomBadge>
        <EgsJackpotAnimatedAmount
          v-if="isJackpot && item.jackpot"
          v-bind="item.jackpot.amount"
          has-background
          :jackpot-animation-duration="jackpotAnimationDuration"
          :class="$style['lobby-egs-games-item-image__jackpot']"
        />
        <LobbyEgsGamesItemButtonsHover
          v-if="'1' && !isJackpot && !isEditMode"
          :is-demo-available="isDemoAvailable"
          @click-play.stop.prevent="emit('click-play', $event)"
          @click-demo.stop.prevent="emit('click-demo', $event)"
          @click.stop.prevent="emit('click', $event)"
        />
        <div
          v-if="isEditMode"
          :class="$style['lobby-egs-games-item-image__edit']"
        >
          <SButton
            v-if="null"
            :icon-name="isRemoved ? IconName.SLOTT_HEART_OUTLINE : IconName.SLOTT_HEART"
            kind="primary"
          />
          <EgsFavorite
            v-else
            has-background
            :is-favorite="!isRemoved"
          />
        </div>
      </template>
      <template v-else>
        <VSkeletonImage />
      </template>
    </div>
  </div>
</template>

<style module lang="scss">
.lobby-egs-games-item-image {
  $self: &;

  position: relative;
  width: 100%;
  padding-top: $lobbyEgsGameDefaultImagePaddingTop;

  &--square {
    padding-top: 100%;
  }

  &--jackpot {
    padding-top: 50%;
  }

  &__image-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    border-radius: $lobbyEgsGameBorderRadius;
    isolation: isolate;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.5s ease;
  }

  &__badges {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: inline-flex;
    column-gap: 2px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 4px;
    pointer-events: none;
  }

  &__panel {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 12px 16px;
    color: var(--White);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 93.75%);

    &--small {
      padding: 12px;

      #{$self}__title {
        @include medium\14\20\025;
      }
    }
  }

  &__badge {
    margin-bottom: 6px;
  }

  &__title {
    @include medium\24\28;
  }

  &__desc {
    @include regular\14\20\025;

    min-height: 42px;
    margin: 6px 0;
    opacity: 0.7;
  }

  &__provider-badge {
    position: absolute;
    right: 0;
    bottom: 8px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__jackpot {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }

  &__edit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $lobbyEgsGameEditBackground;
  }

  &__exclusive {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &:hover {
    #{$self}__image {
      transform: $lobbyEgsGameHoverImageTransform;
    }
  }
}
</style>
