import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { PlayTimeControlReturnType } from '@leon-hub/api-sdk';
import { doDeactivateTimer, doPlayTimeControl, playTimeControl } from '@leon-hub/api-sdk';
import { AlertIconName } from '@leon-hub/icons';

import type { ShowDialogOptions } from '@core/dialogs';
import { useGraphqlClient } from '@core/app-rest-client';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n, useLocalizedTimeString } from '@core/i18n';
import { useCurrency, useFormatMoney } from '@core/money';
import { useUserStore } from '@core/user';

import { ButtonKind } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';

import type { PlayTimeTimerEndedNotification } from 'web/src/modules/front-notifications/types';
import type { InGameData } from 'web/src/modules/profile/submodules/responsible-gambling/store/types';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import useAppFullscreenStore from 'web/src/modules/core/store/useAppFullscreenStore';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import { InGameStepEnum } from 'web/src/modules/profile/submodules/responsible-gambling/store/enums';
import normalizeTime from 'web/src/modules/profile/utils/normalizeTime';

const useResponsibleGamblingStoreV2 = defineStore('responsible-gambling-v2', () => {
  const api = useGraphqlClient();

  const frontNotificationsStore = useFrontNotificationsStore();
  const playTimeEndNotification = toRef(frontNotificationsStore, 'playTimeEndNotification');
  const userStore = useUserStore();
  const { $translate } = useI18n();
  const { showDialog, closeDialog } = useDialogs();
  const { getTimeString } = useLocalizedTimeString();
  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();
  const { setFullscreenMode } = useAppFullscreenStore();

  /** State */
  const timeInGameStep = ref<InGameStepEnum>(InGameStepEnum.INITIAL);
  const timeInGameTimerSetupData = ref<Maybe<InGameData>>(null);
  const timeInGameTimerData = ref<PlayTimeControlReturnType>(null);
  const timeInGameLoadingState = ref<boolean>(false);
  const timeInGameNotificationIsVisible = ref<boolean>(false);
  const depositStep = ref<StepName>(StepName.INFO);
  const betStep = ref<StepName>(StepName.INFO);

  /** Mutations */
  function timeInGameSetNotificationIsVisible(value: boolean): void {
    timeInGameNotificationIsVisible.value = value;
  }
  function timeInGameSetStep(value: InGameStepEnum): void {
    timeInGameStep.value = value;
  }
  function timeInGameSetData(value: Maybe<InGameData>): void {
    timeInGameTimerSetupData.value = value;
  }

  function setDepositStep(value: StepName): void {
    depositStep.value = value;
  }
  function setBetStep(value: StepName): void {
    betStep.value = value;
  }

  async function timeInGameTimerActivate(options: { hours: number; minutes: number }): Promise<void> {
    timeInGameLoadingState.value = true;
    return doPlayTimeControl(api, (node) => node.mutations.playTimeControl.setTimer, {
      options,
    }, { silent: true })
      .then((response) => {
        timeInGameTimerData.value = response;
      })
      .finally(() => {
        timeInGameLoadingState.value = false;
      });
  }

  async function timeInGameTimerDeactivate(): Promise<void> {
    timeInGameLoadingState.value = true;
    return doDeactivateTimer(api, (node) => node.mutations.playTimeControl.deactivateTimer, {
      options: {},
    }, { silent: true })
      .then(() => {
        timeInGameTimerData.value = null;
      })
      .finally(() => {
        timeInGameLoadingState.value = false;
      });
  }

  async function timeInGameTimerContinue(): Promise<void> {
    timeInGameLoadingState.value = true;
    return playTimeControl(api, (node) => node.queries.playTimeControl.getState, {
      options: {},
    }, { silent: true })
      .then((response) => timeInGameTimerActivate({
        hours: response.state?.hours || 0,
        minutes: response.state?.minutes || 0,
      }))
      .finally(() => {
        timeInGameLoadingState.value = false;
      });
  }

  async function timeInGameGetState(): Promise<void> {
    timeInGameLoadingState.value = true;
    return playTimeControl(api, (node) => node.queries.playTimeControl.getState, {
      options: {},
    }, { silent: true })
      .then((response) => {
        timeInGameTimerData.value = response;
      })
      .finally(() => {
        timeInGameLoadingState.value = false;
      });
  }

  const showGameTimeEnd = (data: PlayTimeTimerEndedNotification) => {
    if (!timeInGameNotificationIsVisible.value) {
      timeInGameSetNotificationIsVisible(true);

      const hours = computed(() => {
        const [h] = normalizeTime(data.hours, data.minutes);
        return { count: `${h}` };
      });
      const minutes = computed(() => {
        const [, m] = normalizeTime(data.hours, data.minutes);
        return { count: `${m}` };
      });
      const timeName = getTimeString(hours, minutes);

      const params = computed(() => ({
        time: `${timeName?.hours.toLowerCase()} ${timeName?.minutes.toLowerCase()}`,
        sum: formatMoney(Math.abs(data.overallProfit || 0), { currency: data.currency || currency.value }),
      }));

      const resultMessage = computed(() => {
        if (!Number(data.overallProfit) || Number(data.overallProfit) >= 0) {
          return $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_WIN', params).value;
        }

        return $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_LOSS', params).value;
      });

      const config: ShowDialogOptions = {
        presetName: PresetName.CONFIRM,
        options: {
          ...getJumbotronProps({
            alertIcon: AlertIconName.Rest,
          }),
          width: ModalWidth.SMALL,
          title: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_TITLE').value,
          confirmMessage: `${$translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_DESCRIPTION', params).value} ${resultMessage.value}`,
          buttons: [
            {
              label: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_BUTTONS_CONTINUE').value,
              kind: ButtonKind.PRIMARY,
              action: DialogAction.MODAL_CLOSE,
            },
            {
              label: $translate('RG_TIME_IN_GAME_NOTIFICATION_TIME_END_BUTTON_EXIT').value,
              action: DialogAction.CONFIRM,
              kind: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'tertiary-primary' : ButtonKind.TRANSPARENT,
            },
          ],
        },
      };

      if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        config.options.image = {

          src: require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/alarm.svg`),
        };
      }

      setFullscreenMode(false);

      const { id, subscribe } = showDialog(config);

      subscribe({
        [DialogAction.CONFIRM]: () => {
          timeInGameSetNotificationIsVisible(false);
          void userStore.logout();
        },
        [DialogAction.MODAL_CLOSE]: () => {
          timeInGameSetNotificationIsVisible(false);
          void timeInGameTimerContinue();
          closeDialog(id);
        },
      });
    }
  };

  function init(): Promise<void> {
    return Promise.resolve();
  }

  watch(playTimeEndNotification, (newValue) => {
    if (newValue) {
      showGameTimeEnd(newValue);
    }
  }, {
    immediate: true,
  });

  return {
    init,
    timeInGameStep,
    timeInGameTimerSetupData,
    timeInGameTimerData,
    timeInGameLoadingState,
    timeInGameNotificationIsVisible,
    depositStep,
    betStep,
    timeInGameSetStep,
    timeInGameSetData,
    timeInGameTimerActivate,
    timeInGameTimerDeactivate,
    timeInGameTimerContinue,
    timeInGameGetState,
    timeInGameSetNotificationIsVisible,
    setDepositStep,
    setBetStep,
  };
});

export default useResponsibleGamblingStoreV2;
