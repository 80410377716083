<script setup lang="ts">
import { ref } from 'vue';

import { VCountryFlag, VFlagIcon } from '@components/country-flag';

import type {
  DropdownWrapperEmits,
  DropdownWrapperExposed,
  DropdownWrapperProps,
  DropdownWrapperSlots,
} from '../types';
import DropdownSelectIcon from './DropdownSelectIcon.vue';

withDefaults(defineProps<DropdownWrapperProps>(), {
  selectedValue: '',
  selectedLabel: '',
  placeholder: '',
});

const emit = defineEmits<DropdownWrapperEmits>();

defineSlots<DropdownWrapperSlots>();
const button = ref<HTMLButtonElement | undefined>();
const submit = ref<HTMLButtonElement | undefined>();

defineExpose<DropdownWrapperExposed>({
  button,
  submit,
});
</script>

<template>
  <div v-auto-id="'DropdownWrapper'"
    :class="{
      [$style['dropdown-wrapper']]: true,
      [$style['dropdown-wrapper--open']]: isDropdownShown,
    }"
  >
    <DropdownSelectIcon
      v-if="!disabled"
      :is-open="isDropdownShown"
      :is-large="isLarge"
    />
    <button
      ref="button"
      :disabled="disabled"
      :autofocus="autofocus"
      type="button"
      :class="{
        [$style['dropdown-wrapper__button']]: true,
        [$style['dropdown-wrapper__button--icon-suffix']]: true,
        [$style['dropdown-wrapper__button--icon-prefix']]: hasIconPrefix || (isCountrySelector && selectedValue),
        [$style['dropdown-wrapper__button--hidden']]: isSearchActive,
        [$style['dropdown-wrapper__button--empty']]: isEmpty && !isSearchActive,
        [$style['dropdown-wrapper__button--error']]: hasError,
        [$style['dropdown-wrapper__button--large']]: isLarge,
      }"
      @focus="emit('focus')"
      @blur="emit('blur')"
      @click="emit('click')"
      @keydown="emit('keydown', $event)"
      @mouseover="emit('mouseover', $event)"
      @mouseleave="emit('mouseleave', $event)"
    >
      <span
        v-data-test="{
          el: 'dropdown-select-selected-label',
          value: selectedValue,
        }"
        :class="$style['dropdown-wrapper__button-content']"
      >
        <template v-if="isCountrySelector && selectedValue">
          <VFlagIcon
            v-if="null"
            :country-code="selectedValue"
            :class="$style['dropdown-wrapper__flag']"
          />
          <VCountryFlag
            v-else
            :name="selectedValue"
            :class="$style['dropdown-wrapper__flag']"
          />
        </template>{{ selectedLabel || placeholder }}
      </span>
    </button>
    <slot name="search" />
    <button
      ref="submit"
      type="submit"
      inert
      hidden
    />
    <slot />
  </div>
</template>

<style lang="scss" module>
.dropdown-wrapper {
  @import 'web/src/components/Input/common';

  position: relative;
  z-index: 1;

  &__button {
    $self: &;

    @include inputCore;

    position: relative;
    text-align: left;
    cursor: pointer;
    caret-color: $caretColor;
    user-select: $userSelect;
    border: none;

    &:disabled {
      color: $inputValueDisabledColor;
      cursor: default;
      opacity: 1;
      -webkit-text-fill-color: $inputValueDisabledColor;
    }

    // placeholder
    &--empty {
      color: $inputPlaceholderDefaultColor;
    }

    &--error {
      color: $inputValueErrorColor;
    }

    &--icon-prefix {
      padding-left: $inputIconWidth;
    }

    &--icon-prefix#{$self}--large {
      padding-left: $inputIconWidthLarge;
    }

    &--icon-suffix {
      padding-right: $inputIconWidth;
    }

    &--icon-suffix#{$self}--large {
      padding-right: $inputIconWidthLarge;
    }

    // placeholder error
    &--empty#{$self}--error & {
      color: $inputPlaceholderErrorColor;
    }

    &--hidden {
      visibility: hidden;
    }

    &--large {
      @include inputLarge;
    }
  }

  &__button-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
  }

  &__flag {
    position: absolute;
    top: $selectFlagPositionTop;
    left: $selectFlagPositionLeft;
  }
}
</style>
