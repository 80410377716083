import { assert, isArray } from '@leon-hub/guards';

import type { FormUiSchema } from 'web/src/components/Form';
import type { FormData } from 'web/src/components/Form/types';
import {
  isFormDataValue,
  isPartialFormPhoneValue,
} from 'web/src/components/Form/guards';

function fillSubmittedData<UiSchema extends FormUiSchema<any> = FormUiSchema>(formToFill: UiSchema, submittedData: FormData | null, availableToFillFields: string[]): void {
  if (submittedData === null
    || !formToFill?.fields
    || !availableToFillFields
  ) {
    return;
  }

  const { fields } = formToFill;

  assert(!!fields, 'fields must be present');

  for (const fieldType of availableToFillFields) {
    if (fieldType in fields && fieldType in submittedData) {
      const submittedValue = submittedData[fieldType];
      const uiSchemaField = fields[fieldType];
      if (
        'default' in uiSchemaField
        && isFormDataValue(submittedValue)
        && !isArray<File>(submittedValue)
      ) {
        uiSchemaField.default = submittedValue;
        if (isPartialFormPhoneValue(submittedValue)) {
          fields.prefix = { default: submittedValue.prefix, ...submittedValue };
          fields.suffix = { default: submittedValue.suffix, ...submittedValue };
        }
      }
    }
  }
}

export default fillSubmittedData;
