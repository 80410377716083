<script setup lang="ts">
import type { BaseInputEmits, InputWrappedProps } from '@leon-hub/input-types';
import { TextInputTypes } from '@leon-hub/input-types';

import type { LogoName } from 'web/src/components/Logo/VLogo/enums';
import { TextInput } from 'web/src/components/Input';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import VLogo from 'web/src/components/Logo/VLogo/VLogo.vue';
import useCardNumberInput
  from 'web/src/modules/payments/form-widgets/FormCardNumber/components/CardNumberInput/composables/useCardNumberInput';

const props = defineProps<InputWrappedProps & { selectedCardTypeLogo?: LogoName }>();
const emit = defineEmits<BaseInputEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseInputEvents(emit);

const {
  logoName,
  onInput,
  maskOptions,
} = useCardNumberInput(props, emitInput);
</script>

<template>
  <div v-auto-id="'CardNumberInput'" :class="$style['card-number-input']">
    <TextInput
      v-bind="props"
      :autofocus="autofocus"
      :error="error"
      :mask="maskOptions"
      is-ym-disable-keys
      :type="TextInputTypes.TEL"
      autocomplete="cc-number"
      @input="onInput"
      @change="emitChange"
      @focus="emitFocus"
      @blur="emitBlur"
    >
      <template #iconSuffix>
        <VLogo
          v-if="logoName"
          :name="logoName"
          :class="$style['card-number-input__logo']"
        />
      </template>
    </TextInput>

    <div
      v-if="selectedCardTypeLogo"
      :class="$style['card-number-input__logo-wrapper']"
    >
      <VLogo
        :name="selectedCardTypeLogo"
        :class="$style['card-number-input__logo-image']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.card-number-input {
  position: relative;
  width: 100%;

  &__logo {
    align-self: center;
    width: 32px;
    height: 20px;
    margin-right: 12px;

    &-wrapper {
      position: absolute;
      top: 18px;
      right: 0;
      z-index: 2;
      display: inline-flex;
      width: 32px;
      height: 44px;
      margin-right: 39px;
    }

    &-image {
      align-self: center;
      width: 32px;
      height: 20px;
    }
  }
}
</style>
