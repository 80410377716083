import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import type { BaseError } from '@leon-hub/errors';
import type { FormSchema } from '@leon-hub/form-utils';
import { GqlApiBatchedSubRequestError } from '@leon-hub/api';
import {
  CustomerFieldType,
  InvalidPhoneExceptionCode,
} from '@leon-hub/api-sdk';
import {
  FormControlType,
  FormErrorKeyword,
} from '@leon-hub/form-utils';
import {
  assert,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { sleep } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { ShowDialogOptions } from '@core/dialogs';
import { useAnalytics } from '@core/analytics';
import { useCountryStore } from '@core/country';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';
import { useUserStore } from '@core/user';

import type {
  FormOutput,
  FormUiSchema,
} from 'web/src/components/Form';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import type {
  FormData,
  FormExternalErrors,
} from 'web/src/components/Form/types';
import type {
  RegistrationCompletionRoutePageEmits,
  RegistrationCompletionRoutePageProps,
} from 'web/src/modules/registration-completion/pages/RegistrationCompletionRoutePage/types';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { isUIFormSchema } from 'web/src/components/Form/guards';
import { mapFormData } from 'web/src/components/Form/utils';
import { SimpleRegCompletionRouteParametersHandler } from 'web/src/modules/core/services/router/navigation-guards/registration-completion/utils';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';

enum UpdateIncompleteFieldsErrorCode {
  MODIFICATION_BLOCKED_EMAIL = 'MODIFICATION_BLOCKED_EMAIL',
  MODIFICATION_BLOCKED_MOBILE = 'MODIFICATION_BLOCKED_MOBILE',
}

export interface RegistrationCompletionRouteComponentComposable {
  schema: Ref<FormSchema | undefined>;
  uiSchema: Ref<FormUiSchema | undefined>;
  form: Ref<VFormRef | undefined>;
  descriptionText: Ref<string>;
  isAsoTerms: Ref<boolean>;
  isExAllowedCountry: Ref<boolean>;
  isLoading: Ref<boolean>;
  isPending: Ref<boolean>;
  customErrors: Ref<FormExternalErrors | undefined>;
  onFormMounted(): void;
  validateForm(): void;
  goHome(): void;
  onSubmit(data: FormOutput): Promise<void>;
  onChange(): void;
}

export function useRegistrationCompletionRouteComponent(
  props: RegistrationCompletionRoutePageProps,
  emit: RegistrationCompletionRoutePageEmits,
): RegistrationCompletionRouteComponentComposable {
  const { $translate } = useI18n();
  const router = useRouter();
  const analytics = useAnalytics();
  const { showDialog } = useDialogs();
  const { convertToBaseError } = useErrorsConverter();

  const isLoading = ref(false);
  const isPending = ref(false);
  const customErrors = ref<FormExternalErrors>();

  const remainingDelay = toRef(useSiteConfigStore(), 'millisToSleepAfterCountryChange');
  const registrationCompletionStore = useRegistrationCompletionStore();
  const geoIpCountryCode = toRef(useCountryStore(), 'geoIpCountryCode');
  const { fetchIncompleteRegistrationFields, updateIncompleteFields } = registrationCompletionStore;
  const preparedFormSchemas = toRef(registrationCompletionStore, 'preparedFormSchemas');
  const storeUiSchema = toRef(registrationCompletionStore, 'uiSchema');
  const { loadCustomerData } = useUserStore();
  const pinCodeStore = usePinCodeStore();
  const showCompleteRegistrationFields = toRef(pinCodeStore, 'showCompleteRegistrationFields');
  const pinCodeStep = toRef(pinCodeStore, 'step');

  const schema = computed<FormSchema | undefined>(() => preparedFormSchemas.value?.schema);

  const customerAction = computed<CustomerCompletionAction | undefined>(() => (
    props.manualCustomerAction ?? router.getParam<CustomerCompletionAction>('customerAction')
  ));

  const submitButtonLabel = computed<string>(() => {
    if (process.env.VUE_APP_FEATURE_ASO_ENABLED && customerAction.value === CustomerCompletionAction.AsoTerms) {
      return $translate('WEB2_CONTINUE').value;
    }
    if (customerAction.value === CustomerCompletionAction.ForbiddenCountry) {
      return $translate('WEB2_REGISTRATION_COMPLETION_CONFIRM').value;
    }
    return $translate('WEB2_REGISTRATION_COMPLETION_SAVE').value;
  });

  const uiSchema = computed<FormUiSchema | undefined>(() => {
    if (!preparedFormSchemas.value) {
      return undefined;
    }

    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();
    return {
      ...preparedFormSchemas.value.uiSchema,
      submitButton: {
        label: submitButtonLabel.value,
      },
      validatorErrorPatterns: {
        byWidget: {
          ...phoneErrorPatterns.value,
          [FormControlType.AsoTermsCheckbox]: {
            [FormErrorKeyword.Required]: $translate('WEB2_REGISTRATION_FORM_ERROR_REQUIRED_DEFAULT').value,
          },
        },
      },
    };
  });

  const descriptionText = computed<string>(() => {
    switch (customerAction.value) {
      case CustomerCompletionAction.ExAllowedCountry:
        return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_EXCLUDED_COUNTRY').value;
      case CustomerCompletionAction.ForbiddenCountry:
        return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_FORBIDDEN_COUNTRY').value;
      case CustomerCompletionAction.Withdrawal:
        return $translate('WEB2_REGISTRATION_DESCRIPTION_WITHDRAWAL').value;
      case CustomerCompletionAction.Deposit:
        return $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION_DEPOSITS').value;
      default:
        return props.description || $translate('WEB2_REGISTRATION_COMPLETION_DESCRIPTION').value;
    }
  });

  const isAsoTerms = computed<boolean>(() => customerAction.value === CustomerCompletionAction.AsoTerms);
  const isExAllowedCountry = computed<boolean>(() => customerAction.value === CustomerCompletionAction.ExAllowedCountry);

  async function fetchFields(): Promise<void> {
    if (customerAction.value) {
      isLoading.value = true;
      await fetchIncompleteRegistrationFields({
        customerAction: customerAction.value,
        paymentSystemId: router.getParam<string>('paymentSystemId'),
      });
      isLoading.value = false;
    }
  }

  function showError(error: string | undefined, errorCode: string | undefined): void {
    if (!error) {
      return;
    }

    const modalProperties: ShowDialogOptions = {
      presetName: PresetName.ALERT_WARNING,
      options: {
        title: $translate('JS_CAPTION_ATTENTION').value,
        confirmMessage: error,
      },
    };

    if (errorCode === UpdateIncompleteFieldsErrorCode.MODIFICATION_BLOCKED_EMAIL
      || errorCode === UpdateIncompleteFieldsErrorCode.MODIFICATION_BLOCKED_MOBILE) {
      modalProperties.presetName = PresetName.CONFIRM;
      modalProperties.options = {
        title: $translate('WEB2_MODIFICATION_BLOCKED_TITLE').value,
        confirmMessage: errorCode === UpdateIncompleteFieldsErrorCode.MODIFICATION_BLOCKED_EMAIL
          ? $translate('WEB2_MODIFICATION_BLOCKED_EMAIL_TXT').value
          : $translate('WEB2_MODIFICATION_BLOCKED_MOBILE_TXT').value,
        buttons: [
          {
            label: $translate('WEB2_HELP_BUTTON_IN_MODAL').value,
          },
          {
            label: $translate('WEB2_MODIFICATION_BLOCKED_CLOSE_BTN').value,
          },
        ],
      };
    }

    showDialog(modalProperties).subscribe({
      [DialogAction.CONFIRM]: () => {
        void router.push({
          name: RouteName.SUPPORT,
        });
      },
      [DialogAction.MODAL_CLOSE]: () => {
        if (pinCodeStep.value) {
          pinCodeStore.setShowCompleteRegistrationFields(true);
        }
      },
    });
  }

  function handleSubmitError(error: BaseError, formData: FormData): void {
    if (error instanceof GqlApiBatchedSubRequestError && storeUiSchema.value) {
      assert(isUIFormSchema(storeUiSchema.value));
      if (error.code.equals(RegistrationApiErrorCode.DUPLICATE_EMAIL)) {
        customErrors.value = updateFieldErrorByFormError(
          formData,
          CustomerFieldType.EMAIL,
          storeUiSchema.value,
          extractCustomerFormErrors(storeUiSchema.value, formData),
          error.message,
        );
      }
      if (error.code.equals(InvalidPhoneExceptionCode.INVALID_PHONE)) {
        customErrors.value = updateFieldErrorByFormError(
          formData,
          CustomerFieldType.PHONE_INPUT,
          storeUiSchema.value,
          extractCustomerFormErrors(storeUiSchema.value, formData),
          error.message,
        );
      }
    }

    if (!customErrors.value) {
      analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
        registration: {
          error: {
            message: error.message,
            code: error.code,
          },
        },
      });

      showError(error.message, `${error.code}`);
    }
  }

  async function makeSubmitRequest(formData: FormData): Promise<void> {
    isPending.value = true;
    customErrors.value = undefined;

    const { registrationForm, result } = await updateIncompleteFields(formData);
    const chosenCountryCode = mapFormData(formData)[0].value;
    const redirectLocation = SimpleRegCompletionRouteParametersHandler.getRedirectLocation();

    if (result === 'OK') {
      if (chosenCountryCode !== geoIpCountryCode.value && remainingDelay.value > 0) {
        await sleep(remainingDelay.value);
      }

      await loadCustomerData(true);

      if (registrationForm.hasFieldsErrors) {
        assert(isUIFormSchema(registrationForm.uiFormSchema));
        customErrors.value = updateFieldErrorByFormError(
          formData,
          CustomerFieldType.PHONE_INPUT,
          registrationForm.uiFormSchema,
          extractCustomerFormErrors(registrationForm.uiFormSchema, formData),
        );
        return;
      }

      if (redirectLocation && customerAction.value !== CustomerCompletionAction.CreatePinCode) {
        SimpleRegCompletionRouteParametersHandler.setRedirectLocation(undefined);
        void router.replace(redirectLocation);
        return;
      }

      if (!showCompleteRegistrationFields.value) {
        SimpleRegCompletionRouteParametersHandler.setRedirectLocation(undefined);
        void router.replace({
          name: RouteName.HOME,
        });
        return;
      }
      SimpleRegCompletionRouteParametersHandler.setRedirectLocation(undefined);
    }

    emit('after-submit');
  }

  async function onSubmit(data: FormOutput): Promise<void> {
    const { formData, customErrors: formCustomErrors, errors } = data;
    const hasAnyErrors = (errors !== null && errors.size > 0) || (formCustomErrors !== null && formCustomErrors.size > 0);

    if (!hasAnyErrors) {
      try {
        await makeSubmitRequest(formData);
      } catch (error) {
        handleSubmitError(convertToBaseError(error), formData);
      } finally {
        if (customerAction.value === CustomerCompletionAction.ForbiddenCountry && remainingDelay.value > 0) {
          setTimeout(() => {
            isPending.value = false;
          }, remainingDelay.value);
        } else {
          isPending.value = false;
        }
      }
    }
  }

  const form = ref<VFormRef>();

  function onFormMounted(): void {
    form.value?.focus();
  }

  function validateForm(): void {
    form.value?.showValidationErrors();
  }

  function onChange(): void {
    customErrors.value = undefined;
  }

  function goHome(): void {
    void router.push({ name: RouteName.HOME });
  }

  onBeforeMount(() => {
    if (!schema.value) {
      void fetchFields();
    }
  });

  watch(customerAction, fetchFields);

  return {
    schema,
    uiSchema,
    form,
    descriptionText,
    isAsoTerms,
    isExAllowedCountry,
    isLoading,
    isPending,
    customErrors,
    onFormMounted,
    validateForm,
    goHome,
    onSubmit,
    onChange,
  };
}
