import type {
  Ref,
} from 'vue';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  toRef,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import { useI18n } from '@core/i18n';

import type { EgsGame, EgsGroup } from 'web/src/modules/egs/types';
import type {
  CasinoSearchContentEmits,
  CasinoSearchContentProps,
} from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/types';
import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';
import { filterBlockedGroups } from 'web/src/modules/egs/utils';
import { useSearchCasinoStore } from 'web/src/modules/search/submodules/casino/store';

interface CasinoSearchContentComposable {
  isNoSearchResults: Ref<boolean>;
  noResultsText: Ref<string>;
  isLoading: Ref<boolean>;
  hasSearchResults: Ref<boolean>;
  replaceGameRoute: Ref<boolean>;
  searchText: Ref<string>;
  games: Ref<EgsGame[]>;
  groups: Ref<EgsGroup[]>;
  isMoreLoadingAvailable: Ref<boolean>;
  onGameClick(game: EgsGame): void;
  onLoadMore(): Promise<void>;
  onLobbyGameClicked(): void;
  onInputClear(): void;
  onInput(value: string): void;
}

export default function useCasinoSearchContent(
  props: CasinoSearchContentProps,
  emits: CasinoSearchContentEmits,
): CasinoSearchContentComposable {
  let isGameClicked = false;
  let isInputTouched = false;
  const router = useRouter();
  const route = useRoute();

  const { $translate } = useI18n();
  const searchCasinoStore = useSearchCasinoStore();
  const egsNavigation = useEgsNavigation(router);
  const gamesRef = toRef(searchCasinoStore, 'games');
  const groupsRef = toRef(searchCasinoStore, 'groups');
  const isLoading = toRef(searchCasinoStore, 'isSearchInProgress');
  const searchText = toRef(searchCasinoStore, 'searchText');
  const isNoSearchResultsRef = toRef(searchCasinoStore, 'isNoSearchResults');
  const isSearchAvailable = toRef(searchCasinoStore, 'isSearchAvailable');
  const isMoreLoadingAvailable = toRef(searchCasinoStore, 'isMoreLoadingAvailable');

  const games = computed<EgsGame[]>(() => gamesRef.value ?? []);
  const groups = computed<EgsGroup[]>(() => filterBlockedGroups(groupsRef.value));
  const isNoSearchResults = computed(() => !isSearchAvailable.value || isNoSearchResultsRef.value);
  const hasSearchResults = computed(() => !isLoading.value && !isNoSearchResultsRef.value);
  const noResultsText = computed(() => (!isSearchAvailable.value
    ? $translate('WEB2_SLOTS_SEARCH_BY').value
    : $translate('JAVA_SEARCH_NORESULTS').value));

  // eslint-disable-next-line ts/prefer-nullish-coalescing
  const replaceGameRoute = computed(() => props.shouldReplaceItemRoutes || isCasinoGameRouteName(route.name));

  onMounted(() => {
    emitSearchText(searchText.value);
    emitFocus();
  });

  onBeforeUnmount(() => {
    if (!isGameClicked) {
      if (isInputTouched) {
        searchCasinoStore.sendAnalytics(false);
      }

      searchCasinoStore.setSearchText('');
    }
  });

  function onGameClick(game: EgsGame): void {
    isGameClicked = true;
    emitItemClick();
    searchCasinoStore.sendAnalytics(true);

    void egsNavigation.navigateToGamePage(game, undefined, replaceGameRoute.value);
  }

  function onLobbyGameClicked(): void {
    isGameClicked = true;
    emitItemClick();
  }

  async function onLoadMore(): Promise<void> {
    await searchCasinoStore.fetchMoreGames();
  }

  function onInput(value: string): void {
    emitSearchText(value);
    isInputTouched = true;
    searchCasinoStore.setSearchText(value);
  }

  function onInputClear(): void {
    isInputTouched = false;
    searchCasinoStore.sendAnalytics(false);
    onInput('');
  }

  function emitFocus(): void {
    emits('focus');
  }

  function emitSearchText(value: string): void {
    emits('search-text', value);
  }

  function emitItemClick(): void {
    emits('item-click');
  }

  return {
    isNoSearchResults,
    noResultsText,
    isLoading,
    hasSearchResults,
    searchText,
    games,
    groups,
    isMoreLoadingAvailable,
    replaceGameRoute,
    onGameClick,
    onLoadMore,
    onLobbyGameClicked,
    onInputClear,
    onInput,
  };
}
