import type { VInputFileEvent } from '@leon-hub/input-types';
import { InputEventType } from '@leon-hub/input-types';

import { inputFileEventToFormDataEvent } from 'web/src/components/Form/utils';

import type { FileFormWidgetsEmits } from '../types';

interface UseFormFileSingleEvents {
  emitChange(event: VInputFileEvent): void;
  emitFocus(event: VInputFileEvent): void;
  emitBlur(event: VInputFileEvent): void;
}

function useFormFileSingleEvents(emit: FileFormWidgetsEmits): UseFormFileSingleEvents {
  const emitChange = (event: VInputFileEvent): void => {
    emit(InputEventType.CHANGE, inputFileEventToFormDataEvent(event));
  };

  const emitFocus = (event: VInputFileEvent): void => {
    emit(InputEventType.FOCUS, inputFileEventToFormDataEvent(event));
  };

  const emitBlur = (event: VInputFileEvent): void => {
    emit(InputEventType.BLUR, inputFileEventToFormDataEvent(event));
  };

  return {
    emitChange,
    emitFocus,
    emitBlur,
  };
}

export default useFormFileSingleEvents;
