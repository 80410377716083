import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import type {
  EventsChangesFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
  LeagueFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

import type { PendingGetEventsInput } from './useSportlinePendingApi';
import { useSportlinePendingApi } from './useSportlinePendingApi';

interface SportlineApiLoadLeagueEventsComposable {
  loadLeagueEvents(
    options: LeagueFetchOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

export default function useSportlineApiLoadLeagueEvents(
  props: UseSportlineApiLoadEventsProps,
): SportlineApiLoadLeagueEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const {
    loadEvents: loadLeagueEvents,
  } = useSportlinePendingApi<LeagueFetchOptions>({
    tryRequestWithCTag,
    getEventsInput: ({ leagueId, silent }): PendingGetEventsInput => ({
      betline: BetlineType.All,
      leagueId,
      hideClosed: true,
      silent,
      flags: [...defaultRestApiFlags.value],
      LMCE: isLiveOutrightsEnabled.value,
    }),
    getPendingParams: ({ vTag, leagueId }) => ({
      vTag,
      leagueId,
      betlines: [BetlineType.All],
    }),
  });

  return {
    loadLeagueEvents,
  };
}
