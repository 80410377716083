import {
  isObject,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  LiveChatBaseResponse,
  LiveChatCheckData,
  LiveChatConversationItem,
  LiveChatErrorResponse,
  LiveChatGetData,
  LiveChatOpenData,
  LiveChatStartData,
} from './types';
import {
  LiveChatMessageType,
} from './enums';

export function isLiveChatBaseResponse(argument: unknown): argument is LiveChatBaseResponse {
  return isObject(argument) && typeof argument.result === 'string' && ['OK', 'ERROR'].includes(argument.result);
}

export function isLiveChatMessageType(argument: unknown): argument is LiveChatMessageType {
  return Object.values(LiveChatMessageType).includes(argument as LiveChatMessageType);
}

export function isLiveChatConversationItem(argument: unknown): argument is LiveChatConversationItem {
  return isObject(argument)
    && typeof argument.actionType === 'string'
    && typeof argument.date === 'number'
    && isLiveChatMessageType(argument.messageType)
    && typeof argument.message === 'string'
    && typeof argument.uid === 'string'
    && Array.isArray(argument.files) && argument.files.every((file) => isObject(file)
      && typeof file.url === 'string'
      && typeof file.name === 'string');
}

export function isLiveChatGetData(argument: unknown): argument is LiveChatGetData {
  return isObject(argument)
    && typeof argument.question === 'string'
    && typeof argument.status === 'number'
    && typeof argument.started === 'number'
    && Array.isArray(argument.conversation)
    && argument.conversation.every((element) => isLiveChatConversationItem(element))
    && typeof argument.typing === 'boolean';
}

export function isLiveChatOpenData(argument: unknown): argument is LiveChatOpenData {
  return isObject(argument)
    && typeof argument.chatActive === 'boolean'
    && typeof argument.supportActive === 'boolean'
    && typeof argument.chatStatus === 'number';
}

export function isLiveChatStartData(argument: unknown): argument is LiveChatStartData {
  return isObject(argument)
    && ((
      typeof argument.chatActive === 'boolean'
      && typeof argument.question === 'string'
    ) || typeof argument.errorStatus === 'string');
}

export function isLiveChatCheckData(argument: unknown): argument is LiveChatCheckData {
  return isObject(argument)
    && typeof argument.active === 'boolean'
    && typeof argument.chatStatus === 'number';
}

export function isLiveChatErrorData(argument: unknown): argument is LiveChatErrorResponse {
  return isValidInterface<LiveChatErrorResponse>(argument, {
    result: [
      (value): value is 'ERROR' => value === 'ERROR',
    ],
    errorStatus: [isUndefined, isString],
    errorMessage: [isUndefined, isString],
  });
}
