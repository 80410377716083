<script lang="ts" setup>
import type { AutocompleteAddressProps } from 'web/src/modules/address/components';
import { AutocompleteAddress } from 'web/src/modules/address/components';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useBaseFormWidgetEvents } from '../../composables';
import useFormAutocompleteAddress from './useFormAutocompleteAddress';

const props = defineProps<FormWrapperWidgetProperties<AutocompleteAddressProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useFormAutocompleteAddress(props);
</script>

<template>
  <AutocompleteAddress v-auto-id="'FormAutocompleteAddress'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
