import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { FocusableInputRef } from '@leon-hub/focus';
import type { VInputInputEvent } from '@leon-hub/input-types';

import type { DropListSelectEmits, DropListSelectProps } from '../types';

interface UseDropListSearchQuery {
  searchQuery: Ref<string>;
  searchInputRef: Ref<FocusableInputRef | undefined>;
  isFilterSearch: Ref<boolean>;
  onSearchInput(event: VInputInputEvent): void;
  clearSearchQuery(): void;
  focusOnSearch(): void;
}

export function useDropListSearchQuery(props: DropListSelectProps, emit: DropListSelectEmits, resetPagination: () => void): UseDropListSearchQuery {
  const searchQuery = ref<string>('');

  const searchInputRef = ref<FocusableInputRef | undefined>();

  const focusOnSearch = (): void => {
    if (props.searchEnabled && searchInputRef.value) {
      void searchInputRef.value.focus();
    }
  };

  const emitQueryForApi = (): void => {
    if (props.isSearchByApiQuery) {
      emit('search-input', searchQuery.value);
    }
  };

  const onSearchInput = (event: VInputInputEvent): void => {
    searchQuery.value = event.target.value;
    resetPagination();
    emitQueryForApi();
  };

  const clearSearchQuery = (): void => {
    searchQuery.value = '';
    resetPagination();
    emitQueryForApi();
  };

  const isFilterSearch = computed<boolean>(() => !props.isSearchByApiQuery && !!searchQuery.value.length);

  return {
    searchQuery,
    searchInputRef,
    isFilterSearch,
    onSearchInput,
    focusOnSearch,
    clearSearchQuery,
  };
}
