import type { NearbyCity } from '@leon-hub/api-sdk';

import type { AddressOption } from '../types';

export function mapNearbyCityToAddressOption(input: NearbyCity): AddressOption {
  const fullTextEnd = input.secondaryText ? `, ${input.secondaryText}` : '';
  return {
    fullText: `${input.mainText}${fullTextEnd}`,
    primaryText: input.mainText,
    secondaryText: input.secondaryText,
    id: input.placeId,
  };
}
