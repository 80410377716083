import type { Ref } from 'vue';
import { computed } from 'vue';

import { VirtualSportTournament } from '@leon-hub/api-sdk';
import { SportIconName } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VirtualSportFilterOptionDisplayConfig } from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { SportFamily } from 'web/src/modules/sportline/enums';

export type VirtualSportItemsDisplayConfig = Partial<
  Record<VirtualSportTournament, VirtualSportFilterOptionDisplayConfig>
>;

interface UseVirtualSportItemsDisplayConfigComposable {
  itemsDisplayConfig: Ref<VirtualSportItemsDisplayConfig>;
}

export function useVirtualSportItemsDisplayConfig(): UseVirtualSportItemsDisplayConfigComposable {
  const { $translate } = useI18n();

  const itemsDisplayConfig = computed(() => ({
    [VirtualSportTournament.FOOTBALL_LEAGUE_MODE]: {
      label: $translate('JS_MOBILE_VFLM').value,
      sportFamily: SportFamily.Soccer,
    },
    [VirtualSportTournament.BASKETBALL]: {
      label: $translate('JS_MOBILE_VBL').value,
      sportFamily: SportFamily.Basketball,
    },
    [VirtualSportTournament.FOOTBALL_EUROCUP]: {
      label: $translate('JS_MOBILE_VFC').value,
      sportFamily: SportFamily.Soccer,
    },
    [VirtualSportTournament.FOOTBALL_CHAMPIONS_CUP]: {
      label: $translate('JS_MOBILE_VFCC').value,
      sportFamily: SportFamily.Soccer,
    },
    [VirtualSportTournament.FOOTBALL_NATIONS_CUP]: {
      label: $translate('JS_MOBILE_VFNC').value,
      sportFamily: SportFamily.Soccer,
    },
    [VirtualSportTournament.TENNIS_INPLAY]: {
      label: $translate('JS_MOBILE_VTI').value,
      sportFamily: SportFamily.Tennis,
    },
    [VirtualSportTournament.FOOTBALL_WORLD_CUP]: {
      label: $translate('JS_MOBILE_VFWC').value,
      sportFamily: SportFamily.Soccer,
    },
    [VirtualSportTournament.CRICKET]: {
      label: $translate('JS_MOBILE_VCI').value,
      sportFamily: SportFamily.Cricket,
    },
    [VirtualSportTournament.FOOTBALL_BUNDESLIGA]: {
      label: $translate('WEB2_VIRTUAL_SPORT_FILTER_FOOTBALL_BUNDESLIGA').value,
      icon: SportIconName.BUNDESLIGA,
      highlighted: true,
    },
  }));

  return { itemsDisplayConfig };
}
