<script lang="ts" setup>
import { ref } from 'vue';

import type { VIframeRef } from 'web/src/components/Iframe/VIframe/types';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';

import type {
  EgsInjectedGameIframeWidgetEmits,
  EgsInjectedGameIframeWidgetProps,
  EgsInjectedGameIframeWidgetRef,
} from './types';
import { useEgsInjectedGameIframeWidget } from './composables';

const props = defineProps<EgsInjectedGameIframeWidgetProps>();
const emit = defineEmits<EgsInjectedGameIframeWidgetEmits>();

const {
  iframeName,
  gameUrl,
  onIframeMounted,
  onIframeLoaded,
} = useEgsInjectedGameIframeWidget(props, emit);

const iframeElement = ref<VIframeRef>();

function postMessage(message: unknown, target = '*'): void {
  iframeElement.value?.postMessage(message, target);
}

defineExpose<EgsInjectedGameIframeWidgetRef>({
  postMessage,
});
</script>

<template>
  <VIframe v-auto-id="'EgsInjectedGameIframeWidget'"
    v-if="gameUrl"
    ref="iframeElement"
    v-data-test="{ el: 'started-game-iframe' }"
    :name="iframeName"
    :src="gameUrl"
    :allow="allow"
    :flex-layout="flexLayout"
    :allowfullscreen="allowfullscreen"
    :timeout="timeout"
    full-width
    full-height
    hide-title
    is-dark
    @vue:mounted="onIframeMounted"
    @load="onIframeLoaded"
    @error="emit('error', $event)"
  />
</template>
