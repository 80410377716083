import { computed } from 'vue';

import type { InputHintProps } from '@leon-hub/input-types';

import type { UseHintParentPropsOutput } from '../types';

type UseHintParentPropsInput<T = object> = T & InputHintProps;

function useHintParentProps(props: UseHintParentPropsInput): UseHintParentPropsOutput {
  const hintProperties = computed<InputHintProps>(() => ({
    error: props.error,
    errorRight: props.errorRight,
    hint: props.hint,
    hintLink: props.hintLink,
    hintRight: props.hintRight,
    hintLinkRight: props.hintLinkRight,
  }));
  const hasError = computed<boolean>(() => !!(props.error || props.errorRight));
  return {
    hintProperties,
    hasError,
  };
}

export default useHintParentProps;
