import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { SportlineEventId } from 'web/src/modules/sportline/types';

import { useMatchStreamAccess } from './useMatchStreamAccess';

export function createIsPreviewOnlyRef(sportlineEventId: Ref<Maybe<SportlineEventId>>): Ref<boolean> {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_STREAM_PREVIEW_DISABLED) {
    return ref(false);
  }

  const matchStreamAccess = useMatchStreamAccess();

  return computed(() => (
    !!sportlineEventId.value
    && !!matchStreamAccess.value.find(({ eventId }) => sportlineEventId.value === eventId)?.isPreview
  ));
}
