import type { CustomerNotificationMessage } from 'web/src/modules/customer-notifications/types';
import type { FastTrackMessage } from 'web/src/modules/fast-track/services/api/types';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';

export default function createMessageFromFastTrack(notification: FastTrackMessage): CustomerNotificationMessage {
  return {
    id: notification.MessageId,
    key: `${notification.MessageId}-${CustomerNotificationMessageSource.FAST_TRACK}`,
    title: notification.Title,
    message: notification.Message,
    createdAt: Date.parse(`${notification.Date.replace(/-/g, '/')} GMT`),
    isRead: notification.IsRead,
    isModal: notification.DisplayType !== 'silent',
    source: CustomerNotificationMessageSource.FAST_TRACK,
    image: notification.ImageUrl,
    button: notification.CTAButtonLink && notification.CTAButtonText ? {
      text: notification.CTAButtonText,
      link: notification.CTAButtonLink,
    } : undefined,
    button2: notification.CTAButton2Text ? {
      text: notification.CTAButton2Text,
      link: notification.CTAButton2Link || '',
    } : undefined,
  };
}
