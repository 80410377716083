import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { IconName } from '@leon-hub/icons';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import type { VButtonProps } from '@components/buttons';
import { ButtonHeight, ButtonKind } from '@components/buttons';

import type { PaymentsCardTypes } from 'web/src/modules/payments/enums';
import type {
  PaymentsCreditCardRadioSelectorEmits,
  PaymentsCreditCardRadioSelectorProps,
} from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelector/types';
import type { PaymentsCreditCard } from 'web/src/modules/payments/types/documentTypes';
import { PAYMENTS_VISIBLE_CARDS_COUNT } from 'web/src/modules/payments/constants';
import PaymentsCreditCardBeautifier from 'web/src/modules/payments/form-widgets/CreditCardSelector/utils/PaymentsCreditCardBeautifier';

export interface PaymentsCreditCardRadioSelectorComposable {
  buttonProperties: ComputedRef<VButtonProps>;
  showNewCardButton: ComputedRef<boolean>;
  hasCollapsedView: ComputedRef<boolean>;
  cardsList: ComputedRef<PaymentsCreditCard[]>;
  getName(type: Maybe<PaymentsCardTypes> | undefined, pan: string): string;
  switchCollapsed(): void;
  onDelete(bankCard: PaymentsCreditCard): void;
}

export default function usePaymentsCreditCardRadioSelector(
  props: PaymentsCreditCardRadioSelectorProps,
  emit: PaymentsCreditCardRadioSelectorEmits,
): PaymentsCreditCardRadioSelectorComposable {
  const isCollapsed = ref(true);
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();

  const showNewCardButton = computed(() => (
    props.hasNewCard && props.creditCards.length <= PAYMENTS_VISIBLE_CARDS_COUNT) || !isCollapsed.value);

  const hasCollapsedView = computed(() => props.creditCards.length > PAYMENTS_VISIBLE_CARDS_COUNT);

  const cardsList = computed(() => {
    if (props.creditCards.length > PAYMENTS_VISIBLE_CARDS_COUNT && isCollapsed.value) {
      return props.creditCards.slice(0, PAYMENTS_VISIBLE_CARDS_COUNT);
    }
    return props.creditCards;
  });

  const buttonProperties = computed(() => {
    let properties: VButtonProps = {
      label: $translate('WEB2_PAYMENTS_CARDS_COLLAPSE_SHOW').value,
      iconName: IconName.EXPAND_DOWN,
      kind: ButtonKind.BASE_OPACITY,
      height: process.env.VUE_APP_LAYOUT_DESKTOP ? ButtonHeight.SMALL : ButtonHeight.LARGE,
      fullWidth: true,
      iconRight: true,
    };

    if (!isCollapsed.value) {
      properties = {
        ...properties,
        label: $translate('WEB2_PAYMENTS_CARDS_COLLAPSE_HIDE').value,
        iconName: IconName.EXPAND_UP,
      };
    }

    return properties;
  });

  const switchCollapsed = () => {
    isCollapsed.value = !isCollapsed.value;
  };

  const getName = (
    type: Maybe<PaymentsCardTypes> | undefined,
    pan: string,
  ) => new PaymentsCreditCardBeautifier(`${type}`, pan).getName();

  function onDelete(bankCard: PaymentsCreditCard): void {
    const beautifier = new PaymentsCreditCardBeautifier(bankCard.type ?? '', bankCard.pan ?? '');

    const { subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_CARD_DELETE_TITLE').value,
        confirmMessage:
          `${$translate('WEB2_CARD_DELETE_DESCR_START').value}
            <span class="modal__text-mark">${beautifier.getName()}</span>
            ${$translate('WEB2_CARD_DELETE_DESCR_END').value}`,
        buttons: [
          {
            label: $translate('WEB2_CARD_DELETE').value,
          },
        ],
        dataTestId: 'card-delete',
      },
    });
    subscribe({
      [DialogAction.CONFIRM]: () => {
        emit('delete', bankCard.token);
      },
    });
  }

  return {
    cardsList,
    hasCollapsedView,
    showNewCardButton,
    buttonProperties,
    getName,
    switchCollapsed,
    onDelete,
  };
}
