import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import type {
  doCollectRewardQuery,
} from '@leon-hub/api-sdk';
import { GqlApiBatchedSubRequestError } from '@leon-hub/api';
import {
  doCollectReward,
  doConfirmParticipation,
  getBenefitCustomerLevel,
} from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { Json } from '@leon-hub/utils';

import { useGraphqlClient } from '@core/app-rest-client';
import { useErrorsConverter } from '@core/errors';
import { useSiteConfigStore } from '@core/site-config';

import type {
  BenefitProgramBenefits,
  BenefitProgramLevelSetting,
  BenefitProgramStatusDocument,
  BenefitProgramSubLevelProgress,
} from 'web/src/modules/benefits-program/types';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';

const useBenefitProgramStore = defineStore('benefit-program-store', () => {
  const siteConfigStore = useSiteConfigStore();
  const apiClient = useGraphqlClient();
  const { convertToBaseError } = useErrorsConverter();

  const isLoyaltyEnabled = toRef(siteConfigStore, 'isLoyaltyEnabled');

  const currentLevelId = ref(0);
  const nextLevelId = ref(0);
  const actualTurnover = ref(0);
  const pointsUntilNextLevel = ref(0);
  const currentCashbackPercent = ref(0);
  const nextPeriodCashbackPercent = ref(0);
  const currency = ref('');
  const programEnabled = ref(false);
  const confirmed = ref(false);

  const levelSettings = ref<BenefitProgramLevelSetting[]>([]);
  const benefits = ref<readonly BenefitProgramBenefits[]>([]);
  const subLevelProgress = ref<BenefitProgramSubLevelProgress>();

  const isCurrentInitLevel = computed(() => {
    if (!levelSettings.value.length)
      return false;
    return levelSettings.value[0].levelId === currentLevelId.value;
  });

  const currentLevelData = computed(() => {
    return levelSettings.value.find((level) => level.levelId === currentLevelId.value);
  });

  const currentLevelName = computed(() => {
    return currentLevelData.value?.viewSettings.levelName;
  });

  const nextLevelData = computed(() => {
    const currentLevelIndex = levelSettings.value.findIndex((level) => level.levelId === currentLevelId.value);

    if (currentLevelIndex === levelSettings.value.length - 1) {
      return null;
    }

    return levelSettings.value[currentLevelIndex + 1];
  });

  const currentLevelCardProperties = computed(() => {
    return {
      levelTitle: currentLevelData.value?.viewSettings.levelName,
      imageSrc: currentLevelData.value?.viewSettings.image,
      currentCashbackPercent: currentCashbackPercent.value,
      nextPeriodCashbackPercent: nextPeriodCashbackPercent.value,
      initLevel: isCurrentInitLevel.value,
      expectedTurnover: currentLevelData.value?.expectedTurnover || 0,
    };
  });

  const setBenefitProgramState = (data: BenefitProgramStatusDocument | null) => {
    if (!data) {
      return;
    }

    const {
      currentLevelId: dataId,
      actualTurnover: dataActualTurnover,
      levelSettings: dataLevelSettings,
      benefits: dataBenefits,
      nextPeriodCashbackPercent: dataNextPeriodCashbackPercent,
      programEnabled: dataProgramEnabled,
      nextLevelId: dataNextLevelId,
      currentCashbackPercent: dataCurrentCashbackPercent,
      confirmed: dataConfirmed,
      currency: dataCurrency,
    } = data;

    currentLevelId.value = dataId || 0;
    nextLevelId.value = dataNextLevelId || 0;
    actualTurnover.value = dataActualTurnover || 0;
    levelSettings.value = dataLevelSettings;
    currency.value = dataCurrency || '';
    confirmed.value = dataConfirmed || false;
    programEnabled.value = dataProgramEnabled || false;
    nextPeriodCashbackPercent.value = dataNextPeriodCashbackPercent || 0;
    currentCashbackPercent.value = dataCurrentCashbackPercent || 0;
    benefits.value = dataBenefits;
  };

  const fetchBenefitProgramStatus = async () => {
    if (isLoyaltyEnabled.value) {
      try {
        const result = await getBenefitCustomerLevel(apiClient, (node) => node.queries.levels.customerLevel);

        setBenefitProgramState(result);

        if (!isObject(result)) {
          logger.error(`Wrong data type in getCustomerLevel. Data = ${Json.stringify(result)}`);
        }
      } catch (originalError) {
        const error = convertToBaseError(originalError);
        if (!error.code.equals(RegistrationApiErrorCode.TECH)) {
          throw (originalError);
        }
      }
    }
  };

  async function confirmParticipation(): Promise<void> {
    try {
      await doConfirmParticipation(apiClient, (node) => node.mutations.levels.confirmParticipation);
    } catch (originalError) {
      const error = convertToBaseError(originalError);
      if (!error.code.equals(RegistrationApiErrorCode.TECH)) {
        throw (originalError);
      }
    }
  }

  async function collectReward(rewardId: number): Promise<void> {
    try {
      await doCollectReward(apiClient, (node: doCollectRewardQuery) => node.mutations.levels.collectReward, {
        options: {
          id: rewardId,
        },
      });
    } catch (error) {
      if (error instanceof GqlApiBatchedSubRequestError) {
        logger.error('Something goes wrong with collect reward', error);
      }
    }
  }

  return {
    nextLevelId,
    levelSettings,
    currentLevelData,
    currentLevelId,
    pointsUntilNextLevel,
    actualTurnover,
    nextLevelData,
    currentLevelCardProperties,
    subLevelProgress,
    benefits,
    currency,
    currentLevelName,
    fetchBenefitProgramStatus,
    confirmParticipation,
    collectReward,
  };
});

export default useBenefitProgramStore;
