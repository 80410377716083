import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import { getTimerString } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables/utils';

import type { VTimerProps } from '../types';

export interface VTimerComposable {
  timeLeft: Ref<string>;
}

export default function useVTimer(props: VTimerProps): VTimerComposable {
  const timeLeft = computed(() => (props.seconds ? getTimerString(props.seconds) : ''));

  return {
    timeLeft,
  };
}
