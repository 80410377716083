import type { Ref } from 'vue';
import { computed } from 'vue';

import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import { DateTime } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { League, Region } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

interface UseLeagueHeadlineTitleProps {
  doUseFilter: Ref<boolean>;
  league: Ref<Maybe<League>>;
  region: Ref<Maybe<Region>>;
  filter: Ref<Maybe<SportDateTimeFilter>>;
}

interface UseLeagueHeadlineTitleComposable {
  leagueTitle: Ref<string>;
  regionTitle: Ref<Maybe<string>>;
}

export function useLeagueHeadlineTitle(
  props: UseLeagueHeadlineTitleProps,
): UseLeagueHeadlineTitleComposable {
  const {
    doUseFilter,
    league,
    region,
    filter,
  } = props;

  const { $translate } = useI18n();

  const regionName = computed<Maybe<string>>(() => (region.value?.name ?? null));
  const leagueName = computed<string>(() => (league.value?.name ?? ''));
  // maybe we should update it once per interval for long live pages and apps
  const defaultDateTime = DateTime.now().toTimestamp();
  const customFilterFrom = computed<string>(() => DateTime
    .withTimeStamp(filter.value?.customDateFrom ?? defaultDateTime)
    .toFullDate());
  const customFilterTo = computed<string>(() => DateTime
    .withTimeStamp(filter.value?.customDateTo ?? defaultDateTime)
    .toFullDate());
  const customFilterDateTime = computed(() => ({ from: customFilterFrom.value, to: customFilterTo.value }));

  const dateTimeTitles: Dictionary<Ref<string>> = {
    [SportFilterKickoffPeriod.ONEHOUR]: $translate('WEB2_SPORTS_QUICKFILTERS_1H_FULL'),
    [SportFilterKickoffPeriod.TWOHOURS]: $translate('WEB2_SPORTS_QUICKFILTERS_2H_FULL'),
    [SportFilterKickoffPeriod.THREEHOURS]: $translate('WEB2_SPORTS_QUICKFILTERS_3H_FULL'),
    [SportFilterKickoffPeriod.SIXHOURS]: $translate('WEB2_SPORTS_QUICKFILTERS_6H_FULL'),
    [SportFilterKickoffPeriod.TWELVEHOURS]: $translate('WEB2_SPORTS_QUICKFILTERS_12H_FULL'),
    [SportFilterKickoffPeriod.TWENTYFOURHOURS]: $translate('WEB2_SPORTS_QUICKFILTERS_24H_FULL'),
    [SportFilterKickoffPeriod.TODAY]: $translate('WEB2_SPORTS_QUICKFILTERS_TODAY_FULL'),
    [SportFilterKickoffPeriod.TOMORROW]: $translate('WEB2_SPORTS_QUICKFILTERS_TOMORROW_FULL'),
    [SportFilterKickoffPeriod.CUSTOM]: $translate('WEB2_SPORTS_QUICKFILTERS_CALENDER_FULL', customFilterDateTime),
    default: $translate('WEB2_SPORTS_QUICKFILTERS_OTHER_FULL'),
  };
  const sportlineTypeTitles: Dictionary<Ref<string>> = {
    [SportlineType.Live]: $translate('WEB2_LIVE_SPORTLINE'),
  };
  const emptyFilterTitle = $translate('WEB2_COMING_EVENTS');

  const leagueTitle = computed<string>(() => {
    if (!doUseFilter.value) {
      return leagueName.value;
    }

    const period = filter.value?.activeKickoffPeriod;
    const type = filter.value?.sportlineType;

    if (type && sportlineTypeTitles[type]) {
      return sportlineTypeTitles[type].value;
    }

    if (period) {
      return (dateTimeTitles[period] ?? dateTimeTitles.default).value;
    }

    return emptyFilterTitle.value;
  });

  const regionTitle = computed<Maybe<string>>(() => {
    if (doUseFilter.value) {
      return null;
    }
    return regionName.value;
  });

  return { leagueTitle, regionTitle };
}
