import type { Ref } from 'vue';
import { computed } from 'vue';

import type { UseIsFavoriteTabAvailable } from '../types';

export function createIsFavoritesTabAvailableRef(
  isLoggedIn: Ref<boolean>,
  hasFavoritesOptimistic: Ref<boolean>,
): UseIsFavoriteTabAvailable['isFavoritesTabAvailable'] {
  return computed<boolean>(() => (
    isLoggedIn.value ? hasFavoritesOptimistic.value : false
  ));
}
