<script lang="ts" setup>
import { VButton } from '@components/buttons';

import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
import usePaymentsCreditCardRadioSelector

  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelector/composables/usePaymentsCreditCardRadioSelector';
import PaymentsCreditCardRadioSelectorItem

  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelectorItem/PaymentsCreditCardRadioSelectorItem.vue';

import type { PaymentsCreditCardRadioSelectorEmits, PaymentsCreditCardRadioSelectorProps } from './types';

const props = defineProps<PaymentsCreditCardRadioSelectorProps>();
const emit = defineEmits<PaymentsCreditCardRadioSelectorEmits>();

const {
  cardsList,
  hasCollapsedView,
  showNewCardButton,
  buttonProperties,
  getName,
  switchCollapsed,
  onDelete,
} = usePaymentsCreditCardRadioSelector(props, emit);
</script>

<template>
  <div v-auto-id="'PaymentsCreditCardRadioSelector'"
    class="credit-card-radio-selector"
  >
    <PaymentsCreditCardRadioSelectorItem
      v-for="creditCard in cardsList"
      :key="`${creditCard.token}`"
      :pan="creditCard.pan"
      :token="creditCard.token ?? PAYMENTS_UNDEFINED_CREDIT_CARD"
      :type="creditCard.type"
      :label="getName(creditCard.type, `${creditCard.pan}`)"
      :is-selected="selectedCardToken === creditCard.token"
      :is-deletable="isDeletable"
      name="creditCardSelector"
      @change="emit('change', $event)"
      @delete="onDelete(creditCard)"
    />
    <PaymentsCreditCardRadioSelectorItem
      v-if="showNewCardButton"
      :label="$t('WEB2_ANOTHER_CARD')"
      :token="PAYMENTS_UNDEFINED_CREDIT_CARD"
      :is-selected="selectedCardToken === PAYMENTS_UNDEFINED_CREDIT_CARD"
      name="creditCardSelector"
      @change="emit('change', $event)"
    />
    <VButton
      v-if="hasCollapsedView"
      v-bind="buttonProperties"
      @click="switchCollapsed"
    />
  </div>
</template>
