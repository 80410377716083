import {
  getAvailability,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

import type { AvailabilityGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default async function getAvailabilityData(): Promise<AvailabilityGetDataDocument> {
  const apiClient = useGraphqlClient();

  return getAvailability(
    apiClient,
    (node) => node.queries.refProgram.getAvailability,
    {
      options: {},
    },
    {
      silent: true,
      priority: RequestOptionsPriority.LOW,
    },
  );
}
