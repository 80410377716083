import type { Ref } from 'vue';
import { ref } from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';
import {
  lobbyTypeAll,
  lobbyTypeFastgames,
  lobbyTypeHome,
  lobbyTypeLiveCasino,
  lobbyTypeLiveGamePage,
  lobbyTypeLoyaltyProgram,
  lobbyTypeSearch,
  lobbyTypeSlots,
  lobbyTypeSlotsGamePage,
} from '@leon-hub/api-sdk';

export default function getInitArrayStatesByLobbyType<T extends object>(): Partial<Record<ILobbyType, Ref<T[]>>> {
  if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    return {
      [lobbyTypeFastgames]: ref([]),
    } as unknown as Record<ILobbyType, Ref<T[]>>;
  }

  return {
    [lobbyTypeSlots]: ref([]),
    [lobbyTypeLiveCasino]: ref([]),
    [lobbyTypeLoyaltyProgram]: ref([]),
    [lobbyTypeFastgames]: ref([]),
    [lobbyTypeSearch]: ref([]),
    [lobbyTypeLiveGamePage]: ref([]),
    [lobbyTypeSlotsGamePage]: ref([]),
    [lobbyTypeHome]: ref([]),
    [lobbyTypeAll]: ref([]),
  };
}
