import { defineStore } from 'pinia';
import { watch } from 'vue';

import type { OkResponse } from '@leon-hub/api-sdk';

import { useIsLoggedIn } from '@core/auth';

import type { AddToCustomerLeaguesParameters } from 'web/src/modules/sportline/submodules/favorites/types';
import { toggleFavoriteLeagueRequest } from 'web/src/modules/sportline/utils/api';

import { useFavoriteLeaguesBackgroundUpdates } from './composables/useFavoriteLeaguesBackgroundUpdates';
import { useFavoritesLeaguesEventsStoreComposable } from './composables/useFavoritesLeaguesEventsStoreComposable';
import { useOptimisticFavoritesLeagues } from './composables/useOptimisticFavoritesLeagues';
import { usePendingToFavoriteLeaguesStoreComposable } from './composables/usePendingToFavoriteLeaguesStoreComposable';

export const useCustomerLeaguesStore = defineStore('sportline-customer-leagues', () => {
  const { isLoggedIn } = useIsLoggedIn();

  const {
    customerLeaguesIds,
    rawFavoriteLeaguesEventsResponse,
    setRawFavoriteEventsResponse,
    fetchFavoriteLeaguesEvents,
    fetchCustomerLeaguesIds,
    clearCustomerLeaguesIds,
  } = useFavoritesLeaguesEventsStoreComposable();

  const {
    preparedToAdd,
    preparedToRemove,
    pendingToFavoriteLeaguesList,
    addLeagueIdToTogglePrepared,
    togglePendingToFavoriteLeaguesList,
    setupPendingLeagues,
    clearPendingLeagues,
  } = usePendingToFavoriteLeaguesStoreComposable({ customerLeaguesIds });

  async function tryToAddPendingLeagues(): Promise<boolean> {
    if (!isLoggedIn.value) {
      return false;
    }

    const responses: Promise<OkResponse>[] = [];

    // @TODO optimize add pending leagues
    await fetchCustomerLeaguesIds({ silent: true });

    const pendingList = setupPendingLeagues();

    // @TODO make single request to add many leagues
    for (const pending of pendingList) {
      const { sportId, leagueId } = pending;

      responses.push(toggleFavoriteLeagueRequest({ sportId, leagueId }, { silent: true }));
    }

    await Promise.all(responses);

    return true;
  }

  const {
    onInit: onBackgroundUpdatesInit,
    initialRequests: initialBackgroundUpdatesRequests,
    setIsBackgroundIdsUpdateAvailable,
    setIsEventsBackgroundUpdateAvailable,
    synchronizeLeaguesIds,
  } = useFavoriteLeaguesBackgroundUpdates({
    customerLeaguesIds,
    pendingToFavoriteLeaguesList,
    tryToAddPendingLeagues,
    fetchFavoriteLeaguesEvents,
    fetchCustomerLeaguesIds,
    clearPendingLeagues,
  });
  const {
    optimisticCustomerLeaguesIds,
  } = useOptimisticFavoritesLeagues({
    preparedToAdd,
    preparedToRemove,
    pendingToFavoriteLeaguesList,
    customerLeaguesIds,
  });

  async function toggleCustomerLeague(parameters: AddToCustomerLeaguesParameters): Promise<void> {
    if (!isLoggedIn.value) {
      togglePendingToFavoriteLeaguesList(parameters);
      return;
    }

    // add to optimistic
    const removeFromPrepared = addLeagueIdToTogglePrepared(parameters.leagueId);

    try {
      // toggle league state
      await toggleFavoriteLeagueRequest(parameters, { silent: false });
      // get actual favorite ids list
      await synchronizeLeaguesIds({ silent: true, force: true });
    } finally {
      removeFromPrepared();
    }
  }

  async function initialRequests(): Promise<void> {
    await initialBackgroundUpdatesRequests();
  }

  // init store
  watch(isLoggedIn, (value: boolean) => {
    if (value) {
      void initialBackgroundUpdatesRequests();
      return;
    }

    clearCustomerLeaguesIds();
    setRawFavoriteEventsResponse(null);
    clearPendingLeagues();
  }, { immediate: true });

  onBackgroundUpdatesInit();

  return {
    customerLeaguesIds,
    optimisticCustomerLeaguesIds,
    pendingToFavoriteLeaguesList,
    toggleCustomerLeague,
    setIsBackgroundIdsUpdateAvailable,
    setIsEventsBackgroundUpdateAvailable,
    initialRequests,
    // seems it must be internal field
    rawFavoriteLeaguesEventsResponse,
  };
});
