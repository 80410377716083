import type { AddressPrediction } from '../../../services/api/types';
import type { AddressOption } from '../types';

export function mapAddressPredictionToAddressOption(input: AddressPrediction): AddressOption {
  return {
    fullText: input.description,
    primaryText: input.structured_formatting.main_text,
    secondaryText: input.structured_formatting.secondary_text,
    id: input.place_id,
  };
}
