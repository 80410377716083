<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { SportlineEventMetaInfoProps } from 'web/src/modules/sportline/views/EventBlock/types';
import { useSportlineEventId, useSportlineEventTypeRef } from 'web/src/modules/sportline/composables/sportline-event';
import { useStreamAvailableIndicator } from 'web/src/modules/sportline/submodules/streams/composables';
import { TodayLabelType, UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';

import { useSportlinePrematchMetaInfo } from './useSportlinePrematchMetaInfo';

interface SportlinePrematchMetaInfoProps extends SportlineEventMetaInfoProps {
  upcomingLabelType?: Maybe<UpcomingLabelType>;
  todayLabelType?: Maybe<TodayLabelType>;
}

const props = withDefaults(defineProps<SportlinePrematchMetaInfoProps>(), {
  upcomingLabelType: null,
  todayLabelType: TodayLabelType.None,
});

const sportlineEvent = toRef(() => props.sportlineEvent);
const upcomingLabelType = toRef(() => props.upcomingLabelType);
const todayLabelType = toRef(() => props.todayLabelType);

const sportlineEventId = useSportlineEventId(sportlineEvent);
const sportlineType = useSportlineEventTypeRef(sportlineEvent);
const kickoff = computed(() => sportlineEvent.value.kickoff);

const {
  willComeLabel,
  startsInLabel,
  date,
  time,
} = useSportlinePrematchMetaInfo({ kickoff, todayLabelType });
const {
  isStreamIndicatorAvailable,
} = useStreamAvailableIndicator({ sportEventId: sportlineEventId, sportlineType });
</script>

<template>
  <div v-auto-id="'SportlineEventPrematchMetaInfo'" :class="$style['sportline-event-meta-info']">
    <div :class="$style['sportline-event-meta-info__left-column']">
      <span :class="$style['kickoff-countdown']">
        <template v-if="upcomingLabelType === UpcomingLabelType.StaticLabel">
          <span :class="$style['kickoff-countdown--filled']">
            {{ $t('WEB2_EVENT_WILL_START_SOON') }}
          </span>
        </template>
        <template v-else-if="upcomingLabelType === UpcomingLabelType.Timer">
          {{ willComeLabel }}
          <span :class="$style['kickoff-countdown__value']">
            {{ startsInLabel }}
          </span>
        </template>
        <template v-else>
          <span :class="$style['kickoff-countdown__date']">{{ date }}</span>
          <span :class="$style['kickoff-countdown__time']">{{ time }}</span>
        </template>
      </span>
    </div>

    <div :class="$style['sportline-event-meta-info__right-column']">
      <VIcon
        v-if="isStreamIndicatorAvailable"
        :name="IconName.STREAM"
        :size="IconSize.SIZE_12"
        :class="$style['stream-indicator']"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-meta-info';

.kickoff-countdown {
  @include medium\12\12;

  color: var(--TextDefault);

  &__value {
    font-weight: 500;
    color: var(--TextDefault);
  }

  &__date {
    color: var(--TextDefault);
  }

  &__time {
    font-weight: 400;
    color: var(--TextSecondary);
  }

  &--filled {
    @include bold\9\12\02\caps;

    padding: 2px 4px;
    background-color: var(--Highlight);
    border-radius: 2px;
  }
}

.stream-indicator {
  fill: var(--TextSecondary);
}
</style>
