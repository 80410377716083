<script lang="ts" setup>
import { computed } from 'vue';

import { getCurrencySymbol, useCurrency, useFormatMoney } from '@core/money';

export interface BonusCalculatorProps {
  depositAmountPercent: number;
  minAmount?: number;
  maxAmount?: number;
  amount?: string;
}

const props = withDefaults(defineProps<BonusCalculatorProps>(), {});

const { currency } = useCurrency();
const formatMoney = useFormatMoney();

const type = computed(() => {
  return Number(props.amount || 0) >= Number(props.minAmount || 0) ? 'success' : 'error';
});

const percent = computed(() => {
  let amount = 0;

  if (Number(props.amount || 0) >= Number(props.minAmount)) {
    amount = Number(Number(props.amount || 0) * props.depositAmountPercent) / 100;
  }

  if (props.maxAmount && amount > props.maxAmount) {
    amount = props.maxAmount;
  }

  if (!amount) {
    return `0 ${getCurrencySymbol(currency.value)}`;
  }

  return formatMoney(
    amount,
    {
      currency: currency.value,
    },
  );
});
</script>

<template>
  <span v-auto-id="'BonusCalculator'"
    :class="{
      [$style['bonus-calculator']]: true,
      [$style[`bonus-calculator--type-${type}`]]: true,
    }"
  >
    <span>{{ $t('WEB2_BONUS_CALCULATOR_TITLE') }}</span>
    <span>{{ percent }}</span>
  </span>
</template>

<style lang="scss" module>
  .bonus-calculator {
    @include medium\12\12;

    display: inline-flex;
    column-gap: 2px;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.15);

    &--type {
      &-success {
        color: var(--BrandHighlight);
        background-color: var(--BrandDefaultOpacity02);
      }

      &-error {
        color: var(--AlertText);
        background-color: rgba(255, 214, 10, 0.2);
      }
    }
  }
</style>
