import type { Component } from 'vue';
import { defineAsyncComponent } from 'vue';

import { FormControlType } from '@leon-hub/form-utils';

import { VEmpty } from 'web/src/components/Empty';

import {
  FormAutocompleteAddress,
  FormCaptcha,
  FormCheckbox,
  FormCurrencyInput,
  FormDate,
  FormEmail,
  FormHidden,
  FormMultilineTextInput,
  FormPassword,
  FormPhoneInput,
  FormSelectWithSearch,
  FormSingleSelect,
  FormSmsCode,
  FormSuggestEmail,
  FormSwitch,
  FormText,
} from '../components';
import FormAutoheightTextInput from '../components/FormAutoheightTextInput/FormAutoheightTextInput.vue';
import FormDropListSelect from '../components/FormDropListSelect/FormDropListSelect.vue';
import FormCPFNumber from '../components/li/FormCPFNumber/FormCPFNumber.vue';
import FormCurrencySelect from '../components/li/FormCurrencySelect/FormCurrencySelect.vue';

export type FormWidgetList = Record<string, Component>;

/** common & instantly loaded */
const baseWidgets: FormWidgetList = {
  // most common | registration | login
  [FormControlType.Text]: FormText,
  [FormControlType.Email]: FormEmail,
  [FormControlType.Password]: FormPassword,
  [FormControlType.PasswordValidator]: FormPassword,
  [FormControlType.Select]: FormSingleSelect,
  [FormControlType.Phone]: FormPhoneInput,
  [FormControlType.PhoneInput]: FormPhoneInput,
  [FormControlType.PhoneCountriesSelectorInput]: FormPhoneInput,
  [FormControlType.Checkbox]: FormCheckbox,
  [FormControlType.Date]: FormDate,
  [FormControlType.Switch]: FormSwitch,
  [FormControlType.Hidden]: FormHidden,
  [FormControlType.SuggestEmail]: FormSuggestEmail,
  [FormControlType.Captcha]: FormCaptcha,
  [FormControlType.CaptchaV3]: VEmpty, // TODO LEONWEB-15358
  [FormControlType.MultilineTextInput]: FormMultilineTextInput,
  [FormControlType.SmsCode]: FormSmsCode,
  [FormControlType.AutocompleteAddress]: FormAutocompleteAddress,
  [FormControlType.CurrencyInput]: FormCurrencyInput,
  [FormControlType.SelectWithSearch]: FormSelectWithSearch,
};

/** rarely used */
const asyncWidgets: FormWidgetList = {

  [FormControlType.FileSingle]: defineAsyncComponent(() => import('../components/FormFileSingleInput/FormFileSingleInput.vue')),
  [FormControlType.FileMultiple]: defineAsyncComponent(() => import('../components/FormFileMultipleInput/FormFileMultipleInput.vue')),
  [FormControlType.FileDragAndDrop]: defineAsyncComponent(() => import('../components/FormFileDragAndDropInput/FormFileDragAndDropInput.vue')),
  [FormControlType.PhotoInput]: defineAsyncComponent(() => import('../components/FormPhotoInput/FormPhotoInput.vue')),
  [FormControlType.PieTimerInput]: defineAsyncComponent(() => import('../components/FormPieTimerInput/FormPieTimerInput.vue')),

};

let domainWidgets: FormWidgetList = {};

if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED || process.env.VUE_APP_STORYBOOK) {
  domainWidgets = {
    ...domainWidgets,
    [FormControlType.CPFNumber]: FormCPFNumber,
    [FormControlType.CurrencySelect]: FormCurrencySelect,
  };
}

if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_DISABLED || process.env.VUE_APP_STORYBOOK) {
  domainWidgets = {
    ...domainWidgets,
    [FormControlType.Fio]: FormText,
  };
}

/** Slott */
if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED || process.env.VUE_APP_STORYBOOK) {
  domainWidgets = {
    ...domainWidgets,
    [FormControlType.DropListSelect]: FormDropListSelect,
    [FormControlType.AutoheightTextInput]: FormAutoheightTextInput,
  };
}

const widgetList: FormWidgetList = {
  ...baseWidgets,
  ...asyncWidgets,
  ...domainWidgets,
  /**
   * for payments inputs
   * @see web/src/views/Payments/components/PaymentsSystems/PaymentsSystem/customPaymentsFormWidgets.ts
   */
};

export default Object.freeze(widgetList);
