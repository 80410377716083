<script setup lang="ts">
import { Tag } from '@leon-hub/tags';

import type { HighlightedTextProps } from '../types';
import { useHighlightedText } from '../composables';

defineOptions({
  inheritAttrs: true,
});
const props = withDefaults(defineProps<HighlightedTextProps>(), {
  text: '',
  query: '',
  rootTag: Tag.SPAN,
});
const { substrings } = useHighlightedText(props);
</script>

<template>
  <component v-auto-id="'HighlightedText'" :is="rootTag">
    <template
      v-for="(line, index) in substrings"
      :key="index"
    >
      <span
        v-if="line.isHighlighted"
        :class="$style['highlighted-text']"
      >
        {{ line.text }}
      </span>
      <template v-else>
        {{ line.text }}
      </template>
    </template>
  </component>
</template>

<style module lang="scss">
.highlighted-text {
  color: var(--HighlightedText);
}
</style>
