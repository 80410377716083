<script lang="ts" setup>
import { VButton } from '@components/buttons';

import { useBalanceSwiperNavigation } from './composables';

const {
  slideToPreviousSlide,
  slideToNextSlide,
  isShowButtons,
  previousButtonProperties,
  nextButtonProperties,
} = useBalanceSwiperNavigation();
</script>

<template>
  <div v-auto-id="'BalanceSwiperNavigation'"
    v-show="isShowButtons"
    :class="$style['balance-swiper-navigation']"
  >
    <VButton
      v-bind="previousButtonProperties"
      :class="$style['balance-swiper-navigation__button']"
      @click="slideToPreviousSlide"
    />
    <VButton
      v-bind="nextButtonProperties"
      :class="$style['balance-swiper-navigation__button']"
      @click="slideToNextSlide"
    />
  </div>
</template>

<style lang="scss" module>
.balance-swiper-navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;

  &__button {
    &:last-child {
      margin-left: 4px;
    }
  }
}
</style>
