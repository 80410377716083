import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VBadgeProps } from '@components/badge';
import type { VImageProps } from '@components/image';
import { BadgeKind } from '@components/badge';
import { ObjectFitOption } from '@components/image';

import type {
  CasinoSearchItemEmits,
  CasinoSearchItemProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/types';

interface CasinoSearchItemComposable {
  onClick(): void;
  imageProps: ComputedRef<VImageProps>;
  ntBadgeProperties: ComputedRef<VBadgeProps>;
  formattedCategories: ComputedRef<string[]>;
}

const noimageSrc = require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/noimage-square.png`);

export default function useCasinoSearchItem(
  props: CasinoSearchItemProps,
  emits: CasinoSearchItemEmits,
): CasinoSearchItemComposable {
  const ntBadgeProperties = computed<VBadgeProps>(() => ({
    kind: BadgeKind.SQUARE_ERROR,
    label: props.ntBadge,
  }));
  const formattedCategories = computed(() => props.categories?.filter((item) => item !== props.group && !!item));
  const imageProps = computed<VImageProps>(() => ({
    isLazy: true,
    showLoader: true,
    objectFit: ObjectFitOption.COVER,
    ...(props.image ?? {
      src: noimageSrc,
    }),
  }));

  function onClick(): void {
    emits('click');
  }

  return {
    onClick,
    imageProps,
    ntBadgeProperties,
    formattedCategories,
  };
}
