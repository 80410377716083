import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';
import {
  useRoute,
} from 'vue-router';

import { useCustomerFinanceStore } from '@core/money';
import { useSiteConfigStore } from '@core/site-config';

import { useBonusStore } from 'web/src/modules/bonuses/store';
import { isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';

export interface CustomerFinalBalanceComposable {
  totalBalance: Ref<number>;
  balance: Ref<number>;
  bonusAmount: Ref<number>;
  isSumOfBalancesEnabled: Ref<boolean>;
}

export function useCustomerFinalBalance(sumOnlyOnEgsPages = false): CustomerFinalBalanceComposable {
  const route = useRoute();
  const balance = toRef(useCustomerFinanceStore(), 'balance');
  const siteConfigStore = useSiteConfigStore();
  const homePageType = toRef(siteConfigStore, 'homePageType');
  const isSumOfBonusAndBalanceEnabled = toRef(siteConfigStore, 'isSumOfBonusAndBalanceEnabled');

  const bonusCbc = toRef(useBonusStore(), 'bonusCbc');
  const bonusAmount = computed<number>(() => bonusCbc.value?.walletBalanceNumber ?? 0);

  const isEGSLobbyPage = computed<boolean>(() => {
    if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      return false;
    }

    return isCasinoLobbyRouteName(route.name, homePageType.value);
  });

  const isSumOfBalancesEnabled = computed<boolean>(() => (
    isSumOfBonusAndBalanceEnabled.value && bonusAmount.value > 0 && (!sumOnlyOnEgsPages || isEGSLobbyPage.value)
  ));

  const totalBalance = computed<number>(() => {
    if (!isSumOfBalancesEnabled.value) {
      return balance.value;
    }

    return balance.value + bonusAmount.value;
  });

  return {
    isSumOfBalancesEnabled,
    totalBalance,
    balance,
    bonusAmount,
  };
}
