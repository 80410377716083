import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import {
  CashbackConfirmationState,
  doConfirmCashback,
  doRefuseCashback,
  getCashback as getCashbackApi,
} from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { DateTime, Json } from '@leon-hub/utils';

import { useGraphqlClient } from '@core/app-rest-client';
import { useErrorsConverter } from '@core/errors';
import { useCustomerBalanceUpdateStore } from '@core/money';
import { useSiteConfigStore, useWebSocketsConfig } from '@core/site-config';
import { useWebSockets } from '@core/websockets';

import type { CashBackFull, UpdateCashbackProperties } from 'web/src/modules/promotions/store/types';
import { CashbackStatusType } from 'web/src/modules/profile/submodules/reward-center/components/types';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';

const useCashbackStore = defineStore('cashback', () => {
  const apiClient = useGraphqlClient();
  const { subscribeAuthorized } = useWebSockets();
  const { isCashbackEnabled } = useWebSocketsConfig();
  const { convertToBaseError } = useErrorsConverter();
  const cashbackFromPolling = toRef(useCustomerBalanceUpdateStore(), 'cashbackFromPolling');
  const isWeb2BonusCashbackWidgetEnabled = toRef(useSiteConfigStore(), 'isWeb2BonusCashbackWidgetEnabled');

  const timeout = ref(20000);
  const cashback = ref<CashBackFull>({});
  const cashbackAmount = ref(0);
  const isCashbackActive = ref(false);
  const isCashbackBeforeStart = ref(false);
  const isCashbackRefused = ref(false);
  const isCashbackRoundActiveNow = ref(false);
  const cashbackCounterTimestamp = ref(0);
  const isCurrentCashback = ref(false);
  const isCashbackSuspended = ref(false);

  // Getters:
  const actualRoundStartDate = computed<string>(() => cashback.value.cashback?.actualRoundStartDate || '');
  const actualRoundEndDate = computed(() => cashback.value.cashback?.actualRoundEndDate || '');
  const timestampStartDate = computed<number>(() => DateTime.toTimestamp(actualRoundStartDate.value));
  const timestampEndDate = computed<number>(() => DateTime.toTimestamp(actualRoundEndDate.value));
  const isCashbackParticipatingConfirmed = computed<boolean>(
    () => cashback.value.cashback?.confirmation.confirmationState === CashbackConfirmationState.CONFIRMED,
  );
  const isCashbackParticipatingNotConfirmed = computed<boolean>(
    () => cashback.value.cashback?.confirmation.confirmationState === CashbackConfirmationState.NOT_CONFIRMED,
  );

  const isCashbackAvailable = computed<boolean>(
    () => isCurrentCashback.value && isCashbackParticipatingConfirmed.value,
  );
  const cashbackPromotionUrl = computed<string>(() => cashback.value.cashback?.actionUrl || '');
  const cashbackPromotionCategoryId = computed<string>(() => cashback.value.cashback?.categoryId || '');
  const isCashbackDisplay = computed<boolean>(
    () => isCashbackParticipatingConfirmed.value && isCashbackActive.value && !isCashbackRefused.value,
  );

  const cashbackWagerCurrentAmount = computed(() => cashback.value.cashback?.wagerProgress?.currentAmount || 0);
  const cashbackWagerRequiredAmount = computed(() => cashback.value.cashback?.wagerProgress?.requiredAmount || 0);
  const cashbackCurrency = computed(() => cashback.value.cashback?.currency);

  const bonusAutoTransferEnabled = computed(() => cashback.value.cashback?.bonusAutoTransferEnabled ?? false);

  const cashbackRewardCenterStatus = computed<CashbackStatusType>(() => {
    if (!isCashbackParticipatingConfirmed.value || isCashbackParticipatingNotConfirmed.value) {
      return CashbackStatusType.NOT_ACTIVE;
    }

    if (
      isCashbackParticipatingConfirmed.value
      && isCashbackRoundActiveNow.value
    ) {
      return CashbackStatusType.COLLECT;
    }

    if (isCashbackSuspended.value) {
      return CashbackStatusType.WAGERING;
    }

    return CashbackStatusType.NOT_ACTIVE;
  });

  // Mutations:
  function setCashback(value: CashBackFull): void {
    cashback.value = value;
  }

  function setCashbackAmount(value: number): void {
    cashbackAmount.value = value;
  }

  function updateCashback(data: UpdateCashbackProperties): void {
    cashbackAmount.value = data.newProperties.amount || 0;
  }

  function setCashbackActive(value: boolean): void {
    isCashbackActive.value = value;
  }

  function setCashbackRefused(value: boolean): void {
    isCashbackRefused.value = value;
  }

  function updateCashbackCounterToEndDate(): void {
    cashbackCounterTimestamp.value = timestampEndDate.value;
  }

  function updateCashbackCounterToStartDate(): void {
    cashbackCounterTimestamp.value = timestampStartDate.value;
  }

  // Actions:

  async function getCashback(): Promise<void> {
    return new Promise((resolve, reject) => {
      getCashbackApi(apiClient, (node) => node.queries.cashback.getCashback).then((result) => {
        if (!isObject(result)) {
          logger.error(`Unexpected type: cashback is not object=${Json.stringify(result)}`);
        }
        setCashback(result ?? {});
        if (result && Object.keys(result).length !== 0) {
          setCashbackActive(true);

          const currentDateTime = Date.now();

          isCashbackBeforeStart.value = currentDateTime < timestampStartDate.value;
          isCashbackRoundActiveNow.value = currentDateTime >= timestampStartDate.value && currentDateTime < timestampEndDate.value;
          isCurrentCashback.value = currentDateTime >= timestampStartDate.value && currentDateTime < timestampEndDate.value;
          isCashbackSuspended.value = cashback.value.cashback?.suspended ?? false;

          if (isCashbackRoundActiveNow.value) {
            updateCashbackCounterToEndDate();
          } else {
            updateCashbackCounterToStartDate();
          }
        }
        resolve();
      }).catch((originalError) => {
        const error = convertToBaseError(originalError);

        const { code } = error;

        if (code.toString() === RegistrationApiErrorCode.TECH) {
          logger.error(error);
          resolve();
        } else if (code.toString() === 'NO_CAMPAIGN') {
          resolve();
        } else {
          reject(originalError);
        }
      });
    });
  }

  async function confirmCashback(): Promise<void> {
    const response = await doConfirmCashback(apiClient, (node) => node.mutations.cashback.confirmCashback);

    if (response) {
      await getCashback();
    }
  }

  async function refuseCashback(): Promise<void> {
    try {
      await Promise.all([
        doRefuseCashback(apiClient, (node) => node.mutations.cashback.refuseCashback),
        getCashback(),
      ]);
    } catch (rawError) {
      const error = convertToBaseError(rawError);
      throw new Error(error.message);
    }
  }

  function subscribeOnUpdatedCashback(): void {
    subscribeAuthorized({
      method: 'onUpdatedCashback',
      onMessage: (data) => {
        updateCashback({
          newProperties: {
            amount: data.onUpdatedCashback.amount,
          },
        });
      },
      isEnabled: isCashbackEnabled,
      polling: {
        timeout,
        callback: async () => {
          if (isWeb2BonusCashbackWidgetEnabled.value) {
            await getCashback();
          }
        },
        callOnLogin: true,
      },
    });
  }

  function subscribeOnRefusedCashback(): void {
    subscribeAuthorized({
      method: 'onRefusedCashback',
      onMessage: () => {
        setCashbackRefused(true);
      },
      isEnabled: isCashbackEnabled,
    });
  }

  function init(): void {
    subscribeOnUpdatedCashback();
    subscribeOnRefusedCashback();

    watch(cashbackFromPolling, (newValue) => {
      setCashbackAmount(newValue);
    }, {
      immediate: true,
    });
  }

  init();

  return {
    cashback,
    cashbackAmount,
    isCashbackActive,
    isCashbackRefused,
    isCashbackRoundActiveNow,
    cashbackCounterTimestamp,
    isCurrentCashback,
    isCashbackSuspended,
    // Getters:
    cashbackRewardCenterStatus,
    bonusAutoTransferEnabled,
    cashbackWagerCurrentAmount,
    cashbackWagerRequiredAmount,
    cashbackCurrency,
    timestampEndDate,
    isCashbackDisplay,
    actualRoundEndDate,
    timestampStartDate,
    isCashbackAvailable,
    actualRoundStartDate,
    cashbackPromotionUrl,
    isCashbackBeforeStart,
    cashbackPromotionCategoryId,
    isCashbackParticipatingConfirmed,
    isCashbackParticipatingNotConfirmed,
    // Mutations:
    setCashback,
    updateCashback,
    setCashbackAmount,
    setCashbackActive,
    setCashbackRefused,
    // Actions:
    getCashback,
    refuseCashback,
    confirmCashback,
    subscribeOnUpdatedCashback,
    subscribeOnRefusedCashback,
  };
});

export default useCashbackStore;
