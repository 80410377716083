import type {
  Ref,
  WatchStopHandle,
} from 'vue';
import {
  computed,
  nextTick,
  watch,
} from 'vue';

import type { SportFamily } from '@leon-hub/api-sdk';

import type {
  MarketsGridCell,
  MarketsGridFilter,
  MarketsGridFilterId,
} from 'web/src/modules/sportline/submodules/markets-grid/types';
import type {
  Market,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import { useIsOutrightSportlineEvent } from 'web/src/modules/sportline/composables/sportline-event';
import { getNonEmptyMarketsGridFilters } from 'web/src/modules/sportline/submodules/markets-grid/utils';

import type {
  MarketListToolbarReference,
} from '../types';
import { useMarketsListGroups } from './useMarketsListGroups';
import useMarketsListOpenStateStorage from './useMarketsListOpenStateStorage';
import useMarketsListResizableLayout from './useMarketsListResizableLayout';
import { useMarketsListTabsControl } from './useMarketsListTabsControl';

export interface MarketsListProps {
  toolbar: Ref<Maybe<MarketListToolbarReference>>;
  marketHolder: Ref<Optional<HTMLDivElement>>;
  columnOne: Ref<Optional<HTMLDivElement>>;
  columnTwo: Ref<Optional<HTMLDivElement>>;
  sportEvent: Ref<Maybe<SportlineEvent>>;
  representationSportFamily: Ref<Maybe<string | SportFamily>>;
  isLoaded: Ref<boolean>;
  defaultLayoutType: Ref<Maybe<DetailsLayoutType>>;
  isListItem: Ref<boolean>;
  isMarketGroupsTabsEnabled: Ref<boolean>;
  defaultMarketsLimit: Ref<number>;
  hasStatistic: Ref<boolean>;
  hasStatisticWidget: Ref<boolean>;
  isPrimaryMarketFiltrationDisabled: Ref<boolean>;
}

interface MarketsListComposable {
  hasAnyGroups: Ref<boolean>;
  isDisplayOneColumnLayout: Ref<boolean>;
  doShowInlineStatisticSwitch: Ref<boolean>;
  isStatisticSelected: Ref<boolean>;
  isStatisticWidgetSelected: Ref<boolean>;
  isEmptyMarketsList: Ref<boolean>;
  isMoreMarketButtonVisible: Ref<boolean>;
  gridFilters: Ref<MarketsGridFilter[]>;
  displayedMarketGroupTabId: Ref<MarketsGridFilterId>;
  filteredGroups: Ref<MarketsGridCell[]>;
  oddGroups: Ref<MarketsGridCell[]>;
  evenGroups: Ref<MarketsGridCell[]>;
  recalculateColumnsLayout(): void;
  onMarketTabClicked(value: MarketsGridFilterId): Promise<void>;
  onMoreMarketsClick(): Promise<void>;
  onStatisticSelectedChanged(value: boolean): void;
  onStatisticWidgetSelectedChanged(value: boolean): void;
  onMarketGroupToggle(groupKey: string): void;
  startWatchers(): void;
  stopWatchers(): void;
}

export function useMarketsList(props: MarketsListProps): MarketsListComposable {
  const {
    toolbar,
    marketHolder,
    columnOne,
    columnTwo,
    sportEvent,
    representationSportFamily,
    isLoaded,
    defaultLayoutType,
    isListItem,
    isMarketGroupsTabsEnabled,
    defaultMarketsLimit,
    hasStatistic,
    hasStatisticWidget,
    isPrimaryMarketFiltrationDisabled,
  } = props;

  const {
    markets,
    secondaryMarkets,
  } = useSportlineEventMarketsPriority({
    sportEvent,
    isPrimaryMarketFiltrationDisabled,
  });
  const {
    closedMarketGroups,
    toggleMarketGroupClosedStateInTheStorage,
  } = useMarketsListOpenStateStorage({
    representationSportFamily,
  });

  let marketGroupsWatcher: Maybe<WatchStopHandle> = null;
  let isLoadedWatcher: Maybe<WatchStopHandle> = null;

  const allMarketsList = computed<Market[]>(() => (process.env.VUE_APP_LAYOUT_DESKTOP && !isListItem?.value
    ? secondaryMarkets.value
    : markets.value));
  const isEmptyMarketsList = computed<boolean>(() => allMarketsList.value.length === 0);
  const isOnlyOneMarket = computed(() => allMarketsList.value.length <= 1);
  const gridFilters = computed<MarketsGridFilter[]>(() => {
    if (!isMarketGroupsTabsEnabled?.value) {
      return [];
    }

    return getNonEmptyMarketsGridFilters(sportEvent.value?.marketGroups, allMarketsList.value);
  });

  const isOutright = useIsOutrightSportlineEvent(sportEvent);
  const {
    allowedToSelectLayoutTypes,
    isDisplayOneColumnLayout,
    recalculateColumnsLayout,
  } = useMarketsListResizableLayout({
    isListItem,
    isOutright,
    isEmptyMarketsList,
    defaultLayoutType,
    isOnlyOneMarket,
    marketHolder,
    columnOne,
    columnTwo,
  });
  const {
    isAllTabSelected,
    isStatisticSelected,
    isStatisticWidgetSelected,
    activeGridFilter,
    isSelectedTabExistInMarketGroups,
    doShowInlineStatisticSwitch,
    displayedMarketGroupTabId,
    resetSelectedTabId,
    recalculateActiveTabId,
    setSelectedTabId,
    setIsStatisticSelected: onStatisticSelectedChanged,
    setIsStatisticWidgetSelected: onStatisticWidgetSelectedChanged,
  } = useMarketsListTabsControl({
    gridFilters,
    hasStatistic,
    hasStatisticWidget,
    allowedToSelectLayoutTypes,
  });

  const {
    isMoreMarketsClicked,
    isMoreMarketButtonVisible,
    filteredGroups,
    oddGroups,
    evenGroups,
    hasAnyGroups,
  } = useMarketsListGroups({
    allMarketsList,
    isAllTabSelected,
    activeGridFilter,
    closedMarketGroups,
    defaultMarketsLimit,
  });

  async function onMarketTabClicked(value: MarketsGridFilterId): Promise<void> {
    setSelectedTabId(value);
    await nextTick();
    recalculateColumnsLayout();
  }

  async function onMoreMarketsClick(): Promise<void> {
    isMoreMarketsClicked.value = true;
    await nextTick();
    recalculateColumnsLayout();
  }

  function startWatchers(): void {
    // @TODO check watch isSelectedTabExists
    marketGroupsWatcher = watch(gridFilters, async () => {
      if (isSelectedTabExistInMarketGroups.value) {
        return;
      }

      resetSelectedTabId();

      await nextTick();
      toolbar.value?.slideToActiveTab();
    }, { deep: true, immediate: true });

    isLoadedWatcher = watch(isLoaded, async (newValue: boolean) => {
      if (newValue) {
        recalculateActiveTabId();
      }

      await nextTick();
      recalculateColumnsLayout();
    }, { immediate: true });
  }

  function stopWatchers(): void {
    marketGroupsWatcher?.();
    marketGroupsWatcher = null;

    isLoadedWatcher?.();
    isLoadedWatcher = null;
  }

  return {
    hasAnyGroups,
    isDisplayOneColumnLayout,
    doShowInlineStatisticSwitch,
    isStatisticSelected,
    isStatisticWidgetSelected,
    isEmptyMarketsList,
    isMoreMarketButtonVisible,
    gridFilters,
    displayedMarketGroupTabId,
    filteredGroups,
    oddGroups,
    evenGroups,
    recalculateColumnsLayout,
    onMarketTabClicked,
    onMoreMarketsClick,
    onStatisticSelectedChanged,
    onStatisticWidgetSelectedChanged,
    onMarketGroupToggle(groupKey: string): void {
      toggleMarketGroupClosedStateInTheStorage(groupKey);
    },
    startWatchers,
    stopWatchers,
  };
}
