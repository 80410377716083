import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { useI18n } from '@core/i18n';

import type { SportlineCatalogClickComposable } from 'web/src/modules/sportline/composables/catalog';
import type { Sport, SportId } from 'web/src/modules/sportline/types';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';

import { defaultHeadlineBackgrounColor } from './constants';

interface UseSportHeadlineProps {
  sport: Ref<Maybe<Sport>>;
  customTitle?: Ref<Maybe<string>>;
  isComing: Ref<boolean>;
}

interface UseSportHeadlineComposable extends SportlineCatalogClickComposable {
  backgroundColor: Ref<string>;
  title: Ref<Maybe<string>>;
}

export function useSportHeadline(
  props: UseSportHeadlineProps,
): UseSportHeadlineComposable {
  const { sport, isComing } = props;
  const customTitle = props.customTitle ?? ref<Maybe<string>>(null);

  const { $translate } = useI18n();
  const sportId = computed<Maybe<SportId>>(() => sport.value?.id ?? null);
  const sportlineCatalogClick = useSportlineCatalogClick({ sportId });

  const { backgroundColor: defaultBackgroundColor } = useSportBackgroundColor({ sport });
  const backgroundColor = computed<string>(() => defaultBackgroundColor.value ?? defaultHeadlineBackgrounColor);

  const title = computed<Maybe<string>>(() => {
    if (customTitle.value) {
      return customTitle.value;
    }

    if (isComing.value) {
      return $translate('WEB2_COMING_EVENTS').value;
    }

    if (sport.value) {
      return sport.value.name;
    }

    return null;
  });

  return {
    ...sportlineCatalogClick,
    backgroundColor,
    title,
  };
}
