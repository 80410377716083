var PaymentsWithdrawalsType = /* @__PURE__ */ ((PaymentsWithdrawalsType2) => {
  PaymentsWithdrawalsType2["DEFAULT"] = "default";
  PaymentsWithdrawalsType2["INCOME_TAX"] = "incomeTax";
  return PaymentsWithdrawalsType2;
})(PaymentsWithdrawalsType || {});
var PaymentsNDFLType = /* @__PURE__ */ ((PaymentsNDFLType2) => {
  PaymentsNDFLType2["NETTO_AMOUNT"] = "nettoAmount";
  PaymentsNDFLType2["BRUTTO_AMOUNT"] = "bruttoAmount";
  PaymentsNDFLType2["TAX_AMOUNT"] = "taxAmount";
  return PaymentsNDFLType2;
})(PaymentsNDFLType || {});
export {
  PaymentsNDFLType,
  PaymentsWithdrawalsType
};
