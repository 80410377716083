<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import type { VStepDescriptionProps } from './types';
import { useVStepDescription } from './composables';
import { StepDescriptionKind, StepDescriptionSize } from './enums';

const props = withDefaults(defineProps<VStepDescriptionProps>(), {
  kind: StepDescriptionKind.BRAND,
  label: '',
  size: StepDescriptionSize.SIZE_32,
});

const { iconProperties } = useVStepDescription(props);
</script>

<template>
  <div v-auto-id="'VStepDescription'" :class="$style['step-description']">
    <div :class="$style['step-description__wrapper']">
      <div
        v-data-test="{ el: `step-description__circle ${step ? `step-description__circle-${step}` : ''}` }"
        :class="[
          $style['step-description__circle'],
          $style[`step-description__circle-${kind}`],
          $style[`step-description__circle-${size}`],
        ]"
      >
        <span v-if="step">{{ step }}</span>
        <VIcon
          v-else-if="iconName"
          v-bind="iconProperties"
        />
      </div>
      <div
        v-data-test="{ el: `step-description__label ${step ? `step-description__label-${step}` : ''}` }"
        :class="{
          [$style['step-description__label']]: true,
          [$style['step-description__label-step']]: step,
          [$style['step-description__label-icon']]: iconName,
        }"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </div>
    </div>

    <slot />
  </div>
</template>

<style lang="scss" module>
.step-description {
  @include stepDescription;

  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__circle {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;

    &-brand {
      color: $stepDescriptionCircleColorBrand;
      background-color: var(--BrandDefault);
    }

    &-blue {
      color: var(--DTextDefault);
      background-color: var(--Blue);
    }

    &-primary {
      color: var(--Layer0);
      background-color: var(--TextPrimary);
    }

    &-size-40 {
      width: 40px;
      height: 40px;
    }

    &-size-32 {
      @include medium\18\20;

      width: 32px;
      height: 32px;
    }

    &-size-24 {
      @include regular\14\20\025;

      width: 24px;
      height: 24px;
    }
  }

  &__label {
    &-icon {
      @include regular\14\20\025;

      color: var(--TextPrimary);
    }
  }
}
</style>
