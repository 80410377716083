import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VLinearProgressProps } from '../types';
import { ProgressHeight, ProgressKind } from '../enums';

export interface VLinearProgressComposable {
  iconProperties: VIconProps;
  percentage: ComputedRef<number>;
  barStyleObject: ComputedRef<Dictionary<string>>;
  classObject: ComputedRef<Dictionary<boolean>>;
}

export default function useVLinearProgress(props: VLinearProgressProps): VLinearProgressComposable {
  const iconProperties: Readonly<VIconProps> = {
    name: IconName.CHECK_FILLED,
    size: process.env.VUE_APP_LAYOUT_DESKTOP ? IconSize.SIZE_24 : IconSize.SIZE_16,
  };

  const percentage = computed(() => Math.floor((((props.value || 0) / (props.of || 100)) * 100)));

  const barStyleObject = computed(() => ({
    width: `${percentage.value}%`,
  }));

  const classObject = computed(() => {
    const baseClass = 'linear-progress';
    return {
      [baseClass]: true,
      [`linear-progress--${props.kind}`]: props.kind !== ProgressKind.DEFAULT,
      [`linear-progress--${props.height}`]: props.height !== ProgressHeight.DEFAULT,
      'linear-progress--no-shadow': !!props.noShadow,
    };
  });

  return {
    iconProperties,
    percentage,
    barStyleObject,
    classObject,
  };
}
