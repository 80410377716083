<script lang="ts" setup>
import type { FocusableInputRef } from '@leon-hub/focus';
import type { BaseInputEmits } from '@leon-hub/input-types';
import { InputMode } from '@leon-hub/input-types';

import createUniqueId from 'web/src/utils/createUniqueId';

import type { SmsCodeInputProps } from '../../types';
import useHintParentProps from '../../composables/useHintParentProps';
import InputBorderWrapper from '../InputBorderWrapper';
import InputWrapper from '../InputWrapper';
import TextFlatInput from '../TextFlatInput';
import useSmsCodeInput from './useSmsCodeInput';

defineOptions({
  inheritAttrs: false,
});
const props = withDefaults(defineProps<SmsCodeInputProps>(), {
  name: 'sms-code-input',
  value: '',
  size: 6,
});
const emit = defineEmits<BaseInputEmits>();

const uniqueId = createUniqueId(props.name);

const { hintProperties, hasError } = useHintParentProps(props);

const {
  inputValue: smsCodeValue,
  onPaste,
  onFocus,
  focusOnFirstSubfield,
  onBlur,
  onKeyDown,
  onInput,
  onMouseDown,
  focusIndex,
  hoverIndex,
  onMouseOver,
  onMouseLeave,
} = useSmsCodeInput(props, emit, uniqueId);

defineExpose<FocusableInputRef>({
  focus: focusOnFirstSubfield,
});
</script>

<template>
  <fieldset v-auto-id="'SmsCodeInput'"
    :class="$style['sms-code-input']"
  >
    <div :class="$style['sms-code-input__wrapper']">
      <InputWrapper
        v-data-test="{
          el: 'sms-code-input',
          name,
        }"
        v-bind="hintProperties"
        :class="$style['sms-code-input__raw-wrapper']"
      >
        <template #input>
          <div :class="$style['sms-code-input__inner']">
            <InputBorderWrapper
              v-for="(symbolFromValue, index) in smsCodeValue"
              :key="index"
              :class="$style['sms-code-input__border']"
              :is-focus="focusIndex === index"
              :is-hover="hoverIndex === index"
              :has-error="hasError"
            >
              <TextFlatInput
                :autofocus="index === 0 && autofocus"
                :input-id="`${uniqueId}-${index}`"
                :value="symbolFromValue"
                :name="`${name}-${index}`"
                :data-index="index + 1"
                :has-error="hasError"
                :autocomplete="null && index === 0 ? 'one-time-code' : 'off'"
                :placeholder="focusIndex === index ? '' : '—'"
                type="tel"
                no-padding
                :input-mode="InputMode.NUMERIC"
                :class="$style['sms-code-input__number']"
                @input="onInput($event, index)"
                @paste="onPaste($event, index)"
                @keydown="onKeyDown($event, index)"
                @mousedown="onMouseDown($event, index)"
                @blur="onBlur"
                @focus="onFocus(index)"
                @mouseleave="onMouseLeave"
                @mouseover="onMouseOver(index)"
              />
            </InputBorderWrapper>
          </div>
        </template>
      </InputWrapper>
    </div>
  </fieldset>
</template>

<style lang="scss" module>
@import '../../input-vars';

.sms-code-input {
  display: flex;
  padding: 18px 0 0;
  margin: 0;
  border: 0;

  &__inner {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
  }

  &__wrapper {
    margin: 0 auto;
  }

  &__raw-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__border {
    width: $inputSmsCodeWidth;
    padding: 0;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__number {
    text-align: center;
  }
}
</style>
