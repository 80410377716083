import type {
  Ref,
} from 'vue';
import {
  getCurrentInstance,
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
  toRef,
} from 'vue';

import type { SeoMetaParameters, SeoMetaParametersConfig } from 'web/src/modules/seo/types';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';

export interface SeoMetaParametersComposable {
  metaParameters: Ref<SeoMetaParameters>;
}

/**
 * Use to pass meta parameters defined in component
 */
export default function useSeoMetaParameters(params: Ref<SeoMetaParametersConfig>): SeoMetaParametersComposable {
  let removeParameters: Function | undefined;
  const seoMetaStorage = useSeoMetaStorage();
  const metaParameters = toRef(seoMetaStorage, 'metaParameters');

  function addMetaParameters(): void {
    const uid = getCurrentInstance()?.uid;

    if (!uid) {
      throw new Error('Component not initialized');
    }

    if (removeParameters) {
      // already set ref
      return;
    }

    seoMetaStorage.setMetaParameter(uid, params);
    removeParameters = () => { seoMetaStorage.setMetaParameter(uid, null); };
  }

  function unwatchMetaParameters(): void {
    removeParameters?.();
    removeParameters = undefined;
  }

  onBeforeMount(addMetaParameters);
  onActivated(addMetaParameters);

  onBeforeUnmount(unwatchMetaParameters);
  onDeactivated(unwatchMetaParameters);

  return {
    metaParameters,
  };
}
