import type { I18nFunctionReactive } from '@core/i18n';
import type { FormatMoneyFunc } from '@core/money';

import type { PaymentsInputProps } from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/types';

export default function getInputLabel(
  props: Pick<PaymentsInputProps, 'balance' | 'currency'>,
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
): string {
  return `${$t('JSPACC_ACC_BALANCE').value}: ${formatMoney(props.balance || 0, { currency: props.currency })}`;
}
