<script lang="ts" setup>
import { computed, onMounted } from 'vue';

import type { OptionalAnyIconName } from '@leon-hub/icons';
import { sleep } from '@leon-hub/utils';

import type { SHintBlockIconColor } from '@components/hint-block';
import { SHintBlock } from '@components/hint-block';

import LSnackbarContent from 'web/src/components/SnackBar/VSnackBar/components/LSnackbarContent/LSnackbarContent.vue';

import type { VSnackBarEmits, VSnackBarProps } from './types';

const props = withDefaults(defineProps<VSnackBarProps>(), {
  type: 'default',
});

const emit = defineEmits<VSnackBarEmits>();

onMounted(async () => {
  if (props.duration) {
    await sleep(props.duration * 1000);
    emit('click-close', new MouseEvent('click'));
  }
});

const hintIconColor = computed<SHintBlockIconColor>(() => {
  switch (props.type) {
    case 'error':
      return 'error';
    case 'success':
      return 'success';
    default:
      return 'primary';
  }
});

const iconName = computed<OptionalAnyIconName | undefined>(() => ('iconName' in props ? props.iconName : undefined));
</script>

<template>
  <div v-auto-id="'VSnackBar'"
    :class="{
      [$style['snack-bar']]: true,
      [$style['snack-bar--hidden-tabbar']]: isTabBarHidden,
      [$style['snack-bar--tooltip-index']]: isTooltipZIndex,
      [$style['snack-bar--max-z-index']]: isMaxZIndex,
    }"
  >
    <div
      v-if="null"
      :class="$style['snack-bar--wrapper']"
    >
      <SHintBlock
        type="secondary"
        :icon-name="iconName"
        :icon-color="hintIconColor"
        :title="title"
        :text="text"
        :image="image"
        :primary-button="buttonLabel ? { label: buttonLabel } : undefined"
        :is-done="isDone"
        :show-close="hasClose"
        :class="$style['snack-bar__content']"
        @click-close="emit('click-close', $event)"
        @click-primary="emit('click', $event)"
      />
      <div
        v-if="duration && duration > 0"
        :class="$style['progress-container']"
      >
        <div
          :class="$style['progress-bar']"
          :style="{ animationDuration: `${duration}s` }"
        />
      </div>
    </div>
    <LSnackbarContent
      v-else
      v-bind="props"
      :class="$style['snack-bar__content']"
      @click="emit('click', $event)"
      @click-close="emit('click-close', $event)"
      @click-secondary="emit('click-secondary', $event)"
      @click-link="emit('click-link', $event)"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  @include progress-bar;

  .snack-bar {
    @include z-index('snack-bar');

    position: fixed;
    right: 16px;
    bottom: if($isDesktop, $snackbarBottomDesktop, $snackbarBottomPhoneWithTabbar);
    left: 16px;
    display: flex;
    justify-content: center;
    max-height: 70%;
    pointer-events: none;

    &--wrapper {
      position: relative;
      overflow: hidden;
      pointer-events: all;
      border-radius: $snackBarRadius;
    }

    &--tooltip-index {
      @include z-index('tooltip');
    }

    &--max-z-index {
      @include z-index('max');
    }

    &--hidden-tabbar {
      @if not $isDesktop {
        bottom: $snackbarBottomPhoneWithoutTabbar;
      }
    }

    &__content {
      min-width: if($isDesktop, $snackbarMinWidthBig, $snackbarMinWidthSmall);
      max-width: $snackbarMaxWidthSmall;
      pointer-events: all;

      @include screen-desktop-medium-min {
        max-width: $snackbarMaxWidthBig;
      }
    }
  }
}
</style>
