import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type {
  GetCountersResponse,
  GetSportsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useCountSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import {
  SportsListAdapter,
} from 'web/src/modules/sportline/utils/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

const useSportsTreeStore = defineStore('sportline-sports-tree', () => {
  const fullSportsListResponse = ref<Maybe<Readonly<GetSportsResponse>>>(null);
  const countersResponse = ref<Maybe<GetCountersResponse>>(null);
  const parseSportlineSettings = useParseSportlineSettingsRef();
  const apiService = useSportlineApiService();

  const { timeout: countUpdateTimeout } = useCountSportlineUpdateTimeout();

  const isLoadingErrorOccurred = ref(false);

  const fullSportsList = computed(() => (fullSportsListResponse.value
    ? (new SportsListAdapter(
        fullSportsListResponse.value,
        parseSportlineSettings.value,
      )).getSports()
    : null));
  const isLoaded = computed(() => fullSportsListResponse.value !== null);
  const isCountersLoaded = computed(() => countersResponse.value !== null);
  const hasZeroMarginLeaguesInTheTree = computed(() => !!fullSportsList.value?.some((listElement) => !!listElement
    .regions
    ?.some((regionElement) => regionElement
      .leagues
      .some((leagueElement) => leagueElement.league.hasZeroMargin))));
  const hasZeroMargin = computed(() => (!!countersResponse.value?.zeroMarginMarkets
    || hasZeroMarginLeaguesInTheTree.value));

  const lastUpdate = new BackgroundUpdateStopwatch<['fullSportsList', 'counters']>({
    fullSportsList: async ({ silent }: { silent?: boolean }) => {
      try {
        fullSportsListResponse.value = await apiService.loadFullSportsTree({ silent });
        isLoadingErrorOccurred.value = false;
        lastUpdate.update('fullSportsList');
      } catch (error: unknown) {
        isLoadingErrorOccurred.value = true;
        throw error;
      }
    },
    counters: async ({ silent }: { silent?: boolean }) => {
      countersResponse.value = await apiService.loadCounters({ silent });
      lastUpdate.update('counters');
    },
  });

  /**
   * Will reload list in case when invalidateAfterInterval more the last update time
   */
  async function reloadFullSportsList({ silent, invalidateAfterInterval }: {
    silent?: boolean;
    invalidateAfterInterval: number;
  }): Promise<void> {
    await lastUpdate.callSyncAction('fullSportsList', invalidateAfterInterval, { silent });
  }

  async function reloadZeroMarginCounters({ silent }: { silent?: boolean }): Promise<void> {
    await lastUpdate.callSyncAction('counters', countUpdateTimeout.value, { silent });
  }

  return {
    fullSportsList,
    hasZeroMargin,
    isLoaded,
    isLoadingErrorOccurred,
    isCountersLoaded,
    zeroMarginUpdateInterval: countUpdateTimeout,
    reloadFullSportsList,
    reloadZeroMarginCounters,
  };
});

export default useSportsTreeStore;
