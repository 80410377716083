<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import type { VSocialSharingCopyLinkEmits, VSocialSharingCopyLinkProps } from './types';
import { useVSocialSharingCopyLink } from './composables';

withDefaults(defineProps<VSocialSharingCopyLinkProps>(), {});

const emit = defineEmits<VSocialSharingCopyLinkEmits>();

const {
  iconCopyProps,
} = useVSocialSharingCopyLink();
</script>

<template>
  <div v-auto-id="'VSocialSharingCopyLink'" :class="$style['social-sharing-copy-link']">
    <span
      v-if="label"
      :class="$style['social-sharing-copy-link__label']"
    >
      {{ label }}
    </span>

    <div
      :class="$style['social-sharing-copy-link__block']"
      @click="emit('click')"
    >
      <span
        :class="$style['social-sharing-copy-link__text']"
      >
        {{ link }}
      </span>
      <div :class="$style['social-sharing-copy-link__icon']">
        <VIcon v-bind="iconCopyProps" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.social-sharing-copy-link {
  @import '~web/src/components/Input/common';

  display: inline-flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;

  &__label {
    @include regular\14\24\025;

    color: var(--TextPrimary);
  }

  &__block {
    @include regular\14\16\025;

    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    padding-left: 16px;
    overflow: hidden;
    color: var(--TextDefault);
    cursor: pointer;
    background-color: var(--Layer2);
    border-radius: var(--BorderRadius);

    &:focus {
      @include inputBorderFocus;
    }
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 100%;
    color: var(--BrandText);
    background-color: var(--BrandDefault);
  }
}
</style>
