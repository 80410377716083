import type { Ref } from 'vue';
import { computed, nextTick, ref, toRef } from 'vue';

import { watchSms } from '@leon-hub/cordova';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';

import { useSiteConfigStore } from '@core/site-config';

import type { VButtonProps } from '@components/buttons';

import type { FormOutput } from 'web/src/components/Form';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import type { FormUiSchemaFieldSmsCode } from 'web/src/components/Form/types';
import type { VSmsNumberCheckProps } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/types';
import { getButtonProperties, getTimerString } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables/utils';
import getPageDescription from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables/utils/getPageDescription';

import type { VSmsNumberCheckEmits } from '../types/VSmsNumberCheckEmits';
import getTimerText from './utils/getTimerText';

export interface VSmsNumberCheckComposable {
  form: Ref<VFormRef | undefined>;
  formKey: Ref<number>;
  timer: Ref<string>;
  showButton: Ref<boolean>;
  timerText: string;
  buttonProperties: VButtonProps;
  pageDescription: string;
  onSubmit(data: FormOutput): void;
  emitInput(payload: FormOutput): FormOutput;
  onClick(): void;
  onComponentCreated(): void;
  onComponentMounted(): void;
  onComponentBeforeUnmount(): void;
  onBlur(data: FormOutput): void;
}

export default function useVSmsNumberCheck(
  props: Partial<VSmsNumberCheckProps>,
  emit: VSmsNumberCheckEmits,
): VSmsNumberCheckComposable {
  let showButtonInterval = 0;
  const smsResendCodeTimer = toRef(() => useSiteConfigStore().smsResendCodeTimer);
  const countDown: Ref<number> = ref(smsResendCodeTimer.value);
  const formKey: Ref<number> = ref(0);
  const showButton: Ref<boolean> = ref(true);
  const form = ref<VFormRef>();
  const buttonProperties = getButtonProperties(props);
  const timerText = getTimerText(props);
  const pageDescription = getPageDescription(props);

  const timer: Ref<string> = computed(() => getTimerString(countDown.value));

  function onComponentCreated(): void {
    // TODO: redo this logic in order use VTimer
    if (props.showButtonTime) {
      countDown.value = props.showButtonTime;
      startInterval();
    } else {
      emitShowHintWithEmailRedirect(true);
    }
  }

  function onComponentMounted(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      void watchSms()
        .then((code) => {
          if (!code)
            return;
          const field = props.uiSchema?.order?.[0];
          if (field && props.uiSchema?.fields?.[field]) {
            const smsCodeField = props.uiSchema.fields[field] as FormUiSchemaFieldSmsCode;
            smsCodeField.default = code;
            formKey.value += 1;
            // eslint-disable-next-line sonarjs/no-try-promise
            try {
              void nextTick(() => {
                form.value?.submit();
              });
            } catch (rawError) {
              const error = normalizeError(rawError);
              logger.error(error);
            }
          }
        });
    }
  }

  function onComponentBeforeUnmount(): void {
    if (showButtonInterval) {
      Timer.clearInterval(showButtonInterval);
      showButtonInterval = 0;
    }
    emitSetRetrySmsCodeTime();
  }

  function onSubmit(data: FormOutput): void {
    if (data && !data.errors) {
      emitSubmit(data);
    }
  }

  function onBlur(data: FormOutput): void {
    emit('blur', data);
  }

  function startCountDown(): void {
    if (countDown.value) {
      countDown.value -= 1;
    }
    if (countDown.value === (props.showButtonTime || smsResendCodeTimer.value) - (props.hintTimeout || smsResendCodeTimer.value)) {
      emitShowHintWithEmailRedirect(true);
    }
    if (countDown.value === 0) {
      emitShowHintWithEmailRedirect(true);
      countDown.value = props.showButtonTime || smsResendCodeTimer.value;
      if (showButtonInterval) {
        Timer.clearInterval(showButtonInterval);
        showButtonInterval = 0;
      }
      toggleShowButton();
    }
  }

  function startInterval(): void {
    showButtonInterval = Timer.setInterval(() => startCountDown(), 1000);
    toggleShowButton();
    emitShowHintWithEmailRedirect(false);
    formKey.value += 1;
  }

  function onClick(): void {
    emitRetryButtonClick();
    if ((process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && !props.isTimer) || !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      startInterval();
    }
  }

  function toggleShowButton(): void {
    showButton.value = !showButton.value;
  }

  function emitShowHintWithEmailRedirect(value: boolean): boolean {
    if (value) {
      emit('show-hint-with-email-redirect', true);
    }
    return value;
  }

  function emitSetRetrySmsCodeTime(): number {
    emit('set-retry-sms-code-time');
    return !showButton.value ? countDown.value : 0;
  }

  function emitRetryButtonClick(): void {
    emit('retry-button-click');
  }

  function emitSubmit(data: FormOutput): FormOutput {
    emit('submit', data);
    return data;
  }

  function emitInput(payload: FormOutput): FormOutput {
    emit('input', payload);
    return payload;
  }

  return {
    form,
    formKey,
    timer,
    showButton,
    timerText,
    buttonProperties,
    pageDescription,
    onSubmit,
    emitInput,
    onClick,
    onComponentCreated,
    onComponentMounted,
    onComponentBeforeUnmount,
    onBlur,
  };
}
