import type { InputHintProps } from '@leon-hub/input-types';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

type HintFields = Pick<InputHintProps, 'hint' | 'hintRight'>;

function getHintFields(
  options?: HintFields & { isHintRight?: boolean },
  hintText?: string,
): HintFields {
  const hint = options?.isHintRight ? undefined : options?.hint || hintText;

  assert(isOptionalString(hint), `Unexpected hint type: ${typeof hint}`);

  const hintRight = (options?.isHintRight && hintText) || options?.hintRight;

  assert(isOptionalString(hintRight), `Unexpected hintRight type: ${typeof hintRight}`);

  return {
    hint,
    hintRight,
  };
}

export default getHintFields;
