import type { ILobbyGameType, ILobbyType } from '@leon-hub/api-sdk';
import {
  lobbyGameTypeLive,
  lobbyGameTypeSlots,
  lobbyTypeFastgames,
  lobbyTypeLiveCasino,
  lobbyTypeSlots,
} from '@leon-hub/api-sdk';

export type { ILobbyType } from '@leon-hub/api-sdk';

export default function getLobbyTypeByCategoryGamesType(type?: Maybe<ILobbyGameType | ILobbyType>): ILobbyType {
  if (process.env.VUE_APP_FEATURE_CASINO_DISABLED) {
    return lobbyTypeFastgames;
  }

  switch (type) {
    case lobbyGameTypeLive:
      return lobbyTypeLiveCasino;
    case lobbyTypeFastgames:
      return lobbyTypeFastgames;
    case lobbyGameTypeSlots:
    default:
      return lobbyTypeSlots;
  }
}
