import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

interface UseRegistrationComplete {
  value: Ref<boolean>;
  set(state: boolean): void;
}

export const useRegistrationComplete = defineStore('registration-complete', (): UseRegistrationComplete => {
  const isRegistrationComplete = ref(false);
  return {
    value: readonly(isRegistrationComplete),
    set(value: boolean): void {
      isRegistrationComplete.value = value;
    },
  };
});
