import { normalizeError } from '@leon-hub/errors';
import { ensure, isObject, isString } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { ScriptElement } from '@leon-hub/utils';

export async function getSessionID(sdkUrl: string): Promise<{ session_id: string; helmetId: string } | null> {
  const scriptElement = new ScriptElement({ src: sdkUrl });
  await scriptElement.addScript();
  let deviceResult: unknown = null;
  let err: Error | undefined;
  try {
    // @ts-expect-error not typed.
    deviceResult = await window.getDeviceResult();
    const result = ensure(isObject, ensure(isObject, deviceResult).result);
    return {
      session_id: ensure(isString, result.session_id),
      helmetId: ensure(isString, ensure(isObject, result.device_intelligence).shield_id),
    };
  } catch (rawErr) {
    err = normalizeError(rawErr);
  }
  logger.error('[shield][fail] Unable to get shield session id', deviceResult, err);
  return null;
}
