import { AvailableBonusType } from '@leon-hub/api-sdk';

import type { CustomerBonusType } from 'web/src/modules/profile/submodules/bonuses/types';

function getBonusBalance (bonus: CustomerBonusType): number {
  const bonusType: AvailableBonusType = bonus?.availableBonusType;
  switch (bonusType) {
    case AvailableBonusType.FREEBET:
      return bonus?.freebet?.amount || 0;
    case AvailableBonusType.MAX_BONUS_AMOUNT:
      return bonus?.maxBonusAmount || 0;
    case AvailableBonusType.BONUS_AMOUNT:
      return bonus?.bonusAmount || 0;
    case AvailableBonusType.FREESPIN:
      return bonus?.freespin?.nominal || 0;
    default:
      return 0;
  }
}

export default getBonusBalance;
