import { useBaseApiClient } from '@core/app-rest-client';

import type { UploadDocumentInput, UploadDocumentResponse } from './types';
import { isUploadResponse } from './guards';

export interface VerificationApi {
  uploadDocument(input: UploadDocumentInput): Promise<UploadDocumentResponse>;
}

export default function createVerificationApi(): VerificationApi {
  const client = useBaseApiClient();

  return {
    async uploadDocument(input: UploadDocumentInput) {
      const formData = new FormData();
      formData.append('file', input.file, input.file.name);
      formData.append('reason', input.reason);
      formData.append('comment', input.comment);

      return client.request<UploadDocumentResponse>({
        silent: true,
        id: 'account/upload-doc',
        method: 'POST',
        endpoint: '/account/upload/doc',
        data: formData,
        guard: isUploadResponse,
        requestTimeout: 180_000,
      });
    },
  };
}
