import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useFormatMoney } from '@core/money';

import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';
import usePaymentsMaxValueFastChoice

  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
import usePaymentsSuggestedAmounts

  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsSuggestedAmounts';
import suggestedAmountsToFastChoices

  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/utils/suggestedAmountsToFastChoices';

interface PaymentsFastChoicesComposable {
  fastChoices: ComputedRef<FastMoneyChoiceOption[] | null>;
}

export default function usePaymentsFastChoices(props: PaymentsFastSumInputProps): PaymentsFastChoicesComposable {
  const {
    filteredDefaultSuggestedAmounts,
    filteredCurrentPaymentSystemSuggestedAmounts,
  } = usePaymentsSuggestedAmounts(props);
  const formatMoney = useFormatMoney();
  const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);

  const defaultFastChoices = computed(() => suggestedAmountsToFastChoices(
    filteredDefaultSuggestedAmounts.value,
    formatMoney,
  ));

  const currentPaymentSystemFastChoices = computed(() => suggestedAmountsToFastChoices(
    filteredCurrentPaymentSystemSuggestedAmounts.value,
    formatMoney,
  ));

  function removeMaxFastChoiceValue(choices: FastMoneyChoiceOption[]): FastMoneyChoiceOption[] {
    return choices.filter((item) => item.value !== maxValueFastChoice.value);
  }

  const fastChoices = computed(() => {
    if (currentPaymentSystemFastChoices.value) {
      return removeMaxFastChoiceValue(currentPaymentSystemFastChoices.value);
    }

    return defaultFastChoices.value ? removeMaxFastChoiceValue(defaultFastChoices.value) : null;
  });

  return {
    fastChoices,
  };
}
