<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { PasswordValidateTemplateItemStatus } from 'web/src/components/PasswordValidator/enums';

import type { PasswordValidatorProps } from './types';
import useVPasswordValidator from './composables/useVPasswordValidator';

const props = withDefaults(defineProps<PasswordValidatorProps>(), {
  groups: () => [],
  password: '',
  successMessage: '',
  error: '',
});

const { templates } = useVPasswordValidator(props);
</script>

<template>
  <ul v-auto-id="'PasswordValidator'" :class="$style['password-validator']">
    <li
      v-for="template in templates"
      :key="template.message"
      :class="{
        [$style['password-validator__line']]: true,
        [$style['password-validator__line--success']]: template.status === PasswordValidateTemplateItemStatus.Success,
        [$style['password-validator__line--error']]: template.status === PasswordValidateTemplateItemStatus.Error,
      }"
    >
      <VIcon
        :class="$style['password-validator__icon']"
        :size="IconSize.SIZE_16"
        :name="template.status === PasswordValidateTemplateItemStatus.Success ? IconName.CHECK : IconName.DOT"
      />
      <span :class="$style['password-validator__message']">{{ template.message }}</span>
    </li>
  </ul>
</template>

<style lang="scss" module>
.password-validator {
  @include regular\12\16;

  padding: 0;
  margin: $passwordValidatorMargin;
  color: $passwordValidatorDefaultColor;
  list-style: none inside;

  &__line {
    display: flex;
    align-items: center;
    padding: 0;
    margin: $passwordValidatorLineMargin;

    &--error {
      color: $passwordValidatorErrorColor;
    }

    &--success {
      color: $passwordValidatorSuccessColor;
    }
  }

  &__icon {
    flex: none;
    margin: $passwordValidatorIconMargin;
    margin-right: 8px;
  }

  &__message {
    flex: 1;
  }
}
</style>
