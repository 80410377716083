<script lang="ts" setup>
import { VImage } from '@components/image';
import { VLogoLoader } from '@components/loader';

import BonusLoyaltyProgramBalance
  from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';

import type { BonusLoyaltyProgramCardEmits, BonusLoyaltyProgramCardProps } from './types';
import { useBonusLoyaltyProgramCard } from './composables';

const props = withDefaults(defineProps<BonusLoyaltyProgramCardProps>(), {
  name: '',
  balance: 0,
  imageSrc: '',
  image: null,
  formattedBalance: '',
});

const emit = defineEmits<BonusLoyaltyProgramCardEmits>();

const {
  iconName,
  onBonusLoyaltyProgramCardClick,
  hasImageObject,
} = useBonusLoyaltyProgramCard(props, emit);
</script>

<template>
  <div v-auto-id="'BonusLoyaltyProgramCard'"
    :class="{
      [$style['bonus-loyalty-program-card']]: true,
      [$style['bonus-loyalty-program-card--locked']]: isLocked,
    }"
    @click="onBonusLoyaltyProgramCardClick"
  >
    <span
      :class="$style['bonus-loyalty-program-card__title']"
      :title="name"
    >{{ name }}</span>
    <div :class="$style['bonus-loyalty-program-card__img-wrapper']">
      <VImage
        v-if="hasImageObject && image"
        :class="$style['bonus-loyalty-program-card__img']"
        :src="image.src"
        :x1="image.x1"
        :x2="image.x2"
        :x3="image.x3"
        :x1webp="image.x1webp"
        :x2webp="image.x2webp"
        :x3webp="image.x3webp"
        :alt="name"
      />
      <img
        v-else-if="imageSrc"
        :class="$style['bonus-loyalty-program-card__img']"
        :alt="name"
        :src="imageSrc"
      >
      <VLogoLoader
        v-else
        :class="$style['bonus-loyalty-program-card__placeholder']"
        :has-animation="false"
      />
    </div>
    <BonusLoyaltyProgramBalance
      :text="formattedBalance || String(balance)"
      :icon-name="iconName"
      :is-inactive="isLocked"
      has-no-hover
      @click="onBonusLoyaltyProgramCardClick()"
    />
  </div>
</template>

<style lang="scss" module>
.bonus-loyalty-program-card {
  $self: &;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background-color: var(--Layer1);
  border-radius: 5px;

  @include for-hover {
    &:hover {
      .bonus-loyalty-program-balance {
        &:not(&--inactive) {
          background-color: var(--OpacityLayer2);
        }
      }
    }
  }

  &__title {
    @include medium\16\20\025;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--TextDefault);
    text-align: center;
    white-space: nowrap;
  }

  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    padding: 10px;
    overflow: hidden;
  }

  &__img {
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__placeholder {
    width: 80px;
    height: auto;
  }

  &--locked {
    cursor: auto;

    #{$self}__title {
      color: var(--TextSecondary);
    }

    #{$self}__img-wrapper {
      opacity: 0.3;
    }
  }
}
</style>
