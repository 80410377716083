import {
  isObject,
  isOptionalString,
} from '@leon-hub/guards';

import type { PartialFormPhoneValue } from '../types/common';

function isPartialFormPhoneValue (value: unknown): value is PartialFormPhoneValue {
  return isObject(value)
  && isOptionalString(value.prefix)
  && isOptionalString(value.suffix)
  && isOptionalString(value.countryCode)
}

export default isPartialFormPhoneValue;
