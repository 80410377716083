<script lang="ts" setup>
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import {
  useCasinoLoyaltyProgramLevel,
} from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevel/composables';

import type { CasinoLoyaltyProgramLevelProps } from './types';

const props = defineProps<CasinoLoyaltyProgramLevelProps>();

const { label, iconName } = useCasinoLoyaltyProgramLevel(props);

const iconProperties = computed<VIconProps>(() => ({
  name: iconName.value,
  size: IconSize.SIZE_16,
}));
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramLevel'"
    v-if="label"
    :class="{
      [$style['casino-loyalty-program-level']]: true,
      [$style[`casino-loyalty-program-level--${status}`]]: true,
    }"
  >
    <VIcon
      :class="$style['casino-loyalty-program-level__icon']"
      v-bind="iconProperties"
    />
    <span
      :class="$style['casino-loyalty-program-level__label']"
    >{{ label }}</span>
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-level {
  display: flex;
  column-gap: 4px;
  align-items: center;

  &--success {
    color: var(--BrandDefault);
    fill: var(--BrandDefault);
  }

  &--process {
    color: var(--TextPrimary);
    fill: var(--TextPrimary);
  }

  &--lock {
    color: var(--TextSecondary);
    fill: var(--TextSecondary);
  }

  &__label {
    @include regular\13\16;

    max-width: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
