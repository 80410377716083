import type { VInputInputEvent } from '@leon-hub/input-types';
import { assert } from '@leon-hub/guards';

export default function nativeSelectItemToVInputInputEvent({ target }: Event, name = ''): VInputInputEvent {
  assert(target instanceof HTMLSelectElement);
  const { value } = target;
  return {
    target: {
      value,
      name,
    },
  };
}
