import type { Ref } from 'vue';
import { onMounted, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { logger } from '@leon-hub/logging';

import { useShareEventLogging } from '../../../composable';
import { useSlipShareStore } from '../store';

interface UseSlipShareRoutePage {
  bookingCode: Ref<string | null>;
  imageUrl: Ref<string | null>;
  shareLink: Ref<string | null>;
  amountSwitcherIsVisible: Ref<boolean>;
  showAmountEnabled: Ref<boolean>;
  isSharingPlacedBet: Ref<boolean>;
  isPending: Ref<boolean>;
  errorMessage: Ref<string | null>;
  closeModal(): void;
  canselShare(): void;
  onShowAmountClick(value: boolean): void;
  handleLogShareSuccess(): void;
  handleLogLinkCopied(): void;
}

export function useSlipShareRoutePage(): UseSlipShareRoutePage {
  const router = useRouter();

  const slipShareStore = useSlipShareStore();

  const { canselShare, regenerateSharePicture } = slipShareStore;

  const bookingCode = toRef(slipShareStore, 'bookingCode');

  const imageUrl = toRef(slipShareStore, 'imageUrl');

  const shareLink = toRef(slipShareStore, 'shareLink');

  const shareModalShown = toRef(slipShareStore, 'shareModalShown');

  const isSlipShareEnabled = toRef(slipShareStore, 'isSlipShareEnabled');

  const amountSwitcherIsVisible = toRef(slipShareStore, 'amountSwitcherIsVisible');

  const showAmountEnabled = toRef(slipShareStore, 'showAmountEnabled');

  const isSharingPlacedBet = toRef(slipShareStore, 'isSharingPlacedBet');

  const isPending = toRef(slipShareStore, 'isPending');

  const errorMessage = toRef(slipShareStore, 'errorMessage');

  const shareSuccessLogData = toRef(slipShareStore, 'shareSuccessLogData');

  const closeModal = (): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.back();
    } else {
      void router.closeModal();
    }
  };

  onMounted(() => {
    if (!shareModalShown.value || !isSlipShareEnabled.value) {
      closeModal();
    }
  });

  const onShowAmountClick = (needToShow: boolean): void => {
    regenerateSharePicture(needToShow);
  };

  const { logShareSuccess, logLinkCopied } = useShareEventLogging();

  const logEmptySuccessDataError = (methodName: string): void => {
    logger.error(`${methodName} triggered with empty slipShareStore/shareSuccessLogData`);
  };

  const handleLogShareSuccess = (): void => {
    if (shareSuccessLogData.value) {
      logShareSuccess(shareSuccessLogData.value);
    } else {
      logEmptySuccessDataError('logShareSuccess');
    }
  };

  const handleLogLinkCopied = (): void => {
    if (shareSuccessLogData.value) {
      logLinkCopied(shareSuccessLogData.value);
    } else {
      logEmptySuccessDataError('logLinkCopied');
    }
  };

  return {
    bookingCode,
    imageUrl,
    shareLink,
    amountSwitcherIsVisible,
    showAmountEnabled,
    isSharingPlacedBet,
    isPending,
    errorMessage,
    closeModal,
    canselShare,
    onShowAmountClick,
    handleLogShareSuccess,
    handleLogLinkCopied,
  };
}
