import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import type { UIField, UIFormSchema } from '@leon-hub/api-sdk';
import type { FormSchema } from '@leon-hub/form-utils';
import {
  CustomerFieldType,
  getIncompleteRegistrationFields,
  updateIncompleteRegistrationFields,
} from '@leon-hub/api-sdk';
import { FormControlType, isFormSchema } from '@leon-hub/form-utils';
import { assert } from '@leon-hub/guards';

import { useGraphqlClient } from '@core/app-rest-client';
import { useCountryStore } from '@core/country';
import { useI18n } from '@core/i18n';

import type { FormUiSchema } from 'web/src/components/Form';
import type { FormData } from 'web/src/components/Form/types';
import { isUIFormSchema } from 'web/src/components/Form/guards';
import { mapFormData } from 'web/src/components/Form/utils';
import { useCountry } from 'web/src/modules/country/composables';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import getFormSelectorCountryCode from 'web/src/modules/registration/utils/getFormSelectorCountryCode';
import {
  getUiFieldRawOptions,
  JsonSchemaAdapter,
} from 'web/src/utils/jsonSchemaAdapter';
import StringUtils from 'web/src/utils/StringUtils';

import type { IncompleteFieldsRequestOptions, IncompleteRegistrationFieldsUiSchema } from './types';

export const useRegistrationCompletionStore = defineStore('registration-completion-completion', () => {
  const apiClient = useGraphqlClient();
  const { $translate } = useI18n();
  const { country } = useCountry();
  const geoIpCountryCode = toRef(useCountryStore(), 'geoIpCountryCode');

  const schema = ref<string>();
  const schemaId = ref<string>('');
  const uiSchema = ref<IncompleteRegistrationFieldsUiSchema>();

  const preparedFormSchemas = computed<{
    schema: FormSchema;
    uiSchema: FormUiSchema;
  } | undefined>(() => {
    if (!schema.value || !uiSchema.value) {
      return undefined;
    }
    const jsonSchema = JSON.parse(schema.value);

    assert(isFormSchema(jsonSchema));
    assert(isUIFormSchema(uiSchema.value));

    const jsonSchemaAdapter = new JsonSchemaAdapter(uiSchema.value, jsonSchema, {
      /*
      * compared to default mapping keys - there is no mapping for "hidden"
      * Don't sure it have a sense
      *  */
      default: 'defaultValue',
      disabled: 'disabled',
      title: 'title',
      widget: 'widget',
      options: 'options',
      labels: 'labels',
    })
    // TODO: can leave this only for FormControlType.SelectWithSearch after backend changes (LB-14443)
      .addPropertiesByWidgetGroup([
        FormControlType.Select,
        FormControlType.SelectWithSearch,
      ], {
        default: getFormSelectorCountryCode(geoIpCountryCode.value, uiSchema.value),
      })
      .addPropertiesById(CustomerFieldType.COUNTRY_SELECT, () => ({
        ...(process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED
          ? {}
          : {
              default: getFormSelectorCountryCode(geoIpCountryCode.value, uiSchema.value as UIFormSchema),
            }),
      }))
      .addOptionsByWidget(FormControlType.SelectWithSearch, {
        isCountrySelector: true,
        modalViewHeader: useI18n().$translate('WEB2_REGISTRATION_COMPLETION_COUNTRY_TITLE').value,
      })
      .addPropertiesByWidget(FormControlType.Date, (field) => ({
        default: field?.defaultValue ?? '',
        options: {
          emitFilled: true,
          isBirthday: true,
        },
      }))
      .addPropertiesByWidget(FormControlType.AutocompleteAddress, (field: UIField) => {
        const optionsData = getUiFieldRawOptions(field.options) ?? {};
        const citySearchPlaceholder = optionsData?.isCityType
          ? $translate('WEB2_AUTOCOMPLETE_ADDRESS_SEARCH_CITY').value
          : undefined;
        return {
          options: {
            ...optionsData,
            isCityType: optionsData?.isCityType,
            regexp: process.env.VUE_APP_PRODUCT_LEONRU ? '^[0-9а-яА-ЯЁё\\s-]*$' : undefined,
            disallowUserInput: process.env.VUE_APP_PRODUCT_LEONRU && optionsData?.isCityType,
            modalCaption: citySearchPlaceholder,
            modalPlaceholder: citySearchPlaceholder,
            countryCode: country.value,
          },
        };
      })
      .addPropertiesByWidgetGroup([
        FormControlType.Phone,
        FormControlType.PhoneInput,
        FormControlType.PhoneCountriesSelectorInput,
      ], (field: UIField) => {
        const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
        return {
          default: parsePhoneNumberByUIField(field),
        };
      });

    return {
      schema: jsonSchemaAdapter.getFormSchema(),
      uiSchema: jsonSchemaAdapter.getFormUiSchema(),
    };
  });

  async function fetchIncompleteRegistrationFields(
    {
      customerAction,
      paymentSystemId,
    }: IncompleteFieldsRequestOptions,
  ): Promise<boolean> {
    const response = await getIncompleteRegistrationFields(apiClient, (node) => node.queries.registration.getIncompleteRegistrationFields, {
      options: {
        customerAction: customerAction ? StringUtils.kebabCaseToCamelCase(customerAction) : null,
        paymentSystemId,
      },
    });

    if (!response.uiFormSchema.fields.length) {
      return false;
    }

    schema.value = response.formValidationSchema;
    uiSchema.value = response.uiFormSchema;
    schemaId.value = response.schemaId;

    return true;
  }

  function updateIncompleteFields(
    formData: FormData,
  ): ReturnType<typeof updateIncompleteRegistrationFields> {
    return updateIncompleteRegistrationFields(apiClient, (node) => node.mutations.registration.updateIncompleteRegistrationFields, {
      options: {
        schemaId: schemaId.value,
        formParams: mapFormData(formData),
      },
    });
  }

  return {
    preparedFormSchemas,
    uiSchema,
    fetchIncompleteRegistrationFields,
    updateIncompleteFields,
  };
});
