import type { FormatMoneyFunc } from '@core/money';

import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';

export default function suggestedAmountsToFastChoices(
  suggestedAmounts: number[] | null,
  formatMoney: FormatMoneyFunc,
): FastMoneyChoiceOption[] | null {
  if (!suggestedAmounts)
    return null;
  return suggestedAmounts.map((item) => ({ label: formatMoney(item), value: item }));
}
