import type { IntercomSettings } from 'web/src/modules/intercom/composables/types';
import { getIntercom } from 'web/src/modules/intercom/composables/utils';

interface UseWebIntercomChatComposable {
  loadScript(settings: IntercomSettings): Promise<void>;
}

export default function useWebIntercom(): UseWebIntercomChatComposable {
  let intercomScript: HTMLScriptElement | null = null;

  async function loadScript(settings: IntercomSettings): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!intercomScript) {
        const intercomScriptText = `(function(){
    var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);
    }else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);}
    ;w.Intercom=i;var l=function(){var s=d.createElement('script');
    s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${settings.app_id}';
    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}
    ;if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);
    }else{w.addEventListener('load',l,false);}}})();`;

        intercomScript = document.createElement('script');
        intercomScript.text = intercomScriptText;

        intercomScript.onload = () => {
          resolve();
        };

        intercomScript.onerror = () => {
          reject(new Error('Intercom script load error'));
        };

        document.head.append(intercomScript);
        getIntercom()?.('update', settings);
      }
    });
  }

  return {
    loadScript,
  };
}
