<script lang="ts" setup>
import type {
  SearchSportResultsEmits,
  SearchSportResultsProps,
} from 'web/src/modules/search/submodules/sportline/components/SearchSportResults/types';
import type { SearchSportEvent } from 'web/src/modules/sportline/types';
import SearchListSection
  from 'web/src/modules/search/submodules/sportline/components/SearchListSection/SearchListSection.vue';

withDefaults(defineProps<SearchSportResultsProps>(), {
  searchText: '',
  liveSearchResults: () => ([]),
  prematchSearchResults: () => ([]),
});

const emits = defineEmits<SearchSportResultsEmits>();

function emitClickEvent(event: SearchSportEvent): void {
  emits('click-event', event);
}
</script>

<template>
  <div v-auto-id="'SearchSportResults'">
    <SearchListSection
      v-if="liveSearchResults.length"
      :caption="$t('WEB2_SPORTLINE_LIVE_EVENTS')"
      :search-text="searchText"
      :list="liveSearchResults"
      @item-click="emitClickEvent"
    />
    <SearchListSection
      v-if="prematchSearchResults.length"
      :caption="$t('WEB2_SPORTLINE_PREMATCH_EVENTS')"
      :search-text="searchText"
      :list="prematchSearchResults"
      @item-click="emitClickEvent"
    />
  </div>
</template>
