import noop from 'lodash/noop';

interface Options {
  callbackIfVisible?(): void;
  callbackIfHidden?(): void;
  callbackIfUnavailable?(): void;
  observerOptions?: IntersectionObserverInit;
}

export default function doAfterScrollVisibilityCheck(element: HTMLElement, options: Options): void {
  const defaultOptions: Options = {
    callbackIfVisible: noop,
    callbackIfHidden: noop,
    callbackIfUnavailable: noop,
  };
  const combinedOptions = {
    ...defaultOptions,
    ...options,
  };
  let observer: IntersectionObserver | null = null;
  if (typeof window.IntersectionObserver === 'function') {
    const disconnect = (): void => {
      observer?.disconnect();
      observer = null;
    };
    observer = new IntersectionObserver((entries) => {
      const visible = entries[0]?.isIntersecting;
      if (visible) {
        combinedOptions.callbackIfVisible?.();
      } else {
        combinedOptions.callbackIfHidden?.();
      }
      disconnect();
    }, combinedOptions.observerOptions);
    observer.observe(element);
  } else {
    combinedOptions.callbackIfUnavailable?.();
  }
}
