import type { Ref } from 'vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { getCordovaAppConfig, shareWithOptions } from '@leon-hub/cordova';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

export interface ShareModalComposable {
  isShareModal: Ref<boolean>;
  showShareModal({ title, url }: { title: string; url: string }): Promise<void>;
  closeShareModal(): void;
}

export default function useShareModal(redirectUrlName?: string): ShareModalComposable {
  const router = useRouter();
  const isShareModal = ref(false);

  const showShareModal = async ({ title, url }: { title: string; url: string }) => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const { deepLinks } = getCordovaAppConfig();
      const { webIntent } = deepLinks.intents;
      const { pathname } = new URL(url);

      shareWithOptions({
        subject: title,
        url: `${webIntent}${pathname}`,
      }, () => {
        redirectTo();
      }, (error: Error) => {
        const err = normalizeError(error);

        logger.error(err);

        if (err.name === 'AbortError') {
          redirectTo();
        }
      });
      return;
    }

    if (!process.env.VUE_APP_LAYOUT_DESKTOP && navigator.share) {
      try {
        await navigator.share({
          title,
          url,
        });
        redirectTo();
      } catch (rawError) {
        const error = normalizeError(rawError);

        logger.error(error);

        if (error.name === 'AbortError') {
          redirectTo();
        }
      }
    } else {
      isShareModal.value = true;
    }
  };

  function redirectTo() {
    if (redirectUrlName) {
      void router.push({ name: redirectUrlName });
    }
  }

  function closeShareModal(): void {
    isShareModal.value = false;
    redirectTo();
  }

  return {
    isShareModal,
    showShareModal,
    closeShareModal,
  };
}
