import { assert } from '@leon-hub/guards';

export function getMatchedSmsInputSubfield (id: string): HTMLInputElement | null {
  const input = document.getElementById(id);
  if (!input) {
    return null;
  }
  assert(input instanceof HTMLInputElement);
  return input;
}
