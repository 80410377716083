import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { EventActionGoogleAnalytics, useAnalytics, useGoogleAnalytics } from '@core/analytics';
import { PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';
import { useCurrency, useFormatMoney } from '@core/money';
import { useRouterStore } from '@core/router';

import { ButtonKind } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';

import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';

export interface UseWithdrawalCancelComposable {
  handleCancelWithdrawal(isFullWithdrawal?: boolean): void;
  sendAnalyticsWhenPopupSetVisible(): void;
}

export function useWithdrawalCancel(): UseWithdrawalCancelComposable {
  const withdrawalStore = useWithdrawalStore();
  const customerHistoryStore = useCustomerHistoryStore();
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();
  const router = useRouter();
  const { push } = useAnalytics();
  const { pushGTM } = useGoogleAnalytics();
  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();

  const routerStore = useRouterStore();

  const withdrawalAmount = ref<number>();
  const totalCount = ref<number>(1);
  const currentRouteName = toRef(() => routerStore.currentRouteName);
  const paymentId = toRef(() => withdrawalStore.latestPaymentId);
  const amountMoney = toRef(() => withdrawalStore.submittedAmountAsMoney);
  const amountMoneyNumber = toRef(() => withdrawalStore.submittedAmount);

  const withdrawalsAnalyticsDataByClick = computed(() => getYMAnalyticsData(true));
  const withdrawalsAnalyticsDataForVisible = computed(() => getYMAnalyticsData(false));
  const withdrawalAmountValue = computed(() => withdrawalAmount.value ?? amountMoneyNumber.value);

  function getYMAnalyticsData(byClick: boolean): Record<string, unknown> {
    const routeNameValue = String(currentRouteName.value);
    const currencyValue = currency.value;

    return {
      withdrawals: byClick
        ? {
            withdrawalCancellationClick: {
              location: {
                [routeNameValue]: {
                  withdrawals_count: {
                    [totalCount.value]: {
                      [currencyValue]: withdrawalAmountValue.value,
                    },
                  },
                },
              },
            },
          }
        : {
            withdrawalCancellationPopUp: {
              location: routeNameValue,
            },
          },
    };
  }

  function handleCancelWithdrawal(isFullWithdrawal?: boolean): void {
    if (paymentId.value && !isFullWithdrawal) {
      void cancelSingleWithdrawal(paymentId.value);
      sendAnalyticsByClick();
      void router.push({ name: RouteName.PROFILE });
    } else {
      void cancelAllWithdrawals();
    }
  }

  const amountCount = computed<Record<string, string>>(() => ({ amount: `${withdrawalAmount.value ? formatMoney(withdrawalAmount.value) : amountMoney.value}` }));

  function showSuccessModal(): void {
    showDialog({
      presetName: PresetName.ALERT_SUCCESS,
      options: {
        title: $translate('JSPNAV_USTAT_WITHDRAWAL_CANCEL_OK').value,
        confirmMessage: $translate('WEB2_WTHDRAWAL_SUCCESS_MODAL_DESCR', amountCount).value,
        width: ModalWidth.SMALL,
        isCentered: false,
        buttons: [
          {
            label: $translate('WEB2_CONTINUE').value,
            kind: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'secondary' : ButtonKind.SECONDARY,
          },
        ],
      },
    });
  }

  function sendAnalyticsWhenPopupSetVisible(): void {
    push(AnalyticsEvent.Z_WITHDRAWAL_CANCELLATION_POPUP, withdrawalsAnalyticsDataForVisible.value);

    pushGTM(AnalyticsEvent.WITHDRAWAL_CANCELLATION_POPUP, {
      event: AnalyticsEvent.WITHDRAWAL_CANCELLATION_POPUP,
      event_action: EventActionGoogleAnalytics.MODAL_POPUP,
      event_label: `${String(currentRouteName.value)} modal`,
    });
  }

  function sendAnalyticsByClick(): void {
    push(AnalyticsEvent.Z_WITHDRAWAL_CANCELLATION_CLICK, withdrawalsAnalyticsDataByClick.value);

    pushGTM(AnalyticsEvent.WITHDRAWAL_CANCELLATION, {
      event: AnalyticsEvent.WITHDRAWAL_CANCELLATION,
      event_action: EventActionGoogleAnalytics.REQUEST_CANCELLATION,
      event_label: `${String(currentRouteName.value)} modal`,
      withdrawals_count: totalCount.value,
      withdrawals_value: withdrawalAmount.value ?? amountMoneyNumber.value,
      currency: currency.value,
    });
  }

  async function cancelAllWithdrawals(): Promise<void> {
    await withdrawalStore.cancelRequestedWithdrawals().then((response) => {
      if (response.totalCount) {
        withdrawalAmount.value = response.totalAmount;
        totalCount.value = response.totalCount;
        showSuccessModal();
        sendAnalyticsByClick();
      }
    });
  }

  async function cancelSingleWithdrawal(paymentId: number): Promise<void> {
    await customerHistoryStore.doWithdrawalCancel({ paymentId }).then(() => {
      showSuccessModal();
      withdrawalStore.setPaymentId(0);
    });
  }

  return {
    handleCancelWithdrawal,
    sendAnalyticsWhenPopupSetVisible,
  };
}
