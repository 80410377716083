import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { IconNameType } from '@leon-hub/icons';

import getPasswordInputIcon from './utils/getPasswordInputIcon';

interface UsePasswordInput {
  isTextVisible: Ref<boolean>;
  toggleTextVisibility(): void;
  iconName: ComputedRef<IconNameType>;
}

export default function usePasswordInput(): UsePasswordInput {
  const isTextVisible = ref<boolean>(false);

  const toggleTextVisibility = (): void => {
    isTextVisible.value = !isTextVisible.value;
  };

  const iconName = computed<IconNameType>(() => getPasswordInputIcon(isTextVisible.value));

  return {
    isTextVisible,
    toggleTextVisibility,
    iconName,
  };
}
