import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import type { LiveStatus } from '@leon-hub/api-sdk';
import {
  BetLine,
  BetType,
  OutcomeState,
} from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';

import type { VBadgeProps } from '@components/badge';
import { BadgeKind } from '@components/badge';

import type { CurrentScoreType } from 'web/src/modules/profile/submodules/customer-history/types';
import type {
  SportEventDetailsRouteLocationParameters,
} from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportEventMatchProgress } from 'web/src/modules/sportline/types';
import {
  currentScore,
  defaultDateTimeFormat,
  formatInnings,
} from 'web/src/modules/profile/submodules/customer-history/utils';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { createSportEventLiveStatusInfo } from 'web/src/modules/sportline/utils/rest/build';

import type { CustomerHistoryDetailsScoreBoardProps } from '../types';

export interface CustomerHistoryDetailsScoreBoardComposable {
  isSingle: Ref<boolean>;
  isExpress: Ref<boolean>;
  isLive: Ref<boolean>;
  competitors: Ref<string>;
  betKind: Ref<string>;
  header: Ref<string>;
  showScoreDetails: Ref<boolean>;
  homeMainScore: Ref<string>;
  awayMainScore: Ref<string>;
  additionalScore: Ref<Maybe<CurrentScoreType>>;
  additionalScoreBadgeProperties: Ref<VBadgeProps>;
  currentPhaseScore: Ref<string>;
  statusBadgeProperties: Ref<VBadgeProps>;
  kickoff: Ref<string>;
  isTimeReversedIndicatorAvailable: Ref<boolean>;
  goToEvent(): void;
  gameScoreProgress: Ref<Optional<string>>;
  isStaticLiveProgress: Ref<boolean>;
  isTimeReversed: Ref<boolean>;
  staticDirectTimeProgressLabel: Ref<Optional<string>>;
  staticReversedTimeProgressLabel: Ref<Optional<string>>;
  stage: Ref<string>;
}

export default function useCustomerHistoryDetailsScoreBoard(props: CustomerHistoryDetailsScoreBoardProps): CustomerHistoryDetailsScoreBoardComposable {
  const { $translate } = useI18n();
  const router = useRouter();

  const isTimeReversedIndicatorAvailable = toRef(useSportlineSettingsStore(), 'isTimeReversedIndicatorAvailable');
  const matchProgress = ref<Maybe<SportEventMatchProgress>>();

  const {
    gameScoreProgress,
    isStaticLiveProgress,
    isTimeReversed,
    staticDirectTimeProgressLabel,
    staticReversedTimeProgressLabel,
    stage,
  } = useLiveStatus(matchProgress);

  const isSingle = computed<boolean>(() => props.transaction?.betType === BetType.SINGLE);
  const isExpress = computed<boolean>(() => props.transaction?.betType === BetType.EXPRESS);
  const isSystem = computed<boolean>(() => props.transaction?.betType === BetType.SYSTEM);
  const isLive = computed<boolean>(() => props.transaction.outcomeState === OutcomeState.IN_PLAY);
  const isVirtual = computed<boolean>(() => props.transaction.betLine === BetLine.VSPORTS);
  const competitors = computed<string>(() => {
    const { betEventParts } = props.transaction;
    return betEventParts?.length === 2 ? `${betEventParts[0]} - ${betEventParts[1]}` : '';
  });
  const betKindBtrParams = computed(() => ({
    COUNT: `${props.transaction.betItems?.length || props.transaction?.countItems}`,
  }));
  const betKind = computed<string>(() => {
    let text = '';
    if (isExpress.value) {
      text = $translate('WEB2_BET_EXPRESS_MULTIPLICITY', betKindBtrParams).value;
    }
    if (isSystem.value) {
      text = props.transaction.combiStatusText || '';
    }
    return text;
  });
  const header = computed<string>(() => props.transaction.betLeague || '');
  const mainScore = computed<Maybe<CurrentScoreType>>(() => {
    const { liveStatus, matchResult } = props.transaction;
    if (matchResult?.score) {
      return matchResult.score;
    }
    if (liveStatus?.score) {
      return currentScore(liveStatus.score);
    }
    return null;
  });
  const showScoreDetails = computed<boolean>(() => {
    if (!mainScore.value) {
      return false;
    }
    const { home, away } = mainScore.value;
    return !!home && !!away;
  });
  const homeMainScore = computed<string>(() => (mainScore.value?.home ? formatInnings(mainScore.value.home) : ''));
  const awayMainScore = computed<string>(() => (mainScore.value?.away ? formatInnings(mainScore.value.away) : ''));
  const additionalScore = computed<Maybe<CurrentScoreType>>(() => {
    const { matchResult } = props.transaction;
    if (matchResult?.penaltyScore) {
      return matchResult.penaltyScore;
    }
    if (matchResult?.extraTimeMatchScore) {
      return matchResult.extraTimeMatchScore;
    }
    return null;
  });
  const additionalScoreBadgeProperties = computed<VBadgeProps>(() => {
    const { matchResult } = props.transaction;
    let label = '';
    if (matchResult?.penaltyScore) {
      label = $translate('WEB2_BET_PENALTY').value;
    }
    if (matchResult?.extraTimeMatchScore) {
      label = $translate('WEB2_BET_ET_MATCH').value;
    }

    return {
      label,
      kind: BadgeKind.SQUARE_HIGHLIGHT,
    };
  });
  const currentPhaseScore = computed<string>(() => {
    const { liveStatus, matchResult } = props.transaction;
    if (matchResult?.phaseScores) {
      return matchResult.phaseScores
        .map((item) => `${item.score.home || ''}:${item.score.away || ''}`)
        .join('; ');
    }
    return liveStatus?.setScores || '';
  });

  const statusBadgeProperties = computed<VBadgeProps>(() => {
    let label: string;

    switch (props.transaction.outcomeState) {
      case OutcomeState.IN_PLAY:
        label = 'LIVE';
        break;
      case OutcomeState.PRE_GAME:
        label = $translate('WEB2_BET_START').value;
        break;
      default:
        label = $translate('WEB2_BET_ENDED').value;
        break;
    }

    return {
      label,
      kind: BadgeKind.SQUARE,
    };
  });

  const kickoff = computed<string>(() => defaultDateTimeFormat(props.transaction.kickoff) || '');
  const eventUrl = computed<Maybe<SportEventDetailsRouteLocationParameters>>(() => {
    const { eventUrl: urlTransaction } = props.transaction;
    if (!urlTransaction) {
      return null;
    }
    return {
      id: `${urlTransaction.eventId}`,
      leagueUrlName: urlTransaction?.league || '',
      regionUrlName: urlTransaction?.region || '',
      sportFamily: urlTransaction?.sport || '',
      urlName: urlTransaction?.event || '',
    };
  });

  function goToEvent(): void {
    if (eventUrl.value && !isVirtual.value) {
      void router.push({
        name: RouteName.SPORT_EVENT_DETAILS,
        params: {
          ...eventUrl.value,
          // LEONWEB-5650 seems this logic is deprecated,
          // because the back button always returns to the previous page if it is possible
          isForcedBackLink: '1',
        },
      });
    }
  }

  watch(() => props.transaction.liveStatus, (liveStatus: Maybe<LiveStatus>) => {
    if (liveStatus) {
      matchProgress.value = createSportEventLiveStatusInfo(liveStatus).matchProgress;
    }
  }, {
    deep: true,
    immediate: true,
  });

  return {
    isSingle,
    isExpress,
    isLive,
    competitors,
    betKind,
    header,
    showScoreDetails,
    homeMainScore,
    awayMainScore,
    additionalScore,
    additionalScoreBadgeProperties,
    currentPhaseScore,
    statusBadgeProperties,
    kickoff,
    goToEvent,
    gameScoreProgress,
    isStaticLiveProgress,
    isTimeReversed,
    staticDirectTimeProgressLabel,
    staticReversedTimeProgressLabel,
    stage,
    isTimeReversedIndicatorAvailable,
  };
}
