import { AdditionalInputEventType } from '@leon-hub/input-types';

import type {
  TextFlatInputEmits,
} from '../types';

interface UseAdditionalInputEvents {
  emitMousedown(event: Event): void;
  emitKeydown(event: KeyboardEvent): void;
  emitMouseover(event: Event): void;
  emitMouseleave(event: Event): void;
  emitPaste(event: ClipboardEvent): void;
}

export default function useAdditionalInputEvents(emit: TextFlatInputEmits): UseAdditionalInputEvents {
  const emitMousedown = (event: MouseEvent): void => {
    emit(AdditionalInputEventType.MOUSEDOWN, event);
  };

  const emitKeydown = (event: KeyboardEvent): void => {
    emit(AdditionalInputEventType.KEYDOWN, event);
  };

  const emitMouseover = (event: MouseEvent): void => {
    emit(AdditionalInputEventType.MOUSEOVER, event);
  };

  const emitMouseleave = (event: MouseEvent): void => {
    emit(AdditionalInputEventType.MOUSELEAVE, event);
  };

  const emitPaste = (event: ClipboardEvent): void => {
    emit(AdditionalInputEventType.PASTE, event);
  };

  return {
    emitMousedown,
    emitKeydown,
    emitMouseover,
    emitMouseleave,
    emitPaste,
  };
}
