import type { InputWrappedProps } from '@leon-hub/input-types';
import { assert, isString } from '@leon-hub/guards';

import type { FormData } from 'web/src/components/Form/types';

export default function getOptionsRelatedToSiblingFields<T = InputWrappedProps>(
  optionName: string,
  fieldId?: string,
  formData?: FormData,
  options: Partial<T> = {},
): Partial<T> {
  if (fieldId && formData?.[fieldId]) {
    const relatedValue = formData[fieldId];
    if (relatedValue) {
      assert(isString(relatedValue));
      return {
        ...options,
        [optionName]: relatedValue,
      };
    }
  }
  return options;
}
