import { slipTypeIdSingle } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../../slip-info/types';
import type { ShareCurrentSlipPayload } from '../types';
import { getMakeBetEntryItems } from '../../place-bet/utils/getMakeBetEntryItems';
import { getOddsStringForLog } from './getOddsStringForLog';

export function getLastPlacedSinglesSharePayload(lastPlacedSingles: SlipEntry[]): ShareCurrentSlipPayload {
  return {
    slipEntries: getMakeBetEntryItems(lastPlacedSingles),
    slipType: 's',
    betMode: slipTypeIdSingle,
    odds: getOddsStringForLog(lastPlacedSingles.map((entry) => entry.oddsStr ?? '-')),
  };
}
