<script setup lang="ts">
import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type {
  VSocialSharingItemEmits,
  VSocialSharingItemProps,
} from 'web/src/components/SocialSharing/VSocialSharingItem/types';

import useVSocialSharingItem from './composables/useVSocialSharingItem';

const props = withDefaults(defineProps<VSocialSharingItemProps>(), {
  description: '',
  hashtags: '',
  backgroundColor: 'rgba(38, 211, 103, 1)',
  popup: () => ({
    width: 626,
    height: 436,
  }),
});

const emit = defineEmits<VSocialSharingItemEmits>();

const {
  networkName,
  handleClick,
  iconName,
} = useVSocialSharingItem(props, emit);

const iconSize = null ? IconSize.SIZE_32 : IconSize.SIZE_52;
</script>

<template>
  <a v-auto-id="'VSocialSharingItem'"
    :class="{
      [$style['social-sharing-item']]: true,
      [$style[`social-sharing-item--${networkName}`]]: !!networkName,
    }"
    @click="handleClick"
  >
    <div
      :class="$style['social-sharing-item__icon']"
      :style="{ 'background': backgroundColor }"
    >
      <VIcon
        v-if="icon"
        :name="iconName"
        :size="iconSize"
      />
    </div>
    <div :class="$style['social-sharing-item__name']">
      {{ networkName }}
    </div>
  </a>
</template>

<style lang="scss" module>
.social-sharing-item {
  display: inline-flex;
  flex-direction: column;
  gap: $socialSharingItemGap;
  align-items: center;
  text-decoration: none;

  @include for-hover {
    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $socialSharingIconSize;
    height: $socialSharingIconSize;
    color: $socialSharingIconColor;
    border: 1px solid $socialSharingIconBorderColor;
    border-radius: 50%;
  }

  &__name {
    @include shareNameFont;

    color: $socialSharingNameColor;
    text-align: center;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
