<script lang="ts" setup>
import {
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  ref,
  toRef,
} from 'vue';

import { VSpinner } from '@components/spinner';

import type { MarketsGridCell } from 'web/src/modules/sportline/submodules/markets-grid/types';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { getMarketsSpecifiersKey } from 'web/src/modules/sportline/submodules/markets-grid/utils';
import MarketsListToolbar from 'web/src/modules/sportline/views/markets-list/components/MarketsListToolbar.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

import type { MarketListToolbarReference, MarketsListProps, MarketsListSlots } from './types';
import { useMarketsList } from './composables/useMarketsList';
import { useMarketsListAnalytics } from './composables/useMarketsListAnalytics';
import { useMarketsListReadyToRenderObserver } from './composables/useMarketsListReadyToRenderObserver';
import { useMarketsListTabsShowingState } from './composables/useMarketsListTabsShowingState';
import { unitTestElement } from './constants';
import MarketsListMarketGroup from './MarketGroup.vue';

const props = withDefaults(defineProps<MarketsListProps>(), {
  sportEvent: null,
  representationSportFamily: null,
  defaultMarketsLimit: 0,
  isLoaded: false,
  isShowingLoadingIndicator: false,
  isListItem: false,
  isMarketGroupsTabsEnabled: false,
  hasStatistic: false,
  defaultLayoutType: null,
});
defineSlots<MarketsListSlots>();

const sportEvent = toRef(() => props.sportEvent);
const representationSportFamily = toRef(() => props.representationSportFamily);
const defaultMarketsLimit = toRef(() => props.defaultMarketsLimit);
const isLoaded = toRef(() => props.isLoaded);
const isShowingLoadingIndicator = toRef(() => props.isShowingLoadingIndicator);
const isListItem = toRef(() => props.isListItem);
const isMarketGroupsTabsEnabled = toRef(() => props.isMarketGroupsTabsEnabled);
const hasStatistic = toRef(() => props.hasStatistic);
const defaultLayoutType = toRef(() => props.defaultLayoutType);
const isStatisticWidgetAvailable = toRef(() => props.isStatisticWidgetAvailable);
const hasStatisticWidget = toRef(() => props.hasStatisticWidget);
const isPrimaryMarketFiltrationDisabled = toRef(() => props.isPrimaryMarketFiltrationDisabled);

const toolbar = ref<Maybe<MarketListToolbarReference>>(null);
const marketHolder = ref<Optional<HTMLDivElement>>();
const columnOne = ref<Optional<HTMLDivElement>>();
const columnTwo = ref<Optional<HTMLDivElement>>();

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();

const {
  hasAnyGroups,
  isDisplayOneColumnLayout,
  doShowInlineStatisticSwitch,
  isEmptyMarketsList,
  isStatisticSelected,
  isStatisticWidgetSelected,
  isMoreMarketButtonVisible,
  gridFilters,
  displayedMarketGroupTabId,
  filteredGroups,
  oddGroups,
  evenGroups,
  recalculateColumnsLayout,
  onMarketTabClicked,
  onMoreMarketsClick,
  onStatisticSelectedChanged,
  onStatisticWidgetSelectedChanged,
  onMarketGroupToggle,
  startWatchers,
  stopWatchers,
} = useMarketsList({
  toolbar,
  marketHolder,
  columnOne,
  columnTwo,
  sportEvent,
  representationSportFamily,
  isLoaded,
  defaultLayoutType,
  isListItem,
  isMarketGroupsTabsEnabled,
  defaultMarketsLimit,
  hasStatistic,
  hasStatisticWidget,
  isPrimaryMarketFiltrationDisabled,
});

const {
  isDisplayStatisticColumn,
  isDisplayStatisticWidgetComponent,
  isDisplayInlineStatisticComponent,
  doShowTabs,
  stopShowTabsWatchers,
  startShowTabsWatchers,
} = useMarketsListTabsShowingState({
  gridFilters,
  hasStatistic,
  hasStatisticWidget,
  isStatisticSelected,
  isStatisticWidgetSelected,
  isEmptyMarketsList,
});

const {
  isReadyToRender,
  setIsActivated,
} = useMarketsListReadyToRenderObserver({
  marketHolder,
  recalculateColumnsLayout,
});

const {
  startAnalyticsWatchers,
  stopAnalyticsWatchers,
} = useMarketsListAnalytics({
  isDisplayStatistic: isDisplayInlineStatisticComponent,
});

function getMarketsGridCellRenderKey(cell: MarketsGridCell): string {
  return `${cell.id}|${getMarketsSpecifiersKey(cell.markets)}`;
}

startWatchers();
startShowTabsWatchers();
startAnalyticsWatchers();

onActivated(() => {
  setIsActivated(true);
});
onDeactivated(() => {
  setIsActivated(false);
});
onMounted(() => {
  setIsActivated(true);
});
onUnmounted(() => {
  stopWatchers();
  stopShowTabsWatchers();
  stopAnalyticsWatchers();
  setIsActivated(false);
});
</script>

<template>
  <div v-auto-id="'MarketsList'"
    ref="marketHolder"
    v-data-test-unit="{ el: unitTestElement.holder }"
    :class="{
      [$style['markets-list']]: true,
      [$style['markets-list--large-gaps']]: null && isBetSwipeEventDetailsLayoutEnabled,
    }"
  >
    <div v-if="!isReadyToRender" />
    <template v-else-if="isLoaded">
      <MarketsListToolbar
        v-if="doShowTabs"
        ref="toolbar"
        v-data-test-unit="{ el: unitTestElement.toolbar }"
        :grid-filters="gridFilters"
        :active-market-group-id="displayedMarketGroupTabId"
        :do-show-all-markets-tab="hasAnyGroups"
        :do-show-inline-statistic-switch="doShowInlineStatisticSwitch"
        :has-statistic="hasStatistic"
        :has-statistic-widget="hasStatisticWidget"
        :is-statistic-selected="isStatisticSelected"
        :is-statistic-widget-selected="isStatisticWidgetSelected"
        :class="{
          [$style['markets-list-toolbar']]: true,
          [$style['markets-list-toolbar--list-item']]: isListItem,
        }"
        @market-tab-click="onMarketTabClicked"
        @change-statistic-selected="onStatisticSelectedChanged"
        @change-statistic-widget-selected="onStatisticWidgetSelectedChanged"
      />
      <div
        v-else-if="isDisplayStatisticColumn"
        :class="$style['markets-list-separator']"
      />

      <div
        :class="{
          [$style['markets-list-content__markets']]: true,
          [$style['hidden']]: !hasAnyGroups && !hasStatistic,
          [$style['markets-list-content__markets--2-columns']]: !isDisplayOneColumnLayout,
        }"
      >
        <div
          v-if="!isDisplayOneColumnLayout"
          ref="columnOne"
          :class="$style['markets-list-content__column']"
        >
          <template v-if="isDisplayStatisticColumn">
            <MarketsListMarketGroup
              v-for="group in filteredGroups"
              :key="getMarketsGridCellRenderKey(group)"
              :group="group"
              :sportline-event="sportEvent"
              @market-group-toggle="onMarketGroupToggle"
            />
          </template>
          <template v-else>
            <MarketsListMarketGroup
              v-for="group in oddGroups"
              :key="getMarketsGridCellRenderKey(group)"
              :group="group"
              :sportline-event="sportEvent"
              @market-group-toggle="onMarketGroupToggle"
            />
          </template>
        </div>

        <div
          ref="columnTwo"
          :class="$style['markets-list-content__column']"
        >
          <slot
            v-if="isStatisticWidgetAvailable"
            name="statistic-widget"
            :is-displayed="isDisplayStatisticWidgetComponent"
          />

          <template v-if="isDisplayStatisticColumn">
            <template v-if="isDisplayInlineStatisticComponent">
              <slot name="statistic" />
              <slot name="post-statistic" />
            </template>
          </template>
          <template v-else>
            <template v-if="!isDisplayOneColumnLayout">
              <MarketsListMarketGroup
                v-for="group in evenGroups"
                :key="getMarketsGridCellRenderKey(group)"
                :group="group"
                :sportline-event="sportEvent"
                @market-group-toggle="onMarketGroupToggle"
              />
            </template>
            <template v-else>
              <MarketsListMarketGroup
                v-for="group in filteredGroups"
                :key="getMarketsGridCellRenderKey(group)"
                :group="group"
                :sportline-event="sportEvent"
                @market-group-toggle="onMarketGroupToggle"
              />
            </template>
          </template>
        </div>
      </div>

      <div
        v-if="isListItem"
        :class="{
          [$style['markets-list-content__show-more-markets']]: true,
          [$style['markets-list-content__show-more-markets--hidden']]: !isMoreMarketButtonVisible,
        }"
        @click="onMoreMarketsClick"
      >
        {{ $t('WEB2_SHOW_MORE') }}
      </div>
    </template>
    <div
      v-else-if="isShowingLoadingIndicator"
      :class="$style['markets-list-content__loader']"
    >
      <VSpinner />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .markets-list {
    --markets-list-gap: 8px;
    --markets-list-sides: 8px;
    --markets-list-toolbar-sides: 0;

    &--large-gaps {
      --markets-list-gap: 12px;
      --markets-list-sides: 16px;
      --markets-list-toolbar-sides: 16px;
    }
  }

  .markets-list-separator {
    padding-bottom: 8px;
  }

  .markets-list-toolbar {
    padding: if($isDesktop, 0, 0 var(--markets-list-toolbar-sides));
    margin-top: if($isDesktop, 12px, 8px);
    margin-bottom: if($isDesktop, 12px, 8px);

    &--list-item {
      @if $isDesktop {
        background-color: var(--Layer1);
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .markets-list-content {
    $self: '.markets-list-content';

    &-market-list {
      width: 100%;

      &__menu {
        @if $isDesktop {
          background-color: transparent;
          border-radius: 0;
        }
      }
    }

    &__loader {
      @include z-index('online-chat');

      display: flex;
      justify-content: center;
      padding-top: 75px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: var(--markets-list-gap);
    }

    &__markets {
      @include medium\14\16\025;

      padding: if($isDesktop, 0, 0 var(--markets-list-sides));
      margin-top: var(--markets-list-gap);
      color: var(--TextDefault);

      &--2-columns {
        display: flex;
        gap: var(--markets-list-gap);

        & > #{$self}__column {
          flex: 1;
          max-width: calc(50% - var(--markets-list-gap) / 2);
        }
      }
    }

    &__show-more-markets {
      @include medium\14\16\025;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      margin-top: var(--markets-list-gap);
      margin-bottom: 18px;
      color: var(--TextPrimary);
      text-transform: uppercase;
      cursor: pointer;
      background-color: var(--Button);
      border-radius: 5px;

      &--hidden {
        display: none;
      }
    }
  }
}
</style>
