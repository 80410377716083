import { InputFileTypes } from '@leon-hub/input-types';

import type { InputFileTypeRecords } from 'web/src/components/Input';

import { getFileExtension } from './getFileExtension';

export function isFileImage(allowedTypes: InputFileTypeRecords, file: File): boolean {
  if (Object.keys(allowedTypes).length === 0) {
    return false;
  }

  const extension = getFileExtension(file);

  if (!extension) {
    return false;
  }

  const fileType = allowedTypes[extension];
  if (!fileType) {
    return false;
  }

  return fileType.type === InputFileTypes.Image;
}
