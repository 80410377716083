import {
  FormControlType,
} from '@leon-hub/form-utils';

import {
  ButtonHeight,
  ButtonKind,
  ButtonType,
} from '@components/buttons';

import type {
  FormUiSchema,
  FormUiSchemaField,
  FormUiSchemaSubmitButton,
} from '../types';

// don't show button if we set submitButton to null
export function hasSubmitButton(uiSchema: FormUiSchema): boolean {
  const { submitButton } = uiSchema;

  return submitButton !== null;
}

export function getSubmitButtonProperties(
  uiSchema: FormUiSchema,
): FormUiSchemaSubmitButton {
  const buttonSchema: FormUiSchemaSubmitButton = uiSchema.submitButton ?? {};
  const defaultSchema: FormUiSchemaSubmitButton = {
    type: ButtonType.SUBMIT,
    kind: ButtonKind.PRIMARY,
    label: 'Submit',
    disabled: false,
    fullWidth: true,
    height: ButtonHeight.LARGE,
  };

  return { ...defaultSchema, ...buttonSchema };
}

export function getUiSchemaField(uiSchema: FormUiSchema, field: string): FormUiSchemaField {
  const { fields } = uiSchema;
  const defaultSchema: FormUiSchemaField = {
    widget: FormControlType.Text,
  };

  if (!fields) {
    return defaultSchema;
  }

  const schema: FormUiSchemaField = fields[field] || {};

  if (!schema.widget) {
    return {
      ...schema,
      widget: FormControlType.Text,
    };
  }

  return schema;
}

export function getLabel(uiSchema: FormUiSchema, field: string): string {
  return getUiSchemaField(uiSchema, field)?.title || '';
}
