<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { StakeInputEmits, StakeInputProps } from './types';
import useStakeInput from './useStakeInput';
import useStakeInputLabel from './useStakeInputLabel';

const props = withDefaults(defineProps<StakeInputProps>(), {
  currencySymbol: '$',
  value: '0',
  maximumFractionDigits: 2,
});

const emits = defineEmits<StakeInputEmits>();

const {
  onFocus,
  onBlur,
  onClear,
  onPaste,
  onInput,
  onEnterKeydown,
  inputElement,
  isClearButtonVisible,
  isCurrencySymbolVisible,
  inputValue,
} = useStakeInput(props, emits);

const { widthMeter, isLabelHidden } = useStakeInputLabel(inputValue, props);
</script>

<template>
  <div v-auto-id="'StakeInput'"
    :class="{
      [$style['stake-input']]: true,
      [$style['stake-input--focused']]: isFocused,
      [$style['stake-input--error']]: haveError,
    }"
    @click="onFocus"
  >
    <div :class="$style['stake-input__inner']">
      <label
        v-show="!isLabelHidden"
        :class="$style['stake-input__label']"
      >
        {{ $t('JSPBET_PLBET_STAKE_SHORT') }}
      </label>
      <input
        ref="inputElement"
        :value="inputValue"
        type="tel"
        :readonly="!!null"
        :class="$style['stake-input__value']"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @paste="onPaste"
        @keydown.enter="onEnterKeydown"
      >
      <div
        ref="widthMeter"
        aria-hidden="true"
        :class="$style['stake-input__hidden']"
      >
        <span
          :class="$style['stake-input__hidden-label']"
        >{{ $t('JSPBET_PLBET_STAKE_SHORT') }}</span>
        <span
          :class="$style['stake-input__hidden-value']"
        >{{ inputValue }}</span>
      </div>
    </div>
    <span
      v-if="null"
      v-show="isFocused"
      :class="$style['stake-input__fake-caret']"
    />
    <span :class="$style['stake-input__currency']">
      <span
        v-show="isCurrencySymbolVisible"
        :class="$style['stake-input__currency-symbol']"
      >{{ currencySymbol }}</span>
      <span
        v-show="isClearButtonVisible"
        :class="$style['stake-input__clear']"
      >
        <button
          :class="$style['stake-input__clear-button']"
          type="button"
          tabIndex="-1"
          @mousedown.prevent="onClear"
        >
          <VIcon
            :name="IconName.CANCEL"
            :size="IconSize.SIZE_16"
          />
        </button>
      </span>
    </span>
  </div>
</template>

<style lang="scss" module>
$caretColor: var(--Blue);

@keyframes caret-blink {
  0% {
    background: $caretColor;
  }

  49% {
    background: $caretColor;
  }

  50% {
    background: none;
  }

  100% {
    background: none;
  }
}

.stake-input {
  // NB: design uses light theme colors in both themes
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 0 0 12px;
  color: var(--LTextDefault);
  background-color: var(--LLayer2);
  border-radius: var(--BorderRadius);
  transition: background-color 0.15s $animationEaseInOut;

  &--error {
    &,
    &:hover {
      color: var(--TextDefault);
      background-color: var(--ErrorField);
    }
  }

  &--error &__currency {
    color: var(--TextDefault);
  }

  &--focused &__currency {
    color: var(--LTextDefault);
  }

  &--focused {
    box-shadow: 0 0 0 1px var(--Blue);

    &,
    &:hover {
      color: var(--LTextDefault);
      background-color: var(--LLayer1);
    }
  }

  &__inner {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
  }

  &__label,
  &__hidden-label {
    @include regular\14\16\025;

    margin-right: 12px;
  }

  &__label {
    display: flex;
    flex: none;
    flex-direction: column;
    pointer-events: none;
  }

  &__currency,
  &__value,
  &__fake-selection-text,
  &__hidden-value {
    @include medium\18\20;
  }

  &__value {
    position: relative;
    width: 100%;
    min-width: 0;
    height: 20px;
    padding: 0;
    font-family: inherit;
    line-height: 20px;
    color: inherit;
    text-align: right;
    background: transparent;
    border: none;
  }

  &__currency {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    height: 20px;
    padding: 0 8px 0 4px;
    text-align: center;
  }

  &__currency-symbol {
    min-width: 16px;
  }

  &__clear {
    width: 16px;
    height: 16px;
    overflow: visible;
  }

  &__clear-button {
    @include z-index(absolute-item);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 16px;
    margin: -18px -16px;
    color: var(--LTextSecondary);
    cursor: pointer;
    background: transparent;
    border: none;

    @include is-app-layout-desktop {
      padding: 10px 4px;
      margin: -10px -4px;
    }
  }

  &__fake-caret {
    flex: none;
    width: 1px;
    height: 24px;
    margin-left: -1px;
    background: $caretColor;
    animation: caret-blink 1200ms linear infinite;
  }

  &__fake-selection {
    position: absolute;
    right: 24px;
    height: 25px;
    background: rgba(47, 128, 237, 0.2);
  }

  &__fake-selection-text {
    // to keep same size as text in input
    visibility: hidden;
  }

  &__hidden {
    position: absolute;
    top: -100%;
    left: -100%;
    width: 100%;
    overflow: scroll;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
    pointer-events: none;
  }

  &__hidden-label,
  &__hidden-value {
    display: inline-block;
  }

  &__hidden-value {
    display: inline-block;
  }
}
</style>
