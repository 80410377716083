<script lang="ts" setup>
import type { SelectWithSearchProps } from 'web/src/components/Select';
import { SelectWithSearch } from 'web/src/components/Select';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useBaseFormWidgetEvents, useFormSelect } from '../../composables';

const props = defineProps<FormWrapperWidgetProperties<SelectWithSearchProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useFormSelect(props);
</script>

<template>
  <SelectWithSearch v-auto-id="'FormSelectWithSearch'"
    v-bind="componentProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
