<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { EgsFavoriteEmits, EgsFavoriteProps } from 'web/src/modules/egs/components/EgsFavorite/types';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';

defineProps<EgsFavoriteProps>();
const emit = defineEmits<EgsFavoriteEmits>();

const inactiveIconProperties: VIconProps = {
  name: IconName.HEART_OUTLINE,
  size: IconSize.SIZE_24,
};

const activeIconProperties: VIconProps = {
  name: IconName.HEART,
  size: IconSize.SIZE_24,
};

function emitClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <VFavoriteToggle v-auto-id="'EgsFavorite'"
    v-data-test="{
      el: 'egs-favorite-button',
      favorite: isFavorite ? 'on' : 'off',
    }"
    :active-icon="activeIconProperties"
    :inactive-icon="inactiveIconProperties"
    :is-favorite="isFavorite"
    :has-background="hasBackground"
    is-button
    :class="{
      [$style['egs-favorite--background']]: hasBackground,
    }"
    @click="emitClick"
  />
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .egs-favorite {
    &--background {
      background-color: var(--Button);
      border-radius: 50%;

      &:hover {
        @if $isDesktop {
          background-color: var(--TextDefault);
        }
      }
    }
  }
}
</style>
