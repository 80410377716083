import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { MaskOptions, VInputInputEvent } from '@leon-hub/input-types';
import { CustomInputEventType, InputEventType } from '@leon-hub/input-types';

import { CountryCode } from '@components/country-flag';

import type { SelectedCountryType } from 'web/src/components/CountriesSelector/VCountriesSelector/types';

import type { UseInputCore } from '../../composables/types';
import type {
  PhoneInputEmits,
  PhoneInputProps,
} from '../../types';
import { useInputCore, useMaskedInput } from '../../composables';
import {
  handlePhonePaste,
  handleUnmaskedPhoneInput,
} from '../../composables/utils';
import { PhoneSubfields } from '../../enums';

interface UsePhoneInput extends Omit<UseInputCore, 'emitInput'
  | 'emitChange'
  | 'clearValue'
  | 'setInputValue'
  | 'inputValue'> {
  selectedPrefix: Ref<string>;
  selectedCountryCode: Ref<string>;
  onInput(event: InputEvent): void;
  onChange(): void;
  onPaste(event: ClipboardEvent): void;
  onKeyDown(event: KeyboardEvent): void;
  maskedValue: ComputedRef<string>;
  showMask: ComputedRef<boolean>;
  formattedValue: ComputedRef<string>;
  currentPlaceholder: ComputedRef<string>;
  toggleOpenPrefixModal(): void;
  onPrefixClick(): void;
  isPrefixModalShown: Ref<boolean>;
  isMaskedPlaceholderHidden: ComputedRef<boolean>;
  hasError: ComputedRef<boolean>;
  onCountrySelect(country: SelectedCountryType): void;
  onClear(): void;
}

export default function usePhoneInput(
  props: PhoneInputProps,
  emit: PhoneInputEmits,
): UsePhoneInput {
  const {
    uniqueId,
    inputValue,
    isEmpty,
    isHover,
    onMouseOver,
    onMouseLeave,
    setInputValue,
  } = useInputCore(props, emit, true);

  const selectedPrefix = ref<string>(props.prefix ?? '');

  const selectedCountryCode = ref<string>(props.countryCode ?? '');

  const isHiddenMask = ref(true);

  watch(() => props.countryCode, (value) => {
    selectedCountryCode.value = value ?? '';
  });

  watch(() => props.prefix, (value) => {
    selectedPrefix.value = value ?? '';
  });

  const isFocus = ref<boolean>(false);

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: inputValue.value,
      name: `${props.name}/${PhoneSubfields.SUFFIX}`,
    },
  }));

  const onFocus = (): void => {
    isFocus.value = true;
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const onBlur = (): void => {
    if (isHiddenMask.value) {
      isHiddenMask.value = false;
    }

    isFocus.value = false;
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  const emitChange = (): void => {
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const emitInput = (): void => {
    emit(InputEventType.INPUT, eventToEmit.value);
  };

  const clearValue = (): void => {
    setInputValue('');
    emitChange();
  };

  const maskOptions = computed<undefined | MaskOptions>(() => {
    if (props.disabled) {
      return undefined;
    }
    const placeholder = 'X';
    if (props.mask?.pattern) {
      return {
        pattern: props.mask.pattern,
        placeholder: props.mask.placeholder ?? placeholder,
      };
    }
    const ruMaskOptions: MaskOptions = {
      placeholder,
      pattern: '(999) 999-99-99',
    };
    if (!process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      return ruMaskOptions;
    }

    // eslint-disable-next-line sonarjs/no-small-switch
    switch (selectedCountryCode.value) {
      case CountryCode.RU:
        return ruMaskOptions;
      default:
        return undefined;
    }
  });

  const hasError = computed<boolean>(() => !!(props.error ?? props.errorRight));

  const showClearButton = computed<boolean>(
    () => isFocus.value && !isEmpty.value,
  );

  const currentPlaceholder = computed<string>(() => {
    switch (selectedCountryCode.value) {
      case CountryCode.AU:
        return '4XXXXXXXX';
      case CountryCode.NZ:
        return '2XXXXXXXX';
      default:
        return props.placeholder ?? '';
    }
  });

  const {
    isMaskedPlaceholderHidden,
    formattedValue,
    maskedValue,
    getValueCutToPattern,
    getMaskedInputResult,
    getMaskedKeydownResult,
  } = useMaskedInput({
    inputValue,
    isFocus,
    maskOptions,
  });

  const showMask = computed<boolean>(() => {
    if (isHiddenMask.value) {
      return false;
    }
    if (props.disablePlaceholder && props.disabled) {
      return false;
    }
    return !!maskOptions.value;
  });

  const displayValue = computed<string>(() => {
    if (props.disabled && props.disablePlaceholder) {
      return props.disablePlaceholder;
    }
    return formattedValue.value;
  });

  const isPrefixModalShown = ref<boolean>(false);

  const toggleOpenPrefixModal = (): void => {
    isPrefixModalShown.value = !isPrefixModalShown.value;
  };

  const onPrefixClick = (): void => {
    if (props.disabled && !isPrefixModalShown.value) {
      return;
    }
    toggleOpenPrefixModal();
  };

  const cutValueToPattern = (): void => {
    const nextValue = getValueCutToPattern();
    if (nextValue !== inputValue.value) {
      setInputValue(nextValue);
      emitInput();
    }
  };

  const onCountrySelect = (country: SelectedCountryType): void => {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      const { prefix, code } = country;
      selectedCountryCode.value = code;
      selectedPrefix.value = prefix;
      emit(InputEventType.CHANGE, {
        target: {
          value: prefix,
          name: `${props.name}/${PhoneSubfields.PREFIX}`,
        },
      });
      emit(InputEventType.CHANGE, {
        target: {
          value: code,
          name: `${props.name}/${PhoneSubfields.COUNTRY_CODE}`,
        },
      });
      cutValueToPattern();
      isPrefixModalShown.value = false;
    }
  };

  const emitFilled = (): void => {
    emit(CustomInputEventType.FILLED);
  };

  const onRegularInput = (event: InputEvent): void => {
    const nextValue = handleUnmaskedPhoneInput(event, inputValue.value);
    if (nextValue !== inputValue.value) {
      setInputValue(nextValue);
      emitInput();
      if (props.emitFilled && props.maxSymbols && nextValue.length >= props.maxSymbols) {
        emitFilled();
      }
    }
  };

  const onMaskedInput = (event: InputEvent): void => {
    const result = getMaskedInputResult(event);
    if (result) {
      const { nextValue, isFilled } = result;
      setInputValue(nextValue);
      emitInput();
      if (isFilled) {
        emitFilled();
      }
    }
  };

  const onInput = (event: InputEvent): void => {
    if (isHiddenMask.value) {
      isHiddenMask.value = false;
    }

    if (!maskOptions.value) {
      onRegularInput(event);
    } else {
      onMaskedInput(event);
    }
  };

  const onPaste = (event: ClipboardEvent): void => {
    const result = handlePhonePaste({
      event,
      currentValue: maskedValue.value,
      maskOptions: maskOptions.value,
    });
    if (result) {
      const { nextValue, isFilled } = result;
      setInputValue(nextValue);
      emitInput();
      if (isFilled) {
        emitFilled();
      }
    }
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (maskOptions.value) {
      const result = getMaskedKeydownResult(event);
      if (result && result.nextValue !== maskedValue.value) {
        setInputValue(result.nextValue);
        emitInput();
      }
    }
  };

  return {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    selectedCountryCode,
    selectedPrefix,
    maskedValue,
    showMask,
    formattedValue: displayValue,
    toggleOpenPrefixModal,
    onPrefixClick,
    isPrefixModalShown,
    isMaskedPlaceholderHidden,
    showClearButton,
    currentPlaceholder,
    onCountrySelect,
    hasError,
    onInput,
    onChange: emitChange,
    onPaste,
    onKeyDown,
    onFocus,
    onBlur,
    onClear: clearValue,
    onMouseOver,
    onMouseLeave,
  };
}
