<script lang="ts" setup>
import { BalanceMode } from 'web/src/modules/bonuses/store/enums';

import type { BalanceCardEmits, BalanceCardProps } from './types';
import { useBalanceCard } from './composables';

const props = withDefaults(defineProps<BalanceCardProps>(), {
  mode: BalanceMode.Single,
});
const emit = defineEmits<BalanceCardEmits>();

const { isSingleMode } = useBalanceCard(props);
</script>

<template>
  <div v-auto-id="'BalanceCard'"
    :class="{
      [$style['balance-card']]: true,
      [$style['balance-card--multi']]: !isSingleMode,
    }"
    @click="emit('click')"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
.balance-card {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: $balanceCardPadding;
  margin-bottom: 8px;
  border-radius: $balanceCardRadius;
  box-shadow: $balanceCardBoxShadow;

  &--multi {
    display: grid;
    grid-template-rows: $balanceMultiGridRows;
    gap: $balanceMultiCardGap;
    align-items: flex-start;
  }
}
</style>
