import type { BaseInputEmits } from '@leon-hub/input-types';

import type { UseNumberStringInput } from './types';
import useInputCore from './useInputCore';
import { handleNumberInput, handleNumberPaste } from './utils';

type Props<T = object> = T & {
  maximumFractionDigits?: number;
  value?: string;
  name?: string;
};

export default function useNumberStringInput(
  props: Props,
  emit: BaseInputEmits,
  symbolLimitation?: number,
): UseNumberStringInput {
  const {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    showClearButton,
    clearValue,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    setInputValue,
    emitInput,
    emitChange,
  } = useInputCore(props, emit);

  const onPaste = (event: ClipboardEvent): void => {
    event.preventDefault();
    const updatedValue = handleNumberPaste(
      event,
      inputValue.value,
      props.maximumFractionDigits,
      symbolLimitation,
    );
    setInputValue(updatedValue);
    emitInput();
  };

  const onChange = (event: InputEvent): void => {
    setInputValue(handleNumberInput(event, inputValue.value, props.maximumFractionDigits, symbolLimitation));
    emitChange();
  };

  const onInput = (event: InputEvent): void => {
    setInputValue(handleNumberInput(event, inputValue.value, props.maximumFractionDigits, symbolLimitation));
    emitInput();
  };

  const onClear = (): void => {
    clearValue();
    emitChange();
  };

  return {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    showClearButton,
    onBlur,
    onFocus,
    onMouseOver,
    onMouseLeave,
    onChange,
    onInput,
    onClear,
    onPaste,
  };
}
