<script lang="ts" setup>
import { computed, useSlots } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';
import {
  AlertIconName,
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { hasSlot } from '@leon-hub/utils';

import { VImage } from '@components/image';
import { JumbotronIconKind } from '@components/jumbotron';
import { VIcon } from '@components/v-icon';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { getAlertIconWeb2Image } from 'web/src/modules/icons';

import type { VJumbotronProps } from './types';

const props = withDefaults(defineProps<VJumbotronProps>(), {
  iconSize: IconSize.SIZE_60,
  heading: '',
  text: '',
  textPosition: 'center',
  alertIconSize: 'default',
});

const slots = useSlots();

const hasIcon = computed<boolean>(() => !!props.icon || hasSlot(slots, 'icon'));
const hasContent = computed<boolean>(() => hasSlot(slots, 'default'));
const hasFooter = computed<boolean>(() => hasSlot(slots, 'footer'));
/* test fails on component mount on read process.env if use in template directly */
const isPhoneModal = computed<boolean>(() => !!(null && props.isModal));

const imageSrc = computed<Web2Image | undefined>(() => {
  if ('production' === 'development' && hasIcon.value) {
    console.warn('Jumbotron icon is overridden by image prop');
  }
  if (props.image) {
    return props.image;
  }

  if (null) {
    let alertIcon: AlertIconName | undefined = props?.alertIcon;
    if (props.icon === IconName.ATTENTION_OUTLINE) {
      alertIcon = (AlertIconName.Alert);
    } else if (props.icon === IconName.CHECK_OUTLINE) {
      alertIcon = (AlertIconName.Success);
    }
    if (alertIcon) {
      return getAlertIconWeb2Image(alertIcon);
    }
  }
  return undefined;
});
</script>

<template>
  <div v-auto-id="'VJumbotron'"
    :class="$style.jumbotron"
  >
    <div
      v-if="imageSrc"
      :class="{
        [$style.jumbotron__image]: true,
        [$style[`jumbotron__image--size__${alertIconSize}`]]: true,
      }"
      data-test-id="custom-icon"
    >
      <VImage
        v-bind="imageSrc"
        :class="$style['jumbotron__image-inner']"
      />
    </div>
    <div
      v-else-if="hasIcon"
      :class="$style['jumbotron__icon-container']"
    >
      <slot name="icon">
        <VIcon
          data-test-id="icon"
          :class="{
            [$style.jumbotron__icon]: true,
            [$style[`jumbotron__icon--success`]]: iconKind === JumbotronIconKind.SUCCESS,
          }"
          :name="props.icon"
          :size="props.iconSize"
        />
      </slot>
    </div>
    <div
      v-if="!!heading"
      data-test-id="heading"
      :class="$style.jumbotron__heading"
    >
      {{ heading }}
    </div>
    <div
      v-if="!!text"
      data-test-id="text"
      :class="[
        $style.jumbotron__text,
        $style[`jumbotron__text-${textPosition}`],
      ]"
    >
      <VDynamicContent
        :content="text"
      />
    </div>
    <div
      v-if="hasContent"
      data-test-id="content"
      :class="$style.jumbotron__content"
    >
      <slot />
    </div>
    <div
      v-if="hasFooter"
      data-test-id="footer"
      :class="{
        [$style.jumbotron__footer]: true,
        [$style['jumbotron__footer--phone-modal']]: isPhoneModal,
      }"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" module>
.jumbotron {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  color: $jumbotronColor;
  text-align: center;

  &__icon {
    fill: $jumbotronIconFill;

    &--success {
      fill: $jumbotronIconSuccessFill;
    }
  }

  &__image {
    width: $jumbotronImageSize;
    height: $jumbotronImageSize;
    padding: $jumbotronImagePadding;
    margin-bottom: $jumbotronIconMargin;

    &--size {
      @each $size, $sizeValue in $alertIconSizes {
        &__#{$size} {
          @if type-of($sizeValue) == 'list' {
            width: nth($sizeValue, 1);
            height: nth($sizeValue, 2);
          } @else {
            width: $sizeValue;
            height: $sizeValue;
          }
        }
      }
    }
  }

  &__image-inner {
    width: 100%;
    height: 100%;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    min-height: 60px;
    margin-bottom: $jumbotronIconMargin;
  }

  &__icon-container + &__footer {
    margin-top: $jumbotronTextAndFooterMargin;
  }

  &__heading {
    @include jumbotronHeadingFont;

    width: 100%;
    margin: $jumbotronHeadingMargin;
    color: $jumbotronHeadingColor;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__heading + &__footer {
    margin-top: $jumbotronTextAndFooterMargin;
  }

  &__text {
    @include jumbotronLabelFont;

    width: 100%;
    margin: $jumbotronTextMargin;
    color: $jumbotronTextColor;

    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    & span {
      font-weight: $jumbotronTextFontWeight;
      color: $jumbotronTextColorAccent;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text + &__footer {
    margin-top: $jumbotronTextAndFooterMargin;
  }

  &__content {
    @include jumbotronLabelFont;

    width: 100%;
    color: $jumbotronContentColor;
    text-align: center;

    & p {
      @include jumbotronTextMargin;
    }
  }

  &__footer {
    width: 100%;
    margin-top: $jumbotronFooterMarginTop;
    text-align: center;

    &--phone-modal {
      max-width: $jumbotronPhoneModalMaxWidth;
    }
  }
}
</style>
