import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Timer } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';

import type { VImageProps } from '@components/image';
import { ObjectFitOption } from '@components/image';

import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';

import type { PaymentBonusItemEmits, PaymentBonusItemProps } from '../types';

export interface PaymentBonusItemComposable {
  animatedMask: Ref<HTMLElement | null>;
  isExpanded: Ref<boolean>;
  isStatic: Ref<boolean>;
  isImageLoaded: Ref<boolean>;
  isDetailsAvailable: Ref<boolean>;
  bonusImage: Ref<VImageProps>;
  description: Ref<string>;
  emitSelectComplete(): void;
  emitSelectedBonus(): void;
  onMount(): void;
  onToggleExpand(): void;
  onDetailClick(): void;
  chooseButtonHandle(): void;
  setImageLoaded(): void;
}

export default function usePaymentBonusItem(props: PaymentBonusItemProps, emit: PaymentBonusItemEmits): PaymentBonusItemComposable {
  const { $translate } = useI18n();
  const router = useRouter();

  const animatedMask = ref<HTMLElement | null>(null);
  const isExpanded = ref(false);
  const isStatic = ref(false);
  const isImageLoaded = ref(false);

  const isDetailsAvailable = computed<boolean>(() => !!props.bonusItem?.actionUrl && !!props.bonusItem?.campaignId);

  const bonusImage = computed<VImageProps>(() => ({
    src: props.bonusItem?.image?.src ?? props.bonusItem?.imgUrl ?? '',
    x1: props.bonusItem?.image?.x1,
    x2: props.bonusItem?.image?.x2,
    x3: props.bonusItem?.image?.x3,
    x1webp: props.bonusItem?.image?.x1webp,
    x2webp: props.bonusItem?.image?.x2webp,
    x3webp: props.bonusItem?.image?.x3webp,
    alt: props.bonusItem.campaignName,
    objectFit: ObjectFitOption.COVER,
    showLoader: true,
    isLazy: true,
  }));

  const description = computed<string>(
    () => props.bonusItem?.description ?? `<span>${$translate('WEB2_BONUS_DESCRIPTION').value}</span>`,
  );

  function onToggleExpand(): void {
    isExpanded.value = !isExpanded.value;
  }

  function emitSelectComplete(): void {
    emit('select-complete');
  }

  function emitSelectedBonus(): void {
    emit('select', {
      campaignId: props.isSelected ? DEPOSIT_BONUS_NONE_SELECTED : props.bonusItem.campaignId,
      bonusCode: props.isSelected ? `${DEPOSIT_BONUS_NONE_SELECTED}` : props.bonusItem.bonusCode,
    });
  }

  function onMount(): void {
    if (animatedMask.value && !props.isSelected) {
      animatedMask.value.addEventListener('animationend', () => {
        Timer.setTimeout(() => {
          emitSelectComplete();
        }, 200);
      });
    }
    if (animatedMask.value && props.isSelected) {
      isStatic.value = true;
    }
  }

  function onDetailClick(): void {
    void router.push({
      name: RouteName.PROMOTION_DETAILS,
      params: {
        categoryId: props.bonusItem?.categoryId || '',
        actionUrl: props.bonusItem?.actionUrl || '',
      },
    });
  }

  function chooseButtonHandle(): void {
    if (props.isSelected) {
      emitSelectComplete();
    } else {
      emitSelectedBonus();
    }
  }

  function setImageLoaded(): void {
    isImageLoaded.value = true;
  }

  return {
    animatedMask,
    isExpanded,
    isStatic,
    isImageLoaded,
    isDetailsAvailable,
    bonusImage,
    description,
    emitSelectComplete,
    emitSelectedBonus,
    onMount,
    onToggleExpand,
    onDetailClick,
    chooseButtonHandle,
    setImageLoaded,
  };
}
