<script lang="ts" setup>
import { computed, toRef } from 'vue';

import type { CompetitorsRolesInfo } from 'web/src/modules/sportline/types';
import type { SportlineEventMainInfoProps } from 'web/src/modules/sportline/views/EventBlock/types';
import type { ScoreDetails } from 'web/src/modules/sportline/views/EventBlock/utils';
import DoubleEventLogo from 'web/src/modules/sportline/components/SportlineLogo/DoubleEventLogo.vue';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useSportlineEventInfo } from 'web/src/modules/sportline/views/EventBlock/composables';
import { getActualScoreDetails } from 'web/src/modules/sportline/views/EventBlock/utils';

const props = defineProps<SportlineEventMainInfoProps>();

const sportlineEvent = toRef(props, 'sportlineEvent');
const sport = toRef(props, 'sport');
const canShowLogos = toRef(props, 'canShowLogos');

const actualScoreDetails = computed<Maybe<CompetitorsRolesInfo<ScoreDetails[]>>>(() => (
  // @TODO return the list ready to display
  getActualScoreDetails(sportlineEvent.value, sport.value)
));
const actualScoreDetailsList = computed(() => {
  const host = (actualScoreDetails.value?.host ?? []);
  const guest = (actualScoreDetails.value?.guest ?? []);

  return host.map((_, index) => ({
    host: host[index],
    guest: guest[index],
  }));
});

const {
  hostName,
  guestName,
} = useSportlineEventInfo({ sportlineEvent });
const {
  matchProgress,
} = useLiveMatchProgress({ sportEvent: sportlineEvent });
// @TODO group with actual score details into single composable
const innings = computed(() => (matchProgress.value?.innings ?? { host: false, guest: false }));
</script>

<template>
  <div v-auto-id="'SportlineEventCompetitorsMainInfo'" :class="$style['sportline-event-main-info']">
    <div :class="$style['sportline-event-main-info__left-column']">
      <DoubleEventLogo
        v-if="'1' && canShowLogos"
        :sportline-event="sportlineEvent"
        :sport="sport"
        is-colorful
      />

      <div :class="$style['sportline-event-competitors']">
        <div :class="$style['sportline-event-competitor']">
          <span
            :class="$style['sportline-event-competitor__name']"
            :title="hostName"
          >{{ hostName }}</span>
        </div>

        <div :class="$style['sportline-event-competitor']">
          <span
            :class="$style['sportline-event-competitor__name']"
            :title="guestName"
          >{{ guestName }}</span>
        </div>
      </div>
    </div>

    <div
      v-for="(score, index) in actualScoreDetailsList"
      :key="`${index}_${score.host.value}_${score.guest.value}`"
      :class="{
        [$style['sportline-event-main-info__column']]: true,
        [$style['sportline-event-score-column']]: true,
        [$style['sportline-event-score-column--host-goal']]: score.host.goal,
        [$style['sportline-event-score-column--guest-goal']]: score.guest.goal,
      }"
    >
      <div
        :class="{
          [$style['sportline-event-score']]: true,
          [$style['sportline-event-score--innings']]: !index && innings.host && !score.host.goal,
          [$style['sportline-event-score--goal']]: !index && score.host.goal,
          [$style['sportline-event-score--highlighted']]: score.host.highlighted,
        }"
      >
        <template v-if="score.host.goal">
          {{ $t('WEB2_SPORT_LINE_GOAL') }}
        </template>
        <template v-else>
          {{ score.host.value }}
        </template>
      </div>
      <div
        :class="{
          [$style['sportline-event-score']]: true,
          [$style['sportline-event-score--innings']]: !index && innings.guest && !score.guest.goal,
          [$style['sportline-event-score--goal']]: !index && score.guest.goal,
          [$style['sportline-event-score--highlighted']]: score.guest.highlighted,
        }"
      >
        <template v-if="score.guest.goal">
          {{ $t('WEB2_SPORT_LINE_GOAL') }}
        </template>
        <template v-else>
          {{ score.guest.value }}
        </template>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-main-info';
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-competitors';
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-live-score';
</style>
