import { useI18n } from '@core/i18n';

import type { VSmsNumberCheckProps } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/types';

export default function getPageDescription(
  props: Pick<VSmsNumberCheckProps, 'isNewTel' | 'isPin'>,
): string {
  const { $translate } = useI18n();

  if (props.isNewTel) {
    if (props.isPin) {
      return $translate('WEB2_PIN_INPUT_NEW_TEL').value;
    }
    return $translate('WEB2_INPUT_NEW_TEL').value;
  }
  if (props.isPin) {
    return $translate('WEB2_PIN_INPUT_SMS_CODE').value;
  }
  return $translate('WEB2_INPUT_SMS_CODE').value;
}
