<script lang="ts" setup>
import type {
  SearchListSectionEmits,
  SearchListSectionProps,
} from 'web/src/modules/search/submodules/sportline/components/SearchListSection/types';
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';
import SearchListItem from 'web/src/modules/search/submodules/sportline/components/SearchListItem/SearchListItem.vue';
import { useSearchListSection } from 'web/src/modules/search/submodules/sportline/components/SearchListSection/composables';

const props = withDefaults(defineProps<SearchListSectionProps>(), {
  caption: '',
  list: () => [],
  searchText: '',
});
const emits = defineEmits<SearchListSectionEmits>();

const {
  captionText,
  getItemProperties,
  emitItemClick,
} = useSearchListSection(props, emits);
</script>

<template>
  <section v-auto-id="'SearchListSection'" :class="$style['search-list-section']">
    <SearchSubHeader
      :text="captionText"
    />
    <ul :class="$style['search-list-section__list']">
      <SearchListItem
        v-for="event in list"
        v-bind="getItemProperties(event)"
        :key="event.id"
        :class="$style['search-list-section__list-item']"
        @click="emitItemClick(event)"
      />
    </ul>
  </section>
</template>

<style lang="scss" module>
.search-list-section {
  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
