import type {
  CustomerRegistrationType,
  doRegistrationQuery,
  getAvailableFormsQuery,
  getFreebetDataQuery,
  getSiteConfigSettingsQuery,
  GoBackReturnType,
  RawEntryInput,
  SimpleRegistrationInput,
} from '@leon-hub/api-sdk';
import type { FormSchema } from '@leon-hub/form-utils';

import type { FormUiSchema } from 'web/src/components/Form';
import type { FormData, FormDataValue } from 'web/src/components/Form/types';

export interface CurrentRegistrationForm {
  priority: number;
  formName: string;
  schema: FormSchema;
  uiSchema: FormUiSchema;
  schemaId: string;
}

export interface PrepareRegistrationInputOptions {
  registrationType: CustomerRegistrationType;
  schemaId: string;
  addFingerprint?: boolean;
  extraFormParams?: RawEntryInput[];
}

export class CommonRegistrationState { // TODO: replace by enum
  // user is anonym (not passed login stage)
  static ANONYM = 'ANONYM';

  // user is authorized (passed login stage)
  static AUTHORIZED = 'AUTHORIZED';

  // form loaded
  static FORM_READY = 'FORM_READY';

  // server error - nothing to do
  static ERROR_SERVER = 'ERROR_SERVER';

  // user can resend form
  static FORM_ERROR = 'FORM_ERROR';

  // form updated with error, user can resend form
  static ERROR_FORM_VALIDATION = 'ERROR_FORM_VALIDATION';
}

type SiteConfigQuery = Exclude<getSiteConfigSettingsQuery['queries']['system']['siteConfig']['data'], null>;
type FooterBannerLogotypesDocument = Exclude<SiteConfigQuery['registration']['footerBannerLogotypesByLocale'], null>;
export type FooterBannerLogotypeDocument = FooterBannerLogotypesDocument[number];

export type AvailableFormsDocument =
  Exclude<getAvailableFormsQuery['queries']['registration']['getAvailableForms']['data'], null>;

export type FreeBetDocument = getFreebetDataQuery['queries']['registration']['getAvailableForms']['data'];

export type AvailableRegistrationForm = Pick<AvailableFormsDocument, 'forms'>['forms'][number];

export type SubmitRegistrationForm = Exclude<Exclude<doRegistrationQuery['mutations']['registration']['submit']['data'], null>['form'], null>;

export type GoBackRegistrationForm = Exclude<GoBackReturnType, null>['form'];

export type RegistrationForm = AvailableRegistrationForm | SubmitRegistrationForm | GoBackRegistrationForm;

export type RegistrationFormFilledFields = Dictionary<FormDataValue>;

export interface CoreRegistrationFormService {
  normalizeFormData(form: RegistrationForm): Maybe<RegistrationForm>;

  prepareRegistrationInput(
    formData: FormData,
    options: PrepareRegistrationInputOptions,
  ): Promise<SimpleRegistrationInput>;
}

export interface GetRegistrationFormSchemaOptions {
  bonusCode?: Maybe<string>;
  formFilledFields?: Readonly<RegistrationFormFilledFields>;
  formsEmailHints: readonly string[];
  formsEmailHintsEnabled: boolean;
}

export interface RegistrationFormSchema {
  schema: FormSchema;
  uiSchema: FormUiSchema;
}

export interface RegistrationFormService extends CoreRegistrationFormService {
  getFormSchema(
    form: RegistrationForm,
    options: GetRegistrationFormSchemaOptions,
  ): RegistrationFormSchema;

  fillUiSubmittedData(formToFill: FormUiSchema, submittedData: Maybe<FormData>): FormUiSchema;
}
