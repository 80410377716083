import { ref, toRef, computed } from "vue";
import { isNavigationConfig, NavigationGroup, setNavigationBreadcrumbs } from "@leon-hub/navigation-config";
import { useRouterStore } from "@core/router";
import { defineStore } from "pinia";
import { getNavigationConfig, getPromotionNavigationConfig } from "@leon-hub/api-sdk";
import { assert } from "@leon-hub/guards";
import { useGraphqlClient } from "@core/app-rest-client";
import { useSyncState } from "@core/sync-state";
async function fetchDefaultNavigationConfig(silent = false) {
  const result = await getNavigationConfig(useGraphqlClient(), (node) => node.queries.system.getNavigation, {}, { silent });
  const parsedNavigationConfig = JSON.parse(result.navigation);
  assert(isNavigationConfig(parsedNavigationConfig));
  return parsedNavigationConfig;
}
const useNavigationStore = /* @__PURE__ */ defineStore("navigation", () => {
  const api = useGraphqlClient();
  const navigationConfig = ref({});
  function handleDefaultNavigationConfig(config) {
    navigationConfig.value = config;
  }
  async function updateDefaultNavigationConfig(silent = false) {
    handleDefaultNavigationConfig(await fetchDefaultNavigationConfig(silent));
  }
  async function fetchPromotionsNavigationConfig(silent = false) {
    const result = await getPromotionNavigationConfig(api, (node) => node.queries.system.getNavigation, {}, { silent });
    const parsedNavigationConfig = JSON.parse(result.navigation);
    assert(isNavigationConfig(parsedNavigationConfig));
    const key = NavigationGroup.PROMOTION_CATEGORIES_NAVIGATION;
    navigationConfig.value[key] = parsedNavigationConfig[key];
  }
  useSyncState(updateDefaultNavigationConfig, "navigation");
  return {
    updateDefaultNavigationConfig,
    handleDefaultNavigationConfig,
    fetchPromotionsNavigationConfig,
    navigationConfig
  };
});
function useFilterNavigationItems() {
  const navigationStore = useNavigationStore();
  const navigationConfig = toRef(navigationStore, "navigationConfig");
  const routerStore = useRouterStore();
  const realCurrentRouteName = toRef(routerStore, "realCurrentRouteName");
  function getNavigationItems(group) {
    const items = navigationConfig.value[group] ?? [];
    return realCurrentRouteName.value ? setNavigationBreadcrumbs(
      items,
      realCurrentRouteName.value
    ) : items;
  }
  return {
    getNavigationItems
  };
}
const useNavigationBackButtonStore = /* @__PURE__ */ defineStore("navigation-back-button-store", () => {
  let navigationBackLocationGetter = null;
  const isBackButtonAvailable = ref(false);
  function setNavigationBackLocationGetter(value) {
    navigationBackLocationGetter = value;
    isBackButtonAvailable.value = !!value;
  }
  function getBackLocation() {
    return navigationBackLocationGetter == null ? void 0 : navigationBackLocationGetter();
  }
  return {
    isBackButtonAvailable,
    getBackLocation,
    setNavigationBackLocationGetter
  };
});
const useNavigationItemsStore = /* @__PURE__ */ defineStore("navigation-items", () => {
  const { getNavigationItems } = useFilterNavigationItems();
  const topNavigationItems = computed(
    () => [...getNavigationItems(NavigationGroup.TOP_NAVIGATION)]
  );
  const tabBarItems = computed(
    () => getNavigationItems(NavigationGroup.BOTTOM_NAVIGATION)
  );
  const tabBarItemsShort = computed(
    () => getNavigationItems(NavigationGroup.BOTTOM_NAVIGATION_SHORT)
  );
  const sidebarLeftTabNavigation = computed(
    () => getNavigationItems(NavigationGroup.SIDEBAR_LEFT_TAB_NAVIGATION)
  );
  const sidebarLeftNavigation = computed(
    () => getNavigationItems(NavigationGroup.SIDEBAR_LEFT_NAVIGATION)
  );
  const promotionCategoriesNavigationItems = computed(
    () => getNavigationItems(NavigationGroup.PROMOTION_CATEGORIES_NAVIGATION)
  );
  const bootstrapNavigationItems = computed(
    () => getNavigationItems(NavigationGroup.BOOTSTRAP_NAVIGATION)
  );
  const genericInfoNavigationItems = computed(
    () => getNavigationItems(NavigationGroup.GENERIC_INFO_NAVIGATION)
  );
  const affiliateNavigation = computed(
    () => getNavigationItems(NavigationGroup.AFFILIATE_PROGRAM_NAVIGATION)
  );
  const error404NavigationItems = computed(
    () => getNavigationItems(NavigationGroup.ERROR_404_NAVIGATION)
  );
  const affiliateFooterNavigationItem = computed(
    () => getNavigationItems(NavigationGroup.AFFILIATE_PROGRAM_FOOTER_NAVIGATION)[0]
  );
  const telegramBotNavigationItem = computed(
    () => getNavigationItems(NavigationGroup.TELEGRAM_BOT_NAVIGATION)[0]
  );
  const showcaseItems = computed(
    () => getNavigationItems(NavigationGroup.TOP_SHOWCASE_NAVIGATION)
  );
  const responsibleGamblingItems = computed(
    () => getNavigationItems(NavigationGroup.RESPONSIBLE_GAMBLING_NAVIGATION)
  );
  const sidebarLeftTopItems = computed(
    () => getNavigationItems(NavigationGroup.SIDEBAR_LEFT_TOP_NAVIGATION)
  );
  const sidebarLeftMiddleItems = computed(
    () => getNavigationItems(NavigationGroup.SIDEBAR_LEFT_MIDDLE_NAVIGATION)
  );
  const devtoolsItems = computed(
    () => getNavigationItems(NavigationGroup.DEVTOOLS_NAVIGATION)
  );
  return {
    topNavigationItems,
    tabBarItems,
    tabBarItemsShort,
    sidebarLeftTabNavigation,
    promotionCategoriesNavigationItems,
    bootstrapNavigationItems,
    genericInfoNavigationItems,
    affiliateNavigation,
    error404NavigationItems,
    affiliateFooterNavigationItem,
    telegramBotNavigationItem,
    showcaseItems,
    responsibleGamblingItems,
    sidebarLeftNavigation,
    sidebarLeftTopItems,
    sidebarLeftMiddleItems,
    devtoolsItems
  };
});
const useRootNavigationStore = /* @__PURE__ */ defineStore("root-navigation", () => {
  const isRouteContentLoading = ref({});
  function setOnlyFirstRouteContentLoading(name) {
    if (isRouteContentLoading.value[name] !== void 0) {
      return;
    }
    isRouteContentLoading.value[name] = true;
  }
  function setRouteContentLoaded(name) {
    isRouteContentLoading.value[name] = false;
  }
  return {
    isRouteContentLoading,
    setOnlyFirstRouteContentLoading,
    setRouteContentLoaded
  };
});
export {
  fetchDefaultNavigationConfig,
  useFilterNavigationItems,
  useNavigationBackButtonStore,
  useNavigationItemsStore,
  useNavigationStore,
  useRootNavigationStore
};
