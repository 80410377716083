<script setup lang="ts">
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import RestorePasswordByEmailInfo from 'web/src/modules/restore-password/components/RestorePasswordByEmailInfo/RestorePasswordByEmailInfo.vue';
import RestorePasswordCheckPhoneForm from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/RestorePasswordCheckPhoneForm.vue';

import type { RestoreContainerEmit, RestoreContainerProps } from './types';
import useRestoreContainer from './composables/useRestoreContainer';

withDefaults(defineProps<RestoreContainerProps>(), {});

const emit = defineEmits<RestoreContainerEmit>();
const {
  isCaptchaEnabled,
  tabsProperties,
  switchProperties,
  onTabClick,
} = useRestoreContainer();
</script>

<template>
  <div v-auto-id="'RestoreContainer'">
    <RestorePasswordByEmailInfo
      v-if="isRestoreByEmailSent"
      :is-pin="isPin"
      @form-submit="emit('resend-restore-password-by-email', $event)"
      @form-input="emit('resend-email-input', $event)"
      @success="emit('resend-email-success', $event)"
    />
    <RestorePasswordCheckPhoneForm
      v-else-if="isRestoreByPhoneSent"
      :is-pin="isPin"
      @close="emit('check-pin-success')"
    />
    <div
      v-else
      :class="$style['restore__page']"
    >
      <div
        v-if="(tabsProperties.items?.length ?? 0) > 1"
        :class="$style['restore-password__tabs-container']"
      >
        <VSwitcher
          v-if="null"
          v-bind="switchProperties"
          @change="onTabClick"
        />
        <VTabs
          v-else
          v-bind="tabsProperties"
          with-gap
          @tab-click="onTabClick"
        />
      </div>
      <div
        :class="$style['restore']"
      >
        <slot />
      </div>
    </div>
    <VReCaptchaBadge
      v-if="!isPin && isCaptchaEnabled"
      :class="$style['restore-captcha-badge']"
      :lang="$lang"
    />
  </div>
</template>

<style lang="scss" module>
.restore {
  padding: 0 16px 16px;
  margin-top: 16px;

  &-password__tabs-container {
    margin: $restoreTabsContainerMobileMargins;

    @include is-app-layout-desktop {
      margin: $restoreTabsContainerDesktopMargins;
    }
  }

  @include is-app-layout-desktop {
    padding: 0 32px 32px;
  }
}

.restore-captcha-badge {
  padding: 0 0 44px;
  margin: 0;
}
</style>
