import type { IntercomCustomAttributes } from '@leon-hub/cordova';
import {
  displayIntercomHelpCenter,
  displayIntercomMessages,
  displayIntercomMessenger,
  hideIntercomMessenger,
  trackIntercomEvent,
  updateIntercomUser,
} from '@leon-hub/cordova';

import type { IntercomSettings } from 'web/src/modules/intercom/composables/types';
import type { IntercomUpdateOptions } from 'web/src/modules/intercom/composables/types/IntercomUpdateOptions';
import { getIntercom } from 'web/src/modules/intercom/composables/utils';
import { IntercomShowSpace } from 'web/src/modules/intercom/enums/IntercomShowSpace';
import { useUnreadMessagesStore } from 'web/src/modules/intercom/store/useUnreadMessagesStore';
import useSupport from 'web/src/modules/support/composables/useSupport';

interface IntercomChatHandlers {
  hideIntercomWidget(): void;
  showIntercomWidget(isOpenMenu?: boolean): void;
  openIntercomMenu(): void;
  openIntercom(): void;
  resetIntercom(): void;
  onUpdate(params: IntercomUpdateOptions): void;
  bootIntercomUser(settings: IntercomSettings): void;
  onHide(callback: () => void): void;
  onShow(callback: () => void): void;
  showSpace(spaceName: IntercomShowSpace): void;
  logEvent(event: string, attributes: IntercomCustomAttributes): void;
}

export default function useIntercomEvents(): IntercomChatHandlers {
  const unreadCounterStore = useUnreadMessagesStore();
  const { isIntercomOptionsListFlow } = useSupport();

  function hideIntercomWidget(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      hideIntercomMessenger();
    } else {
      onUpdate({
        hide_default_launcher: true,
      });
      void onUnreadCountChange();
    }
  }

  function openIntercom(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      displayIntercomMessenger();
    } else {
      openIntercomMenu();
    }
  }

  function showIntercomWidget(isOpenMenu?: boolean): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      displayIntercomMessenger();
    } else {
      onUpdate({
        hide_default_launcher: false,
      });
      if (isOpenMenu) {
        openIntercomMenu();
      }
    }
  }

  function openIntercomMenu(): void {
    getIntercom()?.('show');
  }

  function bootIntercomUser(settings: IntercomSettings): void {
    getIntercom()?.('boot', settings);
  }

  function resetIntercom(): void {
    getIntercom()?.('shutdown');
  }

  function onUpdate(options: IntercomUpdateOptions): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      updateIntercomUser({
        location: options.location,
        last_request_at: options.last_request_at,
      });
    } else {
      getIntercom()?.('update', options);
    }
  }

  function onHide(callback: () => void): void {
    getIntercom()?.('onHide', callback);
  }

  function onShow(callback: () => void): void {
    getIntercom()?.('onShow', callback);
  }

  function showSpace(spaceName: IntercomShowSpace): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA && isIntercomOptionsListFlow.value) {
      switch (spaceName) {
        case IntercomShowSpace.MESSAGES:
          displayIntercomMessages();
          return;
        case IntercomShowSpace.HELP:
          displayIntercomHelpCenter();
          return;
        default:
          displayIntercomMessenger();
      }
    }
    getIntercom()?.('showSpace', spaceName);
  }

  function logEvent(event: string, attributes: IntercomCustomAttributes): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      trackIntercomEvent(event, attributes);
    } else {
      getIntercom()?.('trackEvent', event, attributes);
    }
  }

  function onUnreadCountChange(): void {
    getIntercom()?.('onUnreadCountChange', (unreadMessages) => {
      unreadCounterStore.setUnreadMessages(unreadMessages);
    });
  }

  return {
    hideIntercomWidget,
    showIntercomWidget,
    openIntercomMenu,
    openIntercom,
    resetIntercom,
    bootIntercomUser,
    onHide,
    onShow,
    showSpace,
    onUpdate,
    logEvent,
  };
}
