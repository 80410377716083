<script lang="ts" setup>
import { onBeforeMount } from 'vue';

import { VIcon } from '@components/v-icon';

import type {
  VSwitcherEmits,
  VSwitcherProps,
} from './types';
import { useVSwitcher } from './composables';
import { SwitcherKind } from './enums';

const props = withDefaults(defineProps<VSwitcherProps>(), {
  kind: SwitcherKind.DEFAULT,
  showDivider: true,
  options: () => ([]),
});
const emit = defineEmits<VSwitcherEmits>();
const {
  grid,
  innerActiveIndex,
  positionCalculated,
  beforeMount,
  selectionStyle,
  calculateActiveOffsetWidth,
} = useVSwitcher(props);

onBeforeMount(beforeMount);
function emitChange(index: number) {
  innerActiveIndex.value = index;
  calculateActiveOffsetWidth();

  emit('change', props.options[index]?.id);
}
</script>

<template>
  <div v-auto-id="'VSwitcher'"
    :class="{
      [$style['switcher']]: true,
      [$style['switcher--small']]: isSmall,
      [$style['switcher--capitalize']]: isCapitalize,
      [$style['switcher--rounded']]: isRounded,
      [$style['switcher--wide-clickzone']]: isWideClickZone,
      [$style[`switcher--kind-${kind}`]]: kind !== SwitcherKind.DEFAULT,
    }
    "
  >
    <span
      v-show="positionCalculated"
      :class="$style['switcher__selection']"
      :style="selectionStyle"
    />
    <div
      ref="grid"
      :class="$style['switcher__grid']"
    >
      <div
        v-for="(option, index) in options"
        :key="option.id"
        v-data-test="{ el: 'switcher-option', id: option.id, active: activeId === option.id }"
        :class="$style['switcher__option']"
      >
        <div
          :class="{
            [$style['switcher__label']]: true,
            [$style['switcher__label--active']]: index === innerActiveIndex,
            [$style['switcher__label--show-divider']]: showDivider && index > 0 && ![
              index,
              index - 1,
            ].includes(innerActiveIndex),
            [$style['switcher__label--only-icons']]: !option.label,
          }"
        >
          <VIcon
            v-if="option.iconName"
            :name="option.iconName"
            :size="option.iconSize"
          />
          <span v-if="option.label">{{ option.label }}</span>
        </div>
        <div
          :class="$style['switcher__clickzone']"
          @click="emitChange(index)"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.switcher {
  $self: &;

  @mixin is-small() {
    #{$self}--small & {
      @content;
    }
  }

  @mixin is-capitalize() {
    #{$self}--capitalize & {
      @content;
    }
  }

  @mixin is-rounded() {
    #{$self}--rounded & {
      @content;
    }
  }

  @mixin is-wide-clickzone() {
    #{$self}--wide-clickzone & {
      @content;
    }
  }

  position: relative;
  z-index: 1;
  user-select: none;

  &__grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    margin: 0;
    border: none;
    outline: none;
  }

  &__option {
    position: relative;
    cursor: pointer;

    &:first-of-type {
      .switcher__label {
        border-top-left-radius: $switcherOptionBorderRadiusDefault;
        border-bottom-left-radius: $switcherOptionBorderRadiusDefault;

        @include is-rounded {
          border-top-left-radius: $switcherOptionBorderRadiusRounded;
          border-bottom-left-radius: $switcherOptionBorderRadiusRounded;
        }
      }
    }

    &:last-of-type {
      .switcher__label {
        border-top-right-radius: $switcherOptionBorderRadiusDefault;
        border-bottom-right-radius: $switcherOptionBorderRadiusDefault;

        @include is-rounded {
          border-top-right-radius: $switcherOptionBorderRadiusRounded;
          border-bottom-right-radius: $switcherOptionBorderRadiusRounded;
        }
      }
    }
  }

  &__label {
    @include switcherLabel;

    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: $switcherLabelPadding;
    color: var(--TextSecondary);
    text-align: center;
    background-color: var(--Layer1);

    &--show-divider {
      &:before {
        position: absolute;
        top: $switcherDividerPosition;
        bottom: $switcherDividerPosition;
        left: 0;
        width: 1px;
        content: '';
        background-color: var(--Highlight);

        @include is-small {
          top: $switcherSmallDividerPosition;
          bottom: $switcherSmallDividerPosition;
        }
      }
    }

    &--only-icons {
      padding: 9px 16px;
    }

    span {
      @include z-index(above-base);

      position: relative;
      display: block;
      transition: all 0.2s ease;
      will-change: transform;

      @include is-capitalize {
        text-transform: capitalize;
      }
    }

    svg {
      @include z-index(above-base);
    }

    &--active {
      color: var(--TextDefault);
      cursor: default;
    }

    @include is-small {
      @include switcherSmallLabel;

      padding: $switcherSmallLabelPadding;
    }
  }

  &__selection {
    @include z-index(above-base);

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding: $switcherSelectionPadding;
    will-change: transform;

    &:before {
      display: block;
      height: 100%;
      content: '';
      background-color: var(--Highlight);
      border-radius: $switcherSelectionBorderRadiusDefault;
      box-shadow: $switcherSelectionBoxShadow;

      @include is-rounded {
        border-radius: $switcherSelectionBorderRadiusRounded;
      }
    }
  }

  &--kind {
    @each $kind, $kindProps in $switcherKindProps {
      &-#{$kind} {
        @include for-hover {
          #{$self}__option:hover {
            #{$self}__label:not(#{$self}__label--active) {
              color: get($kindProps, 'colorHover');
            }
          }
        }
        #{$self}__label {
          color: get($kindProps, 'color');
          background-color: get($kindProps, 'backgroundColor');

          &--active {
            color: get($kindProps, 'colorActive');
          }

          &--show-divider:before {
            background-color: get($kindProps, 'backgroundColorDivider');
          }
        }

        #{$self}__selection:before {
          background-color: get($kindProps, 'backgroundColorActive');
        }
      }
    }
  }

  &__clickzone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    @include is-wide-clickzone {
      top: -8px;
      bottom: -8px;
    }
  }
}
</style>
