import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Sport, SportId } from 'web/src/modules/sportline/types';
import type { SportElementCache } from 'web/src/modules/sportline/types/cache-storage';

import { useSportlineCacheStorage } from '../store/useSportlineCacheStorage';

export function useSportFromCache(sportId: Ref<Maybe<SportId>>): Ref<Maybe<Sport>> {
  const cacheStore = useSportlineCacheStorage();

  const sportCache = computed<Maybe<SportElementCache>>(() => (
    sportId.value ? cacheStore.getSportCache(sportId.value).value : null
  ));

  return computed<Maybe<Sport>>(() => sportCache.value?.sport ?? null);
}
