<script lang="ts" setup>
import { VButton } from '@components/buttons';
import { ModalWidth } from '@components/dialogs';
import { VList } from '@components/list';

import VListItemCountries from 'web/src/components/CountriesSelector/VListItemCountries/VListItemCountries.vue';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';

import type {
  SelectedCountryType,
  VCountriesSelectorEmits,
  VCountriesSelectorProps,
} from './types';
import { useVCountriesSelector } from './composables';

const props = withDefaults(defineProps<VCountriesSelectorProps>(), {
  countriesList: () => [],
  lazyRenderCount: 30,
  modalProperties: () => ({
    isFullHeight: !'1',
    width: null ? ModalWidth.MEDIUM_PLUS : ModalWidth.SMALL,
    isOverlayVisible: true,
    isPaddingTopBig: !!null,
  }),
});
const emit = defineEmits<VCountriesSelectorEmits>();

const {
  popularCountries,
  unPopularCountries,
  visibleUnpopularCountries,
  selectedModalProperties,
  searchText,
  searchTextValue,
  foundCountries,
  showMoreButtonProperties,
  isShowUnPopularCountriesList,
  toggleUnPopularCountriesList,
  renderMoveUnpopularCountries,
  modal,
  setSearchText,
} = useVCountriesSelector(props);

function emitOnCountrySelect(item: SelectedCountryType): void {
  emit('on-country-select', {
    ...item,
    prefix: item.prefix.replace('+', ''),
  });
}

function emitClearText(): void {
  setSearchText('');
  emit('clear-text');
}
</script>

<template>
  <LazyDefaultModal v-auto-id="'VCountriesSelector'"
    v-bind="selectedModalProperties"
    ref="modal"
    :class="$style['countries-modal']"
    data-test-id="countries-selector"
    @close="emit('close-filter')"
    @scroll-reached-bottom="renderMoveUnpopularCountries"
  >
    <template #inner-content>
      <div :class="$style['countries-selector']">
        <VSearchInput
          :value="searchText"
          :placeholder="$t('WEB2_PLACEHOLDER_SEARCH_INPUT')"
          :is-autofocus="!!'1'"
          is-icon-right
          @input="setSearchText"
          @clear="emitClearText"
        />
        <template
          v-if="!searchTextValue"
        >
          <template
            v-if="!!popularCountries.length"
          >
            <h4
              v-if="!null"
              :class="$style['countries-selector__title']"
            >
              {{ $t('WEB2_POPULAR_COUNTRIES') }}
            </h4>
            <VList :class="$style['countries-selector__list']">
              <VListItemCountries
                v-for="country in popularCountries"
                :key="`${country.name}${country.code}`"
                :name="country.name"
                :code="country.code"
                :prefix="country.phonePrefix"
                :search-text="searchTextValue"
                :selected-item-code="selectedCountryCode"
                @click="emitOnCountrySelect"
              />
            </VList>
          </template>
          <template
            v-if="!!unPopularCountries.length"
          >
            <VButton
              v-bind="showMoreButtonProperties"
              :class="$style['countries-selector__button']"
              @click="toggleUnPopularCountriesList"
            />
            <template v-if="isShowUnPopularCountriesList">
              <VList :class="$style['countries-selector__list']">
                <VListItemCountries
                  v-for="country in visibleUnpopularCountries"
                  :key="`${country.name}${country.code}`"
                  :name="country.name"
                  :code="country.code"
                  :prefix="country.phonePrefix"
                  :search-text="searchTextValue"
                  :selected-item-code="selectedCountryCode"
                  @click="emitOnCountrySelect"
                />
              </VList>
            </template>
          </template>
        </template>
        <VList
          v-if="searchTextValue"
          :class="$style['countries-selector__list']"
        >
          <VListItemCountries
            v-for="country in foundCountries"
            :key="`${country.name}${country.code}`"
            :name="country.name"
            :code="country.code"
            :prefix="country.phonePrefix"
            :search-text="searchTextValue"
            :selected-item-code="selectedCountryCode"
            @click="emitOnCountrySelect"
          />
        </VList>
      </div>
    </template>
  </LazyDefaultModal>
</template>

<style lang="scss" module>
.countries {
  &-selector {
    @include countrySelectorMargin;

    margin-top: -16px;

    &__title {
      margin-top: 16px;
      color: var(--TextSecondary);
    }

    &__list {
      margin-bottom: 4px;
    }

    &__button {
      margin-bottom: 4px;
    }
  }
}
</style>
