import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

import { FormControlType } from '@leon-hub/form-utils';
import { assert } from '@leon-hub/guards';

import type { VButtonProps } from '@components/buttons';

import type { VCaptchaProperties } from 'web/src/components/Input';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';

import type {
  FormFieldProps,
  FormWidgetType,
  FormWrapperWidgetProperties,
  StatelessFormField,
} from '../../../types';
import type { UseFormFieldsOutput, UseFormFieldsProps } from './types';
import { isFormWrapperWidgetPropertiesByType } from '../../../guards/isFormWrapperWidgetProperties';
import mergeErrors from '../../../utils/mergeErrors';
import { getSubmitButtonProperties, getUiSchemaField, hasSubmitButton } from '../../../utils/uiSchema';
import getFormWidgetSchemaProperties from './utils/getFormWidgetSchemaProperties';
import getFormWrapperWidgetProperties from './utils/getFormWrapperWidgetProperties';
import getWidgetTypeList from './utils/getWidgetTypeList';

export default function useFormFields({
  schema,
  uiSchema,
  isFormReady,
  isPending,
  externalErrors,
  schemaErrors,
  formData,
}: UseFormFieldsProps): UseFormFieldsOutput {
  const regularWidgetList = ref<FormWidgetType[]>([]);
  const captchaRelatedField = ref<FormWidgetType | null>(null);

  const updateWidgetLists = (): void => {
    const { widgetTypeList, captchaWidget } = getWidgetTypeList(uiSchema.value);
    regularWidgetList.value = widgetTypeList;
    captchaRelatedField.value = captchaWidget;
  };

  watch(uiSchema, updateWidgetLists, { immediate: true });

  const fields = computed<StatelessFormField[]>(() => {
    const errors = mergeErrors(externalErrors.value, schemaErrors.value);
    return regularWidgetList.value.map(({ type, field }) => {
      const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
      const widgetProps = getFormWrapperWidgetProperties({
        name: field,
        fieldUiSchema,
        value: formData.value[field],
        error: errors[field],
        extraProperties: getFormWidgetSchemaProperties(schema.value.properties[field], fieldUiSchema, formData.value),
      });
      const fieldProps: FormFieldProps = {
        hidden: fieldUiSchema.hidden ?? fieldUiSchema.widget === FormControlType.Hidden,
        shortWidth: fieldUiSchema.shortWidth,
      };
      return {
        widget: type,
        widgetProps,
        id: field,
        fieldProps,
      };
    });
  });

  const captchaField = computed<FormWrapperWidgetProperties<VCaptchaProperties> | null>(() => {
    if (!captchaRelatedField.value) {
      return null;
    }
    const { field } = captchaRelatedField.value;
    const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
    const widgetProps = getFormWrapperWidgetProperties({
      name: field,
      fieldUiSchema,
      value: formData.value[field],
      error: '',
      extraProperties: {},
    });
    assert(isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties), 'captchaField is not match FormWrapperWidgetProperties<VCaptchaProperties> type');
    return widgetProps;
  });

  const buttonProps: ComputedRef<VButtonProps | null> = computed(() => {
    if (!hasSubmitButton(uiSchema.value)) {
      return null;
    }
    const properties = getSubmitButtonProperties(uiSchema.value);
    return {
      ...properties,
      isLoading: isPending.value,
      disabled: !isFormReady.value,
    };
  });

  return {
    fields,
    captchaField,
    buttonProps,
  };
}
