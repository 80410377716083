import type { DropListSelectOption, DropListSelectProps } from '../types';

export function getSelectedOption({
  isMultiselectMode,
  options,
}: DropListSelectProps, selectedValue: string, allFlatOptions: DropListSelectOption[]): DropListSelectOption | null {
  if (isMultiselectMode || !options || !selectedValue) {
    return null;
  }
  return allFlatOptions.find((item) => item.value === selectedValue) ?? null;
}
