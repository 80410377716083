import {
  assert,
  isBoolean,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from '@leon-hub/guards';

import type { UiFieldParsedOptions, UiFieldRawOptions } from '../types';
import { knownBooleanProps, knownNumberProps, knownStringArrayProps } from '../constants/knownFields';
import getBooleanOption from './getBooleanOption';
import getNumberOption from './getNumberOption';
import getStringArrayOption from './getStringArrayOption';

function getMappedValue(key: string, value: unknown): string | boolean | number | object | string[] | null {
  if (isNull(value) || isUndefined(value)) {
    return null;
  }
  if (isObject(value)) {
    return value;
  }
  if (knownBooleanProps.includes(key)) {
    return getBooleanOption(value);
  }
  if (knownNumberProps.includes(key)) {
    return getNumberOption(value);
  }
  if (knownStringArrayProps.includes(key)) {
    return getStringArrayOption(value);
  }
  // no handling for array, but its unexpected to be here
  // pass value without transformation
  assert(
    (isString(value) || isBoolean(value) || isNumber(value)),
    `getMappedValue: unexpected property type of ${key}: ${typeof value}`,
  );
  return value;
}

export default function mapFieldRawOptions(rawOptions: UiFieldRawOptions): UiFieldParsedOptions {
  const result: UiFieldParsedOptions = {};
  const keys = Object.keys(rawOptions);
  for (const key of keys) {
    const value = rawOptions[key];
    const mappedValue = getMappedValue(key, value);
    if (mappedValue !== null) {
      result[key] = mappedValue;
    }
  }
  return result;
}
