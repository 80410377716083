import {
  assert,
  isNumber,
} from '@leon-hub/guards';

export default function getNumberOption(value: unknown): number {
  const convertedValue = Number(value);
  assert(isNumber(convertedValue) && !Number.isNaN(convertedValue), `Can not to convert value to number: ${value}`);
  return convertedValue;
}
