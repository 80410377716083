import type { ShortPromotion } from '@leon-hub/api-sdk';

export default function getLatestCasinoPromotionUrl(input: Array<Pick<ShortPromotion, 'actionUrl' | 'endDateNumber'>>): string | null {
  const now = Date.now();
  const sortedPromotions = input
    .filter((promotion) => promotion.endDateNumber && promotion.endDateNumber > now)
    .sort((a, b) => {
      if (!a.endDateNumber || !b.endDateNumber || a.endDateNumber === b.endDateNumber) {
        return 0;
      }
      if (a.endDateNumber < b.endDateNumber) {
        return -1;
      }
      return 1;
    });
  return sortedPromotions[0]?.actionUrl || null;
}
