import type { FormatMoneyOptions } from '@core/money';

import type { PaymentsInputProps } from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/types';

export default function getFormatMoneyOptions(
  props: Pick<PaymentsInputProps, 'bonus' | 'deposit' | 'currency'>,
): FormatMoneyOptions {
  return props.deposit || props.bonus
    ? {
        currency: props.currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }
    : {};
}
