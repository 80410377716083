export enum ResponsibleGamblingOptionType {
  TEST = 'test',
  SUPPORT = 'support',
  LIMITS = 'limits',
  INFO = 'info',
  DELETE = 'delete',
}

export enum ResponsibleGamblingQuizResultsKind {
  DEFAULT = 'default',
  ACCEPTABLE = 'acceptable',
  MODERATELY = 'moderately',
  CRITICAL = 'critical',
}

export enum StepName {
  INFO = 'info',
  EDIT = 'edit',
}

export enum LimitName {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}
