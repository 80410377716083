import type { Web2FeedbackByCategoryRequest, Web2FeedbackRequest } from '@leon-hub/api-sdk';
import { doSendWeb2Feedback, doSendWeb2FeedbackByCategory, FeedbackChannel } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

interface UserFeedbackResponseComposable {
  sendUserResponse({
    body,
    channel,
    actionId,
    lbType,
    rating,
    email,
  }: Web2FeedbackRequest): Promise<void>;
  sendUserResponseByCategory({
    body,
    categoryId,
    rating,
    email,
  }: Web2FeedbackByCategoryRequest): Promise<void>;
}
export default function useUserFeedbackResponse(): UserFeedbackResponseComposable {
  const apiClient = useGraphqlClient();

  async function sendUserResponse({
    body,
    channel = FeedbackChannel.DEFAULT,
    actionId,
    lbType,
    rating,
    email,
  }: Web2FeedbackRequest): Promise<void> {
    await doSendWeb2Feedback(
      apiClient,
      (node) => node.mutations.feedback.sendWeb2Feedback,
      {
        options: {
          body,
          channel,
          actionId,
          lbType,
          rating,
          email,
        },
      },
    );
  }

  async function sendUserResponseByCategory({
    body,
    categoryId,
    rating,
    email,
  }: Web2FeedbackByCategoryRequest): Promise<void> {
    await doSendWeb2FeedbackByCategory(
      apiClient,
      (node) => node.mutations.feedback.sendWeb2FeedbackByCategory,
      {
        options: {
          body,
          categoryId,
          rating,
          email,
        },
      },
    );
  }

  return {
    sendUserResponse,
    sendUserResponseByCategory,
  };
}
