import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  onMounted,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import { useCurrency, useFormatMoney } from '@core/money';

import type { PaymentBonusesEmits } from 'web/src/modules/payments/components/PaymentBonuses/types';
import type {
  WelcomeBonusSelectProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelect/types';
import type {
  WelcomeBonusSelectorProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/types';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { usePaymentBonuses } from 'web/src/modules/payments/components/PaymentBonuses/composables';
import { DEPOSIT_BONUS_DEFAULT_VALUE } from 'web/src/modules/payments/constants';

interface WelcomeBonusSelectorComposable {
  isPaymentBonuses: Ref<boolean>;
  isDetailsAvailable: Ref<boolean>;
  welcomeBonusSelectProps: Ref<WelcomeBonusSelectProps>;
  onSelectClick(): void;
  openListModal(): void;
  onCloseModal(): void;
  onCloseAllModal(): void;
  onChange(campaignId: Maybe<number>): void;
}
export default function useWelcomeBonusSelector(
  props: WelcomeBonusSelectorProps,
  emit: PaymentBonusesEmits,
): WelcomeBonusSelectorComposable {
  const bonusStore = useBonusStore();
  const router = useRouter();

  const {
    emitBonusSelection,
    onNoDepositClick,
  } = usePaymentBonuses({ bonusList: props.depositBonusList }, emit);

  const formatMoney = useFormatMoney();
  const { currency } = useCurrency();

  const isSelected = ref<boolean>(false);

  const currentSelectedBonus = computed(() => props.depositBonusList.find(
    (item) => item.campaignId === props.selectedBonus,
  ));

  const currentPreselectedBonus = computed(() => props.depositBonusList.find(
    (item) => item.campaignId === props.preselectedBonus,
  ));

  const isPaymentBonuses = computed(() => props.isChoosingBonusBeforeDeposit && props.depositBonusList.length > 0);

  const isDetailsAvailable = computed(() => (
    !!currentSelectedBonus.value?.actionUrl && !!currentSelectedBonus.value?.campaignId
  ));

  const isSimplifyView = computed(() => !currentSelectedBonus.value);

  const welcomeBonusSelectProps = computed(() => {
    const data: WelcomeBonusSelectProps = {
      isSelected: isSelected.value,
      isSimplifyView: isSimplifyView.value,
    };

    if (currentSelectedBonus.value) {
      data.image = currentSelectedBonus.value.image ?? undefined;
      data.minimumDepositValue = formatMoney(currentSelectedBonus.value.minDepositAmount?.amount ?? 0, {
        currency: currentSelectedBonus.value.minDepositAmount?.currency || currency.value,
      });

      data.isLowMoneyAmount = Number(props.amount || 0) < (currentSelectedBonus.value.minDepositAmount?.amount || 0);
      data.campaignName = currentSelectedBonus.value.campaignName;
    } else if (!process.env.VUE_APP_FEATURE_WELCOME_BONUS_WITHOUT_PRESELECTION_ENABLED && currentPreselectedBonus.value) {
      data.isSimplifyView = false;
      data.image = currentPreselectedBonus.value.image ?? undefined;
      data.minimumDepositValue = formatMoney(currentPreselectedBonus.value.minDepositAmount?.amount ?? 0, {
        currency: currentPreselectedBonus.value.minDepositAmount?.currency || currency.value,
      });
      data.campaignName = currentPreselectedBonus.value.campaignName;

      data.isLowMoneyAmount = Number(props.amount || 0) < (currentPreselectedBonus.value.minDepositAmount?.amount || 0);
    } else if (process.env.VUE_APP_FEATURE_WELCOME_BONUS_WITHOUT_PRESELECTION_ENABLED) {
      data.image = {

        src: require('web/src/assets/images/leonru/welcome-bonus-logo.png'),
      };
    }

    return data;
  });

  function onSelectClick(): void {
    isSelected.value = !isSelected.value;

    if (isSelected.value && currentPreselectedBonus.value) {
      void setSelectedOptionId(currentPreselectedBonus.value.campaignId);
    } else if (!isSelected.value) {
      onNoDepositClick();
    }
  }

  function openListModal(): void {
    bonusStore.setChoosingBonusBeforeDeposit(true);
  }

  function onCloseModal(): void {
    bonusStore.setChoosingBonusBeforeDeposit(false);
  }

  function onCloseAllModal(): void {
    void router.closeModal();
  }

  function onChange(campaignId: Maybe<number>): void {
    if (campaignId) {
      const selectedBonus = props.depositBonusList.find((bonus) => bonus.campaignId === campaignId);

      if (selectedBonus) {
        void bonusStore.setPreselectedBonus(campaignId);
        isSelected.value = true;
        bonusStore.setChoosingBonusBeforeDeposit(false);
        emitBonusSelection({ campaignId: selectedBonus.campaignId, bonusCode: selectedBonus.bonusCode });
      }
    }
  }

  async function setSelectedOptionId(value = DEPOSIT_BONUS_DEFAULT_VALUE): Promise<void> {
    isSelected.value = true;

    void bonusStore.setPreselectedBonus(value);

    if (props.selectedBonus === value) {
      bonusStore.setBonusSelection(value);
      return;
    }
    await nextTick();
    bonusStore.setBonusSelection(value);
    emit('change', String(props.selectedBonus));
  }

  useBusSafeSubscribe(BusEvent.DEPOSIT_BONUS_WIDGET_OPEN_LIST, openListModal);

  onMounted(() => {
    const campaignId = router.getQuery('campaignId');
    onNoDepositClick();

    if (campaignId) {
      const bonusId = props.depositBonusList.find((item) => Number(item.campaignId) === Number(campaignId));
      void setSelectedOptionId(bonusId?.campaignId);
    } else if (!process.env.VUE_APP_FEATURE_WELCOME_BONUS_WITHOUT_PRESELECTION_ENABLED) {
      void setSelectedOptionId(props.depositBonusList?.[0]?.campaignId);
    }
  });

  return {
    isPaymentBonuses,
    isDetailsAvailable,
    welcomeBonusSelectProps,
    onSelectClick,
    openListModal,
    onCloseModal,
    onCloseAllModal,
    onChange,
  };
}
