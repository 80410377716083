import type { BetSlipSaveRequest } from '@leon-hub/api-sdk';
import { doSaveBetSlip } from '@leon-hub/api-sdk';

import type { ApplicationGqlClient } from '@core/app-rest-client';

import type { DataForSharing } from '../types';

export function requestSaveBetSlip(input: BetSlipSaveRequest, apiClient: ApplicationGqlClient): Promise<DataForSharing> {
  return doSaveBetSlip(apiClient, (node) => node.mutations.betSlip.saveBetSlip, {
    options: input,
  });
}
