import type { Component } from 'vue';

import type { HintLink, HintLinkProps } from '@leon-hub/input-types';

import { VRouterLink } from '@components/link';

const defaultLinkTag = 'a';

export function isNativeAnchor(hintLink?: Maybe<HintLink>): boolean {
  return !!(hintLink && typeof hintLink.href === 'string');
}

export function getHintLinkComponent(isNative: boolean): string | Component {
  return isNative ? defaultLinkTag : VRouterLink;
}

export function getHintLinkProps(isNative: boolean, hintLink?: Maybe<HintLink>): Partial<HintLinkProps> {
  if (isNative) {
    return {
      href: hintLink?.href,
      target: hintLink?.target,
    };
  }
  return {
    to: hintLink?.href,
    target: hintLink?.target,
  };
}
