import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import type { VPhotoInputProps } from 'web/src/components/PhotoInput/VPhotoInput/types';
import isVPhotoInputProps from 'web/src/components/PhotoInput/VPhotoInput/guards/isVPhotoInputProps';

import type {
  FormWrapperWidgetProperties,
} from '../../types';

interface UseFormPhotoInput {
  componentProps: ComputedRef<VPhotoInputProps>;
}

export default function useFormPhotoInput(props: FormWrapperWidgetProperties<VPhotoInputProps>): UseFormPhotoInput {
  const componentProps = computed<VPhotoInputProps>(
    () => {
      const {
        name,
        options,
      } = props;
      const result = {
        ...(options || {}),
        name,
      };
      assert(isVPhotoInputProps(result));
      return result;
    },
  );
  return {
    componentProps,
  };
}
