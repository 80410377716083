import type { Ref } from 'vue';
import {
  computed,
  onMounted,
  ref,
  toRef,
} from 'vue';

import type { ScrollToElementOptions } from '@leon-hub/scrollbar-types';
import { useWindowResize } from '@leon-hub/browser-composables';

import type { VScrollbarEmits, VScrollbarProps } from '../../types';
import getTestDataAttrs from '../../utils/getTestDataAttrs';

export interface VScrollbarPhoneComposable {
  testAttrs: Ref<Record<string, string>>;
  element: Ref<HTMLElement | null>;
  scrollTo(top: number): void;
  scrollTop(): void;
  scrollDown(): void;
  scrollRight(): void;
  scrollToElement(el: HTMLElement | null, options?: ScrollToElementOptions): void;
}

export default function useVScrollbarPhone(
  props: VScrollbarProps,
  emit: VScrollbarEmits,
): VScrollbarPhoneComposable {
  const testAttrs = computed(
    () => getTestDataAttrs('scrollbar', props),
  );

  const element = ref<HTMLElement | null>(null);
  let offsetXDelta = 0;

  onMounted(() => {
    const el = element.value;
    if (el && (props.useScrollListener || props.handleResizeChange)) {
      el.addEventListener('scroll', () => {
        offsetXDelta = el.scrollHeight - el.scrollTop - el.offsetHeight;

        if (el.scrollHeight <= Math.ceil(el.scrollTop) + el.offsetHeight) {
          emit('reached-bottom');
        }
      });
    }
  });

  function scrollTop(): void {
    scrollTo(0);
  }

  function scrollDown(): void {
    scrollTo(element.value?.scrollHeight || 0);
  }

  function scrollRight(): void {
    const el = element.value;

    if (el) {
      el.scrollTo({
        top: 0,
        left: el.scrollWidth,
      });
    }
  }

  function scrollTo(top: number): void {
    const el = element.value;

    if (el) {
      el.scrollTo({
        top,
        left: 0,
      });
    }
  }

  function scrollToElement(el: HTMLElement | null, options?: ScrollToElementOptions): void {
    if (el) {
      el.scrollIntoView({
        block: options?.onlyIfNeeded ? 'nearest' : 'start',
      });
    }
  }

  const handleResizeChange = toRef(props, 'handleResizeChange');
  useWindowResize(({ deltaY }) => {
    if (element.value && deltaY !== 0) {
      element.value.scrollTo({
        top: element.value.scrollHeight - element.value.offsetHeight - offsetXDelta,
      });
    }
  }, {
    condition: handleResizeChange,
  });

  return {
    testAttrs,
    element,
    scrollTo,
    scrollTop,
    scrollDown,
    scrollRight,
    scrollToElement,
  };
}
