import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { Theme } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import { useSiteConfigStore } from '@core/site-config';

import type { FramedVirtualSportWidgetSport } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/enums';
import type {
  SlipMappingKey,
  VirtualSportBet,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import type {
  ResolveVirtualIdsResponse,
} from 'web/src/modules/sportline/submodules/virtual-sport/services/api/types';
import type {
  VirtualSportFilterOption,
  VirtualSportFilterOptions,
  VirtualSportSelectedFilter,
  VirtualSportWidgetConfig,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { framedVirtualSportBySportId } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/constants';
import { useSlipRunnerClick } from 'web/src/modules/slip/composable';
import { useSlipInfoStore } from 'web/src/modules/slip/submodules/slip-info/store';
import { useSlipSelectedEntriesStore } from 'web/src/modules/slip/submodules/slipSelectedEntries/store';
import { getSlipEntryId } from 'web/src/modules/slip/utils';
import { useVirtualSportApi } from 'web/src/modules/sportline/submodules/virtual-sport/services/api/useVirtualSportApi';
import {
  findSelectedVirtualSportFilterOption,
  VirtualSportBetsMapper,
  VirtualSportBetsMappingStorage,
} from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import { concatUrlPathNames } from 'web/src/utils/url';

import { useVirtualSportItemsDisplayConfig } from './composables/useVirtualSportItemsDisplayConfig';
import { useVirtualSportDebugStore } from './useVirtualSportDebugStore';

export const useVirtualSportStore = defineStore('sportline-virtual-sport', () => {
  const virtualSportApi = useVirtualSportApi();

  const siteConfigStore = useSiteConfigStore();
  const widgetsBlock = toRef(() => siteConfigStore.widgetsBlock);
  const virtualSportSettingsBlock = toRef(() => siteConfigStore.virtualSport);

  const { onSlipRunnerClick } = useSlipRunnerClick();
  const { removeBetClick } = useSlipInfoStore();

  const slipSelectedEntriesStore = useSlipSelectedEntriesStore();
  const selectedEventsInfo = toRef(slipSelectedEntriesStore, 'selectedEventsInfo');

  const debugVirtualSportStore = useVirtualSportDebugStore();
  const { runDebugDelay } = debugVirtualSportStore;
  const isDebugEnabled = toRef(debugVirtualSportStore, 'isDebugEnabled');

  const {
    itemsDisplayConfig,
  } = useVirtualSportItemsDisplayConfig();

  const virtualSportBetsMappingStorage = new VirtualSportBetsMappingStorage();
  const virtualSportBetsMapper = new VirtualSportBetsMapper(
    () => virtualSportBetsMappingStorage,
    async (bet, options = {}) => {
      let response: Maybe<ResolveVirtualIdsResponse> = null;

      try {
        // Use a delay for ensure that data is received from the provider
        await runDebugDelay();

        response = await virtualSportApi.resolveVirtualIds({
          marketId: bet.marketId,
          matchId: bet.matchId,
          odds: bet.odds,
          outcomeId: bet.outcomeId,
          marketSpecifier: bet.marketSpecifier,
          sport: bet.sport,
          silent: !!options.silent,
        });
      } catch (error) {
        if (!options.silent) {
          throw error;
        }
      }

      if (!response) {
        return undefined;
      }

      return {
        eventId: response.eId,
        marketId: response.mId,
        runnerId: response.rId,
      };
    },
    {
      repeatOnError: true,
      repeatOnErrorTimeout: 2000,
    },
  );

  const internalSelectedFilter = ref<VirtualSportSelectedFilter>({});

  const isVirtualSportAvailable = computed<boolean>(() => !!virtualSportSettingsBlock.value?.isEnabled);
  const widgetConfig = computed<VirtualSportWidgetConfig>(() => {
    // @TODO fix reloading virtual events widget for the theme selection
    const iframeUrl = concatUrlPathNames(widgetsBlock.value?.iframeUrl ?? '', Theme.DARK);

    return {
      iframeUrl: iframeUrl.toString(),
      providerUrl: virtualSportSettingsBlock.value?.providerUrl ?? '',
      stylable: isDebugEnabled.value,
    };
  });
  const filterOptions = computed<VirtualSportFilterOptions>(() => {
    const vsmConfig = virtualSportSettingsBlock.value?.items ?? [];
    const sports: VirtualSportFilterOption[] = vsmConfig.map<Maybe<VirtualSportFilterOption>>((config) => {
      const sport: Optional<FramedVirtualSportWidgetSport> = framedVirtualSportBySportId[config.tournament];

      return sport && config
        ? {
            id: config.tournament,
            sport,
            order: config.order,
            selectedByDefault: config.selectedByDefault || false,
            clientId: config.clientId,
            navigationParameters: {
              urlName: config.sportUrlName || sport,
            },
            displayConfig: itemsDisplayConfig.value[config.tournament],
          }
        : null;
    })
      .filter<VirtualSportFilterOption>((sport): sport is VirtualSportFilterOption => !!sport?.sport
        && !!sport?.clientId);

    return { sports };
  });
  const selectedFilter = computed<VirtualSportSelectedFilter>(() => {
    const options = filterOptions.value;
    const selectedOption = findSelectedVirtualSportFilterOption(options, internalSelectedFilter.value);
    return {
      urlName: selectedOption?.navigationParameters.urlName
        || options.sports.find((option) => option.selectedByDefault)?.navigationParameters.urlName
        || options?.sports[0]?.navigationParameters.urlName,
    };
  });
  const selectedOption = computed<Maybe<VirtualSportFilterOption>>(() => findSelectedVirtualSportFilterOption(
    filterOptions.value,
    selectedFilter.value,
  ));
  const selectedBetsMappings = computed<SlipMappingKey[]>(() => (
    virtualSportBetsMappingStorage.getKeysByMappings(selectedEventsInfo.value.map((selectedEvent) => ({
      eventId: selectedEvent.event,
      marketId: selectedEvent.market,
      runnerId: selectedEvent.runner,
    })))
  ));

  function setListFilter(filter: VirtualSportSelectedFilter): void {
    internalSelectedFilter.value = filter;
  }

  async function addToSlip(bet: VirtualSportBet | SlipMappingKey): Promise<void> {
    if (!('marketName' in bet)) {
      throw new Error('Can\'t add bet by selection key');
    }

    const slipMapping = await virtualSportBetsMapper.getSlipMappingByBet(bet).catch();

    if (!slipMapping) {
      logger.error('Virtual sport empty slip mapping');
      return;
    }

    onSlipRunnerClick({
      event: slipMapping.eventId,
      market: slipMapping.marketId,
      runner: slipMapping.runnerId,
      odds: Number(bet.odds),
      competitors: bet.competitors,
      eventName: bet.eventName || undefined,
      runnerName: bet.marketName,
      marketName: bet.outcomeName,
      zeroMargin: false,
      ts: Date.now(),
      /** props for total/handicap replacement can not be applied here */
      marketTypeIdentifier: 0,
      runnerTags: [],
      marketTypeTag: undefined,
    });
  }

  async function removeFromSlip(bet: SlipMappingKey): Promise<void> {
    const slipMapping = await virtualSportBetsMapper.getSlipMappingByBet(bet);

    if (!slipMapping) {
      logger.error('Virtual sport Remove from slip empty slip mapping');
      return;
    }

    const slipEntryId = getSlipEntryId({
      event: slipMapping.eventId,
      market: slipMapping.marketId,
      runner: slipMapping.runnerId,
    });

    await removeBetClick(slipEntryId);
  }

  return {
    isVirtualSportAvailable,
    widgetConfig,
    filterOptions,
    selectedFilter,
    selectedOption,
    selectedBetsMappings,
    setListFilter,
    addToSlip,
    removeFromSlip,
  };
});
