import type { ComputedRef, Ref } from 'vue';
import { computed, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';

import { HistoryFilter, TransactionGroup } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { FormatMoneyFunc } from '@core/money';
import { useCurrency, useFormatMoney } from '@core/money';
import { useUserStore } from '@core/user';

import type { RequestedAmountEmits } from 'web/src/modules/profile/components/RequestedAmount/types';
import { useCustomerHistoryStore } from 'web/src/modules/profile/submodules/customer-history/store';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';

export interface RequestedAmountComposable {
  currency: Ref<string>;
  requestedValue: ComputedRef<number>;
  $formatMoney: FormatMoneyFunc;

  onClickRequest(): void;
}

export default function useRequestedAmount(emit?: RequestedAmountEmits): RequestedAmountComposable {
  const $formatMoney = useFormatMoney();
  const userStore = useUserStore();
  const { currency } = useCurrency();
  const customerHistoryStore = useCustomerHistoryStore();

  const router = useRouter();

  const profileData = toRef(userStore, 'profileData');
  const orderFilter = toRef(customerHistoryStore, 'orderFilter');

  const requestedValue = computed(() => profileData.value?.requestedWithdrawals.requestedAmount ?? 0);
  const singlePaymentId = computed(() => profileData.value?.requestedWithdrawals.singlePaymentId ?? 0);
  const requestedCount = computed(() => profileData.value?.requestedWithdrawals.requestedCount ?? 0);

  function onClickRequest(): void {
    if (singlePaymentId.value && requestedCount.value === 1) {
      void router.push({
        name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
        params: {
          id: `${singlePaymentId.value}`,
          group: TransactionGroup.PAYMENT,
        },
      });
    } else {
      void router.push({
        name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
        query: {
          filter: HistoryFilter.ALL_WITHDRAWALS,
          activeOrderFilter: ActiveOrderFilterEnum.DEFAULT,
        },
      });
    }
    if (emit) {
      emit('close');
    }
  }

  watch(requestedValue, (newValue: number, oldValue: number) => {
    if (newValue < oldValue) {
      void customerHistoryStore.updateCustomerHistoryAction(orderFilter.value);
    }
  });

  return {
    requestedValue,
    currency,
    $formatMoney,
    onClickRequest,
  };
}
