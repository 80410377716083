<script lang="ts" setup>
import { ref, type VNode, watch } from 'vue';

interface CoverProperties {
  opened?: boolean;
}

interface CoverEmits {
  (e: 'change', value: boolean): void;
}

interface CoverSlots {
  default(props: {
    isOpen: boolean;
    isLoaded: boolean;
    toggle(): void;
    open(): void;
    close(): void;
  }): VNode;
}

const props = withDefaults(defineProps<CoverProperties>(), {});
const emit = defineEmits<CoverEmits>();

const isOpen = ref(false);
const isLoaded = ref(props.opened);

defineSlots<CoverSlots>();

watch(() => props.opened, (to) => {
  isOpen.value = to;
}, {
  immediate: true,
});

watch(isOpen, (to) => {
  if (to) {
    isLoaded.value = true;
  }
  emit('change', to);
}, {
  immediate: true,
});

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <slot
    :is-open="isOpen"
    :is-loaded="isLoaded"
    :toggle="toggle"
    :open="open"
    :close="close"
  />
</template>
