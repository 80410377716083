import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import { getCasinoLoyaltyStatus } from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { logger } from '@leon-hub/logging';
import { Json, Timer } from '@leon-hub/utils';
import { LoyaltyCustomerLevelType } from '@leon-hub/websocket';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';
import { useGraphqlClient } from '@core/app-rest-client';
import { useIsLoggedIn } from '@core/auth';
import { useErrorsConverter } from '@core/errors';
import { useFormatMoney } from '@core/money';
import { useSiteConfigStore, useWebSocketsConfig } from '@core/site-config';
import { useWebSockets } from '@core/websockets';

import type {
  CasinoLevelSettingWithStatus,
  CasinoLoyaltyLevelSetting,
  CasinoLoyaltyProgramCurrentLevelCardProperties,
  CasinoLoyaltyProgramProgressProperties,
  CasinoLoyaltyStatusDocument,
  UpdateCasinoLoyaltyStatusDataProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';
import {
  CASINO_LOYALTY_PROGRAM_LEVEL_KEY,
  LOYALTY_STATUS_POLLING_INTERVAL,
} from 'web/src/modules/casino-loyalty-program/store/consts';
import {
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';
import {
  getCurrentPointsToString,
  getProgressProps,
  getRemainingPoints,
  levelSettingsById,
} from 'web/src/modules/casino-loyalty-program/store/utils';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';

const useCasinoLoyaltyStore = defineStore('casino-loyalty-store', () => {
  const { subscribeAuthorized } = useWebSockets();
  const { isLoyaltyProgramEnabled } = useWebSocketsConfig();
  const apiClient = useGraphqlClient();
  const analytics = useAnalytics();
  const { convertToBaseError } = useErrorsConverter();
  const siteConfigStore = useSiteConfigStore();
  const localStorage = useLocalStorageManager();
  const { isLoggedIn } = useIsLoggedIn();
  const formatMoney = useFormatMoney();

  let timeoutModal = 0;
  const isEgsLoyaltyEnabled = toRef(siteConfigStore, 'isEgsLoyaltyEnabled');

  // state
  const currentLevelId = ref(0);
  const currentPoints = ref(0);
  const pointsUntilNextLevel = ref(0);
  const multiplier = ref(0);
  const name = ref('');
  const levelSettings = ref<readonly CasinoLoyaltyLevelSetting[]>([]);
  const type = ref('');
  const hideCasinoLoyaltyOnboardingPage = ref(false);
  const isShowLevelDoneModal = ref(false);
  const savedLocalStorageDataBanner = ref<{
    progressProps: CasinoLoyaltyProgramProgressProperties;
    background: string;
    nextLevelTitle: string;
    secondaryText: string;
  } | null>(null);

  // getters
  const currentLevelData = computed(() => {
    if (!isLoggedIn.value && savedLocalStorageDataBanner.value) {
      return { background: savedLocalStorageDataBanner.value.background } as CasinoLoyaltyLevelSetting;
    }
    return levelSettings.value.find((level) => level.id === currentLevelId.value);
  });
  const nextLevelData = computed(() => {
    const currentLevelIndex = levelSettings.value.findIndex((level) => level.id === currentLevelId.value);

    if (currentLevelIndex === levelSettings.value.length - 1) {
      return null;
    }
    return levelSettings.value[currentLevelIndex + 1] ?? null;
  });

  const isCurrentInitLevel = computed(() => {
    if (!levelSettings.value.length)
      return false;
    return levelSettings.value[0].id === currentLevelId.value;
  });

  const currentLevelProgressInPercent = computed(() => {
    const requiredPoints = nextLevelData.value !== null
      ? nextLevelData.value.requiredPoints
      : currentLevelData.value?.requiredPoints;
    const previousPoints = nextLevelData.value !== null ? Number(currentLevelData.value?.requiredPoints) : 0;
    const divider = (Number(requiredPoints) - previousPoints) || 1;

    if (isCurrentInitLevel.value && requiredPoints) {
      return (currentPoints.value * 100) / requiredPoints;
    }

    return ((currentPoints.value - previousPoints) * 100) / divider;
  });

  const levelSettingsWithStatus = computed(() => {
    const order = currentLevelData.value?.order;

    if (order) {
      const levelsDone = levelSettings.value
        .filter((level) => level.order !== 1 && currentPoints.value >= level.requiredPoints)
        .map((object) => ({ ...object, status: CasinoLoyaltyProgramLevelStatus.SUCCESS }));
      const levelsProcess = levelSettings.value
        .filter((level) => level.order === order + 1 && currentPoints.value < level.requiredPoints)
        .map((object) => ({
          ...object,
          status: CasinoLoyaltyProgramLevelStatus.PROCESS,
          progress: currentLevelProgressInPercent.value,
        }));
      const levelsNotDone = levelSettings.value
        .filter((level) => level.order > order + 1)
        .map((object) => ({ ...object, status: CasinoLoyaltyProgramLevelStatus.LOCK }));

      return [...levelsDone, ...levelsProcess, ...levelsNotDone] as CasinoLevelSettingWithStatus[];
    }
    return [];
  });
  const progressProperties = computed(() => {
    let requiredPoints: Optional<number>;
    if (nextLevelData.value !== null) {
      requiredPoints = nextLevelData.value.requiredPoints;
    } else {
      requiredPoints = currentLevelData.value?.requiredPoints;
    }
    const imageSrcNextLevel = nextLevelData.value ? nextLevelData.value?.imageUrl : currentLevelData.value?.imageUrl;

    return {
      isInactive: !!nextLevelData.value,
      progress: currentLevelProgressInPercent.value,
      currentValue: currentPoints.value,
      imageSrc: currentLevelData.value?.imageUrl,
      imageSrcNextLevel,
      total: requiredPoints,
      image: currentLevelData.value?.image,
    };
  });
  const levelGameProperties = computed(() => ({
    ...progressProperties.value,
    backgroundColor: currentLevelData.value?.background,
    levelTitle: currentLevelData.value?.name,
    multiplier: `${currentLevelData.value?.multiplier}x`,
  }));
  const currentLevelCardProperties = computed(() => {
    if (!isLoggedIn.value && savedLocalStorageDataBanner.value) {
      return { nextLevelTitle: savedLocalStorageDataBanner.value.nextLevelTitle } as CasinoLoyaltyProgramCurrentLevelCardProperties;
    }
    return {
      ...levelGameProperties.value,
      multiplier: `${multiplier.value}x`,
      initLevel: isCurrentInitLevel.value,
      nextLevelTitle: nextLevelData.value?.name,
    };
  });

  const nextLevelProgressProps = computed(() => {
    const settings = levelSettingsWithStatus.value.find((
      level: CasinoLevelSettingWithStatus,
    ) => level.id === nextLevelData.value?.id);
    return {
      progress: settings?.progress,
      imageSrc: settings?.imageUrl,
      isInactive: settings?.status !== CasinoLoyaltyProgramLevelStatus.SUCCESS,
    };
  });

  // actions
  const setCasinoLoyaltyStatus = (data: CasinoLoyaltyStatusDocument | null) => {
    if (!data) {
      return;
    }
    const {
      id: dataId,
      currentPoints: dataCurrentPoints,
      levelSettings: dataLevelSettings,
      multiplier: dataMultiplier,
      name: dataName,
      pointsUntilNextLevel: dataPointsUntilNextLevel,
    } = data;
    currentLevelId.value = dataId || 0;
    currentPoints.value = dataCurrentPoints || 0;
    name.value = dataName || '';
    multiplier.value = dataMultiplier || 0;
    levelSettings.value = dataLevelSettings || [];
    pointsUntilNextLevel.value = dataPointsUntilNextLevel || 0;
  };

  const updateCasinoLoyaltyStatus = (data: UpdateCasinoLoyaltyStatusDataProperties) => {
    currentPoints.value = data.newProperties.currentPoints || 0;
    pointsUntilNextLevel.value = data.newProperties.pointsUntilNextLevel || 0;
    currentLevelId.value = data.newProperties.currentLevelId || 0;
    multiplier.value = data.newProperties.multiplier || 0;
    type.value = data.newProperties.type ?? '';
  };

  const setHideCasinoLoyaltyOnBoardingPageState = (value: boolean) => {
    hideCasinoLoyaltyOnboardingPage.value = value;
  };

  const showTimeoutLevelDoneModal = () => {
    const delay = 3000;
    isShowLevelDoneModal.value = true;

    timeoutModal = Timer.setTimeout(() => {
      isShowLevelDoneModal.value = false;
    }, delay);
  };

  const toggleShowLevelDoneModal = () => {
    if (timeoutModal) {
      Timer.clearTimeout(timeoutModal);
      timeoutModal = 0;
    }
    isShowLevelDoneModal.value = !isShowLevelDoneModal.value;
  };

  const fetchCasinoLoyaltyStatus = async () => {
    if (isEgsLoyaltyEnabled.value) {
      try {
        const result = await getCasinoLoyaltyStatus(apiClient, (node) => node.queries.bonuses.getCasinoLoyaltyStatus);
        setCasinoLoyaltyStatus(result);
        if (!isObject(result)) {
          logger.error(`Wrong data type in getCasinoLoyaltyStatus. Data = ${Json.stringify(result)}`);
        }
        if (result && !hideCasinoLoyaltyOnboardingPage.value) {
          setHideCasinoLoyaltyOnBoardingPageState(result.hideEgsLoyaltyOnboardingPage);
        }
        analytics.push(AnalyticsEvent.Z_PROGRESS_LOYALTY_PROGRAM, {
          levelLoyaltyProgram: {
            progress: result?.name,
          },
        });
      } catch (originalError) {
        const error = convertToBaseError(originalError);
        if (error.code.toString() !== RegistrationApiErrorCode.TECH) {
          throw (originalError);
        }
      }
    }
  };

  const subscribeOnLoyaltyCustomerLevel = () => {
    subscribeAuthorized({
      method: 'onLoyaltyCustomerLevel',
      onMessage: (data) => {
        if (data.onLoyaltyCustomerLevel.type === LoyaltyCustomerLevelType.BALANCE_CHANGE) {
          updateCasinoLoyaltyStatus({
            newProperties: {
              currentLevelId: data.onLoyaltyCustomerLevel.currentLevelId,
              currentPoints: data.onLoyaltyCustomerLevel.currentPoints,
              multiplier: data.onLoyaltyCustomerLevel.multiplier,
              pointsUntilNextLevel: data.onLoyaltyCustomerLevel.pointsUntilNextLevel,
              type: data.onLoyaltyCustomerLevel.type,
            },
          });
        } else if (data.onLoyaltyCustomerLevel.type === LoyaltyCustomerLevelType.CUSTOMER_LEVEL_UPGRADE) {
          void fetchCasinoLoyaltyStatus().then(() => {
            showTimeoutLevelDoneModal();
          });
        } else {
          void fetchCasinoLoyaltyStatus();
        }
      },
      isEnabled: isLoyaltyProgramEnabled,
      polling: {
        timeout: ref(LOYALTY_STATUS_POLLING_INTERVAL),
        callback: () => fetchCasinoLoyaltyStatus(),
        callOnLogin: true,
      },
    });
  };

  const progressProps = computed<CasinoLoyaltyProgramProgressProperties>(() => {
    if (!isLoggedIn.value && savedLocalStorageDataBanner.value) {
      return savedLocalStorageDataBanner.value.progressProps;
    }
    const selectedLevel = levelSettingsById(nextLevelData.value?.id || 0, levelSettingsWithStatus.value);

    return getProgressProps(selectedLevel?.progress, selectedLevel?.imageUrl, selectedLevel?.status);
  });

  const remainingPoints = computed<string>(
    () => getRemainingPoints(nextLevelData.value?.requiredPoints, formatMoney),
  );

  const currentPointsToString = computed<string>(
    () => getCurrentPointsToString(currentPoints.value, formatMoney),
  );

  const secondaryText = computed<string>(() => {
    if (!isLoggedIn.value && savedLocalStorageDataBanner.value)
      return savedLocalStorageDataBanner.value.secondaryText;
    return `${currentPointsToString.value} / ${remainingPoints.value}`;
  });

  function setSavedData() {
    const data = localStorage.getItem(CASINO_LOYALTY_PROGRAM_LEVEL_KEY);
    savedLocalStorageDataBanner.value = data ? JSON.parse(data) : null;
  }

  watch(levelSettings, () => {
    if (isLoggedIn.value) {
      const data = {
        progressProps: progressProps.value,
        background: currentLevelData.value?.background ?? '',
        nextLevelTitle: currentLevelCardProperties.value?.nextLevelTitle ?? '',
        secondaryText: secondaryText.value,
      };
      localStorage.setItem(CASINO_LOYALTY_PROGRAM_LEVEL_KEY, JSON.stringify(data));
      savedLocalStorageDataBanner.value = { ...data };
    }
  }, {
    deep: true,
    immediate: true,
  });

  function onInitStore() {
    if (!isLoggedIn.value) {
      setSavedData();
    }
  }

  onInitStore();
  subscribeOnLoyaltyCustomerLevel();

  return {
    name,
    currentLevelCardProperties,
    levelSettingsWithStatus,
    currentPoints,
    currentLevelId,
    levelGameProperties,
    progressProperties,
    nextLevelData,
    currentLevelData,
    levelSettings,
    setHideCasinoLoyaltyOnBoardingPageState,
    hideCasinoLoyaltyOnboardingPage,
    fetchCasinoLoyaltyStatus,
    toggleShowLevelDoneModal,
    nextLevelProgressProps,
    isShowLevelDoneModal,
    progressProps,
    secondaryText,
  };
});

export default useCasinoLoyaltyStore;
