import type { I18nFunctionReactive } from '@core/i18n';

import type { EgsGameInfoOverlayProps } from 'web/src/modules/egs/components/EgsGameInfoOverlay/types';

export default function getSeats(
  props: EgsGameInfoOverlayProps,
  $t: I18nFunctionReactive,
): string {
  if (!props.availability?.players) {
    return '';
  }

  const { current, total } = props.availability.players;

  if (current >= total) {
    return $t('WEB2_EGS_GAME_NO_SEATS').value;
  }

  return `${current}/${total}`;
}
