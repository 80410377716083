import type { Ref } from 'vue';
import { computed } from 'vue';

import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import type { SportElement } from 'web/src/modules/sportline/types';
import type { GetMergedHeadlineEventsResponse } from 'web/src/modules/sportline/types/rest';
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { HeadlineFactory } from 'web/src/modules/sportline/submodules/top/utils';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';

interface UseMixedHeadlineListsStoreProps {
  rawResponse: Ref<Maybe<GetMergedHeadlineEventsResponse>>;
}

interface UseMixedHeadlineListsStoreComposable {
  isReady: Ref<boolean>;
  totalCount: Ref<number>;
  headlineElements: Ref<HeadlineMatchesSportListElement[]>;
  sportlineElements: Ref<SportElement[]>;
}

export function useMixedHeadlineListsStore(
  props: UseMixedHeadlineListsStoreProps,
): UseMixedHeadlineListsStoreComposable {
  const { rawResponse } = props;

  const {
    parseSportlineSettings,
  } = useSportlineTopEventsSettings();

  const isReady = computed(() => rawResponse.value !== null);

  const totalCount = computed<number>(() => (rawResponse.value?.total ?? 0));

  /** Mixed list of elements */
  const headlineElements = computed<HeadlineMatchesSportListElement[]>(() => {
    const response = rawResponse.value?.events ?? null;
    if (!response) { return []; }
    return (new HeadlineFactory(response, parseSportlineSettings.value)).build() || [];
  });
  const sportlineElements = computed<SportElement[]>(() => {
    const response = rawResponse.value?.events ?? null;
    if (!response) { return []; }
    return (new SportlineFactory(response, parseSportlineSettings.value)).build() || [];
  });

  return {
    isReady,
    totalCount,
    headlineElements,
    sportlineElements,
  };
}
