import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

import type {
  DropListSelectOption,
  DropListSelectProps,
} from '../types';
import {
  getDefaultMultipleSelectedValues,
  getDefaultSelectedValue,
  getMultipleSelectionText,
  getSelectedOption,
} from '../utils';

interface UseDropListSelected {
  selectedId: ComputedRef<string | null>;
  multipleSelectedValues: ComputedRef<string[]>;
  selectedText: ComputedRef<string>;
  selectedSecondaryText: ComputedRef<string>;
  selectedSecondaryBadge: ComputedRef<string>;
  selectedOption: ComputedRef<DropListSelectOption | null>;
  setSingleSelected(value: string): void;
  toggleMultipleSelected(value: string): void;
  isSelected(value: string): boolean;
}

export function useDropListSelected(props: DropListSelectProps, allFlatOptions: ComputedRef<DropListSelectOption[]>): UseDropListSelected {
  // selected

  const singleSelectedValue = ref<string>(getDefaultSelectedValue(props));

  const multipleSelectedValuesSet = ref<Set<string>>(getDefaultMultipleSelectedValues(props));

  const multipleSelectedValues = computed<string[]>(() => Array.from(multipleSelectedValuesSet.value));

  const selectedId = computed<string | null>(() => {
    if (props.isMultiselectMode) {
      return null;
    }
    return singleSelectedValue.value ?? null;
  });

  const selectedOption = computed<DropListSelectOption | null>(() => getSelectedOption(props, singleSelectedValue.value, allFlatOptions.value));

  const selectedText = computed<string>(() => {
    const textFromSelection = props.isMultiselectMode ? getMultipleSelectionText(allFlatOptions.value, multipleSelectedValuesSet.value) : selectedOption.value?.label;
    return textFromSelection || props.placeholder || '';
  });

  const selectedSecondaryText = computed<string>(() => selectedOption.value?.rightText ?? '');
  const selectedSecondaryBadge = computed<string>(() => selectedOption.value?.badge ?? '');

  const setSingleSelected = (value: string): void => {
    singleSelectedValue.value = value;
  };

  const setMultipleSelected = (value: Set<string>) => {
    multipleSelectedValuesSet.value = value;
  };

  const toggleMultipleSelected = (value: string): void => {
    if (multipleSelectedValuesSet.value.has(value)) {
      multipleSelectedValuesSet.value.delete(value);
    } else {
      multipleSelectedValuesSet.value.add(value);
    }
  };

  const isSelected = (value: string): boolean => {
    if (props.isMultiselectMode) {
      return multipleSelectedValuesSet.value.has(value);
    }
    return value === selectedId.value;
  };

  watch(() => props.selected, () => {
    setSingleSelected(getDefaultSelectedValue(props));
    setMultipleSelected(getDefaultMultipleSelectedValues(props));
  });

  return {
    selectedId,
    selectedText,
    selectedSecondaryText,
    selectedSecondaryBadge,
    selectedOption,
    multipleSelectedValues,
    setSingleSelected,
    toggleMultipleSelected,
    isSelected,
  };
}
