import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useSiteConfigStore } from '@core/site-config';

interface UseResetPersistLocationSettingsComposable {
  isResetLocationEnabled: Ref<boolean>;
}

export function useResetPersistLocationSettings(): UseResetPersistLocationSettingsComposable {
  const siteConfigStore = useSiteConfigStore();
  const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');

  const isResetLocationEnabled = computed(() => !!sportLineBlock.value?.familyFilterResetEnabled);

  return { isResetLocationEnabled };
}
