import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from '@core/analytics';

import { CasinoLoyaltyProgramLevelStatus } from 'web/src/modules/casino-loyalty-program/store/types';

import type { CasinoLoyaltyProgramCardEmits, CasinoLoyaltyProgramCardProps } from '../types';

export interface CasinoLoyaltyProgramCardComposable {
  isInactive: ComputedRef<boolean>;
  clickCard(): void;
}

export default function useCasinoLoyaltyProgramCard(
  props: CasinoLoyaltyProgramCardProps,
  emits: CasinoLoyaltyProgramCardEmits,
): CasinoLoyaltyProgramCardComposable {
  const analytics = useAnalytics();

  const isInactive = computed<boolean>(() => props.levelStatus !== CasinoLoyaltyProgramLevelStatus.SUCCESS);

  function clickCard(): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: {
        loyaltyProgram: {
          carouselLevelCard: props.title,
        },
      },
    });
    emits('click');
  }

  return {
    isInactive,
    clickCard,
  };
}
