import type {
  FormErrors,
  FormValidatorErrors,
} from 'web/src/components/Form/types';

function getErrorsMapForOutput (input: FormErrors): FormValidatorErrors | null {
  const errorsMap = new Map();
  for (const key of Object.keys(input)) {
    errorsMap.set(key, input[key]);
  }
  return errorsMap.size ? errorsMap : null;
}

export default getErrorsMapForOutput;
