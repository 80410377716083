<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

import type {
  VScrollbarEmits,
  VScrollbarProps,
  VScrollbarRef,
} from '../types';
import { useVScrollbar } from './composables';

defineProps<VScrollbarProps>();

const emit = defineEmits<VScrollbarEmits>();

const {
  element,
  scrollTop,
  scrollDown,
  scrollTo,
  scrollRight,
  scrollToElement,
  scrollUpdate,
  recompose,
} = useVScrollbar();

const Scrollbar = null || process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_ANDROID
  ? defineAsyncComponent(() => import('../VScrollbarPhone/VScrollbarPhone.vue'))
  : defineAsyncComponent(() => import('../VScrollbarDesktop/VScrollbarDesktop.vue'));

defineExpose<VScrollbarRef>({
  scrollTop,
  scrollDown,
  scrollTo,
  scrollRight,
  scrollToElement,
  scrollUpdate,
  recompose,
});
</script>

<template>
  <Scrollbar v-auto-id="'VScrollbar'"
    ref="element"
    :flex-fill="flexFill"
    :is-dropdown="isDropdown"
    :use-scroll-listener="useScrollListener"
    :test-el="testEl"
    :handle-resize-change="handleResizeChange"
    @reached-bottom="emit('reached-bottom')"
  >
    <slot />
  </Scrollbar>
</template>
