<script setup lang="ts">
import { ModalWidth } from '@components/dialogs';

import { BetCashOut } from 'web/src/components/BetCashOut/components';
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';

import type { CashoutModalEmits, CashoutModalProps } from '../types';
import { useCashoutModal } from '../composables';

const props = defineProps<CashoutModalProps>();

const emits = defineEmits<CashoutModalEmits>();

const {
  coreComponentProps,
  hasDesktopModal,
  isProfileLayout,
  betPrice,
  betName,
  mainButtonClick,
  cancelClick,
  closeModal,
} = useCashoutModal(emits, props);
</script>

<template>
  <LazyDefaultModal v-auto-id="'CashoutModal'"
    :is-alert="!hasDesktopModal && !!'1'"
    :is-inner-modal="hasDesktopModal && !!'1'"
    :is-centered="!!hasDesktopModal"
    :width="ModalWidth.SMALL"
    :is-profile-layout="isProfileLayout"
    data-test-id="cashout-modal"
    @close="closeModal"
  >
    <template #inner-content>
      <BetCashOut
        v-bind="coreComponentProps"
        :bet-name="betName"
        :bet-price="betPrice"
        @main-button-click="mainButtonClick"
        @cancel="cancelClick"
      />
    </template>
  </LazyDefaultModal>
</template>
