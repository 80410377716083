import type { CustomerNotificationMessage } from 'web/src/modules/customer-notifications/types';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';

export default function getMessageByIdAndSource(
  allMessages: CustomerNotificationMessage[] | undefined,
  { id, source }: { id: string | number; source: CustomerNotificationMessageSource },
): CustomerNotificationMessage | undefined {
  return allMessages?.find((item) => item.source === source && (
    source === CustomerNotificationMessageSource.ALCR || source === CustomerNotificationMessageSource.REF || source === CustomerNotificationMessageSource.TEXT
      ? item.id === id
      : item.id === Number(id)
  ));
}
