import { CustomerRegistrationType } from '@leon-hub/api-sdk';

import { RegistrationTypeMetrika } from '../enums';

export default function registrationTypeFormat(
  registrationType: Maybe<CustomerRegistrationType>,
): RegistrationTypeMetrika | '' {
  if (registrationType === CustomerRegistrationType.SIMPLE_PHONE
    || (!!process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED && registrationType === CustomerRegistrationType.SLOTT_PHONE)) {
    return RegistrationTypeMetrika.BY_PHONE;
  }
  if (registrationType === CustomerRegistrationType.SIMPLE_EMAIL) {
    return RegistrationTypeMetrika.BY_EMAIL;
  }

  return '';
}
