import { isArray } from '@leon-hub/guards';

type LastUpdateTimers<T extends string[]> = Record<T[number], number>;

type LastUpdateTimerGroups<T extends string[]> = Record<T[number], Record<string, number>>;

type SyncLastUpdateActions<T extends string[]> = Record<T[number], Function>;

export default class BackgroundUpdateStopwatch<T extends string[] = []> {
  private lastUpdate = {} as LastUpdateTimers<T>;

  private lastGroupUpdate = {} as LastUpdateTimerGroups<T>;

  constructor(private syncActions = {} as SyncLastUpdateActions<T>) {
    this.setLastUpdate((Object.keys(this.syncActions) as T), 0);
  }

  protected setLastUpdate(timers?: T | T[number], time: number = Date.now()): void {
    const keys: (keyof LastUpdateTimers<T>)[] = timers
      ? (isArray(timers) ? timers : [timers])
      : Object.keys(this.lastUpdate);

    for (const timer of keys) {
      this.lastUpdate[timer] = time;
    }
  }

  get syncActionsNames(): T {
    return Object.keys(this.lastUpdate) as T;
  }

  /**
   * Update last update
   * @param {T} timers
   */
  update(timers?: T | T[number]): void {
    this.setLastUpdate(timers, Date.now());
  }

  invalidate(timers?: T | T[number]): void {
    this.setLastUpdate(timers, 0);
  }

  updateGroup(timer: T[number], group: string | string[]): void {
    const lastUpdates: Record<string, number> = this.lastGroupUpdate[timer] || {};
    const groups = isArray(group) ? group : [group];
    const now = Date.now();

    for (const name of groups) {
      lastUpdates[name] = now;
    }

    this.lastGroupUpdate[timer] = lastUpdates;
  }

  async forceCallSyncAction<P = unknown>(timer: T[number], ...payload: P[]): Promise<void> {
    await this.syncActions[timer]?.(...payload);
  }

  async forceCallGroupSyncAction<P = unknown>(timer: T[number], group: string, ...payload: P[]): Promise<void> {
    await this.syncActions[timer]?.(...payload, group);
  }

  /**
   * Call sync action once after the interval
   * @param {T[number]} timer
   * @param {number} interval
   * @param payload
   * @return {boolean}
   */
  async callSyncAction<P = unknown>(timer: T[number], interval: number, ...payload: P[]): Promise<boolean> {
    if (this.isIntervalElapsed(timer, interval)) {
      await this.syncActions[timer]?.(...payload);
      return true;
    }

    return false;
  }

  async callGroupSyncAction<P = unknown>(
    timer: T[number],
    group: string,
    interval: number,
    ...payload: P[]
  ): Promise<boolean> {
    if (this.isGroupTimerMoreThen(timer, group, interval)) {
      await this.syncActions[timer]?.(...payload, group);
      return true;
    }

    return false;
  }

  /**
   * true if interval elapsed
   * @param {T[number]} timer
   * @param {number} interval
   * @return {boolean}
   */
  isIntervalElapsed(timer: T[number], interval: number): boolean {
    const now = Date.now();
    return now - this.lastUpdate[timer] > interval;
  }

  isGroupTimerMoreThen(timer: T[number], group: string, interval: number): boolean {
    const lastUpdate = this.lastGroupUpdate[timer][group];

    if (!lastUpdate) {
      return true;
    }

    const now = Date.now();
    return now - lastUpdate > interval;
  }
}
