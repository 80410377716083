import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  AlertIconName,
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import { getJumbotronProps } from 'web/src/components/Jumbotron';

import type { ConfirmEmailProps } from '../types';
import { ConfirmEmailStage } from '../../../enums';

interface UseConfirmEmail {
  isSuccess: ComputedRef<boolean>;
  jumbotronProps: ComputedRef<VJumbotronProps>;
  isErrorViewVisible: ComputedRef<boolean>;
  withSupportTranslation: ComputedRef<string>;
  isLoaderVisible: ComputedRef<boolean>;
  confirmButtonsVisible: ComputedRef<boolean>;
  closeButtonVisible: ComputedRef<boolean>;
  confirmButtonLabel: ComputedRef<string>;
}

export default function useConfirmEmail(props: ConfirmEmailProps): UseConfirmEmail {
  const { $translate } = useI18n();

  const isSuccess = computed<boolean>(() => props.stage === ConfirmEmailStage.CONFIRM_SUCCESS || props.stage === ConfirmEmailStage.WAIT_FOR_CONFIRMATION);

  const headingText = computed<string>(() => {
    switch (props.stage) {
      case ConfirmEmailStage.EMAIL_UNKNOWN:
      case ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED:
        return $translate('WEB2_CONFIRM_EMAIL_CAPTION_NOT_CONFIRMED').value;
      case ConfirmEmailStage.CONFIRM_SUCCESS:
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? $translate('WEB2_CONFIRM_SUBSCRIPTION_CAPTION_SUCCESS').value
          : $translate('WEB2_CONFIRM_EMAIL_CAPTION_SUCCESS').value;
      case ConfirmEmailStage.WAIT_FOR_CONFIRMATION:
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? $translate('RESTORE_PASSWORD_APPLICATION_SENT').value
          : $translate('WEB2_CONFIRM_EMAIL_CAPTION_CHECK').value;
      case ConfirmEmailStage.TOO_MANY_REQUESTS:
        return $translate('WEB2_CONFIRM_EMAIL_CAPTION_TOO_MANY_REQUESTS').value;
      case ConfirmEmailStage.TECH_ERROR:
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? $translate('WEB2_CONFIRM_SUBSCRIPTION_CAPTION_TECH_ERROR').value
          : $translate('WEB2_CONFIRM_EMAIL_CAPTION_TECH_ERROR').value;
      case ConfirmEmailStage.WRONG_TOKEN:
        return $translate('WEB2_CONFIRM_SUBSCRIPTION_CAPTION_WRONG_TOKEN').value;
      case ConfirmEmailStage.ALREADY_CONFIRMED:
        return $translate('WEB2_CONFIRM_EMAIL_CAPTION_WRONG_TOKEN').value;
      default:
        return '';
    }
  });

  const jumbotronProps = computed<VJumbotronProps>(() => {
    const properties: VJumbotronProps = {
      heading: headingText.value,
    };

    const iconProperties: VJumbotronProps = {
      iconSize: isSuccess.value ? IconSize.SIZE_80 : IconSize.SIZE_60,
      icon: isSuccess.value ? IconName.CHECK_OUTLINE : IconName.ATTENTION_OUTLINE,
    };

    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      properties.icon = undefined;
      let alertIcon = isSuccess.value ? AlertIconName.Success : AlertIconName.Email;

      switch (props.stage) {
        case ConfirmEmailStage.TOO_MANY_REQUESTS:
          alertIcon = AlertIconName.Alert;
          break;
        case ConfirmEmailStage.CONFIRM_SUCCESS:
          alertIcon = AlertIconName.ThumbsUp;
          break;
        case ConfirmEmailStage.TECH_ERROR:
          alertIcon = AlertIconName.Error;
          break;
        case ConfirmEmailStage.WRONG_TOKEN:
          alertIcon = AlertIconName.Fail;
          break;
        case ConfirmEmailStage.ALREADY_CONFIRMED:
          alertIcon = AlertIconName.Fail;
          break;
      }

      return {
        ...properties,
        ...getJumbotronProps({
          ...iconProperties,
          alertIcon,
        }),
      };
    }

    return { ...properties, ...iconProperties };
  });

  const isErrorViewVisible = computed<boolean>(() => {
    switch (props.stage) {
      case ConfirmEmailStage.TECH_ERROR:
      case ConfirmEmailStage.TOO_MANY_REQUESTS:
      case ConfirmEmailStage.WRONG_TOKEN:
      case ConfirmEmailStage.ALREADY_CONFIRMED:
        return true;
      default:
        return false;
    }
  });

  const withSupportTranslation = computed<string>(() => {
    switch (props.stage) {
      case ConfirmEmailStage.TOO_MANY_REQUESTS:
        return $translate('WEB2_CONFIRM_EMAIL_MANY_REQUESTS').value;
      case ConfirmEmailStage.TECH_ERROR:
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? $translate('WEB2_CONFIRM_SUBSCRIPTION_TECH_ERROR').value
          : $translate('WEB2_CONFIRM_EMAIL_TECH_ERROR').value;
      case ConfirmEmailStage.WRONG_TOKEN:
        return $translate('WEB2_CONFIRM_SUBSCRIPTION_WRONG_TOKEN').value;
      case ConfirmEmailStage.ALREADY_CONFIRMED:
        return $translate('WEB2_CONFIRM_EMAIL_WRONG_TOKEN').value;
      default:
        return '';
    }
  });

  const isLoaderVisible = computed<boolean>(() => props.stage === ConfirmEmailStage.PENDING_CHECK
    || (props.stage === ConfirmEmailStage.EMAIL_UNKNOWN && !props.isLoggedIn));

  const confirmButtonsVisible = computed<boolean>(() => {
    switch (props.stage) {
      case ConfirmEmailStage.TECH_ERROR:
      case ConfirmEmailStage.EMAIL_UNKNOWN:
      case ConfirmEmailStage.EMAIL_IS_NOT_CONFIRMED:
        return true;
      case ConfirmEmailStage.WRONG_TOKEN:
        return props.isLoggedIn ?? false;
      case ConfirmEmailStage.ALREADY_CONFIRMED:
        return !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED;
      default:
        return false;
    }
  });

  const closeButtonVisible = computed<boolean>(() => {
    switch (props.stage) {
      case ConfirmEmailStage.CONFIRM_SUCCESS:
      case ConfirmEmailStage.TOO_MANY_REQUESTS:
        return true;
      case ConfirmEmailStage.WRONG_TOKEN:
        return !props.isLoggedIn;
      case ConfirmEmailStage.ALREADY_CONFIRMED:
        return !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED;
      default:
        return false;
    }
  });

  const confirmButtonLabel = computed<string>(() => {
    const isRetryLabel = props.stage === ConfirmEmailStage.TECH_ERROR || props.stage === ConfirmEmailStage.WRONG_TOKEN;
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && (props.stage === ConfirmEmailStage.WRONG_TOKEN || props.stage === ConfirmEmailStage.ALREADY_CONFIRMED)) {
      return $translate('WEB2_CONFIRM_EMAIL_REPEATE_REQUEST').value;
    }
    return isRetryLabel ? $translate('WEB2_TRY_AGAIN').value : $translate('WEB2_MODAL_CONFIRM').value;
  });

  return {
    isSuccess,
    jumbotronProps,
    isErrorViewVisible,
    withSupportTranslation,
    isLoaderVisible,
    confirmButtonsVisible,
    closeButtonVisible,
    confirmButtonLabel,
  };
}
