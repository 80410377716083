import type { ComputedRef } from 'vue';
import {
  computed,
  onBeforeMount,
  onMounted,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import type { SearchSportlineContentEmits } from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/types';
import type { SearchSportEvent } from 'web/src/modules/sportline/types';
import { SearchType } from 'web/src/modules/search/submodules/sportline/enums';
import { useSearchSportlineStore } from 'web/src/modules/search/submodules/sportline/store';

interface SearchSportlineContentComposable {
  isNoResultsBlockVisible: ComputedRef<boolean>;
  noResultsText: ComputedRef<string>;
  isTagsVisible: ComputedRef<boolean>;
  searchTags: ComputedRef<readonly string[]>;
  onTagClick(value: string): void;
  isLoading: ComputedRef<boolean>;
  hasSearchResults: ComputedRef<boolean>;
  liveEvents: ComputedRef<SearchSportEvent[]>;
  prematchEvents: ComputedRef<SearchSportEvent[]>;
  searchText: ComputedRef<string>;
  onEventClick(event: SearchSportEvent): void;
  onInputClear(): void;
  onInput(value: string, searchType?: string): void;
}

export default function useSearchSportlineContent(
  emits: SearchSportlineContentEmits,
): SearchSportlineContentComposable {
  let isEventClicked = false;
  let isInputTouched = false;

  const { $translate } = useI18n();

  const searchSportlineStore = useSearchSportlineStore();
  const siteConfigStore = useSiteConfigStore();
  const router = useRouter();

  const liveEventsRef = toRef(searchSportlineStore, 'liveEvents');
  const prematchEventsRef = toRef(searchSportlineStore, 'prematchEvents');
  const isSearchInProgressRef = toRef(searchSportlineStore, 'isSearchInProgress');
  const searchTextRef = toRef(searchSportlineStore, 'searchText');
  const isNoSearchResultsRef = toRef(searchSportlineStore, 'isNoSearchResults');
  const isSearchAvailableRef = toRef(searchSportlineStore, 'isSearchAvailable');
  const popularSearchQueriesRef = toRef(siteConfigStore, 'popularSearchQueries');

  const liveEvents = computed<SearchSportEvent[]>(() => liveEventsRef.value ?? []);
  const prematchEvents = computed<SearchSportEvent[]>(() => prematchEventsRef.value ?? []);
  const isLoading = computed<boolean>(() => isSearchInProgressRef.value);
  const searchText = computed<string>(() => searchTextRef.value);
  const isNoSearchResults = computed<boolean>(() => isNoSearchResultsRef.value);
  const searchTags = computed<readonly string[]>(() => popularSearchQueriesRef.value);
  const isNoResultsBlockVisible = computed<boolean>(
    () => !isLoading.value && (!isSearchAvailableRef.value || isNoSearchResults.value),
  );
  const isTagsVisible = computed<boolean>(() => isNoResultsBlockVisible.value && searchTags.value.length > 0);
  const hasSearchResults = computed<boolean>(() => !isLoading.value && !isNoSearchResults.value);
  const noResultsText = computed<string>(
    () => (isSearchAvailableRef.value
      ? $translate('JAVA_SEARCH_NORESULTS').value
      : $translate('WEB2_SEARCH_BY').value
    ),
  );

  onMounted(() => emitSearchText(searchText.value));

  onBeforeMount(() => {
    if (!isEventClicked) {
      if (isInputTouched) {
        searchSportlineStore.sendMetrics(false);
      }

      searchSportlineStore.setSearchText('');
    }
  });

  function onTagClick(value: string): void {
    onInput(value, SearchType.POPULAR);
    emitFocus();
  }

  function onEventClick(event: SearchSportEvent): void {
    isEventClicked = true;
    emitItemClick();
    searchSportlineStore.sendMetrics(true);

    void router.push({
      name: RouteName.SPORT_EVENT_DETAILS,
      params: { ...event.navigationParameters },
    });
  }

  function onInput(value: string, searchType = SearchType.MANUAL): void {
    isInputTouched = true;
    emitSearchText(value);

    searchSportlineStore.setSearchType(searchType);
    searchSportlineStore.setSearchText(value);
  }

  function onInputClear(): void {
    isInputTouched = false;
    searchSportlineStore.sendMetrics(false);
    onInput('');
  }

  function emitFocus(): void {
    emits('focus');
  }

  function emitSearchText(value: string): void {
    emits('search-text', value);
  }

  function emitItemClick(): void {
    emits('item-click');
  }

  return {
    isNoResultsBlockVisible,
    noResultsText,
    isTagsVisible,
    searchTags,
    onTagClick,
    isLoading,
    hasSearchResults,
    liveEvents,
    prematchEvents,
    searchText,
    onEventClick,
    onInputClear,
    onInput,
  };
}
