import { computed, toRef } from 'vue';

import { useIsLoggedIn } from '@core/auth';

import type {
  CustomerFavoritesIdsBackgroundUpdateKey,
} from 'web/src/modules/sportline/submodules/favorites/enums';
import type {
  AddToCustomerLeaguesParameters,
} from 'web/src/modules/sportline/submodules/favorites/types';
import type {
  SportElement,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useCustomerLeaguesStore } from 'web/src/modules/sportline/submodules/favorites/store/leagues';
import { useCustomerStreamEventsStore } from 'web/src/modules/sportline/submodules/streams/store';
import {
  SportEventsResponseChangeUtils,
  SportlineFactory,
} from 'web/src/modules/sportline/utils/rest';

import type { UseCustomerFavoritesServiceComposable } from '../types';

/**
 * @TODO split to different composables
 */
export default function useCustomerFavoritesService(): UseCustomerFavoritesServiceComposable {
  const { isLoggedIn } = useIsLoggedIn();

  const customerLeaguesStore = useCustomerLeaguesStore();
  const customerLeaguesIds = toRef(customerLeaguesStore, 'customerLeaguesIds');
  const pendingToFavoriteLeaguesList = toRef(customerLeaguesStore, 'pendingToFavoriteLeaguesList');
  const customerOptimisticFavoriteLeaguesIds = toRef(customerLeaguesStore, 'optimisticCustomerLeaguesIds');
  const rawFavoriteLeaguesEventsResponse = toRef(customerLeaguesStore, 'rawFavoriteLeaguesEventsResponse');

  const favoritesEventsStore = useFavoritesEventsStore();
  const favoritesIdsList = toRef(favoritesEventsStore, 'favoritesIdsList');
  const rawFavoriteEventsResponse = toRef(favoritesEventsStore, 'rawFavoriteEventsResponse');
  const isFavoritesEventsListLoaded = toRef(favoritesEventsStore, 'isFavoritesEventsListLoaded');
  const customerOptimisticFavoriteSportEventsIds = toRef(favoritesEventsStore, 'optimisticFavoritesSportEventsIdsList');
  const { toggleCustomerSportEvent } = favoritesEventsStore;

  const customerStreamEventsStore = useCustomerStreamEventsStore();

  const isFavoritesIdsLoaded = computed(() => (
    favoritesIdsList.value !== null && customerLeaguesIds.value !== null
  ));
  const isFavoritesLoaded = computed(() => {
    const isFavoriteLeaguesEventsLoaded = !isLoggedIn.value
      || rawFavoriteLeaguesEventsResponse.value !== null;

    return isFavoritesEventsListLoaded.value
      && isFavoriteLeaguesEventsLoaded;
  });

  const fullCustomerSportEventsList = computed<Maybe<SportElement[]>>(
    () => {
      if (!isLoggedIn.value) {
        return null;
      }

      const normalizedFavoriteEventsResponse = SportEventsResponseChangeUtils
        // eslint-disable-next-line ts/prefer-nullish-coalescing
        .normalizeDuplicatesResponse(rawFavoriteEventsResponse.value || null);

      if (!normalizedFavoriteEventsResponse || !favoritesIdsList.value
        || !rawFavoriteLeaguesEventsResponse.value) {
        return null;
      }

      // map from response to avoid blinking before reload list but after add to state.customerLeaguesIds
      const customerLeaguesIdsMapping = [...new Set(rawFavoriteLeaguesEventsResponse.value.events
        .map((sportEvent) => String(sportEvent.league.id)))];
      const customerEventsNotInFavoritesLeagues: CoreSportEventResponse[] = [];

      for (const sportEvent of normalizedFavoriteEventsResponse.events) {
        const leagueId = String(sportEvent.league.id);
        const sportEventId = String(sportEvent.id);

        if (!customerLeaguesIdsMapping.includes(leagueId) && favoritesIdsList.value.includes(sportEventId)) {
          customerEventsNotInFavoritesLeagues.push(sportEvent);
        }
      }

      const { parseSportlineSettings } = useSportlineSettingsStore();
      const mixedResponse = {
        ...rawFavoriteLeaguesEventsResponse.value,
        events: [
          ...customerEventsNotInFavoritesLeagues,
          ...rawFavoriteLeaguesEventsResponse.value.events,
        ],
      };

      return (new SportlineFactory<SportlineEvent>(mixedResponse, parseSportlineSettings)).build();
    },
  );

  const hasFavoritesOptimistic = computed<boolean>(() => {
    const hasFavoritesEvents = customerOptimisticFavoriteSportEventsIds.value.length > 0;
    const hasFavoriteLeaguesEvents = customerOptimisticFavoriteLeaguesIds.value.length > 0;

    return hasFavoritesEvents || hasFavoriteLeaguesEvents;
  });

  function changeFavoriteLeagueState(data: AddToCustomerLeaguesParameters): Promise<void> {
    return customerLeaguesStore.toggleCustomerLeague(data);
  }

  async function initialRequests(): Promise<void> {
    await Promise.all([
      favoritesEventsStore.fetchFavoriteEventsIdsList(),
      customerLeaguesStore.initialRequests(),
      customerStreamEventsStore.initialRequests(),
    ]);
  }

  async function setIsBackgroundIdsUpdateAvailable(
    key: CustomerFavoritesIdsBackgroundUpdateKey,
    state: boolean,
  ): Promise<void> {
    await Promise.all([
      favoritesEventsStore.setIsBackgroundIdsUpdateAvailable({ [key]: state }),
      customerLeaguesStore.setIsBackgroundIdsUpdateAvailable({ [key]: state }),
      customerStreamEventsStore.setIsBackgroundIdsUpdateAvailable({ [key]: state }),
    ]);
  }

  async function setFavoriteEventsBackgroundUpdateEnabled(state: boolean): Promise<void> {
    await Promise.all([
      favoritesEventsStore.setIsEventsBackgroundUpdateAvailable(state),
      customerLeaguesStore.setIsEventsBackgroundUpdateAvailable(state),
    ]);
  }

  return {
    fullCustomerSportEventsList,
    pendingToFavoriteLeaguesList,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    hasFavoritesOptimistic,
    isFavoritesIdsLoaded,
    isFavoritesLoaded,
    initialRequests,
    changeFavoriteLeagueState,
    setIsBackgroundIdsUpdateAvailable,
    setFavoriteEventsBackgroundUpdateEnabled,
    toggleCustomerSportEvent,
  };
}
