import type { Ref } from 'vue';
import {
  computed,
  ref,
  shallowRef,
} from 'vue';

import {
  CustomerRegistrationType,
  getAvailableForms,
} from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';

import { useGraphqlClient } from '@core/app-rest-client';

import type { AvailableFormsDocument, RegistrationForm } from 'web/src/modules/registration/types';
import { isRegistrationForms } from 'web/src/modules/registration/guards';

export interface UseAvailableRegistrationComposable {
  isFormLoaded: Ref<boolean>;
  selectedForm: Ref<Maybe<RegistrationForm>>;
  regBannerUrl: Ref<string>;
  registrationType: Ref<CustomerRegistrationType>;
  isExcludedCountry: Ref<boolean>;
  availableForms: Ref<AvailableFormsDocument['forms']>;
  fetchForms(): Promise<void>;
  setSelectedRegistrationType(type: Maybe<CustomerRegistrationType>): void;
}

export function useAvailableRegistrationForms(): UseAvailableRegistrationComposable {
  const gqlClient = useGraphqlClient();

  const selectedRegistrationType = ref<Maybe<CustomerRegistrationType>>(null);
  const isFormLoaded = ref(false);
  const availableFormsResponse = shallowRef<Maybe<AvailableFormsDocument>>(null);

  async function fetchAvailableForms(): Promise<void> {
    const response = await getAvailableForms(gqlClient, (node) => node.queries.registration.getAvailableForms);

    if (response !== null) {
      const { forms } = response;

      assert(
        isRegistrationForms(forms),
        'SCG registration forms configured incorrectly',
      );
      forms.sort((a, b) => a.priority - b.priority);
      availableFormsResponse.value = response;
    }
  }

  function setSelectedRegistrationType(type: Maybe<CustomerRegistrationType>): void {
    selectedRegistrationType.value = type;
  }

  const availableForms = computed<AvailableFormsDocument['forms']>(() => availableFormsResponse.value?.forms ?? []);

  const selectedForm = computed<Maybe<RegistrationForm>>(() => {
    if (selectedRegistrationType.value === null) {
      return availableForms.value[0] ?? null;
    }
    return availableForms.value.find(
      (form) => form.registrationType === selectedRegistrationType.value,
    ) ?? null;
  });

  const registrationType = computed(() => selectedForm.value?.registrationType
    ?? CustomerRegistrationType.FULL);

  async function fetchForms(): Promise<void> {
    isFormLoaded.value = false;
    await fetchAvailableForms();
    if (!registrationType.value) {
      throw new Error('Available registration form is not found');
    }
    isFormLoaded.value = true;
  }

  const regBannerUrl = computed(() => availableFormsResponse.value?.regBannerUrl ?? '');

  const isExcludedCountry = computed(() => availableFormsResponse.value?.isExcludedCountry ?? false);

  return {
    isFormLoaded,
    selectedForm,
    regBannerUrl,
    registrationType,
    isExcludedCountry,
    fetchForms,
    availableForms,
    setSelectedRegistrationType,
  };
}
