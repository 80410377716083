<script lang="ts" setup>
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';

import type { MainBannerSectionRouteComponentProps } from './types';
import { useMainBannerSectionRouteComponent } from './composables';

const props = withDefaults(defineProps<MainBannerSectionRouteComponentProps>(), {
  customLocationId: '',
  aspectRatio: 1.75,
  bannerType: BannerType.MAIN_BANNER,
  bannerCategory: BannerCategory.DEFAULT,
  location: BannerLocation.TOP,
});

const {
  showBannerSection,
  bannerGroupComponent,
  currentGroup,
  isLoggedIn,
  disableClickAreas,
  onRouterLinkClick,
} = useMainBannerSectionRouteComponent(props);
</script>

<template>
  <div v-auto-id="'MainBannerSectionRouteComponent'"
    v-if="showBannerSection"
    v-data-test="{ el: 'banner-section' }"
    :class="{
      [$style['banner-section']]: true,
      [$style['banner-section__big-height']]: isBigHeight,
    }"
  >
    <div :class="$style[isStatic ? 'banner-section__column' : 'banner-section__wrapper']">
      <component
        :is="bannerGroupComponent"
        :banner-group="currentGroup"
        :is-logged-in="isLoggedIn"
        :banner-type="bannerType"
        :aspect-ratio="aspectRatio"
        :banner-category="bannerCategory"
        :is-big-height="isBigHeight"
        @banner-item-click="onRouterLinkClick"
      />
    </div>
    <div
      v-for="click in disableClickAreas"
      :key="click"
      :class="{
        [$style['banner-section__disable-click']]: true,
        [$style[`banner-section__disable-click--${click}`]]: true,
      }"
    />
  </div>
</template>

<style lang="scss" module>
$positions: (
  top: (
    top: 0,
    right: 0,
    left: 0,
    bottom: auto,
    width: 100%,
    height: 18px,
  ),
  bottom: (
    top: auto,
    right: 0,
    left: 0,
    bottom: 0,
    width: 100%,
    height: 18px,
  ),
);

.banner-section {
  position: relative;
  padding: 0 0 8px;

  &__big-height {
    padding: 0 0 24px;
  }

  &__disable-click {
    position: absolute;

    @each $position, $map in $positions {
      &--#{$position} {
        @include is-app-layout-phone {
          top: map-get($map, top);
          bottom: map-get($map, bottom);
          width: map-get($map, width);
          height: map-get($map, height);
        }
      }
    }
  }

  &__column {
    margin: 16px 0;
  }

  @include is-app-layout-phone {
    padding: 8px;
    overflow: hidden;
  }
}
</style>
