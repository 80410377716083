import type { MarkAchievementAsViewedResponse } from '@leon-hub/api-sdk';
import { doAchievementMarkAsViewed } from '@leon-hub/api-sdk';

import { useGraphqlClient } from '@core/app-rest-client';

export default function doAchievementMarkAsViewedRequest(id: number): Promise<MarkAchievementAsViewedResponse> {
  const apiClient = useGraphqlClient();

  return doAchievementMarkAsViewed(
    apiClient,
    (node) => node.mutations.achievements.markAsViewed,
    {
      options: {
        id,
      },
    },
  );
}
