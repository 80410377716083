<script lang="ts" setup>
import {
  nextTick,
  ref,
} from 'vue';

import { onComponentActivated } from '@leon-hub/vue-utils';

import { VBadge } from '@components/badge';
import { ObjectFitOption, VImage } from '@components/image';

import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';

import type {
  LobbyEgsGroupItemEmits,
  LobbyEgsGroupItemProps,
} from './types';
import { useLobbyEgsGroupItem } from './composables';

const props = withDefaults(defineProps<LobbyEgsGroupItemProps>(), {});
const emit = defineEmits<LobbyEgsGroupItemEmits>();

const {
  src,
  ntBadgeProperties,
  href,
} = useLobbyEgsGroupItem(props);

const wrapper = ref<HTMLElement>();
onComponentActivated(async () => {
  if (props.isActive) {
    if (!wrapper.value) {
      await nextTick();
    }

    if (wrapper.value) {
      emit('selected-loaded', wrapper.value);
    }
  }
});
</script>

<template>
  <component v-auto-id="'LobbyEgsGroupItem'"
    :is="href ? 'a' : 'div'"
    ref="wrapper"
    :class="{
      [$style['lobby-egs-group-item']]: true,
      [$style['lobby-egs-group-item--big']]: isBig,
      [$style['lobby-egs-group-item--active']]: isActive,
    }"
    :style="{
      background: item?.color,
    }"
    :href="href"
    @click.prevent="emit('click', $event)"
  >
    <div :class="$style['lobby-egs-group-item__wrapper']">
      <template v-if="item">
        <VImage
          v-if="src"
          :key="src"
          :alt="item.name"
          :src="src"
          is-lazy
          :object-fit="null ? ObjectFitOption.COVER : undefined"
          :class="$style['lobby-egs-group-item__logo']"
          is-prerender-optimized
        />
        <span
          v-else
          :class="$style['lobby-egs-group-item__text']"
        >
          {{ item.name }}
        </span>
        <VBadge
          v-if="item.ntBadge"
          v-bind="ntBadgeProperties"
          :class="$style['lobby-egs-group-item__nt-badge']"
        />
      </template>
      <VSkeletonImage v-else />
    </div>
  </component>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-egs-group-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: $lobbyEgsGroupHeightDefault;
    overflow: hidden;
    text-decoration: none;
    background-color: var(--Layer1);
    border-radius: if($isDesktop, $lobbyEgsGroupRadiusDesktop, $lobbyEgsGroupRadiusPhone);
    isolation: isolate;

    &__wrapper {
      position: absolute;
      top: $lobbyEgsGroupPadding;
      right: $lobbyEgsGroupPadding;
      bottom: $lobbyEgsGroupPadding;
      left: $lobbyEgsGroupPadding;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--active {
      border: 2px solid var(--BrandDefault);
    }

    &--big {
      @include screen-desktop-small-min {
        padding-top: $lobbyEgsGroupHeightBig;
      }
    }

    &__text {
      @include medium\14\16\025;

      max-width: 120px;
      color: var(--TextDefault);
      text-align: center;
    }

    &__logo {
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: var(--Highlight);
    }

    &__nt-badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
</style>
