import type { UIField } from '@leon-hub/api-sdk';
import { assert, isArray } from '@leon-hub/guards';

import type { FieldMappingKeys, UiFieldRawOptions } from '../types';
import decodeDefaultValue from './decodeDefaultValue';
import getUiFieldRawOptions from './getUiFieldRawOptions';
import isStringEntryArray from './isStringEntryArray';

/** should return base for FormUiSchemaFieldCommon */
export default function mapCommonFieldProperties(
  uiField: UIField,
  mappingKeys: FieldMappingKeys,
): UiFieldRawOptions {
  const result: Record<string, unknown> = {};
  for (const key of Object.keys(mappingKeys)) {
    const uiFieldKey = mappingKeys[key];
    if (uiFieldKey) {
      const value = uiField[uiFieldKey];
      const valueIsArray = isArray(value);
      if (!valueIsArray) {
        result[key] = value;
      } else if (value.length) {
        assert(isStringEntryArray(value), `is not StringEntry array: ${key}`);
        result[key] = getUiFieldRawOptions(value);
      }
    }
  }
  return uiField.encrypted ? decodeDefaultValue(result) : result;
}
