import { ref } from 'vue';

import type { ProgramDepositReward } from '@leon-hub/api-sdk';

import type { I18nFunctionReactive } from '@core/i18n';
import type { FormatMoneyFunc } from '@core/money';

import type { RewardList } from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/types';
import getRewardTitle from 'web/src/modules/referral-program/utils/referralProgramPopup/getRewardTitle';

export default function getPlayerReward(
  rewards: Maybe<ProgramDepositReward[]>,
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
  locale: string,
  currency?: string,
): RewardList[] {
  if (rewards) {
    let options = {};

    if (currency) {
      options = {
        currency,
      };
    }

    return rewards.map((reward) => {
      const depositKeys = ref({
        count: String(formatMoney(Number(reward.depositAmount) || 0, options)),
      });

      return reward.rewardAmount.referralReward.map((rew) => ({
        deposit: $t('WEB2_REFFERAL_PROGRAM_DEPOSIT_FROM', depositKeys).value,
        reward: getRewardTitle(rew, $t, locale),
      }));
    }).flat();
  }

  return [];
}
