import type { EventData } from 'vanilla-swipe';
import type { ComputedRef, Ref, UnwrapRef } from 'vue';
import VS, { Direction } from 'vanilla-swipe';
import {
  computed,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  onUpdated,
  provide,
  reactive,
  ref,
} from 'vue';

import { useWindowVisibilityChanged } from '@leon-hub/browser-composables';
import { Timer } from '@leon-hub/utils';

import type { FadeCarouselState } from 'web/src/components/FadeCarousel/types';
import type { FadeCarouselEmits, FadeCarouselProps } from 'web/src/components/FadeCarousel/VFadeCarousel/types';
import { PaginationType } from 'web/src/components/FadeCarousel/enums';

interface useVFadeCarouselComposable {
  holder: Ref<UnwrapRef<HTMLElement | null | undefined>>;
  onClick(): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
  carouselOptions: FadeCarouselState;
  isPointsPagination: ComputedRef<boolean>;
  moveToNextSlide(): void;
  moveToPreviousSlide(): void;
  moveToSlide(index: number): void;
}

export default function useVFadeCarousel(
  props: FadeCarouselProps,
  emits: FadeCarouselEmits,
): useVFadeCarouselComposable {
  const MIN_VELOCITY = 0.05;
  const IS_TOUCH_DEVICE = process.env.VUE_APP_RENDERING_CSR && ('ontouchstart' in document.documentElement);
  const holder = ref<HTMLElement | null | undefined>(null);
  const isPointsPagination = computed(() => (props.pagination === PaginationType.POINTS));

  let dragStartInfo: EventData | null = null;
  let dragCurrentInfo: EventData | null = null;
  let autoPlayInterval = 0;
  let swipeListener: VS | null = null;

  const carouselOptions: FadeCarouselState = reactive({
    activeSlideIndex: 0,
    slidesCount: 0,
    isDragInProgress: false,
  });

  provide('carouselOptions', carouselOptions);
  provide('moveToNextSlide', moveToNextSlide);
  provide('moveToPreviousSlide', moveToPreviousSlide);
  provide('moveToSlide', moveToSlide);

  function onActivate(): void {
    initSwipeListener();
    initAutoplay();
    initSlidesCount();
    emitMounted();
  }

  function onUpdate(): void {
    initSlidesCount();
  }

  function onDeactivate(): void {
    stopSwipeListener();
    stopAutoplay();
  }

  onMounted(() => {
    carouselOptions.activeSlideIndex = props.initSlideIndex ? props.initSlideIndex : 0;
    onActivate();
  });

  onUpdated(() => {
    onUpdate();
  });

  onActivated(() => {
    onActivate();
  });

  onBeforeUnmount(() => {
    onDeactivate();
  });

  onDeactivated(() => {
    onDeactivate();
  });

  function onWindowVisibilityChanged(isVisible: boolean): void {
    if (!isVisible) {
      stopAutoplay();
    } else {
      initAutoplay();
    }
  }

  function initAutoplay(): void {
    stopAutoplay();
    if (props.autoplayTimeout && props.autoplayTimeout > 0) {
      autoPlayInterval = Timer.setTimeout(() => {
        if (!carouselOptions.isDragInProgress && !dragStartInfo) {
          moveToNextSlide();
        }
        initAutoplay();
      }, props.autoplayTimeout);
    }
  }

  function initSlidesCount(): void {
    if (holder.value) {
      carouselOptions.slidesCount = Array.from(holder.value.children).reduce((accumulator, item) => {
        if (item.classList.contains('fade-carousel-slide')) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
    }
  }

  function stopAutoplay(): void {
    if (autoPlayInterval) {
      Timer.clearTimeout(autoPlayInterval);
      autoPlayInterval = 0;
    }
  }

  function moveToNextSlide(): void {
    if (carouselOptions.slidesCount <= carouselOptions.activeSlideIndex + 1) {
      carouselOptions.activeSlideIndex = 0;
    } else {
      carouselOptions.activeSlideIndex += 1;
    }

    emitSlideChanged();
  }

  function moveToPreviousSlide(): void {
    if (carouselOptions.activeSlideIndex <= 0) {
      carouselOptions.activeSlideIndex = carouselOptions.slidesCount - 1;
    } else {
      carouselOptions.activeSlideIndex -= 1;
    }
    emitSlideChanged();
  }

  function moveToSlide(index: number): void {
    if (
      carouselOptions.activeSlideIndex !== index
      && carouselOptions.activeSlideIndex >= 0
      && carouselOptions.activeSlideIndex < carouselOptions.slidesCount
    ) {
      initAutoplay();
      carouselOptions.activeSlideIndex = index;
      emitSlideChanged();
    }
  }

  function onMouseEnter(): void {
    stopAutoplay();
  }

  function onMouseLeave(): void {
    initAutoplay();
  }

  function dragStart(event: Event, data: EventData): void {
    if (!carouselOptions.isDragInProgress) {
      dragInit(event, data);
      stopAutoplay();
    }
  }

  function initSwipeListener(): void {
    if (holder.value) {
      swipeListener?.destroy();
      swipeListener = new VS({
        element: holder.value,
        onSwiping: dragMove,
        onSwiped: dragEnd,
        onSwipeStart: dragStart,
        mouseTrackingEnabled: true,
        preventDefaultTouchmoveEvent: false,
        preventTrackingOnMouseleave: true,
        delta: 10,
      });
      swipeListener.init();
    }
  }

  function stopSwipeListener(): void {
    if (swipeListener) {
      swipeListener.destroy();
      swipeListener = null;
    }
  }

  function dragInit(event: Event, data: EventData): void {
    dragStartInfo = data;
    dragCurrentInfo = dragStartInfo;
  }

  function dragMove(event: Event, data: EventData): void {
    if (dragStartInfo) {
      dragCurrentInfo = data;

      if (carouselOptions.isDragInProgress) {
        if (event.cancelable) {
          event.preventDefault();
        }
      } else {
        carouselOptions.isDragInProgress = true;
        dragInit(event, data);
      }
    }
  }

  function dragEnd(): void {
    dragStartInfo = null;
    if (carouselOptions.isDragInProgress) {
      dragChangeSlide();
      initAutoplay();

      if (IS_TOUCH_DEVICE) {
        carouselOptions.isDragInProgress = false;
      }
    }
  }

  function onClick(): void {
    carouselOptions.isDragInProgress = false;
  }

  function dragChangeSlide(): boolean {
    if (dragCurrentInfo) {
      const { velocity } = dragCurrentInfo;

      if (velocity > 0 && velocity > MIN_VELOCITY) {
        if (dragCurrentInfo.directionX === Direction.LEFT) {
          moveToNextSlide();
        } else if (dragCurrentInfo.directionX === Direction.RIGHT) {
          moveToPreviousSlide();
        }

        return true;
      }
    }

    return false;
  }

  useWindowVisibilityChanged(onWindowVisibilityChanged);

  function emitSlideChanged(): void {
    emits('slide-changed', carouselOptions.activeSlideIndex);
  }

  function emitMounted(): void {
    emits('mounted', carouselOptions.activeSlideIndex);
  }

  return {
    holder,
    onClick,
    onMouseEnter,
    onMouseLeave,
    carouselOptions,
    isPointsPagination,
    moveToNextSlide,
    moveToPreviousSlide,
    moveToSlide,
  };
}
