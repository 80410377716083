<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';

import { InputBorderWrapper, InputWrapper } from 'web/src/components/Input';

import type { SelectWithSearchProps } from '../types';
import { useSingleSelect } from '../composables';
import LayoutRelatedSearchSelect from './LayoutRelatedSearchSelect';

const props = withDefaults(defineProps<SelectWithSearchProps>(), {
  name: 'SelectWithSearch',
  options: () => [],
  selectedValue: '',
  dropdownMinWidth: 0,
});

const emits = defineEmits<BaseInputEmits>();

const {
  mouseover,
  mouseleave,
  onFocus,
  onBlur,
  onChange,
  borderWrapperProps,
  wrapperInputProps,
  selectProperties,
} = useSingleSelect(props, emits);
</script>

<template>
  <InputWrapper v-auto-id="'SelectWithSearch'"
    v-data-test="{
      el: 'select-with-search',
      name,
    }"
    v-bind="wrapperInputProps"
  >
    <template #input>
      <InputBorderWrapper
        v-bind="borderWrapperProps"
      >
        <LayoutRelatedSearchSelect
          v-bind="selectProperties"
          :modal-view-header="'1' ? undefined : modalViewHeader"
          :search-label="'1' ? undefined : searchLabel"
          enable-search
          @change="onChange"
          @focus="onFocus"
          @blur="onBlur"
          @mouseover="mouseover"
          @mouseleave="mouseleave"
        />
      </InputBorderWrapper>
    </template>
  </InputWrapper>
</template>
