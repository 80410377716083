<script lang="ts" setup>
import { toRef } from 'vue';

import type { CustomerConfigOddsType } from '@core/customer';

import { VListItemAccordion } from '@components/list';

import type { OddsSelectorProps } from 'web/src/modules/sportline/submodules/odds/views/types';
import OddsList from 'web/src/modules/sportline/submodules/odds/components/OddsList/OddsList.vue';
import useOddsStore from 'web/src/modules/sportline/submodules/odds/store/useOddsStore';

defineProps<OddsSelectorProps>();

const oddStore = useOddsStore();
const oddsList = toRef(oddStore, 'oddsList');
const currentOdd = toRef(oddStore, 'userOddTypeLocal');

function selectOdd(oddType: CustomerConfigOddsType): void {
  void oddStore.setCustomerOdd(oddType);
}
</script>

<template>
  <VListItemAccordion v-auto-id="'OddsSelectorItem'"
    id="1"
    :title="$t('WEB2_ODDS')"
    :icon-name="iconName"
    :no-background="!hasBackground"
  >
    <template #content>
      <OddsList
        :odd="currentOdd"
        :odds="oddsList"
        @click="selectOdd($event)"
      />
    </template>
  </VListItemAccordion>
</template>
