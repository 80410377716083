<script lang="ts" setup>
import { ref } from 'vue';

import type {
  LTabsButtonEmits,
  LTabsButtonProps,
  LTabsButtonRef,
  LTabsButtonSlots,
} from './types';
import { useBaseTabsButton } from '../BaseTabsButton/composables';
import { TabsType } from '../VTabs/enums';

const props = withDefaults(defineProps<LTabsButtonProps>(), {
  version: 'default',
  type: TabsType.NORMAL,
});

const emit = defineEmits<LTabsButtonEmits>();

defineSlots<LTabsButtonSlots>();

const element = ref<HTMLElement>();
const {
  isDisabled,
  tag,
  htmlTypeAttr,
  role,
} = useBaseTabsButton(props);

function emitClick(): void {
  emit('click', props.id);
}

function click(): void {
  if (props.disabled) {
    return;
  }
  emitClick();
}

function getOffsetLeft(): number {
  return element.value?.offsetLeft || 0;
}

defineExpose<LTabsButtonRef>({
  getOffsetLeft,
});
</script>

<template>
  <component v-auto-id="'TabsButton'"
    :is="tag"
    ref="element"
    :disabled="isDisabled"
    :role="role"
    :class="{
      [$style['tabs-button']]: true,
      [$style[`tabs-button-type--${type}`]]: type !== TabsType.NORMAL,
      [$style['tabs-button--flexible']]: flexible,
      [$style['tabs-button--active']]: active,
      [$style['tabs-button--is-bordered']]: isBordered,
      [$style['tabs-button--is-rounded-frame']]: isRoundedFrame,
      [$style['tabs-button--semi-rounded']]: null && version === 'bet-swipe',
      [$style['tabs-button--single']]: single,
    }"
    :href="href"
    :type="htmlTypeAttr"
    @click.prevent="click"
  >
    <slot />
  </component>
</template>

<style module lang="scss">
.tabs-button {
  $self: &;

  @include medium\14\16\025;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: if($isEnvFeatureTwinlikeStyle, 40px, 44px);
  padding: 0 16px;
  color: var(--TextSecondary);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;

  @if $isEnvFeatureTwinlikeStyle {
    &--is-bordered {
      padding: 0 16px;
      &#{$self}--active {
        background-color: transparent;

        &:after {
          right: 8px;
          left: 8px;
          display: block;
        }
      }

      &.tabs-button-type--small {
        padding: 0 8px;

        &#{$self}--active {
          color: var(--TextDefault);
        }
      }
    }
  }

  @include for-hover {
    &:hover:not(:disabled, #{$self}--single) {
      color: var(--TextDefault);

      & .tabs__icon {
        color: var(--TextDefault);
      }
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.65;
  }

  &--flexible {
    width: 100%;
  }

  &--active {
    color: var(--TextDefault);

    @if $isEnvFeatureTwinlikeStyle {
      background-color: var(--Layer2);
      border-radius: 20px;

      &#{$self}--semi-rounded {
        border-radius: 15px;
      }

      &.tabs-button--is-bordered {
        background-color: transparent;

        &:after {
          display: block;
        }
      }
    }

    &:after {
      position: absolute;
      right: 16px;
      bottom: 0;
      left: 16px;
      display: if($isEnvFeatureTwinlikeStyle, none, block);
      height: 2px;
      content: '';
      background-color: var(--BrandDefault);
      border-radius: 2px 2px 0 0;
    }

    & .tabs__icon {
      color: var(--TextDefault);
    }
  }

  &--single {
    color: var(--TextSecondary);
    cursor: default;
  }

  &-type {
    &--small {
      @include regular\12\12;

      height: 32px;
      padding: 0 8px;

      &#{$self}--active:after {
        right: 8px;
        left: 8px;
      }
    }

    &--uppercase {
      @include medium\12\16\04\caps;

      padding: if($isEnvFeatureTwinlikeStyle, 0 16px, 0 8px);

      &#{$self}--active:after {
        right: 8px;
        left: 8px;
      }
    }

    &--large {
      @include medium\12\16\04\caps;

      height: 56px;
      padding: 0 12px;
      color: var(--TextPrimary);

      &:hover {
        background-color: var(--Highlight);
      }

      &#{$self}--active {
        color: var(--TextDefault);
        background-color: var(--Layer2);

        &:after {
          display: none;
        }
      }
    }

    &--promo {
      color: var(--BrandDefault);

      @include for-hover {
        &:hover:not(:disabled, #{$self}--single) {
          color: var(--BrandHighlight);
        }
      }

      &#{$self}--active {
        color: var(--BrandHighlight);
      }
    }
  }

  &--is-rounded-frame {
    display: flex;
    align-content: center;
    height: 44px;
    padding: 0 12px;
    border: 2px solid var(--OpacityBrandDefault);
    border-radius: 22px;

    &#{$self}--active {
      border: 2px solid var(--BrandDefault);

      &:after {
        display: none;
      }
    }
  }

  @include is-app-layout-desktop {
    @if $isEnvFeatureTwinlikeStyle {
      &:hover:not(:disabled, #{$self}--is-bordered) {
        background-color: var(--Highlight);
        border-radius: 20px;

        &#{$self}--semi-rounded {
          border-radius: 15px;
        }
      }
    }
  }
}
</style>
