import type {
  CoreSportlineFetchOptions,
  GetRegionResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportlineApiService } from 'web/src/modules/sportline/types/services';
import { getRegion } from 'web/src/modules/sportline/utils/api';

import type { UseSportlineApiLoadTreeEntitiesProps } from './types';
import type { ApiPending, ApiPendingParams } from './useSportlineApiPending';
import useSportlineApiPending from './useSportlineApiPending';

export function useSportlineApiLoadRegion(
  props: UseSportlineApiLoadTreeEntitiesProps,
): Pick<SportlineApiService, 'loadRegion'> {
  const {
    tryRequestWithCTag,
    sportsRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const sportsApiPendings = useSportlineApiPending<GetRegionResponse>();

  function loadRegion({ id, silent }: CoreSportlineFetchOptions & { id: string }): Promise<GetRegionResponse> {
    const pendingFilter: ApiPendingParams = { regionId: id, betlines: [] };
    const apiPendingIndex = sportsApiPendings.getApiPendingIndexForFilter(pendingFilter);

    if (apiPendingIndex > -1) {
      return sportsApiPendings.getApiPendingByIndex(apiPendingIndex).request;
    }

    const request = new Promise<GetRegionResponse>((resolve, reject) => {
      tryRequestWithCTag((ctag) => getRegion({
        ctag,
        regionId: id,
        silent,
        flags: [...sportsRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
      })).then((result) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        resolve(result);
        return result;
      }).catch((error) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        reject(error);
      });
    });
    const apiPending: ApiPending<GetRegionResponse> = {
      ...pendingFilter,
      request,
    };

    sportsApiPendings.addApiPending(apiPending);

    return apiPending.request;
  }

  return { loadRegion };
}
