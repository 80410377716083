<script setup lang="ts">
import { DateTime } from '@leon-hub/utils';

import type { NativeDateInputEmits, NativeDateInputProps } from './types';
import { DateInputRoundDateTo, DateInputType } from '../../enums';
import useNativeDateInput from './useNativeDateInput';

const props = withDefaults(defineProps<NativeDateInputProps>(), {
  roundTo: DateInputRoundDateTo.None,
  value: () => DateTime.now().toTimestamp(),
  name: 'date',
  type: DateInputType.Date,
  tabIndex: '-1',
  focused: '',
});

const emits = defineEmits<NativeDateInputEmits>();

const {
  uniqueId,
  systemInputMax,
  systemInputMin,
  systemInputValue,
  displayingValue,
  onBlur,
  onInput,
  onFocus,
  onChange,
} = useNativeDateInput(props, emits);
</script>

<template>
  <div v-auto-id="'NativeDateInput'"
    :class="{
      [$style['date-picker']]: true,
      [$style[`date-picker--focused-${focused}`]]: focused,
    }"
  >
    <input
      :id="uniqueId"
      :key="name"
      :disabled="disabled"
      :name="name"
      :tabindex="tabIndex"
      :type="type"
      :value="systemInputValue"
      :min="systemInputMin"
      :max="systemInputMax"
      :class="$style['date-picker__input']"
      placeholder="&nbsp;"
      @change="onChange"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    >
    <label
      :class="$style['date-picker__label']"
      :for="uniqueId"
    >
      {{ displayingValue }}
    </label>
  </div>
</template>

<style lang="scss" module>
.date-picker {
  position: relative;
  overflow: hidden;

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      color: transparent;
      cursor: pointer;
      background: transparent;
    }
  }

  &__label {
    @include nativeDateInputFont;

    color: $nativeDateInputFontColor;
  }

  &--from.date-picker--focused-to,
  &--to.date-picker--focused-from {
    .date-picker__label {
      color: $nativeDateInputFocusedFontColor;
    }
  }
}
</style>
