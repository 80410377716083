import type { RawEntryInput } from '@leon-hub/api-sdk';

import { PaymentsCardField } from 'web/src/modules/payments/enums';
import { isPaymentsCardField } from 'web/src/modules/payments/guards/isPaymentsCardField';

export default function isTokenizedCardField(item: RawEntryInput): boolean {
  const key = isPaymentsCardField(item.key) ? item.key : null;
  return !!key && [
    PaymentsCardField.CARD,
    PaymentsCardField.CARD_NUMBER,
  ].includes(key);
}
