import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { InputEventType } from '@leon-hub/input-types';

import type { StakeInputEmits, StakeInputProps } from './types';
import { handleNumberInput, handleNumberPaste } from '../../composables/utils';

interface UseStakeInput {
  onFocus(): void;
  onBlur(): void;
  onInput(event: InputEvent): void;
  onPaste(event: ClipboardEvent): void;
  onClear(): void;
  onEnterKeydown(): void;
  inputElement: Ref<undefined | HTMLInputElement>;
  inputValue: Ref<string>;
  isCurrencySymbolVisible: ComputedRef<boolean>;
  isClearButtonVisible: ComputedRef<boolean>;
}

export default function useStakeInput(
  props: StakeInputProps,
  emit: StakeInputEmits,
): UseStakeInput {
  const inputValue = ref<string>(props.value || '');

  const inputElement = ref<undefined | HTMLInputElement>();

  watch(() => props.value, (value) => {
    inputValue.value = value || '';
  });

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    watch(() => props.isFocused, (value) => {
      if (value) {
        inputElement.value?.focus();
      }
    }, { immediate: true });
  }

  const onFocus = (): void => {
    emit(InputEventType.FOCUS);
  };

  const onBlur = (): void => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      emit(InputEventType.BLUR, inputValue.value);
    }
  };

  const emitInput = (): void => {
    emit(InputEventType.INPUT, inputValue.value);
  };

  const onClear = (): void => {
    inputValue.value = '';
    emitInput();
  };

  const onInput = (event: InputEvent): void => {
    inputValue.value = handleNumberInput(event, inputValue.value, props.maximumFractionDigits);
    emitInput();
  };

  const onPaste = (event: ClipboardEvent): void => {
    event.preventDefault();
    inputValue.value = handleNumberPaste(
      event,
      inputValue.value,
      props.maximumFractionDigits,
    );
    emitInput();
  };

  const onEnterKeydown = (): void => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      if (props.isFocused) {
        inputElement.value?.blur();
      }
    }
  };

  const isClearButtonVisible = computed<boolean>(() => !!props.isFocused && !!inputValue.value);

  const isCurrencySymbolVisible = computed<boolean>(() => !inputValue.value || !props.isFocused);

  return {
    onFocus,
    onBlur,
    onClear,
    onPaste,
    onInput,
    onEnterKeydown,
    inputElement,
    isClearButtonVisible,
    isCurrencySymbolVisible,
    inputValue,
  };
}
