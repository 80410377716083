import { useLocalStorageManager } from '@leon-hub/local-storage';
import { Timer } from '@leon-hub/utils';

export default class ResendSmsStopwatch {
  protected currentTickTime = 0;

  protected timeOutId: Maybe<number> = null;

  constructor(
    protected syncStore: (time: number) => Promise<void> | void,
    protected localStorageTimeOutKey: string,
  ) {}

  saveCurrentTimeToLocalStorage(): void {
    const localStorageManager = useLocalStorageManager();
    const time = this.currentTickTime;
    if (time > 0) {
      localStorageManager.setItem(this.localStorageTimeOutKey, `${Date.now() + time * 1000}`);
    } else {
      localStorageManager.removeItem(this.localStorageTimeOutKey);
    }
  }

  getCurrentTimeFromLocalStorage(): number {
    const expiredDate = Number(useLocalStorageManager().getItem(this.localStorageTimeOutKey));
    return expiredDate
      ? Math.floor(Math.max(expiredDate - Date.now(), 0) / 1000)
      : 0;
  }

  async run(time: number): Promise<void> {
    if (this.currentTickTime > 0 && time > this.currentTickTime) { return; }

    await this.stop();
    await this.nextTick(time);
    this.saveCurrentTimeToLocalStorage();
  }

  async stop(): Promise<void> {
    if (!this.timeOutId) { return; }

    Timer.clearTimeout(this.timeOutId);
    await this.syncCurrentTickTimeout(0);
  }

  async pause(): Promise<void> {
    if (!this.currentTickTime) { return; }

    // save to local storage
    this.saveCurrentTimeToLocalStorage();
    // then drop current value
    await this.stop();
  }

  clear(): void {
    // drop timer to 0
    void this.stop();
    // remove from local storage
    this.saveCurrentTimeToLocalStorage();
  }

  protected async syncCurrentTickTimeout(time: number): Promise<void> {
    this.currentTickTime = time;
    await this.syncStore(time);
  }

  protected async nextTick(time: number): Promise<void> {
    const actualTime = Math.max(time, 0);
    await this.syncCurrentTickTimeout(actualTime);

    if (actualTime <= 0) {
      void this.stop();
      return;
    }

    if (this.timeOutId) {
      Timer.clearTimeout(this.timeOutId);
    }

    this.timeOutId = Timer.setTimeout(() => {
      this.timeOutId = null;
      void this.nextTick(actualTime - 1);
    }, 1000);
  }
}
