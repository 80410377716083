<script lang="ts" setup>
import type {
  CasinoSearchContentEmits,
  CasinoSearchContentProps,
} from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/types';
import type { SearchResultContentRef } from 'web/src/modules/search/submodules/types';
import { VLoaderDelayed } from 'web/src/components/Loader';
import SearchNoResultsPlaceholder
  from 'web/src/modules/search/components/SearchNoResultsPlaceholder/SearchNoResultsPlaceholder.vue';
import CasinoSearchSection
  from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/CasinoSearchSection.vue';
import { useCasinoSearchContent } from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/composables';
import CasinoSearchLobby from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/CasinoSearchLobby.vue';

const props = defineProps<CasinoSearchContentProps>();
const emits = defineEmits<CasinoSearchContentEmits>();

const {
  isNoSearchResults,
  noResultsText,
  isLoading,
  hasSearchResults,
  searchText,
  games,
  groups,
  isMoreLoadingAvailable,
  replaceGameRoute,
  onGameClick,
  onLoadMore,
  onLobbyGameClicked,
  onInputClear,
  onInput,
} = useCasinoSearchContent(props, emits);

defineExpose<SearchResultContentRef>({
  onInputClear,
  onInput,
});
</script>

<template>
  <div v-auto-id="'CasinoSearchContent'">
    <SearchNoResultsPlaceholder
      v-if="isNoSearchResults"
      :text="noResultsText"
    />
    <div
      v-if="isLoading"
      :class="$style['search__loader']"
    >
      <VLoaderDelayed />
    </div>
    <CasinoSearchSection
      v-if="hasSearchResults"
      :search-text="searchText"
      :games="games"
      :groups="groups"
      :is-load-more-available="isMoreLoadingAvailable"
      @item-click="onGameClick"
      @load-more="onLoadMore"
    />
    <CasinoSearchLobby
      v-show="isNoSearchResults"
      :should-replace-game-routes="replaceGameRoute"
      @game-click="onLobbyGameClicked"
    />
  </div>
</template>

<style lang="scss" module>
.search {
  &__loader {
    margin-top: 36px;
  }
}
</style>
