import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { FormControlType, FormErrorKeyword } from '@leon-hub/form-utils';

import { useI18n } from '@core/i18n';

import type { FormValidatorByPattern } from '../types/common';

interface UseFormPhoneErrorPatterns {
  phoneErrorPatterns: ComputedRef<FormValidatorByPattern>;
}

/**
 * provides similar error text for incorrect phone from LEONWEB-9138
 */
export default function useFormPhoneErrorPatterns(): UseFormPhoneErrorPatterns {
  const { $translate } = useI18n();

  const phoneValidationWidgetError = computed<Record<string, string>>(() => ({
    [FormErrorKeyword.Pattern]: $translate('WEB2_PHONE_INCORRECT').value,
  }));

  const phoneErrorPatterns = computed<FormValidatorByPattern>(() => ({
    [FormControlType.Phone]: phoneValidationWidgetError.value,
    [FormControlType.PhoneInput]: phoneValidationWidgetError.value,
    [FormControlType.PhoneCountriesSelectorInput]: phoneValidationWidgetError.value,
  }));

  return {
    phoneErrorPatterns,
  };
}
