import type { Ref } from 'vue';
import { ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type { Web2FeedbackRequest } from '@leon-hub/api-sdk';
import { GqlApiBatchedSubRequestError } from '@leon-hub/api';

import { useCustomerDataStore } from '@core/customer';
import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import { ModalWidth } from '@components/dialogs';

import type { VFeedbackCompositionEmitFormData }
  from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';
import { FeedbackApiErrorCode } from 'web/src/modules/core/enums';
import useUserFeedbackResponse from 'web/src/modules/feedback/composables/useUserFeedbackResponse';

import type { SupportFeedbackLegacyRoutePageEmits } from '../types';

export interface SupportFeedbackLegacyRoutePageComposable {
  feedbackFormMaxChars: Ref<number>;
  haveValidEmailValue: Ref<boolean>;
  submitting: Ref<boolean>;
  isClearTextarea: Ref<boolean>;
  isApiErrorEmailMessage: Ref<string>;
  savedErrorDuplicateEmail: Ref<string>;
  submit(data: VFeedbackCompositionEmitFormData): Promise<void>;
  closeSuccessDialog(): void;
}
export default function useSupportFeedbackLegacyRoutePage(
  emit: SupportFeedbackLegacyRoutePageEmits,
): SupportFeedbackLegacyRoutePageComposable {
  const siteConfigStore = useSiteConfigStore();
  const customerDataStore = useCustomerDataStore();
  const { showDialog, closeDialog } = useDialogs();
  const { $translate } = useI18n();
  const router = useRouter();
  const { sendUserResponse } = useUserFeedbackResponse();

  const feedbackFormMaxChars = toRef(siteConfigStore, 'feedbackFormMaxChars');
  const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
  const emailValue = toRef(customerDataStore, 'email');

  const successModalId = ref<string>('');
  const submitting = ref<boolean>(false);
  const isClearTextarea = ref<boolean>(false);
  const isApiErrorEmailMessage = ref<string>('');
  const savedErrorDuplicateEmail = ref<string>('');

  function emitClose(): void {
    emit('close');
  }

  async function submit(data: VFeedbackCompositionEmitFormData): Promise<void> {
    submitting.value = true;
    isApiErrorEmailMessage.value = '';
    savedErrorDuplicateEmail.value = '';

    try {
      await handleSubmit(data);
      isClearTextarea.value = true;
      await handleSuccessResponse();
    } catch (error) {
      handleErrorResponse(error, data.email);
    }

    submitting.value = false;
  }

  async function handleSubmit(data: VFeedbackCompositionEmitFormData): Promise<boolean | unknown> {
    try {
      const payload: Mutable<Web2FeedbackRequest> = { body: data.comment };
      const userEmail = haveValidEmailValue.value ? emailValue.value : '';

      payload.email = userEmail || data.email;

      await sendUserResponse(payload);

      return true;
    } catch (error) {
      return error;
    }
  }

  async function handleSuccessResponse(): Promise<void> {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      const { subscribe, id } = showDialog({
        presetName: PresetName.ALERT_SUCCESS,
        options: {
          confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
          title: $translate('WEB2_FEEDBACK_SENT').value,
          width: ModalWidth.MEDIUM,
          fullHeight: true,
          dataTestId: 'feedback-sent',
          isCloseAllowed: !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED,
        },
      });
      successModalId.value = id;

      subscribe({
        [DialogAction.MODAL_CLOSE]: () => {
          isClearTextarea.value = false;
          if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
            router.back();
          }
        },
      });
    } else if (process.env.VUE_APP_LAYOUT_PHONE) {
      await showDialog({
        presetName: PresetName.ALERT_SUCCESS,
        options: {
          confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
          title: $translate('WEB2_FEEDBACK_SENT').value,
          dataTestId: 'feedback-sent-mobile',
        },
      }).promise;

      emitClose();
      router.back();
      isClearTextarea.value = false;
    }
  }
  function handleErrorResponse(error: unknown, email: string): void {
    if (error instanceof GqlApiBatchedSubRequestError
      && error.extensions.errorCode === FeedbackApiErrorCode.EMAIL_ALREADY_EXISTS) {
      isApiErrorEmailMessage.value = error.message;
      savedErrorDuplicateEmail.value = email;
      submitting.value = false;
      return;
    }
    const { subscribe, id } = showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        title: $translate('JS_CAPTION_ATTENTION').value,
        confirmMessage: $translate('WEB2_USER_FEEDBACK_IS_NOT_SENT').value,
        width: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalWidth.MEDIUM : ModalWidth.DEFAULT,
        dataTestId: 'feedback-is-not-sent',
      },
    });

    subscribe({
      [DialogAction.MODAL_CLOSE]: () => {
        closeDialog(id);
      },
    });
  }

  function closeSuccessDialog(): void {
    if (successModalId.value) {
      closeDialog(successModalId.value);
      isClearTextarea.value = false;
    }
  }

  return {
    feedbackFormMaxChars,
    haveValidEmailValue,
    submitting,
    isClearTextarea,
    isApiErrorEmailMessage,
    savedErrorDuplicateEmail,
    submit,
    closeSuccessDialog,
  };
}
