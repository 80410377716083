import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyBannersItem,
  LobbyItemSdk,
} from 'web/src/modules/lobby/types';

export default function isLobbyBannersItem(input: LobbyItemSdk): input is LobbyBannersItem {
  return input.typeId === LobbyItemType.BANNERS;
}
