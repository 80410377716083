<script lang="ts" setup>
import type { Ref } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
  provide,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { useRouterStore } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VCmsContentSeo from 'web/src/modules/cms/components/VCmsContentSeo/VCmsContentSeo.vue';
import { getCmsKeys, getCmsParams } from 'web/src/modules/cms/utils';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';

import type { FooterSeoSlots } from './types';

interface CmsData {
  keys: string[];
  params?: Record<string, string>;
  cacheKey?: string;
}

defineSlots<FooterSeoSlots>();

const route = useRoute();

const { seoConfigs } = storeToRefs(useRouterStore());
const { metaParameters } = storeToRefs(useSeoMetaStorage());
const { isSeoBetCmsSpintaxEnabled: isSpintaxCms } = storeToRefs(useSiteConfigStore());

const cmsData: Ref<CmsData> = ref({ keys: [] });

watch(() => route, (newRoute: RouteLocationNormalizedLoaded) => {
  const newCmsData: CmsData = {
    keys: getCmsKeys(newRoute.name, newRoute.params, seoConfigs.value),
  };

  if (isSpintaxCms.value) {
    newCmsData.params = getCmsParams(newRoute.name, newRoute.params, seoConfigs.value);
    newCmsData.cacheKey = Date.now().toString();
  }

  cmsData.value = newCmsData;
}, { deep: true, immediate: true });

provide('getMetaData', () => metaParameters.value || {});
</script>

<template>
  <VCmsContentSeo
    v-if="null && cmsData.keys.length"
    :cms-data="cmsData"
    :spintax-cms="isSpintaxCms"
    silent
  >
    <template
      #empty
    >
      <slot name="empty" />
    </template>
  </VCmsContentSeo>
  <VCmsContent
    v-else-if="cmsData.keys.length"
    :cms-data="cmsData"
    :spintax-cms="isSpintaxCms"
    silent
  >
    <template #empty>
      <slot name="empty" />
    </template>
  </VCmsContent>
</template>
