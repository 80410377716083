import type { FormUiSchema } from 'web/src/components/Form';

export default function getDefaultAutofocus(uiSchema: FormUiSchema): string | undefined {
  let result: string | undefined;
  if (uiSchema.fields) {
    const keys = Object.keys(uiSchema.fields);
    for (const key of keys) {
      const field = uiSchema.fields[key];
      if (field && field.options && 'autofocus' in field.options && field.options.autofocus) {
        return key;
      }
    }
  }
  return result;
}
