<script lang="ts" setup>
import { onBeforeMount } from 'vue';

import VFadeCarousel from 'web/src/components/FadeCarousel/VFadeCarousel/VFadeCarousel.vue';
import VFadeCarouselNavigationButtons
  from 'web/src/components/FadeCarousel/VFadeCarouselNavigationButtons/VFadeCarouselNavigationButtons.vue';
import VFadeCarouselPointsPagination
  from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import VFadeCarouselSlide from 'web/src/components/FadeCarousel/VFadeCarouselSlide/VFadeCarouselSlide.vue';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import MainBannerItem from 'web/src/modules/banners/components/MainBannerItem/MainBannerItem.vue';

import type { FadeCarouselBannerEmits, FadeCarouselBannerProps } from './types';
import { useFadeCarouselBanner } from './composables';

const props = withDefaults(defineProps<FadeCarouselBannerProps>(), {
  aspectRatio: 1.75,
  bannerType: BannerType.MAIN_BANNER,
});
const emit = defineEmits<FadeCarouselBannerEmits>();

const {
  fadeCarousel,
  autoplayTimeout,
  banners,
  readySlides,
  nextVisibleIndex,
  isGroupBannersDev,
  activeSlideIndex,
  bannerSliderDotsCount,
  onSlideChanged,
  beforeMount,
  paginationLocation,
} = useFadeCarouselBanner(props, emit);

onBeforeMount(beforeMount);
</script>

<template>
  <VFadeCarousel v-auto-id="'FadeCarouselBanner'"
    ref="fadeCarousel"
    :autoplay-timeout="autoplayTimeout"
    :pagination-location="paginationLocation"
    @slide-changed="onSlideChanged"
    @mounted="emit('mounted')"
  >
    <template #default="{ options }">
      <VFadeCarouselSlide
        v-for="(banner, index) in banners"
        :key="index"
        :current-slide-index="index"
      >
        <template #default="{ isSlideShown }">
          <MainBannerItem
            v-if="readySlides.includes(index)"
            v-data-test="{ el: 'banner-item' }"
            :banner="banner"
            :group-id="bannerGroup?.id"
            :is-redirect-disabled="options.isDragInProgress"
            :is-slide-hidden="!process.env.VUE_APP_PRERENDER && !isSlideShown && index !== nextVisibleIndex"
            :is-group-banners-dev="isGroupBannersDev"
            :is-big-height="isBigHeight"
            :is-active="activeSlideIndex === index"
            :class="$style['fade-carousel-banner__item--link-drag-prevent']"
            :banner-type="bannerType"
            @click="emit('banner-item-click', {
              path: $event,
              banner,
            })"
          />
        </template>
      </VFadeCarouselSlide>
    </template>
    <template #pagination>
      <VFadeCarouselNavigationButtons v-if="'1' || isBigHeight" />
      <slot name="pagination">
        <VFadeCarouselPointsPagination
          :pagination-location="paginationLocation"
          :banner-slider-dots-count="bannerSliderDotsCount"
        />
      </slot>
    </template>
  </VFadeCarousel>
</template>

<style lang="scss" module>
.fade-carousel-banner {
  &__item {
    &--link-drag-prevent {
      display: block;
      user-select: none;
      -webkit-user-drag: none;
      -webkit-touch-callout: none;
    }
  }
}
</style>
