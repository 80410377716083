<script setup lang="ts">
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VButtonProps } from '@components/buttons';
import type { VIconProps } from '@components/v-icon';
import { ButtonHeight, ButtonKind, LButton, SButton } from '@components/buttons';
import { VIcon } from '@components/v-icon';

import type {
  PromotionRateButtonEmits,
  PromotionRateButtonProps,
} from 'web/src/modules/promotions/components/PromotionRateButton/types';

const props = withDefaults(defineProps<PromotionRateButtonProps>(), {
  likes: 0,
});
const emit = defineEmits<PromotionRateButtonEmits>();

const buttonProperties: VButtonProps = {
  kind: ButtonKind.BASE,
  height: ButtonHeight.LARGE,
  rounded: true,
};

const iconProperties = computed<VIconProps>(() => ({
  name: props.isLikeButton ? IconName.LIKE : IconName.DISLIKE,
  size: IconSize.SIZE_16,
}));

const iconSlottProperties = computed<VIconProps>(() => ({
  name: props.isLikeButton ? IconName.SLOTT_LIKE_OUTLINE : IconName.SLOTT_DISLIKE_OUTLINE,
  size: IconSize.SIZE_16,
}));
</script>

<template>
  <SButton
    v-if="null"
    :class="{
      [$style['promotion-rate-button']]: true,
      [$style['promotion-rate-button--have-likes']]: likes > 0,
      [$style['promotion-rate-button--pressed']]: isPressed,
    }"
    kind="tertiary-secondary"
    size="small"
    :disabled="disabled"
    @click="emit('click')"
  >
    <VIcon
      v-bind="iconSlottProperties"
      :class="$style['promotion-rate-button__icon']"
    />
    <span
      v-if="likes > 0"
      :class="$style['promotion-rate-button__label']"
    >{{ likes }}</span>
  </SButton>
  <LButton
    v-else
    :class="{
      [$style['promotion-rate-button']]: true,
      [$style['promotion-rate-button--have-likes']]: likes > 0,
      [$style['promotion-rate-button--pressed']]: isPressed,
    }"
    v-bind="buttonProperties"
    :disabled="disabled"
    @click="emit('click')"
  >
    <VIcon
      v-bind="iconProperties"
      :class="$style['promotion-rate-button__icon']"
    />
    <span
      v-if="likes > 0"
      :class="$style['promotion-rate-button__label']"
    >{{ likes }}</span>
  </LButton>
</template>

<style lang="scss" module>
.promotion-rate-button {
  $self: &;

  gap: $promotionRateButtonGap;
  width: $promotionRateButtonWidth;
  padding: 0;
  color: $promotionRateButtonColor;

  @include for-hover {
    &:hover {
      color: $promotionRateButtonHoverColor;
    }
  }

  &#{&}--have-likes {
    width: auto;
    padding: 0 20px 0 16px;
    border-radius: 24px;
  }

  &[disabled]:not(&--pressed) {
    opacity: 1;
  }

  &__icon {
    flex-shrink: 0;
  }

  &#{&}--pressed {
    color: $promotionRateButtonPressedColor;
    opacity: $promotionRateButtonPressedOpacity;

    #{$self}__label {
      color: $promotionRateButtonPressedLabelColor;
    }

    @include is-app-layout-desktop {
      &:hover:not([disabled]),
      &:hover[disabled] {
        color: $promotionRateButtonPressedHoverColor;
        background: $promotionRateButtonPressedHoverBackgroundColor;

        #{$self}__label {
          color: $promotionRateButtonPressedHoverLabelColor;
        }
      }
    }
  }
}
</style>
