import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { RouteName } from '@leon-hub/routing-config-names';

import { useSiteConfigStore } from '@core/site-config';

import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { useAppDownloadStore } from 'web/src/modules/app-download/store';
import { getMobileAppOs } from 'web/src/modules/app-download/utils';

export interface AppDownloadComposable {
  appOs: MobileAppOs | undefined;
  onAppDownload(appOs?: MobileAppOs): void;
  appDownloadLink(inputAppOs?: MobileAppOs): Ref<string>;
  isAppDownloadEnabled(inputAppOs?: MobileAppOs): Ref<boolean>;
}

export default function useAppDownload(): AppDownloadComposable {
  const appDownloadStore = useAppDownloadStore();
  const isAndroidAppEnabled = toRef(appDownloadStore, 'isAndroidAppEnabled');
  const isIosAppEnabled = toRef(appDownloadStore, 'isIosAppEnabled');
  const iosAppUrl = toRef(appDownloadStore, 'iosAppUrl');
  const androidAppUrl = toRef(appDownloadStore, 'androidAppUrl');

  const appOs = getMobileAppOs();
  const router = useRouter();
  const isLandingAndroidEnabled = toRef(useSiteConfigStore(), 'isLandingAndroidEnabled');

  const bus = useEventsBus();

  function onAppDownload(inputAppOs?: MobileAppOs): void {
    switch (inputAppOs) {
      case MobileAppOs.Android:
        if (isLandingAndroidEnabled.value) {
          if (process.env.VUE_APP_LAYOUT_PHONE) {
            bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, { reason: 'sidebar' });
          }
          void router.push({ name: RouteName.ANDROID });
        } else {
          window.location.href = androidAppUrl.value;
        }
        break;
      case MobileAppOs.Ios:
        window.location.href = iosAppUrl.value;
        break;
      default:
        break;
    }
  }

  function appDownloadLink(inputAppOs?: MobileAppOs): Ref<string> {
    switch (inputAppOs) {
      case MobileAppOs.Android:
        return computed(() => {
          if (isLandingAndroidEnabled.value) {
            return router.resolve({ name: RouteName.ANDROID }).href;
          }

          return androidAppUrl.value;
        });
      case MobileAppOs.Ios:
        return iosAppUrl;
      default:
        return ref('/');
    }
  }

  function isAppDownloadEnabled(inputAppOs: MobileAppOs): Ref<boolean> {
    switch (inputAppOs) {
      case MobileAppOs.Android:
        return isAndroidAppEnabled;
      case MobileAppOs.Ios:
        return isIosAppEnabled;
      default:
        return ref(false);
    }
  }

  return {
    appOs,
    onAppDownload,
    appDownloadLink,
    isAppDownloadEnabled,
  };
}
