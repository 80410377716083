import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import type { FileMultipleDragAndDropInputProps } from 'web/src/components/Input';
import isFileMultipleDragAndDropInputProperties from 'web/src/components/Input/guards/isFileMultipleDragAndDropInputProperties';

import type {
  FormWrapperWidgetProperties,
} from '../../types';
import getWrapperHintProperties from '../../utils/getWrapperHintProperties';

interface UseFormFileDragAndDropInput {
  componentProps: ComputedRef<FileMultipleDragAndDropInputProps>;
}

export default function useFormFileDragAndDropInput(
  props: FormWrapperWidgetProperties<FileMultipleDragAndDropInputProps>,
): UseFormFileDragAndDropInput {
  const componentProps = computed<FileMultipleDragAndDropInputProps>(
    () => {
      const {
        name,
        options,
        error,
        disabled,
        hintMessage,
      } = props;

      assert(isFileMultipleDragAndDropInputProperties(options), 'Unexpected options for FileMultipleDragAndDropInput');

      let errorString = error;
      let errorsArray: (string | null | object)[] = [];
      if (Array.isArray(error)) {
        errorsArray = error;
        errorString = error.map(String).filter((item) => !!item).join(', ');
      }

      return {
        ...options,
        ...getWrapperHintProperties({ options, error: errorString, hintMessage }),
        name,
        formErrors: errorsArray,
        disabled,
      };
    },
  );
  return {
    componentProps,
  };
}
