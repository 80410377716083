import type { SportFamily } from 'web/src/modules/sportline/enums';
import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import type { DateTimeRange } from 'web/src/modules/sportline/types';
import type {
  CoreSportlineFetchOptions,
  EventsChangesFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

import type { PendingGetEventsInput } from './useSportlinePendingApi';
import { useSportlinePendingApi } from './useSportlinePendingApi';

interface LoadComingEventsOptions extends CoreSportlineFetchOptions {
  filters: DateTimeRange;
  regionId?: string;
  sportFamily?: string | SportFamily;
}

interface UseSportlineApiLoadComingEventsComposable {
  loadComingEvents(
    options: LoadComingEventsOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

export function useSportlineApiLoadComingEvents(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadComingEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const {
    loadEvents: loadComingEvents,
  } = useSportlinePendingApi<LoadComingEventsOptions>({
    tryRequestWithCTag,
    getEventsInput: ({
      silent,
      filters,
      regionId,
      sportFamily,
    }): PendingGetEventsInput => ({
      betline: BetlineType.Prematch,
      regionId,
      sportFamily,
      to: filters.to,
      silent,
      hideClosed: true,
      flags: [...defaultRestApiFlags.value],
      LMCE: isLiveOutrightsEnabled.value,
    }),
    getPendingParams: ({
      vTag,
      regionId,
      sportFamily,
      filters,
    }) => ({
      filter: filters,
      vTag,
      regionId,
      sportFamily,
      betlines: [BetlineType.Prematch],
    }),
  });

  return { loadComingEvents };
}
