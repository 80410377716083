<script lang="ts" setup>
import type { PaymentBonusesEmits } from 'web/src/modules/payments/components/PaymentBonuses/types';
import type {
  WelcomeBonusSelectorProps,
} from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/types';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import WelcomeBonusSelect from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelect/WelcomeBonusSelect.vue';
import WelcomeBonusSelectorModal
  from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/components/WelcomeBonusSelectorModal/WelcomeBonusSelectorModal.vue';
import useWelcomeBonusSelector
  from 'web/src/modules/payments/form-widgets/WelcomeBonusSelector/composables/useWelcomeBonusSelector';

const props = withDefaults(defineProps<WelcomeBonusSelectorProps>(), {});

const emit = defineEmits<PaymentBonusesEmits>();

const {
  isPaymentBonuses,
  welcomeBonusSelectProps,
  onSelectClick,
  openListModal,
  onCloseModal,
  onCloseAllModal,
  onChange,
} = useWelcomeBonusSelector(props, emit);

const modalSelector = '1' ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY;
</script>

<template>
  <div v-auto-id="'WelcomeBonusSelector'" :class="$style['welcome-bonus-selector']">
    <WelcomeBonusSelect
      v-bind="welcomeBonusSelectProps"
      @select="onSelectClick"
      @bonus-click="openListModal"
    />

    <ModalPortal
      :selector="modalSelector"
    >
      <WelcomeBonusSelectorModal
        v-if="isPaymentBonuses"
        :bonus-list="depositBonusList"
        :selected-option-id="selectedBonus"
        @back="onCloseModal"
        @close="onCloseAllModal"
        @select="onChange"
      />
    </ModalPortal>
  </div>
</template>

<style lang="scss" module>
.welcome-bonus-selector {
  margin-top: 15px;
}
</style>
