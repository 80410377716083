import type { RouteLocationNormalized } from 'vue-router';

import { CasinoRouteName } from '@leon-hub/routing-config-names';

import { isCasinoLobbyRouteName } from 'web/src/modules/casino/utils';
import { FavoriteGamesCategoryUrl } from 'web/src/modules/egs/constants';

export default function isFavoriteEgsRoute(to: Pick<RouteLocationNormalized, 'name' | 'params'>): boolean {
  if (!process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    return false;
  }

  if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED && to.name === CasinoRouteName.CASINO_MY_GAMES) {
    return true;
  }

  return isCasinoLobbyRouteName(to.name) && to.params.categoryUrl === FavoriteGamesCategoryUrl;
}
