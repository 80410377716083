import type { IInputFileTypes } from '@leon-hub/input-types';
import { InputFileTypes } from '@leon-hub/input-types';

export function getFileType(fileName: string): IInputFileTypes {
  if (/.(jpg|png|jpeg)$/i.test(fileName)) {
    return InputFileTypes.Image;
  }

  if (/.(mp4|mov|3gp|3gpp|webm|m4v)$/i.test(fileName)) {
    return InputFileTypes.Video;
  }

  if (/.(pdf)$/i.test(fileName)) {
    return InputFileTypes.Pdf;
  }

  return InputFileTypes.Other;
}
