import { DateTime } from '@leon-hub/utils';

import { DateInputCustomDate } from 'web/src/components/Input/enums';

export function getMinDate(min: string | number | DateInputCustomDate | undefined): number | undefined {
  if (typeof min === 'string') {
    if (min === DateInputCustomDate.Now) {
      return DateTime.now().toTimestamp();
    }

    return undefined;
  }

  return min;
}
