<script lang="ts" setup>
import { VSwitch } from '@components/switch';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import useFormSwitch from './useFormSwitch';
import useFormSwitchEvents from './useFormSwitchEvents';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useFormSwitchEvents(emit, props.name);

const { componentProps } = useFormSwitch(props);
</script>

<template>
  <VSwitch v-auto-id="'FormSwitch'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
