import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  nextTick,
  toRef,
} from 'vue';

import type { VCoreDropdownProps } from '@components/core-dropdown';
import { useDropdownButton } from '@components/core-dropdown';

import type {
  DropdownSelectEmits,
  DropdownSelectProperties,
  TemplateRef,
  VSelectOption,
} from '../types';
import setDropdownSelectRefs from '../utils/setDropdownSelectRefs';
import useDropdownEvents from './useDropdownEvents';
import useDropdownKeydownAndFocusIndex from './useDropdownKeydownAndFocusIndex';
import useDropdownList from './useDropdownList';
import useDropdownSearchInput from './useDropdownSearchInput';

interface UseDropdownSelect {
  isDropdownShown: Ref<boolean>;
  onFocus(): void;
  onBlur(): void;
  onButtonBlur(): void;
  onChange(value: string): void;
  focusOnDropdownButton(): void;
  close(): Promise<void>;
  toggleOpen(): Promise<void>;
  dropdownButton: Ref<HTMLButtonElement | undefined>;
  dropdownProps: Ref<VCoreDropdownProps | null>;
  optionsToRender: ComputedRef<VSelectOption[]>;
  hiddenSubmit: Ref<HTMLButtonElement | undefined>;
  handleKeyDown(event: KeyboardEvent): void;
  isSearchActive: ComputedRef<boolean>;
  dropdownSearchInput: Ref<HTMLInputElement | undefined>;
  searchQuery: Ref<string>;
  handleSearchInput(event: InputEvent): void;
  clearSearchQuery(): void;
  preselectedListIndex: Ref<number>;
  setPreselectedIndex(index: number): void;
  renderMoreItems(): void;
  setRefs(templateRef: TemplateRef): void;
}

export default function useDropdownSelect(
  props: DropdownSelectProperties,
  emits: DropdownSelectEmits,
): UseDropdownSelect {
  const {
    isDropdownShown,
    closeDropdown,
    openDropdown,
    focusOnDropdownButton,
    setDropdownButton,
    dropdownButton,
    dropdownProps,
  } = useDropdownButton(props, { ignoreScroll: props.ignoreScroll });

  const {
    dropdownSearchInput,
    searchQuery,
    handleSearchInput,
    clearSearchQuery,
    focusOnDropdownInput,
  } = useDropdownSearchInput();

  const {
    optionsToRender,
    allPossibleOptions,
    renderMoreItems,
    resetPagination,
    setPaginationToReachIndex,
  } = useDropdownList({
    enableSearch: toRef(props, 'enableSearch'),
    hideSelectedOption: toRef(props, 'hideSelectedOption'),
    options: toRef(props, 'options'),
    selectedValue: toRef(props, 'selectedValue'),
    itemsPerPage: toRef(props, 'itemsPerPage'),
    searchQuery,
  });

  const close = async (): Promise<void> => {
    await closeDropdown();
    resetPagination();
    emits('close');
  };

  const open = async () => {
    if (isDropdownShown.value) {
      return;
    }
    clearSearchQuery();
    openDropdown();
    emits('open');
    if (props.enableSearch) {
      await nextTick();
      focusOnDropdownInput();
    }
  };

  const toggleOpen = (): Promise<void> => (isDropdownShown.value ? close() : open());

  const { onBlur, onChange, onFocus } = useDropdownEvents(props, emits, close, clearSearchQuery);

  const {
    hiddenSubmit,
    handleKeyDown,
    preselectedListIndex,
    setPreselectedIndex,
    setHiddenSubmit,
  } = useDropdownKeydownAndFocusIndex({
    openDropdown: open,
    closeDropdown: close,
    onOptionSelect: onChange,
    selectedValue: toRef(props, 'selectedValue'),
    options: allPossibleOptions,
    disableKeySearch: toRef(props, 'enableSearch'),
    isDropdownShown,
    searchQuery,
    setPaginationToReachIndex,
  });

  const onButtonBlur = (): void => {
    if (!dropdownSearchInput.value) {
      onBlur();
    }
  };

  const isSearchActive = computed<boolean>(
    () => !!(isDropdownShown.value && props.enableSearch),
  );

  const setRefs = (templateRef: TemplateRef): void => {
    setDropdownSelectRefs(templateRef, setDropdownButton, setHiddenSubmit);
  };

  return {
    isDropdownShown,
    onFocus,
    onChange,
    onBlur,
    onButtonBlur,
    close,
    toggleOpen,
    focusOnDropdownButton,
    dropdownProps,
    dropdownButton,
    optionsToRender,
    hiddenSubmit,
    handleKeyDown,
    isSearchActive,
    dropdownSearchInput,
    searchQuery,
    handleSearchInput,
    clearSearchQuery,
    preselectedListIndex,
    setPreselectedIndex,
    renderMoreItems,
    setRefs,
  };
}
