import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import type {
  CoreSportlineFetchOptions,
  GetHeadlineMatchesChangesResponse,
  GetHeadlineMatchesResponse,
  GetMergedHeadlineEventsResponse,
  GetSplitHeadlineEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useTopSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import { mapHeadlineMatchesToSportEventsResponse } from 'web/src/modules/sportline/utils/rest/pre-build';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

import {
  handleRawSportEventsResponse,
} from '../utils';
import {
  useMixedHeadlineListsStore,
  useSeparateHeadlineListsStore,
} from './composables';

export const useSportlineTopStore = defineStore('sportline-top', () => {
  const sportlineSettingsStore = useSportlineSettingsStore();

  const apiService = useSportlineApiService();

  const customerFavoritesService = useCustomerFavoritesService();
  const broadcastSelected = useBroadcastSelected();
  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();
  const { timeout: topSportlineUpdateInterval } = useTopSportlineUpdateTimeout();

  const rawSplitSportEventsResponse = ref<Maybe<GetSplitHeadlineEventsResponse>>(null);
  const rawMergedSportEventsResponse = ref<Maybe<GetMergedHeadlineEventsResponse>>(null);

  const {
    isReady: isSeparateReady,
    totalLiveCount,
    totalPrematchCount,
    liveHeadlineElements,
    prematchHeadlineElements,
  } = useSeparateHeadlineListsStore({ rawResponse: rawSplitSportEventsResponse });
  const {
    isReady: isMixedReady,
    totalCount,
    headlineElements,
    sportlineElements,
  } = useMixedHeadlineListsStore({ rawResponse: rawMergedSportEventsResponse });

  const isReady = computed(() => (
    sportlineSettingsStore.isTopEventsEnabled ? isMixedReady.value : isSeparateReady.value
  ));

  function setRawSportEventsResponse(
    response: Maybe<GetHeadlineMatchesChangesResponse | GetHeadlineMatchesResponse>,
  ): void {
    const parsed = handleRawSportEventsResponse(response);
    rawMergedSportEventsResponse.value = parsed.rawMergedSportEventsResponse;
    rawSplitSportEventsResponse.value = parsed.rawSplitSportEventsResponse;
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['sportEvents']>({
    sportEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      const inplayVTag = rawSplitSportEventsResponse.value?.live?.vtag;
      const prematchVTag = rawSplitSportEventsResponse.value?.prematch?.vtag;
      const allVTag = rawMergedSportEventsResponse.value?.events?.vtag;

      const response = await apiService.loadHeadlineMatches({
        inplayVTag,
        prematchVTag,
        allVTag,
        silent,
      });

      setRawSportEventsResponse(response ?? null);
      lastUpdate.update('sportEvents');

      broadcastSelected.updateDataInStorageByResponse({
        response: mapHeadlineMatchesToSportEventsResponse(response),
      });
    },
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: topSportlineUpdateInterval,
    onEnableChanged(value: boolean): void {
      void customerFavoritesService.setIsBackgroundIdsUpdateAvailable(
        CustomerFavoritesIdsBackgroundUpdateKey.HOME,
        value,
      );
    },
  });

  // init
  useSyncSportline((silent) => syncState({ silent }), topSportlineUpdateInterval);
  onGlobalSportlineEventsInit();

  return {
    isReady,
    totalLiveCount,
    totalPrematchCount,
    totalCount,
    liveHeadlineElements,
    prematchHeadlineElements,
    headlineElements,
    sportlineElements,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
});
