export enum ErrorCodeWithdrawal {
  VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
  PAYOUT_BLOCKED = 'PAYOUT_BLOCKED',
  IDENT_NEEDED = 'IDENT_NEEDED',
}

export enum ErrorCodeDeposit {
  EKYC_NEEDED = 'EKYC_NEEDED',
}

export enum PaymentsFormStep {
  SMS_CODE = 'SMS_CODE', // no longer used step LEONWEB-7405
  INITIAL = 'INITIAL',
  NEW_CARD = 'NEW_CARD',
  CVC = 'CVC',
  SECOND = 'SECOND',
}

export enum PaymentsCardField {
  CARD_NUMBER = 'cardnumber',
  CARD_HOLDER = 'cardholder',
  CARD_EXPIRATION = 'expiration',
  CARD_CVC = 'cvc',
  CARD_CVV = 'cvv',
  CARD = 'card', // withdrawal cardnumber field
}

export enum PaymentsCardTypes {
  MASTER_CARD_NEW = 'MASTER_CARD_NEW',
  MASTER_CARD = 'MASTER_CARD',
  VISA_ELECTRON = 'VISA_ELECTRON',
  VISA = 'VISA',
  MAESTRO = 'MAESTRO',
  AMEX = 'AMEX',
  DINERS = 'DINERS',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MIR = 'MIR',
  HUMO = 'HUMO',
  UZCARD = 'UZCARD',
}

export enum CardTypesValidationPatterns {
  AMEX = '^(3[47])',
  DINERS = '^((30[0-59]{1})|(3[689]{1}[0-9]{1}))',
  DISCOVER = '^(6((5[0-9][4])|(4[4-9][0-9]{3})|(22(12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5]))|(011[0-9]{2})))',
  JCB = '^(35(2[8-9]|[3-8][0-9]))',
  MAESTRO = '^((5018)|(5020)|(5038)|(5612)|(5893)|(6304)|(67[0-9]{2})|(0604)|(6390))',
  VISA_ELECTRON = '^((417500)|((4026)|(4405)|(4508)|(4844)|(4913)|(4917)))',
  VISA = '^(4(?!(17500)|(026)|(405)|(508)|(844)|(913)|(917)))',
  MIR = '^(220([0-4]))',
  MASTER_CARD = '^((5([1-5]))|(22[2-9])|(2[3-7]))',
  HUMO = '^9860',
  UZCARD = '^(8600|5614)',
}

export enum PaymentsStatus {
  OK = 'OK',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum PaymentsSubmitAction {
  SUBMIT = 'submit',
  CANCEL = 'cancel',
  RETRY = 'retry',
  NEXT_STEP_SUBMIT = 'next_step_submit',
}

export enum PaymentsDepositStatus {
  SUCCESS = 'success',
  PROCESS = 'process',
  FAIL = 'fail',
}

export enum InvalidPaymentsExceptionCode {
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_ERROR_WRONG_PAN = 'PAYMENT_ERROR_WRONG_PAN',
  PAYMENT_ERROR_DATE_EXPIRED = 'PAYMENT_ERROR_DATE_EXPIRED',
  PAYMENT_ERROR_WRONG_EXP_DATE = 'PAYMENT_ERROR_WRONG_EXP_DATE',
  PAYMENT_ERROR_WRONG_NAME = 'PAYMENT_ERROR_WRONG_NAME',
}

export enum InvalidCreditCardTokenizeCode {
  TOKENIZE_ERROR_INVALID_PAN = 'INVALID_PAN',
}

export enum PaymentsModalType {
  DEPOSITS_OTHER_SUCCESS_MODAL = 'DEPOSITS_OTHER_SUCCESS_MODAL',
  WITHDRAWAL_CANCEL_SUCCESS_MODAL = 'WITHDRAWAL_CANCEL_SUCCESS_MODAL',
  WITHDRAWAL_CANCEL_MODAL = 'WITHDRAWAL_CANCEL_MODAL',
}

export enum PaymentsIdentificationButtonName {
  CHECK_STATUS = 'checkStatus',
  RETRY = 'retry',
  CLOSE = 'close',
  TO_SUPPORT = 'toSupport',
}

export enum PaymentFormFieldName {
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  CUST_BCODES = 'cust_bcodes',
  DOACTION = 'doaction',
  PAYMENT_SYSTEM_ID = 'paymentSystemId',
  PMTMETHOD = 'pmtmethod',
  CARDHOLDER = 'cardholder',
  CARDNUMBER = 'cardnumber',
  CARDNUMBER_WITHDRAWAL = 'card',
  CARDNUMBER_SHOW = 'cardnumber_show',
  CVC = 'cvc',
  EXPIRATION = 'expiration',
  SAVE_CARD = 'saveCard',
  STEP = 'step',
  PHONE = 'phone',
}
