import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { DialogAction, PresetName, useDialogs } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { isCookiesDisabled } from 'web/src/utils/browser';

export function useDisableCookiesGuard(): void {
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();
  const router = useRouter();
  onMounted(() => {
    if (isCookiesDisabled()) {
      showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('WEB2_COOKIES_DISABLED_CAPTION').value,
          confirmMessage: $translate('WEB2_COOKIES_DISABLED_DESCRIPTION').value,
          dataTestId: 'cookies-disabled-description',
        },
      }).subscribe({
        [DialogAction.MODAL_CLOSE]: () => {
          if (process.env.VUE_APP_LAYOUT_DESKTOP) {
            void router.closeModal();
          } else {
            router.back();
          }
        },
      });
    }
  });
}
