<script setup lang="ts">
import { computed } from 'vue';

import {
  AlertIconName,
  IconName,
  IconSize,
} from '@leon-hub/icons';

import type { ConfirmDialog } from '@core/dialogs';
import { DialogAction } from '@core/dialogs';
import { useI18n } from '@core/i18n';

import { ButtonKind } from '@components/buttons';

import type {
  PaymentsWithdrawalCancelModalEmits,
  PaymentsWithdrawalCancelModalProps,
} from 'web/src/modules/payments/components/PaymentsWithdrawalCancelModal/types';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { getImageOrIcon } from 'web/src/modules/icons';

const props = defineProps<PaymentsWithdrawalCancelModalProps>();
const emit = defineEmits<PaymentsWithdrawalCancelModalEmits>();

const { $translate } = useI18n();

function onButtonClick({ action }: { action: DialogAction }): void {
  if (action === DialogAction.CONFIRM) {
    emit('confirm-click');
  }
  if (action === DialogAction.MODAL_CLOSE) {
    emit('close');
  }
}

const modalProperties = computed<ConfirmDialog>(() => ({
  ...(null
    ? {
        iconSize: IconSize.SIZE_96,
      }
    : {}),
  isInnerModal: !!'1',
  ...getImageOrIcon({
    icon: IconName.ATTENTION_OUTLINE,
    alertIcon: AlertIconName.QuestionMark,
  }),
  fullHeight: false,
  title: $translate('WEB2_CANCEL_PAYMENT_WITHDRAWAL_TITLE').value,
  isProfileLayout: !!'1' && props.isInProfile,
  buttons: [
    {
      action: DialogAction.CONFIRM,
      kind: ButtonKind.PRIMARY,
      label: $translate('WEB2_YES_CANCEL').value,
    },
    {
      action: DialogAction.MODAL_CLOSE,
      kind: null ? 'tertiary-primary' : ButtonKind.TRANSPARENT,
      label: $translate('WEB2_DO_NOT_CANCEL').value,
    },
  ],
}));
</script>

<template>
  <ConfirmModal v-auto-id="'PaymentsWithdrawalCancelModal'"
    :modal="modalProperties"
    @button-click="onButtonClick"
    @close="emit('close')"
  >
    <div :class="$style['deposits-success__text']">
      <span>{{ secondaryText }}</span> {{ text }}
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.deposits-success {
  &__text {
    @include paymentModalCancelText;

    span {
      @include paymentModalCancelTextSpan;
    }
  }
}
</style>
