import type { Component, ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  HintLinkProps,
  InputHintProps,
} from '@leon-hub/input-types';

import {
  getHintLinkComponent,
  getHintLinkProps,
  isNativeAnchor,
} from './utils';

interface UseInputHint {
  hintErrorText: ComputedRef<string>;
  hintErrorTextRight: ComputedRef<string>;
  hintLinkComponent: ComputedRef<string | Component>;
  hintLinkRightComponent: ComputedRef<string | Component>;
  hintLinkLeftProperties: ComputedRef<Partial<HintLinkProps>>;
  hintLinkRightProperties: ComputedRef<Partial<HintLinkProps>>;
}

export default function useInputHint(props: InputHintProps): UseInputHint {
  const hintErrorText = computed<string>(() => (props.error || props.hint) || '');
  const hintErrorTextRight = computed<string>(() => (props.errorRight || props.hintRight) || '');

  const isLeftLinkNative = computed<boolean>(() => isNativeAnchor(props.hintLink));
  const isRightLinkNative = computed<boolean>(() => isNativeAnchor(props.hintLinkRight));

  const hintLinkComponent = computed<string | Component>(() => getHintLinkComponent(isLeftLinkNative.value));
  const hintLinkRightComponent = computed<string | Component>(() => getHintLinkComponent(isRightLinkNative.value));

  const hintLinkLeftProperties = computed<Partial<HintLinkProps>>(
    () => getHintLinkProps(isLeftLinkNative.value, props.hintLink),
  );
  const hintLinkRightProperties = computed<Partial<HintLinkProps>>(
    () => getHintLinkProps(isRightLinkNative.value, props.hintLinkRight),
  );

  return {
    hintErrorText,
    hintErrorTextRight,
    hintLinkComponent,
    hintLinkRightComponent,
    hintLinkLeftProperties,
    hintLinkRightProperties,
  };
}
