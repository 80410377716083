import { toRef } from "vue";
import { useSiteConfigStore } from "@core/site-config";
var FlagValue = /* @__PURE__ */ ((FlagValue2) => {
  FlagValue2["GREECE"] = "el";
  FlagValue2["KOREAN"] = "ko";
  FlagValue2["TECH"] = "ln";
  FlagValue2["UZBEK"] = "uc";
  FlagValue2["UKRAINIAN"] = "uk";
  FlagValue2["VIETNAMESE"] = "vi";
  return FlagValue2;
})(FlagValue || {});
var LanguageSelectorPlace = /* @__PURE__ */ ((LanguageSelectorPlace2) => {
  LanguageSelectorPlace2["HEADER"] = "header";
  LanguageSelectorPlace2["FOOTER"] = "footer";
  LanguageSelectorPlace2["SETTINGS"] = "settings";
  LanguageSelectorPlace2["HAMBURGERMENU"] = "hamburgerMenu";
  return LanguageSelectorPlace2;
})(LanguageSelectorPlace || {});
function useIsGlobeIcon() {
  const isLanguageSelectorFlagsEnabled = toRef(useSiteConfigStore(), "isLanguageSelectorFlagsEnabled");
  function isGlobeIcon(value) {
    return value === FlagValue.TECH || !isLanguageSelectorFlagsEnabled.value;
  }
  return {
    isGlobeIcon
  };
}
export {
  FlagValue,
  LanguageSelectorPlace,
  useIsGlobeIcon
};
