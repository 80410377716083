<script lang="ts" setup>
import { TextInputTypes } from '@leon-hub/input-types';

import { SuggestEmailInput } from 'web/src/components/Input/components';

import type {
  BaseFormWidgetEmits,
  FormWrapperWidgetProperties,
} from '../../types';
import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useCommonFormWidgetProps(props, TextInputTypes.EMAIL);
</script>

<template>
  <SuggestEmailInput v-auto-id="'FormSuggestEmail'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
