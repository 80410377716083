<script setup lang="ts">
import type { BaseInputEmits } from '@leon-hub/input-types';
import { InputMode, TextInputTypes } from '@leon-hub/input-types';

import type { DateMaskInputProps } from '../../types';
import { useHintParentProps } from '../../composables';
import TextInput from '../TextInput';
import useDateMaskInput from './useDateMaskInput';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<DateMaskInputProps>(), {
  name: 'date-input',
});

const emits = defineEmits<BaseInputEmits>();

const {
  inputValue,
  mask,
  maxSymbols,
  onInput,
  onChange,
  onFocus,
  onBlur,
} = useDateMaskInput(props, emits);

const { hintProperties } = useHintParentProps(props);
</script>

<template>
  <TextInput v-auto-id="'DateMaskInput'"
    v-bind="hintProperties"
    :autofocus="autofocus"
    :name="name"
    :label="label"
    :value="inputValue"
    :disabled="disabled"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    :max-symbols="maxSymbols"
    :prefix-icon-name="prefixIconName"
    :suffix-icon-name="suffixIconName"
    :mask="mask"
    :type="TextInputTypes.TEL"
    :input-mode="InputMode.NUMERIC"
    :is-large="isLarge"
    @input="onInput"
    @change="onChange"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>
